import { useEffect, useRef, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import useS3 from "../../../../hooks/useS3";
import Popover from "@mui/material/Popover";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Edit from "@mui/icons-material/Edit";

const imgExtensions = [
  "png",
  "jpg",
  "jpeg",
  "apng",
  "gif",
  "avif",
  "svb",
  "webp",
  "bpm",
  "ico",
  "tiff",
];

interface props {
  dir: string;
  Key: string;
  cb?: () => void;
  noEdit?: boolean;
}

export default function KeyObject({ dir, Key, cb, noEdit }: props) {
  const { getFileURL, deleteObject, renameFile } = useS3();

  const [url, setURL] = useState("");

  useEffect(() => {
    getFileURL(Key).then(url => setURL(url || ""));
  }, []);

  const fileNameOnly = Key.replace(`${dir}/`, "");
  const ext = fileNameOnly.split(".").pop() || "";
  const nameOnly = fileNameOnly.replace(`.${ext}`, "");

  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const isImage = imgExtensions.includes(ext.toLowerCase());

  const handleClick = () => {
    setOpen(true);
  };

  const _delete = async () => {
    const proceed = confirm("파일을 삭제하시겠습니까?");
    if (!proceed) {
      return;
    }

    await deleteObject(Key);

    if (cb) {
      cb();
    }
  };

  const rename = async () => {
    let newName = prompt("새 파일 이름을 입력해주세요");

    if (!newName) {
      return;
    }

    const newExt = newName.includes(".") && newName.split(".").pop();

    newName = newName.replace(`.${newExt || ""}`, "");

    const newKey = `${dir}/${newName}.${newExt || ext}`;

    if (newKey == Key) {
      return;
    }

    await renameFile(Key, newKey);
    setOpen(false);

    if (cb) {
      cb();
    }
  };

  return (
    <>
      <div
        className="hover:text-quezone cursor-pointer flex flex-row gap-4 items-center"
        onClick={handleClick}
        ref={anchorRef}
      >
        <div className="p-[3px] bg-black rounded-sm" />
        <div className="flex flex-col gap-1 text-sm">
          {isImage && (
            <img
              src={url}
              alt={fileNameOnly}
              className="h-24 w-24 object-cover"
            />
          )}
          {nameOnly}
        </div>
      </div>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: 1 }}
        className="shadow-md p-2 "
      >
        <div className="relative">
          <div className="absolute top-0 right-0 w-full bg-green-100 bg-opacity-50 p-[4px] md:p-3 flex flex-row justify-end gap-4 z-10">
            {!noEdit && (
              <>
                <Edit
                  className="hover:text-quezone cursor-pointer"
                  onClick={rename}
                />
                <DeleteForeverIcon
                  className="hover:text-red-500 cursor-pointer"
                  onClick={_delete}
                />
              </>
            )}
            <a href={url} download target="_blank">
              <CloudDownloadIcon className="hover:text-quezone" />
            </a>
          </div>
          {isImage && (
            <a href={url} target="_blank">
              <img
                src={url}
                alt={nameOnly}
                className="max-h-96 min-w-[150px] object-cover"
              />
            </a>
          )}

          {!isImage && (
            <DocViewer
              documents={[{ uri: url }]}
              prefetchMethod="GET"
              pluginRenderers={DocViewerRenderers}
              style={{
                width: "90vw",
                height: "80vh",
              }}
            />
          )}
        </div>
      </Popover>
    </>
  );
}
