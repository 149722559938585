import Header from "../layouts/header/header";
import { useRoutes, Outlet, useNavigate } from "react-router-dom";
import { useCheckAuth } from "../../../store/authStore";
import { useAddAlert } from "../../../store/alertStore";
import { scrollbar } from "../../../classPresets";
import TasksMain from "./tasksMain/tasksMain";
import TaskDetailWrapper from "./taskDetail/taskDetailWrapper";
import ReportEditWrapper from "./report/reportEdit/reportEditWrapper";
import AddReport from "./report/addReport";
import ReportDetailWrapper from "./report/reportDetail/reportDetailWrapper";

function Wrapper() {
  const checkAuth = useCheckAuth();

  const navigate = useNavigate();
  const addAlert = useAddAlert();

  if (!checkAuth({ permissionName: "작업열람" })) {
    addAlert({
      message: "접근 권한이 없습니다",
      type: "error",
    });
    navigate("/");
    return null;
  }

  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar} bg-white md:bg-transparent`}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <div className="px-10">
        <Header title="작업관리" />
      </div>
      <Outlet />
    </div>
  );
}

export default function Tasks() {
  let routes = useRoutes([
    {
      path: "/",
      element: <Wrapper />,
      children: [
        { path: "/", element: <TasksMain /> },
        { path: "/*", element: <TasksMain /> },
        { path: "/detail/:id/*", element: <TaskDetailWrapper /> },
        { path: "/detail/:id/addReport", element: <AddReport /> },
        {
          path: "/detail/:id/reportDetail/:reportId",
          element: <ReportDetailWrapper />,
        },
        {
          path: "/detail/:id/reportDetail/:reportId/edit",
          element: <ReportEditWrapper />,
        },
      ],
    },
  ]);

  return routes;
}
