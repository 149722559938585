import Header from "../layouts/header/header";
import { useRoutes, Outlet, useNavigate } from "react-router-dom";
import { useCheckAuth } from "../../../store/authStore";
import { useAddAlert } from "../../../store/alertStore";
import AddInventory from "./addInventory/addInventory";
import InventoryDetail from "./detail/inventoryDetail";
import { scrollbar } from "../../../classPresets";
import ListMain from "./listMain";
import { useEffect } from "react";

function Wrapper() {
  const checkAuth = useCheckAuth();

  const navigate = useNavigate();
  const addAlert = useAddAlert();

  useEffect(() => {
    if (!checkAuth({ permissionName: "재고열람" })) {
      addAlert({
        message: "접근 권한이 없습니다",
        type: "error",
      });
      navigate("/");
    }
  }, []);

  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar} md:px-10 bg-white md:bg-transparent px-3`}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <Header title="재고관리" />
      <Outlet />
    </div>
  );
}

export default function Inventory() {
  let routes = useRoutes([
    {
      path: "/",
      element: <Wrapper />,
      children: [
        { path: "/", element: <ListMain /> },
        { path: "/*", element: <ListMain /> },
        { path: "/detail/:id", element: <InventoryDetail /> },
        { path: "/add", element: <AddInventory /> },
        { path: "/add/:id", element: <AddInventory /> },
        { path: "/add/:id/:productId", element: <AddInventory /> },
      ],
    },
  ]);

  return routes;
}
