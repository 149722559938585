import CloudUpload from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import VisuallyHiddenInput from "../../../../comps/hiddenInput";
import useS3 from "../../../../hooks/useS3";
import { useState } from "react";

interface props {
  dir: string;
  small?: boolean;
  cb?: () => void;
}

type uploading = {
  index: number;
  total: number;
  currentFileName: string;
  on: boolean;
};

export default function CustomerUploadControl({ dir, small, cb }: props) {
  const { uploadFile } = useS3();

  const [uploading, setUploading] = useState<uploading>({
    index: 0,
    total: 0,
    currentFileName: "",
    on: false,
  });

  const handleFile: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const files = e.target.files;
    if (!files) {
      return;
    }

    setUploading({
      currentFileName: files[0].name,
      index: 0,
      total: files.length,
      on: true,
    });

    await (async function () {
      for (const file of files) {
        setUploading(uploading => ({
          ...uploading,
          currentFileName: file.name,
          index: uploading.index + 1,
        }));
        await uploadFile(file, `${dir}/${file.name}`, file.type);
        continue;
      }
    })();

    setUploading(uploading => ({
      ...uploading,
      on: false,
    }));
    if (cb) {
      cb();
    }
  };

  return (
    <div className="flex flex-row gap-2 items-center">
      {uploading.on && (
        <div>
          {uploading.currentFileName}
          {uploading.total > 1 &&
            ` (${uploading.index} / 
          ${uploading.total})`}
        </div>
      )}
      <Button
        startIcon={<CloudUpload />}
        variant="contained"
        sx={{
          backgroundColor: "black",
          fontWeight: 400,
        }}
        color="success"
        size={small ? "small" : "medium"}
        component="label"
      >
        <VisuallyHiddenInput type="file" onChange={handleFile} multiple />
        업로드
      </Button>
    </div>
  );
}
