import { useNavigate } from "react-router-dom";
import { customer } from "../../../../../../types/customer";
import TaskControls from "./taskControls/taskControls";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { tag } from "../../../../../../types/common";
import dayjs, { Dayjs } from "dayjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@apollo/client";
import { ADD_TASK, ADD_TASK_TAG_MANY } from "../../../../../../gqls/task";
import hasuraFilter from "../../../../../../utils/hasuraFilter";
import {
  TASK_CORE_FIELDS,
  TASK_TAG_FIELDS,
} from "../../../../../../fragments/task";
import { useAuthStore } from "../../../../../../store/authStore";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../../../classPresets";
import useAddCustomerHistory from "../../../../../../hooks/useAddCustomerHistory";
import { useTaskStore } from "../../../../../../store/taskStore";
import { inventory_with_product } from "../../../../../../types/inv";
import AddInventoryFromInstaller from "./addInventoryFromInstaller";
import useInvAction from "../../../../../../hooks/useInvActions";
import { useInvStore } from "../../../../../../store/invStore";

interface props {
  customer: customer;
}

const schema = z.object({
  description: z.string().nullable(),
  tags: z.array(z.any()),
  typeId: z.coerce.number().min(1, "고객 구분을 선택하세요"),
  companyId: z.coerce.number().min(1, "담당사를 선택하세요"),
  assignedToId: z.coerce.number().nullable().optional(),
  statusId: z.coerce.number().min(1, "고객 상태를 선택하세요"),
  date: z.coerce.date(),
  time: z.coerce.date().nullable().optional(),
  amount: z.coerce.number().nullable(),
  inv: z.any().nullable().optional(),
  invQtyId: z.coerce.number().nullable().optional(),
  qty: z.coerce.number().nullable().optional(),
  invActionId: z.coerce.number().nullable().optional(),
});

export default function AddTask({ customer }: props) {
  const navigate = useNavigate();

  const { user } = useAuthStore();
  const { types, statuses } = useTaskStore();
  const { invActions } = useInvStore();

  const defaultValues = {
    typeId: 0,
    statusId: 0,
    companyId: 9,
    assignedToId: 0,
    amount: null as null | number,
    tags: [] as Partial<tag>[],
    description: null as null | string,
    date: dayjs(),
    time: null as null | Dayjs,
    inv: null as inventory_with_product | null,
    invQtyId: 0,
    qty: 1,
    invActionId: 0,
  };
  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = methods;

  const [addTask, { loading }] = useMutation(ADD_TASK);
  const [addTags] = useMutation(ADD_TASK_TAG_MANY);
  const { addHistory } = useAddCustomerHistory();

  const inventory = useWatch({
    control,
    name: "inv",
  });

  const invQtyId = useWatch({
    control,
    name: "invQtyId",
  });

  const selectedInvQty = inventory?.qty.find(q => q.id == Number(invQtyId));

  const addInventory = useInvAction({
    inventory,
    invQty: selectedInvQty,
  });

  const onSubmit = handleSubmit(async data => {
    const {
      typeId,
      statusId,
      companyId,
      assignedToId,
      amount,
      tags,
      description,
      date,
      time,
      inv,
    } = data;

    let invHistoryId = null as number | null;

    await (async () => {
      if (inv?.id) {
        const invAction = invActions.find(a => a.id == data.invActionId);
        if (!invAction || !inventory) {
          console.log(invAction, inventory);
          return;
        }

        const id = await addInventory({
          qty: data.qty,
          customerId: customer.id,
          invAction,
          location: {
            type: "user",
            id: (inventory.userId
              ? inventory.userId
              : inventory.companyId) as number,
          },
        });

        invHistoryId = id || null;
      }
    })();

    // handle Tags
    const tagIds = [] as number[];
    await (async () => {
      tagIds.push(...tags.filter(t => t.id).map(t => t.id as number));
      const newTags = tags.filter(t => !t.id);

      if (newTags.length < 1) {
        return;
      }

      await addTags({
        variables: {
          objects: newTags,
        },
        onCompleted(data) {
          let insertedTags = (data.insert_customerTags?.returning ||
            []) as tag[];

          if (data.insert_customerTags_one) {
            insertedTags.concat(data.insert_customerTags_one);
          }

          tagIds.push(...insertedTags.map(c => c.id));
        },
        onError(error) {
          console.log(error);
        },
        update(cache, { data }) {
          let insertedTags = (data.insert_customerTags?.returning ||
            []) as tag[];

          if (data.insert_customerTags_one) {
            insertedTags.concat(data.insert_customerTags_one);
          }

          cache.modify({
            fields: {
              taskTags(existing = [], { storeFieldName }) {
                const filtered = hasuraFilter({
                  items: insertedTags,
                  name: "taskTags",
                  string: storeFieldName,
                });

                const newTagsRefs = filtered.map(sd =>
                  cache.writeFragment({
                    data: sd,
                    fragment: TASK_TAG_FIELDS,
                    fragmentName: "TaskTagFields",
                  })
                );
                return [...existing, ...newTagsRefs];
              },
            },
          });
        },
      });

      return;
    })();

    await addTask({
      variables: {
        object: {
          typeId,
          statusId,
          companyId,
          assignedToId: assignedToId ? assignedToId : undefined,
          amount,
          tagIds,
          description,
          customerId: customer.id,
          assignedById: user?.id,
          reads: [user?.id],
          date: date.toISOString(),
          time: time ? dayjs(time).format("HH:mm:ssZ") : undefined,
          invHistoryId,
        },
      },
      update(cache, { data }) {
        const task = data.insert_tasks_one;
        cache.modify({
          fields: {
            tasks(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: [task],
                name: "tasks",
                string: storeFieldName,
              });

              const newTaskRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: TASK_CORE_FIELDS,
                  fragmentName: "TaskCoreFields",
                })
              );
              return [...existing, ...newTaskRefs];
            },
          },
        });
      },
      onCompleted(data) {
        const type = types.find(t => t.id === typeId);
        const status = statuses.find(s => s.id === statusId);

        const task = data.insert_tasks_one;

        addHistory({
          customerId: customer.id,
          message: `${type?.name} 작업 등록 [${status?.name}]`,
          type: "task",
          taskId: task.id,
          link: `/tasks/detail/${task.id}`,
        });
      },
    });

    reset();
    navigate(-1);
  });

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-2 mx-3 md:mx-0" onSubmit={onSubmit}>
        {/* Header PC */}
        <div className="hidden md:flex flex-row justify-end items-center gap-2 text-white bg-gray-200 px-6 py-3 mb-3">
          <h2 className="font-normal">작업 의뢰</h2>
        </div>
        {/* Header Mobile */}
        <div className="md:hidden">
          <h2>작업 의뢰</h2>
        </div>
        {/* Fields */}
        <TaskControls />
        <AddInventoryFromInstaller customerId={customer.id} />
        {/* Footer */}
        <div className="flex flex-row justify-end gap-4 mt-4">
          <Button
            {...blackButtonOutlined}
            onClick={() => {
              navigate(-1);
            }}
          >
            취소
          </Button>
          <LoadingButton
            loading={loading}
            disabled={!isDirty}
            {...blackButtonContained}
            type="submit"
          >
            등록
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
}
