import { Controller, useFormContext } from "react-hook-form";

interface props {
  where: string;
}

export default function GstCostControl({ where }: props) {
  const { control, setValue, getValues } = useFormContext();

  const handleChange = (gst: number) => {
    const { installSubTotal, productSubTotal } = getValues();
    const supply = getValues()[`${where}Supply`];

    const subTotal = gst + Number(supply);

    if (getValues()[`${where}SubTotal`] !== subTotal) {
      setValue(`${where}SubTotal`, subTotal);
    }

    const otherSubTotal =
      where == "product" ? installSubTotal : productSubTotal;

    const total = Number(otherSubTotal) + Number(supply) + gst;
    if (getValues().total !== total) {
      setValue("total", total);
    }
  };

  return (
    <Controller
      control={control}
      name={`${where}Gst`}
      render={({ field }) => (
        <input
          {...field}
          onChange={e => {
            field.onChange(e);
            handleChange(Number(e.target.value));
          }}
          type="number"
          className="text-center bg-gray-100"
        />
      )}
    />
  );
}
