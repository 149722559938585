import { Auth } from "../../../../types/auth";
import { DELETE_AUTH } from "../../../../gqls/auth";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { cacheEvict } from "../../../../utils/cacheMethods";
import { useAuthStore } from "../../../../store/authStore";

interface props {
  auth: Auth;
}

export default function AuthDeleteControl({ auth }: props) {
  const [deleting, setDeleting] = useState(false);
  const [nameCheck, setNameCheck] = useState("");

  const [remove, { loading }] = useMutation(DELETE_AUTH);

  const { deleteAuth } = useAuthStore();

  const _delete = () => {
    remove({
      variables: {
        id: auth.id,
      },
      onCompleted() {
        setDeleting(false);
        deleteAuth(auth.id);
      },
      update(cache) {
        cacheEvict({ cache, id: auth.id, __typename: "auths" });
      },
    });
  };
  return (
    <>
      <div
        onClick={() => {
          setDeleting(true);
        }}
        className="text-red-500 cursor-pointer hover:animate-pulse"
      >
        권한 삭제
      </div>
      <Dialog
        open={deleting}
        onClose={() => {
          setDeleting(false);
        }}
      >
        <DialogTitle>권한 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            권한을 삭제할 경우 데이터의 무결성이 하향될 수 있습니다. 정말 권한을
            삭제하시려면 아래 권한의 DB 이름을 입력해주세요.
          </DialogContentText>
          <TextField
            className="shadow-md col-span-1"
            size="small"
            margin="dense"
            autoComplete="off"
            type="text"
            color="success"
            placeholder={auth.name}
            value={nameCheck}
            onChange={e => {
              setNameCheck(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setDeleting(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={auth.name !== nameCheck}
            loading={loading}
            variant="contained"
            color="error"
            onClick={_delete}
          >
            삭제
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
