import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { company } from "../../../../../../types/company";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY_BY_ID } from "../../../../../../gqls/company";

interface props {
  companyId: number;
  company: company;
  subs: company[];
}
export default function SubCompany({ companyId, company, subs }: props) {
  const navigate = useNavigate();
  const goToCompanyDetail = () => {
    navigate(`/settings/company/${company.id}`);
  };

  const [deleting, setDeleting] = useState(false);

  const [_delete, { loading }] = useMutation(UPDATE_COMPANY_BY_ID, {
    variables: {
      id: companyId,
      set: {
        subCompanies: subs.filter(c => c.id !== company.id).map(c => c.id),
      },
    },
    onCompleted() {
      setDeleting(false);
    },
  });

  return (
    <>
      <TableRow hover>
        <TableCell
          onClick={goToCompanyDetail}
          className="cursor-pointer hover:text-quezone"
        >
          {company.name}
        </TableCell>
        <TableCell>{company.description}</TableCell>
        <TableCell className="min-w-[80px]">{company.type.name}</TableCell>
        <TableCell
          className="cursor-pointer"
          onClick={() => {
            setDeleting(true);
          }}
        >
          <DeleteForeverIcon color="error" />
        </TableCell>
      </TableRow>
      <Dialog
        open={deleting}
        onClose={() => {
          setDeleting(false);
        }}
      >
        <DialogTitle>서브 회사 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            서브 회사 목록에서 삭제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setDeleting(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="error"
            onClick={() => {
              _delete();
            }}
          >
            삭제
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
