import Button from "@mui/material/Button";
import { useNotificationStore } from "../../../../store/notificationStore";
import NotificationItem from "./notification";
import { sort } from "fast-sort";
import { useAuthStore } from "../../../../store/authStore";

export default function Notifications() {
  const { notifications, resetNotification } = useNotificationStore();
  const { user } = useAuthStore();

  const notificationsNotAvailable =
    !("Notification" in window) || Notification?.permission !== "granted";
  const off = user?.pushPref == false || notificationsNotAvailable;

  const askNotificationPermission = () => {
    if ("Notification" in window) {
      Notification.requestPermission();
    }
  };

  return (
    <div className="w-[524px] max-w-full flex flex-col">
      <div className="flex flex-row items-center justify-between p-4">
        <h2>알림</h2>
        <Button
          size="small"
          color="success"
          sx={{ fontWeight: "bold" }}
          onClick={resetNotification}
        >
          모두 지우기
        </Button>
      </div>
      <hr className="border-gray-300" />
      {notifications.length == 0 && (
        <div className="p-4 text-gray-500">
          {off && (
            <>
              {notificationsNotAvailable
                ? "알림이 지원되지 않거나 권한 허용이 되지 않았습니다. 앱을 설치하신 후 권한을 허용해주세요"
                : "알림을 꺼두신 상태입니다, 프로필에서 설정을 변경하세요."}
            </>
          )}

          {!off && "알림이 없습니다"}
        </div>
      )}

      {"Notification" in window && notificationsNotAvailable && (
        <div className="flex flex-row justify-end pr-2">
          <Button
            color="success"
            size="small"
            onClick={askNotificationPermission}
          >
            알림 허용하기
          </Button>
        </div>
      )}

      {sort(notifications)
        .desc("timestamp")
        .map(notification => (
          <NotificationItem
            key={notification.messageId}
            notification={notification}
          />
        ))}
    </div>
  );
}
