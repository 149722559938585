import { useMutation, useQuery } from "@apollo/client";
// import { GET_BIZTALKS } from "../../../../gqls/biztalk";
import Button from "@mui/material/Button";
import { useInvStore } from "../../../../../store/invStore";
import { invStatus } from "../../../../../types/inv";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useEffect, useMemo, useState } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { sort } from "fast-sort";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { UPDATE_INV_STATUSES_MANY } from "../../../../../gqls/inv";
import { createPortal } from "react-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import NewInvStat from "./newInvStat";
import InvStatRow from "./invStatRow";
import { blackButtonContained } from "../../../../../classPresets";
// import BiztalkRow from "./biztalkRow";

export default function InvStats() {
  const { invStatuses, updateInvStatuses } = useInvStore();

  const [sortedInvStats, setSortedInvStats] = useState<invStatus[]>([]);

  useEffect(() => {
    setSortedInvStats(sort(invStatuses).asc("priority"));
  }, [invStatuses]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedInvStats.find(item => item.id === theActive?.id),
    [theActive, sortedInvStats]
  );

  const [touched, setTouched] = useState(false);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedInvStats.findIndex(
        ({ id }) => id === active.id
      );
      const overIndex = sortedInvStats.findIndex(({ id }) => id === over.id);
      const movedArray = arrayMove(sortedInvStats, activeIndex, overIndex);
      setSortedInvStats(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const resetSort = () => {
    setSortedInvStats(sort(sortedInvStats).asc("priority"));
    setTouched(false);
  };

  const [batchUpdate, { loading }] = useMutation(UPDATE_INV_STATUSES_MANY);

  const applySort = () => {
    const priorityMappedActions = sortedInvStats.map((status, i) => ({
      ...status,
      priority: i,
    }));

    const updates = priorityMappedActions.map(status => ({
      where: { id: { _eq: status.id } },
      _set: {
        priority: status.priority,
      },
    }));

    batchUpdate({
      variables: {
        updates,
      },
      onCompleted() {
        updateInvStatuses(priorityMappedActions);
        setSortedInvStats(priorityMappedActions);
        setTouched(false);
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <h1 className="md:mb-4">재고 상태 목록</h1>

      <div className="flex flex-col gap-2">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext
            items={sortedInvStats.map(status => status.id)}
            strategy={verticalListSortingStrategy}
          >
            {sortedInvStats.map(status => (
              <InvStatRow
                key={status.id}
                invStatus={status}
                isOverlay={false}
              />
            ))}
          </SortableContext>
          {activeItem && (
            <>
              {createPortal(
                <DragOverlay>
                  <InvStatRow invStatus={activeItem} isOverlay={true} />
                </DragOverlay>,
                document.body
              )}
            </>
          )}
        </DndContext>
      </div>

      <div className="flex flex-row justify-end items-center gap-2 mt-2">
        {touched && (
          <>
            <Button onClick={resetSort} color="success">
              취소
            </Button>
            <LoadingButton
              onClick={applySort}
              loading={loading}
              {...blackButtonContained}
            >
              저장
            </LoadingButton>
          </>
        )}
        <NewInvStat />
      </div>
    </div>
  );
}
