import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useTaskStore } from "../../../../../../../store/taskStore";
import { useEffect } from "react";
import { sort } from "fast-sort";

export default function TaskTypeControl({ disabled }: { disabled?: boolean }) {
  const { control, getValues, setValue } = useFormContext();

  let { types } = useTaskStore();

  types = sort(types).asc("priority");

  useEffect(() => {
    if (!types) {
      return;
    }
    const typeId = getValues("typeId");

    if (types.some(type => type.id === typeId)) {
      return;
    }

    setValue("typeId", types[0]?.id);
  }, [types]);

  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>작업 구분</label>
      <Controller
        name="typeId"
        control={control}
        render={({ field }) => (
          <Select
            margin="none"
            size="small"
            required
            className="shadow-md"
            color="success"
            value={field.value || 0}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled || disabled}
          >
            <MenuItem value={0}>작업 구분을 선택하세요</MenuItem>
            {sort(types)
              .asc("priority")
              .map(type => (
                <MenuItem value={type.id} key={type.id}>
                  {type.name}
                </MenuItem>
              ))}
          </Select>
        )}
      />
    </div>
  );
}
