import { Controller, useFormContext } from "react-hook-form";
import { useTaskStore } from "../../../../../store/taskStore";
import { task } from "../../../../../types/task";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { fieldWrapperEdit } from "../../../../../classPresets";

interface props {
  task: task;
}

export default function ReportTypeControl({ task }: props) {
  let { reportTypes } = useTaskStore();
  const { control } = useFormContext();

  reportTypes = reportTypes.filter(type => type.taskTypeId == task.typeId);

  return (
    <div
      className={`${fieldWrapperEdit(true)} col-span-1 gap-1 !py-2 md:!py-0`}
    >
      <label className="text-black">보고 구분</label>
      <Controller
        name="typeId"
        control={control}
        render={({ field }) => (
          <Select
            margin="none"
            size="small"
            required
            className="md:shadow-md bg-white"
            color="success"
            value={field.value || 0}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled}
          >
            <MenuItem value={0}>보고 구분을 선택하세요</MenuItem>
            {reportTypes.map(type => (
              <MenuItem value={type.id} key={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </div>
  );
}
