import { NavLink, useNavigate } from "react-router-dom";
import { extendedCustomer } from "../../../../../types/customer";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavStore } from "../../../../../store/navStore";
import CustomerInvItem from "./customerInvItem";
import { useQuery } from "@apollo/client";
import { GET_INVENTORY_CONDITIONAL } from "../../../../../gqls/inv";
import { useCheckAuth } from "../../../../../store/authStore";
import { useEffect } from "react";

interface props {
  customer: extendedCustomer;
}

export default function CustomerInventory({ customer }: props) {
  const { open } = useNavStore();

  const companyIds = [customer?.companyId || 0, customer.sourceCompanyId || 0];

  const { data, called, loading } = useQuery(GET_INVENTORY_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: customer.id,
        },
      },
    },
  });

  const inventories = data?.inventory || [];

  const checkAuth = useCheckAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !checkAuth({
        permissionName: "고객_재고정보열람",
        companyIds,
      })
    ) {
      navigate(-1);
    }
  }, []);

  return (
    <div className="flex-1 flex flex-col px-3 md:px-0 gap-4 md:mt-6">
      <div className="flex flex-row gap-y-8 gap-x-4 flex-wrap p-4 justify-evenly md:justify-normal">
        {inventories?.map(inventory => (
          <CustomerInvItem key={inventory.id} inventory={inventory} />
        ))}
        {!loading &&
          called &&
          inventories.length == 0 &&
          "할당된 재고가 없습니다"}
      </div>
      {checkAuth({ permissionName: "고객_재고추가" }) && (
        <div
          className={`${
            open && "hidden"
          } absolute bottom-3 md:bottom-5 right-3 md:right-8`}
        >
          <NavLink to={"./add"}>
            <Fab color="success" size="medium">
              <AddIcon />
            </Fab>
          </NavLink>
        </div>
      )}
    </div>
  );
}
