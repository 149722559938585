import { useCallback } from "react";
import { useAuthStore } from "../store/authStore";
import { useTaskStore } from "../store/taskStore";
import { useAddAlert } from "../store/alertStore";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_BY_ID } from "../gqls/task";
import { task } from "../types/task";
import useAddCustomerHistory from "./useAddCustomerHistory";

interface props {
  task: task;
  newStatId: number;
}
export default function useTaskStatUpdate() {
  const { statuses } = useTaskStore();
  const { user } = useAuthStore();

  const addAlert = useAddAlert();

  const alertNoAuth = () => {
    addAlert({
      message: "상태 변경을 위한 권한이 없습니다",
      type: "warning",
    });
  };

  const [updateTask, { loading }] = useMutation(UPDATE_TASK_BY_ID);

  const { addHistory } = useAddCustomerHistory();
  const updateFunc = ({ task, newStatId }: props) => {
    if (!user) {
      alertNoAuth();
      return;
    }

    const oldStatId = task.statusId;

    const userPermissionNewStat = user?.auth.taskStatusPermissions?.find(
      s => s.id == newStatId
    );
    const userPermissionOldStat = user?.auth.taskStatusPermissions?.find(
      s => s.id == oldStatId
    );

    if (user.auth.name !== "admin") {
      if (!userPermissionNewStat || !userPermissionOldStat) {
        alertNoAuth();
        return;
      }
      if (
        userPermissionNewStat?.scope !== "edit" ||
        userPermissionOldStat?.scope !== "edit"
      ) {
        alertNoAuth();
        return;
      }
      if (!user.company) {
        alertNoAuth();
        return;
      }
    }

    const set = {
      statusId: newStatId,
      reads: [user.id],
    };

    updateTask({
      variables: {
        id: task.id,
        set,
      },
      onError(error) {
        console.log(error);
      },
      optimisticResponse: {
        update_tasks_by_pk: {
          ...task,
          ...set,
          status: statuses?.find(sl => sl.id == newStatId) || task.status,
        },
      },
    });

    addHistory({
      customerId: task.customerId,
      message: `${task.type.name} 작업 상태 변경 [${task.status.name}]`,
      type: "task",
      taskId: task.id,
      link: `/customers/detail/${task.customerId}/progress/task/${task.id}`,
    });
  };

  return { updateStatus: useCallback(updateFunc, [user, statuses]), loading };
}
