import { task } from "../../../../../../types/task";
import MyBadge from "../../../../../../comps/myBadge";
import dayjs from "dayjs";
import CakeIcon from "@mui/icons-material/Cake";
import EventIcon from "@mui/icons-material/Event";
import { useAuthStore, useCheckAuth } from "../../../../../../store/authStore";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useProductStore } from "../../../../../../store/productStore";
import InventoryIcon from "@mui/icons-material/Inventory";
import { product } from "../../../../../../types/product";
import { currencyFormat } from "../../../../../../utils/numberMethods";

interface props {
  task: task;
  isKanban?: boolean;
  mobileDashboard?: boolean;
  measure?: () => void;
}

export default function ListKanbanItem({
  task,
  isKanban,
  mobileDashboard,
  measure,
}: props) {
  if (!task) {
    return null;
  }

  const { products } = useProductStore();

  const {
    customer: {
      year,
      number,
      name,
      address,
      warrantyDate,
      registered_at,
      inventories,
      subType,
    },
    created_at,
    status,
    type,
    assignedTo,
    assignedToId,
    date,
    time,
    reads,
    report,
  } = task;

  const assignedProducts = inventories?.reduce((acc, cur) => {
    if (acc.find(a => a.id == cur.productId)) {
      return acc;
    }
    const productToAdd = products.find(p => p.id == cur.productId);
    if (!productToAdd) {
      return acc;
    }
    return [...acc, productToAdd];
  }, [] as product[]);

  const { user } = useAuthStore();

  const updated =
    reads &&
    user &&
    !reads.includes(user.id) &&
    dayjs(created_at).isAfter(dayjs(user.created_at));

  const reportUpdated =
    report &&
    ((user &&
      !report.reads.includes(user.id) &&
      dayjs(created_at).isAfter(dayjs(user.created_at))) ||
      !report.checked);

  const fullNumber = `#${year}-${number}`;

  const isUnderWarranty = warrantyDate
    ? dayjs(warrantyDate).isAfter(dayjs())
    : dayjs(registered_at).diff(dayjs(), "days") < 365;

  const suspendPayment = subType?.name == "정산보류";

  const accountsTotal = (report?.accounts || []).reduce<number>(
    (acc, cur) => acc + cur.amount + cur.gst,
    0
  );

  const checkAuth = useCheckAuth();

  const canSeeAccounts = checkAuth({
    permissionName: "보고정산열람",
    userId: assignedToId,
  });

  return (
    <div
      onLoad={() => {
        measure && measure();
      }}
      className={`flex flex-col gap-1 bg-white p-3 ring-1 ring-gray-300 rounded-sm m-[1px] group hover:ring-quezone cursor-pointer relative overflow-clip ${
        mobileDashboard && "ring-transparent min-w-max overflow-visible"
      }`}
    >
      {/* updated badge */}
      {updated && !mobileDashboard && (
        <div className="absolute -right-[4px] -top-[7px] py-[7px] px-4 rounded-lg bg-quezone" />
      )}

      {/* number, type, company */}
      <div className="flex flex-row justify-between items-center gap-2">
        <div className="flex flex-row gap-2 items-center">
          <div className="font-medium">{fullNumber}</div>
          <MyBadge color={type.color} text={type.name} />
        </div>
        <div className="font-medium truncate w-0 flex-1 text-right">
          {assignedTo?.name || "작업자 미정"}
        </div>
      </div>
      <hr />
      {/* name, status, updated */}
      <div
        className={`flex flex-row items-center justify-between ${
          mobileDashboard && "min-w-[250px]"
        }`}
      >
        <h3 className="group-hover:text-quezone">{name}</h3>
        <div className="flex flex-row gap-2">
          {!isKanban && (
            <MyBadge color={status.color} text={status.name} fill />
          )}
          {!isUnderWarranty && <MyBadge text="₩" color="red" />}
        </div>
      </div>
      {/* address */}
      <div className="text-sm truncate">{address}</div>

      {/* Etcs */}

      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center gap-x-2 flex-1 truncate flex-wrap">
          {!mobileDashboard && (
            <div
              className="text-sm flex flex-row gap-1 items-center"
              title="제안날짜"
            >
              <div className="pb-[2px]">
                <CakeIcon sx={{ width: 17 }} />
              </div>
              {dayjs(created_at).format("YY.MM.DD")}
            </div>
          )}
          {!mobileDashboard && (
            <div
              className="text-sm flex flex-row gap-1 items-center"
              title="작업날짜"
            >
              <EventIcon sx={{ width: 19 }} />
              {dayjs(date).format("YY.MM.DD")}
            </div>
          )}
          {(mobileDashboard || time) && (
            <div
              className="text-sm flex flex-row gap-1 items-center"
              title="작업시간"
            >
              <AccessTimeIcon sx={{ width: 19 }} />
              {time ? dayjs(time, "HH:mm:Z").format("HH:mm") : "미상"}
            </div>
          )}
          <div
            className="text-sm flex flex-row gap-1 items-center"
            title="할당된 제품"
          >
            <InventoryIcon sx={{ width: 19 }} />
            {assignedProducts && assignedProducts.length > 0
              ? assignedProducts.map(p => p.name).join(", ")
              : "없음"}
          </div>
          {reportUpdated && !mobileDashboard && (
            <div
              className="text-sm flex flex-row gap-1 items-center"
              title="보고확인요"
            >
              <div className="pb-[2px]">
                <TipsAndUpdatesIcon
                  sx={{ width: 17 }}
                  className="text-yellow-500 animate-pulse"
                />
              </div>
              보고
            </div>
          )}
          {canSeeAccounts && accountsTotal !== 0 && (
            <div className="text-sm flex flex-row gap-1 items-center">
              <span className="text-base font-medium pt-[2px]">₩</span>
              <span className="leading-none">
                {currencyFormat(accountsTotal, true)}
              </span>
            </div>
          )}
          {suspendPayment && (
            <div className="ml-1">
              <MyBadge color={subType.color} text="정산보류" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
