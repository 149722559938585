import { useStatePersist } from "use-state-persist";
import { useInvStore } from "../store/invStore";
import { inventory, statPopulatedInvQty } from "../types/inv";
import { useQuery } from "@apollo/client";
import { GET_INV_QTY_CONDITIONAL, STREAM_INV_QTY } from "../gqls/inv";
import { useEffect } from "react";
import { cloneDeep } from "apollo-utilities";

export default function useInvQty(inv: inventory, includeAll?: boolean) {
  const where: any = {
    _and: {
      _or: [
        {
          invId: {
            _eq: inv.id,
          },
        },
        {
          subInvId: {
            _eq: inv.id,
          },
        },
      ],
    },
  };

  if (!includeAll) {
    where._and.status = {
      available: {
        _eq: true,
      },
    };
  }

  const { invStatuses } = useInvStore();

  const [cursor, setCursor] = useStatePersist(
    `@invQtyCursor:${inv.id}`,
    new Date().toISOString()
  );

  const { data, subscribeToMore } = useQuery(GET_INV_QTY_CONDITIONAL, {
    variables: {
      where,
    },
    onCompleted() {
      setCursor(new Date().toISOString());
    },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: STREAM_INV_QTY,
      variables: {
        where,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());

        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.invQty);
        const newFeedItem = subscriptionData.data.invQty_stream || [];
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];

        return Object.assign({}, previous, {
          invQty: [...newFeedItem, ...existing],
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const qty = data?.invQty;

  const populatedQty: statPopulatedInvQty[] = (qty || []).map(q => {
    const status = invStatuses.find(s => s.id == q.statusId);
    return {
      ...q,
      status,
    };
  });

  return populatedQty;
}
