import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useCustomerStore } from "../../../../store/customerStore";
import { useEffect } from "react";
import { useAuthStore } from "../../../../store/authStore";

export const CustomerTypeControlOnly = () => {
  const { control, getValues, setValue } = useFormContext();

  const { user } = useAuthStore();
  const companyTypes = user?.company?.customerTypeIds;

  let { types } = useCustomerStore();

  if (companyTypes) {
    types = types.filter(type => companyTypes.includes(type.id));
  }

  useEffect(() => {
    if (!types) {
      return;
    }
    const typeId = getValues("typeId");

    if (types.some(type => type.id === typeId)) {
      return;
    }

    setValue("typeId", types[0]?.id);
  }, [types]);

  return (
    <Controller
      name="typeId"
      control={control}
      render={({ field }) => (
        <Select
          margin="none"
          size="small"
          required
          className="shadow-md"
          color="success"
          value={field.value || 0}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={field.name}
          ref={field.ref}
          disabled={field.disabled}
        >
          <MenuItem value={0}>고객 구분을 선택하세요</MenuItem>
          {types.map(type => (
            <MenuItem value={type.id} key={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default function CustomerTypeControl() {
  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>구분</label>
      <CustomerTypeControlOnly />
    </div>
  );
}
