import { useMutation } from "@apollo/client";
import { ADD_INV_HISTORY } from "../gqls/inv";
import { invHistory } from "../types/inv";
import hasuraFilter from "../utils/hasuraFilter";
import { INV_HISTORY_FIELDS } from "../fragments/inv";
import { useAuthStore } from "../store/authStore";

export default function useAddInvHistory() {
  const [addInvHistory] = useMutation(ADD_INV_HISTORY);

  const { user } = useAuthStore();

  const addHistory = async (
    history: Omit<
      invHistory,
      "id" | "created_at" | "updated_at" | "from" | "to"
    >
  ) => {
    const { data } = await addInvHistory({
      variables: { object: { ...history, byId: user?.id } },
      update(cache, { data }) {
        const history = data.insert_invHistories_one;
        cache.modify({
          fields: {
            invHistories(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: [history],
                name: "invHistories",
                string: storeFieldName,
              });

              const newHistoryRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: INV_HISTORY_FIELDS,
                  fragmentName: "InvHistoryFields",
                })
              );
              return [...existing, ...newHistoryRefs];
            },
          },
        });
      },
    });

    return (data.insert_invHistories_one?.id as number) || null;
  };

  return addHistory;
}
