import { TypedDocumentNode, gql } from "@apollo/client";
import {
  REPORT_TYPE_FIELDS,
  REPORT_CORE_FIELDS,
  REPORT_FIELDS,
  REPORT_ACCOUNT_FIELDS,
} from "../fragments/report";
import {
  extendedReport,
  report,
  reportAccount,
  reportType,
} from "../types/report";

export const UPDATE_REPORT_MANY = gql`
  ${REPORT_CORE_FIELDS}
  mutation UPDATE_REPORT_MANY($updates: [reports_updates!]!) {
    update_reports_many(updates: $updates) {
      returning {
        ...ReportCoreFields
      }
    }
  }
`;

export const GET_REPORT_M: TypedDocumentNode<{
  reports: {
    id: number;
    uuid?: string;
  }[];
}> = gql`
  query GET_REPORT_M {
    reports {
      id
      uuid
    }
  }
`;

export const GET_REPORT_TYPES: TypedDocumentNode<{
  reportTypes: reportType[];
}> = gql`
  query GetReportTypes {
    reportTypes {
      ...ReportTypeFields
    }
  }
  ${REPORT_TYPE_FIELDS}
`;

export const UPDATE_REPORT_TYPE_BY_ID: TypedDocumentNode<{
  update_reportTypes_by_pk: reportType;
}> = gql`
  ${REPORT_TYPE_FIELDS}
  mutation UPDATE_REPORT_TYPE_BY_ID($id: Int!, $set: reportTypes_set_input!) {
    update_reportTypes_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ReportTypeFields
    }
  }
`;

export const UPDATE_REPORT_TYPES_MANY = gql`
  ${REPORT_TYPE_FIELDS}
  mutation UPDATE_REPORT_TYPES_MANY($updates: [reportTypes_updates!]!) {
    update_reportTypes_many(updates: $updates) {
      returning {
        ...ReportTypeFields
      }
    }
  }
`;

export const ADD_REPORT_TYPE = gql`
  ${REPORT_TYPE_FIELDS}
  mutation ADD_REPORT_TYPE($object: reportTypes_insert_input!) {
    insert_reportTypes_one(object: $object) {
      ...ReportTypeFields
    }
  }
`;

export const DELETE_REPORT_TYPE = gql`
  mutation DELETE_REPORT_TYPE($id: Int!) {
    delete_reportTypes_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_REPORT_BY_TASK_ID: TypedDocumentNode<{
  reports: extendedReport[];
}> = gql`
  ${REPORT_FIELDS}
  query GET_REPORT_BY_TASK_ID($taskId: Int!) {
    reports(where: { taskId: { _eq: $taskId } }) {
      ...ReportFields
    }
  }
`;

export const GET_REPORTS_BY_TASK_IDS: TypedDocumentNode<{
  reports: extendedReport[];
}> = gql`
  ${REPORT_FIELDS}
  query GET_REPORTS_BY_TASK_IDS($taskIds: [Int!]!) {
    reports(where: { taskId: { _in: $taskIds } }) {
      ...ReportFields
    }
  }
`;

export const GET_REPORT_BY_ID: TypedDocumentNode<{
  reports_by_pk: extendedReport;
}> = gql`
  ${REPORT_FIELDS}
  query GET_REPORT_BY_ID($id: Int!) {
    reports_by_pk(id: $id) {
      ...ReportFields
    }
  }
`;

export const ADD_REPORT = gql`
  ${REPORT_CORE_FIELDS}
  mutation ADD_REPORT($object: reports_insert_input!) {
    insert_reports_one(object: $object) {
      ...ReportCoreFields
    }
  }
`;

export const UPDATE_REPORT = gql`
  ${REPORT_CORE_FIELDS}
  mutation UPDATE_REPORT($id: Int!, $set: reports_set_input!) {
    update_reports_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ReportCoreFields
    }
  }
`;

export const READ_REPORT_BY_ID: TypedDocumentNode<{
  update_reports_by_pk: report;
}> = gql`
  ${REPORT_CORE_FIELDS}
  mutation READ_TASK_BY_ID($id: Int!, $userId: jsonb!) {
    update_reports_by_pk(pk_columns: { id: $id }, _append: { reads: $userId }) {
      ...ReportCoreFields
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DELETE_REPORT($id: Int!) {
    delete_reports_by_pk(id: $id) {
      id
    }
  }
`;

export const ADD_REPORT_ACCOUNT = gql`
  ${REPORT_ACCOUNT_FIELDS}
  mutation ADD_REPORT_ACCOUNT($object: reportAccounts_insert_input!) {
    insert_reportAccounts_one(object: $object) {
      ...ReportAccountFields
    }
  }
`;

export const UPDATE_REPORT_ACCOUNT = gql`
  ${REPORT_ACCOUNT_FIELDS}
  mutation UPDATE_REPORT_ACCOUNT($id: Int!, $set: reportAccounts_set_input!) {
    update_reportAccounts_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ReportAccountFields
    }
  }
`;

export const GET_REPORT_ACCOUTNS_BY_REPORT_ID: TypedDocumentNode<{
  reportAccounts: reportAccount[];
}> = gql`
  ${REPORT_ACCOUNT_FIELDS}
  query GET_REPORT_ACCOUTNS_BY_REPORT_ID($reportId: Int!) {
    reportAccounts(where: { reportId: { _eq: $reportId } }) {
      ...ReportAccountFields
    }
  }
`;

export const DELETE_REPORT_ACCOUNT = gql`
  mutation DELETE_REPORT_ACCOUNT($id: Int!) {
    delete_reportAccounts_by_pk(id: $id) {
      id
    }
  }
`;
