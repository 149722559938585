import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { company } from "../../../../../types/company";
import { TypedDocumentNode, gql, useQuery } from "@apollo/client";
import { User } from "../../../../../types/user";
import { USER_CORE_FIELDS } from "../../../../../fragments/user";
import { useNavigate } from "react-router-dom";

const GET_COMPANY_USERS: TypedDocumentNode<{ users: User[] }> = gql`
  ${USER_CORE_FIELDS}
  query getCompanyUsers($id: Int!) {
    users(where: { companyId: { _eq: $id } }) {
      ...UserCoreFields
    }
  }
`;

interface props {
  company: company;
}

export default function CompanyUsers({ company }: props) {
  const { data } = useQuery(GET_COMPANY_USERS, {
    variables: {
      id: company.id,
    },
    onError(error) {
      console.log(error);
    },
  });
  const users = data?.users || [];

  return (
    <div className="flex flex-col gap-2">
      <h1>사원 목록</h1>
      <Paper className="border-x-[1px] md:border-none">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>권한</TableCell>
              <TableCell>직함</TableCell>
              <TableCell>상태</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <Row key={user.id} user={user} />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

const Row = ({ user }: { user: User }) => {
  const navigate = useNavigate();

  // TODO: AuthCheck

  return (
    <TableRow
      hover
      className="cursor-pointer"
      onClick={() => navigate(`/settings/user/detail/${user.id}`)}
    >
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.auth.displayName}</TableCell>
      <TableCell>{user.role || "없음"}</TableCell>
      <TableCell>{user.status.name}</TableCell>
    </TableRow>
  );
};
