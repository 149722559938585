import dayjs from "dayjs";
import { useTaskStore } from "../../../../../../store/taskStore";
import { HEX, range } from "../../../../../../types/common";
import { task } from "../../../../../../types/task";
import { sort } from "fast-sort";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { toFixed } from "../../../../../../utils/numberMethods";

interface props {
  tasks: task[];
}

interface chartData {
  id: number;
  name: string;
  value: number;
  color?: HEX;
}

interface barData {
  installerId: number;
  name: string;
  count: number;
}

const countFormatter = (value: number) => `${value} 개`;

export default function Charts({ tasks }: props) {
  const { currentTab, tabs } = useTaskStore();

  const tab = tabs.find(t => t.id == currentTab);
  const filters = tab?.filters;

  const dateFilter = filters?.find(f => f.where == "date" && f.on);
  const reportDateFilter = filters?.find(f => f.where == "report.date" && f.on);

  const filter = reportDateFilter || dateFilter;

  const base = reportDateFilter
    ? "보고일기준"
    : dateFilter
    ? "작업일 기준"
    : "불러온 작업 기준";

  const range = filter?.values as range;

  const firstTask = sort(tasks).asc("date")[0];
  const lastTask = sort(tasks).desc("date")[0];

  const from = filter ? range?.from : firstTask?.date;
  const to = filter ? range?.to : lastTask?.date;

  const taskTypeStatistics =
    tasks.reduce<chartData[]>((acc, task) => {
      const { type } = task;

      if (acc.find(a => a.id == type.id)) {
        return acc.map(a => ({
          ...a,
          value: a.id == type.id ? a.value + 1 : a.value,
        }));
      }

      const newTypeData = {
        id: type.id,
        name: type.name,
        value: 1,
        color: type.color,
      };

      return acc.concat(newTypeData);
    }, []) || [];

  const installerStatistics = tasks
    .reduce<barData[]>(
      (acc, task) => {
        if (!task.assignedToId || !task.assignedTo) {
          return acc.map(a => ({
            ...a,
            count: a.installerId == 0 ? Number(a.count) + 1 : a.count,
          }));
        }
        if (acc.find(a => a.installerId == task.assignedToId)) {
          return acc.map(a => ({
            ...a,
            count:
              a.installerId == task.assignedToId
                ? Number(a.count) + 1
                : a.count,
          }));
        }
        const newInstallerData: barData = {
          installerId: task.assignedToId,
          name: task.assignedTo.name,
          count: 1,
        };

        return acc.concat(newInstallerData);
      },
      [
        {
          installerId: 0,
          name: "작업자 미정",
          count: 0,
        },
      ]
    )
    .filter(i => i.count > 0);

  // console.log(installerStatistics);

  return (
    <>
      <div className="flex flex-row justify-between gap-2 items-center flex-wrap">
        <h1>작업 통계</h1>
        <div className="text-gray-500 text-sm">{base}</div>
      </div>
      <h3>
        {dayjs(from).format("YYYY. MM. DD")} ~{" "}
        {dayjs(to).format("YYYY. MM. DD")}
      </h3>
      {/* 작업 비율 PieChart */}
      <div className="flex flex-row flex-wrap gap-4 items-start">
        <div className="min-w-[300px]">
          <PieChart
            series={[
              {
                arcLabel: item =>
                  `${toFixed((item.value / tasks.length) * 100, 1)}%`,
                data: taskTypeStatistics,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
              },
            }}
            width={300}
            height={200}
          />
        </div>
        <div className="flex flex-col gap-4 items-start py-2">
          <h3>작업 비율</h3>
          {taskTypeStatistics.map(({ id, value, color, name }) => (
            <div
              key={id}
              className="flex flex-row gap-2 items-center"
              style={{
                color: color,
              }}
            >
              <div
                className="p-2 rounded-full"
                style={{
                  backgroundColor: color,
                }}
              />
              <div className="leading-none">
                {name} {toFixed((value / tasks.length) * 100, 1)}% ({value})
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* 기사 비율 BarChart */}
      <div className="overflow-x-auto">
        <BarChart
          // @ts-expect-error
          dataset={installerStatistics}
          xAxis={[{ scaleType: "band", dataKey: "name" }]}
          series={[
            {
              dataKey: "count",
              label: "갯수",
              valueFormatter: countFormatter,
              color: "#007d25",
            },
          ]}
          width={150 * installerStatistics.length}
          height={300}
        />
      </div>
    </>
  );
}
