import { useQuery } from "@apollo/client";
import { mongoTask } from "../types";
import { GET_USERS } from "../../../../gqls/user";
import { GET_TASKS_M } from "../../../../gqls/task";

export default function useReportConvert() {
  const { data: usersData } = useQuery(GET_USERS);
  const users = usersData?.users || [];

  const { data: tasksData } = useQuery(GET_TASKS_M);
  const tasks = tasksData?.tasks || [];

  const convert = (
    task: mongoTask,
    exCustomers: { id: number; uuid: string; name: string }[]
  ) => {
    try {
      const {
        companyId,
        customer,
        date,
        description,
        statusId,
        tags,
        typeId,
        by,
        createdBy,
        // otherReason,
        proposed,
        _id,
        value,
      } = task;

      const exCustomer = exCustomers.find(c => c.uuid === customer);

      if (!exCustomer) {
        return null;
      }

      const assignedBy = users.find(user => user.email === createdBy);

      if (!assignedBy) {
        return null;
      }

      const assignedTo = users.find(user => user.email === by);

      const converted = {
        uuid: _id,
        amount: value,
        assignedById: assignedBy.id,
        assignedToId: assignedTo?.id,
        companyId,
        customerId: exCustomer.id,
        created_at: date,
        updated_at: date,
        date: proposed,
        description,
        statusId,
        typeId,
        tagIds: JSON.stringify(tags),
      };

      return converted;
    } catch (error) {
      console.log(task, error);

      return null;
    }
  };

  const convertCorrespondence = (task: mongoTask) => {
    try {
      const { createdBy, otherReason, _id, date } = task;

      if (!otherReason || otherReason == "") {
        return null;
      }

      const exTask = tasks.find(c => c.uuid === _id);

      if (!exTask) {
        return null;
      }

      const user = users.find(user => user.email === createdBy);

      if (!user) {
        return null;
      }

      return {
        date,
        content: otherReason,
        customerId: exTask.customerId,
        taskId: exTask.id,
        byId: user.id,
      };
    } catch (error) {
      console.log(task, error);

      return null;
    }
  };

  return { convert, convertCorrespondence };
}
