import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import Popover from "@mui/material/Popover";
import { otherHistory } from "../../../../types/common";
import useUserAvatar from "../../../../hooks/useUserAvatar";
import Avatar from "@mui/material/Avatar";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

interface props {
  otherHistory: otherHistory;
}
export default function OtherLog({ otherHistory }: props) {
  const { title, desc, link, company, user, created_at } = otherHistory;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { avatar } = useUserAvatar(user?.id || 0);

  return (
    <NavLink to={link ?? ""} target={target} onClick={handlePopoverClose}>
      <div
        className="flex flex-col gap-1 bg-white p-2 rounded-sm ring-1 ring-gray-300 hover:text-quezone relative"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div className="flex flex-row gap-1 items-center justify-between flex-wrap">
          <div className="font-medium">{title}</div>
          <div className="truncate flex-1 w-0 text-right">{company?.name}</div>
        </div>
        <div className="text-xs absolute right-full mr-2 top-3 text-gray-500 text-right min-w-max">
          {Math.abs(dayjs(created_at).diff(dayjs(), "minutes")) < 40
            ? dayjs(created_at).fromNow()
            : dayjs(created_at).format("HH:mm")}
        </div>
        <div className="flex flex-row gap-2 items-center font-light">
          <Avatar
            sx={{ width: 22, height: 22, bgcolor: user?.color }}
            alt={user?.name}
            src={avatar ?? ""}
          />
          <div className="text-sm flex-1 w-0 truncate">
            {user?.name || "미정"}
          </div>
        </div>
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          className="shadow-md mt-1"
        >
          <div className="flex flex-col gap-2 p-2">
            {/* Description */}
            <div className="text-sm">{desc}</div>
          </div>
        </Popover>
      </div>
    </NavLink>
  );
}
