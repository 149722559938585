import TextField from "@mui/material/TextField";
import { User } from "../../../../../types/user";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_BY_ID } from "../../../../../gqls/user";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuthStore } from "../../../../../store/authStore";

interface props {
  user: User;
}

export default function UserColorControl({ user }: props) {
  const { color } = user;
  const defaultValues = {
    color,
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const { user: currentUser, setUser } = useAuthStore();

  const [update, { loading }] = useMutation(UPDATE_USER_BY_ID);

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: user.id,
        set: data,
      },
      onCompleted: data => {
        const user = data.update_users_by_pk;
        const { color } = user;
        reset({
          color,
        });

        if (user.id == currentUser?.id) {
          setUser({
            ...currentUser,
            color,
          });
        }
      },
    });
  });

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-4">
      <TextField
        fullWidth
        className="shadow-md"
        margin="normal"
        id="color"
        label="개인 색상"
        autoComplete="color"
        color="success"
        {...register("color")}
        type="color"
        defaultValue="#ffffff"
        InputLabelProps={{
          style: {
            fontWeight: 500,
          },
        }}
      />
      {isDirty && (
        <div className="flex flex-row gap-2 items-center justify-end flex-1">
          <Button
            color="success"
            onClick={() => {
              reset();
            }}
          >
            취소
          </Button>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            color="success"
            sx={{
              backgroundColor: "black",
              fontWeight: 500,
            }}
          >
            수정
          </LoadingButton>
        </div>
      )}
    </form>
  );
}
