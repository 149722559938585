import { Link, NavLink, useNavigate } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import MyBadge from "../../../../../comps/myBadge";
import ReportImages from "./reportImages";
import dayjs from "dayjs";
import { currencyFormat } from "../../../../../utils/numberMethods";
import ReportSerials from "./reportSerials";
import ReportChecks from "./reportChecks";
import { extendedReport } from "../../../../../types/report";
import ReportAccounts from "./reportAccounts/reportAccounts";
import Launch from "@mui/icons-material/Launch";
import useS3 from "../../../../../hooks/useS3";
import { useEffect, useState } from "react";
import ReportCheckSwitch from "./reportCheckSwitch";
import { fieldWrapper } from "../../../../../classPresets";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

interface props {
  report: extendedReport;
  nested?: boolean;
  viewOnly?: boolean;
}

export default function ReportDetail({ report, nested, viewOnly }: props) {
  const task = report?.task;

  const navigate = useNavigate();
  const fullNum = `#${task?.customer.year}-${task?.customer.number}`;

  const dir = `customers/${task?.customerId}/reports/${report.id}`;

  const getPageMargins = () => {
    return `@page { 
      margin: 5mm 0mm 5mm 0mm !important;
      size: 210mm 297mm; 
      header {
        display: none;
      }
    }`;
  };

  const {
    type,
    date,
    description,
    charge,
    checks,
    serials,
    received_on_site,
    received_amount,
  } = report;

  const { getObjectsInDir, getFileURL } = useS3();

  const [signs, setSigns] = useState({
    installer: null as null | string,
    customer: null as null | string,
  });

  const getSigns = async () => {
    const res = await getObjectsInDir(`${dir}/signs`);
    if (!res || !res.Contents) {
      return;
    }
    const contents = res.Contents;

    contents.forEach(async content => {
      if (content.Key?.includes("installer.png")) {
        const url = await getFileURL(content.Key);
        setSigns(signs => ({
          ...signs,
          installer: url,
        }));
      }
      if (content.Key?.includes("customer.png")) {
        const url = await getFileURL(content.Key);
        setSigns(signs => ({
          ...signs,
          customer: url,
        }));
      }
    });
  };

  useEffect(() => {
    getSigns();
  }, []);

  return (
    <>
      <style>{getPageMargins()}</style>
      {/* Header */}
      <div className="flex flex-row justify-between items-center gap-4 flex-wrap">
        <div className="flex flex-row items-center gap-2">
          <Link to={`/tasks/detail/${report.taskId}`}>
            <h1 className="-mb-1 px-3 md:px-0 print:px-0">
              {nested ? `상세 보고서` : `${fullNum} ${task?.customer.name}`}
            </h1>
          </Link>
          {task && (
            <MyBadge text={task?.type.name} color={task?.type.color} big />
          )}
        </div>
        {!viewOnly && (
          <div
            className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ReplyIcon />
          </div>
        )}
        {viewOnly && (
          <NavLink
            to={`/tasks/detail/${report.taskId}/reportDetail/${report.id}`}
            target={target}
          >
            <div className="cursor-pointer text-gray-500 hover:text-quezone">
              <Launch />
            </div>
          </NavLink>
        )}
      </div>
      {/* Details */}
      <div
        className={`flex-1 grid grid-cols-1 print:grid-cols-2 lg:grid-cols-2 md:gap-4 print:gap-4 grid-flow-dense`}
      >
        {/* Left */}
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 print:grid-cols-2 md:gap-4 print:gap-4">
            {/* Type */}
            <div
              className={`${fieldWrapper} bg-gray-100 md:bg-transparent print:bg-transparent col-span-1 flex flex-row justify-between md:justify-normal md:flex-col print:justify-normal print:flex-col gap-1 items-start`}
            >
              <label className="text-black">보고 구분</label>
              <MyBadge color={type.color} text={type.name} big fill />
            </div>
            {/* Date */}
            <div
              className={`${fieldWrapper} col-span-1 flex flex-row justify-between md:justify-normal md:flex-col print:justify-normal print:flex-col gap-1 items-start`}
            >
              <label className="text-black">작업 날짜</label>
              <div>{dayjs(date).format("YYYY년 MM월 DD일")}</div>
            </div>
            <ReportImages dir={dir} />
          </div>
        </div>
        {/* Right */}
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 print:grid-cols-2 md:gap-4 print:gap-4">
            {/* Desc */}
            <div className="col-span-full flex flex-col gap-1 items-start">
              <label className="text-black px-3 md:px-0 pt-3 md:pt-0 print:px-0 print:pt-0">
                보고 내용
              </label>
              <div className="whitespace-pre-wrap text-sm bg-gray-100 md:bg-transparent print:bg-transparent w-full p-3 md:p-0 print:p-0">
                {description}
              </div>
            </div>
            {/* S/Ns */}
            <ReportSerials serials={serials} />
            {/* Charge */}
            <div
              className={`${fieldWrapper} col-span-full flex flex-row justify-between md:justify-normal md:flex-col print:justify-normal print:flex-col gap-1 items-start`}
            >
              <label className="text-black">추가 비용</label>
              <div>{charge ? currencyFormat(charge) : "없음"}</div>
            </div>
            {/* Received On Site */}
            {received_on_site && (
              <div
                className={`${fieldWrapper} col-span-full flex flex-row justify-between md:justify-normal md:flex-col print:justify-normal print:flex-col gap-1 items-start`}
              >
                <label className="text-black">현장 수령</label>
                <div>
                  {received_amount ? currencyFormat(received_amount) : "없음"}
                </div>
              </div>
            )}
            {/* Checks */}
            <ReportChecks
              checks={checks}
              signs={signs}
              typeId={task?.type.id || 0}
            />
          </div>
        </div>
      </div>
      {/* ReportCheckedSwitch */}
      <ReportCheckSwitch report={report} viewOnly={viewOnly} />
      {/* Accounts */}
      <ReportAccounts report={report} viewOnly={viewOnly} />
    </>
  );
}
