import { reportType } from "../../../../../../types/report";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import { useTaskStore } from "../../../../../../store/taskStore";
import { UPDATE_REPORT_TYPE_BY_ID } from "../../../../../../gqls/report";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeleteForever from "@mui/icons-material/DeleteForever";
import AddCircle from "@mui/icons-material/AddCircle";

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
  color: z.string().min(1, { message: "required" }),
  needAllChecks: z.boolean(),
  photoTemplate: z.array(z.any()).optional().nullable(),
});

interface props {
  reportType: reportType;
  mode: "editing" | "deleting" | null;
  setMode: React.Dispatch<React.SetStateAction<"editing" | "deleting" | null>>;
}

export default function ReportTypeEdit({ reportType, mode, setMode }: props) {
  const { updateReportTypes } = useTaskStore();

  const defaultPhotoTemplate =
    reportType.photoTemplate?.map(t => ({ name: t })) ||
    ([] as { name: string }[]);

  const defaultValues = { ...reportType, photoTemplate: defaultPhotoTemplate };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "photoTemplate",
    control,
  });

  const [update, { loading }] = useMutation(UPDATE_REPORT_TYPE_BY_ID);

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: reportType.id,
        set: {
          name: data.name,
          description: data.description,
          color: data.color,
          needAllChecks: data.needAllChecks,
          photoTemplate: data.photoTemplate
            .map(t => t.name)
            .filter(t => t !== ""),
        },
      },
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        const update = data.update_reportTypes_by_pk;
        if (update) {
          updateReportTypes([update]);
          setMode(null);
          reset({
            ...update,
            photoTemplate:
              update.photoTemplate?.map(t => ({ name: t })) ||
              ([] as { name: string }[]),
          });
        }
      },
    });
  });
  return (
    <Dialog
      open={mode == "editing"}
      onClose={() => {
        setMode(null);
      }}
    >
      <DialogTitle>보고 구분 수정</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.name && touchedFields.name}
            color="success"
            placeholder={
              errors.name?.message ? errors.name?.message : "구분 이름"
            }
            label="이름"
            {...register("name")}
          />
          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.description && touchedFields.description}
            color="success"
            placeholder={
              errors.description?.message ? errors.description?.message : "설명"
            }
            label="설명"
            {...register("description")}
          />
          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.color && touchedFields.color}
            color="success"
            placeholder={errors.color?.message ? errors.color?.message : "색상"}
            label="색상"
            type="color"
            defaultValue="#ffffff"
            {...register("color")}
          />
          <div className="flex flex-row gap-2 items-center justify-between">
            <label>사진 프리셋</label>
            <div
              className="cursor-pointer text-quezone"
              onClick={() => {
                append({ name: "" });
              }}
            >
              <AddCircle />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            {fields.map((f, i) => (
              <div key={f.id} className="pl-2 flex flex-row gap-1 items-center">
                -{" "}
                <input
                  {...register(`photoTemplate.${i}.name`)}
                  placeholder="프리셋 이름을 입력하세요"
                  className="outline-none border-none"
                />
                <div
                  className="cursor-pointer text-red-500"
                  onClick={() => remove(i)}
                >
                  <DeleteForever />
                </div>
              </div>
            ))}
            {fields.length == 0 && <div>없음</div>}
          </div>
          <Controller
            name="needAllChecks"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox color="success" checked={field.value} {...field} />
                }
                label="확인사항 100% 요구"
              />
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div
          className="text-red-500 cursor-pointer"
          onClick={() => setMode("deleting")}
        >
          <DeleteForever />
        </div>
        <Button
          color="success"
          onClick={() => {
            setMode(null);
          }}
        >
          취소
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={loading}
          variant="contained"
          color="success"
          onClick={onSubmit}
        >
          수정
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
