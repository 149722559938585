import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useEffect, useMemo, useState } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { sort } from "fast-sort";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useMutation } from "@apollo/client";
import { createPortal } from "react-dom";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import NewInvAction from "./newInvAction";
import InvActionRow from "./invActionRow";
import { invAction } from "../../../../../types/inv";
import { useInvStore } from "../../../../../store/invStore";
import { UPDATE_INV_ACTIONS_MANY } from "../../../../../gqls/inv";
import { blackButtonContained } from "../../../../../classPresets";

export default function InvActions() {
  const { invActions, updateInvActions } = useInvStore();
  const [sortedInvActions, setSortedInvActions] = useState<invAction[]>([]);

  useEffect(() => {
    setSortedInvActions(
      sort(invActions.filter(ia => ia.id !== 12)).asc("priority")
    );
  }, [invActions]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedInvActions.find(item => item.id === theActive?.id),
    [theActive, sortedInvActions]
  );

  const [touched, setTouched] = useState(false);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedInvActions.findIndex(
        ({ id }) => id === active.id
      );
      const overIndex = sortedInvActions.findIndex(({ id }) => id === over.id);
      const movedArray = arrayMove(sortedInvActions, activeIndex, overIndex);
      setSortedInvActions(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const resetSort = () => {
    setSortedInvActions(sort(sortedInvActions).asc("priority"));
    setTouched(false);
  };

  const [batchUpdate, { loading }] = useMutation(UPDATE_INV_ACTIONS_MANY);

  const applySort = () => {
    const priorityMappedActions = sortedInvActions.map((action, i) => ({
      ...action,
      priority: i,
    }));

    const updates = priorityMappedActions.map(action => ({
      where: { id: { _eq: action.id } },
      _set: {
        priority: action.priority,
      },
    }));

    batchUpdate({
      variables: {
        updates,
      },
      onCompleted() {
        updateInvActions(priorityMappedActions);
        setSortedInvActions(priorityMappedActions);
        setTouched(false);
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <h1 className="md:mb-4">재고 액션 목록</h1>

      <div className="flex flex-col gap-2">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext
            items={sortedInvActions.map(action => action.id)}
            strategy={verticalListSortingStrategy}
          >
            {sortedInvActions.map(action => (
              <InvActionRow
                key={action.id}
                invAction={action}
                isOverlay={false}
              />
            ))}
          </SortableContext>
          {activeItem && (
            <>
              {createPortal(
                <DragOverlay>
                  <InvActionRow invAction={activeItem} isOverlay={true} />
                </DragOverlay>,
                document.body
              )}
            </>
          )}
        </DndContext>
      </div>

      <div className="flex flex-row justify-end items-center gap-2 mt-2">
        {touched && (
          <>
            <Button onClick={resetSort} color="success">
              취소
            </Button>
            <LoadingButton
              onClick={applySort}
              loading={loading}
              {...blackButtonContained}
            >
              저장
            </LoadingButton>
          </>
        )}
        <NewInvAction />
      </div>
    </div>
  );
}
