import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  // onMessage,
  Messaging,
  // MessagePayload,
} from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDJ85eoCsh8bJ1tYA1LgCbCZyWeh2WxJxY",
  authDomain: "test-9b3d4.firebaseapp.com",
  projectId: "test-9b3d4",
  storageBucket: "test-9b3d4.appspot.com",
  messagingSenderId: "189011098844",
  appId: "1:189011098844:web:9f1daaf594cc6491040819",
  measurementId: "G-HGFMGG2S0E",
};

// Initialize Firebase
let firebaseApp;
let messaging: Messaging;

try {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
} catch (error) {
  console.log(error);
}

export const getFCMToken = async () => {
  try {
    const serviceWorker = await navigator.serviceWorker.getRegistration();

    if (!serviceWorker) {
      console.log("service worker not registered");
      return undefined;
    }

    const token = await getToken(messaging, {
      vapidKey:
        "BNlZZOMQOf9jM8GnSRWGD8ZSGBq6r9FwDWDLIZiQAVpEcE_AaBS8L_yQuJL7JCSjbeDUOYE8uTm7JRRSU4B9d2U",
      serviceWorkerRegistration: serviceWorker,
    });

    // console.log(token);
    return token;
  } catch (error) {
    console.log("token cannot be retreived", error);
    return undefined;
  }
};

export const getMyMessaging = () => {
  if (messaging) {
    return messaging;
  }
};
