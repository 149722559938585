import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import BG from "/quezone.svg";
import { useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useReset } from "../../store/authStore";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

const resetSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: "8자리 이상의 비밀번호를 등록해주세요" }),
    passwordConfirm: z.string(),
  })
  .refine(data => data.password === data.passwordConfirm, {
    message: "비밀번호가 일치하지 않습니다.",
    path: ["passwordConfirm"],
  });

export default function ResetPassword() {
  const defaultValues = {
    password: "",
    passwordConfirm: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(resetSchema),
  });

  const reset = useReset();
  const { token, email } = useParams();

  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async data => {
    const res = await reset(token || "", data.password);
    setLoading(true);
    if (res) {
      navigate("/login");
    }
    setLoading(false);
  });

  const navigate = useNavigate();

  return (
    <form onSubmit={onSubmit} className="flex flex-col pt-20 px-10">
      <img src={BG} className="max-w-[250px] m-auto mb-12" />
      {/* Email */}
      <FormControl fullWidth>
        <TextField
          className="shadow-lg"
          margin="normal"
          required
          id="id"
          label="이메일"
          autoComplete="id"
          autoFocus
          type="email"
          color="success"
          placeholder="이메일"
          value={email}
          disabled
          InputLabelProps={{
            style: {
              fontWeight: 500,
            },
          }}
        />
      </FormControl>
      {/* Password */}
      <FormControl fullWidth>
        <TextField
          className="shadow-lg"
          error={errors.password && touchedFields.password}
          margin="normal"
          required
          id="password"
          label="비밀번호"
          autoComplete="password"
          color="success"
          type="password"
          placeholder="비밀번호"
          {...register("password")}
          InputLabelProps={{
            style: {
              fontWeight: 500,
            },
          }}
        />
        <FormHelperText error={errors.password && touchedFields.password}>
          {touchedFields.password && errors.password?.message}
        </FormHelperText>
      </FormControl>
      {/* Password Confirm*/}
      <FormControl fullWidth>
        <TextField
          className="shadow-lg"
          error={errors.passwordConfirm && touchedFields.passwordConfirm}
          margin="normal"
          required
          id="passwordConfirm"
          label="비밀번호 확인"
          color="success"
          type="password"
          placeholder="비밀번호 확인"
          {...register("passwordConfirm")}
          InputLabelProps={{
            style: {
              fontWeight: 500,
            },
          }}
        />
        <FormHelperText
          error={errors.passwordConfirm && touchedFields.passwordConfirm}
        >
          {touchedFields.passwordConfirm && errors.passwordConfirm?.message}
        </FormHelperText>
      </FormControl>
      {/* Actions */}

      <LoadingButton
        type="submit"
        fullWidth
        loading={loading}
        variant="contained"
        color="success"
        sx={{
          mt: 3,
          mb: 2,
          height: "56px",
          backgroundColor: "black",
          fontWeight: 500,
        }}
      >
        비밀번호 재설정
      </LoadingButton>

      <div className="flex flex-row justify-end items-center text-sm">
        <div
          className="text-quezone font-semibold cursor-pointer"
          onClick={() => {
            navigate("/login");
          }}
        >
          로그인 화면으로 돌아가기
        </div>
      </div>
    </form>
  );
}
