import { inventory, statPopulatedInvQty } from "../../../../../types/inv";
import getContrast from "../../../../../utils/getContrast";
import { useEffect, useRef, useState } from "react";
import useInvQty from "../../../../../hooks/useInvQty";

interface props {
  inv: inventory;
  includeAll?: boolean;
  noSubInv?: boolean;
  subInvIds?: number[];
  small?: boolean;
}
export default function InvQty({
  inv,
  includeAll,
  small,
  noSubInv,
  subInvIds,
}: props) {
  const qty = useInvQty(inv, includeAll).reduce<statPopulatedInvQty[]>(
    (acc, cur) => {
      const idx = acc.findIndex(q => q.statusId == cur.statusId);
      if (cur.subInvId && noSubInv) {
        return acc;
      }

      if (cur.subInvId && subInvIds && !subInvIds?.includes(cur.subInvId)) {
        return acc;
      }
      if (idx === -1) {
        return acc.concat(cur);
      } else {
        acc[idx].qty += cur.qty;
      }

      return acc;
    },
    []
  );

  return (
    <>
      {qty?.map(qtyPerStat => {
        if (qtyPerStat.qty <= 0) {
          return null;
        }
        return (
          <QtyDisplay
            key={qtyPerStat.id}
            color={qtyPerStat.status?.color as string}
            qty={qtyPerStat.qty}
            name={qtyPerStat.status?.name as string}
            small={small}
          />
        );
      })}
    </>
  );
}

const QtyDisplay = ({
  name,
  color,
  qty,
  small,
}: {
  name: string;
  color: string;
  qty: number;
  small?: boolean;
}) => {
  const isMounted = useRef(false);

  const [flash, setFlash] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    setFlash(true);
    setTimeout(() => {
      setFlash(false);
    }, 2000);
  }, [qty]);

  return (
    <div
      className={`px-2 py-1 rounded-md text-xs md:text-sm
      ${small && "rounded-sm px-1 md:text-xs"}
       ${flash && "animate-pulse"}`}
      style={{
        backgroundColor: color,
        color: getContrast(color),
      }}
    >
      {name}: {qty}
    </div>
  );
};
