import { Controller, useForm } from "react-hook-form";
import { invStatus } from "../../../../../types/inv";
import generatePastelColor from "../../../../../utils/genPastelColor";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@apollo/client";
import {
  ADD_INV_STATUS,
  UPDATE_INV_STATUS_BY_ID,
} from "../../../../../gqls/inv";
import { INV_STATUS_FIELDS } from "../../../../../fragments/inv";
import { useInvStore } from "../../../../../store/invStore";
import DeleteForever from "@mui/icons-material/DeleteForever";

interface props {
  status?: invStatus;
  onDismiss: () => void;
  setDeleting?: () => void;
}

export default function InvStatControl({
  status,
  onDismiss,
  setDeleting,
}: props) {
  const defaultValues: Omit<invStatus, "id" | "priority"> = {
    name: status?.name || "",
    description: status?.description || "",
    color: status?.color || generatePastelColor(),
    available: status?.available == false ? false : true,
  };

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, touchedFields, isDirty },
  } = useForm({
    defaultValues,
  });

  const [insert, { loading: inserting }] = useMutation(ADD_INV_STATUS);

  const [update, { loading: updating }] = useMutation(UPDATE_INV_STATUS_BY_ID);

  const { addInvStatus, updateInvStatuses, invStatuses } = useInvStore();

  const loading = inserting || updating;

  const onSubmit = handleSubmit(data => {
    if (status) {
      update({
        variables: { id: status.id, set: data },
        onCompleted(returning) {
          reset(returning.update_invStatuses_by_pk);
          updateInvStatuses([returning.update_invStatuses_by_pk]);
          onDismiss();
        },
      });
    } else {
      insert({
        variables: { object: { ...data, priority: invStatuses.length } },
        onCompleted(inserted) {
          reset();
          addInvStatus(inserted.insert_invStatuses_one);
          onDismiss();
        },
        update(cache, { data: { insert_invStatuses_one: newInvStat } }) {
          cache.modify({
            fields: {
              invStatuses(existing = []) {
                const newInvStatRef = cache.writeFragment({
                  data: newInvStat,
                  fragment: INV_STATUS_FIELDS,
                });
                return [...existing, newInvStatRef];
              },
            },
          });
        },
      });
    }
  });

  const cancel = () => {
    reset();
    onDismiss();
  };

  return (
    <Dialog open={true} onClose={cancel}>
      <DialogTitle>재고 상태 {status ? "수정" : "추가"}</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.name && touchedFields.name}
            color="success"
            placeholder={
              errors.name?.message ? errors.name?.message : "상태 이름"
            }
            label="이름"
            {...register("name")}
          />

          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.description && touchedFields.description}
            color="success"
            placeholder={
              errors.description?.message ? errors.description?.message : "설명"
            }
            label="설명"
            {...register("description")}
          />
          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.color && touchedFields.color}
            color="success"
            placeholder={errors.color?.message ? errors.color?.message : "색상"}
            label="색상"
            type="color"
            defaultValue="#ffffff"
            {...register("color")}
          />
          <Controller
            name="available"
            control={control}
            defaultValue={status?.available}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox color="success" checked={field.value} {...field} />
                }
                label="가용한 상태"
              />
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="text-red-500 cursor-pointer" onClick={setDeleting}>
          <DeleteForever />
        </div>
        <Button color="success" onClick={cancel}>
          취소
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={loading}
          variant="contained"
          color="success"
          onClick={onSubmit}
        >
          {status ? "수정" : "추가"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
