import { useMutation } from "@apollo/client";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { READ_CUSTOMER_BY_ID } from "../../../../gqls/customer";
import { useEffect } from "react";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
import CustomerDetailHeader from "./customerDetailHeader/customerDetailHeader";
import { useNavStore } from "../../../../store/navStore";
import { extendedCustomer } from "../../../../types/customer";
import { MobileMenu, Menu } from "./customerMenues";

export default function CustomerDetailWrapper({
  customer,
}: {
  customer: extendedCustomer;
}) {
  const { user } = useAuthStore();

  const [readCustomer] = useMutation(READ_CUSTOMER_BY_ID);
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();

  const companyIds = [customer?.companyId || 0, customer.sourceCompanyId || 0];

  useEffect(() => {
    if (
      !checkAuth({
        permissionName: "고객열람",
        companyIds,
      })
    ) {
      return navigate("/customers");
    }
    if (!customer?.reads || !user) {
      return;
    }
    if (customer.reads.includes(user.id)) {
      return;
    }

    readCustomer({
      variables: {
        id: customer.id,
        userId: user.id,
      },
    });
  }, [customer?.reads, user]);

  const location = useLocation();
  const pathname = location.pathname.split("/");
  const currentPath = pathname[4] || "basic";

  const { customerMenues } = useNavStore();

  const menues = customerMenues.filter(menu => {
    const permission = checkAuth({
      permissionName: menu.permission,
      companyIds,
    });

    // console.log({ permission, menu });
    return permission;
  });

  return (
    <div className="flex-1 md:pb-5 flex flex-row md:px-10">
      {/* PC Nav*/}
      <div id="setting-nav" className="hidden md:flex md:flex-col mt-16">
        {menues.map(menu => (
          <Menu
            key={menu.path}
            currentPath={currentPath}
            path={menu.path}
            title={menu.title}
          />
        ))}
      </div>
      <div className="flex-1 md:drop-shadow-md md:bg-white overflow-x-clip md:px-8">
        {/* Basic Info Header */}
        <CustomerDetailHeader customer={customer} />
        {/* Mobile Nav*/}
        <div className="md:hidden flex flex-row gap-4 overflow-x-auto scrollbar-none pt-1 px-3">
          {menues.map(menu => (
            <MobileMenu
              key={menu.path}
              currentPath={currentPath}
              path={menu.path}
              title={menu.title == "관련파일" ? "파일" : menu.title}
            />
          ))}
        </div>
        <hr className="w-screen md:hidden" />
        <div className="flex-1 flex flex-col pt-4 md:pt-0 pb-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
