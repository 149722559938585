import { useQuery } from "@apollo/client";
import BlackHeading from "../../../../comps/blackHeading";
import { task } from "../../../../types/task";
import { GET_TASK_TAGS_BY_IDS } from "../../../../gqls/task";
import Chip from "@mui/material/Chip";
import MyBadge from "../../../../comps/myBadge";

interface props {
  task: task;
}

export default function TaskTags({ task }: props) {
  const { tagIds } = task;

  const { data } = useQuery(GET_TASK_TAGS_BY_IDS, {
    variables: {
      ids: tagIds || [],
    },
    onError(error) {
      console.log(error);
    },
  });

  const tags = data?.taskTags || [];

  return (
    <div className="flex flex-col gap-2">
      <BlackHeading title="작업 태그" />
      <div className="border-[1px] p-2 flex flex-row gap-2 items-center flex-wrap">
        {tags.length == 0 && "태그가 없습니다"}
        {tags.map(tag => (
          <MyBadge text={tag.name} color="green" key={tag.id} />
        ))}
      </div>
    </div>
  );
}
