import Header from "../layouts/header/header";
import { useRoutes, Outlet, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Companies from "./companies/companies";
import CompanyDetail from "./companies/companyDetail/companyDetail";
import NewCompany from "./companies/newCompany/newCompany";
import { useCheckAuth } from "../../../store/authStore";
import Users from "./users/users";
import UserDetail from "./users/userDetail/userDetail";
import Permissions from "./permissions/permissions";
import Auths from "./auths/auths";
import { useNavStore } from "../../../store/navStore";
import SettingPermissionWrapper from "./settingPermissionWrapper";
import Customers from "./customers/customers";
import Tasks from "./tasks/tasks";
import Inventories from "./inventories/inventories";
import NewProduct from "./inventories/products/newProduct";
import EditProduct from "./inventories/products/editProduct";
import Biztalks from "./biztalks/biztalks";
import BiztalkControl from "./biztalks/biztalkControl/biztalkControl";
import EditBiztalk from "./biztalks/editBiztalk";
import Automations from "./automations/automations";
import AutomationControl from "./automations/automationControl/automationControl";
import EditAutomation from "./automations/editAutomation";
import { scrollbar } from "../../../classPresets";

function Wrapper() {
  // TODO: AuthCheck

  const { settingMenues } = useNavStore();

  const location = useLocation();
  let pathname = location.pathname.split("/")[2];
  if (pathname == "") {
    pathname = "company";
  }

  const checkAuth = useCheckAuth();

  const filteredMenues = settingMenues.filter(menu =>
    checkAuth({ permissionName: menu.permission })
  );

  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar} md:px-10 bg-white md:bg-transparent px-3`}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <Header title="설정" />
      <div className="flex-1 pb-5 flex flex-row">
        {/* PC Nav*/}
        <div id="setting-nav" className="hidden md:flex md:flex-col mt-16">
          {filteredMenues.map(menu => (
            <Menu key={menu.path} {...menu} />
          ))}
          {checkAuth({ isPeter: true }) && (
            <Menu title="권한단위" path="permission" />
          )}
        </div>
        <div className="flex-1 md:drop-shadow-md md:bg-white overflow-x-clip">
          {/* Mobile Nav*/}
          <div className="md:hidden flex flex-row gap-4 overflow-x-auto scrollbar-none pt-4">
            {filteredMenues.map(menu => (
              <MobileMenu key={menu.path} {...menu} />
            ))}
            {checkAuth({ isPeter: true }) && (
              <MobileMenu title="권한단위" path="permission" />
            )}
          </div>
          <hr className="w-screen md:hidden" />
          <SettingPermissionWrapper>
            <Outlet />
          </SettingPermissionWrapper>
        </div>
      </div>
    </div>
  );
}

const MobileMenu = ({ title, path }: { title: string; path: string }) => {
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const isCurrent =
    pathname[2] == path ||
    (path == "company" && location.pathname == "/settings");

  return (
    <Link to={`/settings/${path}`}>
      <div
        className={`whitespace-nowrap relative py-1 ${
          isCurrent ? "text-quezone" : ""
        } `}
      >
        {title}
        {isCurrent && (
          <div className="absolute bottom-0 h-2 w-full  border-b-4 border-b-quezone" />
        )}
      </div>
    </Link>
  );
};

const Menu = ({ title, path }: { title: string; path: string }) => {
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const isCurrent =
    pathname[2] == path ||
    (path == "company" && location.pathname == "/settings");

  return (
    <Link to={`/settings/${path}`}>
      <div
        className={`flex flex-row items-center py-3 px-6 rounded-l-md relative ${
          isCurrent ? "bg-[#d4e4d9] text-quezone" : "bg-[#e1e1e1] text-white"
        }`}
      >
        {title}
        {isCurrent && (
          <div className="absolute left-0 h-12 rounded-l-md border-l-[5px] border-l-quezone" />
        )}
      </div>
    </Link>
  );
};

export default function Settings() {
  let routes = useRoutes([
    {
      path: "/",
      element: <Wrapper />,
      children: [
        { path: "/", element: <Companies /> },
        { path: "/*", element: <Companies /> },
        // Company
        { path: "/company", element: <Companies /> },
        { path: "/company/detail/:id", element: <CompanyDetail /> },
        { path: "/company/new", element: <NewCompany /> },
        // User
        { path: "/user", element: <Users /> },
        { path: "/user/detail/:id", element: <UserDetail /> },
        // Customer
        { path: "/customer", element: <Customers /> },
        // Task
        { path: "/task", element: <Tasks /> },
        // Inventories
        { path: "/inventory", element: <Inventories /> },
        { path: "/inventory/newProduct", element: <NewProduct /> },
        { path: "/inventory/editProduct/:id", element: <EditProduct /> },
        // Biztalks
        { path: "biztalk", element: <Biztalks /> },
        { path: "biztalk/new", element: <BiztalkControl /> },
        { path: "biztalk/edit/:id", element: <EditBiztalk /> },
        // Automations
        { path: "automation", element: <Automations /> },
        { path: "automation/new", element: <AutomationControl /> },
        { path: "automation/edit/:id", element: <EditAutomation /> },
        // Auths
        { path: "/auth", element: <Auths /> },
        // Permission
        { path: "/permission", element: <Permissions /> },
      ],
    },
  ]);

  return routes;
}
