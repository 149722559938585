import { company } from "../../../../types/company";
import InventoryPerLocation from "./inventoryPerLocation";
import Overview from "./overview";

export default function Statistics({ companies }: { companies: company[] }) {
  return (
    <main className="flex-1 md:py-5 md:p-8 flex flex-col gap-4 md:drop-shadow-md md:bg-white overflow-x-visible">
      <Overview companies={companies} />
      <InventoryPerLocation companies={companies} />
    </main>
  );
}
