import { useQuery } from "@apollo/client";
import { extendedCustomer } from "../../../../../../types/customer";
import { GET_INVENTORY_CONDITIONAL_WITH_QTY } from "../../../../../../gqls/inv";
import { GET_USERS_CONDITIONAL } from "../../../../../../gqls/user";
import { useCheckAuth } from "../../../../../../store/authStore";
import { useProductStore } from "../../../../../../store/productStore";
import { sort } from "fast-sort";
import { inventory, inventory_with_product } from "../../../../../../types/inv";
import { useFormContext } from "react-hook-form";
import AvailableInventoryItem from "./availableInventoryItem";

interface props {
  customer: extendedCustomer;
  inventories?: inventory[];
  setActiveStep: (num: number) => void;
}

export default function AvailableInventory({
  customer,
  setActiveStep,
  inventories,
}: props) {
  const { company } = customer;

  const availableCompanyIds = [company.id, ...(company.invLocations || [])];

  const { data: userData } = useQuery(GET_USERS_CONDITIONAL, {
    variables: {
      where: {
        companyId: {
          _in: availableCompanyIds,
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const userIds = (userData?.users || []).map(u => u.id);

  const { data } = useQuery(GET_INVENTORY_CONDITIONAL_WITH_QTY, {
    variables: {
      where: {
        _and: {
          _or: [
            {
              companyId: {
                _in: availableCompanyIds,
              },
            },
            {
              userId: {
                _in: userIds,
              },
            },
          ],
          customerId: {
            _is_null: true,
          },
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const { products } = useProductStore();

  const checkAuth = useCheckAuth();

  const availableInventories: inventory_with_product[] = (data?.inventory || [])
    .map(inv => {
      return {
        ...inv,
        product: products.find(p => p.id === inv.productId),
      };
    })
    .filter(inv =>
      checkAuth({
        permissionName: "재고할당",
        companyIds: [inv.companyId || 0],
        userId: inv.userId,
      })
    );

  const sorted = sort(availableInventories).by([
    { asc: i => i.company?.name },
    { asc: i => i.user?.name },
  ]);

  const { setValue } = useFormContext();

  const handleClick = (inv: inventory_with_product) => {
    setActiveStep(1);
    setValue("inv", inv, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <div className="flex flex-col gap-6">
      <label>할당할 재고를 선택해주세요</label>
      <div className="flex flex-row gap-y-8 gap-x-4 flex-wrap justify-evenly md:justify-normal">
        {sorted.map(inventory => (
          <AvailableInventoryItem
            key={inventory.id}
            inventory={inventory}
            customerInvs={inventories}
            handleClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
}
