import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { company } from "../../../../../../types/company";
import NewSource from "./newSource";
import { GET_COMPANY_SOURCES } from "../../../../../../gqls/source";
import { useQuery } from "@apollo/client";
import CompanyTypeSources from "./companyTypeSources";
import OtherTypeSources from "./otherTypeSources";

interface props {
  company: company;
}

export default function CompanySourceControl({ company }: props) {
  const { data: sourceData } = useQuery(GET_COMPANY_SOURCES, {
    variables: {
      id: company.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const sources = sourceData?.sources || [];

  const chainSources = sources.filter(source => source.isSourceCompany);
  const otherSources = sources.filter(source => !source.isSourceCompany);

  return (
    <div className="flex flex-col gap-2">
      <h1>접수처 설정</h1>
      <Paper className="border-x-[1px] md:border-none">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>접수처</TableCell>
              <TableCell>세부 접수처</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3}>
                <div className="text-gray-500 text-sm text-right">
                  회사 타입
                </div>
              </TableCell>
            </TableRow>
            {/* 회사 타입 접수처 */}
            {chainSources.map(source => (
              <CompanyTypeSources
                key={source.id}
                source={source}
                company={company}
              />
            ))}
            <TableRow>
              <TableCell colSpan={3}>
                <div className="text-gray-500 text-sm text-right">기타</div>
              </TableCell>
            </TableRow>
            {/* 기타 접수처 */}
            {otherSources.map(source => (
              <OtherTypeSources
                key={source.id}
                company={company}
                source={source}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>

      <NewSource company={company} />
    </div>
  );
}
