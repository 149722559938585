import Menu from "@mui/material/Menu";
import MyBadge from "../../../../../comps/myBadge";
import { useState } from "react";
import { useCustomerStore } from "../../../../../store/customerStore";
import MenuItem from "@mui/material/MenuItem";
import useCustomerStatUpdate from "../../../../../hooks/useCustomerStatUpdate";
import { customer } from "../../../../../types/customer";
import { useAuthStore } from "../../../../../store/authStore";
import { sort } from "fast-sort";

interface props {
  customer: customer;
}

export default function CustomerStatControl({ customer }: props) {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { status } = customer;

  const { user } = useAuthStore();
  let { statuses } = useCustomerStore();

  statuses = statuses.filter(stat => {
    if (stat.id == status.id) {
      return true;
    }

    if (!user) {
      return false;
    }

    if (user.auth.name !== "admin") {
      const userStatPermission = user.auth.customerStatusPermissions?.find(
        s => s.id == stat.id
      );

      if (!userStatPermission || userStatPermission.scope == "none") {
        return false;
      }

      if (user.company && !user.company.type.canHaveCustomers) {
        const companyStatPermissions = user.company.customerStatusIds;
        if (!companyStatPermissions?.includes(stat.id)) {
          return false;
        }
      }
    }

    return true;
  });

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { updateStatus } = useCustomerStatUpdate();

  const update = (statId: number) => {
    updateStatus({
      customer,
      newStatId: statId,
    });

    setOpen(false);
  };

  return (
    <div className="relative">
      <div className="cursor-pointer hover:brightness-90" onClick={openMenu}>
        <MyBadge color={status.color} text={status.name} fill />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {sort(statuses)
          .asc("priority")
          .map(stat => {
            const selected = stat.id == status.id;

            return (
              <MenuItem
                key={stat.id}
                onClick={() => update(stat.id)}
                disabled={selected}
              >
                {stat.name}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
