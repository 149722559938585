import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { company } from "../../../../../../types/company";
import { useQuery } from "@apollo/client";
import { GET_COMPANIES } from "../../../../../../gqls/company";
import NewSub from "./newSub";
import SubCompany from "./subCompany";

interface props {
  company: company;
}

export default function CompanySubControl({ company }: props) {
  const { data } = useQuery(GET_COMPANIES);

  const companies = (data?.companies || []).filter(
    c => c.id !== company.id && c.active
  );

  const subs = companies.filter(c => company.subCompanies?.includes(c.id));
  const others = companies.filter(
    c => !subs.find(s => s.id == c.id) && !c.isQuezone
  );

  return (
    <div className="flex flex-col gap-2">
      <h1>서브 회사</h1>
      <Paper className="overflow-auto">
        <Table className="min-w-[524px] border-x-[1px] md:border-none">
          <TableHead>
            <TableRow>
              <TableCell>회사명</TableCell>
              <TableCell>설명</TableCell>
              <TableCell>타입</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {subs.length == 0 && (
              <TableRow>
                <TableCell colSpan={4}>없음</TableCell>
              </TableRow>
            )}
            {subs.map(c => (
              <SubCompany
                key={c.id}
                companyId={company.id}
                company={c}
                subs={subs}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
      {others.length > 0 && (
        <NewSub companyId={company.id} subs={subs} others={others} />
      )}
    </div>
  );
}
