import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useTaskStore } from "../../../../../../../store/taskStore";
import { useEffect } from "react";
import { useAuthStore } from "../../../../../../../store/authStore";
import { sort } from "fast-sort";

export default function TaskStatusControl({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { control, getValues, setValue } = useFormContext();

  let { statuses } = useTaskStore();

  statuses = sort(statuses).asc("priority");

  const { user } = useAuthStore();

  if (user?.auth.taskStatusPermissions && user.auth.name !== "admin") {
    const statPermissions = user.auth.taskStatusPermissions
      .filter(stat => stat.scope == "edit")
      .map(stat => stat.id);
    statuses = statuses.filter(status => statPermissions.includes(status.id));
  }

  useEffect(() => {
    if (!statuses) {
      return;
    }
    const statusId = getValues("statusId");

    if (statuses.some(type => type.id === statusId)) {
      return;
    }

    setValue("statusId", statuses[0]?.id);
  }, [statuses]);

  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>작업 상태</label>
      <Controller
        name="statusId"
        control={control}
        render={({ field }) => (
          <Select
            margin="none"
            size="small"
            required
            className="shadow-md"
            color="success"
            value={field.value || 0}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled || disabled}
          >
            <MenuItem value={0}>작업 상태를 선택하세요</MenuItem>
            {statuses.map(stat => (
              <MenuItem value={stat.id} key={stat.id}>
                {stat.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </div>
  );
}
