import { useEffect, useState } from "react";
import useS3 from "../../../../../hooks/useS3";

export default function ReportImage({ image }: { image: string }) {
  const { getFileURL } = useS3();

  const [src, setSrc] = useState<string>("");

  useEffect(() => {
    getFileURL(image).then(res => {
      if (res) {
        setSrc(res);
      }
    });
  }, [image]);

  const name = image.split("/").pop()?.split(".")[0] || "";
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="col-span-1 flex flex-col items-center gap-2 cursor-default relative">
      <a href={src} target="_blank" download>
        <img
          src={src || "/no-img.png"}
          alt="no-image"
          onLoad={() => {
            setTimeout(() => setLoaded(true), 300);
          }}
          className={`w-full aspect-square object-cover rounded-md bg-gray-100 ring-1 ring-black ${
            (!src || !loaded) && "p-8"
          }`}
        />
      </a>
      <div className="text-xs text-center">{name}</div>
    </div>
  );
}
