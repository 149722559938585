import { TypedDocumentNode, gql } from "@apollo/client";
import { TASK_TYPE_CHECK_FIELDS } from "../fragments/task";
import { taskTypeCheck } from "../types/taskTypeCheck";

export const ADD_TASK_TYPE_CHECK = gql`
  ${TASK_TYPE_CHECK_FIELDS}
  mutation ADD_TASK_TYPE_CHECK($object: taskTypeChecks_insert_input!) {
    insert_taskTypeChecks_one(object: $object) {
      ...TaskTypeCheckFields
    }
  }
`;

export const DELETE_TASK_TYPE_CHECK = gql`
  mutation DELETE_TASK_TYPE_CHECK($id: Int!) {
    delete_taskTypeChecks_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_TASK_TYPE_CHECK_BY_ID: TypedDocumentNode<{
  update_taskTypeChecks_by_pk: taskTypeCheck;
}> = gql`
  ${TASK_TYPE_CHECK_FIELDS}
  mutation UPDATE_TASK_TYPE_CHECK_BY_ID(
    $id: Int!
    $set: taskTypeChecks_set_input!
  ) {
    update_taskTypeChecks_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...TaskTypeCheckFields
    }
  }
`;

export const UPDATE_TASK_TYPE_CHECKS_MANY = gql`
  ${TASK_TYPE_CHECK_FIELDS}
  mutation UPDATE_TASK_TYPE_CHECKS_MANY($updates: [taskTypeChecks_updates!]!) {
    update_taskTypeChecks_many(updates: $updates) {
      returning {
        ...TaskTypeCheckFields
      }
    }
  }
`;
