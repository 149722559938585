import { product } from "../../../../types/product";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Fragment } from "react";
import Avatar from "@mui/material/Avatar";
import PanoramaIcon from "@mui/icons-material/Panorama";
import getContrast from "../../../../utils/getContrast";
import Total from "../companyList/companyInventory/total";
import InvQty from "../companyList/companyInventory/invQty";
import MyBadge from "../../../../comps/myBadge";
import Place from "@mui/icons-material/Place";
import { inventory_populated } from "../../../../types/inv";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { company } from "../../../../types/company";

interface props {
  product: product;
  qtyPerStat: {
    qty: number;
    available: boolean;
    id: number;
    name: string;
    description?: string | undefined;
    color: `#${string}`;
    priority: number;
  }[];
  qtyPerCompany: {
    companyId: number;
    qties: {
      groupCompanyId: number;
      inventory: inventory_populated;
      id: number;
      invId: number;
      statusId: number;
      qty: number;
    }[];
  }[];
  totalQty: number;
  companies: company[];
}

export default function ProductListItemPC({
  product,
  qtyPerStat,
  qtyPerCompany,
  totalQty,
  companies,
}: props) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <TableRow hover className="cursor-pointer">
        <TableCell className="w-40">
          <Avatar
            variant="square"
            sx={{
              width: 100,
              height: 100,
              bgcolor: "#CCCCCCCC",
              borderRadius: 2,
            }}
            src={product.photo}
          >
            <PanoramaIcon />
          </Avatar>
        </TableCell>
        <TableCell>{product.name}</TableCell>
        <TableCell>{product.serial}</TableCell>
        <TableCell>
          <div className="flex flex-wrap flex-row gap-1 items-start h-full">
            {qtyPerStat.map(stat => (
              <div
                key={stat.id}
                className="px-2 py-1 rounded-md text-xs md:text-sm"
                style={{
                  backgroundColor: stat.color,
                  color: getContrast(stat.color),
                }}
              >
                {stat.name}: {stat.qty}
              </div>
            ))}
          </div>
        </TableCell>
        <TableCell>{totalQty}</TableCell>
      </TableRow>
      {qtyPerCompany.map(companyQty => {
        const company = companies.find(c => c.id == companyQty.companyId);

        const companyInventory = companyQty.qties.find(
          q => q.inventory.company
        )?.inventory;

        const userInventory = companyQty.qties.reduce<inventory_populated[]>(
          (acc, cur) => {
            if (!cur.inventory.userId) {
              return acc;
            }
            if (acc.find(a => a.userId == cur.inventory.userId)) {
              return acc;
            }
            return acc.concat(cur.inventory);
          },
          []
        );

        return (
          <Fragment key={companyQty.companyId}>
            {/* Company Inventory */}
            <TableRow
              hover
              className="cursor-pointer"
              onClick={() => {
                if (!companyInventory) {
                  return;
                }

                navigate(`/inventory/detail/${companyInventory.id}`);
              }}
            >
              <TableCell />
              <TableCell colSpan={2}>
                <div className="flex flex-row items-center gap-2">
                  <Place className="text-quezone" />
                  {company?.name}
                </div>
              </TableCell>
              <TableCell>
                <div className="flex flex-wrap flex-row gap-1 items-start h-full">
                  {companyInventory ? (
                    <InvQty inv={companyInventory} />
                  ) : (
                    <MyBadge text="재고없음" color="gray" />
                  )}
                </div>
              </TableCell>
              <TableCell>
                <Total
                  companyId={companyQty.companyId}
                  productId={product.id}
                />
              </TableCell>
            </TableRow>
            {userInventory.map(i => (
              <TableRow
                key={i.id}
                hover
                className="cursor-pointer"
                onClick={() => navigate(`/inventory/detail/${i.id}`)}
              >
                <TableCell />
                <TableCell colSpan={2}>
                  <div className="flex flex-row gap-2 items-center ml-8">
                    <PersonIcon className="text-quezone" />
                    <div className="truncate">{i.user?.name}</div>
                  </div>
                </TableCell>
                <TableCell colSpan={2}>
                  <div className="flex flex-wrap flex-row gap-1">
                    <InvQty inv={i} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Fragment>
        );
      })}
    </Fragment>
  );
}
