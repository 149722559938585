import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useProductStore } from "../../../../../store/productStore";
import ProductRow from "./productRow";
import { blackButtonContained } from "../../../../../classPresets";

export default function Products() {
  const { products } = useProductStore();

  return (
    <div className="flex flex-col gap-2">
      <h1 className="md:mb-4">제품 목록</h1>
      <div className="flex flex-col gap-4">
        {products.map(product => (
          <ProductRow key={product.id} product={product} />
        ))}
      </div>
      <div className="flex flex-row justify-end mt-2">
        <Link to="/settings/inventory/newProduct">
          <Button {...blackButtonContained}>새 제품 등록</Button>
        </Link>
      </div>
    </div>
  );
}
