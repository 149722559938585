import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { GET_USER_BY_ID } from "../../../../../gqls/user";
import ReplyIcon from "@mui/icons-material/Reply";
import UserAvatarControl from "./userAvatarControl";
import UserDetailControl from "./userDetailControl";
import UserDeleteControl from "./userDeleteControl";
import UserColorControl from "./userColorControl";
import { useEffect } from "react";
import { useAuthStore, useCheckAuth } from "../../../../../store/authStore";
import UserPasswordControl from "./userPasswordControl";

interface props {
  userId?: number;
}

export default function UserDetail({ userId }: props) {
  const { id } = useParams();

  const { data } = useQuery(GET_USER_BY_ID, {
    variables: {
      id: userId || id,
    },
  });

  const user = data?.users_by_pk;

  const navigate = useNavigate();

  const checkAuth = useCheckAuth();

  const { user: currentUser } = useAuthStore();

  useEffect(() => {
    if (!checkAuth({ permissionName: "설정_사용자열람", userId: user?.id })) {
      navigate(-1);
    }
  }, []);

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 py-4 md:p-8 w-full">
      <div className="flex flex-row justify-between items-center">
        <h1 className="font-bold">
          {user.name}
          <span className="text-gray-500 ml-2 text-base font-normal">
            #{user.id}
          </span>
        </h1>
        <div
          className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
          onClick={() => {
            navigate("/settings/user");
          }}
        >
          <ReplyIcon />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8">
        {/* Avatar */}

        <div className="col-span-1 flex flex-col gap-2">
          <UserAvatarControl user={user} />
          <UserColorControl user={user} />
          {user.id == currentUser?.id && <UserPasswordControl user={user} />}
        </div>

        <UserDetailControl user={user} />
      </div>
      {!userId && <UserDeleteControl user={user} />}
    </div>
  );
}
