import { utils, writeFile } from "xlsx";

export const uniqByProp = (prop: string) => (arr: any[]) => {
  if (!arr) {
    return arr;
  }
  return Array.from(
    arr
      .reduce(
        (acc, item) => (item && item[prop] && acc.set(item[prop], item), acc), // using map (preserves ordering)
        new Map()
      )
      .values()
  );
};

// usage (still the same):

export const uniqueById = uniqByProp("id");
export const uniqueByRef = uniqByProp("__ref");

export function put(obj: any, path: any, val: any) {
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  function stringToPath(path: any) {
    // If the path isn't a string, return it
    if (typeof path !== "string") return path;

    // Create new array
    let output: any = [];

    // Split to an array with dot notation
    path.split(".").forEach(function (item) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key);
        }
      });
    });

    return output;
  }

  // Convert the path to an array if not already
  path = stringToPath(path);

  // Cache the path length and current spot in the object
  let length = path.length;
  let current = obj;

  // Loop through the path
  path.forEach(function (key: any, index: any) {
    // Check if the assigned key should be an array
    let isArray = key.slice(-2) === "[]";

    // If so, get the true key name by removing the trailing []
    key = isArray ? key.slice(0, -2) : key;

    // If the key should be an array and isn't, create an array
    if (isArray && !Array.isArray(current[key])) {
      current[key] = [];
    }

    // If this is the last item in the loop, assign the value
    if (index === length - 1) {
      // If it's an array, push the value
      // Otherwise, assign it
      if (isArray) {
        current[key].push(val);
      } else {
        current[key] = val;
      }
    }

    // Otherwise, update the current place in the object
    else {
      // If the key doesn't exist, create it
      if (!current[key]) {
        current[key] = {};
      }

      // Update the current place in the object
      current = current[key];
    }
  });
}

export const exportData = (data: any, docName?: string) => {
  if (!data) {
    return;
  }
  //creates an empty workbook with no worksheet
  let wb = utils.book_new(), //creates a new workbook
    //coverts JSON data into a sheet
    ws = utils.json_to_sheet(data);

  utils.book_append_sheet(wb, ws, "items");
  // package and release data (`writeFile` tries to write and save an XLSX file)
  writeFile(wb, `${docName || "items"}.xlsx`);
};

export const base64ToBlob = async (base64: string) => {
  const blob = await fetch(base64).then(res => res.blob());
  return blob;
};
