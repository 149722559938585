import { Controller, useFormContext } from "react-hook-form";
import { useCheckAuth } from "../../../../store/authStore";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../../../gqls/user";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";

interface props {
  taskId?: number | null;
  simple?: boolean;
}

export default function CorrespondenceControls({ taskId, simple }: props) {
  const userLabel = taskId ? "사용자" : "상담자";
  const { data } = useQuery(GET_USERS);
  const checkAuth = useCheckAuth();

  const users = (data?.users || []).filter(user =>
    checkAuth({
      permissionName: "내역추가",
      userId: user.id,
      companyIds: [user.company?.id || 0],
    })
  );
  const { control, register } = useFormContext();

  return (
    <>
      {!simple && (
        <div className="flex flex-row items-center gap-4">
          {users && (
            <Controller
              name="byId"
              control={control}
              render={({ field }) => (
                <FormControl margin="none" size="small" className="flex-1">
                  <InputLabel sx={{ fontWeight: 500 }} color="success">
                    {userLabel}
                  </InputLabel>
                  <Select
                    variant="outlined"
                    label={userLabel}
                    required
                    className="shadow-md"
                    color="success"
                    value={field.value || 0}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    ref={field.ref}
                  >
                    <MenuItem value={0}>상담자를 선택하세요</MenuItem>
                    {users.map(user => (
                      <MenuItem value={user.id} key={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="YYYY-MM-DD"
                  slotProps={{
                    textField: {
                      size: "small",
                      color: "success",
                      variant: "outlined",
                    },
                    openPickerButton: {
                      color: "success",
                    },
                  }}
                  label="날짜"
                  className="shadow-md flex-1"
                  value={field.value}
                  onChange={e => {
                    field.onChange(e);
                    field.onBlur();
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </div>
      )}
      <TextField
        margin="none"
        size="small"
        multiline
        minRows={3}
        placeholder="내역 추가"
        {...register("content")}
        className="shadow-md"
        color="success"
      />
    </>
  );
}
