import { task } from "../../../../../../../types/task";
import dayjs from "dayjs";

interface props {
  date: Date;
  tasks: task[];
}

export default function Day({ tasks, date }: props) {
  const list = tasks
    .filter(task => dayjs(task.date).isSame(date, "date"))
    .slice(0, 3);

  const hiddenLength =
    tasks.filter(task => dayjs(task.date).isSame(date, "date")).length - 4;

  return (
    <div className="dateTile flex flex-col gap-2">
      {list.map(task => (
        <TaskItem key={task.id} task={task} />
      ))}
      {hiddenLength > 0 && (
        <div className="text-sm text-quezone">+{hiddenLength}</div>
      )}
    </div>
  );
}

const TaskItem = ({ task }: { task: task }) => {
  return (
    <div className="flex flex-row text-base font-sans font-semibold leading-tight items-center gap-1">
      <div
        className="p-1 rounded-full"
        style={{
          backgroundColor: task.type.color,
        }}
      />
      <div className="truncate flex-1 w-0">{task.customer.name}</div>
    </div>
  );
};
