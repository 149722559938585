import { useCheckAuth } from "./authStore";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import CheckListIcon from "@mui/icons-material/Checklist";
import { create } from "zustand";

export interface menu {
  path: string;
  title: string;
  icon?: {
    muiName: string;
  };
  permission: string | null;
}

type Store = {
  menues: menu[];
  settingMenues: menu[];
  customerMenues: menu[];
  containerRef: HTMLDivElement | null;
  setContainerRef: (containerRef: HTMLDivElement) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const useNavStore = create<Store>(set => ({
  menues: [
    {
      path: "dashboard",
      title: "대시보드",
      icon: DashboardOutlinedIcon,
      permission: null,
    },
    {
      path: "customers",
      title: "고객관리",
      icon: LanOutlinedIcon,
      permission: "고객열람",
    },
    {
      path: "tasks",
      title: "작업관리",
      icon: CheckListIcon,
      permission: "작업열람",
    },
    {
      path: "inventory",
      title: "재고관리",
      icon: Inventory2OutlinedIcon,
      permission: "재고열람",
    },
    {
      path: "settings",
      title: "설정",
      icon: SettingsOutlinedIcon,
      permission: "설정열람",
    },
  ],
  settingMenues: [
    {
      path: "company",
      title: "회사관련",
      permission: "설정_회사목록열람",
    },
    {
      path: "user",
      title: "유저관련",
      permission: "설정_사용자열람",
    },
    { path: "customer", title: "고객관련", permission: "설정_고객열람" },
    { path: "task", title: "작업관련", permission: "설정_작업열람" },
    { path: "inventory", title: "재고관련", permission: "설정_재고열람" },
    { path: "biztalk", title: "비즈톡", permission: "설정_권한열람" },
    { path: "automation", title: "자동화", permission: "설정_권한열람" },
    { path: "auth", title: "권한그룹", permission: "설정_권한열람" },
  ],
  customerMenues: [
    {
      path: "basic",
      title: "고객정보",
      permission: "고객열람",
    },
    {
      path: "progress",
      title: "진행상황",
      permission: "고객_진행상황열람",
    },
    {
      path: "inventory",
      title: "고객재고",
      permission: "고객_재고정보열람",
    },
    {
      path: "account",
      title: "입금정보",
      permission: "고객_입금정보열람",
    },
    {
      path: "file",
      title: "관련파일",
      permission: "고객_파일열람",
    },
  ],
  open: false,
  setOpen: open => set(state => ({ ...state, open })),
  containerRef: null,
  setContainerRef: containerRef =>
    set(state => ({
      ...state,
      containerRef,
    })),
}));

export const useMenues = () => {
  const checkAuth = useCheckAuth();

  const { menues } = useNavStore();

  return menues.filter(
    menu =>
      menu.permission == null || checkAuth({ permissionName: menu.permission })
  );
};
