import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { bizQuickReply, biztalk } from "../../../../../types/biztalk";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteForever from "@mui/icons-material/DeleteForever";

export default function QuickReplyControl() {
  const { control, register } = useFormContext();

  const defaultReply: bizQuickReply = {
    name: "",
    type: "WL",
    scheme_android: null,
    scheme_ios: null,
    url_mobile: null,
    url_pc: null,
  };

  const { fields, append, remove } = useFieldArray({
    name: "quickReplies",
    control,
  });

  const addButton = () => {
    append(defaultReply);
  };

  const typedFields = fields as FieldArrayWithId<
    Omit<biztalk, "id">,
    "quickReplies",
    "id"
  >[];

  return (
    <div className="flex flex-col gap-2 overflow-x-auto">
      <label>바로연결</label>
      <Table size="small" className="min-w-[528px]">
        <TableHead>
          <TableRow>
            <TableCell className="w-32">타입</TableCell>
            <TableCell className="w-48">이름</TableCell>
            <TableCell>링크</TableCell>
            <TableCell className="w-8"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {typedFields.map((qr, i) => {
            return (
              <TableRow key={qr.id} className="">
                <TableCell className="w-32">
                  <Controller
                    name={`quickReplies.${i}.type`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        size="small"
                        margin="dense"
                        required
                        className="shadow-md"
                        color="success"
                        defaultValue={qr.type || "WL"}
                        value={field.value || ""}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        name={field.name}
                        ref={field.ref}
                        disabled={field.disabled}
                      >
                        <MenuItem value="WL">웹링크</MenuItem>
                        <MenuItem value="AL">앱링크</MenuItem>
                        <MenuItem value="BK">텍스트 전송</MenuItem>
                        <MenuItem value="MD">본문 전송</MenuItem>
                      </Select>
                    )}
                  />
                </TableCell>
                <TableCell className="w-48">
                  <TextField
                    className="shadow-md"
                    required
                    size="small"
                    margin="dense"
                    autoComplete="off"
                    color="success"
                    placeholder="이름"
                    {...register(`quickReplies.${i}.name`)}
                  />
                </TableCell>
                <TableCell>
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row gap-4 items-center">
                      <label>URL</label>
                      <div className="flex flex-col gap-1 flex-1">
                        <input
                          className="border-b-[1px] p-[1px] placeholder:font-thin"
                          autoComplete="off"
                          placeholder="mobile URL"
                          {...register(`quickReplies.${i}.url_mobile`)}
                        />
                        <input
                          className="border-b-[1px] p-[1px] placeholder:font-thin"
                          autoComplete="off"
                          placeholder="PC URL"
                          {...register(`quickReplies.${i}.url_pc`)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                      <label>APP</label>
                      <div className="flex flex-col gap-1 flex-1">
                        <input
                          className="border-b-[1px] p-[1px] placeholder:font-thin"
                          autoComplete="off"
                          placeholder="android app scheme"
                          {...register(`quickReplies.${i}.scheme_android`)}
                        />
                        <input
                          className="border-b-[1px] p-[1px] placeholder:font-thin"
                          autoComplete="off"
                          placeholder="ios app scheme"
                          {...register(`quickReplies.${i}.scheme_ios`)}
                        />
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    className="text-red-500 cursor-pointer"
                    onClick={() => remove(i)}
                  >
                    <DeleteForever />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Button
        size="small"
        color="success"
        onClick={addButton}
        startIcon={<AddCircle />}
      >
        <span className="pt-[2px]">연결 추가</span>
      </Button>
    </div>
  );
}
