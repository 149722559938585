import TaskStatuses from "./taskStatuses/taskStatuses";
import TaskTags from "./taskTags/taskTags";
import TaskTypes from "./taskTypes/taskTypes";

export default function Tasks() {
  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <TaskStatuses />
      <TaskTypes />
      <TaskTags />
    </div>
  );
}
