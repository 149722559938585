import { Controller, useFormContext } from "react-hook-form";
import { toFixed } from "../../../../../../utils/numberMethods";

interface props {
  where: string;
}

export default function SupplyCostControl({ where }: props) {
  const { control, setValue, getValues } = useFormContext();

  const handleChange = (supply: number) => {
    const gst = toFixed(Math.round(supply / 10), 0);

    if (Math.abs(getValues()[`${where}Gst`] - gst) > 1) {
      setValue(`${where}Gst`, gst);
    }

    const { installSubTotal, productSubTotal } = getValues();
    const mySubTotal = gst + supply;

    if (getValues()[`${where}SubTotal`] !== mySubTotal) {
      setValue(`${where}SubTotal`, gst + supply);
    }

    const otherSubTotal =
      where == "product" ? installSubTotal : productSubTotal;

    const total = Number(otherSubTotal) + Number(gst) + Number(supply);

    if (getValues().total !== total) {
      setValue("total", total);
    }
  };

  return (
    <Controller
      control={control}
      name={`${where}Supply`}
      render={({ field }) => (
        <input
          {...field}
          onChange={e => {
            field.onChange(e);
            handleChange(Number(e.target.value));
          }}
          type="number"
          className="text-center bg-gray-100"
        />
      )}
    />
  );
}
