import { useCustomerStore } from "../../../../../store/customerStore";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useEffect, useMemo, useState } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { sort } from "fast-sort";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { typeOrStatus } from "../../../../../types/common";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_STATUSES_MANY } from "../../../../../gqls/customer";
import CustomerStatRow from "./customerStatRow";
import { createPortal } from "react-dom";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import NewCustomerStat from "./newCustomerStat";
import { blackButtonContained } from "../../../../../classPresets";

export default function CustomerStatuses() {
  const { statuses, updateStatuses } = useCustomerStore();
  const [sortedStats, setSortedStats] = useState<typeOrStatus[]>([]);

  useEffect(() => {
    setSortedStats(sort(statuses).asc("priority"));
  }, [statuses]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedStats.find(item => item.id === theActive?.id),
    [theActive, sortedStats]
  );

  const [touched, setTouched] = useState(false);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedStats.findIndex(({ id }) => id === active.id);
      const overIndex = sortedStats.findIndex(({ id }) => id === over.id);
      const movedArray = arrayMove(sortedStats, activeIndex, overIndex);
      setSortedStats(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const resetSort = () => {
    setSortedStats(sort(sortedStats).asc("priority"));
    setTouched(false);
  };

  const [batchUpdate, { loading }] = useMutation(UPDATE_CUSTOMER_STATUSES_MANY);

  const applySort = () => {
    const priorityMappedStats = sortedStats.map((stat, i) => ({
      ...stat,
      priority: i,
    }));

    const updates = priorityMappedStats.map(stat => ({
      where: { id: { _eq: stat.id } },
      _set: {
        priority: stat.priority,
      },
    }));

    batchUpdate({
      variables: {
        updates,
      },
      onCompleted() {
        updateStatuses(priorityMappedStats);
        setSortedStats(priorityMappedStats);
        setTouched(false);
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <h1 className="md:mb-4">고객 상태 목록</h1>

      <div className="flex flex-col gap-2">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext
            items={sortedStats.map(stat => stat.id)}
            strategy={verticalListSortingStrategy}
          >
            {sortedStats.map(stat => (
              <CustomerStatRow key={stat.id} status={stat} isOverlay={false} />
            ))}
          </SortableContext>
          {activeItem && (
            <>
              {createPortal(
                <DragOverlay>
                  <CustomerStatRow status={activeItem} isOverlay={true} />
                </DragOverlay>,
                document.body
              )}
            </>
          )}
        </DndContext>
      </div>

      <div className="flex flex-row justify-end items-center gap-2 mt-2">
        {touched && (
          <>
            <Button onClick={resetSort} color="success">
              취소
            </Button>
            <LoadingButton
              onClick={applySort}
              loading={loading}
              {...blackButtonContained}
            >
              저장
            </LoadingButton>
          </>
        )}
        <NewCustomerStat />
      </div>
    </div>
  );
}
