import ReplyIcon from "@mui/icons-material/Reply";
import TextField from "@mui/material/TextField";
import { FormProvider, useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import NumberControl from "./numberControl";
import CustomerTypeControl from "../commonControls/customerTypeControl";
import CustomerCompanyControl from "./customerCompanyControl";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
import { useEffect, useState } from "react";
import { useCustomerStore } from "../../../../store/customerStore";
import SourceControl from "../commonControls/sourceControl";
import SourceDetailControl from "../commonControls/sourceDetailControl";
import CustomerStatusControl from "./customerStatusControl";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomerContacts from "../commonControls/customerContacts";
import { contact } from "../../../../types/contact";
import CustomerTagControl from "../commonControls/customerTagControl";
import { tag } from "../../../../types/common";
import CustomerAddressControl from "../commonControls/customerAddressControl";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_TAG_MANY,
  GET_CUSTOMERS_CONDITIONAL,
  GET_CUSTOMER_LAST_NUM,
} from "../../../../gqls/customer";
import { ADD_CONTACT_MANY } from "../../../../gqls/contact";
import hasuraFilter from "../../../../utils/hasuraFilter";
import { CONTACT_CORE_FIELDS } from "../../../../fragments/contact";
import {
  CUSTOMER_CORE_FIELDS,
  CUSTOMER_TAG_FIELDS,
} from "../../../../fragments/customer";
import { useAddAlert } from "../../../../store/alertStore";
import CustomerRefereeControl from "../commonControls/customerRefereeControl";
import useAddCustomerHistory from "../../../../hooks/useAddCustomerHistory";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../classPresets";
import CustomerSubTypeControl from "../commonControls/customerSubTypeControl";

const schema = z
  .object({
    name: z.string().min(1, "고객 이름을 입력하세요"),
    address: z.string().min(1, "고객 주소를 입력하세요"),
    postCode: z.coerce.number().nullable().optional(),
    description: z.string().nullable(),
    contacts: z.array(z.any()),
    newContacts: z.array(z.any()),
    year: z.coerce.number(),
    number: z.coerce.number(),
    tags: z.array(z.any()),
    typeId: z.coerce.number().min(1, "고객 구분을 선택하세요"),
    subTypeId: z.coerce.number().nullable().optional(),
    companyId: z.coerce.number().min(1, "담당사를 선택하세요"),
    statusId: z.coerce.number().min(1, "고객 상태를 선택하세요"),
    sourceId: z.coerce.number().min(1, "접수처를 선택하세요"),
    sourceDetailId: z.coerce.number(),
    sourceCompanyId: z.coerce.number(),
    refereeId: z.coerce.number().nullable().optional(),
  })
  .refine(data => data.sourceDetailId || data.sourceCompanyId, {
    message: "세부 접수처를 선택하세요",
    path: ["sourceId"],
  })
  .refine(data => data.contacts.length + data.newContacts.length > 0, {
    message: "고객 연락처를 입력하세요",
    path: ["contacts"],
  });
export default function AddCustomer() {
  const { user } = useAuthStore();

  const checkAuth = useCheckAuth();

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/customers");
  };

  useEffect(() => {
    !checkAuth({
      permissionName: "고객추가",
    }) && goBack();
  }, []);

  const { types } = useCustomerStore();

  const defaultValues = {
    name: "",
    address: "",
    postCode: "",
    description: null as null | string,
    contacts: [] as contact[],
    newContacts: [
      {
        name: "",
        number: "",
      },
    ] as Omit<contact, "id">[],
    year: new Date().getFullYear() - 2000,
    number: 0,
    tags: [] as Partial<tag>[],
    typeId: types[0]?.id,
    subTypeId: null as null | number,
    companyId: 0,
    statusId: 0,
    sourceId: 0,
    sourceCompanyId: null as null | number,
    sourceDetailId: null as null | number,
    refereeId: null as null | number,
  };

  const methods = useForm({ defaultValues, resolver: zodResolver(schema) });

  const [getLastNum] = useLazyQuery(GET_CUSTOMER_LAST_NUM, {
    fetchPolicy: "network-only",
  });

  const [getCustomers] = useLazyQuery(GET_CUSTOMERS_CONDITIONAL, {
    fetchPolicy: "network-only",
  });

  const [addContacts] = useMutation(ADD_CONTACT_MANY);
  const [addTags] = useMutation(ADD_CUSTOMER_TAG_MANY);
  const [addCustomer] = useMutation(ADD_CUSTOMER);

  const [loading, setLoading] = useState(false);

  const addAlert = useAddAlert();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, touchedFields },
  } = methods;

  const { addHistory } = useAddCustomerHistory();

  const onSubmit = handleSubmit(async data => {
    setLoading(true);

    const {
      typeId,
      subTypeId,
      year,
      address,
      companyId,
      description,
      name,
      postCode,
      sourceCompanyId,
      sourceDetailId,
      sourceId,
      statusId,
      refereeId,
    } = data;

    const resLastNum = await getLastNum({
      variables: { year },
      onError(error) {
        console.log(error);
      },
    });
    const number = Number(resLastNum.data?.customers[0]?.number || 0) + 1;

    // handle Contacts
    const contactIds = [];
    if (data.contacts) {
      contactIds.push(...data.contacts.map(c => c.id));
    }
    await (async () => {
      if (!data.newContacts) {
        return;
      }

      const newContacts = data.newContacts.filter(c => c.name.trim() !== "");

      if (newContacts.length == 0) {
        return;
      }

      await addContacts({
        variables: {
          objects: newContacts,
        },
        onCompleted(data) {
          let insertedContacts = (data.insert_contacts?.returning ||
            []) as contact[];

          if (data.insert_contacts_one) {
            insertedContacts.concat(data.insert_contacts_one);
          }

          contactIds.push(...insertedContacts.map(c => c.id));
        },
        onError(error) {
          console.log(error);
        },
        update(cache, { data }) {
          let insertedContacts = (data.insert_contacts?.returning ||
            []) as contact[];

          if (data.insert_contacts_one) {
            insertedContacts.concat(data.insert_contacts_one);
          }

          cache.modify({
            fields: {
              contacts(existing = [], { storeFieldName }) {
                const filtered = hasuraFilter({
                  items: insertedContacts,
                  name: "contacts",
                  string: storeFieldName,
                });

                const newContactsRefs = filtered.map(sd =>
                  cache.writeFragment({
                    data: sd,
                    fragment: CONTACT_CORE_FIELDS,
                    fragmentName: "ContactCoreFields",
                  })
                );
                return [...existing, ...newContactsRefs];
              },
            },
          });
        },
      });

      return;
    })();

    // check exCustomer

    const { data: exCustomersData } = await getCustomers({
      variables: {
        where: {
          name: {
            _eq: name,
          },
          contactIds: {
            _contains: contactIds,
          },
        },
      },
    });

    if (exCustomersData?.customers && exCustomersData?.customers.length > 0) {
      addAlert({
        message: "이미 존재하는 고객입니다",
        type: "error",
      });
      setLoading(false);
      return;
    }

    // handle Tags
    const tagIds = [] as number[];
    await (async () => {
      tagIds.push(...data.tags.filter(t => t.id).map(t => t.id as number));
      const newTags = data.tags.filter(t => !t.id);

      if (newTags.length < 1) {
        return;
      }

      await addTags({
        variables: {
          objects: newTags,
        },
        onCompleted(data) {
          let insertedTags = (data.insert_customerTags?.returning ||
            []) as tag[];

          if (data.insert_customerTags_one) {
            insertedTags.concat(data.insert_customerTags_one);
          }

          tagIds.push(...insertedTags.map(c => c.id));
        },
        onError(error) {
          console.log(error);
        },
        update(cache, { data }) {
          let insertedTags = (data.insert_customerTags?.returning ||
            []) as tag[];

          if (data.insert_customerTags_one) {
            insertedTags.concat(data.insert_customerTags_one);
          }

          cache.modify({
            fields: {
              customerTags(existing = [], { storeFieldName }) {
                const filtered = hasuraFilter({
                  items: insertedTags,
                  name: "customerTags",
                  string: storeFieldName,
                });

                const newTagsRefs = filtered.map(sd =>
                  cache.writeFragment({
                    data: sd,
                    fragment: CUSTOMER_TAG_FIELDS,
                    fragmentName: "CustomerTagFields",
                  })
                );
                return [...existing, ...newTagsRefs];
              },
            },
          });
        },
      });

      return;
    })();

    // addCustomer
    const { data: insertedCustomerData } = await addCustomer({
      variables: {
        object: {
          typeId,
          subTypeId: subTypeId || undefined,
          address,
          companyId,
          description,
          year,
          name,
          number,
          postCode,
          sourceId,
          sourceCompanyId: sourceCompanyId || undefined,
          sourceDetailId,
          statusId,
          tagIds,
          contactIds,
          reads: [user?.id],
          createdById: user?.id,
          refereeId: refereeId || undefined,
        },
      },
      update(cache, { data }) {
        const customer = data.insert_customers_one;

        cache.modify({
          fields: {
            customers(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: [customer],
                name: "customers",
                string: storeFieldName,
              });

              const newCustomerRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: CUSTOMER_CORE_FIELDS,
                  fragmentName: "CustomerCoreFields",
                })
              );
              return [...existing, ...newCustomerRefs];
            },
          },
        });
      },
    });

    await addHistory({
      customerId: insertedCustomerData.insert_customers_one.id,
      type: "registration",
      message: "등록",
      statusId,
    });

    setLoading(false);
    addAlert({
      message: "고객을 성공적으로 등록하였습니다",
      type: "success",
    });
    reset();
    navigate(
      "/customers/detail/" + insertedCustomerData.insert_customers_one.id
    );
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className="flex-1 py-4 px-3 md:p-8 md:mx-10 flex flex-col md:drop-shadow-md md:bg-white overflow-x-clip gap-4"
      >
        {/* Header */}
        <div className="flex flex-row justify-between items-center">
          <h1 className="text-xl md:text-2xl">고객 등록</h1>
          <div
            className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
            onClick={goBack}
          >
            <ReplyIcon />
          </div>
        </div>
        {/* Fields */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left */}
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Type */}
              <CustomerTypeControl />
              {/* Number */}
              <NumberControl />
              {/* Name */}
              <div className="col-span-1 flex flex-col gap-1">
                <label>고객 이름</label>
                <TextField
                  margin="none"
                  size="small"
                  color="success"
                  placeholder="고객 이름"
                  error={
                    touchedFields.name && errors.name?.message ? true : false
                  }
                  className="shadow-md"
                  {...register("name")}
                />
              </div>
              {/* Company */}
              <CustomerCompanyControl />
              {/* Source */}
              <SourceControl />
              {/* SourceControl */}
              <SourceDetailControl />
              {/* Address */}
              <CustomerAddressControl />
              {/* Status */}
              <CustomerStatusControl />
              {/* SubType */}
              <CustomerSubTypeControl />
            </div>
          </div>
          {/* Right */}
          <div className="flex flex-col gap-4">
            <CustomerContacts />
            <CustomerRefereeControl />
            <div className="flex flex-col gap-1">
              <label>비고</label>
              <TextField
                margin="none"
                size="small"
                color="success"
                multiline
                minRows={3}
                className="shadow-md"
                {...register("description")}
              />
            </div>
            <CustomerTagControl />
          </div>
        </div>
        {/* Footer */}
        <div className="flex flex-row justify-between gap-4 items-center flex-wrap">
          <NavLink to="./batch">
            <Button {...blackButtonOutlined}>일괄 등록</Button>
          </NavLink>
          <div className="flex flex-row justify-end gap-4">
            <Button {...blackButtonOutlined} onClick={goBack}>
              취소
            </Button>
            <LoadingButton
              loading={loading}
              disabled={!isDirty}
              {...blackButtonContained}
              type="submit"
            >
              등록
            </LoadingButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
