import { z } from "zod";
import { source } from "./source";

export interface company {
  id: number;
  isQuezone: boolean;
  name: string;
  description?: string;
  email?: string;
  phone?: string;
  address?: string;
  registration?: string;
  subCompanies?: number[];
  typeId: number;
  type: companyType;
  customerTypeIds?: number[];
  customerStatusIds?: number[];
  invLocations?: number[];
  noUsers: boolean;
  created_at: string;
  updated_at: string;
  sources?: source[];
  users: {
    id: number;
    name: string;
  }[];
  active: boolean;
}

export interface companyType {
  id: number;
  name: string;
  description?: string;
  icon?: string;
  canHaveCustomers: boolean;
  isChain: boolean;
  isInstallAgency: boolean;
}

export const basicCompanySchema = z.object({
  name: z.string().min(1, { message: "이름을 입력해세요" }),
  description: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  registration: z.string().nullable().optional(),
  typeId: z.coerce.number(),
  noUsers: z.boolean(),
});
