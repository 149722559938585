import Button from "@mui/material/Button";
import { currencyFormat } from "../../../../../../utils/numberMethods";
import {
  customerPayment,
  customerPrice,
} from "../../../../../../types/customer";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import { modal } from "../../../../../../classPresets";
import AddPayment from "./addPayment";
import { useCheckAuth } from "../../../../../../store/authStore";

interface props {
  payments: customerPayment[];
  customerId: number;
  companyIds: number[];
  price?: customerPrice;
}

export default function CustomerBalance({
  payments,
  customerId,
  price,
  companyIds,
}: props) {
  const balance = payments.reduce((acc, cur) => {
    return acc + cur.amount;
  }, 0);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const totalPrice =
    price &&
    price.installGst +
      price.installSupply +
      price.productGst +
      price.productSupply;

  const diff = totalPrice ? totalPrice - balance : 0;

  const checkAuth = useCheckAuth();

  const canAdd = checkAuth({
    permissionName: "고객_거래내역추가",
    companyIds,
  });

  return (
    <div className="flex flex-col">
      <div className="p-4 shadow-md flex flex-col gap-6">
        <h3>입금된 총 금액</h3>
        <div className="flex flex-row gap-4 justify-between items-center px-5 md:px-10 flex-wrap">
          <h1 className="text-quezone font-bold">{currencyFormat(balance)}</h1>
          {canAdd && (
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              color="success"
              sx={{
                padding: "5px 40px",
                fontWeight: 300,
                fontSize: "16px",
                borderRadius: "30px",
              }}
            >
              내역추가
            </Button>
          )}
        </div>

        <Modal open={open} onClose={handleClose}>
          <div className={`${modal}`}>
            <AddPayment cb={handleClose} customerId={customerId} />
          </div>
        </Modal>
      </div>
      {totalPrice ? (
        <div className="flex flex-row justify-end h-4 pt-2">
          {`차액: ${currencyFormat(diff)}`}
        </div>
      ) : null}
    </div>
  );
}
