import dayjs from "dayjs";
import { customerPayment } from "../../../../../../types/customer";
import { currencyFormat } from "../../../../../../utils/numberMethods";
import CreditCard from "@mui/icons-material/CreditCard";
import LocalAtm from "@mui/icons-material/LocalAtm";
import EditPayment from "./editPayment";
import { modal } from "../../../../../../classPresets";
import { useState } from "react";
import { useCheckAuth } from "../../../../../../store/authStore";
import Modal from "@mui/material/Modal";

interface props {
  payment: customerPayment;
  companyIds: number[];
}

export default function Payment({ payment, companyIds }: props) {
  const { received_at, amount, enteredBy, method, comment } = payment;

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const checkAuth = useCheckAuth();

  const handleOpen = () => {
    if (checkAuth({ permissionName: "고객_거래내역수정", companyIds })) {
      setOpen(true);
    }
  };

  return (
    <div className="flex flex-col">
      {/* Header */}
      <div
        className="flex flex-row justify-between text-sm items-center bg-lightGreen px-4 py-2 hover:brightness-90 cursor-pointer"
        onClick={handleOpen}
      >
        <div>{dayjs(received_at).format("YYYY. MM. DD")}</div>
        <div className={`font-medium ${amount < 0 && "text-red-500"}`}>
          {method?.icon == "CreditCard" ? <CreditCard /> : <LocalAtm />}
          {currencyFormat(amount).replace("₩", "")}
        </div>
      </div>
      {/* Body */}
      <div className="px-4 py-2 flex flex-col text-sm">
        <div className="whitespace-pre-wrap">
          {comment ? `${comment}` : "비고 없음"}
        </div>
        <div className="text-right">{enteredBy?.name}</div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <div className={`${modal} `}>
          <EditPayment cb={handleClose} payment={payment} />
        </div>
      </Modal>
    </div>
  );
}
