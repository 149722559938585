import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import { useCustomerStore } from "../../../../../store/customerStore";
import { ADD_CUSTOMER_TYPE } from "../../../../../gqls/customer";
import { CUSTOMER_TYPE_FIELDS } from "../../../../../fragments/customer";
import generatePastelColor from "../../../../../utils/genPastelColor";
import { blackButtonContained } from "../../../../../classPresets";

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
  color: z.string().min(1, { message: "required" }),
});

export default function NewCustomerType() {
  const [adding, setAdding] = useState(false);

  const { types, addType } = useCustomerStore();

  const defaultValues = {
    name: "",
    description: "",
    color: generatePastelColor(),
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [insert, { loading }] = useMutation(ADD_CUSTOMER_TYPE);

  const onSubmit = handleSubmit(data => {
    insert({
      variables: {
        object: {
          ...data,
          priority: types.length,
        },
      },
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        addType(data.insert_customerTypes_one);
        setAdding(false);
        reset({
          ...defaultValues,
          color: generatePastelColor(),
        });
      },
      update(cache, { data: { insert_customerTypes_one: newType } }) {
        cache.modify({
          fields: {
            customerTypes(existing = []) {
              const newTypeRef = cache.writeFragment({
                data: newType,
                fragment: CUSTOMER_TYPE_FIELDS,
              });
              return [...existing, newTypeRef];
            },
          },
        });
      },
    });
  });

  return (
    <>
      <Button
        {...blackButtonContained}
        onClick={() => {
          setAdding(true);
        }}
      >
        타입 추가
      </Button>

      <Dialog
        open={adding}
        onClose={() => {
          setAdding(false);
        }}
      >
        <DialogTitle>고객 타입 추가</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.name && touchedFields.name}
              color="success"
              placeholder={
                errors.name?.message ? errors.name?.message : "타입 이름"
              }
              label="이름"
              {...register("name")}
            />

            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.description && touchedFields.description}
              color="success"
              placeholder={
                errors.description?.message
                  ? errors.description?.message
                  : "설명"
              }
              label="설명"
              {...register("description")}
            />
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.color && touchedFields.color}
              color="success"
              placeholder={
                errors.color?.message ? errors.color?.message : "색상"
              }
              label="색상"
              type="color"
              defaultValue="#ffffff"
              {...register("color")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setAdding(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={onSubmit}
          >
            추가
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
