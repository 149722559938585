import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_CUSTOMER_PAYMENT,
  GET_PAYMENT_METHODS,
} from "../../../../../../gqls/customer";
import { customerPayment } from "../../../../../../types/customer";
import { useAuthStore } from "../../../../../../store/authStore";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import hasuraFilter from "../../../../../../utils/hasuraFilter";
import { CUSTOMER_PAYMENT_FIELDS } from "../../../../../../fragments/customer";
import PaymentControls from "./paymentControls";
import { blackButtonContained } from "../../../../../../classPresets";

interface props {
  customerId: number;
  cb: () => void;
}

interface defaultValues
  extends Omit<
    customerPayment,
    "id" | "method" | "enteredBy" | "created_at" | "updated_at"
  > {
  methodId: number;
}

const schema = z.object({
  methodId: z.coerce.number(),
  amount: z.coerce.number(),
  comment: z.string().nullable().optional(),
  received_at: z.coerce.date(),
});

export default function AddPayment({ customerId, cb }: props) {
  const { data } = useQuery(GET_PAYMENT_METHODS);

  const paymentMethods = data?.paymentMethods;

  const { user } = useAuthStore();

  const defaultValues: defaultValues = {
    methodId: 1,
    amount: 0,
    received_at: dayjs(),
    comment: null,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    register,
    handleSubmit,
    reset,

    formState: { isDirty },
  } = methods;

  const [addPayment, { loading }] = useMutation(ADD_CUSTOMER_PAYMENT);

  const onSubmit = handleSubmit(data => {
    const { methodId, amount, comment, received_at } = data;
    if (amount == 0) {
      return;
    }

    addPayment({
      variables: {
        object: {
          customerId,
          methodId,
          amount,
          comment,
          received_at: new Date(received_at as string).toISOString(),
          enteredById: user?.id,
        },
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            customerPayments(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: [data.insert_customerPayments_one],
                name: "customerPayments",
                string: storeFieldName,
              });

              // not working why?

              const newPaymentRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: CUSTOMER_PAYMENT_FIELDS,
                  fragmentName: "CustomerPaymentFields",
                })
              );
              return [...existing, ...newPaymentRefs];
            },
          },
        });
      },
      onCompleted: () => {
        reset();
        cb();
      },
    });
  });

  const cancel = () => {
    reset();
    cb();
  };

  if (!paymentMethods) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className="p-4 max-w-full w-[524px] grid grid-cols-1 lg:grid-cols-2 gap-y-2 gap-x-4"
      >
        <h3 className="col-span-1 md:col-span-2 mb-2">거래 내역 추가</h3>

        <PaymentControls />
        <div className="col-span-1 md:col-span-2 flex flex-row justify-end gap-2 mt-4">
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={cancel}
          >
            취소
          </Button>
          <LoadingButton
            size="small"
            loading={loading}
            type="submit"
            disabled={!isDirty}
            {...blackButtonContained}
          >
            추가
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
}
