import { inventory, statPopulatedInvQty } from "../../../../types/inv";
import useInvQty from "../../../../hooks/useInvQty";
import BlackHeading from "../../../../comps/blackHeading";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";
import InventoryActionGroup from "./inventoryActionGroup";

interface props {
  inventory: inventory;
}

export default function InventoryAction({ inventory }: props) {
  const [includeAll, setIncludeAll] = useState(
    inventory.customerId ? true : false
  );
  let qty = useInvQty(inventory, includeAll);

  qty = (qty || []).filter(q => q.qty > 0);

  const qtyStatGroup = qty.reduce<
    { statId: number; qty: statPopulatedInvQty[] }[]
  >((acc, cur) => {
    const idx = acc.findIndex(a => a.statId === cur.statusId);
    if (idx === -1) {
      return acc.concat({ statId: cur.statusId, qty: [cur] });
    } else {
      acc[idx].qty.push(cur);
    }
    return acc;
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row bg-black gap-2 pr-2 items-center">
        <BlackHeading title="재고 현황" fullWidth />
        <Tooltip title="모두 보기">
          <div
            className="cursor-pointer hover:text-quezone text-white"
            onClick={() => setIncludeAll(!includeAll)}
          >
            {includeAll ? (
              <VisibilityIcon sx={{ width: 18 }} />
            ) : (
              <VisibilityOffIcon sx={{ width: 18 }} />
            )}
          </div>
        </Tooltip>
      </div>
      <div className="flex flex-col gap-2">
        {qtyStatGroup.map(group => (
          <InventoryActionGroup
            inventory={inventory}
            statId={group.statId}
            qty={group.qty}
            key={group.statId}
          />
        ))}
      </div>
    </div>
  );
}
