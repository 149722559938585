import { useFormContext, useWatch } from "react-hook-form";
import { taskTypeCheck } from "../../../../../types/taskTypeCheck";
import Checkbox from "@mui/material/Checkbox";
export default function CheckControl({ check }: { check: taskTypeCheck }) {
  const { control, setValue } = useFormContext();

  const formChecks: number[] = useWatch({
    control,
    name: "checks",
    defaultValue: [],
  });

  const checked = formChecks.includes(check.id);
  const toggle = () => {
    if (checked) {
      setValue(
        "checks",
        formChecks.filter(c => c != check.id),
        { shouldDirty: true, shouldTouch: true, shouldValidate: true }
      );
    } else {
      setValue("checks", [...formChecks, check.id], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <div
      className={`border-2 ${
        checked && "border-quezone"
      } p-2 flex flex-row items-center gap-2 cursor-pointer hover:text-quezone hover:border-quezone break-keep`}
      onClick={toggle}
    >
      <Checkbox
        color="success"
        checked={checked}
        onChange={() => {}}
        sx={{ padding: 0 }}
      />
      {check.name}
    </div>
  );
}
