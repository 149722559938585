import { useEffect, useState } from "react";
import { useAuthStore, useCheckAuth } from "../../../../../store/authStore";
import { customer, extendedCustomer } from "../../../../../types/customer";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UPDATE_CUSTOMER_BY_ID } from "../../../../../gqls/customer";
import { useMutation } from "@apollo/client";
import { z } from "zod";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { CustomerRegisteredAtControlOnly } from "../customerBasicInfo/customerRegisteredAtControl";
import dayjs from "dayjs";
import { CustomerInstallDateControlOnly } from "./customerInstallDateControl";
import CustomerHistory from "./customerHistory/customerHistory";
import useAddCustomerHistory from "../../../../../hooks/useAddCustomerHistory";
import Correspondences from "../../../common/correspondences/correspondences";
import AddCorrespondence from "../../../common/correspondences/addCorrespondence";
import CustomerTasks from "./customerTasks/customerTasks";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../../classPresets";
import FieldWrapper from "../customerBasicInfo/fieldWrapper";
import AddCorrespondencesMobile from "./addCorrespondencesMobile";

interface props {
  customer: extendedCustomer;
}

const schema = z.object({
  registered_at: z.coerce.date().nullable().optional(),
  installDate: z.coerce.date(),
});

export default function CustomerProgress({ customer }: props) {
  const checkAuth = useCheckAuth();

  const navigate = useNavigate();

  const companyIds = [customer?.companyId || 0, customer.sourceCompanyId || 0];
  useEffect(() => {
    if (
      !checkAuth({
        permissionName: "고객_진행상황열람",
        companyIds,
      })
    ) {
      return navigate("/customers");
    }
  }, []);

  const { id, registered_at, installDate } = customer;

  const defaultValues = {
    registered_at: dayjs(registered_at),
    installDate: installDate ? dayjs(installDate) : null,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [editing, setEditing] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { isDirty, touchedFields },
  } = methods;

  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER_BY_ID);
  const { addHistory } = useAddCustomerHistory();

  const { user } = useAuthStore();

  const onSubmit = handleSubmit(async data => {
    if (!user) return;

    const { installDate, registered_at } = data;

    const set: Partial<customer> = {
      installDate: installDate ? installDate.toISOString() : null,
      registered_at: registered_at.toISOString(),
      reads: [user.id],
    };

    await updateCustomer({
      variables: {
        id: customer.id,
        set,
      },
      onCompleted(data) {
        const updatedCustomer = data.update_customers_by_pk;

        reset({
          installDate: updatedCustomer.installDate
            ? dayjs(updatedCustomer.installDate)
            : null,
          registered_at: dayjs(updatedCustomer.registered_at),
        });
      },
    });

    let oldData: any = {};
    let newData: any = {};

    const touchedFieldsArr = Object.keys(touchedFields);

    for (const field of touchedFieldsArr) {
      if (field in customer) {
        oldData[field] = customer[field as keyof customer];
      }
      if (field in set) {
        newData[field] = set[field as keyof customer];
      }
    }

    await addHistory({
      customerId: customer.id,
      message: "기본정보 변경",
      type: "detail",
      oldData,
      newData,
    });

    setEditing(false);
    return;
  });

  const handleDateFieldClick = () => {
    if (!editing) {
      setEditing(true);
    }
  };

  const canEdit = checkAuth({ permissionName: "고객_기본정보수정" });

  return (
    <div className="flex-1 flex flex-col gap-4 md:mt-6">
      {/* Fields */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 gap-y-0 md:gap-y-8">
        {/* Left */}
        <div className="flex flex-col md:gap-4">
          <FormProvider {...methods}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
              <FieldWrapper
                title="접수 날짜"
                content={dayjs(registered_at).format("YYYY-MM-DD")}
                striped
                editing={editing}
                onClick={handleDateFieldClick}
              >
                <CustomerRegisteredAtControlOnly disabled={!canEdit} />
              </FieldWrapper>
              <FieldWrapper
                title="설치 날짜"
                content={
                  installDate ? dayjs(installDate).format("YYYY-MM-DD") : "미정"
                }
                editing={editing}
                onClick={handleDateFieldClick}
              >
                <CustomerInstallDateControlOnly disabled={!canEdit} />
              </FieldWrapper>
            </div>
          </FormProvider>
          {/* Actions */}
          {(isDirty || editing) && (
            <div className="flex flex-row justify-end gap-4 px-3 md:px-0">
              <Button
                size="small"
                {...blackButtonOutlined}
                onClick={() => {
                  reset();
                  setEditing(false);
                }}
              >
                취소
              </Button>
              <LoadingButton
                size="small"
                loading={loading}
                disabled={!isDirty}
                {...blackButtonContained}
                onClick={onSubmit}
              >
                수정
              </LoadingButton>
            </div>
          )}
          <div className="flex flex-col gap-2">
            <label className="text-black bg-gray-100 md:bg-transparent px-3 md:px-0 py-3 md:py-0">
              진행 상황
            </label>
            <CustomerHistory customer={customer} />
          </div>
        </div>
        {/* Right */}
        <div className="flex flex-col gap-2 md:gap-6">
          {/* 작업 목록 */}
          {checkAuth({
            permissionName: "작업열람",
            companyIds,
          }) && <CustomerTasks customer={customer} />}

          {/* Correspondences */}
          {checkAuth({
            permissionName: "고객_내역열람",
          }) && (
            <>
              <div className="flex flex-col md:gap-1">
                <div className="flex flex-row justify-between bg-gray-100 md:bg-transparent items-center px-3 md:px-0 py-3 md:py-0">
                  <label className=" text-black">상담내역</label>
                  <AddCorrespondencesMobile customerId={id} />
                </div>

                <div className="md:shadow-md md:px-2 py-1">
                  <Correspondences customerId={id} />
                </div>
              </div>

              <div className="hidden md:flex flex-col gap-3 border-b-[1px] md:border-none">
                <label className="text-black">상담 내역 추가</label>
                <AddCorrespondence customerId={id} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
