import { company } from "../../../../types/company";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { Link } from "react-router-dom";

interface props {
  company: company;
}

export default function Company({ company }: props) {
  return (
    <>
      <Link to={`/settings/company/detail/${company.id}`}>
        <div className="flex flex-row gap-4 items-center md:hover:bg-gray-100 p-1 rounded-md cursor-pointer">
          <div className="hidden md:block">
            <ApartmentIcon sx={{ fontSize: 52 }} />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="flex flex-row justify-between items-center gap-2">
              <h3
                className={`flex-1 w-0 truncate ${
                  !company.active && "text-gray-500"
                }`}
              >
                {company.name}
              </h3>
              <div
                className={`px-2 py-[2px] border-[1px] ${
                  company.active
                    ? "border-quezone text-quezone"
                    : "border-gray-500 text-gray-500"
                } rounded-md mr-1`}
              >
                {company.active ? company.type.name : "비활성"}
              </div>
            </div>
            <div className="font-light flex flex-row">
              <div className="flex-1 w-0 truncate">
                {company.address || "주소 미상"}
              </div>
            </div>
            <div className="font-light flex flex-row">
              <div className="flex-1 w-0 truncate">
                {company.email || "메일 없음"} /{" "}
                {company.phone || "연락처 없음"}
              </div>
            </div>
          </div>
        </div>
      </Link>
      <hr />
    </>
  );
}
