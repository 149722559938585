import { useProductStore } from "../../../../store/productStore";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useStatePersist } from "use-state-persist";
import Switch from "@mui/material/Switch";
import ProductListItem from "./productListItem";
import { company } from "../../../../types/company";

export default function ProductList({ companies }: { companies: company[] }) {
  const { products } = useProductStore();

  const [includeNull, setIncludeNull] = useStatePersist(
    "@productViewIncludeNull",
    false
  );

  return (
    <main className="flex-1 md:py-5 md:p-8 flex flex-col md:drop-shadow-md md:bg-white overflow-x-clip">
      {/* Header */}
      <div className="flex flex-row justify-between gap-2 items-center pl-2 md:pl-0">
        <h2>제품별 재고</h2>
        <div className="flex flex-row justify-end gap-1 items-center text-sm">
          <span className={includeNull ? "" : "text-gray-500"}>
            미보유 포함
          </span>
          <Switch
            color="success"
            checked={includeNull}
            onChange={() => {
              setIncludeNull(!includeNull);
            }}
          />
        </div>
      </div>
      {/* PC */}
      <div className="hidden md:block">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>사진</TableCell>
              <TableCell>제품명</TableCell>
              <TableCell>일련번호</TableCell>
              <TableCell>재고</TableCell>
              <TableCell>총</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .filter(p => p.active)
              .map(product => (
                <ProductListItem
                  companies={companies}
                  key={product.id}
                  product={product}
                  includeNull={includeNull}
                  mode={"PC"}
                />
              ))}
          </TableBody>
        </Table>
      </div>
      {/* Mobile */}

      <div className="md:hidden mt-4 flex flex-col gap-2">
        {products
          .filter(p => p.active)
          .map((product, i) => (
            <ProductListItem
              key={product.id}
              companies={companies}
              product={product}
              includeNull={includeNull}
              mode={"mobile"}
              index={i}
            />
          ))}
      </div>
    </main>
  );
}
