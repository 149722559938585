import { TypedDocumentNode, gql, useLazyQuery } from "@apollo/client";
import { CUSTOMER_HISTORY_FIELDS } from "../../../../fragments/customer";
import { extendedCustomerHistory } from "../../../../types/customer";
import { INV_HISTORY_FIELDS } from "../../../../fragments/inv";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
import { invHistory } from "../../../../types/inv";
import { sort } from "fast-sort";
import RecentGroup from "./recentGroup";
import { useDashboardStore } from "../../../../store/dashboardStore";
import { useEffect } from "react";
import { cloneDeep } from "apollo-utilities";
import {
  GET_OTHER_HISTORIES_CONDITIONAL,
  STREAM_OTHER_HISTORIES,
} from "../../../../gqls/common";
import { otherHistory } from "../../../../types/common";
import dayjs from "dayjs";

export default function Recents() {
  const { user } = useAuthStore();

  const subCompanyIds = user?.company?.subCompanies || [];
  const companyIds = [user?.company?.id || 0, ...subCompanyIds];

  const {
    customerCursor,
    invCursor,
    setCustomerCursor,
    setInvCursor,
    otherCursor,
    setOtherCurosr,
  } = useDashboardStore();

  const checkAuth = useCheckAuth();

  const customerCompanyIds = companyIds.filter(id =>
    checkAuth({
      permissionName: "고객열람",
      companyIds: [id],
    })
  );

  const taskCompanyIds = companyIds.filter(id =>
    checkAuth({
      permissionName: "작업열람",
      companyIds: [id],
    })
  );

  const invCompanyIds = companyIds.filter(id =>
    checkAuth({
      permissionName: "재고열람",
      companyIds: [id],
    })
  );

  const otherCompanyIds = companyIds.filter(id =>
    checkAuth({
      permissionName: "기록열람",
      companyIds: [id],
    })
  );

  const customerWhere =
    user?.auth.name == "admin"
      ? {}
      : {
          _or: [
            {
              customer: {
                companyId: {
                  _in: customerCompanyIds,
                },
              },
            },
            {
              customer: {
                sourceCompanyId: {
                  _in: customerCompanyIds,
                },
              },
            },
            {
              task: {
                companyId: {
                  _in: taskCompanyIds,
                },
              },
            },
            {
              _and: {
                task: {
                  assignedToId: {
                    _eq: user?.id,
                  },
                },
                type: {
                  _eq: "task",
                },
              },
            },
          ],
        };

  const invWhere =
    user?.auth.name == "admin"
      ? {}
      : {
          _or: [
            {
              to: {
                companyId: {
                  _in: invCompanyIds,
                },
              },
            },
            {
              from: {
                companyId: {
                  _in: invCompanyIds,
                },
              },
            },
            {
              to: {
                userId: {
                  _eq: user?.id,
                },
              },
            },
            {
              from: {
                userId: {
                  _eq: user?.id,
                },
              },
            },
          ],
        };

  const otherWhere =
    user?.auth.name == "admin"
      ? {}
      : {
          _or: [
            {
              userId: {
                _in: otherCompanyIds,
              },
            },
            {
              companyId: {
                _in: otherCompanyIds,
              },
            },
          ],
        };

  const [
    getCustomerHistories,
    { data: customerData, subscribeToMore: customerSubscribe },
  ] = useLazyQuery(GET_CUSTOMER_HISTORIES);

  const [getInvHistories, { data: invData, subscribeToMore: invSubscribe }] =
    useLazyQuery(GET_INV_HISTORIES);

  const [
    getOtherHistories,
    { data: otherData, subscribeToMore: otherSubscribe },
  ] = useLazyQuery(GET_OTHER_HISTORIES_CONDITIONAL);

  useEffect(() => {
    getCustomerHistories({
      variables: {
        where: customerWhere,
      },
      onCompleted() {
        setCustomerCursor(new Date().toISOString());
      },
      onError(error) {
        console.log(error);
      },
    });
    getInvHistories({
      variables: {
        where: invWhere,
      },
      onCompleted() {
        setInvCursor(new Date().toISOString());
      },
      onError(error) {
        console.log(error);
      },
    });
    getOtherHistories({
      variables: {
        where: otherWhere,
      },
      onCompleted() {
        setOtherCurosr(new Date().toISOString());
      },
      onError(error) {
        console.log(error);
      },
    });

    const unsubscribeCustomer = customerSubscribe({
      document: STREAM_CUSTOMER_HISTORY,
      variables: {
        where: customerWhere,
        cursor: {
          initial_value: { created_at: customerCursor },
          ordering: "ASC",
        },
      },
      onError(error) {
        console.log(error);
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCustomerCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.customerHistories);
        const newFeedItem = subscriptionData.data.customerHistories_stream;
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];
        const items = [...newFeedItem, ...existing];
        return Object.assign({}, previous, {
          customerHistories: items,
        });
      },
    });

    const unsubscribeInv = invSubscribe({
      document: STREAM_INV_HISTORY,
      variables: {
        where: invWhere,
        cursor: {
          initial_value: { created_at: invCursor },
          ordering: "ASC",
        },
      },
      onError(error) {
        console.log(error);
      },
      updateQuery: (previous, { subscriptionData }) => {
        setInvCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.invHistories);
        const newFeedItem = subscriptionData.data.invHistories_stream;
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];
        const items = [...newFeedItem, ...existing];
        return Object.assign({}, previous, {
          invHistories: items,
        });
      },
    });

    const unsubscribeOther = otherSubscribe({
      document: STREAM_OTHER_HISTORIES,
      variables: {
        where: otherWhere,
        cursor: {
          initial_value: { created_at: otherCursor },
          ordering: "ASC",
        },
      },
      onError(error) {
        console.log(error);
      },
      updateQuery: (previous, { subscriptionData }) => {
        setInvCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.otherHistories);
        const newFeedItem = subscriptionData.data.otherHistories_stream;
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];
        const items = [...newFeedItem, ...existing];
        return Object.assign({}, previous, {
          otherHistories: items,
        });
      },
    });

    return () => {
      unsubscribeCustomer();
      unsubscribeInv();
      unsubscribeOther();
    };
  }, []);

  const customerHistories = customerData?.customerHistories || [];
  const invHistories = invData?.invHistories || [];
  const otherHistories = otherData?.otherHistories || [];

  const recents = sort([
    ...customerHistories,
    ...invHistories,
    ...otherHistories,
  ]).desc("created_at");

  const groupByDate = recents.reduce(
    (acc, log) => {
      const date = dayjs(log.created_at).format("YYYY-MM-DD");
      if (acc.find(d => d.date === date)) {
        return acc.map(a => ({
          ...a,
          logs: date == a.date ? [...a.logs, log] : a.logs,
        }));
      }

      return acc.concat({
        date,
        logs: [log],
      });
    },
    [] as {
      date: string;
      logs: (invHistory | extendedCustomerHistory | otherHistory)[];
    }[]
  );

  return (
    <div className="col-span-1 lg:col-span-2 lg:pr-2 lg:pl-4 flex flex-col gap-4">
      <div className="flex flex-row gap-2 items-center">
        <div className="lg:hidden px-2 self-stretch bg-quezone rounded-md" />
        <h1 className="leading-none mt-[2px] lg:mt-0">최근 활동</h1>
      </div>

      <div className="flex flex-col gap-2">
        {groupByDate.map(group => (
          <RecentGroup key={group.date} group={group} />
        ))}
      </div>
    </div>
  );
}

const GET_CUSTOMER_HISTORIES: TypedDocumentNode<{
  customerHistories: extendedCustomerHistory[];
}> = gql`
  ${CUSTOMER_HISTORY_FIELDS}
  query GET_CUSTOMER_HISTORIES($where: customerHistories_bool_exp!) {
    customerHistories(
      where: $where
      limit: 200
      order_by: { created_at: desc }
    ) {
      ...CustomerHistoryFields
    }
  }
`;

export const STREAM_CUSTOMER_HISTORY: TypedDocumentNode<{
  customerHistories_stream: extendedCustomerHistory[];
}> = gql`
  ${CUSTOMER_HISTORY_FIELDS}
  subscription STREAM_CUSTOMER_HISTORY(
    $where: customerHistories_bool_exp
    $cursor: [customerHistories_stream_cursor_input]!
  ) {
    customerHistories_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...CustomerHistoryFields
    }
  }
`;

const GET_INV_HISTORIES: TypedDocumentNode<{
  invHistories: invHistory[];
}> = gql`
  ${INV_HISTORY_FIELDS}
  query GET_INV_HISTORIES($where: invHistories_bool_exp!) {
    invHistories(where: $where, limit: 200, order_by: { created_at: desc }) {
      ...InvHistoryFields
    }
  }
`;

export const STREAM_INV_HISTORY: TypedDocumentNode<{
  invHistories_stream: invHistory[];
}> = gql`
  ${INV_HISTORY_FIELDS}
  subscription STREAM_INV_HISTORY(
    $where: invHistories_bool_exp
    $cursor: [invHistories_stream_cursor_input]!
  ) {
    invHistories_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...InvHistoryFields
    }
  }
`;
