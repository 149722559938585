import dayjs, { Dayjs } from "dayjs";
import { filter, range } from "../../../../types/common";
import { useFormContext, useWatch } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface props {
  filter: filter;
  coord: string;
}

export default function RangeFilter({ filter, coord }: props) {
  const { control, setValue, register } = useFormContext();

  const { rangeType } = filter;

  if (!rangeType) {
    return null;
  }

  const range = useWatch({
    name: `${coord}.values`,
    control,
  }) as range;

  const from =
    rangeType == "date"
      ? dayjs(range.from).format("YYYY-MM-DD")
      : Number(range.from);

  const to =
    rangeType == "date"
      ? dayjs(range.to).format("YYYY-MM-DD")
      : Number(range.to);

  const handleDateChange = (
    where: string,
    value: string | Date | Dayjs | null
  ) => {
    if (rangeType == "date") {
      if (where == "from" && dayjs(value).isAfter(dayjs(to))) {
        return;
      }
      if (where == "to" && dayjs(value).isBefore(dayjs(from))) {
        return;
      }
    }

    setValue(`${coord}.values.${where}`, value, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row justify-between px-4 items-center">
        <div className="text-quezone font-medium text-sm">FROM</div>
        {rangeType == "date" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY/MM/DD"
              slotProps={{
                textField: {
                  size: "small",
                  color: "success",
                  variant: "standard",
                },
                openPickerButton: {
                  color: "success",
                },
              }}
              value={from ? dayjs(from) : dayjs()}
              onChange={e => {
                handleDateChange("from", e);
              }}
            />
          </LocalizationProvider>
        )}
        {rangeType == "number" && (
          <input
            className="outline-none border-b-[1px] py-[2px] px-2"
            type="number"
            {...register(`${coord}.values.from`)}
          />
        )}
      </div>

      <div className="flex flex-row justify-between px-4 items-center">
        <div className="text-quezone font-medium text-sm">TO</div>
        {rangeType == "date" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY/MM/DD"
              slotProps={{
                textField: {
                  size: "small",
                  color: "success",
                  variant: "standard",
                },
                openPickerButton: {
                  color: "success",
                },
              }}
              value={to ? dayjs(to) : dayjs()}
              onChange={e => {
                handleDateChange("to", e);
              }}
            />
          </LocalizationProvider>
        )}
        {rangeType == "number" && (
          <input
            className="outline-none border-b-[1px] py-[2px] px-2"
            type="number"
            {...register(`${coord}.values.to`)}
          />
        )}
      </div>
    </div>
  );
}
