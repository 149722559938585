import { filter } from "../../../../types/common";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

interface props {
  filter: filter;
  coord: string;
}
export default function CountFilter({ filter, coord }: props) {
  const { control, getValues } = useFormContext();
  let { options } = filter;

  const { filters } = getValues() as { filters: filter[] };

  const parentFilterIndex = filters.findIndex(f => f?.name == filter.dependsOn);
  const parentValues = useWatch({
    control,
    name: `filters.${parentFilterIndex}.values`,
    // @ts-expect-error
  })?.map(v => v.toString());

  if (filter.dependsOn) {
    if (!parentValues || !filter.dependsValue) {
      return;
    }

    const dependsValue = filter.dependsValue.toString();

    options = options?.filter(o => {
      // @ts-expect-error
      if (!o.data || !o.data[dependsValue]) {
        return o;
      }
      // @ts-expect-error
      return parentValues.includes(o.data[dependsValue].toString());
    });
  }

  return (
    <FormGroup row>
      <Controller
        name={`${coord}.values`}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} row>
            {options?.map((option, i) => (
              <FormControlLabel
                key={i}
                label={option.name}
                value={option.value}
                control={<Radio color="success" />}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormGroup>
  );
}
