import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";

export default function CustomerCreatedAtControlOnly({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name="created_at"
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="YYYY-MM-DD"
            slotProps={{
              textField: {
                size: "small",
                color: "success",
                variant: "outlined",
              },
              openPickerButton: {
                color: "success",
              },
            }}
            disabled={disabled}
            className="shadow-md"
            value={field.value}
            onChange={e => {
              field.onChange(e);
              field.onBlur();
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
}
