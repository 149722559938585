import { typeOrStatus } from "../../../../../../types/common";
import NewTaskTypeCheck from "./newTaskTypeCheck";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useEffect, useMemo, useState } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { sort } from "fast-sort";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_TYPE_CHECKS_MANY } from "../../../../../../gqls/taskCheck";
import { createPortal } from "react-dom";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTaskStore } from "../../../../../../store/taskStore";
import { taskTypeCheck } from "../../../../../../types/taskTypeCheck";
import TaskTypeCheck from "./taskTypeCheck";
import { blackButtonContained } from "../../../../../../classPresets";

interface props {
  type: typeOrStatus;
}

export default function TaskTypeChecksInstaller({ type }: props) {
  const { taskTypeChecks, updateTaskTypeChecks } = useTaskStore();
  const [sortedChecks, setSortedChecks] = useState<taskTypeCheck[]>([]);

  useEffect(() => {
    setSortedChecks(
      sort(
        taskTypeChecks.filter(
          check => check.typeId == type.id && !check.forCustomer
        )
      ).asc("priority")
    );
  }, [taskTypeChecks]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedChecks.find(item => item.id === theActive?.id),
    [theActive, sortedChecks]
  );

  const [touched, setTouched] = useState(false);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedChecks.findIndex(({ id }) => id === active.id);
      const overIndex = sortedChecks.findIndex(({ id }) => id === over.id);
      const movedArray = arrayMove(sortedChecks, activeIndex, overIndex);
      setSortedChecks(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const resetSort = () => {
    setSortedChecks(sort(sortedChecks).asc("priority"));
    setTouched(false);
  };

  const [batchUpdate, { loading }] = useMutation(UPDATE_TASK_TYPE_CHECKS_MANY);

  const applySort = () => {
    const priorityMappedChecks = sortedChecks.map((stat, i) => ({
      ...stat,
      priority: i,
    }));

    const updates = priorityMappedChecks.map(stat => ({
      where: { id: { _eq: stat.id } },
      _set: {
        priority: stat.priority,
      },
    }));

    batchUpdate({
      variables: {
        updates,
      },
      onCompleted() {
        updateTaskTypeChecks(priorityMappedChecks);
        setSortedChecks(priorityMappedChecks);
        setTouched(false);
      },
    });
  };
  return (
    <div className="flex flex-col gap-2">
      <label>기사 확인 목록</label>
      <div className="flex flex-col gap-2">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext
            items={sortedChecks.map(check => check.id)}
            strategy={verticalListSortingStrategy}
          >
            {sortedChecks.map(check => (
              <TaskTypeCheck key={check.id} check={check} isOverlay={false} />
            ))}
          </SortableContext>
          {activeItem && (
            <>
              {createPortal(
                <DragOverlay>
                  <TaskTypeCheck check={activeItem} isOverlay={true} />
                </DragOverlay>,
                document.body
              )}
            </>
          )}
        </DndContext>
      </div>
      <NewTaskTypeCheck type={type} forCustomer={false} />
      <div className="flex flex-row justify-end items-center gap-2 mt-2">
        {touched && (
          <>
            <Button onClick={resetSort} color="success" size="small">
              취소
            </Button>
            <LoadingButton
              onClick={applySort}
              loading={loading}
              size="small"
              {...blackButtonContained}
            >
              저장
            </LoadingButton>
          </>
        )}
      </div>
    </div>
  );
}
