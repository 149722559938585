import { useParams } from "react-router-dom";
import ProductControl from "./productControl";
import { useProductStore } from "../../../../../store/productStore";

export default function EditProduct() {
  const { id } = useParams();
  const { products } = useProductStore();
  const product = products.find(product => product.id === Number(id));

  if (!product) {
    return null;
  }

  return <ProductControl product={product} />;
}
