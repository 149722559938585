import { z } from "zod";
import { extendedReport } from "../../../../../../types/report";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ReportAccountControls from "./reportAccountControls";
import { useMutation } from "@apollo/client";
import {
  ADD_REPORT_ACCOUNT,
  UPDATE_REPORT,
} from "../../../../../../gqls/report";
import Button from "@mui/material/Button";
import {
  blackButtonContained,
  blackButtonOutlined,
  modal,
} from "../../../../../../classPresets";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import hasuraFilter from "../../../../../../utils/hasuraFilter";
import { REPORT_ACCOUNT_FIELDS } from "../../../../../../fragments/report";
import { useAuthStore } from "../../../../../../store/authStore";

interface props {
  report: extendedReport;
}

const schema = z.object({
  item: z.string(),
  amount: z.coerce.number(),
  gst: z.coerce.number(),
});

export default function AddReportAccount({ report }: props) {
  const [adding, setAdding] = useState(false);

  const methods = useForm({
    defaultValues: {
      item: "",
      amount: 0,
      gst: 0,
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const [add, { loading }] = useMutation(ADD_REPORT_ACCOUNT);
  const [updateReport] = useMutation(UPDATE_REPORT);

  const resetAndClose = () => {
    reset();
    setAdding(false);
  };

  const { user } = useAuthStore();

  const onSubmit = handleSubmit(data => {
    add({
      variables: {
        object: {
          ...data,
          reportId: report.id,
        },
      },
      update(cache, { data }) {
        const history = data.insert_reportAccounts_one;

        cache.modify({
          fields: {
            reportAccounts(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: [history],
                name: "reportAccounts",
                string: storeFieldName,
              });

              const newReportAccountRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: REPORT_ACCOUNT_FIELDS,
                  fragmentName: "ReportAccountFields",
                })
              );
              return [...existing, ...newReportAccountRefs];
            },
          },
        });
      },
      onCompleted: () => {
        resetAndClose();
        updateReport({
          variables: {
            id: report.id,
            set: {
              reads: [user?.id],
            },
          },
        });
      },
    });
  });

  return (
    <>
      <div className="flex flex-row justify-end mt-1 px-3 md-px-0">
        <Button
          {...blackButtonContained}
          size="small"
          onClick={() => {
            setAdding(true);
          }}
        >
          정산 내역 추가
        </Button>
      </div>
      <Modal open={adding} onClose={resetAndClose}>
        <form
          className={`${modal} p-2 flex flex-col gap-2`}
          onSubmit={onSubmit}
        >
          <h3>정산 내역 추가</h3>
          <FormProvider {...methods}>
            <ReportAccountControls />
          </FormProvider>
          <div className="flex flex-row gap-2 justify-end">
            <Button
              {...blackButtonOutlined}
              onClick={resetAndClose}
              size="small"
            >
              취소
            </Button>
            <LoadingButton
              loading={loading}
              disabled={!isDirty}
              {...blackButtonContained}
              size="small"
              type="submit"
            >
              추가
            </LoadingButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
