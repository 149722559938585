import { useTaskStore } from "../../../../../store/taskStore";
import { sort } from "fast-sort";
import CheckControl from "./checkControl";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import SignControl from "./signControl";

export default function ReportChecksSignsControl({
  typeId,
}: {
  typeId: number;
}) {
  const { taskTypeChecks } = useTaskStore();

  const checks = taskTypeChecks.filter(t => t.typeId == typeId);

  const customerChecks = sort(checks.filter(c => c.forCustomer)).asc(
    "priority"
  );

  const installerChecks = sort(checks.filter(c => !c.forCustomer)).asc(
    "priority"
  );

  const [signing, setSigning] = useState<null | "customer" | "installer">(null);

  const { control } = useFormContext();

  const customerSign = useWatch({
    control,
    name: "customerSign",
    defaultValue: null,
  });

  const installerSign = useWatch({
    control,
    name: "installerSign",
    defaultValue: null,
  });

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 md:gap-4 bg-gray-100 md:bg-transparent mt-2 md:mt-0">
      {/* Customer */}
      <div className="col-span-1 flex flex-col gap-1 justify-between md:gap-y-4">
        <div className="flex flex-col gap-1">
          <label className="text-black p-3 md:p-0 pb-0">고객 확인 사항</label>
          <div className="flex flex-col gap-1 px-3 py-1 md:px-0 md:py-0">
            {customerChecks.map(check => (
              <CheckControl key={check.id} check={check} />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-black p-3 pb-0 md:p-0">고객 서명</label>
          <div
            className="md:border-[1px] h-44 md:h-60 px-3 md:p-0 md:aspect-square cursor-pointer hover:border-quezone flex flex-col justify-center"
            onClick={() => setSigning("customer")}
          >
            <div className="bg-white md:bg-transparent w-full h-full flex flex-row justify-center rounded-md">
              {customerSign && (
                <img
                  src={customerSign}
                  alt="customerSign"
                  className="object-contain h-full rounded-md"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Installer */}
      <div className="col-span-1 flex flex-col gap-1 md:gap-y-4 justify-between">
        <div className="flex flex-col gap-1">
          <label className="text-black p-3 md:p-0 pb-0">기사 확인 사항</label>
          <div className="flex flex-col gap-1 px-3 py-1 md:px-0 md:py-0">
            {installerChecks.map(check => (
              <CheckControl key={check.id} check={check} />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-black p-3 pb-0 md:p-0">기사 서명</label>
          <div
            className="md:border-[1px] h-44 md:h-60 px-3 md:p-0 md:aspect-square cursor-pointer hover:border-quezone flex flex-col justify-center"
            onClick={() => setSigning("installer")}
          >
            <div className="bg-white md:bg-transparent w-full h-full flex flex-row justify-center rounded-md">
              {installerSign && (
                <img
                  src={installerSign}
                  alt="installerSign"
                  className="object-contain h-full rounded-md"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <SignControl signing={signing} setSigning={setSigning} />
    </div>
  );
}
