import { TypedDocumentNode, gql } from "@apollo/client";
import { COMPANY_FIELDS, COMPANY_TYPE_FIELDS } from "../fragments/company";
import { company } from "../types/company";
import { typeOrStatus } from "../types/common";

export const GET_COMPANIES_CONDITIONAL: TypedDocumentNode<{
  companies: company[];
}> = gql`
  ${COMPANY_FIELDS}
  query GetCompanies($where: companies_bool_exp!) {
    companies(where: $where) {
      ...CompanyFields
    }
  }
`;

export const GET_COMPANIES: TypedDocumentNode<{
  companies: company[];
}> = gql`
  ${COMPANY_FIELDS}
  query GetCompanies {
    companies {
      ...CompanyFields
    }
  }
`;

export const GET_COMPANY_BY_ID: TypedDocumentNode<{
  companies_by_pk: company;
}> = gql`
  ${COMPANY_FIELDS}
  query GetCompanyById($id: Int!) {
    companies_by_pk(id: $id) {
      ...CompanyFields
    }
  }
`;

export const GET_COMPANY_TYPES: TypedDocumentNode<{
  companyTypes: typeOrStatus[];
}> = gql`
  query GetCompanyTypes {
    companyTypes {
      ...CompanyTypeFields
    }
  }
  ${COMPANY_TYPE_FIELDS}
`;

export const UPDATE_COMPANY_BY_ID: TypedDocumentNode<{
  update_companies_by_pk: company;
}> = gql`
  ${COMPANY_FIELDS}
  mutation UpdateCompanyById($id: Int!, $set: companies_set_input!) {
    update_companies_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...CompanyFields
    }
  }
`;

export const ADD_COMPANY = gql`
  ${COMPANY_FIELDS}
  mutation ADD_COMPANY($object: companies_insert_input!) {
    insert_companies_one(object: $object) {
      ...CompanyFields
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation DELETE_COMPANY($id: Int!) {
    delete_companies_by_pk(id: $id) {
      id
    }
  }
`;
