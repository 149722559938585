import { useFormContext } from "react-hook-form";
import TaskCompanyControl from "./taskCompanyControl";
import TaskAssignedToControl from "./taskAssignedToControl";
import TaskTypeControl from "./taskTypeControl";
import TaskStatusControl from "./taskStatusControl";
import TaskDateControl from "./taskDateControl";
import TextField from "@mui/material/TextField";
import TaskTagControl from "./taskTagControl";
import TaskTimeControl from "./taskTimeControl";

export default function TaskControls({ tagIds }: { tagIds?: number[] }) {
  const { register } = useFormContext();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4">
      {/* Left */}
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <TaskCompanyControl />
          <TaskAssignedToControl />
          <TaskTypeControl />
          <TaskStatusControl />
          {/* Amount */}
          <div className="col-span-full flex flex-col gap-1">
            <label>예상 작업 금액</label>
            <TextField
              margin="none"
              size="small"
              color="success"
              type="number"
              className="shadow-md"
              placeholder="0"
              {...register("amount")}
            />
          </div>
        </div>
      </div>
      {/* Right */}
      <div className="flex flex-col gap-4">
        <TaskDateControl />
        <TaskTimeControl />

        <TaskTagControl tagIds={tagIds} />
      </div>
      {/* Desc */}
      <div className="col-span-full flex flex-col gap-1">
        <label>비고 / 요청사항</label>
        <TextField
          margin="none"
          size="small"
          color="success"
          multiline
          minRows={2}
          className="shadow-md"
          placeholder="무상기간에도 고객과실일경우 유상입니다"
          {...register("description")}
        />
      </div>
    </div>
  );
}
