import { User } from "../../../../../types/user";
import UserAuthControl from "./userAuthControl";
import UserBasicInfoControl from "./userBasicInfoControl";

interface props {
  user: User;
}
export default function UserDetailControl({ user }: props) {
  return (
    <div className="col-span-1 xl:col-span-2 2xl:col-span-3 grid grid-cols-1 xl:grid-cols-2  2xl:grid-cols-3 gap-8">
      <UserBasicInfoControl user={user} />
      <UserAuthControl user={user} />
    </div>
  );
}
