import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import axios from "axios";
import { CookiesProvider } from "react-cookie";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "@apollo/client/link/context";
import Root from "./root";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { uniqueByRef } from "./utils/objectArrayMethods";
import "./common.css";

const hasuraAPI = import.meta.env.VITE_GRAPHQL_API;
const hasuraWS = import.meta.env.VITE_GRAPHQL_WS;
const authLink = setContext((_, previousContext) => {
  return sessionStorage.getItem("accessToken")
    ? {
        headers: {
          Authorization: sessionStorage.getItem("accessToken")
            ? `Bearer ${sessionStorage.getItem("accessToken")}`
            : "",
        },
      }
    : previousContext;
});

// HTTP Link
const httpLink = new HttpLink({
  uri: hasuraAPI,
});

// WebSocket Link
const wsLink = new WebSocketLink({
  uri: hasuraWS,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      const token = sessionStorage.getItem("accessToken");
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
  },
});

// Send query request based on the type definition
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  //@ts-expect-error
  wsLink,
  authLink.concat(httpLink)
);

// Apollo Client
export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          invQty: {
            merge: (existing = [], incoming = []) => {
              return [...uniqueByRef([...existing, ...incoming])];
            },
          },
          invHistories: offsetLimitPagination(["where"]),
          customers: offsetLimitPagination(["where"]),
          tasks: offsetLimitPagination(["where"]),
        },
      },
    },
  }),
});

axios.defaults.withCredentials = true;
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.credentials = "include";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Root />,
  },
]);

root.render(
  <CookiesProvider>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </CookiesProvider>
);
