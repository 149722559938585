import TextField from "@mui/material/TextField";
import { useFormContext, useWatch } from "react-hook-form";
import { Address, useDaumPostcodePopup } from "react-daum-postcode";

const scriptUrl =
  "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

export const CutomerAddressControlOnly = ({
  disabled,
}: {
  disabled?: boolean;
}) => {
  const {
    register,
    control,
    setValue,
    formState: { touchedFields, errors },
  } = useFormContext();

  const address = useWatch({
    control,
    name: "address",
  });

  const open = useDaumPostcodePopup(scriptUrl);

  const handleAddressSearch = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    const postCode = data.zonecode;
    setValue("address", fullAddress, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setValue("postCode", postCode, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const addressSearchPopup = () => {
    open({ onComplete: handleAddressSearch, defaultQuery: address });
  };

  return (
    <div className="flex flex-row gap-2">
      <div className="flex-1 flex flex-row items-center gap-2">
        <TextField
          fullWidth
          margin="none"
          size="small"
          color="success"
          disabled={disabled}
          placeholder="주소"
          error={
            touchedFields.address && errors.address?.message ? true : false
          }
          className="shadow-md pr-10"
          {...register("address")}
        />
        <TextField
          margin="none"
          size="small"
          color="success"
          disabled={disabled}
          placeholder="우편번호"
          error={
            touchedFields.address && errors.address?.message ? true : false
          }
          className="shadow-md w-24"
          inputProps={{
            className: "text-center",
            style: { paddingLeft: 2, paddingRight: 2 },
          }}
          {...register("postCode")}
          InputProps={{
            style: {
              fontSize: "0.8rem",
              paddingTop: "0.14rem",
              paddingBottom: "0.14rem",
            },
          }}
        />
      </div>
      <button
        type="button"
        className="text-quezone px-1 font-medium"
        onClick={addressSearchPopup}
      >
        검색
      </button>
    </div>
  );
};

export default function CustomerAddressControl({
  disabled,
}: {
  disabled?: boolean;
}) {
  return (
    <div className="col-span-1 md:col-span-2 flex flex-col gap-1">
      <label>주소</label>
      <CutomerAddressControlOnly disabled={disabled} />
    </div>
  );
}
