import DeleteForever from "@mui/icons-material/DeleteForever";
import { automation } from "../../../../../types/automation";
import { useMutation } from "@apollo/client";
import { DELETE_AUTOMATION } from "../../../../../gqls/automation";
import { useNavigate } from "react-router-dom";
import { cacheEvict } from "../../../../../utils/cacheMethods";

interface props {
  automation: automation;
}
export default function AutomationDeleteControl({ automation }: props) {
  const [remove] = useMutation(DELETE_AUTOMATION);

  const navigate = useNavigate();

  const _remove = () => {
    const proceed = confirm("자동화 템플릿을 삭제하시겠습니까?");
    if (!proceed) {
      return;
    }

    remove({
      variables: {
        id: automation.id,
      },
      update(cache) {
        cacheEvict({ cache, id: automation.id, __typename: "automations" });
      },
      onCompleted() {
        navigate("/settings/automation");
      },
    });
  };

  return (
    <div className="cursor-pointer text-red-500" onClick={_remove}>
      <DeleteForever />
    </div>
  );
}
