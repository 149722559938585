import { useQuery } from "@apollo/client";
import {
  GET_INVENTORY_CONDITIONAL,
  GET_INVENTORY_CONDITIONAL_WITH_QTY,
} from "../../../../../../gqls/inv";
import { useFormContext, useWatch } from "react-hook-form";
import { useProductStore } from "../../../../../../store/productStore";
import { inventory_with_product } from "../../../../../../types/inv";
import AvailableInventoryItem from "../../customerInventory/addCustomerInventory/availableInventoryItem";
import { useState } from "react";
import QtyActionControl from "../../customerInventory/addCustomerInventory/qtyActionControl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function AddInventoryFromInstaller({
  customerId,
}: {
  customerId?: number;
}) {
  const { control, setValue } = useFormContext();

  const installerId = useWatch({
    control,
    name: "assignedToId",
  });

  const { data } = useQuery(GET_INVENTORY_CONDITIONAL_WITH_QTY, {
    variables: {
      where: {
        _and: {
          userId: {
            _eq: installerId,
          },

          customerId: {
            _is_null: true,
          },
        },
      },
    },
  });

  const inventory = data?.inventory || [];

  const { products } = useProductStore();

  const [activeStep, setActiveStep] = useState(0);

  const handleClick = (inv: inventory_with_product) => {
    setValue("inv", inv, { shouldDirty: true, shouldTouch: true });
    setActiveStep(1);
  };

  const availableInventories: inventory_with_product[] = (
    data?.inventory || []
  ).map(inv => {
    return {
      ...inv,
      product: products.find(p => p.id === inv.productId),
    };
  });

  const { data: customerInvData } = useQuery(GET_INVENTORY_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: customerId,
        },
      },
    },
  });

  const customerInvs = customerInvData?.inventory || [];

  const [show, setShow] = useState(false);

  if (inventory.length == 0) {
    return null;
  }
  return (
    <div className="flex flex-col gap-6 mt-2">
      <div className="flex flex-row justify-between gap-2 items-center flex-wrap">
        <label>기사 {"->"} 고객 재고 할당</label>
        <button type="button" onClick={() => setShow(!show)}>
          {show ? (
            <VisibilityIcon className="text-quezone" />
          ) : (
            <VisibilityOffIcon className="text-quezone" />
          )}
        </button>
      </div>
      {show && (
        <>
          {activeStep == 0 && (
            <div className="flex flex-row gap-y-8 gap-x-4 flex-wrap justify-evenly lg:justify-start lg:px-4">
              {availableInventories.map(inventory => (
                <AvailableInventoryItem
                  key={inventory.id}
                  inventory={inventory}
                  handleClick={handleClick}
                />
              ))}
            </div>
          )}
          {activeStep == 1 && (
            <div>
              <QtyActionControl
                setActiveStep={setActiveStep}
                inventories={customerInvs}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
