import AddCircle from "@mui/icons-material/AddCircle";
import { ComponentPropsWithRef } from "react";

export const AddButton = (props: ComponentPropsWithRef<"button">) => {
  return (
    <button className="flex flex-row items-center gap-1 text-xs" {...props}>
      <AddCircle className="mb-[3px]" sx={{ width: 15, height: 15 }} />
      <span>추가하기</span>
    </button>
  );
};
