import { useParams } from "react-router-dom";
import BG from "/quezone.svg";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMERS_GUEST } from "../../gqls/customer";
import LinearProgress from "@mui/material/LinearProgress";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { tempImage } from "../../types/report";
import useS3 from "../../hooks/useS3";
import TempImage from "./tempImage";
import {
  blackButtonContained,
  blackButtonOutlined,
  scrollbar,
} from "../../classPresets";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import axios from "axios";
const API = import.meta.env.VITE_API;

export default function CustomerPhoto() {
  // id: UUID, type: as | install
  const { id, type } = useParams();

  const { data, loading, called } = useQuery(GET_CUSTOMERS_GUEST, {
    variables: {
      where: {
        uuid: {
          _eq: id,
        },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const customer = data?.customers[0];

  const methods = useForm({
    defaultValues: {
      images: [] as tempImage[],
    },
  });

  const { control, handleSubmit } = methods;

  const { replace, fields } = useFieldArray({
    name: "images",
    control,
  });

  const { getObjectsInDir, getFileURL, uploadFile, deleteObject } = useS3();

  const presets =
    type == "install"
      ? ["싱크대하부장 정면", "배관 측면", "투입구", "하부장 전원콘센트"]
      : ["사진 1", "사진 2", "사진 3"];

  const dir = `customers/${customer?.id}/customerPhotos`;

  const [exImages, setExImages] = useState([] as tempImage[]);

  const [biztalkSent, setBiztalkSent] = useState(false);

  const getInitImages = async () => {
    if (!customer) {
      return;
    }

    const res = await getObjectsInDir(dir);

    const keys = res ? res.Contents : [];

    const objectKeysOnly =
      keys
        ?.filter(c => c.Size && c.Size > 0)
        .map(c => c.Key)
        .filter(c => c) || [];

    const images: tempImage[] = await Promise.all(
      presets.map(async preset => {
        const existingKey = objectKeysOnly.find(key => {
          if (!key) {
            return false;
          }
          const name = key.split("/").pop()!.split(".")[0];
          return name == preset;
        });

        const image: tempImage = {
          image: null,
          isPreset: true,
          name: preset,
          src: existingKey ? await getFileURL(existingKey) : null,
          key: existingKey || null,
        };

        return image;
      })
    );

    const _exImages = images.filter(i => i.key);

    setExImages(_exImages);

    replace(images);
  };

  useEffect(() => {
    getInitImages();
  }, [customer]);

  const [updating, setUpdating] = useState(false);

  const onSubmit = handleSubmit(async data => {
    if (!customer) {
      return;
    }
    setUpdating(true);
    const { images } = data;

    let uploaded = [] as string[];

    await Promise.all(
      exImages.map(async image => {
        const matchingImage = images.find(i => i.name === image.name);
        if (!matchingImage) {
          if (image.key) {
            await deleteObject(image.key);
            return;
          } else {
            return;
          }
        } else {
          if (matchingImage.image) {
            const ext = matchingImage.image.name.split(".").pop();
            const name = `${matchingImage.name}.${ext}`;
            await deleteObject(image.key as string);
            await uploadFile(
              matchingImage.image,
              `${dir}/${name}`,
              `image/${ext}`
            );
            uploaded.push(matchingImage.name);
            return;
          } else {
            return;
          }
        }
      })
    );

    const newImages = images.filter(i => !uploaded.includes(i.name));

    await Promise.all(
      newImages.map(async image => {
        if (!image.image) {
          return;
        }
        const ext = image.image.name.split(".").pop();
        const name = `${image.name}.${ext}`;
        await uploadFile(image.image, `${dir}/${name}`, `image/${ext}`);
        return;
      })
    );

    if (exImages.length < 1 && type == "install" && !biztalkSent) {
      setBiztalkSent(true);
      await axios.get(API + "/biztalk/customerSuccessBizTalk", {
        params: {
          id: customer.id,
        },
      });
    }

    await getInitImages();
    setUpdating(false);
  });

  return (
    <FormProvider {...methods}>
      <form
        className={`flex flex-col p-8 gap-1 h-[100dvh] w-full overflow-y-auto ${scrollbar}`}
        onSubmit={onSubmit}
      >
        <img src={BG} className="max-w-[250px] mx-auto mb-12" />
        {loading && <LinearProgress color="success" className="w-full" />}
        {called && !customer && "해당하는 고객 페이지를 찾을 수 없습니다."}
        {customer && (
          <>
            <h1 className="mb-4">고객 정보가 확인 되었습니다.</h1>
            <h3>고객 이름: {customer.name}</h3>
            <h3>
              고객 번호: #{customer.year}-{customer.number}
            </h3>

            <h2 className="text-quezone my-4">
              {type == "install" ? "설치 환경" : "관련"} 사진 등록
            </h2>
            <div>
              작업 위치에 관련된 사진을 미리 업로드 해주시면 더욱 원활한
              서비스에 도움이 됩니다.
            </div>

            {type == "install" && (
              <>
                <h2 className="mt-4">등록 예시</h2>
                <hr className="w-full border-black mb-4" />
                <div>예시를 참조하여 사진을 등록해주세요.</div>
                <div className="flex flex-row gap-2 max-w-lg self-center">
                  <div className="flex-1 flex flex-col gap-1">
                    <img src="/exampleFront.jpg" className="aspect-square" />
                    <div className="text-sm font-medium text-center">
                      하부장 정면
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col gap-1">
                    <img src="/exampleSide.jpg" className="aspect-square" />
                    <div className="text-sm font-medium text-center">
                      배관 측면
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col gap-1">
                    <img src="/exampleTop.jpg" className="aspect-square" />
                    <div className="text-sm font-medium text-center">
                      투입구
                    </div>
                  </div>
                </div>
              </>
            )}

            <h2 className="text-quezone mt-4">사진 등록</h2>
            <hr className="w-full border-black" />

            <div
              className={`grid ${
                type == "install" ? "grid-cols-2" : "grid-cols-3"
              } gap-4 max-w-lg self-center mt-4`}
            >
              {fields.map((field, index) => (
                <TempImage image={field} index={index} key={field.id} />
              ))}
            </div>

            {type == "install" && (
              <ul className="list-disc pl-4 indent-2 mt-4 text-sm break-keep">
                <li>
                  하부장 전원콘센트 사진은 콘센트 유무 및 접근성 확인을 위한
                  사진입니다 전원콘센트가 없을시 비워두셔도 됩니다
                </li>
              </ul>
            )}

            <div className="flex flex-row justify-end gap-2 items-center mt-4">
              <Button {...blackButtonOutlined} onClick={getInitImages}>
                초기화
              </Button>
              <LoadingButton
                loading={updating}
                type="submit"
                {...blackButtonContained}
              >
                저장
              </LoadingButton>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
}
