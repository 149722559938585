import FolderIcon from "@mui/icons-material/Folder";
import KeyObject from "./keyObject";
import UploadControl from "./uploadControl";
import useS3 from "../../../../hooks/useS3";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface props {
  name: string;
  dir: string;
  noHeading?: boolean;
  noEdit?: boolean;
  noAdd?: boolean;
}
export default function Directory({
  dir,
  name,
  noHeading,
  noEdit,
  noAdd,
}: props) {
  const { getObjectsInDir } = useS3();

  const [objects, setObjects] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const load = async () => {
    setLoading(true);

    const res = await getObjectsInDir(dir);

    if (!res) {
      setObjects([]);
      setLoading(false);
      return;
    }

    const { Contents } = res;

    if (!Contents) {
      setObjects([]);
      setLoading(false);
      return;
    }

    const objectKeysOnly = Contents.filter(c => c.Size && c.Size > 0)
      .map(c => c.Key)
      .filter(c => c);
    setObjects(objectKeysOnly as string[]);

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, [dir]);

  return (
    <div className="flex flex-col gap-2">
      {!noHeading && (
        <div className="flex flex-row gap-2 items-center">
          <FolderIcon className="text-gray-500" />{" "}
          <div className="flex-1 w-0 truncate">{name}</div>
          {loading && <CircularProgress color="success" size={20} />}
        </div>
      )}

      {objects.length == 0 && !loading && <div>파일이 없습니다</div>}
      {/* File List */}
      <div className="pl-4 flex flex-col gap-2">
        {objects.map(Key => (
          <KeyObject key={Key} dir={dir} Key={Key} cb={load} noEdit={noEdit} />
        ))}
      </div>
      {/* Upload */}
      {!noAdd && (
        <div className="flex flex-row justify-end mt-2">
          <UploadControl small dir={dir} cb={load} />
        </div>
      )}
    </div>
  );
}
