import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import TabSelect from "./tabSelect";
import uuid from "react-uuid";
import { refineFilters } from "../../../../utils/filterMethods";
import { useLazyQuery, useMutation } from "@apollo/client";
import { cloneDeep } from "apollo-utilities";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import TaskSearch from "./taskSearch";
import MobileSearch from "./mobileSearch";
import { useTaskStore } from "../../../../store/taskStore";
import {
  GET_TASKS_MAIN,
  GET_TASKS_TOTAL_MAIN,
  READ_TASKS,
  STREAM_TASKS_MAIN,
} from "../../../../gqls/task";
import TaskFilters from "./taskFilters";
import Views from "./views/views";
import { useAuthStore } from "../../../../store/authStore";
import LinearProgress from "@mui/material/LinearProgress";
import { task } from "../../../../types/task";
import { uniqueById } from "../../../../utils/objectArrayMethods";

export default function CustomersMain() {
  const { tabs, setTabs, currentTab, setCurrentTab } = useTaskStore();

  const selectedTab = tabs.find(t => t.id == currentTab);
  const [filtering, setFiltering] = useState(false);

  const addTab = (init?: boolean) => {
    const id = uuid();
    setTabs([
      ...tabs,
      {
        id,
        name: init ? "기본" : "새 탭",
        filters: [],
        cursor: new Date().toISOString(),
        view: "list",
      },
    ]);
    setCurrentTab(id);
    setFiltering(init ? false : true);
  };

  useEffect(() => {
    if (!selectedTab && tabs.length > 0) {
      setCurrentTab(tabs[0].id);
    }
    if (tabs.length == 0) {
      addTab(true);
    }
  }, [selectedTab?.id]);

  const filters = selectedTab?.filters;
  const cursor = selectedTab?.cursor;
  const refinedFilters = refineFilters(filters || []);

  const { where, order_by } = refinedFilters;
  const [totalLength, setTotalLength] = useState(0);

  const [getTasks, { data, subscribeToMore, fetchMore, loading }] =
    useLazyQuery(GET_TASKS_MAIN);

  const [getTasksTotal] = useLazyQuery(GET_TASKS_TOTAL_MAIN);

  const limit = 200;

  const updateCursor = () => {
    setTabs(
      tabs.map(t => {
        if (t.id == selectedTab?.id) {
          return { ...selectedTab, cursor: new Date().toISOString() };
        }
        return t;
      })
    );
  };

  useEffect(() => {
    if (filters?.length == 0) {
      setFiltering(true);
      return;
    }
    getTasksTotal({
      variables: {
        where,
      },
      onCompleted(data) {
        setTotalLength(data.tasks_aggregate?.aggregate?.count || 0);
      },
    });
    getTasks({
      variables: {
        where,
        order_by,
        limit,
        offset: 0,
      },
      onCompleted() {
        updateCursor();
      },
      onError(error) {
        console.log(error);
      },
    });
  }, [filters, selectedTab?.id]);

  const tasks = uniqueById(data?.tasks || []) as task[];

  const [fetchingMore, setFetchingMore] = useState(false);

  const loadMore = () => {
    const currentLength = tasks.length;
    if (currentLength >= totalLength) return;
    setFetchingMore(true);
    fetchMore({
      variables: {
        where,
        order_by,
        offset: currentLength,
        limit,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setFetchingMore(false);
        if (!fetchMoreResult) return previousResult;
        return Object.assign({}, previousResult, {
          tasks: [...previousResult.tasks, ...fetchMoreResult.tasks],
        });
      },
    });
  };

  const canLoadMore = totalLength > tasks.length;

  const ids = tasks.map(c => c.id);

  useEffect(() => {
    let _and = where._and;
    delete _and.deleted;

    const subFilter = {
      _or: [_and, { id: { _in: ids || [] } }],
    };

    const unsubscribe = subscribeToMore({
      document: STREAM_TASKS_MAIN,
      variables: {
        where: subFilter,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        updateCursor();
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.tasks);
        const newFeedItem = subscriptionData.data.tasks_stream;
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];

        const filterDeleted = [...newFeedItem, ...existing].filter(
          p => !p.deleted
        );

        return Object.assign({}, previous, {
          tasks: filterDeleted,
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [where, ids]);

  const [readTasks] = useMutation(READ_TASKS);

  const { user } = useAuthStore();

  const readAll = () => {
    if (!user) {
      return;
    }
    const tasksToRead = tasks
      .filter(c => !c.reads?.includes(user.id))
      .map(c => c.id);

    if (tasksToRead.length > 0) {
      readTasks({
        variables: {
          ids: tasksToRead,
          userId: user.id,
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-lightBG flex-1 px-3 md:p-0 md:pb-4">
      {/* Header */}
      <div className="flex flex-col gap-1 md:px-10">
        <div className="flex flex-row md:items-center gap-2 md:flex-wrap mt-4">
          {/* Tab Select */}
          <div className="flex flex-row items-center scrollbar-none overflow-x-scroll flex-1 pt-[4px] md:pt-[1px] p-[1px]">
            {tabs.map(tab => (
              <TabSelect tab={tab} key={tab.id} />
            ))}
            <div
              onClick={() => {
                addTab();
              }}
              className={`cursor-pointer text-gray-500 font-medium border-b-2 md:hover:text-quezone md:hover:ring-quezone border-b-transparent px-2 py-[1px] rounded-sm`}
            >
              +
            </div>
          </div>
          {/* Search, Add */}
          <div className="flex flex-row items-center gap-2 md:gap-3 justify-end">
            {/* Search DeskTop */}
            <div className="hidden md:flex">
              <TaskSearch />
            </div>
            <MobileSearch />
            <div
              onClick={() => setFiltering(!filtering)}
              className={`bg-white p-[2px] md:p-[6px] rounded-sm ring-1 ring-gray-300 cursor-pointer md:hover:ring-quezone md:hover:text-quezone ${
                filtering && "text-quezone ring-quezone"
              }`}
            >
              <TuneIcon />
            </div>
            <div className="hidden md:flex flex-row items-center gap-3">
              {!loading && canLoadMore && (
                <Button
                  onClick={loadMore}
                  variant="outlined"
                  sx={{ backgroundColor: "white" }}
                  color="success"
                >
                  더 불러오기
                </Button>
              )}
              <div
                className={`bg-white p-[2px] md:p-[6px] rounded-sm ring-1 ring-gray-300 cursor-pointer text-gray-300`}
              >
                {tasks.length !== totalLength && `${tasks.length}/`}
                {totalLength < tasks.length ? tasks.length : totalLength}
              </div>
            </div>
            {(loading || fetchingMore) && (
              <CircularProgress size={18} sx={{ color: green[500] }} />
            )}
          </div>
        </div>
      </div>
      {/* Tabs */}
      {(loading || fetchingMore) && (
        <div className="md:hidden pt-4">
          <LinearProgress color="success" />
        </div>
      )}
      <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap h-full">
        {filtering && (
          <TaskFilters setFiltering={setFiltering} readAll={readAll} />
        )}
        {selectedTab && (
          <Views
            tasks={tasks}
            tab={selectedTab}
            loadMore={loadMore}
            loading={loading}
            totalLength={totalLength}
            fetchingMore={fetchingMore}
            canLoadMore={canLoadMore}
          />
        )}
      </div>
    </div>
  );
}
