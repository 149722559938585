import { TypedDocumentNode, gql } from "@apollo/client";
import { CONTACT_CORE_FIELDS } from "../fragments/contact";
import { contact } from "../types/contact";

export const SEARCH_CONTACT: TypedDocumentNode<{ contacts: contact[] }> = gql`
  ${CONTACT_CORE_FIELDS}
  query SEARCH_CONTACT($Str: String!) {
    contacts(
      where: { _or: [{ name: { _regex: $Str } }, { number: { _regex: $Str } }] }
      limit: 10
    ) {
      ...ContactCoreFields
    }
  }
`;

export const ADD_CONTACT_MANY = gql`
  ${CONTACT_CORE_FIELDS}
  mutation ADD_CONTACT_MANY($objects: [contacts_insert_input!]!) {
    insert_contacts(objects: $objects) {
      returning {
        ...ContactCoreFields
      }
    }
  }
`;

export const FIND_CONTACT: TypedDocumentNode<{ contacts: contact[] }> = gql`
  ${CONTACT_CORE_FIELDS}
  query FIND_CONTACT($name: String!, $number: String!) {
    contacts(
      where: {
        _and: [{ name: { _ilike: $name } }, { number: { _ilike: $number } }]
      }
      limit: 10
    ) {
      ...ContactCoreFields
    }
  }
`;

export const ADD_CONTACT = gql`
  ${CONTACT_CORE_FIELDS}
  mutation ADD_CONTACT($object: contacts_insert_input!) {
    insert_contacts_one(object: $object) {
      ...ContactCoreFields
    }
  }
`;

export const GET_CONTACTS_BY_IDS: TypedDocumentNode<{
  contacts: contact[];
}> = gql`
  ${CONTACT_CORE_FIELDS}
  query GET_CONTACTS_BY_IDS($ids: [Int!]!) {
    contacts(where: { id: { _in: $ids } }) {
      ...ContactCoreFields
    }
  }
`;

export const GET_CONTACTS_CONDITIONAL: TypedDocumentNode<{
  contacts: contact[];
}> = gql`
  ${CONTACT_CORE_FIELDS}
  query GET_CONTACTS_CONDITIONAL($where: contacts_bool_exp!) {
    contacts(where: $where) {
      ...ContactCoreFields
    }
  }
`;

export const GET_CONTACTS: TypedDocumentNode<{
  contacts: contact[];
}> = gql`
  ${CONTACT_CORE_FIELDS}
  query GET_CONTACTS {
    contacts {
      ...ContactCoreFields
    }
  }
`;

export const UPDATE_CONTACT_BY_ID: TypedDocumentNode<{
  update_contacts_by_pk: contact;
}> = gql`
  ${CONTACT_CORE_FIELDS}
  mutation UPDATE_CONTACT_BY_ID($id: Int!, $set: contacts_set_input!) {
    update_contacts_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ContactCoreFields
    }
  }
`;
