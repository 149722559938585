import Chip from "@mui/material/Chip";
import { tag } from "../../../../../types/common";
import { useState } from "react";
import TagControl from "../../tagControl";
import { useMutation } from "@apollo/client";
import {
  DELETE_TASK_TAG,
  UPDATE_TASK_TAG_BY_ID,
} from "../../../../../gqls/task";
import { cacheEvict } from "../../../../../utils/cacheMethods";

interface props {
  tag: tag;
}

export default function TaskTag({ tag }: props) {
  const [editing, setEditing] = useState(false);

  const [update] = useMutation(UPDATE_TASK_TAG_BY_ID);
  const [remove] = useMutation(DELETE_TASK_TAG);

  const onSubmit = (data: { name: string }) => {
    update({
      variables: {
        id: tag.id,
        set: data,
      },
      onCompleted() {
        setEditing(false);
      },
    });
  };

  const onDelete = () => {
    remove({
      variables: {
        id: tag.id,
      },
      onCompleted() {
        setEditing(false);
      },
      update(cache) {
        cacheEvict({
          cache,
          id: tag.id,
          __typename: "taskTags",
        });
      },
    });
  };

  return (
    <>
      <Chip
        color="success"
        variant="outlined"
        clickable
        onClick={() => {
          setEditing(true);
        }}
        label={tag.name}
        key={tag.id}
      />

      {editing && (
        <TagControl
          dismiss={() => {
            setEditing(false);
          }}
          onSubmit={onSubmit}
          tag={tag}
          onDelete={onDelete}
        />
      )}
    </>
  );
}
