import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_BIZTALK_BY_ID } from "../../../../gqls/biztalk";
import BiztalkControl from "./biztalkControl/biztalkControl";

export default function EditBiztalk() {
  const { id } = useParams();

  const { data } = useQuery(GET_BIZTALK_BY_ID, {
    variables: { id },
    onError(error) {
      console.log(error);
    },
  });

  const biztalk = data?.biztalks_by_pk;

  if (!biztalk) {
    return null;
  }

  return <BiztalkControl biztalk={biztalk} />;
}
