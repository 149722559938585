import { useNavigate } from "react-router-dom";
import { task } from "../../../../../../types/task";
import ListKanbanItem from "../listKanbanItem/listKanbanItem";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

interface props {
  task: task;
}
export default function KanbanItem({ task }: props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: task.id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  const navigate = useNavigate();

  const toDetail = () => {
    navigate(`/tasks/detail/${task.id}`);
  };

  return (
    <div
      className="shadow-md"
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      onClick={toDetail}
    >
      <ListKanbanItem task={task} isKanban={true} />
    </div>
  );
}
