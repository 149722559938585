import { useEffect } from "react";
import { extendedCustomer } from "../../../../../types/customer";
import Directory from "../../../common/files/directory";
import { useCheckAuth } from "../../../../../store/authStore";
import { useNavigate } from "react-router-dom";

interface props {
  customer: extendedCustomer;
}

export default function CustomerFile({ customer }: props) {
  const dir = `customers/${customer.id}`;
  const installerDir = `customers/${customer.id}/installer`;
  const customerDir = `customers/${customer.id}/customerPhotos`;

  const checkAuth = useCheckAuth();

  const navigate = useNavigate();
  const companyIds = [customer?.companyId || 0, customer.sourceCompanyId || 0];

  useEffect(() => {
    if (
      !checkAuth({
        permissionName: "고객_파일열람",
        companyIds,
      })
    ) {
      navigate(-1);
    }
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-4 p-2 px-3 md:px-0 md:mt-6">
      {/* ROOT Files*/}
      <Directory name="기본" dir={dir} />
      {/* INSTALLER Files */}
      <Directory name="설치기사용" dir={installerDir} />
      {/* CUSTOMER Files */}
      <Directory name="고객 사진" dir={customerDir} />
    </div>
  );
}
