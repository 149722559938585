import ReplyIcon from "@mui/icons-material/Reply";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import InvProductControl from "../inventoryControls/invProductControl";
import InvLocationControl from "../inventoryControls/invLocationControl";
import { useInvStore } from "../../../../store/invStore";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { sort } from "fast-sort";
import LoadingButton from "@mui/lab/LoadingButton";
import { invAction } from "../../../../types/inv";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_INVENTORY,
  ADD_INV_QTY,
  GET_INVENTORY_CONDITIONAL,
  GET_INV_QTY_CONDITIONAL,
  UDPATE_INV_QTY_BY_ID,
} from "../../../../gqls/inv";
import { INVENTORY_FIELDS, INV_QTY_FIELDS } from "../../../../fragments/inv";
import { useAddAlert } from "../../../../store/alertStore";
import { useState } from "react";
import useAddInvHistory from "../../../../hooks/useAddInvHistory";
import hasuraFilter from "../../../../utils/hasuraFilter";

const schema = z.object({
  productId: z.coerce.number().min(1),
  location: z.object({
    type: z.string(),
    id: z.coerce.number().min(1),
  }),
  qty: z.coerce.number().min(1),
  actionId: z.coerce.number().min(1),
  description: z.string().nullable().optional(),
});

export default function AddInventory() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/inventory");
  };

  const { id, productId } = useParams();

  const defaultValues = {
    productId: Number(productId),
    location: {
      type: "company",
      id: Number(id),
    },
    qty: 0,
    actionId: 0,
    description: null,
  };

  const methods = useForm({ defaultValues, resolver: zodResolver(schema) });

  let { invActions } = useInvStore();
  invActions = sort(invActions.filter(a => a.type == "add")).asc("priority");

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors, isDirty, touchedFields },
  } = methods;

  const [getInv] = useLazyQuery(GET_INVENTORY_CONDITIONAL);
  const [addInv] = useMutation(ADD_INVENTORY);
  const [getInvQty] = useLazyQuery(GET_INV_QTY_CONDITIONAL);
  const [addInvQty] = useMutation(ADD_INV_QTY);
  const [updateInvQty] = useMutation(UDPATE_INV_QTY_BY_ID);
  const addHistory = useAddInvHistory();

  const addAlert = useAddAlert();

  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async data => {
    setLoading(true);

    const { productId, location, qty, actionId, description } = data;

    const invAction = invActions.find(a => a.id == actionId) as invAction;

    const locationType = `${location.type}Id`;

    let existingInvData = await getInv({
      variables: {
        where: {
          productId: { _eq: productId },
          [locationType]: { _eq: location.id },
        },
      },
      fetchPolicy: "network-only",
    });

    let existingInv =
      existingInvData.data?.inventory && existingInvData.data?.inventory[0];

    await (async () => {
      if (!existingInv) {
        const { data } = await addInv({
          variables: {
            object: {
              productId,
              [locationType]: location.id,
            },
          },
          update(cache, { data }) {
            const inv = data.insert_inventory_one;
            cache.modify({
              fields: {
                inventory(existing = [], { storeFieldName }) {
                  const filtered = hasuraFilter({
                    items: [inv],
                    name: "inventory",
                    string: storeFieldName,
                  });

                  const newInvRefs = filtered.map(sd =>
                    cache.writeFragment({
                      data: sd,
                      fragment: INVENTORY_FIELDS,
                      fragmentName: "InventoryFields",
                    })
                  );
                  return [...existing, ...newInvRefs];
                },
              },
            });
          },
        });
        if (data) {
          existingInv = data.insert_inventory_one;
        }
        return;
      } else {
        return;
      }
    })();

    if (!existingInv) {
      setLoading(false);
      return addAlert({
        message: "재고 추가에 실패 했습니다",
        type: "error",
      });
    }

    const toStatId = invAction.statusId;

    const { data: invQtyData } = await getInvQty({
      variables: {
        where: {
          invId: { _eq: existingInv.id },
          statusId: { _eq: toStatId },
        },
      },
      fetchPolicy: "network-only",
    });

    const existingInvQty = invQtyData?.invQty && invQtyData?.invQty[0];

    if (existingInvQty) {
      const { data } = await updateInvQty({
        variables: {
          id: existingInvQty.id,
          set: {
            qty: existingInvQty.qty + qty,
          },
        },
      });

      if (!data) {
        setLoading(false);
        return addAlert({
          message: "재고 추가에 실패 했습니다 - 재고 수량 수정",
          type: "error",
        });
      }

      await addHistory({
        invActionId: invAction.id,
        changes: [
          {
            statusId: invAction.statusId,
            diff: qty,
            invId: existingInv.id,
          },
        ],
        description: description ? description : undefined,
        fromId: existingInv.id,
        toId: existingInv.id,
      });

      reset();
      setLoading(false);
      addAlert({
        message: "재고를 성공적으로 추가했습니다",
        type: "success",
      });
      goBack();
    } else {
      const { data: addedInvQtyData } = await addInvQty({
        variables: {
          object: {
            invId: existingInv.id,
            statusId: toStatId,
            qty,
          },
        },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              invQty(existing = [], { storeFieldName }) {
                if (!storeFieldName.includes(String(existingInv?.id))) {
                  return existing;
                }
                const newInvQtyRef = cache.writeFragment({
                  data: data?.insert_invQty_one,
                  fragment: INV_QTY_FIELDS,
                });
                return [...existing, newInvQtyRef];
              },
            },
          });
        },
      });

      if (!addedInvQtyData) {
        setLoading(false);
        return addAlert({
          message: "재고 추가에 실패 했습니다 - 재고 수량 추가",
          type: "error",
        });
      }

      await addHistory({
        invActionId: invAction.id,
        fromId: existingInv.id,
        toId: existingInv.id,
        changes: [
          {
            statusId: invAction.statusId,
            diff: qty,
            invId: existingInv.id,
          },
        ],
        description: description ? description : undefined,
      });

      reset();
      setLoading(false);
      addAlert({
        message: "재고를 성공적으로 추가했습니다",
        type: "success",
      });
      goBack();
    }
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className="flex-1 py-4 md:p-8 flex flex-col md:drop-shadow-md md:bg-white overflow-x-clip gap-4"
      >
        <div className="flex flex-row justify-between items-center">
          <h1 className="text-xl md:text-2xl">재고 추가</h1>
          <div
            className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
            onClick={goBack}
          >
            <ReplyIcon />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4">
          <InvProductControl name="productId" />
          <div className="col-span-1 flex flex-col gap-4">
            <InvLocationControl />
            <div className="flex flex-col gap-1">
              <label>재고 액션</label>
              <Controller
                name="actionId"
                control={control}
                render={({ field }) => (
                  <Select
                    margin="none"
                    size="small"
                    required
                    className="shadow-md"
                    color="success"
                    error={
                      touchedFields.actionId && errors.actionId?.message
                        ? true
                        : false
                    }
                    value={field.value || 0}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    ref={field.ref}
                    disabled={field.disabled}
                  >
                    <MenuItem value={0}>재고 액션을 선택하세요</MenuItem>
                    {invActions?.map(action => (
                      <MenuItem key={action.id} value={action.id}>
                        {action.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label>수량</label>
              <TextField
                type="number"
                margin="none"
                size="small"
                color="success"
                error={touchedFields.qty && errors.qty?.message ? true : false}
                className="shadow-md"
                {...register("qty")}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label>비고</label>
          <TextField
            margin="none"
            size="small"
            color="success"
            placeholder="비고 입력란"
            error={
              touchedFields.description && errors.description?.message
                ? true
                : false
            }
            className="shadow-md"
            {...register("description")}
          />
        </div>
        <div className="flex flex-row justify-end">
          <LoadingButton
            loading={loading}
            disabled={!isDirty}
            type="submit"
            variant="contained"
            color="success"
            sx={{ backgroundColor: "black", fontWeight: 400 }}
          >
            추가
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
}
