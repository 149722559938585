import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { GET_TASK_BY_PK, UPDATE_TASK_BY_ID } from "../../../../gqls/task";
import ReplyIcon from "@mui/icons-material/Reply";
import MyBadge from "../../../../comps/myBadge";
import ReportControls from "./reportControls/reportControls";
import { z } from "zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { ADD_REPORT } from "../../../../gqls/report";
import dayjs from "dayjs";
import { tempImage } from "../../../../types/report";
import Resizer from "react-image-file-resizer";
import { useInterval } from "usehooks-ts";
import { useTaskStore } from "../../../../store/taskStore";
import { useAuthStore } from "../../../../store/authStore";
import { useAddAlert } from "../../../../store/alertStore";
import hasuraFilter from "../../../../utils/hasuraFilter";
import { REPORT_CORE_FIELDS } from "../../../../fragments/report";
import useS3 from "../../../../hooks/useS3";
import { useEffect, useState } from "react";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../classPresets";
import useAddCustomerHistory from "../../../../hooks/useAddCustomerHistory";
import { base64ToBlob } from "../../../../utils/objectArrayMethods";

interface props {
  nested?: boolean;
}

const schema = z.object({
  typeId: z.coerce.number().min(1),
  checks: z.array(z.coerce.number()).nullable().optional(),
  received_on_site: z.boolean().nullable().optional(),
  received_amount: z.coerce.number().nullable().optional(),
  charge: z.coerce.number().nullable().optional(),
  serials: z.array(z.string()).nullable().optional(),
  description: z.string().nullable().optional(),
  date: z.coerce.date(),
  images: z.array(z.any()).nullable().optional(),
  customerSign: z.string().nullable().optional(),
  installerSign: z.string().nullable().optional(),
});

export default function AddReport({ nested }: props) {
  const { id } = useParams();

  const { data } = useQuery(GET_TASK_BY_PK, {
    variables: {
      id,
    },
    onError(error) {
      console.log(error);
    },
  });

  const task = data?.tasks_by_pk;

  const navigate = useNavigate();
  const fullNum = `#${task?.customer.year}-${task?.customer.number}`;

  const defaultValues = {
    typeId: 0,
    checks: [] as number[],
    charge: null as null | number,
    received_on_site: null as null | boolean,
    received_amount: null as null | number,
    serials: [] as string[],
    description: null as null | string,
    date: dayjs(),
    images: [] as tempImage[],
    customerSign: null as null | string,
    installerSign: null as null | string,
  };

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
    getValues,
  } = methods;

  useEffect(() => {
    const savedValuesString = localStorage.getItem(`@report:${id}`);

    const savedValues = savedValuesString
      ? JSON.parse(savedValuesString)
      : null;

    if (!savedValues) {
      return;
    }
    const images = getValues("images");
    reset({
      typeId: savedValues?.typeId || 0,
      checks: savedValues?.checks || ([] as number[]),
      charge: savedValues?.charge || (null as null | number),
      serials: savedValues?.serials || ([] as string[]),
      description: savedValues?.description || (null as null | string),
      received_on_site:
        savedValues?.received_on_site || (null as null | boolean),
      received_amount: savedValues?.received_amount || (null as null | number),
      date: savedValues?.date ? dayjs(savedValues.date) : dayjs(),
      images,
      customerSign: savedValues?.customerSign || (null as null | string),
      installerSign: savedValues?.installerSign || (null as null | string),
    });
  }, []);

  const [addReport] = useMutation(ADD_REPORT);
  const [updateTask] = useMutation(UPDATE_TASK_BY_ID);

  const [loading, setLoading] = useState(false);

  const resize = (file: File) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        "PNG",
        90,
        0,
        uri => {
          resolve(uri);
        },
        "file"
      );
    });

  const { taskTypeChecks, reportTypes } = useTaskStore();

  const { user } = useAuthStore();

  const addAlert = useAddAlert();

  const { uploadFile, getObjectsInDir, moveDir } = useS3();

  const { addHistory } = useAddCustomerHistory();

  const onSubmit = handleSubmit(async data => {
    setLoading(true);
    const {
      charge,
      checks,
      customerSign,
      date,
      description,
      images,
      installerSign,
      serials,
      typeId,
      received_on_site,
      received_amount,
    } = data;

    const typedImages = images as tempImage[];

    const reportType = reportTypes.find(t => t.id === typeId);

    if (!reportType) {
      setLoading(false);
      return;
    }

    const { needAllChecks, photoTemplate } = reportType;

    const myChecks = taskTypeChecks.filter(
      check => check.typeId == task?.typeId
    );

    if (needAllChecks && myChecks.some(t => !checks.includes(t.id))) {
      setLoading(false);
      return addAlert({
        message: "확인 사항을 모두 체크해주세요",
        type: "warning",
      });
    }

    if (
      photoTemplate &&
      photoTemplate.some(p => {
        const tempImage = typedImages.find(ti => ti.name === p);
        if (!tempImage) {
          return true;
        }

        if (!tempImage.image) {
          return true;
        }
        return false;
      })
    ) {
      setLoading(false);
      return addAlert({
        message: "필수 이미지를 등록해주세요",
        type: "warning",
      });
    }

    if (!installerSign || !customerSign) {
      setLoading(false);
      return addAlert({
        message: "서명을 등록해주세요",
        type: "warning",
      });
    }

    const object = {
      taskId: task?.id,
      received_on_site,
      received_amount,
      charge,
      checks,
      date: date.toISOString(),
      description,
      serials,
      typeId,
      reporterId: user?.id,
      reads: [user?.id],
      checked: false,
    };

    // handle images
    const filteredImages = typedImages
      .filter(t => t.image)
      .map((t, i) => ({
        ...t,
        name: `${t.name.trim() == "" ? `제목없음_${i + 1}` : t.name}`,
      }));

    const dir = `customers/${task?.customerId}/reports/temp/${typeId}`;

    // check uploaded images
    const uploadedImages = [] as string[];
    const existingImgContents = await getObjectsInDir(dir);
    if (existingImgContents && existingImgContents.Contents) {
      for await (const content of existingImgContents.Contents) {
        if (content && content.Key) {
          uploadedImages.push(
            content.Key.split("/").pop()?.split(".")[0] as string
          );
        }
      }
    }

    // check uploaded signs
    const uploadedSigns = [] as string[];
    const existingSignContents = await getObjectsInDir(`${dir}/signs`);
    if (existingSignContents && existingSignContents.Contents) {
      for await (const content of existingSignContents.Contents) {
        if (content && content.Key) {
          uploadedSigns.push(
            content.Key.split("/").pop()?.split(".")[0] as string
          );
        }
      }
    }

    const imageRes = await Promise.all(
      filteredImages.map(async image => {
        if (!image.image) {
          return {
            ...image,
            res: false,
            reason: "image not found",
          };
        }

        if (uploadedImages.includes(image.name)) {
          return {
            ...image,
            res: true,
            reason: null,
          };
        }

        const ext = image.image.name.split(".").pop();
        const name = `${image.name}.${ext}`;

        const processedImage = await (async () => {
          if (!image.image) {
            return {
              ...image,
              res: false,
              reason: "image not found",
            };
          }

          if (image.image.size > 5000000) {
            const resizedImage = await resize(image.image);
            const keyName = `${image.name}.png`;

            return {
              name: keyName,
              image: resizedImage,
              ext: "png",
              reason: null,
            };
          } else {
            return {
              name,
              image: image.image,
              ext,
              reason: null,
            };
          }
        })();

        if (!processedImage) {
          return {
            ...image,
            res: false,
            reason: "image not processed",
          };
        }

        const res = await uploadFile(
          processedImage.image as File,
          `${dir}/${processedImage.name}`,
          `image/${processedImage.ext}`
        );

        if (!res) {
          const res = await uploadFile(
            image.image,
            `${dir}/${name}`,
            `image/${ext}`
          );

          return {
            ...image,
            res,
            reason: "image upload failed (original)",
          };
        }

        return {
          ...image,
          res,
          reason: "image upload failed",
        };
      })
    );

    await (async () => {
      if (uploadedSigns.includes("customer")) {
        return;
      }

      if (customerSign) {
        const sign = await base64ToBlob(customerSign);
        await uploadFile(sign, `${dir}/signs/customer.png`, "image/png");
        return;
      } else {
        return;
      }
    })();

    await (async () => {
      if (uploadedSigns.includes("installer")) {
        return;
      }

      if (installerSign) {
        const sign = await base64ToBlob(installerSign);
        await uploadFile(sign, `${dir}/signs/installer.png`, "image/png");
        return;
      } else {
        return;
      }
    })();

    const imageUploadFailed = imageRes.some(i => i?.res === false);
    const reasons = imageRes.map(i => String(i.reason)).join(", ");

    // terminate if imageUploadFailed
    if (imageUploadFailed) {
      setLoading(false);
      addAlert({
        message: `일부 사진이 업로드 되지 않았습니다, 사진을 재추가해주세요 디버깅 메세지:(${reasons})`,
        type: "error",
        persist: true,
      });
      return;
    }

    addReport({
      variables: {
        object,
      },
      update(cache, { data }) {
        const report = data.insert_reports_one;

        cache.modify({
          fields: {
            reports(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: [report],
                name: "reports",
                string: storeFieldName,
              });

              const newReportRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: REPORT_CORE_FIELDS,
                  fragmentName: "ReportCoreFields",
                })
              );
              return [...existing, ...newReportRefs];
            },
          },
        });
      },
      onCompleted: async data => {
        addHistory({
          customerId: task?.customerId || 0,
          message: `보고 등록`,
          type: "task",
          link: `/tasks/detail/${task?.id}`,
          taskId: task?.id,
        });

        //  move uploaded images
        const oldDir = dir;
        const destinationDir = `customers/${task?.customerId}/reports/${data.insert_reports_one?.id}`;

        await moveDir(oldDir, destinationDir);

        setLoading(false);
        reset();
        updateTask({
          variables: {
            id: task?.id,
            set: {
              reads: [user?.id],
            },
          },
        });
        localStorage.removeItem(`@report:${id}`);

        addAlert({
          message: "보고를 성공적으로 추가하였습니다",
          type: "success",
        });

        navigate(
          `/tasks/detail/${task?.id}/reportDetail/${data.insert_reports_one?.id}`
        );
      },
      onError(error) {
        console.log(error);
        setLoading(false);
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <Persist id={Number(id)} />
      <form
        onSubmit={onSubmit}
        className={`flex-1 flex flex-col gap-4 ${!nested && "md:mx-10"}`}
      >
        <div
          className={`flex-1 pt-5
          ${nested && "pt-0 md:pt-5"}
           ${
             !nested && "md:p-8 md:drop-shadow-md"
           } flex flex-col gap-4 md:bg-white overflow-x-clip`}
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center px-3 md:px-0 gap-4 flex-wrap">
            <div className="flex flex-row items-center gap-2">
              <h1 className="-mb-1">
                {nested ? `보고 등록` : `${fullNum} ${task?.customer.name}`}
              </h1>
              {task && (
                <MyBadge text={task?.type.name} color={task?.type.color} big />
              )}
            </div>
            <div
              className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ReplyIcon />
            </div>
          </div>
          {/* Body */}
          {task && <ReportControls task={task} />}
          {/* Footer */}
          <div className="flex flex-row justify-end gap-4 px-3 md:px-0 bg-gray-100 md:bg-transparent py-5 md:py-0">
            <Button
              {...blackButtonOutlined}
              onClick={() => {
                navigate(-1);
              }}
            >
              취소
            </Button>
            <LoadingButton
              loading={loading}
              {...blackButtonContained}
              type="submit"
            >
              등록
            </LoadingButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

const Persist = ({ id }: { id: number }) => {
  const { getValues } = useFormContext();

  useInterval(() => {
    const value = getValues();
    localStorage.setItem(`@report:${id}`, JSON.stringify(value));
  }, 5000);

  return null;
};
