import { useMutation, useQuery } from "@apollo/client";
import { task } from "../../../../types/task";
import { GET_INV_HISTORY_BY_PK } from "../../../../gqls/inv";
import { useProductStore } from "../../../../store/productStore";
import Button from "@mui/material/Button";
import useCancelHistoryChanges from "../../../../hooks/useCancelHistoryChanges";
import { UPDATE_TASK_BY_ID } from "../../../../gqls/task";

export default function TaskAssignedInventory({ task }: { task: task }) {
  const { invHistoryId } = task;

  const { data } = useQuery(GET_INV_HISTORY_BY_PK, {
    variables: {
      id: invHistoryId,
    },
  });

  const invHistory = data?.invHistories_by_pk;
  const productId = invHistory?.from?.productId;

  const { products } = useProductStore();

  const product = products.find(p => p.id === productId);

  const cancelHistoryChanges = useCancelHistoryChanges();

  const [update] = useMutation(UPDATE_TASK_BY_ID);

  const reclaim = async () => {
    if (!invHistoryId) {
      return;
    }
    const proceed = confirm("작업에서 할당된 재고를 회수하시겠습니까?");
    if (!proceed) {
      return;
    }
    await cancelHistoryChanges(invHistoryId);
    update({
      variables: {
        id: task.id,
        set: {
          invHistoryId: null,
        },
      },
    });
  };

  if (!invHistory) {
    return null;
  }

  return (
    <div className="border-[1px] p-2 flex flex-row justify-between items-center">
      <div className="text-gray-500 ">
        작업 할당 재고 : {product?.name} ({Math.abs(invHistory.changes[0].diff)}
        )
      </div>
      <Button
        size="small"
        color="success"
        sx={{ color: "gray" }}
        onClick={reclaim}
      >
        회수
      </Button>
    </div>
  );
}
