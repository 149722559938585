import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import AutomationRow from "./automationRow";
import { useQuery } from "@apollo/client";
import { GET_AUTOMATIONS } from "../../../../gqls/automation";
import { blackButtonContained } from "../../../../classPresets";

export default function Automations() {
  const { data } = useQuery(GET_AUTOMATIONS);

  const automations = data?.automations || [];

  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <h1 className="md:mb-4">자동화 목록</h1>

      {automations.map(automation => (
        <AutomationRow automation={automation} key={automation.id} />
      ))}

      <div className="flex flex-row justify-end mt-4">
        <Link to="/settings/automation/new">
          <Button {...blackButtonContained}>자동화 등록</Button>
        </Link>
      </div>
    </div>
  );
}
