import { useMediaQuery } from "usehooks-ts";
import { customer } from "../../../../../types/customer";
import { useEffect } from "react";
import { useCustomerStore } from "../../../../../store/customerStore";
import List from "./list";
import Kanban from "./kanban/kanban";
import Table from "./table/table";
import { tab, view } from "../../../../../types/common";

interface props {
  customers: customer[];
  tab: tab;
  loadMore: () => void;
  loading: boolean;
  fetchingMore: boolean;
  canLoadMore: boolean;
  totalLength: number;
}
export default function Views({
  customers,
  tab,
  loadMore,
  canLoadMore,
  loading,
  fetchingMore,
  totalLength,
}: props) {
  const isBig = useMediaQuery("(min-width: 768px)", {
    initializeWithValue: true,
  });

  const { setTabs, tabs } = useCustomerStore();

  const changeView = (view: view) => {
    setTabs(
      tabs.map(t => {
        if (t.id == tab.id) {
          return {
            ...t,
            view,
          };
        }
        return t;
      })
    );
  };

  useEffect(() => {
    if (isBig) {
      return;
    }

    if (tab.view !== "list") {
      changeView("list");
    }
  }, [isBig]);

  const view = tab.view;

  return (
    <>
      {view == "list" && (
        <List
          customers={customers}
          loadMore={loadMore}
          canLoadMore={canLoadMore}
          loading={loading}
          fetchingMore={fetchingMore}
          totalLength={totalLength}
        />
      )}
      {view == "kanban" && <Kanban customers={customers} tab={tab} />}
      {view == "table" && <Table customers={customers} />}
    </>
  );
}
