import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useCustomerStore } from "../../../../store/customerStore";
import { useEffect } from "react";
import { sort } from "fast-sort";
import { useAuthStore } from "../../../../store/authStore";

export default function CustomerStatusControl() {
  const { control, getValues, setValue } = useFormContext();

  let { statuses } = useCustomerStore();

  const { user } = useAuthStore();
  const companyStats = user?.company?.customerStatusIds;

  const allowedScopes = ["edit", "access"];

  statuses = sort(statuses)
    .asc("priority")
    .filter(
      stat =>
        (user?.auth.name == "admin" ||
          user?.auth?.customerStatusPermissions?.some(
            perm => perm.id == stat.id && allowedScopes.includes(perm.scope)
          )) &&
        (companyStats ? companyStats.includes(stat.id) : true)
    );

  useEffect(() => {
    if (!statuses) {
      return;
    }
    const statusId = getValues("statusId");

    if (statuses.some(type => type.id === statusId)) {
      return;
    }
    setValue("statusId", statuses[0]?.id);
  }, [statuses]);

  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>상태</label>
      <Controller
        name="statusId"
        control={control}
        render={({ field }) => (
          <Select
            margin="none"
            size="small"
            required
            className="shadow-md"
            color="success"
            value={field.value || 0}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled}
          >
            <MenuItem value={0}>고객 상태를 선택하세요</MenuItem>
            {statuses.map(stat => (
              <MenuItem value={stat.id} key={stat.id}>
                <div className="flex flex-row gap-2 items-center">
                  <div
                    className="p-1 rounded-full"
                    style={{ backgroundColor: stat.color }}
                  />
                  {stat.name}
                </div>
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </div>
  );
}
