import { useRoutes } from "react-router-dom";
import Container from "./container";
import Dashboard from "./dashboard/dashboard";
import Settings from "./settings/settings";
import Inventory from "./inventory/inventory";
import Customers from "./customers/customers";
import Tasks from "./tasks/tasks";
import CustomerPhoto from "../public/customerPhoto";
import Profile from "./profile";
import CustomerMigration from "./migration/customer/customerMigration";
import TaskMigration from "./migration/task/taskMigration";
import ReportMigration from "./migration/report/reportMigration";

export default function ProtectedRoot() {
  let routes = useRoutes([
    {
      path: "/",
      element: <Container />,
      children: [
        { path: "/", element: <Dashboard /> },
        { path: "*", element: <Dashboard /> },
        { path: "customers/*", element: <Customers /> },
        { path: "settings/*", element: <Settings /> },
        { path: "inventory/*", element: <Inventory /> },
        { path: "tasks/*", element: <Tasks /> },
        { path: "profile", element: <Profile /> },
        { path: "customerPage/:type/:id", element: <CustomerPhoto /> },
        { path: "migration/customer", element: <CustomerMigration /> },
        { path: "migration/task", element: <TaskMigration /> },
        { path: "migration/report", element: <ReportMigration /> },
      ],
    },
  ]);

  return routes;
}
