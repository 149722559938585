import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { invAction } from "../../../../../types/inv";
import { useInvStore } from "../../../../../store/invStore";
import { UPDATE_INV_ACTION_BY_ID } from "../../../../../gqls/inv";

import InvActionControl from "./invActionControl";

interface props {
  invAction: invAction;
  setMode: React.Dispatch<React.SetStateAction<"editing" | "deleting" | null>>;
}

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
  color: z.string().min(1, { message: "required" }),
  type: z.enum(["add", "delete", "move", "update", "assign"]),
  statusId: z.coerce.number().nullable().optional(),
  fromStatIds: z.array(z.coerce.number()).nullable().optional(),
  linger: z.boolean(),
});

export default function EditInvAction({ invAction, setMode }: props) {
  const { name, description, color, type, statusId, fromStatIds, linger } =
    invAction;

  const { updateInvActions } = useInvStore();

  const defaultValues = {
    name,
    description,
    color,
    type,
    statusId,
    fromStatIds,
    linger,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isDirty, errors },
  } = methods;

  console.log(errors);

  const [update, { loading }] = useMutation(UPDATE_INV_ACTION_BY_ID);

  const onSubmit = handleSubmit(data => {
    if (data.type !== "delete" && !data.statusId) {
      return;
    }
    update({
      variables: {
        id: invAction.id,
        set: data,
      },
      onCompleted(updatedData) {
        const updatedInvAction = updatedData.update_invActions_by_pk;
        updateInvActions([updatedInvAction]);
        setMode(null);
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <Dialog
        open={true}
        onClose={() => {
          setMode(null);
        }}
      >
        <DialogTitle>재고 액션 수정</DialogTitle>
        <DialogContent>
          <InvActionControl invAction={invAction} />
        </DialogContent>
        <DialogActions>
          <div
            className="text-red-500 cursor-pointer"
            onClick={() => {
              setMode("deleting");
            }}
          >
            <DeleteForever />
          </div>
          <Button
            sx={{ color: "black" }}
            onClick={() => {
              setMode(null);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={onSubmit}
          >
            수정
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
