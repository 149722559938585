import { useState } from "react";
import { isMobileSafari } from "react-device-detect";
import Close from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";

export default function PromptSafariInstall() {
  const [show, setShow] = useState(
    // @ts-ignore
    window.navigator?.standalone === true ? false : true
  );

  return (
    <div
      onClick={() => setShow(false)}
      className={`${
        isMobileSafari && show ? "flex" : "hidden"
      } flex-col gap-1 absolute p-4 bottom-5 right-1/2 translate-x-1/2 bg-quezone rounded-md w-4/5 text-center break-keep text-white cursor-pointer`}
    >
      <Close
        className="text-white absolute top-1 right-1"
        sx={{ width: 15, height: 15 }}
      />
      <p>앱을 홈 화면에 설치하시면 알림 기능을 활용하실 수 있습니다.</p>
      <p>
        아래 <IosShareIcon sx={{ width: 20, height: 20 }} /> 버튼을 누르신 후
      </p>
      <p>
        <span className="font-medium text-gray-50">홈 화면에 추가</span> 를
        선택해주세요
      </p>
      <div className="w-3 h-3 absolute rotate-45 bg-quezone top-full right-1/2 translate-x-1/2 -translate-y-1/2" />
    </div>
  );
}
