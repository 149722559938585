import { useMutation, useQuery } from "@apollo/client";
import { mongoReport } from "../types";
import { GET_USERS } from "../../../../gqls/user";
import { GET_TASKS_M } from "../../../../gqls/task";
import { useTaskStore } from "../../../../store/taskStore";
import { ADD_REPORT } from "../../../../gqls/report";
import useS3 from "../../../../hooks/useS3";
import { base64ToBlob } from "../../../../utils/objectArrayMethods";

export default function useReportUpload() {
  const { data: usersData } = useQuery(GET_USERS);
  const users = usersData?.users || [];

  const { data: taskData } = useQuery(GET_TASKS_M);

  const tasks = taskData?.tasks || [];

  const { reportTypes } = useTaskStore();
  const [add] = useMutation(ADD_REPORT);

  const { uploadFile } = useS3();

  const upload = async (report: mongoReport) => {
    try {
      const {
        checks,
        date,
        desc,
        reportDate,
        serials,
        sign,
        taskId,
        taskTypeId,
        type,
        extraCost,
        reporter,
        subCategory,
        photos,
      } = report;

      const task = tasks.find(t => t.uuid == taskId);

      if (!task) {
        console.log("no task");
        return false;
      }

      const reporterUser = users.find(user => user.email === reporter);

      if (!reporterUser) {
        console.log("no reporter");
        return false;
      }

      const typeId = reportTypes.find(
        t =>
          t.taskTypeId == taskTypeId &&
          (t.name == subCategory || t.name == type || t.name == "완료")
      )?.id;

      const converted = {
        taskId: task?.id,
        charge: extraCost,
        checks,
        created_at: date,
        updated_at: date,
        date: reportDate,
        description: desc,
        serials,
        reporterId: reporterUser.id,
        reads: [reporterUser.id],
        typeId,
        uuid: report._id,
      };

      const res = await add({
        variables: {
          object: converted,
        },
        onError(error) {
          console.log(error);
        },
      });

      const inserted = res.data?.insert_reports_one;

      if (!inserted) {
        console.log("no inserted");
        return false;
      }

      const dir = `customers/${task.customerId}/reports/${inserted.id}`;

      await Promise.all(
        (photos || [])?.map(async photo => {
          if (!photo.data) {
            return;
          }

          const ext = photo.data.split(";")[0].split("/")[1];
          const name = `${photo.name}.${ext}`;

          const blob = await base64ToBlob(photo.data);
          await uploadFile(blob, `${dir}/${name}`, `image/${ext}`);
          return;
        })
      );

      await (async () => {
        if (sign.customer) {
          const blob = await base64ToBlob(sign.customer);
          await uploadFile(blob, `${dir}/signs/customer.png`, "image/png");
          return;
        } else {
          return;
        }
      })();

      await (async () => {
        if (sign.installer) {
          const blob = await base64ToBlob(sign.installer);
          await uploadFile(blob, `${dir}/signs/installer.png`, "image/png");
          return;
        } else {
          return;
        }
      })();

      return inserted;
    } catch (error) {
      console.log(report, error);

      return false;
    }
  };

  return { upload };
}
