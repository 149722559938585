import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { cacheEvict } from "../../../../../utils/cacheMethods";
import { useInvStore } from "../../../../../store/invStore";
import { invAction } from "../../../../../types/inv";
import { DELETE_INV_ACTION } from "../../../../../gqls/inv";

interface props {
  invAction: invAction;
  setMode: React.Dispatch<React.SetStateAction<"editing" | "deleting" | null>>;
}
export default function DeleteInvAction({ invAction, setMode }: props) {
  const { deleteInvAction } = useInvStore();

  const [remove, { loading }] = useMutation(DELETE_INV_ACTION);

  const _delete = async () => {
    remove({
      variables: {
        id: invAction.id,
      },
      onCompleted() {
        deleteInvAction(invAction.id);
        setMode(null);
      },
      update(cache) {
        cacheEvict({
          cache,
          id: invAction.id,
          __typename: "invActions",
        });
      },
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        setMode(null);
      }}
    >
      <DialogTitle>재고 액션 삭제</DialogTitle>
      <DialogContent>
        {invAction.name} 재고 액션을 삭제 하시겠습니까?
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: "black" }}
          onClick={() => {
            setMode(null);
          }}
        >
          취소
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="success"
          onClick={_delete}
        >
          삭제
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
