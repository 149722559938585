import { useQuery } from "@apollo/client";
import { useCheckAuth } from "../../../../../../store/authStore";
import { customer } from "../../../../../../types/customer";
import { GET_TASKS_CONDITIONAL } from "../../../../../../gqls/task";
import Avatar from "@mui/material/Avatar";
import Add from "@mui/icons-material/Add";
import { NavLink, useNavigate } from "react-router-dom";
import CustomerTask from "./customerTask";
import { AddButton } from "../../../../../../comps/oldMobileComps";
import { sort } from "fast-sort";

interface props {
  customer: customer;
}
export default function CustomerTasks({ customer }: props) {
  const checkAuth = useCheckAuth();

  const { data } = useQuery(GET_TASKS_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: customer.id,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const tasks = data?.tasks || [];

  const companyIds = [customer?.companyId || 0, customer.sourceCompanyId || 0];

  const navigate = useNavigate();

  const goToAdd = () => {
    navigate("./addTask");
  };
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center bg-gray-100 md:bg-transparent px-3 md:px-0 py-3 md:py-0">
        <label className="text-black">작업의뢰</label>
        <div className="md:hidden">
          {checkAuth({
            permissionName: "고객_작업추가",
            companyIds,
          }) && <AddButton onClick={goToAdd} />}
        </div>
      </div>
      <div className="flex flex-col gap-2 px-3 md:px-0">
        {sort(tasks)
          .asc("date")
          .map(t => (
            <CustomerTask key={t.id} task={t} />
          ))}
      </div>
      <hr className="mt-2 hidden md:block" />
      {checkAuth({
        permissionName: "고객_작업추가",
        companyIds,
      }) && (
        <NavLink to="./addTask">
          <div className="hidden md:flex flex-row gap-4 items-center text-quezone mt-1">
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "transparent",
                border: "#007d25 3px solid",
                color: "#007d25",
              }}
            >
              <Add />
            </Avatar>
            작업 추가
          </div>
        </NavLink>
      )}
    </div>
  );
}
