import { gql } from "@apollo/client";
import { TASK_CORE_FIELDS } from "./task";

export const REPORT_TYPE_FIELDS = gql`
  fragment ReportTypeFields on reportTypes {
    id
    name
    description
    color
    priority
    needAllChecks
    taskTypeId
    photoTemplate
  }
`;

export const REPORT_CORE_FIELDS = gql`
  fragment ReportCoreFields on reports {
    id
    typeId
    taskId
    checks
    created_at
    updated_at
    reporterId
    reporter {
      name
      email
    }
    received_amount
    received_on_site
    charge
    serials
    description
    date
    reads
    checked
  }
`;

export const REPORT_ACCOUNT_FIELDS = gql`
  fragment ReportAccountFields on reportAccounts {
    id
    created_at
    updated_at
    item
    amount
    gst
    reportId
  }
`;

export const REPORT_FIELDS = gql`
  ${REPORT_CORE_FIELDS}
  ${REPORT_TYPE_FIELDS}
  ${TASK_CORE_FIELDS}
  ${REPORT_ACCOUNT_FIELDS}
  fragment ReportFields on reports {
    ...ReportCoreFields
    type {
      ...ReportTypeFields
    }
    task {
      ...TaskCoreFields
    }
    accounts {
      ...ReportAccountFields
    }
  }
`;
