import { createPortal } from "react-dom";
import { task } from "../../../../../../types/task";
import Agenda from "./agenda";
import MobileCalendar from "./mobileCalendar/mobileCalendar";
import PcCalendar from "./pcCalendar/pcCalendar";
import { TouchEventHandler, memo, useRef, useState } from "react";
import { useNavStore } from "../../../../../../store/navStore";

interface props {
  tasks: task[];
}
export default function Calendar({ tasks }: props) {
  return (
    <>
      {/* PC */}
      <div className="hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-4 bg-lightBG flex-1 md-p-0">
        <div className="col-span-1 lg:flex flex-col">
          <Agenda tasks={tasks} />
        </div>
        <div className="col-span-2">
          <PcCalendar tasks={tasks} />
        </div>
      </div>
      {/* Mobile */}
      <div className="flex-1 flex flex-col lg:hidden mobile-calendar">
        <MobileCalendar tasks={tasks} />
        <MobileAgenda tasks={tasks} />
      </div>
    </>
  );
}

const MemoizedAgenda = memo(Agenda);

const MobileAgenda = ({ tasks }: { tasks: task[] }) => {
  const initialY = 420;
  const [y, setY] = useState(initialY);

  const mobileAgendaContainer = useRef<null | HTMLDivElement>(null);

  const handleTouch: TouchEventHandler<HTMLDivElement> = e => {
    let newY = e.touches[0].clientY - 30;
    if (newY < 70) newY = 70;
    const maxY = Number(mobileAgendaContainer.current?.offsetHeight) - 20;
    if (newY > maxY) newY = maxY;
    setY(newY);
  };
  const { open } = useNavStore();

  return (
    <>
      {!open && (
        <>
          {createPortal(
            <div
              ref={mobileAgendaContainer}
              className="lg:hidden absolute z-10 w-screen h-[calc(100dvh-64px)] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.2)] rounded-t-3xl px-2"
              style={{ top: y }}
            >
              <div
                className="w-full h-16 z-10 relative flex flex-row justify-center"
                onTouchMove={handleTouch}
              >
                <div className="h-1 w-14 mt-1 bg-gray-200 rounded-md pointer-events-none" />
              </div>
              <div className="-mt-14">
                <MemoizedAgenda tasks={tasks} />
              </div>
            </div>,
            document.body
          )}
        </>
      )}
    </>
  );
};
