import { useQuery } from "@apollo/client";
import { GET_COMPANIES } from "../../../../gqls/company";
import Company from "./company";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { sort } from "fast-sort";
import { blackButtonContained } from "../../../../classPresets";
import { useCheckAuth } from "../../../../store/authStore";

export default function Companies() {
  const { data } = useQuery(GET_COMPANIES);
  const authCheck = useCheckAuth();
  const companies = sort(data?.companies || [])
    .by([{ desc: "active" }, { asc: "typeId" }])
    .filter(c =>
      authCheck({ permissionName: "설정_회사목록열람", companyIds: [c.id] })
    );

  const quezone = companies.find(c => c.isQuezone);

  const navigate = useNavigate();

  const goToAdd = () => {
    navigate("/settings/company/new");
  };

  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <h1 className="hidden md:block mb-4 ">회사 목록</h1>
      {quezone && <Company company={quezone} />}
      {companies
        .filter(c => !c.isQuezone)
        .map(company => (
          <Company key={company.id} company={company} />
        ))}

      <div className="flex flex-row justify-end mt-2">
        <Button {...blackButtonContained} onClick={goToAdd}>
          새 회사 등록
        </Button>
      </div>
    </div>
  );
}
