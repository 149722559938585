import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { typeOrStatus } from "../../../../../../types/common";
import AddCircle from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import { useTaskStore } from "../../../../../../store/taskStore";
import generatePastelColor from "../../../../../../utils/genPastelColor";
import { ADD_REPORT_TYPE } from "../../../../../../gqls/report";
import { REPORT_TYPE_FIELDS } from "../../../../../../fragments/report";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeleteForever from "@mui/icons-material/DeleteForever";

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
  color: z.string().min(1, { message: "required" }),
  needAllChecks: z.boolean(),
  photoTemplate: z.array(z.any()).optional().nullable(),
});

interface props {
  type: typeOrStatus;
}

export default function NewReportType({ type }: props) {
  const [adding, setAdding] = useState(false);
  const { reportTypes: allReportTypes, addReportType } = useTaskStore();

  const reportTypes = allReportTypes.filter(s => s.taskTypeId === type.id);

  const defaultPhotoTemplate = [] as { name: string }[];

  const defaultValues = {
    name: "",
    description: "",
    color: generatePastelColor(),
    needAllChecks: false,
    photoTemplate: defaultPhotoTemplate,
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "photoTemplate",
    control,
  });

  const [insert, { loading }] = useMutation(ADD_REPORT_TYPE);

  const onSubmit = handleSubmit(data => {
    insert({
      variables: {
        object: {
          ...data,
          taskTypeId: type.id,
          priority: reportTypes.length,
          photoTemplate: data.photoTemplate
            .map(t => t.name)
            .filter(t => t !== ""),
        },
      },
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        addReportType(data.insert_reportTypes_one);
        setAdding(false);
        reset({
          ...defaultValues,
          color: generatePastelColor(),
        });
      },
      update(cache, { data: { insert_reportTypes_one: newReport } }) {
        cache.modify({
          fields: {
            reportTypes(existing = []) {
              const newReportTypeRef = cache.writeFragment({
                data: newReport,
                fragment: REPORT_TYPE_FIELDS,
              });
              return [...existing, newReportTypeRef];
            },
          },
        });
      },
    });
  });

  return (
    <>
      <div
        className="text-quezone cursor-pointer"
        onClick={() => setAdding(true)}
      >
        <AddCircle />
      </div>
      <Dialog
        open={adding}
        onClose={() => {
          setAdding(false);
        }}
      >
        <DialogTitle>보고 구분 추가</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.name && touchedFields.name}
              color="success"
              placeholder={
                errors.name?.message ? errors.name?.message : "구분 이름"
              }
              label="이름"
              {...register("name")}
            />
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.description && touchedFields.description}
              color="success"
              placeholder={
                errors.description?.message
                  ? errors.description?.message
                  : "설명"
              }
              label="설명"
              {...register("description")}
            />
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.color && touchedFields.color}
              color="success"
              placeholder={
                errors.color?.message ? errors.color?.message : "색상"
              }
              label="색상"
              type="color"
              defaultValue="#ffffff"
              {...register("color")}
            />
            <div className="flex flex-row gap-2 items-center justify-between">
              <label>사진 프리셋</label>
              <div
                className="cursor-pointer text-quezone"
                onClick={() => {
                  append({ name: "" });
                }}
              >
                <AddCircle />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              {fields.map((f, i) => (
                <div
                  key={f.id}
                  className="pl-2 flex flex-row gap-1 items-center"
                >
                  -{" "}
                  <input
                    {...register(`photoTemplate.${i}.name`)}
                    placeholder="프리셋 이름을 입력하세요"
                    className="outline-none border-none"
                  />
                  <div
                    className="cursor-pointer text-red-500"
                    onClick={() => remove(i)}
                  >
                    <DeleteForever />
                  </div>
                </div>
              ))}
              {fields.length == 0 && <div>없음</div>}
            </div>
            <Controller
              name="needAllChecks"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      checked={field.value}
                      {...field}
                    />
                  }
                  label="확인사항 100% 요구"
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setAdding(false);
              reset();
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={onSubmit}
          >
            추가
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
