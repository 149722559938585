import Popover from "@mui/material/Popover";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { ReactEventHandler, useState } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTaskStore } from "../../../../../../store/taskStore";

export default function CalendarNav() {
  const [pickerOpen, setPickerOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open: ReactEventHandler<HTMLDivElement> = event => {
    setAnchorEl(event.currentTarget);
    setPickerOpen(true);
  };
  const { selectedDate, setSelectedDate } = useTaskStore();

  const moveMonth = (num: number) => {
    setSelectedDate(dayjs(selectedDate).add(num, "month"));
  };

  const button =
    "p-[1px] bg-white rounded-sm ring-1 ring-gray-300 cursor-pointer hover:ring-quezone hover:text-quezone";

  return (
    <div className="flex flex-row items-center justify-between gap-4 w-full mt-1 lg:mt-4">
      <div
        className={button}
        onClick={() => {
          moveMonth(-1);
        }}
      >
        <ChevronLeftIcon sx={{ fontSize: 30 }} />
      </div>
      <div
        className="flex flex-row cursor-pointer gap-1 items-end"
        onClick={open}
      >
        <h2 className="lg:hidden font-bold">
          {dayjs(selectedDate).format("M월")}
        </h2>
        <h1 className="hidden lg:block font-extrabold text-5xl">
          {dayjs(selectedDate).format("MM")}
        </h1>
        <div className="hidden md:block text-2xl">/</div>
        <h3 className="hidden md:block font-extrabold text-3xl">
          {dayjs(selectedDate).format("YYYY")}
        </h3>
      </div>
      <Popover
        open={pickerOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setPickerOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            openTo="day"
            className="shadow-md flex-1"
            value={selectedDate}
            onChange={e => {
              if (!e) {
                return;
              }
              setSelectedDate(e);
            }}
            onClose={() => setPickerOpen(false)}
            showDaysOutsideCurrentMonth
          />
        </LocalizationProvider>
      </Popover>
      <div
        className={button}
        onClick={() => {
          moveMonth(1);
        }}
      >
        <ChevronRightIcon sx={{ fontSize: 30 }} />
      </div>
    </div>
  );
}
