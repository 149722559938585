import { useQuery } from "@apollo/client";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useEffect, useState } from "react";
import { GET_INV_HISTORY_CONDITIONAL } from "../../../../gqls/inv";
import dayjs, { Dayjs } from "dayjs";
import { invHistory } from "../../../../types/inv";
import { useInvStore } from "../../../../store/invStore";
import { company } from "../../../../types/company";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useStatePersist } from "use-state-persist";
import OverviewHistoryDetail from "./overviewHistoryDetail";

export default function Overview({ companies }: { companies: company[] }) {
  const [mode, setMode] = useStatePersist(
    "@inv_overview_mode",
    "today" as "today" | "month" | "custom"
  );
  const { invActions } = useInvStore();

  const [range, setRange] = useStatePersist("@inv_overview_range", {
    _gte: dayjs().startOf("day").toISOString(),
    _lte: dayjs().toISOString(),
  });

  useEffect(() => {
    if (mode == "month") {
      setRange({
        _gte: dayjs().startOf("month").toISOString(),
        _lte: dayjs().toISOString(),
      });
    }

    if (mode == "today") {
      setRange({
        _gte: dayjs().startOf("day").toISOString(),
        _lte: dayjs().toISOString(),
      });
    }
  }, [mode]);

  const { data } = useQuery(GET_INV_HISTORY_CONDITIONAL, {
    variables: {
      where: {
        _and: {
          created_at: range,
          _or: [
            {
              from: {
                companyId: {
                  _in: companies.map(c => c.id),
                },
              },
            },
            {
              from: {
                user: {
                  companyId: {
                    _in: companies.map(c => c.id),
                  },
                },
              },
            },
            {
              from: {
                customer: {
                  companyId: {
                    _in: companies.map(c => c.id),
                  },
                },
              },
            },
            {
              from: {
                customer: {
                  sourceCompanyId: {
                    _in: companies.map(c => c.id),
                  },
                },
              },
            },
            {
              to: {
                companyId: {
                  _in: companies.map(c => c.id),
                },
              },
            },
            {
              to: {
                user: {
                  companyId: {
                    _in: companies.map(c => c.id),
                  },
                },
              },
            },
            {
              to: {
                customer: {
                  companyId: {
                    _in: companies.map(c => c.id),
                  },
                },
              },
            },
            {
              to: {
                customer: {
                  sourceCompanyId: {
                    _in: companies.map(c => c.id),
                  },
                },
              },
            },
          ],
        },
      },
      order_by: {
        created_at: "desc",
      },
    },
    fetchPolicy: "network-only",
  });

  const invHistories = data?.invHistories || [];

  const totalQtyChanges = invHistories
    .map(history => history.changes[0])
    .reduce<number>((acc, cur) => {
      return acc + Math.abs(cur.diff);
    }, 0);

  const invHistoriesPerAction = invHistories.reduce<
    {
      actionId: number;
      histories: invHistory[];
      totalDiff: number;
    }[]
  >((acc, cur) => {
    const actionId = cur.invActionId;
    const diff = Math.abs(cur.changes[0].diff);
    if (acc.find(a => a.actionId == actionId)) {
      return acc.map(a => {
        if (a.actionId == actionId) {
          return {
            ...a,
            totalDiff:
              a.actionId == actionId ? a.totalDiff + diff : a.totalDiff,
            histories:
              a.actionId == actionId ? a.histories.concat(cur) : a.histories,
          };
        }
        return a;
      });
    }
    return acc.concat({
      actionId,
      histories: [cur],
      totalDiff: diff,
    });
  }, []);

  const [historyView, setHistoryView] = useState({
    open: false,
    invHistories: [] as invHistory[],
  });

  const openHistoryView = (actionId?: number) => {
    setHistoryView({
      open: true,
      invHistories: actionId
        ? invHistories.filter(h => h.invActionId == actionId)
        : invHistories,
    });
  };

  const closeView = () => {
    setHistoryView({ open: false, invHistories: [] });
  };

  useEffect(() => {
    setHistoryView({ open: false, invHistories: [] });
  }, [range._gte, range._lte]);

  return (
    <div className="flex flex-col gap-6 p-2">
      <div className="flex flex-row justify-between items-center">
        <h1>재고 변동</h1>
        <ToggleButtonGroup
          size="small"
          color="success"
          value={mode}
          onChange={(_, val) => {
            setMode(val as "today" | "month" | "custom");
          }}
          exclusive
        >
          <ToggleButton value="today" className="w-14">
            오늘
          </ToggleButton>
          <ToggleButton value="month" className="w-14">
            금월
          </ToggleButton>
          <ToggleButton value="custom" className="w-14">
            기간
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {/* Range Select */}
      <div
        className={`${
          mode !== "custom" && "hidden"
        } flex flex-row justify-end gap-4`}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="w-28 md:w-36"
            format="YYYY/MM/DD"
            slotProps={{
              textField: {
                size: "small",
                color: "success",
                variant: "standard",
              },
              openPickerButton: {
                color: "success",
              },
            }}
            value={dayjs(range._gte)}
            onChange={(e: Dayjs | null) => {
              setRange({
                ...range,
                _gte: dayjs(e).startOf("day").toISOString(),
              });
            }}
          />
        </LocalizationProvider>
        ~
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="w-28 md:w-36"
            format="YYYY/MM/DD"
            slotProps={{
              textField: {
                size: "small",
                color: "success",
                variant: "standard",
              },
              openPickerButton: {
                color: "success",
              },
            }}
            value={dayjs(range._lte)}
            onChange={(e: Dayjs | null) => {
              setRange({
                ...range,
                _lte: dayjs(e).endOf("day").toISOString(),
              });
            }}
          />
        </LocalizationProvider>
      </div>

      <div className="flex flex-row gap-4 divide-x-2 flex-wrap justify-evenly md:justify-center">
        <div
          className="flex flex-col gap-2 items-center cursor-pointer hover:brightness-125"
          onClick={() => {
            openHistoryView();
          }}
        >
          <h1 className="font-extrabold text-4xl text-quezone">
            {totalQtyChanges}
          </h1>
          <div className="font-semibold text-gray-500">총 변동 갯수</div>
        </div>

        {invHistoriesPerAction.map(({ actionId, totalDiff }) => {
          const action = invActions.find(a => a.id == actionId);
          return (
            <div
              className="flex flex-col gap-2 pl-4 items-center cursor-pointer group"
              key={actionId}
              onClick={() => {
                openHistoryView(actionId);
              }}
            >
              <h1 className="font-extrabold text-4xl group-hover:text-quezone">
                {totalDiff}
              </h1>
              <div className="font-semibold text-gray-500">{action?.name}</div>
            </div>
          );
        })}
      </div>

      {historyView.open && (
        <OverviewHistoryDetail
          histories={historyView.invHistories}
          closeView={closeView}
        />
      )}
    </div>
  );
}
