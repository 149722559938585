import dayjs from "dayjs";
import {
  StampedMessagePayload,
  useNotificationStore,
} from "../../../../store/notificationStore";
import relativeTime from "dayjs/plugin/relativeTime";
import DeleteForever from "@mui/icons-material/DeleteForever";
dayjs.extend(relativeTime);
import SwipeableViews from "react-swipeable-views";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

export default function Notification({
  notification,
}: {
  notification: StampedMessagePayload;
}) {
  const { messageId, timestamp, data } = notification;

  const { title, body, path } = data || {};

  const { removeNotification } = useNotificationStore();

  const handleClick = () => {
    removeNotification(messageId);
    if (path) {
      window.open(path, target);
    }
  };

  const remove = () => {
    removeNotification(messageId);
  };

  const Content = () => (
    <div className="px-4 py-3 flex flex-row gap-4 items-start group lg:hover:bg-gray-100">
      <div className="p-[5px] rounded-full bg-quezone mt-[5px]" />
      <div className="flex flex-col gap-1 w-full">
        <div className="flex flex-row justify-between gap-1 items-center">
          {/* Title */}
          <div className="font-medium cursor-pointer" onClick={handleClick}>
            {title.replace(/undefined/g, "")}
          </div>
          {/* TimeStamp */}
          <div className="text-sm text-gray-500 lg:group-hover:hidden min-w-max">
            {dayjs(timestamp).fromNow()}
          </div>
          <div
            className="hidden lg:group-hover:block cursor-pointer"
            onClick={remove}
          >
            <DeleteForever
              className="text-red-500"
              sx={{ width: 22, height: 22 }}
            />
          </div>
        </div>
        <div
          className="text-sm text-gray-500 cursor-pointer"
          onClick={handleClick}
        >
          {body}
        </div>
      </div>
    </div>
  );

  const onChangeIndex = (i: number) => {
    i == 1 && remove();
  };

  return (
    <div className="border-y-[1px]">
      <div className="hidden lg:block">
        <Content />
      </div>
      <div className="lg:hidden">
        <SwipeableViews onChangeIndex={onChangeIndex} hysteresis={0.4}>
          <Content />
          <div className="px-10 flex flex-row text-sm text-white items-center gap-4 h-full bg-gradient-to-l from-red-500">
            <DeleteForever
              className="text-red-500 mb-[2px]"
              sx={{ width: 22, height: 22 }}
            />
            알림 삭제
          </div>
        </SwipeableViews>
      </div>
    </div>
  );
}
