import { useForm, useWatch } from "react-hook-form";
import { useCustomerStore } from "../../../../../store/customerStore";
import { company } from "../../../../../types/company";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { UPDATE_COMPANY_BY_ID } from "../../../../../gqls/company";
import { useMutation } from "@apollo/client";
import { useState } from "react";

interface props {
  company: company;
}
export default function CustomerTypeControl({ company }: props) {
  const { types } = useCustomerStore();
  const defaultValues = {
    types: company?.customerTypeIds || [],
  };

  const { setValue, control, reset } = useForm({
    defaultValues,
  });

  const formTypes = useWatch({
    control,
    name: "types",
  });

  const [isDirty, setIsDirty] = useState(false);

  const toggle = (id: number) => {
    setIsDirty(true);
    if (formTypes.includes(id)) {
      setValue(
        "types",
        formTypes.filter(s => s != id)
      );
    } else {
      setValue("types", formTypes.concat(id));
    }
  };

  const [update, { loading }] = useMutation(UPDATE_COMPANY_BY_ID);

  const submit = () => {
    update({
      variables: {
        id: company.id,
        set: {
          customerTypeIds: formTypes,
        },
      },
      onCompleted() {
        reset({
          types: formTypes,
        });
        setIsDirty(false);
      },
    });
  };

  return (
    <div className="col-span-1 flex flex-col gap-2">
      <h1>고객 타입 설정</h1>
      <label>* 엑세스 할수 있는 고객 타입을 설정합니다</label>
      <div className="flex flex-row gap-2 flex-wrap max-w-md">
        {types.map(type => (
          <Chip
            key={type.id}
            variant={formTypes.includes(type.id) ? "filled" : "outlined"}
            color={formTypes.includes(type.id) ? "success" : "default"}
            onClick={() => toggle(type.id)}
            label={type.name}
          />
        ))}
      </div>
      {isDirty && (
        <div className="flex flex-row justify-end gap-2">
          <Button
            color="success"
            onClick={() => {
              reset();
              setIsDirty(false);
            }}
          >
            취소
          </Button>

          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={submit}
          >
            저장
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
