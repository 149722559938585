import * as React from "react";
import { useCheckAuth } from "../../../store/authStore";
import { useLocation } from "react-router-dom";
import { useNavStore } from "../../../store/navStore";

type Props = {
  children?: React.ReactNode;
};

export default function SettingPermissionWrapper({
  children,
}: Props): React.ReactNode {
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const path = pathname[2] || "company";

  const { settingMenues } = useNavStore();

  const seetingPermission = settingMenues.find(
    menu => menu.path == path
  )?.permission;
  const checkAuth = useCheckAuth();

  const check = () => {
    if (path == "permission") {
      return checkAuth({ isPeter: true });
    }
    return (
      seetingPermission && checkAuth({ permissionName: seetingPermission })
    );
  };

  if (!check()) {
    return <div className="p-10">접근 권한이 없습니다</div>;
  }

  return <>{children}</>;
}
