import { Controller, FormProvider, useForm } from "react-hook-form";
import { biztalk } from "../../../../../types/biztalk";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ButtonControl from "./buttonControl";
import QuickReplyControl from "./quickReplyControl";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ADD_BIZTALK, UPDATE_BIZTALK_BY_ID } from "../../../../../gqls/biztalk";
import { BIZTALK_FIELDS } from "../../../../../fragments/biztalk";
import BiztalkDeleteControl from "./biztalkDeleteControl";
import ReplyIcon from "@mui/icons-material/Reply";
import BiztalkVariables from "./biztalkVariables";

interface props {
  biztalk?: biztalk;
}

const schema = z.object({
  tmpltCode: z.string().min(1, { message: "required" }),
  message: z.string().min(1, { message: "required" }),
  title: z.string().optional().nullable(),
  messageType: z.string().min(1, { message: "required" }),
  buttons: z.array(z.any()).nullable().optional(),
  quickReplies: z.array(z.any()).nullable().optional(),
});

export default function BiztalkControl({ biztalk }: props) {
  const defaultValues: Omit<biztalk, "id"> = {
    tmpltCode: biztalk?.tmpltCode || "",
    message: biztalk?.message || "",
    title: biztalk?.title || "",
    messageType: biztalk?.messageType || "AT",
    buttons: biztalk?.buttons || [],
    quickReplies: biztalk?.quickReplies || [],
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, touchedFields, isDirty },
  } = methods;

  const [insert, { loading: inserting }] = useMutation(ADD_BIZTALK);

  const [update, { loading: updating }] = useMutation(UPDATE_BIZTALK_BY_ID);

  const loading = inserting || updating;

  const navigate = useNavigate();

  const onSubmit = handleSubmit(data => {
    if (biztalk) {
      update({
        variables: { id: biztalk.id, set: data },
        onCompleted(returning) {
          reset(returning.update_biztalks_by_pk);
          navigate("/settings/biztalk");
        },
      });
    } else {
      insert({
        variables: { object: data },
        onCompleted() {
          reset();
          navigate("/settings/biztalk");
        },
        update(cache, { data: { insert_biztalks_one: newBiztalk } }) {
          cache.modify({
            fields: {
              biztalks(existing = []) {
                const newBiztalkRef = cache.writeFragment({
                  data: newBiztalk,
                  fragment: BIZTALK_FIELDS,
                });
                return [...existing, newBiztalkRef];
              },
            },
          });
        },
      });
    }
  });

  const cancel = () => {
    reset();
    if (!biztalk) {
      navigate("/settings/biztalk");
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-4 py-4 md:p-8 w-full"
      >
        <div className="flex flex-row items-center justify-between">
          <h1 className="mb-4">비즈톡 {biztalk ? "수정" : "추가"}</h1>
          <Link to="/settings/biztalk" className="hidden md:flex">
            <div className="hover:text-quezone">
              <ReplyIcon />
            </div>
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          <FormControl margin="none" required>
            <InputLabel id="company" sx={{ fontWeight: 500 }} color="success">
              메세지 유형
            </InputLabel>
            <Controller
              name="messageType"
              control={control}
              render={({ field }) => (
                <Select
                  required
                  className="shadow-md"
                  color="success"
                  label="메세지 유형"
                  defaultValue={defaultValues.messageType}
                  value={field.value || ""}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                  ref={field.ref}
                  disabled={field.disabled}
                >
                  <MenuItem value="AT">기본</MenuItem>
                  <MenuItem value="AI">이미지</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl margin="none" className="col-span-1">
            <TextField
              error={errors.tmpltCode && touchedFields.tmpltCode}
              className="shadow-md col-span-1"
              required
              label="템플릿 코드"
              autoComplete="off"
              color="success"
              placeholder="템플릿 코드"
              {...register("tmpltCode")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText error={errors.tmpltCode && touchedFields.tmpltCode}>
              {touchedFields.tmpltCode && errors.tmpltCode?.message}
            </FormHelperText>
          </FormControl>
          <FormControl margin="none" className="col-span-1">
            <TextField
              error={errors.title && touchedFields.title}
              className="shadow-md col-span-1"
              label="강조표기 제목"
              autoComplete="off"
              color="success"
              placeholder="강조표기 제목"
              {...register("title")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText error={errors.title && touchedFields.title}>
              {touchedFields.title && errors.title?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <FormControl margin="dense">
          <TextField
            error={errors.message && touchedFields.message}
            className="shadow-md col-span-1"
            required
            label="템플릿 내용"
            autoComplete="off"
            multiline
            minRows={5}
            color="success"
            placeholder="템플릿 내용"
            {...register("message")}
            InputLabelProps={{
              style: {
                fontWeight: 500,
              },
            }}
          />
          <FormHelperText error={errors.message && touchedFields.message}>
            {touchedFields.message && errors.message?.message}
          </FormHelperText>
        </FormControl>
        <ButtonControl />
        <QuickReplyControl />
        <BiztalkVariables />

        <div className="flex flex-row gap-2 justify-end items-center mt-2">
          {biztalk && <BiztalkDeleteControl biztalk={biztalk} />}
          {isDirty && (
            <>
              <Button color="success" onClick={cancel}>
                취소
              </Button>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                color="success"
                sx={{
                  backgroundColor: "black",
                  fontWeight: 500,
                }}
              >
                등록
              </LoadingButton>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
