import { useQuery } from "@apollo/client";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { GET_PRODUCTS } from "../../../../gqls/inv";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ProductRow from "../../settings/inventories/products/productRow";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

interface props {
  name: string;
}

export default function InvProductControl({ name }: props) {
  const { control } = useFormContext();

  const { data } = useQuery(GET_PRODUCTS);

  const products = data?.products;

  const selectedProductId = useWatch({
    name,
    control,
  });

  const selectedProduct = products?.find(
    product => product.id === selectedProductId
  );

  if (!products) {
    return null;
  }

  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>제품</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            margin="none"
            size="small"
            required
            className="shadow-md"
            color="success"
            value={field.value || 0}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled}
          >
            <MenuItem value={0}>제품을 선택해주세요</MenuItem>
            {products.map(product => (
              <MenuItem value={product.id} key={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      <div className="flex-1 flex flex-col justify-end pt-2">
        {selectedProduct && (
          <ProductRow product={selectedProduct} newTab={true} />
        )}
        {!selectedProduct && (
          <div className="flex-1 justify-center  items-center flex flex-col text-gray-300">
            <ImageNotSupportedIcon sx={{ width: 120, height: 120 }} />
          </div>
        )}
      </div>
    </div>
  );
}
