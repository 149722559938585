import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import generatePastelColor from "../../../../../utils/genPastelColor";
import { useInvStore } from "../../../../../store/invStore";
import { ADD_INV_ACTION } from "../../../../../gqls/inv";
import { INV_ACTION_FIELDS } from "../../../../../fragments/inv";

import InvActionControl from "./invActionControl";
import { blackButtonContained } from "../../../../../classPresets";

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
  color: z.string().min(1, { message: "required" }),
  type: z.enum(["add", "delete", "move", "update", "assign"]),
  statusId: z.coerce.number().nullable().optional(),
  fromStatIds: z.array(z.coerce.number()).nullable().optional(),
  linger: z.boolean(),
});

export default function NewInvAction() {
  const [adding, setAdding] = useState(false);

  const { invActions, addInvAction } = useInvStore();

  const defaultValues = {
    name: "",
    description: "",
    color: generatePastelColor(),
    type: "add",
    statusId: 0,
    fromStatIds: null,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const [insert, { loading }] = useMutation(ADD_INV_ACTION);

  const onSubmit = handleSubmit(data => {
    if (data.type !== "delete" && !data.statusId) {
      return;
    }

    insert({
      variables: {
        object: {
          ...data,
          priority: invActions.length,
        },
      },
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        addInvAction(data.insert_invActions_one);
        setAdding(false);
        reset({
          ...defaultValues,
          color: generatePastelColor(),
        });
      },
      update(cache, { data: { insert_invActions_one: newInvAction } }) {
        cache.modify({
          fields: {
            invActions(existing = []) {
              const newActionRef = cache.writeFragment({
                data: newInvAction,
                fragment: INV_ACTION_FIELDS,
              });
              return [...existing, newActionRef];
            },
          },
        });
      },
    });
  });

  return (
    <>
      <Button
        {...blackButtonContained}
        onClick={() => {
          setAdding(true);
        }}
      >
        액션 추가
      </Button>

      <FormProvider {...methods}>
        <Dialog
          open={adding}
          onClose={() => {
            setAdding(false);
            reset();
          }}
        >
          <DialogTitle>재고 액션 추가</DialogTitle>
          <DialogContent>
            <InvActionControl />
          </DialogContent>
          <DialogActions>
            <Button
              color="success"
              onClick={() => {
                setAdding(false);
              }}
            >
              취소
            </Button>
            <LoadingButton
              disabled={!isDirty}
              loading={loading}
              variant="contained"
              color="success"
              onClick={onSubmit}
            >
              추가
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
}
