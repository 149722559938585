import { Auth } from "../../../../types/auth";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuthStore } from "../../../../store/authStore";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_AUTH_BY_ID } from "../../../../gqls/auth";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import AuthDeleteControl from "./authDeleteControl";
import AuthPermissionControl from "./authPermissionControl";
import AuthCustomerStatusPermissionControl from "./authCustomerStatusPermissionControl";
import { useTaskStore } from "../../../../store/taskStore";
import { useCustomerStore } from "../../../../store/customerStore";
import AuthTaskStatusPermissionControl from "./authTaskStatusPermissionControl";

interface props {
  auth: Auth;
}

const schema = z.object({
  name: z.string().min(1, { message: "영문 이름을 적어주세요" }),
  displayName: z.string().min(1, { message: "이름을 적어주세요" }),
  description: z.string().min(1, { message: "설명을 적어주세요" }),
  permissions: z.array(z.any()),
  customerStatusPermissions: z.array(z.any()),
  taskStatusPermissions: z.array(z.any()),
});

export default function AuthDetail({ auth }: props) {
  const { statuses: customerStatuses } = useCustomerStore();
  const { statuses: taskStatuses } = useTaskStore();

  const {
    name,
    displayName,
    description,
    permissions,
    customerStatusPermissions,
    taskStatusPermissions,
  } = auth;

  const defaultValues = {
    name,
    displayName,
    description,
    permissions,
    customerStatusPermissions: customerStatuses.map(status => ({
      id: status.id,
      scope:
        customerStatusPermissions.find(cs => cs.id == status.id)?.scope ||
        "none",
    })),
    taskStatusPermissions: taskStatuses.map(status => ({
      id: status.id,
      scope:
        taskStatusPermissions.find(ts => ts.id == status.id)?.scope || "none",
    })),
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, errors, touchedFields },
  } = methods;

  const [update, { loading }] = useMutation(UPDATE_AUTH_BY_ID);
  const { updateAuth } = useAuthStore();

  const onUpdate = handleSubmit(data => {
    update({
      variables: {
        id: auth.id,
        set: { ...data },
      },
      onCompleted(updatedData) {
        updateAuth(updatedData.update_auths_by_pk);
        reset(data);
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="flex flex-row gap-2 items-center">
            <h3>{auth.displayName}</h3>{" "}
            <div className="hidden md:block">- {auth.description}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-1 flex flex-col gap-1">
                <label>이름</label>
                <TextField
                  size="small"
                  margin="none"
                  color="success"
                  error={errors.displayName && touchedFields.displayName}
                  placeholder={
                    errors.displayName?.message
                      ? errors.displayName?.message
                      : "권한 이름"
                  }
                  {...register("displayName")}
                />
              </div>
              <div className="col-span-1 flex flex-col gap-1">
                <label>설명</label>
                <TextField
                  size="small"
                  margin="none"
                  color="success"
                  error={errors.description && touchedFields.description}
                  placeholder={
                    errors.description?.message
                      ? errors.description?.message
                      : "권한 설명"
                  }
                  {...register("description")}
                />
              </div>
            </div>
            <AuthPermissionControl />
            <AuthCustomerStatusPermissionControl />
            <AuthTaskStatusPermissionControl />
            <div className="flex flex-row gap-2 items-center justify-end mt-2">
              <AuthDeleteControl auth={auth} />
              {isDirty && (
                <>
                  <Button
                    color="success"
                    onClick={() => {
                      reset();
                    }}
                  >
                    취소
                  </Button>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    color="success"
                    onClick={onUpdate}
                  >
                    저장
                  </LoadingButton>
                </>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </FormProvider>
  );
}
