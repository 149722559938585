import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { GET_SOURCE_DETAILS_CONDITIONAL } from "../../../../gqls/source";
import useFormValues from "../../../../hooks/useFormValues";
import { useAuthStore } from "../../../../store/authStore";
export function SourceDetailControlConly({ disabled }: { disabled?: boolean }) {
  const { control, setValue } = useFormContext();

  const { sourceId } = useFormValues();

  const { data, loading } = useQuery(GET_SOURCE_DETAILS_CONDITIONAL, {
    variables: {
      where: {
        sourceId: {
          _eq: sourceId,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  let sourceDetails = data?.sourceDetails || [];

  const { user } = useAuthStore();

  if (!user?.company?.type.canHaveCustomers) {
    sourceDetails = sourceDetails.filter(s => s.companyId == user?.company?.id);
  }

  return (
    <>
      {!loading && (
        <Controller
          name="sourceDetailId"
          control={control}
          render={({ field }) => (
            <Select
              margin="none"
              size="small"
              required
              className="shadow-md"
              color="success"
              value={field.value || 0}
              onChange={e => {
                field.onChange(e.target.value);
                const companyId =
                  sourceDetails.find(sd => sd.id == Number(e.target.value))
                    ?.companyId || null;
                setValue("sourceCompanyId", companyId);
              }}
              onBlur={field.onBlur}
              name={field.name}
              ref={field.ref}
              disabled={field.disabled || disabled}
            >
              <MenuItem value={0}>세부 접수처를 선택하세요</MenuItem>
              {sourceDetails.map(source => (
                <MenuItem value={source.id} key={source.id}>
                  {source.name || source.company?.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      )}
    </>
  );
}

export default function SourceDetailControl({
  disabled,
}: {
  disabled?: boolean;
}) {
  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>세부접수처</label>
      <SourceDetailControlConly disabled={disabled} />
    </div>
  );
}
