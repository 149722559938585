import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { product } from "../../../../../types/product";
import Avatar from "@mui/material/Avatar";
import { currencyFormat } from "../../../../../utils/numberMethods";
import { Link } from "react-router-dom";
import MyBadge from "../../../../../comps/myBadge";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

interface props {
  product: product;
  newTab?: boolean;
  noBorder?: boolean;
  displayOnly?: boolean;
}

export default function ProductRow({
  product,
  newTab,
  noBorder,
  displayOnly,
}: props) {
  const { name, description, photo, price, brand, serial, active } = product;

  const to = displayOnly ? "" : `/settings/inventory/editProduct/${product.id}`;

  return (
    <Link to={to} target={newTab ? target : ""}>
      <div
        className={`flex flex-row gap-3 p-2 items-center rounded-md shadow-md bg-white cursor-pointer md:ring-quezone md:hover:ring-1 ${
          noBorder && " hover:ring-transparent shadow-none rounded-none"
        }`}
      >
        <Avatar
          variant="square"
          sx={{
            width: 120,
            height: 120,
            bgcolor: "#CCCCCCCC",
            borderRadius: 2,
          }}
          src={photo}
        >
          <div className="flex flex-col items-center text-base">
            <AddPhotoAlternateIcon sx={{ width: 124, height: 124 }} />
          </div>
        </Avatar>
        <div className="flex flex-col gap-1 flex-1">
          <div
            className="flex flex-row items-center justify-between flex-wrap"
            dir="rtl"
          >
            {active ? (
              <div className="text-sm text-gray-500 flex-1 text-right truncate w-0 min-w-[100px]">
                #{serial}
              </div>
            ) : (
              <MyBadge color="#A52A2A" text="비활성" />
            )}
            <h3 className="flex-[3] truncate w-0 min-w-[100px] text-left">
              {brand}
            </h3>
          </div>
          <div className="flex flex-row">
            <h1 className="truncate w-0 flex-1">{name}</h1>
          </div>
          <div className="flex flex-row">
            <div className="flex-1 w-0 truncate">
              {description || "설명 없음"}
            </div>
          </div>
          <div>{currencyFormat(price)}</div>
        </div>
      </div>
    </Link>
  );
}
