import { useStatePersist } from "use-state-persist";
import CompanyInventoryList from "./companyList/companyInventoryList";
import ProductList from "./productList/productList";
import Statistics from "./statistics/statistics";
import { useQuery } from "@apollo/client";
import { GET_COMPANIES } from "../../../gqls/company";
import { useAuthStore, useCheckAuth } from "../../../store/authStore";
import MyInventory from "./myInventory";

type view = "me" | "company" | "product" | "statistics";
export default function ListMain() {
  const [view, setView] = useStatePersist<view>("@invView", "me");

  const selectView = (view: view) => {
    setView(view);
  };

  const commonCN =
    "cursor-pointer font-medium border-b-2 px-2 py-[1px] rounded-sm";

  const { data } = useQuery(GET_COMPANIES);
  const checkAuth = useCheckAuth();

  const { user } = useAuthStore();
  let companies = data?.companies || [];
  const userCompany = companies.find(c => c.id == user?.company?.id);

  companies = companies.filter(company => {
    return (
      checkAuth({
        permissionName: "재고열람",
        companyIds: [company.id],
      }) &&
      company.invLocations &&
      company.invLocations.length > 0 &&
      company.active
    );
  });

  const filteredCompanies = companies.filter(company =>
    userCompany?.invLocations?.includes(company.id)
  );

  return (
    <div className="flex-1 flex flex-col gap-4 pb-5 pt-4 md:pt-0">
      <div className="flex flex-row items-center gap-2">
        {checkAuth({
          permissionName: "재고열람",
          scopeCheck: ["all", "sub", "company"],
        }) && (
          <>
            <div
              onClick={() => {
                selectView("company");
              }}
              className={`${commonCN} ${
                view == "company"
                  ? "text-quezone border-b-quezone"
                  : "md:hover:text-quezone md:hover:ring-quezone border-b-transparent"
              }`}
            >
              위치별
            </div>
            <div
              onClick={() => {
                selectView("product");
              }}
              className={`${commonCN} ${
                view == "product"
                  ? "text-quezone border-b-quezone"
                  : "md:hover:text-quezone md:hover:ring-quezone border-b-transparent"
              }`}
            >
              제품별
            </div>
            <div
              onClick={() => {
                selectView("statistics");
              }}
              className={`${commonCN} ${
                view == "statistics"
                  ? "text-quezone border-b-quezone"
                  : "md:hover:text-quezone md:hover:ring-quezone border-b-transparent"
              }`}
            >
              통계
            </div>
          </>
        )}
        <div
          onClick={() => {
            selectView("me");
          }}
          className={`${commonCN} ${
            view == "me"
              ? "text-quezone border-b-quezone"
              : "md:hover:text-quezone md:hover:ring-quezone border-b-transparent"
          }`}
        >
          내 재고
        </div>
      </div>
      {view == "me" && <MyInventory />}
      {view == "company" && (
        <CompanyInventoryList companies={filteredCompanies} />
      )}
      {view == "product" && <ProductList companies={filteredCompanies} />}
      {view == "statistics" && <Statistics companies={companies} />}
    </div>
  );
}
