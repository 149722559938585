import { gql } from "@apollo/client";

export const SOURCE_FIELDS = gql`
  fragment SourceFields on sources {
    id
    name
    companyId
    isSourceCompany
  }
`;

export const SOURCE_DETAIL_CORE_FIELDS = gql`
  fragment SourceDetailCoreFields on sourceDetails {
    id
    sourceId
    companyId
    name
  }
`;

export const SOURCE_DETAIL_FIELDS = gql`
  ${SOURCE_DETAIL_CORE_FIELDS}
  fragment SourceDetailFields on sourceDetails {
    ...SourceDetailCoreFields
    source {
      id
      name
      isSourceCompany
      companyId
    }
    company {
      id
      name
    }
  }
`;
