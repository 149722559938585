import { useQuery } from "@apollo/client";
import { company } from "../../../../../types/company";
import {
  GET_INVENTORY_CONDITIONAL,
  STREAM_INVENTORY,
} from "../../../../../gqls/inv";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useProductStore } from "../../../../../store/productStore";
import { product } from "../../../../../types/product";
import Avatar from "@mui/material/Avatar";
import PanoramaIcon from "@mui/icons-material/Panorama";
import InvQty from "./invQty";
import { Fragment, useEffect } from "react";
import { useStatePersist } from "use-state-persist";
import { cloneDeep } from "apollo-utilities";
import { Link, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { inventory } from "../../../../../types/inv";
import { sort } from "fast-sort";
import MyBadge from "../../../../../comps/myBadge";
import Total from "./total";

interface props {
  company: company;
}
export default function CompanyInventory({ company }: props) {
  const { products } = useProductStore();

  const where = {
    _and: {
      customerId: {
        _is_null: true,
      },
      product: {
        active: {
          _eq: true,
        },
      },
      _or: [
        {
          companyId: {
            _eq: company.id,
          },
        },
        {
          userId: {
            _in: company.users.map(u => u.id),
          },
        },
      ],
    },
  };

  const [cursor, setCursor] = useStatePersist(
    `@companyInvCursor:${company.id}`,
    new Date().toISOString()
  );

  const { data, subscribeToMore } = useQuery(GET_INVENTORY_CONDITIONAL, {
    variables: {
      where,
    },
    onCompleted() {
      setCursor(new Date().toISOString());
    },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: STREAM_INVENTORY,
      variables: {
        where,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());

        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.inventory);
        const newFeedItem = subscriptionData.data.inventory_stream || [];
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];

        return Object.assign({}, previous, {
          inventory: [...newFeedItem, ...existing],
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const inventory = (data?.inventory || []).map(i => {
    const product = products.find(p => p.id == i.productId) as product;
    return {
      ...i,
      product,
    };
  });

  const productGroup = sort(inventory)
    .asc("productId")
    .reduce<
      {
        productId: number;
        inventory: inventory[];
      }[]
    >((acc, cur) => {
      if (acc.length == 0) {
        return [
          {
            productId: cur.productId,
            inventory: [cur],
          },
        ];
      }
      if (acc[acc.length - 1].productId == cur.productId) {
        acc[acc.length - 1].inventory.push(cur);
        return acc;
      }
      return [
        ...acc,
        {
          productId: cur.productId,
          inventory: [cur],
        },
      ];
    }, []);

  const navigate = useNavigate();

  return (
    <section className="flex flex-col gap-2">
      {/* PC Table*/}
      <div className="hidden md:block">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>사진</TableCell>
              <TableCell>제품명</TableCell>
              <TableCell>일련번호</TableCell>
              <TableCell>재고</TableCell>
              <TableCell>총</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productGroup.map(group => {
              const companyInventory = group.inventory.find(
                i => i.companyId && !i.userId
              );
              const userInventory = group.inventory.filter(i => i.userId);
              const product = products.find(
                p => p.id == group.productId
              ) as product;

              const to = companyInventory
                ? `/inventory/detail/${companyInventory.id}`
                : `/inventory/add/${company.id}/${group.productId}`;

              if (!product) {
                return null;
              }

              return (
                <Fragment key={group.productId}>
                  <TableRow
                    hover
                    className="cursor-pointer"
                    onClick={() => navigate(to)}
                  >
                    <TableCell className="w-40">
                      <Avatar
                        variant="square"
                        sx={{
                          width: 100,
                          height: 100,
                          bgcolor: "#CCCCCCCC",
                          borderRadius: 2,
                        }}
                        src={product.photo}
                      >
                        <PanoramaIcon />
                      </Avatar>
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.serial}</TableCell>
                    <TableCell>
                      <div className="flex flex-wrap flex-row gap-1 items-start h-full">
                        {companyInventory ? (
                          <InvQty inv={companyInventory} />
                        ) : (
                          <MyBadge text="재고없음" color="gray" />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Total companyId={company.id} productId={product.id} />
                    </TableCell>
                  </TableRow>
                  {userInventory.map(i => (
                    <TableRow
                      key={i.id}
                      hover
                      className="cursor-pointer"
                      onClick={() => navigate(`/inventory/detail/${i.id}`)}
                    >
                      <TableCell>
                        <div className="flex flex-row gap-2 items-center ml-10">
                          <PersonIcon className="text-quezone" />
                          <div className="truncate">
                            {company.users.find(u => u.id == i.userId)?.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell colSpan={2} />
                      <TableCell colSpan={2}>
                        <div className="flex flex-wrap flex-row gap-1">
                          <InvQty inv={i} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {/* Mobile List*/}
      <div className="md:hidden mt-4 flex flex-col gap-2">
        {productGroup.map((group, i) => {
          const companyInventory = group.inventory.find(
            i => i.companyId && !i.userId
          );
          const userInventory = group.inventory.filter(i => i.userId);
          const product = products.find(
            p => p.id == group.productId
          ) as product;

          const to = companyInventory
            ? `/inventory/detail/${companyInventory.id}`
            : `/inventory/add/${company.id}/${group.productId}`;

          if (!product) {
            return null;
          }

          return (
            <Fragment key={group.productId}>
              <hr className={`${i == 0 ? "hidden" : ""} mt-2 pb-2`} />
              <Link to={to}>
                <div className="flex flex-row gap-2 ">
                  <Avatar
                    variant="square"
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: "#CCCCCCCC",
                      borderRadius: 2,
                    }}
                    src={product.photo}
                  >
                    <PanoramaIcon />
                  </Avatar>

                  <div className="flex flex-1 flex-col gap-1 justify-center">
                    <div className="flex flex-row items-center gap-2">
                      <div className="truncate">{product.name}</div>
                    </div>

                    <div className="flex flex-row flex-wrap gap-1 items-center">
                      {companyInventory ? (
                        <InvQty inv={companyInventory} />
                      ) : (
                        <MyBadge text="재고없음" color="gray" />
                      )}

                      <div className="flex-1 flex flex-row justify-end pr-2">
                        <div
                          className={`rounded-sm py-[3px] px-[6px] text-xs ring-quezone text-quezone ring-1 `}
                        >
                          총{" "}
                          <Total
                            companyId={company.id}
                            productId={product.id}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              {userInventory.map(i => (
                <Link key={i.id} to={`/inventory/detail/${i.id}`}>
                  <hr className="py-2" />
                  <div className="flex flex-row items-center gap-2 justify-between">
                    <div className="flex flex-row gap-2 items-center ml-4 pt-1 text-sm md:text-base">
                      <PersonIcon />
                      {company.users.find(u => u.id == i.userId)?.name}
                    </div>

                    <div className="flex flex-wrap flex-row gap-1">
                      <InvQty inv={i} />
                    </div>
                  </div>
                </Link>
              ))}
            </Fragment>
          );
        })}
      </div>
    </section>
  );
}
