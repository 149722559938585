import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { company } from "../../../../../../types/company";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY_BY_ID } from "../../../../../../gqls/company";

interface props {
  subs: company[];
  others: company[];
  companyId: number;
}

export default function NewSub({ subs, others, companyId }: props) {
  const [adding, setAdding] = useState(false);
  const [companiesToAdd, setCompaniesToAdd] = useState<number[]>([]);

  const doesContain = (id: number) => {
    return companiesToAdd.includes(id);
  };
  const toggle = (id: number) => {
    if (doesContain(id)) {
      setCompaniesToAdd(s => s.filter(s => s != id));
    } else {
      setCompaniesToAdd(s => [...s, id]);
    }
  };

  const [update, { loading }] = useMutation(UPDATE_COMPANY_BY_ID, {
    variables: {
      id: companyId,
      set: {
        subCompanies: subs.map(c => c.id).concat(companiesToAdd),
      },
    },
    onCompleted() {
      setAdding(false);
      setCompaniesToAdd([]);
    },
  });

  return (
    <div className="flex flex-row justify-end">
      <Button color="success" onClick={() => setAdding(true)}>
        서브 회사 추가
      </Button>
      <Dialog
        open={adding}
        onClose={() => {
          setAdding(false);
        }}
      >
        <DialogTitle>서브 회사 추가</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            추가하시려는 회사를 선택해주세요
          </DialogContentText>
          <div className="my-2 flex flex-row gap-2 flex-wrap">
            {others.map(c => (
              <Chip
                clickable
                onClick={() => toggle(c.id)}
                color={doesContain(c.id) ? "success" : "default"}
                // @ts-expect-error
                variant={doesContain(c.id) ? "contained" : "outlined"}
                key={c.id}
                label={c.name}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setAdding(false);
              setCompaniesToAdd([]);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={companiesToAdd.length == 0}
            loading={loading}
            variant="contained"
            color="success"
            onClick={() => {
              update();
            }}
          >
            추가
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
