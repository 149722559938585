import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
// import Switch from "@mui/material/Switch";
import { useInterval, useOnClickOutside } from "usehooks-ts";
import Tesseract from "tesseract.js";
import { createPortal } from "react-dom";
import Scanner from "./Scanner";
import Quagga from "@ericblade/quagga2";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";

export default function SNCapture({
  setNewSN,
}: {
  setNewSN: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [scanning, setScanning] = useState(false);
  const [mode, setMode] = useState<"code" | "ocr">("code");
  const webcamRef = useRef<Webcam | null>(null);
  const [tempSN, setTempSN] = useState("");

  useInterval(() => {
    if (!scanning || mode !== "ocr" || !webcamRef.current) {
      return;
    }

    const imageSrc = webcamRef.current.getScreenshot();

    if (!imageSrc) {
      return;
    }

    Tesseract.recognize(imageSrc, "eng").then(({ data: { text } }) => {
      let regex = /[A-Za-z0-9]+-[A-Za-z0-9]+/i;
      if (regex.test(text)) {
        const match = text.match(regex);
        if (match && match[0]) {
          if (tempSN == match[0]) {
            setNewSN(match[0]);
            setScanning(false);
            setTempSN("");
          } else {
            setTempSN(match[0]);
          }
        }
      }
    });
  }, 2000);

  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]); // array of available cameras, as returned by Quagga.CameraAccess.enumerateVideoDevices()
  const [cameraId, setCameraId] = useState<null | string>(null); // id of the active camera device
  const [cameraError, setCameraError] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const enableCamera = async () => {
      await Quagga.CameraAccess.request(null, {});
    };
    const disableCamera = async () => {
      await Quagga.CameraAccess.release();
    };
    const enumerateCameras = async () => {
      const cameras = await Quagga.CameraAccess.enumerateVideoDevices();
      console.log("Cameras Detected: ", cameras);
      return cameras;
    };

    enableCamera()
      .then(disableCamera)
      .then(enumerateCameras)
      .then(cameras => setCameras(cameras))
      .then(() => Quagga.CameraAccess.disableTorch()) // disable torch at start, in case it was enabled before and we hot-reloaded
      .catch(err => setCameraError(err));

    return () => {
      disableCamera();
    };
  }, []);

  const nextCamera = () => {
    const index = cameras.findIndex(c => c.deviceId === cameraId);
    const next = index + 1 < cameras.length ? index + 1 : 0;
    setCameraId(cameras[next].deviceId);
  };

  const torchOn = () => {
    Quagga.CameraAccess.enableTorch();
  };

  const torchOff = () => {
    Quagga.CameraAccess.disableTorch();
  };

  const handleClose = () => {
    setTempSN("");
    setScanning(false);
  };

  const modalRef = useRef(null);

  useOnClickOutside(modalRef, handleClose);

  const scannerRef = useRef(null);
  return (
    <>
      <Button
        size="small"
        color="success"
        variant="contained"
        sx={{ fontWeight: 400 }}
        onClick={() => setScanning(true)}
      >
        스캔
      </Button>
      {scanning &&
        createPortal(
          <div
            ref={modalRef}
            className={`absolute ${
              !scanning && "hidden"
            } w-5/6 md:w-[524px] z-50 h-96 rounded-md overflow-clip shadow-md ring-1 ring-quezone bg-gray-50 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 `}
          >
            <div className="absolute z-10 left-0 top-0 p-4 flex flex-row w-full justify-between gap-4 items-center">
              {cameraError ? (
                <p>
                  ERROR INITIALIZING CAMERA ${JSON.stringify(cameraError)} -- DO
                  YOU HAVE PERMISSION?
                </p>
              ) : null}
              {cameras.length > 1 && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextCamera}
                  size="small"
                >
                  <CameraswitchIcon />
                </Button>
              )}
              <div className="flex flex-row items-center gap-2">
                <Button
                  variant="contained"
                  color="success"
                  onClick={torchOn}
                  size="small"
                >
                  <FlashlightOnIcon />
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={torchOff}
                  size="small"
                >
                  <FlashlightOffIcon />
                </Button>
              </div>
            </div>
            {/* <div className="absolute z-10 right-0 top-0 flex flex-row justify-end items-center text-sm px-2">
            <Switch
              color="success"
              checked={mode === "ocr"}
              onChange={() => setMode(mode === "code" ? "ocr" : "code")}
            />
            OCR
          </div> */}
            <div ref={scannerRef}>
              <canvas
                className="drawingBuffer w-full h-full"
                style={{
                  position: "absolute",
                  top: "0px",
                }}
              />
              <Scanner
                onScannerReady={() => {
                  setInitialized(true);
                }}
                decoders={["code_128_reader"]}
                scannerRef={scannerRef}
                facingMode="environment"
                cameraId={cameraId}
                onDetected={result => {
                  if (!result) {
                    return;
                  }
                  setNewSN(result);
                  setScanning(false);
                }}
              />
            </div>
            {/* {mode === "code" ? (
            
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                facingMode: { exact: "environment" },
              }}
            />
          )} */}

            <div className="text-center w-full left-0 px-2 absolute bottom-4 animate-pulse text-white break-keep drop-shadow-sm">
              {tempSN !== ""
                ? tempSN
                : "스캔 중입니다 바코드를 중앙에 위치시켜주세요"}
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
