import { memo, useEffect, useRef, useState } from "react";
import { useCheckAuth } from "../../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { blackButtonOutlined, scrollbar } from "../../../../classPresets";
import Header from "../../layouts/header/header";
import VisuallyHiddenInput from "../../../../comps/hiddenInput";
import Button from "@mui/material/Button";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import MyBadge from "../../../../comps/myBadge";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useCustomerStore } from "../../../../store/customerStore";
import { isMongoCustomer, mongoCustomer } from "../types";
import useUploadCustomer from "./useUploadCustomer";
import { GET_COMPANIES } from "../../../../gqls/company";
import { GET_CUSTOMERS_CONDITIONAL_M } from "../../../../gqls/customer";
import CircularProgress from "@mui/material/CircularProgress";
import useCustomerConvert from "./useCustomerConvert";
import { exportData } from "../../../../utils/objectArrayMethods";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { GET_INVENTORY_CONDITIONAL } from "../../../../gqls/inv";

export default function CustomerMigration() {
  const checkAuth = useCheckAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!checkAuth({ isPeter: true })) {
      navigate(-1);
    }
  }, []);

  const [getCustomers, { data: exCustomerData }] = useLazyQuery(
    GET_CUSTOMERS_CONDITIONAL_M
  );
  const exCustomers = exCustomerData?.customers || [];

  const { data: inventoryData } = useQuery(GET_INVENTORY_CONDITIONAL);
  const inventory = inventoryData?.inventory || [];

  const [customers, setCustomers] = useState<mongoCustomer[]>([]);
  const done = useRef<string[]>([]);
  const { upload, currentJobId } = useUploadCustomer();

  const handleFile: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const files = e.target.files;
    if (!files || !files[0]) {
      return;
    }
    const file = files[0];

    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = async e => {
      const result = JSON.parse(e.target?.result as string);

      if (!Array.isArray(result)) {
        return;
      }

      const _customers: mongoCustomer[] = result.filter(r =>
        isMongoCustomer(r)
      );

      const { data } = await getCustomers({
        variables: {
          where: {
            uuid: {
              _in: _customers.map(c => c._id.$oid),
            },
          },
        },
      });

      const exCustomers = data?.customers || [];

      done.current = exCustomers.map(c => c.uuid);

      if (result) {
        setCustomers(_customers);
      }
    };
  };
  const columns = [
    { field: "id", width: 250, headerName: "id", hide: true },
    { field: "number", width: 90, headerName: "번호" },
    { field: "company", width: 100, headerName: "회사" },
    {
      field: "type",
      width: 90,
      headerName: "구분",
    },
    { field: "name", headerName: "이름" },
    { field: "address", flex: 2, headerName: "주소" },
    { field: "source", flex: 1, headerName: "접수처" },
    { field: "registered_at", width: 100, headerName: "접수일" },
    { field: "installDate", width: 100, headerName: "설치일" },
    {
      field: "status",
      headerName: "상태",
    },
    {
      field: "progress",
      width: 100,
      headerName: "Prog",
      renderCell: (params: any) => (
        <>
          {currentJobId == params.value ? (
            <CircularProgress color="success" size={20} />
          ) : (
            <MyBadge
              text={`${done.current.includes(params.value) ? "V" : "X"}`}
              color={done.current.includes(params.value) ? "green" : "red"}
            />
          )}
        </>
      ),
    },
  ];

  const { data } = useQuery(GET_COMPANIES, {
    onError(error) {
      console.log(error);
    },
  });

  const companies = data?.companies;

  const { statuses, types } = useCustomerStore();

  const rows = customers.map(c => ({
    id: c._id.$oid,
    number: `#${c.year}-${c.number}`,
    company: companies?.find(com => com.id == c.companyId)?.name,
    type: types.find(t => t.id == c.typeId)?.name,
    name: c.name,
    address: c.address,
    source: c.source + " " + c.sourceDetail,
    registered_at: dayjs(c.created.$date).format("YY. MM. DD"),
    installDate: c.due ? dayjs(c.due.$date).format("YY. MM. DD") : "미정",
    status: statuses.find(s => s.id == c.statusId)?.name,
    progress: c._id.$oid,
  }));

  const handleUpload = async (customer: mongoCustomer) => {
    const id = customer._id.$oid;
    const uploaded = await upload(customer);
    if (uploaded) {
      done.current.push(id);
    }
    return;
  };

  const uploadOne = async (id: string) => {
    if (done.current.includes(id)) {
      return;
    }
    const customer = customers.find(c => c._id.$oid == id);
    if (!customer) {
      return;
    }
    handleUpload(customer);
  };

  const { convert, convertInventory, convertRest } = useCustomerConvert();

  const convertAll = () => {
    const converted = customers.map(customer => convert(customer));
    exportData(converted, "customers");
  };

  const convertAllInventory = () => {
    const converted = customers
      .map(customer => convertInventory(customer, exCustomers))
      .flat();

    exportData(
      converted.filter(c => !!c),
      "customerInventory"
    );
  };

  const convertAllRest = () => {
    const converted = customers.map(customer =>
      convertRest(customer, exCustomers, inventory)
    );

    const invQty = converted.map(c => c?.invQty).flat();
    const history = converted.map(c => c?.history).flat();
    const correspondence = converted.map(c => c?.correspondence).flat();
    const price = converted.map(c => c?.price).flat();
    const payment = converted.map(c => c?.payment).flat();

    exportData(
      invQty.filter(c => !!c),
      "customerInvQty"
    );
    exportData(
      history.filter(c => !!c),
      "customerHistory"
    );
    exportData(
      correspondence.filter(c => !!c),
      "customerCorrespondence"
    );
    exportData(
      price.filter(c => !!c),
      "customerPrice"
    );
    exportData(
      payment.filter(c => !!c),
      "customerPayment"
    );
  };

  const convertOne = async (id: string) => {
    const customer = customers.find(c => c._id.$oid == id);
    if (!customer) {
      return;
    }
    const converted = convert(customer);
    exportData(converted, "customers");
  };

  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar} md:px-10 bg-white md:bg-transparent`}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <Header title="Mongo 고객이주" />
      <div className="bg-white p-4 rounded-md shadow-md mb-4">
        {customers.length == 0 && <p>No file uploaded yet</p>}
        {customers.length > 0 && (
          <MemoizedTable
            columns={columns}
            rows={rows}
            handleRowClick={uploadOne}
            currentJobId={currentJobId}
            done={done.current}
          />
        )}
      </div>
      <div className="flex flex-row justify-between items-center gap-2 mb-4">
        <div>
          <div className="flex flex-row gap-4 text-sm">
            <p>
              {done.current.length}/{customers.length}
            </p>
            {customers.length > 0 && (
              <p className="text-quezone">
                {((done.current.length / customers.length) * 100).toFixed(1)}%
              </p>
            )}
            <p>{currentJobId}</p>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <Button startIcon={<CloudUpload />} color="success" component="label">
            <VisuallyHiddenInput
              type="file"
              accept="application/json"
              onChange={handleFile}
            />
            파일 선택
          </Button>
          {customers.length > 0 && (
            <>
              <Button
                startIcon={<LanOutlinedIcon />}
                {...blackButtonOutlined}
                onClick={convertAll}
              >
                CONVERT CUSTOMERS
              </Button>
              <Button
                startIcon={<Inventory2OutlinedIcon />}
                {...blackButtonOutlined}
                onClick={convertAllInventory}
              >
                CONVERT INVENTORY
              </Button>
              <Button
                startIcon={<Inventory2OutlinedIcon />}
                {...blackButtonOutlined}
                onClick={convertAllRest}
              >
                CONVERT REST
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

interface props {
  rows: any[];
  columns: any[];
  handleRowClick: (id: string) => void;
  currentJobId: string | null;
  done: string[];
}

const Table = ({ rows, columns, handleRowClick }: props) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      sx={{ backgroundColor: "white" }}
      onRowClick={e => {
        handleRowClick(e.id as string);
      }}
    />
  );
};

const MemoizedTable = memo(Table, (prev, next) => {
  if (next.done.find(id => !prev.done.includes(id))) {
    return false;
  }
  if (prev.currentJobId != next.currentJobId) {
    return false;
  }

  return true;
});
