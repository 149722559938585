import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { useAuthStore } from "../../../../store/authStore";
import { useInvStore } from "../../../../store/invStore";
import { useEffect, useRef, useState } from "react";
import Fade from "@mui/material/Fade";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useOnClickOutside } from "usehooks-ts";
import CompanyInventory from "./companyInventory/companyInventory";
import { blackButtonContained } from "../../../../classPresets";
import { company } from "../../../../types/company";

export default function CompanyInventoryList({
  companies,
}: {
  companies: company[];
}) {
  const { selectedInvLocation, setSelectedInvLocation } = useInvStore();
  const { user } = useAuthStore();

  useEffect(() => {
    if (!companies || companies.length == 0) {
      return;
    }

    if (companies.find(c => c.id == selectedInvLocation)) {
      return;
    }
    setSelectedInvLocation(
      companies.find(c => c.id == user?.company?.id)
        ? user?.company?.id || 0
        : companies[0].id
    );
  }, [companies]);

  const [selecting, setSelecting] = useState(false);

  const currentCompany = companies.find(c => c.id == selectedInvLocation);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, () => setSelecting(false));

  return (
    <div className="flex-1 flex flex-col gap-4">
      <main className="flex-1 md:py-5 md:p-8 flex flex-col md:drop-shadow-md md:bg-white overflow-x-clip">
        {(!currentCompany || companies.length == 0) &&
          "확인 가능한 재고 위치가 없습니다"}
        {/* Company Select, Add Button */}
        <section
          ref={ref}
          className="flex flex-row justify-between items-center relative"
        >
          <div
            className="flex flex-row gap-2 items-center cursor-pointer flex-1"
            onClick={() => setSelecting(!selecting)}
          >
            <h1
              className={`flex-1 w-0 truncate ${
                currentCompany?.name &&
                currentCompany?.name.length > 15 &&
                "text-xl md:text-2xl"
              }`}
            >
              {currentCompany?.name}
              <ArrowDropDownIcon
                className={`${selecting ? "rotate-180" : ""} ml-1`}
              />
            </h1>
          </div>
          <Fade in={selecting}>
            <div className="absolute z-20 top-full bg-white shadow-md border-[1px] border-gray-100 mt-2">
              <List component="nav">
                {companies.map(company => (
                  <ListItemButton
                    key={company.id}
                    onClick={() => {
                      setSelectedInvLocation(company.id);
                      setSelecting(false);
                    }}
                  >
                    <ListItemText primary={company.name} />
                  </ListItemButton>
                ))}
              </List>
            </div>
          </Fade>
          <Link
            to={`${
              currentCompany
                ? `/inventory/add/${currentCompany.id}`
                : "/inventory/add"
            }`}
          >
            <Button size="small" {...blackButtonContained}>
              추가
            </Button>
          </Link>
        </section>
        {/* Company Inventory */}
        {currentCompany && <CompanyInventory company={currentCompany} />}
      </main>
    </div>
  );
}
