import { sort } from "fast-sort";
import { scrollbar } from "../../../../../../classPresets";
import BlackHeading from "../../../../../../comps/blackHeading";
import { customerPayment } from "../../../../../../types/customer";
import Payment from "./payment";

interface props {
  payments: customerPayment[];
  companyIds: number[];
}

export default function CustomerPayments({ payments, companyIds }: props) {
  return (
    <div className="flex-1 flex flex-col gap-2">
      <BlackHeading fullWidth title="거래 내역" />
      <div
        className={`flex flex-col gap-2 shadow-md max-h-[100dvh] overflow-y-auto ${scrollbar}`}
      >
        {sort(payments)
          .desc("received_at")
          .map(payment => (
            <Payment
              key={payment.id}
              payment={payment}
              companyIds={companyIds}
            />
          ))}
      </div>
    </div>
  );
}
