import { useAuthStore } from "../../../../store/authStore";
import AuthDetail from "./authDetail";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NewAuth from "./newAuth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Auths() {
  const { auths } = useAuthStore();
  const authsNotAdmin = auths.filter(auth => auth.name !== "admin");

  const navigate = useNavigate();

  const { user } = useAuthStore();

  useEffect(() => {
    if (!user || user.auth.name !== "admin") {
      return navigate("/");
    }
  }, [user]);

  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <h1 className="hidden md:block mb-4 ">권한 목록</h1>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="flex flex-row gap-2 items-center">
            <h3>시스템 관리자</h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          시스템 관리자 권한, 삭제 혹은 수정할 수 없습니다
        </AccordionDetails>
      </Accordion>
      {authsNotAdmin.map(auth => (
        <AuthDetail key={auth.id} auth={auth} />
      ))}

      <NewAuth />
    </div>
  );
}
