import { useQuery } from "@apollo/client";
import useFormValues from "../../../../../../../hooks/useFormValues";
import { GET_USERS_CONDITIONAL } from "../../../../../../../gqls/user";
import { Controller, useFormContext } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function TaskAssignedToControl() {
  const { companyId } = useFormValues();

  const { data } = useQuery(GET_USERS_CONDITIONAL, {
    variables: {
      where: {
        companyId: {
          _eq: companyId,
        },
        status: {
          considerActive: {
            _eq: true,
          },
        },
      },
    },
  });

  const users = data?.users;

  const { control } = useFormContext();

  if (!users) {
    return null;
  }

  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>작업자</label>
      <Controller
        name="assignedToId"
        control={control}
        render={({ field }) => (
          <Select
            margin="none"
            size="small"
            required
            className="shadow-md"
            color="success"
            value={field.value || 0}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled}
          >
            <MenuItem value={0}>
              {users.length === 0
                ? "가용 작업자가 없습니다"
                : "작업자를 선택해주세요"}
            </MenuItem>
            {users.map(user => (
              <MenuItem value={user.id} key={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </div>
  );
}
