import { User } from "../../../../types/user";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";

interface props {
  user: User;
}
export default function UserRow({ user }: props) {
  const navigate = useNavigate();
  const goToDetail = () => {
    navigate(`/settings/user/detail/${user.id}`);
  };
  return (
    <TableRow hover className="cursor-pointer" onClick={goToDetail}>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.company?.name || "무소속"}</TableCell>
      <TableCell>{user.auth.name}</TableCell>
      <TableCell>{user.role}</TableCell>
      <TableCell>{user.status.name}</TableCell>
    </TableRow>
  );
}
