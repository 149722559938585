import { useQuery } from "@apollo/client";
import { GET_BIZTALKS } from "../../../../gqls/biztalk";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import BiztalkRow from "./biztalkRow";
import { blackButtonContained } from "../../../../classPresets";

export default function Biztalks() {
  const { data } = useQuery(GET_BIZTALKS, {
    onError(error) {
      console.log(error);
    },
  });

  const biztalks = data?.biztalks || [];

  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <h1 className="md:mb-4">비즈톡 목록</h1>

      {biztalks.map(biztalk => (
        <BiztalkRow biztalk={biztalk} key={biztalk.id} />
      ))}

      <div className="flex flex-row justify-end mt-4">
        <Link to="/settings/biztalk/new">
          <Button {...blackButtonContained}>비즈톡 등록</Button>
        </Link>
      </div>
    </div>
  );
}
