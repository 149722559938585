import { Controller, useFormContext } from "react-hook-form";
import useFormValues from "../../../../../../hooks/useFormValues";
import { inventory, inventory_with_product } from "../../../../../../types/inv";
import Button from "@mui/material/Button";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Face from "@mui/icons-material/Face";
import { useInvStore } from "../../../../../../store/invStore";
import ProductRow from "../../../../settings/inventories/products/productRow";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { sort } from "fast-sort";

interface props {
  setActiveStep: (num: number) => void;
  inventories: inventory[];
}

export default function QtyActionControl({
  setActiveStep,
  inventories,
}: props) {
  const { register, control, setValue } = useFormContext();

  const { inv, invActionId, invQtyId } = useFormValues();
  const inventory = inv as inventory_with_product | undefined;

  const { invActions } = useInvStore();
  const availableInvActions = sort(
    invActions.filter(a => {
      const typeCheck = a.type == "assign";
      const matchingQty =
        inventory?.qty.filter(q => a.fromStatIds?.includes(q.statusId)) || [];

      const total = matchingQty.reduce<number>((prev, cur) => {
        return prev + cur.qty;
      }, 0);

      return typeCheck && total > 0;
    })
  ).by([
    {
      desc: a => {
        const matchingQty =
          inventory?.qty.filter(q => a.fromStatIds?.includes(q.statusId)) || [];

        const total = matchingQty.reduce<number>((prev, cur) => {
          return prev + cur.qty;
        }, 0);

        return total;
      },
    },
    { asc: a => a.priority },
  ]);
  const selectedInvAction = invActions.find(a => a.id == Number(invActionId));

  const selectedInvQty = inventory?.qty.find(q => q.id == Number(invQtyId));
  const availableQty = selectedInvQty?.qty || 0;

  const invQties = sort(
    inventory?.qty.filter(
      q =>
        selectedInvAction?.fromStatIds?.includes(q.statusId) &&
        (!q.subInvId || inventories.some(i => i.id == q.subInvId))
    ) || []
  ).by([{ desc: q => q.qty }, { asc: q => q.status?.priority }]);

  useEffect(() => {
    if (!availableInvActions || availableInvActions.length == 0) {
      return;
    }

    setValue("invActionId", availableInvActions[0].id, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [availableInvActions.length]);

  useEffect(() => {
    if (!invQties || invQties.length == 0) {
      return;
    }
    setValue("invQtyId", invQties[0].id, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [invQties.length]);

  if (!inventory) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <label>수량 및 재고 액션을 선택해주세요</label>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row gap-2 items-center">
          <Button
            color="success"
            onClick={() => {
              setActiveStep(0);
            }}
          >
            {"<"} 재고 변경
          </Button>
          <div className="flex-1 w-0 text-quezone truncate text-right">
            {inventory.company ? <ApartmentIcon /> : <Face />}{" "}
            {inventory.company?.name || inventory.user?.name}
          </div>
        </div>
        {inventory.product && (
          <ProductRow product={inventory.product} displayOnly />
        )}
      </div>

      <div className="flex flex-row gap-2 items-center flex-wrap">
        <div className="flex flex-row gap-2 items-center flex-1">
          <Controller
            name="invActionId"
            control={control}
            render={({ field }) => (
              <Select
                className="flex-[2] w-0"
                size="small"
                margin="none"
                color="success"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              >
                <MenuItem value={0}>재고 액션을 선택해주세요</MenuItem>
                {availableInvActions.map(a => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Controller
            name="invQtyId"
            control={control}
            render={({ field }) => (
              <Select
                className="flex-1 w-0 min-w-12"
                size="small"
                margin="none"
                color="success"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              >
                <MenuItem value={0}>재고상태를 선택해주세요</MenuItem>
                {invQties.map(qty => (
                  <MenuItem key={qty.id} value={qty.id}>
                    {qty.status?.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="flex flex-row gap-3 items-center">
          <TextField
            className="w-12"
            inputProps={{
              className: "text-center",
            }}
            size="small"
            margin="none"
            color="success"
            type="number"
            placeholder="Qty"
            {...register("qty")}
          />
          <span>/</span>
          {availableQty}
        </div>
      </div>
    </div>
  );
}
