import { useState } from "react";
import { reportType } from "../../../../../../types/report";
import ReportTypeEdit from "./reportTypeEdit";
import ReportTypeDelete from "./reportTypeDelete";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface props {
  reportType: reportType;
  isOverlay: boolean;
}
export default function ReportTypeRow({ reportType, isOverlay }: props) {
  const { name, description, color, id } = reportType;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const [mode, setMode] = useState<null | "editing" | "deleting">(null);
  return (
    <>
      <div
        className={`px-4 py-2 shadow-md rounded-md flex flex-row gap-2 items-center justify-between group ${
          isOverlay ? "text-quezone" : ""
        }`}
        ref={setNodeRef}
        style={style}
      >
        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-quezone"
          onClick={() => setMode("editing")}
        >
          <div
            className="p-[6px] rounded-full"
            style={{
              backgroundColor: color,
            }}
          />
          <span className="font-semibold">{name}</span> - {description}
        </div>
        <div
          className="md:hidden md:group-hover:flex"
          {...attributes}
          {...listeners}
        >
          <DragIndicatorIcon className="cursor-pointer" />
        </div>
      </div>
      <ReportTypeEdit reportType={reportType} mode={mode} setMode={setMode} />
      <ReportTypeDelete reportType={reportType} mode={mode} setMode={setMode} />
    </>
  );
}
