import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useInvStore } from "../../../../../store/invStore";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { cacheEvict } from "../../../../../utils/cacheMethods";
import {
  DELETE_INV_STATUS,
  GET_INV_QTY_CONDITIONAL,
  UPDATE_INV_QTY_MANY,
} from "../../../../../gqls/inv";
import { invHistory, invStatus } from "../../../../../types/inv";
import useAddInvHistory from "../../../../../hooks/useAddInvHistory";

interface props {
  status: invStatus;
  setMode: React.Dispatch<React.SetStateAction<"editing" | "deleting" | null>>;
}
export default function InvStatDeleteControl({ status, setMode }: props) {
  const { invStatuses, deleteInvStatus } = useInvStore();
  const invStatusesNotMe = invStatuses.filter(s => s.id != status.id);

  const [altStat, setAltStat] = useState(0);

  const [remove, { loading: removing }] = useMutation(DELETE_INV_STATUS);
  const [getInvQty] = useLazyQuery(GET_INV_QTY_CONDITIONAL);

  const [updateInvQtyMany, { loading: updatingInventory }] =
    useMutation(UPDATE_INV_QTY_MANY);

  const loading = removing || updatingInventory;

  const addInvHistory = useAddInvHistory();

  const _delete = async () => {
    const res = await getInvQty({
      variables: {
        where: {
          statusId: {
            _in: [status.id, altStat],
          },
        },
      },
    });

    const exInvQty = res.data?.invQty;

    const invQtiesToDelete = exInvQty?.filter(
      qty =>
        qty.statusId == status.id &&
        exInvQty.find(i => i.statusId == altStat && i.invId == qty.invId)
    );

    const histories: Omit<
      invHistory,
      "id" | "created_at" | "updated_at" | "from" | "to" | "inventory"
    >[] = [];

    const updates = exInvQty
      ?.map(qty => {
        const historyBody = {
          fromId: qty.invId,
          toId: qty.invId,
          invActionId: 12,
          description: `${status.name} 재고 삭제로 인한 변경`,
        };

        if (qty.statusId == status.id) {
          if (
            exInvQty.find(i => i.statusId == altStat && i.invId == qty.invId)
          ) {
            return null;
          }

          const history: Omit<
            invHistory,
            "id" | "created_at" | "updated_at" | "from" | "to" | "inventory"
          > = {
            changes: [
              {
                invId: qty.invId,
                diff: qty.qty,
                statusId: altStat,
              },
            ],
            ...historyBody,
          };

          histories.push(history);

          return {
            where: { id: { _eq: qty.id } },
            _set: {
              statusId: altStat,
            },
          };
        }
        if (qty.statusId == altStat) {
          const invQtyToDelete = exInvQty.find(
            i => i.invId == qty.invId && i.statusId == status.id
          );

          const history: Omit<
            invHistory,
            "id" | "created_at" | "updated_at" | "from" | "to" | "inventory"
          > = {
            changes: [
              {
                invId: qty.invId,
                diff: qty.qty,
                statusId: altStat,
              },
            ],
            ...historyBody,
          };

          histories.push(history);

          return {
            where: { id: { _eq: qty.id } },
            _inc: {
              qty: invQtyToDelete?.qty || 0,
            },
          };
        }
      })
      .filter(qty => qty !== null);

    updateInvQtyMany({
      variables: { updates },
      onCompleted() {
        remove({
          variables: {
            id: status.id,
          },
          onCompleted() {
            deleteInvStatus(status.id);
            setMode(null);
            histories.map(history => {
              if (history == null) {
                return;
              }
              addInvHistory(history);
            });
          },
          update(cache) {
            cacheEvict({
              cache,
              id: status.id,
              __typename: "invStatuses",
            });

            invQtiesToDelete?.map(qty => {
              cacheEvict({
                cache,
                id: qty.id,
                __typename: "invQty",
              });
            });
          },
        });
      },
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        setMode(null);
      }}
    >
      <DialogTitle>재고 상태 삭제</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <div>{status.name} 상태를 삭제합니다</div>
          <div>삭제될 상태를 대신할 상태를 선택해주세요</div>
          <Select
            required
            size="small"
            color="success"
            value={altStat}
            onChange={e => setAltStat(Number(e.target.value))}
          >
            <MenuItem value={0}>대체 상태 선택</MenuItem>
            {invStatusesNotMe.map(stat => (
              <MenuItem key={stat.id} value={stat.id}>
                {stat.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: "black" }}
          onClick={() => {
            setMode(null);
          }}
        >
          취소
        </Button>
        <LoadingButton
          disabled={altStat === 0}
          loading={loading}
          variant="contained"
          color="success"
          onClick={_delete}
        >
          삭제
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
