export default function ReportSerials({ serials }: { serials?: string[] }) {
  return (
    <div className="col-span-full flex flex-col gap-1">
      <label className="text-black p-3 md:p-0 pb-0 print:p-0">일련번호</label>
      <div className="flex flex-col gap-1 rounded-sm bg-gray-50 md:rounded-md print:rounded-md p-3 md:px-6 print:px-6 min-h-10 text-sm">
        {serials?.length == 0 && "번호 없음"}
        {serials && serials.length > 0 && (
          <ul className="pl-4 bg-white p-2 space-y-1">
            {serials?.map((serial, i) => (
              <li className="text-quezone" key={`${serial}-${i}`}>
                S/N {serial}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
