import { ChangeEventHandler, useRef, useState } from "react";
import { tempImage } from "../../types/report";
import { useFormContext } from "react-hook-form";
import VisuallyHiddenInput from "../../comps/hiddenInput";
import Resizer from "react-image-file-resizer";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CollectionsIcon from "@mui/icons-material/Collections";

interface props {
  index: number;
  image: tempImage;
}
export default function TempImage({ index, image }: props) {
  const [fileSrc, setFileSrc] = useState<null | string>(null);

  const { setValue } = useFormContext();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const takePhotoRef = useRef<HTMLInputElement | null>(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const handleImgClick = (mode: null | "album" | "capture") => {
    if (mode == null) {
      setDrawerOpen(true);
      return;
    }
    if (mode == "album") {
      fileInputRef.current?.click();
    } else {
      takePhotoRef.current?.click();
    }
  };

  const resize = (file: File) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        "PNG",
        80,
        0,
        uri => {
          resolve(uri);
        },
        "file"
      );
    });

  const onFileChange: ChangeEventHandler<HTMLInputElement> = async e => {
    const files = e.target.files;
    const file = files && files[0];

    if (!file) {
      setValue(`images.${index}.image`, null, { shouldDirty: true });
      return;
    }
    const converted = await resize(file);

    setValue(`images.${index}.image`, converted, { shouldDirty: true });

    const fr = new FileReader();
    fr.onload = () => {
      setFileSrc(fr.result as string);
    };

    fr.readAsDataURL(converted as File);
  };

  if (!image) {
    return null;
  }

  return (
    <div className="col-span-1 flex flex-col items-center gap-2 cursor-default relative">
      <img
        src={fileSrc || image.src || "/no-img.jpg"}
        alt="no-image"
        className={`w-full aspect-square object-cover bg-gray-100 cursor-pointer ring-1 ring-quezone ${
          !fileSrc && !image.src && "p-8 md:p-14"
        }`}
        onClick={() => {
          handleImgClick(null);
        }}
      />
      <VisuallyHiddenInput
        type="file"
        ref={fileInputRef}
        accept="image/*"
        onChange={onFileChange}
      />
      <VisuallyHiddenInput
        type="file"
        ref={takePhotoRef}
        accept="image/*"
        capture="environment"
        onChange={onFileChange}
      />
      <div className="flex flex-row items-center gap-2">
        <div className="text-sm">{image.name}</div>
      </div>

      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleImgClick("capture");
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <PhotoCameraIcon />
              </ListItemIcon>
              <ListItemText primary="카메라" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleImgClick("album");
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <CollectionsIcon />
              </ListItemIcon>
              <ListItemText primary="앨범" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
