import { useFormContext, useWatch } from "react-hook-form";
import { automation, trigger } from "../../../../../types/automation";
import { useCustomerStore } from "../../../../../store/customerStore";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

interface props {
  automation?: automation;
}

export default function AutomationCustomerTriggerControl({
  automation,
}: props) {
  const { setValue, control } = useFormContext();

  const trigger: trigger = useWatch({
    name: "trigger",
    control,
  });

  const requiredTriggers = ["고객 등록", "고객 상태 변경"];

  const { statuses } = useCustomerStore();

  const fromCustomerStatIds: number[] | null = useWatch({
    name: "fromCustomerStatIds",
    control,
    defaultValue: automation?.fromCustomerStatIds || [],
  });

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    setValue("fromCustomerStatIds", event.target.value);
  };

  if (!requiredTriggers.includes(trigger)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1">
      <label>고객 상태 조건</label>
      <FormControl fullWidth margin="none" color="success" size="small">
        <Select
          multiple
          placeholder="상태 조건 선택"
          className="shadow-md"
          input={<OutlinedInput />}
          renderValue={selected => {
            if (selected.length === 0) {
              return <>상태 조건을 선택해주세요</>;
            }
            return selected
              .map(s => statuses.find(stat => stat.id === s)?.name)
              .join(", ");
          }}
          displayEmpty
          value={fromCustomerStatIds || []}
          onChange={handleChange}
        >
          {statuses.map(stat => (
            <MenuItem key={stat.id} value={stat.id}>
              <Checkbox
                checked={fromCustomerStatIds?.includes(stat.id)}
                color="success"
              />
              <ListItemText primary={stat.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
