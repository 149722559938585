import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import CustomerSearch from "./customerSearch";

export default function MobileSearch() {
  const [searching, setSearching] = useState(false);

  const dismiss = () => {
    setSearching(false);
  };
  return (
    <>
      <div
        className="md:hidden bg-white p-[2px] md:p-[6px] rounded-sm ring-1 ring-gray-300 cursor-pointer md:hover:ring-quezone md:hover:text-quezone"
        onClick={() => setSearching(true)}
      >
        <SearchIcon />
      </div>
      {searching && (
        <div className="flex flex-col h-[100dvh]  absolute bg-black bg-opacity-50 w-screen left-0 top-0 z-20">
          <div className="h-24 w-full" onClick={dismiss} />
          <div className="flex flex-row w-full pl-3">
            <CustomerSearch />
          </div>
          <div className="flex-1" onClick={dismiss} />
        </div>
      )}
    </>
  );
}
