import loadingImg from "/loading.gif";

export default function Loading() {
  return (
    <div className="h-full w-full flex justify-center items-center bg-white overflow-clip">
      <img
        src={loadingImg}
        className="bg-no-repeat bg-center bg-auto min-w-[524px] max-w-[860px]"
      />
    </div>
  );
}
