import { sort } from "fast-sort";
import useTaskStatUpdate from "../../../../hooks/useTaskStatUpdate";
import { useAuthStore } from "../../../../store/authStore";
import { useTaskStore } from "../../../../store/taskStore";
import { typeOrStatus } from "../../../../types/common";
import { task } from "../../../../types/task";
import getContrast from "../../../../utils/getContrast";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MyBadge from "../../../../comps/myBadge";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";

interface props {
  task: task;
}

export default function TaskStatControl({ task }: props) {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { user } = useAuthStore();
  let { statuses } = useTaskStore();

  statuses = sort(statuses).asc("priority");

  const userStatPermissions = user?.auth.taskStatusPermissions || [];

  statuses = statuses.filter(s => {
    if (user?.auth.name == "admin") {
      return true;
    }

    const statPermission = userStatPermissions.find(sp => sp.id == s.id);

    if (!statPermission || statPermission?.scope !== "edit") {
      return false;
    }

    return true;
  });

  const { updateStatus } = useTaskStatUpdate();

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const update = (id: number) => {
    if (task.statusId == id) {
      return;
    }
    updateStatus({
      task,
      newStatId: id,
    });
    handleClose();
  };

  return (
    <div className="relative">
      <div className="cursor-pointer hover:brightness-90" onClick={openMenu}>
        <MyBadge
          color={task.status.color}
          text={`${task.status.name} ▼`}
          fill
          big
        />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {statuses.map(stat => {
          const selected = stat.id == task.status.id;

          return (
            <MenuItem
              key={stat.id}
              onClick={() => update(stat.id)}
              disabled={selected}
            >
              {stat.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
