import { customerHistory } from "../../../../../../types/customer";
import dayjs from "dayjs";
import { useCustomerStore } from "../../../../../../store/customerStore";
import Popover from "@mui/material/Popover";
import { useRef, useState } from "react";
import BiztalkRow from "../../../../settings/biztalks/biztalkRow";
import DetailUpdates from "./detailUpdates";
import MyBadge from "../../../../../../comps/myBadge";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

export default function History({ history }: { history: customerHistory }) {
  const { created_at, message, statusId, oldData, type, biztalk, link } =
    history;

  const time = dayjs(created_at).format("hh:mm");
  const { statuses } = useCustomerStore();
  const status = statuses.find(s => s.id == statusId);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const detail = () => {
    if (type == "status") {
      const oldStatusId = oldData?.statusId;
      const newStatus = status;
      const oldStatus = statuses.find(s => s.id == oldStatusId);

      return (
        <div className="flex flex-row gap-2 items-center">
          {oldStatus ? (
            <MyBadge color={oldStatus.color} text={oldStatus.name} fill />
          ) : (
            ""
          )}{" "}
          {"=>"}
          {newStatus ? (
            <MyBadge color={newStatus.color} text={newStatus.name} fill />
          ) : (
            ""
          )}
        </div>
      );
    }

    if (type == "detail") {
      return <DetailUpdates history={history} />;
    }

    return "변경 정보를 찾을 수 없습니다";
  };

  const handleClick = () => {
    if (link) {
      window.open(link, target);
      return;
    }
    setOpen(true);
  };

  return (
    <div className="py-2 px-1 flex flex-row gap-2">
      <div className="text-xs font-extralight pt-1">{time}</div>
      <div className="flex-1 flex flex-row">
        <div
          ref={anchorRef}
          onClick={handleClick}
          className="shadow-md bg-white px-3 pt-2 pb-1 text-sm font-medium border-[1px] border-gray-100 flex flex-row gap-2 items-center max-w-full cursor-pointer hover:text-quezone"
        >
          {status && (
            <div
              className="rounded-full p-1"
              style={{ backgroundColor: status.color }}
            />
          )}
          <div>
            {message}
            {status && ` [${status.name}]`}
            {biztalk && ` [${biztalk.tmpltCode}]`}
          </div>
        </div>
        {type !== "registration" && (
          <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ marginTop: 1 }}
            className="shadow-md"
          >
            {biztalk && <BiztalkRow biztalk={biztalk} displayOnly />}
            {type !== "biztalk" && (
              <div className="p-2 flex flex-col text-sm">{detail()}</div>
            )}
          </Popover>
        )}
      </div>
    </div>
  );
}
