import { create } from "zustand";
import { invAction, invStatus } from "../types/inv";

type Store = {
  invActions: invAction[];
  setInvActions: (invActions: invAction[]) => void;
  updateInvActions: (invActions: invAction[]) => void;
  addInvAction: (invAction: invAction) => void;
  deleteInvAction: (id: number) => void;
  invStatuses: invStatus[];
  setInvStatuses: (invStatuses: invStatus[]) => void;
  updateInvStatuses: (invStatuses: invStatus[]) => void;
  addInvStatus: (invStatus: invStatus) => void;
  deleteInvStatus: (id: number) => void;
  selectedInvLocation: number;
  setSelectedInvLocation: (selectedInvLocation: number) => void;
};

export const useInvStore = create<Store>(set => ({
  invActions: [],
  setInvActions: invActions =>
    set(state => ({
      ...state,
      invActions,
    })),
  updateInvActions(invActions) {
    set(state => ({
      ...state,
      invActions: state.invActions.map(
        action => invActions.find(s => s.id == action.id) || action
      ),
    }));
  },
  addInvAction(invAction) {
    set(state => ({
      ...state,
      invActions: state.invActions.concat(invAction),
    }));
  },
  deleteInvAction(id) {
    set(state => ({
      ...state,
      invActions: state.invActions.filter(s => s.id != id),
    }));
  },
  invStatuses: [],
  setInvStatuses: invStatuses =>
    set(state => ({
      ...state,
      invStatuses,
    })),
  updateInvStatuses(invStatuses) {
    set(state => ({
      ...state,
      invStatuses: state.invStatuses.map(
        stat => invStatuses.find(s => s.id == stat.id) || stat
      ),
    }));
  },
  addInvStatus(invStatus) {
    set(state => ({
      ...state,
      invStatuses: state.invStatuses.concat(invStatus),
    }));
  },
  deleteInvStatus(id) {
    set(state => ({
      ...state,
      invStatuses: state.invStatuses.filter(s => s.id != id),
    }));
  },
  selectedInvLocation: localStorage.getItem("selectedInvLocation")
    ? Number(localStorage.getItem("selectedInvLocation"))
    : 0,
  setSelectedInvLocation: selectedInvLocation => {
    localStorage.setItem("selectedInvLocation", selectedInvLocation.toString());
    return set(state => ({
      ...state,
      selectedInvLocation,
    }));
  },
}));
