import { extendedCustomer } from "../../../../../../types/customer";
import { useState } from "react";
import AvailableInventory from "./availableInventory";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { inventory_with_product } from "../../../../../../types/inv";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import QtyActionControl from "./qtyActionControl";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import useInvAction from "../../../../../../hooks/useInvActions";
import { useInvStore } from "../../../../../../store/invStore";
import { GET_INVENTORY_CONDITIONAL } from "../../../../../../gqls/inv";
import { useQuery } from "@apollo/client";

interface props {
  customer: extendedCustomer;
}

const schema = z.object({
  inv: z.any(),
  invQtyId: z.coerce.number().min(1, "재고를 지정해주세요"),
  qty: z.coerce.number().min(1, "수량을 입력해주세요"),
  invActionId: z.coerce.number().min(1, "재고액션을 지정해주세요"),
});

export default function AddCustomerInventory({ customer }: props) {
  const { data } = useQuery(GET_INVENTORY_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: customer.id,
        },
      },
    },
  });

  const inventories = data?.inventory || [];

  const [activeStep, setActiveStep] = useState(0);

  const defaultValues = {
    inv: null as inventory_with_product | null,
    invQtyId: 0,
    qty: 1,
    invActionId: 0,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields },
  } = methods;

  const navigate = useNavigate();

  const cancel = () => {
    navigate("../inventory");
    reset();
  };

  const inventory = useWatch({
    control,
    name: "inv",
  });

  const invQtyId = useWatch({
    control,
    name: "invQtyId",
  });

  const selectedInvQty = inventory?.qty.find(q => q.id == Number(invQtyId));

  const update = useInvAction({
    inventory,
    invQty: selectedInvQty,
  });

  const { invActions } = useInvStore();

  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async data => {
    const invAction = invActions.find(a => a.id == data.invActionId);
    if (!invAction || !inventory) {
      return;
    }

    setLoading(true);
    await update({
      qty: data.qty,
      customerId: customer.id,
      invAction,
      location: {
        type: inventory.userId ? "user" : "company",
        id: (inventory.userId
          ? inventory.userId
          : inventory.companyId) as number,
      },
    });

    setLoading(false);
    navigate("../inventory");
    reset();
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="px-3 md:px-0">
        {activeStep == 0 && (
          <AvailableInventory
            customer={customer}
            inventories={inventories}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep == 1 && (
          <QtyActionControl
            setActiveStep={setActiveStep}
            inventories={inventories}
          />
        )}

        <div className="flex flex-row justify-end gap-2 mt-4">
          <Button color="success" onClick={cancel}>
            취소
          </Button>
          {isDirty && dirtyFields.invActionId && dirtyFields.invQtyId && (
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              color="success"
              sx={{
                backgroundColor: "black",
                fontWeight: 500,
              }}
            >
              할당
            </LoadingButton>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
