import { filter as filterWhere } from "../utils/hasura-where";
import { parse } from "json-bigint";
import XRegExp from "xregexp";

export default function hasuraFilter({
  name,
  string,
  items,
}: {
  name: string;
  string: string;
  items: any[];
}) {
  try {
    let filter = string.replace(name, "");
    const outerBkt = XRegExp.matchRecursive(filter, "\\{", "\\}", "g");

    filter = `{${outerBkt}}`;

    const parsed = parse(filter);

    const res = filterWhere(items, parsed.where || {});

    return res || items;
  } catch (error) {
    console.log(error, string);
    return items;
  }
}
