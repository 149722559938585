import { useCheckAuth } from "../../../../../../store/authStore";
import BlackHeading from "../../../../../../comps/blackHeading";
import { extendedReport } from "../../../../../../types/report";
import AddReportAccount from "./addReportAccount";
import { useQuery } from "@apollo/client";
import { GET_REPORT_ACCOUTNS_BY_REPORT_ID } from "../../../../../../gqls/report";
// import dayjs from "dayjs";
import { currencyFormat } from "../../../../../../utils/numberMethods";
import ReportAccount from "./reportAccount";

interface props {
  report: extendedReport;
  viewOnly?: boolean;
}

export default function ReportAccounts({ report, viewOnly }: props) {
  const checkAuth = useCheckAuth();
  const canView = checkAuth({
    permissionName: "보고정산열람",
    userId: report.task.assignedToId,
  });
  const canEdit = checkAuth({
    permissionName: "보고정산수정",
    userId: report.task.assignedToId,
  });

  const { data } = useQuery(GET_REPORT_ACCOUTNS_BY_REPORT_ID, {
    variables: {
      reportId: report.id,
    },
  });

  const accounts = data?.reportAccounts || [];

  const total = accounts.reduce<number>((acc, cur) => {
    const total = cur.amount + cur.gst;
    return acc + total;
  }, 0);

  if (!canView) {
    return null;
  }
  return (
    <div className="flex flex-col gap-2 print:hidden">
      <BlackHeading title="정산 내역" />
      <table className="m-1 border-separate border-spacing-1 px-3 md-px-0">
        <thead>
          <tr className="text-right text-sm">
            <th>항목</th>
            <th>금액</th>
            <th>부가세</th>
            <th>총액</th>
          </tr>
        </thead>
        <tbody className="text-sm text-right">
          {accounts.map(account => (
            <ReportAccount
              key={account.id}
              account={account}
              viewOnly={viewOnly}
            />
          ))}
        </tbody>
        <tfoot>
          <tr className="text-right text-sm">
            <td colSpan={3} className="border-t-[1px] pt-1 font-medium">
              TOTAL
            </td>
            <td className="text-quezone border-t-[1px] pt-1 font-medium">
              {currencyFormat(total)}
            </td>
          </tr>
        </tfoot>
      </table>
      {canEdit && !viewOnly && <AddReportAccount report={report} />}
    </div>
  );
}
