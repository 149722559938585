import { Controller, useFormContext, useWatch } from "react-hook-form";
import { action } from "../../../../../types/automation";
import { useTaskStore } from "../../../../../store/taskStore";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function AutomationTaskStatControl() {
  const { control } = useFormContext();

  const action: action = useWatch({
    name: "action",
    control,
  });

  const { statuses } = useTaskStore();

  if (action !== "작업 상태 변경") {
    return null;
  }

  return (
    <div className="flex flex-col gap-1">
      <label>변경할 작업 상태</label>
      <Controller
        name="toTaskStatId"
        control={control}
        render={({ field }) => (
          <Select
            margin="none"
            size="small"
            required
            className="shadow-md"
            color="success"
            // defaultValue={0}
            value={field.value || 0}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled}
          >
            <MenuItem value={0}>작업 상태를 선택해주세요</MenuItem>
            {statuses.map(stat => (
              <MenuItem value={stat.id} key={stat.id}>
                {stat.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </div>
  );
}
