import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTaskStore } from "../../../../../store/taskStore";
import { useEffect, useState } from "react";
import { tempImage } from "../../../../../types/report";
import { sort } from "fast-sort";
import TempImage from "./tempImage";
import Button from "@mui/material/Button";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

export default function ReportImagesControl({ typeId }: { typeId: number }) {
  const { reportTypes } = useTaskStore();
  const type = reportTypes.find(type => type.id === typeId);

  const { control } = useFormContext();

  const tempImages: tempImage[] = useWatch({
    control,
    name: "images",
    defaultValue: [],
  });

  const { replace, append, remove, fields } = useFieldArray({
    name: "images",
    control,
  });

  useEffect(() => {
    if (!type?.photoTemplate) {
      return;
    }

    const newTempImages: tempImage[] = type.photoTemplate.map(t => {
      return {
        name: t,
        image: tempImages.find(ti => ti.name == t)?.image || null,
        src: tempImages.find(ti => ti.name == t)?.src || null,
        key: tempImages.find(ti => ti.name == t)?.key || null,
        isPreset: true,
      };
    });
    const oldTempImages: tempImage[] = tempImages
      .filter(
        t => !newTempImages.some(ti => ti.name == t.name) && (t.image || t.src)
      )
      .map(t => {
        return {
          ...t,
          isPreset: false,
        };
      });

    const images = sort([...newTempImages, ...oldTempImages]).desc("isPreset");
    replace(images);
  }, [type]);

  const addImgSlot = () => {
    append({
      name: "",
      image: null,
      src: null,
      isPreset: false,
    });
  };

  const [uploaded, setUploaded] = useState<null | number>(null);
  const [uploadingMode, setUploadingMode] = useState<
    null | "album" | "capture"
  >(null);

  if (!type) {
    return null;
  }

  return (
    <div className="col-span-1 lg:col-span-2 flex flex-col gap-1 p-3 md:p-0 bg-gray-100 md:bg-transparent">
      <label className="text-black">관련 사진</label>
      <div className="grid grid-cols-3 gap-4 p-2 bg-gray-50">
        {fields.map((field, index) => (
          <TempImage
            key={field.id}
            index={index}
            image={tempImages[index]}
            uploaded={uploaded}
            setUploaded={setUploaded}
            uploadingMode={uploadingMode}
            setUploadingMode={setUploadingMode}
            remove={() => {
              remove(index);
            }}
          />
        ))}
        <div className="col-span-full flex flex-row justify-end p-1">
          <Button
            size="small"
            color="success"
            variant="contained"
            sx={{ fontWeight: 400 }}
            startIcon={<AddAPhotoIcon />}
            onClick={addImgSlot}
          >
            사진 추가
          </Button>
        </div>
      </div>
    </div>
  );
}
