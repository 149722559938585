import { gql } from "@apollo/client";

export const PRODUCT_FIELDS = gql`
  fragment ProductFields on products {
    id
    name
    brand
    description
    serial
    price
    photo
    created_at
    updated_at
    active
  }
`;
