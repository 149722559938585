import { useLazyQuery } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  GET_CUSTOMER_TAGS_BY_IDS,
  SEARCH_CUSTOMER_TAG,
} from "../../../../gqls/customer";
import { tag } from "../../../../types/common";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Search from "@mui/icons-material/Search";

interface props {
  tagIds?: number[];
  disabled?: boolean;
}

export default function CustomerTagControl({ tagIds, disabled }: props) {
  const { control, getValues, reset } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tags",
    keyName: "fid",
  });

  const [search, setSearch] = useState("");
  const [results, setResults] = useState([] as tag[]);
  const [searchTags] = useLazyQuery(SEARCH_CUSTOMER_TAG);

  useEffect(() => {
    if (search == "") {
      setResults([]);
      return;
    }

    searchTags({
      variables: {
        Str: `%${search}%`,
      },
      fetchPolicy: "network-only",
      onCompleted(data) {
        const res = data.customerTags;
        if (res) {
          const currentTags = getValues("tags") as tag[];
          setResults(res.filter(r => !currentTags.some(c => c.id === r.id)));
        }
      },
      onError(error) {
        console.log(error);
      },
    });
  }, [search]);

  const [getTags] = useLazyQuery(GET_CUSTOMER_TAGS_BY_IDS);

  useEffect(() => {
    if (!tagIds) return;
    getTags({
      variables: {
        ids: tagIds,
      },
      onCompleted(data) {
        const values = getValues();
        reset({
          ...values,
          tags: data.customerTags,
        });
      },
    });
  }, [tagIds]);

  const appendTag = (tag: tag) => {
    append(tag);
    setResults([]);
    setSearch("");
  };

  const addTag = () => {
    if (results.some(r => r.name == search) || search == "") {
      return;
    }
    append({
      name: search,
    });
    setSearch("");
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center flex-wrap">
        <label className="font-semibold">태그</label>
        {!disabled && (
          <div className="relative border-b-[1px] p-1 border-black flex flex-row gap-2 items-center">
            <div className="text-quezone">
              <Search />
            </div>

            <input
              className="outline-none flex-1 placeholder:text-green-600 placeholder:text-opacity-50 text-sm"
              value={search}
              placeholder="새 태그 검색/추가"
              onChange={e => {
                setSearch(e.target.value.replaceAll(" ", ""));
              }}
            />

            <div
              onClick={addTag}
              className={`${
                results.some(r => r.name == search) || search == ""
                  ? "text-transparent"
                  : "text-quezone cursor-pointer"
              } `}
            >
              <Add />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {fields.map((field, i) => (
          <div
            key={field.fid}
            className="flex flex-row gap-2 rounded-full font-medium px-2 py-1 border-[1px] border-quezone bg-lightGreen text-sm items-center"
          >
            <div>
              #
              {
                // @ts-expect-error
                field.name
              }
            </div>
            {!disabled && (
              <div
                className=" text-white cursor-pointer"
                onClick={() => remove(i)}
              >
                <CancelRoundedIcon />
              </div>
            )}
          </div>
        ))}
        {results.length > 0 && (
          <>
            {results.map(result => (
              <div
                key={result.id}
                className="flex flex-row gap-2 rounded-full font-medium px-2 py-1 border-[1px] border-quezone bg-lightGreen text-sm items-center text-gray-500 bg-opacity-50 cursor-pointer"
                onClick={() => {
                  appendTag(result);
                }}
              >
                #{result.name}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
