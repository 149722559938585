import { useMutation, useQuery } from "@apollo/client";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  DELETE_REPORT,
  GET_REPORT_BY_ID,
  READ_REPORT_BY_ID,
} from "../../../../../gqls/report";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { useAuthStore, useCheckAuth } from "../../../../../store/authStore";
import useS3 from "../../../../../hooks/useS3";
import { blackButtonContained } from "../../../../../classPresets";
import ReportDetail from "./reportDetail";

interface props {
  nested?: boolean;
}

export default function ReportDetailWrapper({ nested }: props) {
  const { reportId } = useParams();

  const { data } = useQuery(GET_REPORT_BY_ID, {
    variables: {
      id: reportId,
    },
    onError(error) {
      console.log(error);
    },
  });

  const report = data?.reports_by_pk;
  const task = report?.task;

  const navigate = useNavigate();
  const fullNum = `#${task?.customer.year}-${task?.customer.number}`;

  const dir = `customers/${task?.customerId}/reports/${reportId}`;

  const printableDiv = useRef(null);
  const print = useReactToPrint({
    content: () => printableDiv.current,
    documentTitle: `${fullNum} ${task?.customer.name} ${task?.type.name} 보고서`,
  });

  const checkAuth = useCheckAuth();

  const canEdit =
    checkAuth({
      permissionName: "보고수정",
      userId: report?.reporterId,
    }) &&
    (!report?.accounts ||
      report.accounts.length == 0 ||
      checkAuth({
        permissionName: "보고수정",
        scopeCheck: ["all", "company", "sub"],
      }));

  const { user } = useAuthStore();

  const [readReport] = useMutation(READ_REPORT_BY_ID);

  useEffect(() => {
    if (
      !checkAuth({
        permissionName: "보고열람",
      })
    ) {
      navigate(-1);
      return;
    }

    if (!report?.reads || !user) {
      return;
    }
    if (report.reads.includes(user.id)) {
      return;
    }

    readReport({
      variables: {
        id: reportId,
        userId: user.id,
      },
    });
  }, [report, user]);

  const [remove] = useMutation(DELETE_REPORT);

  const { deleteDirectory } = useS3();

  const deleteReport = () => {
    const proceed = confirm("보고를 삭제하시겠습니까?");
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id: reportId,
      },
      onCompleted() {
        deleteDirectory(dir);
        navigate(-1);
      },
    });
  };

  if (!report) {
    return null;
  }

  return (
    <div className="flex-1 pb-5 flex flex-col gap-4">
      <div
        ref={printableDiv}
        className={`flex-1 py-5 ${
          !nested && "md:p-8 md:drop-shadow-md md:mx-10"
        } ${nested && "pt-0 md:pt-5"}
        flex flex-col gap-4 md:bg-white overflow-x-clip`}
      >
        <ReportDetail report={report} nested={nested} />
      </div>
      <div
        className={`flex flex-row gap-2 justify-between px-3 md:px-0 ${
          !nested && "md:mx-10"
        }`}
      >
        <Button
          onClick={print}
          variant="contained"
          color="success"
          sx={{ backgroundColor: "gray" }}
        >
          프린트
        </Button>
        {canEdit && (
          <div className="flex flex-row gap-2">
            <Button color="error" onClick={deleteReport}>
              삭제
            </Button>
            <NavLink to={`./edit`}>
              <Button {...blackButtonContained}>수정</Button>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}
