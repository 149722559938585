import { typeOrStatus } from "../../../../../types/common";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { DELETE_TASK_TYPE, UPDATE_TASK_MANY } from "../../../../../gqls/task";
import { useTaskStore } from "../../../../../store/taskStore";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { cacheEvict } from "../../../../../utils/cacheMethods";
import DeleteForever from "@mui/icons-material/DeleteForever";

interface props {
  type: typeOrStatus;
}
export default function DeleteTaskStatus({ type }: props) {
  const { types, deleteType } = useTaskStore();
  const typesNotMe = types.filter(s => s.id != type.id);

  const [altType, setAltType] = useState(0);

  const [remove, { loading: removing }] = useMutation(DELETE_TASK_TYPE);

  const [updateTasks, { loading: updatingTasks }] =
    useMutation(UPDATE_TASK_MANY);

  const loading = removing || updatingTasks;

  const [deleting, setDeleting] = useState(false);

  const _delete = async () => {
    const updates = [
      {
        where: { typeId: { _eq: type.id } },
        _set: {
          typeId: altType,
        },
      },
    ];
    updateTasks({
      variables: { updates },
      onCompleted() {
        remove({
          variables: {
            id: type.id,
          },
          onCompleted() {
            deleteType(type.id);
            setDeleting(false);
          },
          update(cache) {
            cacheEvict({
              cache,
              id: type.id,
              __typename: "taskTypes",
            });
          },
        });
      },
    });
  };

  return (
    <>
      <div className="flex flex-row justify-end mt-4">
        <div
          className="text-red-500 cursor-pointer"
          onClick={() => {
            setDeleting(true);
          }}
        >
          <DeleteForever />
        </div>
      </div>
      <Dialog
        open={deleting}
        onClose={() => {
          setDeleting(false);
        }}
      >
        <DialogTitle>작업 타입 삭제</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <div>{type.name} 타입을 삭제합니다</div>
            <div>삭제될 타입을 대신할 타입을 선택해주세요</div>
            <Select
              required
              size="small"
              color="success"
              value={altType}
              onChange={e => setAltType(Number(e.target.value))}
            >
              <MenuItem value={0}>대체 타입 선택</MenuItem>
              {typesNotMe.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "black" }}
            onClick={() => {
              setDeleting(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={altType === 0}
            loading={loading}
            variant="contained"
            color="success"
            onClick={_delete}
          >
            삭제
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
