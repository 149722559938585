import { useFormContext, useWatch } from "react-hook-form";
import { automation, trigger } from "../../../../../types/automation";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTaskStore } from "../../../../../store/taskStore";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import { sort } from "fast-sort";

interface props {
  automation?: automation;
}

const myTriggers: trigger[] = ["보고 등록", "정산 등록"];

export default function AutomationReportTypeControl({ automation }: props) {
  const { control, setValue } = useFormContext();

  const trigger: trigger = useWatch({
    name: "trigger",
    control,
  });

  let { reportTypes, types } = useTaskStore();
  reportTypes = sort(reportTypes).asc(["taskTypeId", "priority"]);

  const reportTypeIds: number[] | null = useWatch({
    name: "reportTypeIds",
    control,
    defaultValue: automation?.reportTypeIds || [],
  });

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    setValue("reportTypeIds", event.target.value);
  };

  if (!myTriggers.includes(trigger)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1">
      <label>보고 구분 조건</label>
      <FormControl fullWidth margin="none" color="success" size="small">
        <Select
          multiple
          placeholder="보고 구분 조건 선택"
          className="shadow-md"
          input={<OutlinedInput />}
          renderValue={selected => {
            if (selected.length === 0) {
              return <>보고 구분 조건을 선택해주세요</>;
            }
            return selected
              .map(s => reportTypes.find(type => type.id === s)?.name)
              .join(", ");
          }}
          displayEmpty
          value={reportTypeIds || []}
          onChange={handleChange}
        >
          {reportTypes.map(type => (
            <MenuItem key={type.id} value={type.id}>
              <Checkbox
                checked={reportTypeIds?.includes(type.id)}
                color="success"
              />
              <ListItemText
                primary={`${types.find(t => t.id == type.taskTypeId)?.name} ${
                  type.name
                }`}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
