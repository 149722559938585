import { useFormContext, useWatch } from "react-hook-form";
import { automation, trigger } from "../../../../../types/automation";
import { useTaskStore } from "../../../../../store/taskStore";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

interface props {
  automation?: automation;
}
export default function AutomationTaskTriggerControl({ automation }: props) {
  const { setValue, control } = useFormContext();

  const trigger: trigger = useWatch({
    name: "trigger",
    control,
  });

  const requiredTriggers = ["작업 등록", "작업 상태 변경"];

  const { statuses, types } = useTaskStore();

  const fromTaskStatIds: number[] | null = useWatch({
    name: "fromTaskStatIds",
    control,
    defaultValue: automation?.fromTaskStatIds || [],
  });

  const taskTypeIds: number[] | null = useWatch({
    name: "taskTypeIds",
    control,
    defaultValue: automation?.taskTypeIds || [],
  });

  const handleChangeStat = (event: SelectChangeEvent<number[]>) => {
    setValue("fromTaskStatIds", event.target.value, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeType = (event: SelectChangeEvent<number[]>) => {
    setValue("taskTypeIds", event.target.value, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  if (!requiredTriggers.includes(trigger)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <label>작업 구분 조건</label>
        <FormControl fullWidth margin="none" color="success" size="small">
          <Select
            multiple
            placeholder="구분 조건 선택"
            className="shadow-md"
            input={<OutlinedInput />}
            renderValue={selected => {
              if (selected.length === 0) {
                return <>구분 조건을 선택해주세요</>;
              }
              return selected
                .map(s => types.find(type => type.id === s)?.name)
                .join(", ");
            }}
            displayEmpty
            value={taskTypeIds || []}
            onChange={handleChangeType}
          >
            {types.map(type => (
              <MenuItem key={type.id} value={type.id}>
                <Checkbox
                  checked={taskTypeIds?.includes(type.id)}
                  color="success"
                />
                <ListItemText primary={type.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex flex-col gap-1">
        <label>작업 상태 조건</label>
        <FormControl fullWidth margin="none" color="success" size="small">
          <Select
            multiple
            placeholder="상태 조건 선택"
            className="shadow-md"
            input={<OutlinedInput />}
            renderValue={selected => {
              if (selected.length === 0) {
                return <>상태 조건을 선택해주세요</>;
              }
              return selected
                .map(s => statuses.find(stat => stat.id === s)?.name)
                .join(", ");
            }}
            displayEmpty
            value={fromTaskStatIds || []}
            onChange={handleChangeStat}
          >
            {statuses.map(stat => (
              <MenuItem key={stat.id} value={stat.id}>
                <Checkbox
                  checked={fromTaskStatIds?.includes(stat.id)}
                  color="success"
                />
                <ListItemText primary={stat.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
