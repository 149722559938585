import { useQuery } from "@apollo/client";
import BlackHeading from "../../../../comps/blackHeading";
import { task } from "../../../../types/task";
import { GET_TASKS_CONDITIONAL } from "../../../../gqls/task";
import CustomerTask from "../../customers/customerDetail/customerProgress/customerTasks/customerTask";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface props {
  task: task;
}

export default function OtherTasks({ task }: props) {
  const { data } = useQuery(GET_TASKS_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: task.customerId,
        },
        id: {
          _neq: task.id,
        },
        assignedToId: {
          _is_null: false,
        },
      },
    },
  });

  const tasks = data?.tasks || [];

  const [extended, setExtended] = useState(false);

  if (tasks.length == 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <BlackHeading title="기타 작업 내역">
        <div className="flex flex-row justify-between items-center cursor-pointer">
          <span>기타 작업 내역</span>
          <div
            className="cursor-pointer hover:text-quezone text-white"
            onClick={() => setExtended(!extended)}
          >
            {extended ? (
              <VisibilityIcon sx={{ width: 18 }} />
            ) : (
              <VisibilityOffIcon sx={{ width: 18 }} />
            )}
          </div>
        </div>
      </BlackHeading>

      <div className={`${!extended && "hidden"} flex flex-col gap-1`}>
        {tasks.map(t => (
          <CustomerTask key={t.id} task={t} inTask />
        ))}
      </div>
    </div>
  );
}
