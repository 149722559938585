import { useQuery } from "@apollo/client";
import {
  invHistoryWithQty,
  inventory,
  statPopulatedInvQty,
} from "../../../../types/inv";
import {
  GET_INV_HISTORY_CONDITIONAL,
  STREAM_INV_HISTORY,
} from "../../../../gqls/inv";
import { useStatePersist } from "use-state-persist";
import { Fragment, useEffect, useState } from "react";
import { cloneDeep } from "apollo-utilities";
import { useInvStore } from "../../../../store/invStore";
import { sort } from "fast-sort";
import Button from "@mui/material/Button";
import { scrollbar } from "../../../../classPresets";
import InvHistory from "./invHistory";
import useInvQty from "../../../../hooks/useInvQty";

interface props {
  inventory: inventory;
}

export default function InventoryHistory({ inventory }: props) {
  const where = {
    _or: [
      {
        fromId: {
          _eq: inventory.id,
        },
      },
      {
        toId: {
          _eq: inventory.id,
        },
      },
    ],
  };

  const [cursor, setCursor] = useStatePersist(
    `@invHistoryCursor:${inventory.id}`,
    new Date().toISOString()
  );

  const [page, setPage] = useState(0);

  const limit = 100;

  const { data, subscribeToMore, fetchMore } = useQuery(
    GET_INV_HISTORY_CONDITIONAL,
    {
      variables: {
        where,
        limit,
        offset: page * limit,
        order_by: {
          created_at: "desc",
        },
      },
      onCompleted() {
        setCursor(new Date().toISOString());
      },
    }
  );

  const loadMore = async () => {
    const offset = (page + 1) * limit;

    const res = await fetchMore({
      variables: {
        where,
        offset,
        limit,
      },
    });

    const received = res?.data?.invHistories;
    if (!received || received.length < 1) {
      return setPage(-1);
    }

    setPage(p => p + 1);
  };

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: STREAM_INV_HISTORY,
      variables: {
        where,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());

        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.invHistories);
        const newFeedItem = subscriptionData.data.invHistories_stream || [];
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];

        return Object.assign({}, previous, {
          invHistories: [...newFeedItem, ...existing],
        });
      },
    });
    return () => {
      unsubscribe();
      setPage(0);
    };
  }, []);

  let qty = useInvQty(inventory, true).reduce<statPopulatedInvQty[]>(
    (acc, q) => {
      const idx = acc.findIndex(a => a.statusId == q.statusId);
      if (idx === -1) {
        acc.push(q);
        return acc;
      } else {
        acc[idx].qty += q.qty;
        return acc;
      }
    },
    []
  );

  let invHistories = sort(data?.invHistories || []).desc("created_at");

  const invHistoriesWithQty: invHistoryWithQty[] = invHistories.map(h => {
    const myChanges = h.changes.filter(change => change.invId == inventory.id);

    qty = qty.map(q => {
      const matchingChange = myChanges.find(c => c.statusId == q.statusId);
      return {
        ...q,
        qty: q.qty - (matchingChange?.diff || 0),
      };
    });

    return { ...h, qty };
  });

  const { invActions, invStatuses } = useInvStore();

  if (!invHistories) {
    return null;
  }

  return (
    <div
      className={`flex flex-col gap-2 max-h-[100dvh] overflow-y-auto ${scrollbar}`}
    >
      {invHistoriesWithQty.map((history, i) => {
        const invAction = invActions.find(a => a.id == history.invActionId);

        if (!invAction) {
          return null;
        }

        return (
          <Fragment key={history.id}>
            {i > 0 && <hr />}
            <InvHistory
              invId={inventory.id}
              history={history}
              invAction={invAction}
              invStatuses={invStatuses}
            />
          </Fragment>
        );
      })}
      {page >= 0 &&
        invHistories.length <
          Number(data?.invHistories_aggregate?.aggregate?.count) && (
          <Fragment>
            <hr />
            <Button color="success" onClick={loadMore}>
              추가 로드
            </Button>
          </Fragment>
        )}
    </div>
  );
}
