import { User } from "../../../../../types/user";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CHANGE_PASSWORD, GET_PASSWORD } from "../../../../../gqls/user";
import { useForm, useWatch } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import bcrypt from "bcryptjs";
import { useAddAlert } from "../../../../../store/alertStore";

interface props {
  user: User;
}

const schema = z
  .object({
    previousPassword: z
      .string()
      .min(8, { message: "현재 비밀번호를 입력해주세요" }),
    password: z
      .string()
      .min(8, { message: "비밀번호는 8자리 이상이여야 합니다" }),
    passwordConfirm: z.string(),
  })
  .refine(data => data.password === data.passwordConfirm, {
    message: "비밀번호가 일치하지 않습니다",
    path: ["passwordConfirm"],
  });

export default function UserPasswordControl({ user }: props) {
  const defaultValues = {
    previousPassword: "",
    password: "",
    passwordConfirm: "",
  };

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [update, { loading: updating }] = useMutation(CHANGE_PASSWORD);
  const [get_password, { loading: fetchingPassword }] =
    useLazyQuery(GET_PASSWORD);

  const loading = updating || fetchingPassword;

  const addAlert = useAddAlert();

  const onSubmit = handleSubmit(async data => {
    const res = await get_password({
      variables: { id: user?.id },
      fetchPolicy: "no-cache",
    });

    if (!res?.data?.users_by_pk?.password) {
      return;
    }

    const { previousPassword } = data;

    const isMatch = await bcrypt.compare(
      previousPassword,
      res?.data?.users_by_pk?.password
    );

    if (!isMatch) {
      addAlert({
        message: "현재 비밀번호와 일치하지 않습니다",
        type: "warning",
      });

      return;
    }

    const salt = await bcrypt.genSalt(10);
    const password = await bcrypt.hash(data.password, salt);

    update({
      variables: {
        id: user.id,
        password,
      },
      onCompleted: data => {
        if (data?.update_users_by_pk) {
          addAlert({
            message: "비밀번호를 성공적으로 변경하였습니다.",
            type: "success",
          });

          reset();
        }
      },
    });
  });

  const prevPassword = useWatch({
    control,
    name: "previousPassword",
  });

  return (
    <form onSubmit={onSubmit} className="col-span-1 flex flex-col gap-4">
      <Paper className="border-x-[1px] md:border-none p-4 flex flex-col">
        <h1>비밀번호 변경</h1>
        <FormControl margin="dense">
          <TextField
            error={errors.previousPassword && touchedFields.previousPassword}
            className="shadow-md col-span-1"
            size="small"
            id="prevPassword"
            autoComplete="off"
            type="password"
            color="success"
            label="현재 비밀번호"
            placeholder="******"
            {...register("previousPassword")}
          />
          <FormHelperText
            error={errors.previousPassword && touchedFields.previousPassword}
          >
            {touchedFields.previousPassword && errors.previousPassword?.message}
          </FormHelperText>
        </FormControl>
        {prevPassword && (
          <>
            <FormControl margin="dense">
              <TextField
                error={errors.password && touchedFields.password}
                className="shadow-md col-span-1"
                size="small"
                id="newPassword"
                autoComplete="off"
                type="password"
                color="success"
                label="새 비밀번호"
                placeholder="******"
                {...register("password")}
              />
              <FormHelperText error={errors.password && touchedFields.password}>
                {touchedFields.password && errors.password?.message}
              </FormHelperText>
            </FormControl>

            <FormControl margin="dense">
              <TextField
                error={errors.passwordConfirm && touchedFields.passwordConfirm}
                className="shadow-md col-span-1"
                size="small"
                id="newPasswordConfirm"
                autoComplete="off"
                type="password"
                color="success"
                label="새 비밀번호 확인"
                placeholder="******"
                {...register("passwordConfirm")}
              />
              <FormHelperText
                error={errors.passwordConfirm && touchedFields.passwordConfirm}
              >
                {touchedFields.passwordConfirm &&
                  errors.passwordConfirm?.message}
              </FormHelperText>
            </FormControl>
          </>
        )}
      </Paper>
      {isDirty && (
        <div className="flex flex-row gap-2 items-center justify-end flex-1">
          <Button
            color="success"
            onClick={() => {
              reset();
            }}
          >
            취소
          </Button>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            color="success"
            sx={{
              backgroundColor: "black",
              fontWeight: 500,
            }}
          >
            변경
          </LoadingButton>
        </div>
      )}
    </form>
  );
}
