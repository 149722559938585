import { useQuery } from "@apollo/client";
import { GET_COMPANY_TYPES } from "../../../../../gqls/company";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Controller, useFormContext } from "react-hook-form";

export default function CompanyType({ defaultType }: { defaultType: number }) {
  const { data } = useQuery(GET_COMPANY_TYPES);
  const types = data?.companyTypes;

  const { register, control } = useFormContext();

  if (!types) {
    return null;
  }

  return (
    <FormControl margin="dense">
      <label htmlFor="companyType">구분</label>
      <Controller
        name="typeId"
        control={control}
        render={({ field }) => (
          <Select
            className="shadow-md"
            labelId="companyType"
            id="companyType"
            size="small"
            color="success"
            defaultValue={defaultType}
            value={field.value || ""}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled}
          >
            {types &&
              types.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
          </Select>
        )}
      />
    </FormControl>
  );
}
