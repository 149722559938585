import { ButtonProps } from "@mui/material";

export const scrollbar =
  "md:scrollbar-thin md:scrollbar-thumb-[#C1C1C1] md:hover:scrollbar-thumb-[#A8A8A8] scrollbar-track-transparent";

export const modal =
  "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-sm max-w-[90dvw] shadow-md bg-white border-[1px] max-h-[90dvh] overflow-y-auto";

export const blackButtonContained: Partial<ButtonProps> = {
  variant: "contained",
  color: "success",
  sx: { backgroundColor: "black", fontWeight: 400 },
};

export const blackButtonOutlined: Partial<ButtonProps> = {
  variant: "outlined",
  color: "success",
  sx: {
    color: "black",
    fontWeight: 400,
    borderColor: "black",
  },
};

export const fieldWrapper = "py-3 md:py-0 px-3 print:py-0 md:px-0 print:px-0";

export const fieldWrapperEdit = (striped?: boolean) =>
  `flex flex-row justify-between items-center md:items-stretch md:justify-normal md:flex-col py-3 md:py-0 px-3 md:px-0 ${
    striped && "bg-gray-100 md:bg-transparent"
  }`;
