import { useProductStore } from "../../../../../../store/productStore";
import { customer } from "../../../../../../types/customer";
import InventoryIcon from "@mui/icons-material/Inventory";

interface props {
  inventories: customer["inventories"];
}

interface customerInventory {
  id: number;
  productId: number;
  qty: {
    statusId: number;
    qty: number;
  }[];
  subQty?: {
    statusId: number;
    qty: number;
  }[];
}

export default function CustomerInventories({ inventories }: props) {
  return (
    <div className="flex flex-row flex-grow w-0 items-center gap-2">
      {inventories?.map(inventory => (
        <Inv key={inventory.id} inventory={inventory} />
      ))}
    </div>
  );
}

const Inv = ({ inventory }: { inventory: customerInventory }) => {
  const { products } = useProductStore();

  const product = products.find(p => p.id === inventory.productId);

  const qty = inventory.qty.reduce<number>((prev, cur) => {
    return prev + Number(cur.qty);
  }, 0);

  const subQty = inventory.subQty?.reduce<number>((prev, cur) => {
    return prev + Number(cur.qty);
  }, 0);

  const total = qty + (subQty || 0);

  if (!product || total === 0) {
    return null;
  }

  return (
    <div className="flex flex-row gap-1 text-sm items-center" title="재고정보">
      <InventoryIcon sx={{ width: 16 }} />
      <div>
        {product.name}:{total}
      </div>
    </div>
  );
};
