import { useQuery } from "@apollo/client";
import { source } from "../../../../../../types/source";
import { GET_SOURCE_DETAILS_CONDITIONAL } from "../../../../../../gqls/source";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { useState } from "react";
import EditSource from "./editSource";
import { company } from "../../../../../../types/company";
import { useNavigate } from "react-router-dom";

interface props {
  source: source;
  company: company;
}

export default function CompanyTypeSources({ source, company }: props) {
  const { data } = useQuery(GET_SOURCE_DETAILS_CONDITIONAL, {
    variables: {
      where: {
        sourceId: {
          _eq: source.id,
        },
      },
    },
  });

  const sourceDetails = data?.sourceDetails || [];

  const navigate = useNavigate();
  const goToCompanyDetail = (id?: number) => {
    if (!id) {
      return;
    }
    navigate(`/settings/company/detail/${id}`);
  };

  const [editing, setEditing] = useState(false);
  return (
    <>
      {sourceDetails.map((sourceDetail, i) => (
        <TableRow key={sourceDetail.id}>
          {i == 0 && (
            <TableCell
              rowSpan={sourceDetails.length}
              className="border-r-[1px]"
            >
              {source.name}
            </TableCell>
          )}

          <TableCell
            className="hover:text-quezone cursor-pointer"
            onClick={() => {
              goToCompanyDetail(sourceDetail.companyId);
            }}
          >
            {sourceDetail.company?.name}
          </TableCell>
          {i == 0 && (
            <TableCell
              className="border-l-[1px] w-20"
              rowSpan={sourceDetails.length}
            >
              <Button color="success" onClick={() => setEditing(true)}>
                수정
              </Button>
            </TableCell>
          )}
        </TableRow>
      ))}
      {editing && (
        <EditSource
          source={source}
          sourceDetails={sourceDetails || []}
          company={company}
          editing={editing}
          setEditing={setEditing}
        />
      )}
    </>
  );
}
