import { useQuery } from "@apollo/client";
import { GET_COMPANY_BY_ID } from "../../../../../gqls/company";
import { useNavigate, useParams } from "react-router-dom";
import BasicInfo from "./basicInfo";
import CompanyDeleteControl from "./companyDeleteControl";
import ReplyIcon from "@mui/icons-material/Reply";
import CompanyUsers from "./companyUsers";
import CompanySourceControl from "./sourceControl/companySourceControl";
import CompanySubControl from "./subControl/companySubControl";
import CustomerTypeControl from "./customerTypeControl";
import CustomerStatusControl from "./customerStatusControl";
import InvLocationControl from "./invLocationControl";
import CompanyActiveControl from "./companyActiveControl";
import { useEffect } from "react";
import { useCheckAuth } from "../../../../../store/authStore";
import MyBadge from "../../../../../comps/myBadge";

export default function CompanyDetail() {
  const { id } = useParams();

  const { data } = useQuery(GET_COMPANY_BY_ID, {
    variables: {
      id,
    },
  });

  const company = data?.companies_by_pk;
  const navigate = useNavigate();
  const checkAuth = useCheckAuth();

  useEffect(() => {
    if (
      !checkAuth({
        permissionName: "설정_회사목록열람",
        companyIds: [Number(id)],
      })
    ) {
      navigate(-1);
    }
  }, []);

  if (!company) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 py-4 md:p-8 w-full">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-2">
          <h1 className={`${!company.active && "text-gray-500"} font-bold`}>
            {company.name}{" "}
            <span className="text-gray-500 ml-2 text-base font-normal">
              #{company.id}
            </span>
          </h1>
          {!company.active && <MyBadge text="비활성" color="grey" />}
        </div>
        <div
          className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
          onClick={() => {
            navigate("/settings/company");
          }}
        >
          <ReplyIcon />
        </div>
      </div>
      <BasicInfo company={company} />
      <div className="flex flex-col gap-8">
        {!company.noUsers && <CompanyUsers company={company} />}
        <CompanySubControl company={company} />
        {company.type.canHaveCustomers && (
          <CompanySourceControl company={company} />
        )}
        <InvLocationControl company={company} />
        {!company.type.canHaveCustomers && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <CustomerTypeControl company={company} />
            <CustomerStatusControl company={company} />
          </div>
        )}
      </div>
      {!company.isQuezone && (
        <div className="flex flex-row justify-end gap-2">
          <CompanyActiveControl company={company} />
          <CompanyDeleteControl company={company} />
        </div>
      )}
    </div>
  );
}
