import { invHistory } from "../../../../types/inv";
import { NavLink } from "react-router-dom";
import { useInvStore } from "../../../../store/invStore";
import InvActionIcon from "../../settings/inventories/invActinos/invActionIcon";
import dayjs from "dayjs";
import { useProductStore } from "../../../../store/productStore";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

interface props {
  invHistory: invHistory;
}
export default function InvLog({ invHistory }: props) {
  const {
    // description,
    invActionId,
    changes,
    created_at,
    from,
    to,
    fromId,
    toId,
  } = invHistory;
  const {
    invActions,
    // invStatuses
  } = useInvStore();
  const { products } = useProductStore();
  const invAction = invActions.find(a => a.id == invActionId);
  const product = products.find(
    p => p.id == from?.productId || p.id == to?.productId
  );

  const toLink = `/inventory/detail/${fromId}`;

  const qtyDiff = changes[0].diff;

  const selfName =
    from?.company?.name ||
    (from?.customer &&
      `#${from?.customer.year}-${from?.customer.number}
  ${from?.customer.name}`) ||
    from?.user?.name;

  const otherName =
    to?.company?.name ||
    (to?.customer &&
      `#${to?.customer.year}-${to?.customer.number}
  ${to?.customer.name}`) ||
    to?.user?.name;

  if (!invAction) {
    return null;
  }

  return (
    <NavLink to={toLink} target={target}>
      <div className="flex flex-col gap-1 bg-white p-2 rounded-sm ring-1 ring-gray-300 hover:text-quezone relative">
        {/* TimeStamp */}
        <div className="text-xs absolute right-full mr-2 top-3 text-gray-500 text-right min-w-max">
          {Math.abs(dayjs(created_at).diff(dayjs(), "minutes")) < 40
            ? dayjs(created_at).fromNow()
            : dayjs(created_at).format("HH:mm")}
        </div>

        {/* Log */}
        <div className="flex flex-row gap-1 items-center justify-between flex-wrap">
          <div className="flex flex-row gap-1 items-center">
            <div
              style={{
                color: invAction.color,
              }}
            >
              <InvActionIcon type={invAction.type} />
            </div>
            <div className="font-medium">
              {invAction.name}{" "}
              {changes.length < 2 ? (qtyDiff < 0 ? "-" : "+") : "±"}{" "}
              {Math.abs(qtyDiff)}
            </div>
          </div>
          <div className="text-sm">{product?.name}</div>
        </div>
        {/* Relevant Inv */}
        <div className="font-light">
          {selfName}
          {fromId !== toId && ` => ${otherName}`}
        </div>
      </div>
    </NavLink>
  );
}
