interface props {
  title?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
}
export default function BlackHeading({ title, fullWidth, children }: props) {
  return (
    <div
      className={`bg-gray-500 font-light text-white p-3 md:py-2 ${
        fullWidth ? "w-full" : ""
      }`}
    >
      {children || title}
    </div>
  );
}
