import { create } from "zustand";
import { filter, typeOrStatus } from "../types/common";
import { reportType } from "../types/report";
import { taskTypeCheck } from "../types/taskTypeCheck";
import { tab } from "../types/common";
import { useAuthStore, useCheckAuth } from "./authStore";
import { useQuery } from "@apollo/client";
import { GET_TASK_RELATED_CONFIGS } from "../gqls/task";
import { useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useCustomerStore } from "./customerStore";

type Store = {
  statuses: typeOrStatus[];
  setStatuses: (status: typeOrStatus[]) => void;
  updateStatuses: (statuses: typeOrStatus[]) => void;
  addStatus: (status: typeOrStatus) => void;
  deleteStatus: (id: number) => void;
  types: typeOrStatus[];
  setTypes: (types: typeOrStatus[]) => void;
  updateTypes: (types: typeOrStatus[]) => void;
  addType: (type: typeOrStatus) => void;
  deleteType: (id: number) => void;
  reportTypes: reportType[];
  setReportTypes: (type: reportType[]) => void;
  updateReportTypes: (types: reportType[]) => void;
  addReportType: (type: reportType) => void;
  deleteReportType: (id: number) => void;
  taskTypeChecks: taskTypeCheck[];
  setTaskTypeChecks: (check: taskTypeCheck[]) => void;
  updateTaskTypeChecks: (checks: taskTypeCheck[]) => void;
  addTaskTypeCheck: (check: taskTypeCheck) => void;
  deleteTaskTypeCheck: (id: number) => void;
  tabs: tab[];
  setTabs: (tabs: tab[]) => void;
  currentTab?: string;
  setCurrentTab: (id: string) => void;
  selectedDate: Dayjs;
  setSelectedDate: (selectedDate: Dayjs) => void;
};

export const useTaskStore = create<Store>(set => ({
  statuses: [],
  setStatuses: statuses =>
    set(state => ({
      ...state,
      statuses,
    })),
  updateStatuses(statuses) {
    set(state => ({
      ...state,
      statuses: state.statuses.map(
        stat => statuses.find(s => s.id == stat.id) || stat
      ),
    }));
  },
  addStatus(status) {
    set(state => ({
      ...state,
      statuses: state.statuses.concat(status),
    }));
  },
  deleteStatus(id) {
    set(state => ({
      ...state,
      statuses: state.statuses.filter(s => s.id != id),
    }));
  },
  types: [],
  setTypes: types =>
    set(state => ({
      ...state,
      types,
    })),
  updateTypes(types) {
    set(state => ({
      ...state,
      types: state.types.map(type => types.find(s => s.id == type.id) || type),
    }));
  },
  addType(type) {
    set(state => ({
      ...state,
      types: state.types.concat(type),
    }));
  },
  deleteType(id) {
    set(state => ({
      ...state,
      types: state.types.filter(s => s.id != id),
    }));
  },
  reportTypes: [],
  setReportTypes: reportTypes =>
    set(state => ({
      ...state,
      reportTypes,
    })),
  updateReportTypes(reportTypes) {
    set(state => ({
      ...state,
      reportTypes: state.reportTypes.map(
        type => reportTypes.find(s => s.id == type.id) || type
      ),
    }));
  },
  addReportType(type) {
    set(state => ({
      ...state,
      reportTypes: state.reportTypes.concat(type),
    }));
  },
  deleteReportType(id) {
    set(state => ({
      ...state,
      reportTypes: state.reportTypes.filter(s => s.id != id),
    }));
  },
  taskTypeChecks: [],
  setTaskTypeChecks: taskTypeChecks =>
    set(state => ({
      ...state,
      taskTypeChecks,
    })),
  updateTaskTypeChecks(taskTypeChecks) {
    set(state => ({
      ...state,
      taskTypeChecks: state.taskTypeChecks.map(
        check => taskTypeChecks.find(s => s.id == check.id) || check
      ),
    }));
  },
  addTaskTypeCheck(check) {
    set(state => ({
      ...state,
      taskTypeChecks: state.taskTypeChecks.concat(check),
    }));
  },
  deleteTaskTypeCheck(id) {
    set(state => ({
      ...state,
      taskTypeChecks: state.taskTypeChecks.filter(s => s.id != id),
    }));
  },
  tabs: localStorage.getItem("taskTabs")
    ? JSON.parse(localStorage.getItem("taskTabs")!)
    : [],
  setTabs: tabs => {
    localStorage.setItem("taskTabs", JSON.stringify(tabs));
    return set(state => ({
      ...state,
      tabs,
    }));
  },
  currentTab: localStorage.getItem("taskTab") || undefined,
  setCurrentTab: id => {
    localStorage.setItem("taskTab", id);
    return set(state => ({
      ...state,
      currentTab: id,
    }));
  },
  selectedDate: dayjs(),
  setSelectedDate: selectedDate =>
    set(state => ({
      ...state,
      selectedDate,
    })),
}));

export const useGetFilters = () => {
  const { user } = useAuthStore();

  const { types, statuses } = useTaskStore();
  const { statuses: customerStats } = useCustomerStore();
  const { permissions } = useAuthStore();

  const { data, loading } = useQuery(GET_TASK_RELATED_CONFIGS, {
    onError(error) {
      console.log(error);
    },
  });

  const checkAuth = useCheckAuth();

  const getFilters = () => {
    function* infinite() {
      let index = 0;

      while (true) {
        yield index++;
      }
    }

    const priorityGen = infinite();

    if (loading || !user || types.length == 0 || statuses.length == 0) {
      return undefined;
    }

    const defaultFilters: filter[] = [];

    // Customer Stats
    const options_customer_stats = customerStats.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_customer_stats = customerStats.map(t => t.id);

    const customerStatFilter: filter = {
      name: "고객상태",
      where: "customer.statId",
      type: "in",
      values: defaultValues_customer_stats,
      defaultValues: defaultValues_customer_stats,
      options: options_customer_stats,
      on: false,
      defaultOn: false,
      required: false,
    };
    defaultFilters.push(customerStatFilter);

    // Types
    const options_type = types.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_type = types.map(t => t.id);

    const typeFilter: filter = {
      name: "구분",
      where: "typeId",
      type: "in",
      values: defaultValues_type,
      defaultValues: defaultValues_type,
      options: options_type,
      on: true,
      defaultOn: true,
      required: true,
      sort: {
        where: "type.priority",
        dir: "asc",
        priority: priorityGen.next().value || 0,
      },
      defaultSort: {
        where: "type.priority",
        dir: "asc",
        priority: priorityGen.next().value || 0,
      },
    };
    defaultFilters.push(typeFilter);

    // Companies
    const options_companies = data?.companies
      .filter(c => c.type.canHaveCustomers && c.active)
      .map(c => ({
        name: c.name,
        value: c.id,
      }));

    const defaultValues_companies = options_companies?.map(o => o.value) || [];

    const companyFilter: filter = {
      name: "고객회사",
      where: "customer.companyId",
      type: "in",
      values: defaultValues_companies,
      defaultValues: defaultValues_companies,
      options: options_companies,
      on: true,
      defaultOn: true,
      required: true,
    };

    defaultFilters.push(companyFilter);

    // Companies
    const options_install_companies = data?.companies
      .filter(c => c.type.isInstallAgency && c.active)
      .map(c => ({
        name: c.name,
        value: c.id,
      }));

    const defaultValues_install_companies =
      options_install_companies?.map(o => o.value) || [];

    const installCompanyFilter: filter = {
      name: "작업회사",
      where: "companyId",
      type: "in",
      values: defaultValues_install_companies,
      defaultValues: defaultValues_install_companies,
      options: options_install_companies,
      on: true,
      defaultOn: true,
      required: true,
    };

    defaultFilters.push(installCompanyFilter);

    const taskCanBeAssignedPermission = permissions?.find(
      p => p.name == "작업피지명"
    )?.id;

    const options_users = (data?.users || [])
      .filter(
        u =>
          checkAuth({
            permissionName: "작업열람",
            userId: u.id,
          }) &&
          u.auth.permissions.find(p => p.id == taskCanBeAssignedPermission)
      )
      .map(u => ({
        name: u.name,
        value: u.id,
        data: u,
      }));

    const defaultValues_users = [user.id || 0];

    const userFilter: filter = {
      name: "작업자",
      where: "assignedToId",
      includeNullOption: checkAuth({
        permissionName: "작업_미정열람",
      })
        ? true
        : false,
      includeNull: false,
      type: "in",
      values: defaultValues_users,
      defaultValues: defaultValues_users,
      options: options_users,
      dependsOn: "작업회사",
      dependsValue: "companyId",
      on: true,
      defaultOn: true,
      required: true,
    };

    defaultFilters.push(userFilter);

    const taskAddPermissionId = permissions?.find(
      p => p.name == "고객_작업추가"
    )?.id;

    const options_assignee = (data?.users || [])
      .filter(
        u =>
          u.auth.name == "admin" ||
          u.auth.permissions?.find(p => p.id === taskAddPermissionId)
      )
      .map(u => ({
        name: u.name,
        value: u.id,
      }));

    const defaultValues_assignee = [user.id || 0];

    const assigneeFilter: filter = {
      name: "담당자",
      where: "assignedById",
      type: "in",
      values: defaultValues_assignee,
      defaultValues: defaultValues_assignee,
      options: options_assignee,
      on: false,
      defaultOn: false,
      required: false,
    };

    defaultFilters.push(assigneeFilter);

    // Year
    const years = [];

    for (let i = 2018; i <= new Date().getFullYear(); i++) {
      years.push(i - 2000);
    }

    const options_year = years.map(y => ({
      name: y.toString(),
      value: y,
    }));

    const yearFilter: filter = {
      name: "년도",
      where: "customer.year",
      type: "in",
      values: years.slice(-1),
      defaultValues: years.slice(-1),
      options: options_year,
      on: true,
      defaultOn: true,
      required: true,
      sort: {
        dir: "desc",
        priority: priorityGen.next().value || 0,
        where: "customer.year",
      },
      defaultSort: {
        dir: "desc",
        priority: priorityGen.next().value || 0,
        where: "customer.year",
      },
    };

    defaultFilters.push(yearFilter);

    // Status
    const options_status = statuses.map(s => ({
      name: s.name,
      value: s.id,
    }));

    const defaultValues_status = statuses.map(s => s.id);

    const statusFilter: filter = {
      name: "상태",
      where: "statusId",
      type: "in",
      values: defaultValues_status,
      defaultValues: defaultValues_status,
      options: options_status,
      on: true,
      defaultOn: true,
      required: true,
      sort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "status.priority",
      },
      defaultSort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "status.priority",
      },
    };

    defaultFilters.push(statusFilter);

    // installDate
    const taskDate: filter = {
      name: "작업일자",
      where: "date",
      type: "range",
      rangeType: "date",
      values: {
        from: undefined,
        to: undefined,
      },
      defaultValues: {
        from: undefined,
        to: undefined,
      },
      on: false,
      defaultOn: false,
      required: false,
      sort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "date",
      },
      defaultSort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "date",
      },
    };

    defaultFilters.push(taskDate);

    // reportDate
    const reportDate: filter = {
      name: "보고일자",
      where: "report.date",
      type: "range",
      rangeType: "date",
      values: {
        from: undefined,
        to: undefined,
      },
      defaultValues: {
        from: undefined,
        to: undefined,
      },
      on: false,
      defaultOn: false,
      required: false,
    };

    defaultFilters.push(reportDate);

    // Updated_At
    const updated_at: filter = {
      name: "최근 업데이트",
      where: "updated_at",
      type: "range",
      rangeType: "date",
      values: {
        from: undefined,
        to: undefined,
      },
      defaultValues: {
        from: undefined,
        to: undefined,
      },
      on: false,
      defaultOn: false,
      required: false,
      sort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "updated_at",
      },
      defaultSort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "updated_at",
      },
    };

    defaultFilters.push(updated_at);

    // tags
    const options_tags = data?.taskTags.map(tag => ({
      name: tag.name,
      value: tag.id,
    }));

    const tagsFilter: filter = {
      name: "태그",
      where: "tagIds",
      type: "contains",
      values: [],
      defaultValues: [],
      options: options_tags,
      on: false,
      defaultOn: false,
      required: false,
    };

    defaultFilters.push(tagsFilter);

    // product
    const options_products = data?.products.map(p => ({
      name: p.name,
      value: p.id,
    }));

    const productFilter: filter = {
      name: "제품",
      where: "customer.inventories.productId",
      type: "in",
      values: [],
      defaultValues: [],
      options: options_products,
      on: false,
      defaultOn: false,
      required: false,
    };

    defaultFilters.push(productFilter);

    return defaultFilters;
  };

  return {
    loading,
    getFilters: useCallback(getFilters, [loading, types, statuses, user]),
  };
};
