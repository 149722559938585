import { useEffect, useRef, useState } from "react";
import { useCustomerStore } from "../../../../store/customerStore";
import { tab } from "../../../../types/common";

interface props {
  tab: tab;
}

export default function TabSelect({ tab }: props) {
  const { currentTab, setCurrentTab, tabs, setTabs } = useCustomerStore();

  const [width, setWidth] = useState(0);
  const span = useRef<HTMLSpanElement | null>(null);
  const input = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!span.current) return;
    setWidth(span.current.offsetWidth);
  }, [tab.name]);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTabs(
      tabs.map(t => (t.id == tab.id ? { ...t, name: evt.target.value } : t))
    );
  };

  const isCurrent = tab.id == currentTab;

  const handleClick = () => {
    if (isCurrent && input.current) {
      input.current.focus();
      return;
    }
    setCurrentTab(tab.id);
  };

  return (
    <div
      onClick={handleClick}
      className={`cursor-pointer font-medium border-b-2 ${
        isCurrent
          ? "text-quezone border-b-quezone"
          : "md:hover:text-quezone md:hover:ring-quezone border-b-transparent"
      } px-2 md:px-3 py-[1px] rounded-sm`}
    >
      <span className="absolute opacity-0 px-[1px]" ref={span}>
        {tab.name}
      </span>
      <input
        ref={input}
        type="text"
        value={tab.name}
        className={`bg-transparent outline-none ${
          !isCurrent && "pointer-events-none"
        }`}
        style={{ width }}
        onChange={changeHandler}
      />
    </div>
  );
}
