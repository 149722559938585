import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import BG from "/quezone.svg";
import { useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAddAlert } from "../../store/alertStore";
import { useRecover } from "../../store/authStore";

const recoverSchema = z.object({
  email: z.string().email({ message: "올바른 이메일 형식이 아닙니다" }),
});

export default function Recover() {
  const defaultValues = {
    email: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(recoverSchema),
  });

  const [restrict, setRestrict] = useState(false);

  const addAlert = useAddAlert();

  useEffect(() => {
    if (restrict) {
      setTimeout(() => {
        setRestrict(false);
      }, 5000);
    }
  }, [restrict]);

  const recover = useRecover();

  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async data => {
    if (!restrict) {
      setLoading(true);
      await recover(data.email);

      addAlert({
        type: "success",
        message: "복구 이메일이 발송되었습니다, 메일함을 확인해주세요",
      });

      setLoading(false);
      reset();
      setRestrict(true);
    } else {
      addAlert({
        type: "warning",
        message: "비밀번호 찾기 재시도 대기 제한 (5초)",
      });
    }
  });

  const navigate = useNavigate();

  return (
    <form onSubmit={onSubmit} className="flex flex-col pt-20 px-10">
      <img src={BG} className="max-w-[250px] m-auto mb-12" />
      {/* Email */}
      <FormControl fullWidth>
        <TextField
          className="shadow-lg"
          error={errors.email && touchedFields.email}
          margin="normal"
          required
          id="id"
          label="이메일"
          autoComplete="id"
          autoFocus
          type="email"
          color="success"
          placeholder="이메일"
          {...register("email")}
          InputLabelProps={{
            style: {
              fontWeight: 500,
            },
          }}
        />
        <FormHelperText error={errors.email && touchedFields.email}>
          {touchedFields.email && errors.email?.message}
        </FormHelperText>
      </FormControl>
      {/* Password */}

      <LoadingButton
        type="submit"
        fullWidth
        loading={loading}
        variant="contained"
        color="success"
        sx={{
          mt: 2,
          mb: 2,
          height: "56px",
          backgroundColor: "black",
          fontWeight: 500,
        }}
      >
        비밀번호 찾기
      </LoadingButton>

      <div className="flex flex-row justify-between items-center text-sm">
        <div
          className="text-quezone font-semibold cursor-pointer"
          onClick={() => {
            navigate("/login");
          }}
        >
          로그인
        </div>
        <div className="flex flex-row items-center gap-2">
          계정이 없으신가요?
          <div
            className="text-quezone font-semibold cursor-pointer"
            onClick={() => {
              navigate("/register");
            }}
          >
            회원가입
          </div>
        </div>
      </div>
    </form>
  );
}
