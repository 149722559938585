import { useQuery } from "@apollo/client";
import { source } from "../../../../../../types/source";
import { GET_SOURCE_DETAILS_CONDITIONAL } from "../../../../../../gqls/source";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { useState } from "react";
import EditSource from "./editSource";
import { company } from "../../../../../../types/company";

interface props {
  source: source;
  company: company;
}

export default function OtherTypeSources({ source, company }: props) {
  const { data } = useQuery(GET_SOURCE_DETAILS_CONDITIONAL, {
    variables: {
      where: {
        sourceId: {
          _eq: source.id,
        },
      },
    },
  });

  const sourceDetails = data?.sourceDetails || [];

  // const sourceDetails = (data?.sourceDetails || []).filter(
  //   sd => sd.sourceId == source.id
  // );

  const [editing, setEditing] = useState(false);
  return (
    <>
      {sourceDetails.map((sourceDetail, i) => (
        <TableRow key={sourceDetail.id}>
          {i == 0 && (
            <TableCell
              rowSpan={sourceDetails.length}
              className="border-r-[1px]"
            >
              {source.name}
            </TableCell>
          )}

          <TableCell>
            {sourceDetail.name}{" "}
            <span className="text-gray-500 text-xs ml-2">
              #{sourceDetail.id}
            </span>
          </TableCell>
          {i == 0 && (
            <TableCell
              className="border-l-[1px] w-20"
              rowSpan={sourceDetails.length}
            >
              <Button color="success" onClick={() => setEditing(true)}>
                수정
              </Button>
            </TableCell>
          )}
        </TableRow>
      ))}
      {editing && (
        <EditSource
          source={source}
          sourceDetails={sourceDetails || []}
          company={company}
          editing={editing}
          setEditing={setEditing}
        />
      )}
    </>
  );
}
