import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PAYMENT_METHODS,
  UPDATE_CUSTOMER_PAYMENT_BY_ID,
} from "../../../../../../gqls/customer";
import { customerPayment } from "../../../../../../types/customer";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import PaymentControls from "./paymentControls";
import { blackButtonContained } from "../../../../../../classPresets";

interface props {
  payment: customerPayment;
  cb: () => void;
}

interface defaultValues
  extends Omit<
    customerPayment,
    "id" | "method" | "enteredBy" | "created_at" | "updated_at"
  > {
  methodId: number;
}

const schema = z.object({
  methodId: z.coerce.number(),
  amount: z.coerce.number(),
  comment: z.string().nullable().optional(),
  received_at: z.coerce.date(),
});

export default function EditPayment({ payment, cb }: props) {
  const { data } = useQuery(GET_PAYMENT_METHODS);

  const paymentMethods = data?.paymentMethods;

  const { id, method, amount, received_at, comment } = payment;

  const defaultValues: defaultValues = {
    methodId: method.id,
    amount,
    received_at: dayjs(received_at),
    comment,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    register,
    handleSubmit,
    reset,

    formState: { isDirty },
  } = methods;

  const [updatePayment, { loading }] = useMutation(
    UPDATE_CUSTOMER_PAYMENT_BY_ID
  );

  const onSubmit = handleSubmit(data => {
    const { methodId, amount, comment, received_at } = data;
    if (amount == 0) {
      return;
    }

    updatePayment({
      variables: {
        id,
        set: {
          methodId,
          amount,
          comment,
          received_at: new Date(received_at as string).toISOString(),
        },
      },

      onCompleted: () => {
        reset();
        cb();
      },
    });
  });

  const cancel = () => {
    reset();
    cb();
  };

  if (!paymentMethods) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className="p-4 max-w-full w-[524px] grid grid-cols-1 lg:grid-cols-2 gap-y-2 gap-x-4"
      >
        <h3 className="col-span-1 md:col-span-2 mb-2">거래 내역 수정</h3>
        <PaymentControls />
        <div className="col-span-1 md:col-span-2 flex flex-row justify-end gap-2 mt-4">
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={cancel}
          >
            취소
          </Button>
          <LoadingButton
            size="small"
            loading={loading}
            type="submit"
            disabled={!isDirty}
            {...blackButtonContained}
          >
            수정
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
}
