import Button from "@mui/material/Button";
import InvStatControl from "./invStatControl";
import { useState } from "react";
import { blackButtonContained } from "../../../../../classPresets";

export default function NewInvStat() {
  const [adding, setAdding] = useState(false);
  return (
    <>
      <Button
        {...blackButtonContained}
        onClick={() => {
          setAdding(true);
        }}
      >
        상태 추가
      </Button>
      {adding && (
        <InvStatControl
          onDismiss={() => {
            setAdding(false);
          }}
        />
      )}
    </>
  );
}
