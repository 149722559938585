import getContrast from "../utils/getContrast";

interface props {
  color: string;
  text: string;
  fill?: boolean;
  big?: boolean;
}

export default function MyBadge({ color, text, fill, big }: props) {
  return (
    <div
      className={`rounded-sm py-[3px] px-[6px]  ${
        !big ? "text-xs" : "text-sm"
      } ring-1`}
      style={{
        color: fill ? getContrast(color) : color,
        // @ts-ignore
        "--tw-ring-color": color,
        backgroundColor: fill ? color : "white",
      }}
    >
      {text}
    </div>
  );
}
