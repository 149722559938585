import { useMenues } from "../../../../store/navStore";
import icon from "/navIcon.png";
import "./sideNav.css";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useLogOut } from "../../../../store/authStore";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Menu from "./menu";

export default function SideNav() {
  const menues = useMenues();
  const logOut = useLogOut();
  const navigate = useNavigate();

  const toHome = () => {
    navigate("/");
  };

  return (
    <div
      id="sideNav"
      className="hidden md:flex flex-col h-[100dvh] py-8 bg-white w-24 gap-5"
    >
      <div
        className="flex flex-row justify-center cursor-pointer"
        onClick={toHome}
      >
        <img src={icon} className="w-12" />
      </div>
      <hr className="w-1/2 mx-auto border-[1.5px]" />
      <div className="flex flex-col flex-1 justify-around py-5">
        {menues.map(menu => (
          <Menu key={menu.path} menu={menu} />
        ))}
      </div>
      <hr className="w-1/2 mx-auto border-[1.5px]" />
      <div
        className="flex flex-row justify-center cursor-pointer items-center"
        onClick={logOut}
      >
        <Tooltip title="로그아웃">
          <LogoutOutlinedIcon
            sx={{ fontSize: 34, color: "lightgray" }}
            className="hover:text-quezone"
          />
        </Tooltip>
      </div>
    </div>
  );
}
