import Button from "@mui/material/Button";
import { company } from "../../../../../types/company";
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY_BY_ID } from "../../../../../gqls/company";

export default function CompanyActiveControl({
  company,
}: {
  company: company;
}) {
  const [update] = useMutation(UPDATE_COMPANY_BY_ID);

  const toggleActive = () => {
    const proceed = confirm(
      `회사를 ${company.active ? "비활성" : "활성"}화 하시겠습니까?`
    );
    if (!proceed) return;

    update({
      variables: {
        id: company.id,
        set: {
          active: !company.active,
        },
      },
    });
  };
  return (
    <Button
      variant="contained"
      color={company.active ? "warning" : "success"}
      onClick={toggleActive}
    >
      {company.active ? "비활성" : "활성"}
    </Button>
  );
}
