import { invHistory } from "../../../../types/inv";
import { useInvStore } from "../../../../store/invStore";
import { useProductStore } from "../../../../store/productStore";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { scrollbar } from "../../../../classPresets";
import Button from "@mui/material/Button";
import { exportData } from "../../../../utils/objectArrayMethods";
import { sort } from "fast-sort";

export default function OverviewHistoryDetail({
  histories,
  closeView,
}: {
  histories: invHistory[];
  closeView: () => void;
}) {
  const { invActions } = useInvStore();
  const { products } = useProductStore();

  const mappedHistories = sort(
    histories.map(history => {
      const {
        description,
        invActionId,
        changes,
        created_at,
        from,
        to,
        fromId,
        id,
      } = history;

      const invAction = invActions.find(a => a.id == invActionId);
      const actionName = invAction?.name;
      const product = products.find(
        p => p.id == from?.productId || p.id == to?.productId
      );
      const toLink = `/inventory/detail/${fromId}`;
      const qtyDiff = Math.abs(changes[0].diff);

      const selfName =
        from?.company?.name ||
        (from?.customer &&
          `#${from?.customer.year}-${from?.customer.number}
     ${from?.customer.name}`) ||
        from?.user?.name;

      const otherName =
        to?.company?.name ||
        (to?.customer &&
          `#${to?.customer.year}-${to?.customer.number}
      ${to?.customer.name}`) ||
        to?.user?.name;

      const timeStamp = dayjs(created_at).format("YYYY-MM-DD HH:mm");

      return {
        id,
        invAction,
        actionName,
        product,
        toLink,
        qtyDiff,
        selfName,
        otherName,
        description,
        timeStamp,
      };
    })
  ).desc("timeStamp");

  const exportToExcel = () => {
    const data = mappedHistories.map(
      ({
        timeStamp,
        actionName,
        product,
        qtyDiff,
        selfName,
        otherName,
        description,
      }) => ({
        일시: timeStamp,
        구분: actionName,
        제품: product?.name,
        수량: qtyDiff,
        위치1: selfName,
        위치2: otherName,
        비고: description,
      })
    );
    exportData(data, "재고 변동 내역");
  };

  return (
    <div
      className={`flex flex-col gap-2 max-h-screen overflow-y-auto ${scrollbar}`}
    >
      <div className="flex flex-row justify-between items-center">
        <h3>상세 기록</h3>

        <Button size="small" variant="text" color="success" onClick={closeView}>
          닫기
        </Button>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>일시</TableCell>
            <TableCell>구분</TableCell>
            <TableCell>제품</TableCell>
            <TableCell>수량</TableCell>
            <TableCell>위치 1</TableCell>
            <TableCell>위치 2</TableCell>
            <TableCell>비고</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mappedHistories.map(history => {
            return (
              <TableRow key={history.id}>
                <TableCell>{history.timeStamp}</TableCell>
                <TableCell>{history.actionName}</TableCell>
                <TableCell>{history.product?.name}</TableCell>
                <TableCell>{history.qtyDiff}</TableCell>
                <TableCell>{history.selfName}</TableCell>
                <TableCell>{history.otherName}</TableCell>
                <TableCell>{history.description}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div className="flex flex-row justify-end">
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={exportToExcel}
        >
          내보내기
        </Button>
      </div>
    </div>
  );
}
