import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";

export function CustomerASDateControlOnly({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name="asDate"
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="YYYY-MM-DD"
            slotProps={{
              textField: {
                size: "small",
                color: "success",
                variant: "outlined",
              },
              openPickerButton: {
                color: "success",
              },
            }}
            disabled={disabled}
            className="shadow-md"
            value={field.value}
            onChange={e => {
              field.onChange(e);
              field.onBlur();
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default function CustomerASDateControl({
  disabled,
}: {
  disabled?: boolean;
}) {
  return (
    <div className="col-span-1 md:col-span-2 flex flex-col gap-1">
      <label>A/S 날짜</label>
      <CustomerASDateControlOnly disabled={disabled} />
    </div>
  );
}
