import { useMediaQuery } from "usehooks-ts";
import { task } from "../../../../../types/task";
import { useEffect } from "react";
import { useTaskStore } from "../../../../../store/taskStore";
import List from "./list";
import Kanban from "./kanban/kanban";
import Table from "./table/table";
import { tab, view } from "../../../../../types/common";
import Statistics from "./statistics/statistics";
import Calendar from "./calendar/calendar";

interface props {
  tasks: task[];
  tab: tab;
  loadMore: () => void;
  loading: boolean;
  canLoadMore: boolean;
  totalLength: number;
  fetchingMore: boolean;
}
export default function Views({
  tasks,
  tab,
  loadMore,
  canLoadMore,
  loading,
  totalLength,
  fetchingMore,
}: props) {
  const isBig = useMediaQuery("(min-width: 768px)", {
    initializeWithValue: true,
  });

  const { setTabs, tabs } = useTaskStore();

  const mobileViews: view[] = ["list", "calendar", "statistics"];

  const changeView = (view: view) => {
    setTabs(
      tabs.map(t => {
        if (t.id == tab.id) {
          return {
            ...t,
            view,
          };
        }
        return t;
      })
    );
  };

  useEffect(() => {
    if (isBig) {
      return;
    }

    if (!mobileViews.includes(tab.view)) {
      changeView("calendar");
    }
  }, [isBig]);

  const view = tab.view;

  return (
    <>
      {view == "list" && (
        <List
          tasks={tasks}
          loadMore={loadMore}
          canLoadMore={canLoadMore}
          totalLength={totalLength}
          fetchingMore={fetchingMore}
          loading={loading}
        />
      )}
      {view == "kanban" && <Kanban tasks={tasks} tab={tab} />}
      {view == "table" && <Table tasks={tasks} />}
      {view == "statistics" && <Statistics tasks={tasks} />}
      {view == "calendar" && <Calendar tasks={tasks} />}
    </>
  );
}
