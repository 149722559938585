import { useFormContext, useWatch } from "react-hook-form";
import { biztalk } from "../../../../../types/biztalk";
import Chip from "@mui/material/Chip";
import getBiztalkVariables from "../../../../../utils/getBiztalkVariables";

export default function BiztalkVariables() {
  const { control } = useFormContext();

  const biztalk = useWatch({
    control,
  }) as biztalk;

  const variables = getBiztalkVariables(biztalk);

  return (
    <div className="flex flex-col gap-2">
      <label>변수</label>
      <div className="flex flex-row gap-2 flex-wrap">
        {variables.length == 0 && "없음"}
        {variables.map(variable => (
          <Chip label={variable} key={variable} />
        ))}
      </div>
    </div>
  );
}
