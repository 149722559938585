import { useTaskStore } from "../../../../../store/taskStore";
import { sort } from "fast-sort";
import Check from "./check";

export default function ReportChecks({
  checks,
  typeId,
  signs,
}: {
  signs: { installer: string | null; customer: string | null };
  typeId: number;
  checks?: number[];
}) {
  const { taskTypeChecks } = useTaskStore();

  const requiredChecks = taskTypeChecks.filter(t => t.typeId == typeId);

  const customerChecks = sort(requiredChecks.filter(c => c.forCustomer)).asc(
    "priority"
  );

  const installerChecks = sort(requiredChecks.filter(c => !c.forCustomer)).asc(
    "priority"
  );

  return (
    <div className="col-span-full grid grid-cols-1 lg:grid-cols-2 print:grid-cols-2 md:gap-4 print:gap-4 bg-gray-100 md:bg-transparent print:bg-transparent">
      <div className="col-span-1 flex flex-col md:gap-4 print:gap-4 justify-between">
        <div className="flex flex-col gap-1">
          <label className="text-black p-3 md:p-0 print:p-0 pb-0">
            고객 확인 사항
          </label>
          <div className="flex flex-col gap-1 p-3 print:p-0 md:p-0">
            {checks &&
              customerChecks.map(check => (
                <Check key={check.id} check={check} checks={checks} />
              ))}
          </div>
        </div>
        <div className="flex flex-col gap-1 items-start bg-gray-100 md:bg-transparent print:bg-transparent">
          <label className="text-black p-3 pb-0 md:p-0 print:p-0">
            고객 서명
          </label>
          <div className="md:border-[1px] print:border-[1px] p-4 md:p-0 w-full h-44 md:h-60 cursor-pointer hover:border-quezone flex flex-col justify-center">
            {signs.customer && (
              <img
                className="h-full object-contain bg-white md:bg-transparent print:bg-transparent"
                src={signs.customer}
                alt="installerSign"
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-span-1 flex flex-col md:gap-4 print:gap-4 justify-between">
        <div className="flex flex-col gap-1">
          <label className="text-black p-3 md:p-0 print:p-0 pb-0">
            기사 확인 사항
          </label>
          <div className="flex flex-col gap-1 p-3 print:p-0 md:p-0">
            {checks &&
              installerChecks.map(check => (
                <Check key={check.id} check={check} checks={checks} />
              ))}
          </div>
        </div>
        <div className="flex flex-col gap-1 items-start bg-gray-100 md:bg-transparent print:bg-transparent">
          <label className="text-black p-3 pb-0 md:p-0 print:p-0">
            기사 서명
          </label>
          <div className="md:border-[1px] print:border-[1px] p-4 md:p-0 w-full h-44 md:h-60 cursor-pointer hover:border-quezone flex flex-col justify-center">
            {signs.installer && (
              <img
                className="h-full object-contain bg-white md:bg-transparent print:bg-transparent"
                src={signs.installer}
                alt="installerSign"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
