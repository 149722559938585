import { useMutation } from "@apollo/client";
import { useAuthStore } from "../store/authStore";
import { customer, customerHistory } from "../types/customer";
import {
  ADD_CUSTOMER_HISTORY,
  ADD_CUSTOMER_HISTORY_MANY_M,
} from "../gqls/customer";
import hasuraFilter from "../utils/hasuraFilter";
import { CUSTOMER_HISTORY_CORE_FIELDS } from "../fragments/customer";

export interface historyToUploadProps {
  customerId: number;
  type: customerHistory["type"];
  message: string;
  statusId?: number;
  oldData?: Partial<customer>;
  newData?: Partial<customer>;
  link?: string;
  taskId?: number;
  created_at?: string;
}

interface historyToUpload extends Omit<customerHistory, "id" | "created_at"> {
  created_at?: string;
}

export default function useAddCustomerHistory() {
  const { user } = useAuthStore();

  const [addCustomerHistory, { loading }] = useMutation(ADD_CUSTOMER_HISTORY);
  const [addCustomerHistory_many] = useMutation(ADD_CUSTOMER_HISTORY_MANY_M);

  const addHistory = async ({
    customerId,
    type,
    message,
    statusId,
    oldData,
    newData,
    link,
    taskId,
    created_at,
  }: historyToUploadProps) => {
    if (!user) {
      return;
    }

    const object: historyToUpload = {
      customerId,
      type,
      message,
      statusId: statusId || undefined,
      oldData,
      newData,
      byId: user?.id,
      link,
      taskId,
      created_at,
    };

    await addCustomerHistory({
      variables: {
        object,
      },
      onError(error) {
        console.log(error);
      },
      update(cache, { data }) {
        const history = data.insert_customerHistories_one;

        cache.modify({
          fields: {
            customerHistories(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: [history],
                name: "customerHistories",
                string: storeFieldName,
              });

              const newCustomerHistoryRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: CUSTOMER_HISTORY_CORE_FIELDS,
                  fragmentName: "CustomerHistoryCoreFields",
                })
              );
              return [...existing, ...newCustomerHistoryRefs];
            },
          },
        });
      },
    });

    return;
  };

  const addHistoryBatch = async (histories: historyToUploadProps[]) => {
    if (!user) {
      return;
    }

    const historiesToUpload = histories.map(h => {
      const {
        customerId,
        type,
        message,
        statusId,
        oldData,
        newData,
        link,
        taskId,
        created_at,
      } = h;

      const object: historyToUpload = {
        customerId,
        type,
        message,
        statusId: statusId || undefined,
        oldData,
        newData,
        byId: user?.id,
        link,
        taskId,
        created_at,
      };

      return object;
    });

    await addCustomerHistory_many({
      variables: {
        objects: historiesToUpload,
      },
      onError(error) {
        console.log(error);
      },
    });
  };

  return {
    addHistory,
    loading,
    addHistoryBatch,
  };
}
