import dayjs from "dayjs";
import { extendedReport } from "../../../../types/report";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useTaskStore } from "../../../../store/taskStore";
import MyBadge from "../../../../comps/myBadge";
import { currencyFormat } from "../../../../utils/numberMethods";
import { NavLink } from "react-router-dom";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

interface props {
  report: extendedReport;
}
export default function ReportItem({ report }: props) {
  const { created_at, typeId, reads, accounts } = report;

  const { reportTypes } = useTaskStore();

  const type = reportTypes.find(type => type.id === typeId);

  const { user } = useAuthStore();

  const updated = reads && user && !reads.includes(user.id);

  const to = `./reportDetail/${report.id}`;

  const checkAuth = useCheckAuth();
  const canView = checkAuth({
    permissionName: "보고정산열람",
    userId: report.task.assignedToId,
  });

  const settlement = accounts.reduce<number>((acc, cur) => {
    const total = cur.amount + cur.gst;
    return acc + total;
  }, 0);

  return (
    <NavLink to={to}>
      <div className="flex flex-row gap-2 items-center flex-wrap justify-between hover:text-quezone hover:brightness-90">
        <div className="flex flex-row gap-2 items-center">
          <AssignmentIcon
            className="text-quezone"
            sx={{ width: 55, height: 55 }}
          />
          <div className="flex flex-col gap-1 items-start">
            <div className="flex flex-row gap-2 items-center">
              {updated && (
                <TipsAndUpdatesIcon
                  sx={{ width: 18 }}
                  className="text-yellow-500 animate-pulse pb-[2px]"
                />
              )}
              <MyBadge text={type?.name || ""} color={type?.color || ""} fill />
            </div>
            <div className="text-sm">
              {dayjs(created_at).format("YYYY. MM. DD")}
            </div>
          </div>
        </div>

        <div className="text-lg font-medium">
          {canView && settlement !== 0 && currencyFormat(settlement)}
        </div>
      </div>
    </NavLink>
  );
}
