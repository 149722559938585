import { scrollbar } from "../../../classPresets";
import Header from "../layouts/header/header";
import Recents from "./recents/recents";
import Upcoming from "./upcoming/upcoming";

export default function Dashboard() {
  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar} md:px-10 bg-white md:bg-transparent px-3`}
    >
      <Header title="대시보드" />
      <div className="flex-1 py-5 grid grid-cols-1 lg:grid-cols-5 gap-y-4 lg:divide-x-2">
        <Upcoming />
        <Recents />
      </div>
    </div>
  );
}
