import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import BG from "/quezone.svg";
import { useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthStore, useLogin } from "../../store/authStore";
import { useNavigate } from "react-router-dom";

const loginSchema = z.object({
  email: z.string().email({ message: "올바른 이메일 형식이 아닙니다" }),
  password: z.string().min(1, { message: "비밀번호를 입력해주세요" }),
  persist: z.boolean(),
});

export default function Login() {
  const defaultValues = {
    email: "",
    password: "",
    persist: true,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(loginSchema),
  });

  const login = useLogin();
  const { loggingIn } = useAuthStore();

  const onSubmit = handleSubmit(data => {
    login(data);
  });

  const navigate = useNavigate();

  return (
    <form onSubmit={onSubmit} className="flex flex-col pt-20 px-10">
      <img src={BG} className="max-w-[250px] m-auto mb-12" />
      {/* Email */}
      <FormControl fullWidth>
        <TextField
          className="shadow-lg"
          error={errors.email && touchedFields.email}
          margin="normal"
          required
          id="id"
          label="이메일"
          autoComplete="id"
          autoFocus
          type="email"
          color="success"
          placeholder="이메일"
          {...register("email")}
          InputLabelProps={{
            style: {
              fontWeight: 500,
            },
          }}
        />
        <FormHelperText error={errors.email && touchedFields.email}>
          {touchedFields.email && errors.email?.message}
        </FormHelperText>
      </FormControl>
      {/* Password */}
      <FormControl fullWidth>
        <TextField
          className="shadow-lg"
          error={errors.password && touchedFields.password}
          margin="normal"
          required
          id="password"
          label="비밀번호"
          autoComplete="password"
          color="success"
          type="password"
          placeholder="비밀번호"
          {...register("password")}
          InputLabelProps={{
            style: {
              fontWeight: 500,
            },
          }}
        />
        <FormHelperText error={errors.password && touchedFields.password}>
          {touchedFields.password && errors.password?.message}
        </FormHelperText>
      </FormControl>
      {/* Actions */}
      <FormControlLabel
        control={
          <Checkbox color="success" defaultChecked {...register("persist")} />
        }
        label="로그인 상태 유지"
      />
      <LoadingButton
        type="submit"
        fullWidth
        loading={loggingIn}
        variant="contained"
        color="success"
        sx={{
          mt: 3,
          mb: 2,
          height: "56px",
          backgroundColor: "black",
          fontWeight: 500,
        }}
      >
        로그인
      </LoadingButton>

      <div className="flex flex-row justify-between items-center text-sm">
        <div
          className="text-quezone font-semibold cursor-pointer"
          onClick={() => {
            navigate("/recover");
          }}
        >
          비밀번호 찾기
        </div>
        <div className="flex flex-row items-center gap-2">
          계정이 없으신가요?
          <div
            className="text-quezone font-semibold cursor-pointer"
            onClick={() => {
              navigate("/register");
            }}
          >
            회원가입
          </div>
        </div>
      </div>
    </form>
  );
}
