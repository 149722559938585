import { useFormContext, useWatch } from "react-hook-form";
import { invAction } from "../../../../../types/inv";
import { useInvStore } from "../../../../../store/invStore";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";

interface props {
  invAction?: invAction;
}

export default function InvActionFromStatControl({ invAction }: props) {
  const { setValue, control } = useFormContext();

  const { invStatuses: statuses } = useInvStore();

  const fromStatIds: number[] | null =
    useWatch({
      name: "fromStatIds",
      control,
      defaultValue: invAction?.fromStatIds || [],
    }) || [];

  const type: invAction["type"] = useWatch({
    name: "type",
    control,
  });

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    setValue("fromStatIds", event.target.value, { shouldDirty: true });
  };

  if (type == "add" || !fromStatIds) {
    return null;
  }

  return (
    <FormControl fullWidth margin="dense" color="success" size="small">
      <InputLabel>상태 조건 선택</InputLabel>
      <Select
        multiple
        className="shadow-md"
        label="상태 조건 선택"
        renderValue={selected => {
          if (selected.length === 0) {
            return <></>;
          }
          return selected
            .map(s => statuses.find(stat => stat.id === s)?.name)
            .join(", ");
        }}
        displayEmpty
        value={fromStatIds || []}
        onChange={handleChange}
      >
        {statuses.map(stat => (
          <MenuItem key={stat.id} value={stat.id}>
            <Checkbox
              checked={fromStatIds?.includes(stat.id)}
              color="success"
            />
            <ListItemText primary={stat.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
