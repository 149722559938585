import { useQuery } from "@apollo/client";
import BlackHeading from "../../../../comps/blackHeading";
import { task } from "../../../../types/task";
import { GET_REPORT_BY_TASK_ID } from "../../../../gqls/report";
import Button from "@mui/material/Button";
import { useCheckAuth } from "../../../../store/authStore";
import { NavLink } from "react-router-dom";
import ReportItem from "../report/reportItem";

interface props {
  task: task;
}

export default function TaskReport({ task }: props) {
  const { data } = useQuery(GET_REPORT_BY_TASK_ID, {
    variables: {
      taskId: task.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const report = data?.reports && data?.reports[0];

  const checkAuth = useCheckAuth();

  const companyIds = [
    task.customer.companyId || 0,
    task.customer.sourceCompanyId || 0,
    task.companyId,
  ];

  const canAdd = checkAuth({
    permissionName: "보고추가",
    companyIds,
    userId: task.assignedToId,
  });

  return (
    <div className="flex flex-col gap-2">
      <BlackHeading title="작업 보고" />

      <div className="border-[1px] p-2">
        {!report && "등록된 보고가 없습니다."}
        {report && <ReportItem report={report} />}
      </div>

      {!report && canAdd && (
        <div className="flex flex-row justify-end px-3 md:px-0">
          <NavLink to="./addReport">
            <Button
              color="success"
              variant="contained"
              size="small"
              sx={{ fontWeight: 300 }}
            >
              보고 등록
            </Button>
          </NavLink>
        </div>
      )}
    </div>
  );
}
