import { product } from "../../../../types/product";
import { Fragment } from "react";
import Avatar from "@mui/material/Avatar";
import PanoramaIcon from "@mui/icons-material/Panorama";
import getContrast from "../../../../utils/getContrast";
import Total from "../companyList/companyInventory/total";
import InvQty from "../companyList/companyInventory/invQty";
import MyBadge from "../../../../comps/myBadge";
import Place from "@mui/icons-material/Place";
import { inventory_populated } from "../../../../types/inv";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import { company } from "../../../../types/company";

interface props {
  product: product;
  qtyPerStat: {
    qty: number;
    available: boolean;
    id: number;
    name: string;
    description?: string | undefined;
    color: `#${string}`;
    priority: number;
  }[];
  qtyPerCompany: {
    companyId: number;
    qties: {
      groupCompanyId: number;
      inventory: inventory_populated;
      id: number;
      invId: number;
      statusId: number;
      qty: number;
    }[];
  }[];
  totalQty: number;
  companies: company[];
  index?: number;
}

export default function ProductListItemMobile({
  product,
  qtyPerStat,
  qtyPerCompany,
  totalQty,
  companies,
  index,
}: props) {
  return (
    <Fragment>
      <hr className={`${Number(index) == 0 ? "hidden" : ""} mt-2 pb-2`} />
      {/* product total */}
      <div className="flex flex-row gap-2 ">
        <Avatar
          variant="square"
          sx={{
            width: 80,
            height: 80,
            bgcolor: "#CCCCCCCC",
            borderRadius: 2,
          }}
          src={product.photo}
        >
          <PanoramaIcon />
        </Avatar>

        <div className="flex flex-1 flex-col gap-1 justify-center">
          <div className="flex flex-row items-center gap-2">
            <div className="truncate">{product.name}</div>
          </div>

          <div className="flex flex-row flex-wrap gap-1 items-center">
            {qtyPerStat.map(stat => (
              <div
                key={stat.id}
                className="px-2 py-1 rounded-md text-xs md:text-sm"
                style={{
                  backgroundColor: stat.color,
                  color: getContrast(stat.color),
                }}
              >
                {stat.name}: {stat.qty}
              </div>
            ))}
            <div className="flex-1 flex flex-row justify-end pr-2">
              <div
                className={`rounded-sm py-[3px] px-[6px] text-xs ring-quezone text-quezone ring-1 `}
              >
                총 {totalQty}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* inventory */}
      {qtyPerCompany.map(companyQty => {
        const company = companies.find(c => c.id == companyQty.companyId);

        const companyInventory = companyQty.qties.find(
          q => q.inventory.company
        )?.inventory;

        const userInventory = companyQty.qties.reduce<inventory_populated[]>(
          (acc, cur) => {
            if (!cur.inventory.userId) {
              return acc;
            }
            if (acc.find(a => a.userId == cur.inventory.userId)) {
              return acc;
            }
            return acc.concat(cur.inventory);
          },
          []
        );

        return (
          <Fragment key={companyQty.companyId}>
            {/* Company Inventory */}
            <Link
              to={
                companyInventory
                  ? `/inventory/detail/${companyInventory.id}`
                  : "#"
              }
            >
              <hr className="py-2" />
              <div className="flex flex-row items-center gap-2 justify-between">
                <div className="flex flex-row gap-2 items-center pt-1 text-sm md:text-base">
                  <Place className="text-quezone" />
                  {company?.name}
                </div>

                <div className="flex flex-wrap flex-row gap-1">
                  {companyInventory ? (
                    <InvQty inv={companyInventory} />
                  ) : (
                    <div className="pr-1">
                      <MyBadge text="재고없음" color="gray" />
                    </div>
                  )}
                  <div
                    className={`rounded-sm py-[3px] px-[6px] text-xs ring-quezone text-quezone ring-1 mr-1`}
                  >
                    총{" "}
                    <Total
                      companyId={company?.id || 0}
                      productId={product.id}
                    />
                  </div>
                </div>
              </div>
            </Link>
            {/* user inventory */}
            {userInventory.map(inv => (
              <Link key={inv.id} to={`/inventory/detail/${inv.id}`}>
                <hr className="py-2" />
                <div className="flex flex-row items-center gap-2 justify-between">
                  <div className="flex flex-row gap-2 items-center ml-4 pt-1 text-sm md:text-base">
                    <PersonIcon />
                    {inv.user?.name}
                  </div>

                  <div className="flex flex-wrap flex-row gap-1">
                    <InvQty inv={inv} />
                  </div>
                </div>
              </Link>
            ))}
          </Fragment>
        );
      })}
    </Fragment>
  );
}
