import { sort } from "fast-sort";
import {
  customerHistory,
  extendedCustomer,
} from "../../../../../../types/customer";
import dayjs from "dayjs";
import HistoryGroup from "./historyGroup";
import { scrollbar } from "../../../../../../classPresets";
import { useQuery } from "@apollo/client";
import {
  GET_CUSTOMER_HISTORY_BY_CUSTOMER_ID,
  STREAM_CUSTOMER_HISTORY_BY_CUSTOMER_ID,
} from "../../../../../../gqls/customer";
import { cloneDeep } from "apollo-utilities";

interface props {
  customer: extendedCustomer;
}

interface historyGroup {
  date: string;
  histories: customerHistory[];
}

const blackBoxClass =
  "px-3 font-thin py-[5px] text-sm rounded-[4px] bg-black text-white";

export default function CustomerHistory({ customer }: props) {
  const { data, loading, subscribeToMore } = useQuery(
    GET_CUSTOMER_HISTORY_BY_CUSTOMER_ID,
    {
      variables: { customerId: Number(customer.id) },
      onError(error) {
        console.log(error);
      },
    }
  );

  subscribeToMore({
    document: STREAM_CUSTOMER_HISTORY_BY_CUSTOMER_ID,
    variables: {
      where: { customerId: { _eq: customer.id } },
      cursor: {
        initial_value: { created_at: new Date().toISOString() },
        ordering: "ASC",
      },
    },

    updateQuery: (previous, { subscriptionData }) => {
      if (!subscriptionData.data) return previous;
      const previousData = cloneDeep(previous.customerHistories);
      const newFeedItem = subscriptionData.data.customerHistories_stream;
      const existing =
        previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];
      const items = [...newFeedItem, ...existing];
      return Object.assign({}, previous, {
        customerHistories: items,
      });
    },
  });

  const histories = data?.customerHistories || [];

  const historyGroups = histories.reduce<historyGroup[]>((prev, cur) => {
    const curDate = dayjs(cur.created_at).format("YYYY.MM.DD");
    if (prev.find(p => p.date == curDate)) {
      return prev.map(p => {
        if (p.date == curDate) {
          p.histories.push(cur);
        }
        return p;
      });
    }
    return prev.concat({
      date: curDate,
      histories: [cur],
    });
  }, []);

  const sortedGroups = sort(historyGroups).by({ desc: g => new Date(g.date) });

  return (
    <div
      className={`flex flex-col max-h-[60dvh] md:max-h-[100dvh] overflow-y-auto ${scrollbar} items-start border-b-[1px] pb-4 border-gray-300 md:border-black px-3 md:px-0`}
    >
      <div className={blackBoxClass}>오늘</div>
      {sortedGroups.map(g => (
        <HistoryGroup group={g} key={g.date} boxClass={blackBoxClass} />
      ))}
    </div>
  );
}
