import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { biztalk } from "../../../../types/biztalk";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { blackButtonContained } from "../../../../classPresets";

interface props {
  biztalk: biztalk;
  displayOnly?: boolean;
}
export default function BiztalkRow({ biztalk, displayOnly }: props) {
  return (
    <Link
      to={`/settings/biztalk/edit/${biztalk.id}`}
      className={`${displayOnly && "pointer-events-none"}`}
    >
      <div className="p-4 shadow-md flex flex-col gap-2">
        <div className="flex flex-row justify-between items-center">
          <h2>{biztalk.tmpltCode}</h2>
          <Chip
            label={biztalk.messageType == "AT" ? "기본형" : "이미지"}
            variant="outlined"
            color="success"
          />
        </div>
        {biztalk.title && <h3>{biztalk.title}</h3>}
        <div className="whitespace-pre-wrap">{biztalk.message}</div>
        <div className="flex flex-row flex-wrap gap-3 justify-end">
          {biztalk.buttons?.map(button => (
            <Tooltip
              title={button.url_mobile || button.url_pc}
              key={button.name}
            >
              <Button {...blackButtonContained} color="success">
                {button.name}
              </Button>
            </Tooltip>
          ))}
          {biztalk.quickReplies?.map(qr => (
            <Tooltip
              key={qr.name}
              title={
                qr.scheme_android || qr.scheme_ios || qr.url_mobile || qr.url_pc
              }
            >
              <Button sx={{ color: "black" }} color="success">
                {qr.name}
              </Button>
            </Tooltip>
          ))}
        </div>
      </div>
    </Link>
  );
}
