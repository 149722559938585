import dayjs from "dayjs";
import BlackHeading from "../../../../comps/blackHeading";
import MyBadge from "../../../../comps/myBadge";
import { task } from "../../../../types/task";
import { currencyFormat } from "../../../../utils/numberMethods";
import TaskDateTimeControl from "./taskDateTimeControl";
import { fieldWrapper } from "../../../../classPresets";
import TaskCustomerInfo from "./taskCustomerInfo";
import { useCheckAuth } from "../../../../store/authStore";
import TaskStatControl from "./taskStatControl";

interface props {
  task: task;
  noCustomerInfo?: boolean;
}

export default function TaskBasicInfo({ task, noCustomerInfo }: props) {
  const {
    company,
    type,
    assignedToId,
    assignedTo,
    assignedBy,
    created_at,
    amount,
    description,
    customer,
    id,
  } = task;

  const { registered_at, warrantyDate } = customer;

  const isUnderWarranty = warrantyDate
    ? dayjs(warrantyDate).isAfter(dayjs())
    : dayjs(registered_at).diff(dayjs(), "days") < 365;

  const checkAuth = useCheckAuth();

  const canStatChange = checkAuth({
    permissionName: "작업열람",
    userId: assignedToId,
  });

  return (
    <div className="flex flex-col md:gap-2">
      <BlackHeading title="작업 기본 정보" />
      <div className="md:border-[1px] md:p-2 flex flex-col md:gap-3">
        <div
          className={`${fieldWrapper} flex flex-row justify-between items-start gap-2`}
        >
          <label className="text-black">작업 고유 번호</label>
          <div className="flex-1 text-right">{id}</div>
        </div>
        <div
          className={`${fieldWrapper} bg-gray-100 md:bg-transparent flex flex-row justify-between items-start gap-2`}
        >
          <label className="text-black">설치 담당 회사</label>
          <div className="flex-1 text-right">{company.name}</div>
        </div>

        {!noCustomerInfo && <TaskCustomerInfo task={task} />}
        <div
          className={`${fieldWrapper} flex flex-row justify-between items-start gap-2`}
        >
          <label className="text-black">작업 기사</label>
          <div className="flex-1 text-right">{assignedTo?.name || "미정"}</div>
        </div>
        <div
          className={`${fieldWrapper} bg-gray-100 md:bg-transparent flex flex-row justify-between items-start gap-2`}
        >
          <label className="text-black">담당자</label>
          <div className="flex-1 text-right">{assignedBy.name}</div>
        </div>
        <div
          className={`${fieldWrapper} flex flex-row justify-between items-center gap-2`}
        >
          <label className="text-black">작업 구분</label>
          <MyBadge color={type.color} text={type.name} big />
        </div>
        <div
          className={`${fieldWrapper} bg-gray-100 md:bg-transparent flex flex-row justify-between items-center gap-2`}
        >
          <label className="text-black">작업 상태</label>
          {assignedToId && canStatChange && <TaskStatControl task={task} />}
        </div>
        <TaskDateTimeControl task={task} />
        <div
          className={`${fieldWrapper} bg-gray-100 md:bg-transparent flex flex-row justify-between items-center gap-2`}
        >
          <label className="text-black">작업 등록일</label>
          <div>{dayjs(created_at).format("YYYY. MM. DD")}</div>
        </div>
        <div
          className={`${fieldWrapper} flex flex-row justify-between items-center gap-2`}
        >
          <label className="text-black">작업 예상 금액</label>
          <div>{currencyFormat(amount || 0)}</div>
        </div>
        <div
          className={`${fieldWrapper} bg-gray-100 md:bg-transparent flex flex-row justify-between items-center gap-2`}
        >
          <label className="text-black">보증 상태</label>
          <div>
            <MyBadge
              text={isUnderWarranty ? "무상" : "유상"}
              color={isUnderWarranty ? "green" : "red"}
              big
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 py-3 md:py-0">
          <label className="text-black px-3 md:px-0">비고</label>
          <div className="whitespace-pre-wrap text-sm p-3 md:p-2 border-[1px] md:rounded-md bg-gray-50">
            {description}
            <p className="mt-2">* 무상기간에도 고객과실일경우 유상입니다</p>
          </div>
        </div>
      </div>
    </div>
  );
}
