import { typeOrStatus } from "../../../../../../types/common";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { ADD_TASK_TYPE_CHECK } from "../../../../../../gqls/taskCheck";
import { useTaskStore } from "../../../../../../store/taskStore";
import { TASK_TYPE_CHECK_FIELDS } from "../../../../../../fragments/task";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

interface props {
  type: typeOrStatus;
  forCustomer: boolean;
}

export default function NewTaskTypeCheck({ type, forCustomer }: props) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      newCheck: "",
    },
  });

  const [insert] = useMutation(ADD_TASK_TYPE_CHECK);

  const { taskTypeChecks, addTaskTypeCheck } = useTaskStore();
  const myTaskTypeChecks = taskTypeChecks.filter(
    check => check.typeId == type.id && !check.forCustomer
  );

  const onSubmit = handleSubmit(data => {
    const { newCheck } = data;

    if (newCheck.trim() == "") {
      return;
    }

    const typeCheckToAdd = {
      typeId: type.id,
      name: newCheck,
      priority: myTaskTypeChecks.length,
      forCustomer,
    };

    insert({
      variables: {
        object: typeCheckToAdd,
      },
      onCompleted: data => {
        addTaskTypeCheck(data.insert_taskTypeChecks_one);
        reset();
      },
      update(cache, { data: { insert_taskTypeChecks_one: newCheck } }) {
        cache.modify({
          fields: {
            taskTypeChecks(existing = []) {
              const newCheckRef = cache.writeFragment({
                data: newCheck,
                fragment: TASK_TYPE_CHECK_FIELDS,
              });
              return [...existing, newCheckRef];
            },
          },
        });
      },
    });
  });

  return (
    <form onSubmit={onSubmit} className="flex flex-row items-center gap-2 pl-2">
      <TextField
        size="small"
        variant="standard"
        {...register("newCheck")}
        className="flex-1"
        placeholder="새 확인 사항 추가"
        color="success"
      />
      <div className="text-quezone cursor-pointer" onClick={onSubmit}>
        <PlaylistAddIcon />
      </div>
    </form>
  );
}
