import Chip from "@mui/material/Chip";
import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useAuthStore } from "../../../../store/authStore";
import { AuthPermission } from "../../../../types/auth";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import useFormValues from "../../../../hooks/useFormValues";
import Tooltip from "@mui/material/Tooltip";
import { sort } from "fast-sort";

export default function AuthPermissionControl() {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "permissions",
    keyName: "fid",
  });

  const typedFields = fields as FieldArrayWithId<
    {
      name: string;
      displayName: string;
      description: string;
      permissions: AuthPermission[];
    },
    "permissions",
    "fid"
  >[];

  const { permissions } = useAuthStore();

  const { permissions: _formPermissions } = useFormValues();

  const formPermissions = (_formPermissions || []) as AuthPermission[];

  const availablePermissions = permissions.filter(
    p => !formPermissions.some(f => f.id == p.id)
  );

  const [adding, setAdding] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <label className="mt-2">권한 단위</label>

      {typedFields.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>설명</TableCell>
              <TableCell>범위</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {typedFields.map((f, i) => {
              const permission = permissions.find(p => p.id == f.id);
              if (!permission) {
                return;
              }
              return (
                <TableRow hover key={f.fid}>
                  <TableCell>{permission.name}</TableCell>
                  <TableCell>{permission.description}</TableCell>
                  <TableCell>
                    <Controller
                      name={`permissions.${i}.scope`}
                      control={control}
                      defaultValue={f.scope}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          value={field.value}
                          onChange={e => field.onChange(e.target.value)}
                          color="success"
                        >
                          <FormControlLabel
                            value="all"
                            control={<Radio size="small" color="success" />}
                            label="전체"
                          />
                          <FormControlLabel
                            value="sub"
                            control={<Radio size="small" color="success" />}
                            label="서브"
                          />
                          <FormControlLabel
                            value="company"
                            control={<Radio size="small" color="success" />}
                            label="회사"
                          />
                          <FormControlLabel
                            value="me"
                            control={<Radio size="small" color="success" />}
                            label="본인"
                          />
                        </RadioGroup>
                      )}
                    />
                  </TableCell>
                  <TableCell className="cursor-pointer">
                    <DeleteForever color="error" onClick={() => remove(i)} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}

      <div className="mt-2 flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <label className="pt-1">새 단위 추가</label>
          <div className="cursor-pointer" onClick={() => setAdding(!adding)}>
            {adding ? (
              <ExpandLessIcon color="error" />
            ) : (
              <ExpandMoreIcon color="success" />
            )}
          </div>
        </div>
        {adding && (
          <Paper className="p-2">
            <div className="flex flex-row flex-wrap gap-2">
              <div className="flex flex-row flex-wrap gap-2">
                {sort(availablePermissions)
                  .asc("name")
                  .map(p => (
                    <Tooltip key={p.id} title={p.description}>
                      <Chip
                        variant="outlined"
                        onClick={() =>
                          append({
                            id: p.id,
                            scope: "me",
                          })
                        }
                        label={p.name}
                      />
                    </Tooltip>
                  ))}
              </div>
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
}
