import { gql } from "@apollo/client";

export const AUTOMATION_FIELDS = gql`
  fragment AutomationFields on automations {
    id
    trigger
    action
    fromCustomerStatIds
    toCustomerStatId
    fromTaskStatIds
    toTaskStatId
    reportTypeIds
    biztalkId
    biztalkVars
    schedule
    dateFrom
    date
    invTo
    created_at
    updated_at
    taskTypeIds
    invActionId
  }
`;
