import { useEffect, useState } from "react";
import Alert from "./comps/alert/Alert";
import { useAuthStore } from "./store/authStore";
import PublicRoot from "./routes/public/routes";
import ProtectedRoot from "./routes/protected/routes";
import { createTheme, ThemeProvider } from "@mui/material";
import { useNotification } from "./notification/useNotification";
import Loading from "./comps/loading";

export default function Root() {
  const user = useAuthStore(state => state.user);

  const cookieEnabled = navigator?.cookieEnabled;

  if (!cookieEnabled) {
    alert("본 앱을 사용하시려면 쿠키 사용을 허용해주세요");
  }

  const { initNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    initNotification()
      .then(_ => {
        setLoading(false);
      })
      .catch(_ => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: ['"omni"', "sans-serif"].join(","),
    },
    palette: {
      mode: "light",
      secondary: {
        main: "#007d25",
      },
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="Root flex h-[100dvh] flex-col">
        <div className="flex-1 text-black">
          {user ? <ProtectedRoot /> : <PublicRoot />}
        </div>
        <Alert />
      </div>
    </ThemeProvider>
  );
}
