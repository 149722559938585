import { useTaskStore } from "../../../../../../store/taskStore";
import { typeOrStatus } from "../../../../../../types/common";
import NewReportType from "./newReportType";
import ReportTypeRow from "./reportTypeRow";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useEffect, useMemo, useState } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { sort } from "fast-sort";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { createPortal } from "react-dom";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@apollo/client";
import { UPDATE_REPORT_TYPES_MANY } from "../../../../../../gqls/report";
import { reportType } from "../../../../../../types/report";
import { blackButtonContained } from "../../../../../../classPresets";

interface props {
  type: typeOrStatus;
}

export default function ReportTypes({ type }: props) {
  const { reportTypes, updateReportTypes } = useTaskStore();
  const [sortedTypes, setSortedTypes] = useState<reportType[]>([]);

  useEffect(() => {
    const myReportTypes = reportTypes.filter(s => s.taskTypeId === type.id);
    setSortedTypes(sort(myReportTypes).asc("priority"));
  }, [reportTypes]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedTypes.find(item => item.id === theActive?.id),
    [theActive, sortedTypes]
  );

  const [touched, setTouched] = useState(false);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedTypes.findIndex(({ id }) => id === active.id);
      const overIndex = sortedTypes.findIndex(({ id }) => id === over.id);
      const movedArray = arrayMove(sortedTypes, activeIndex, overIndex);
      setSortedTypes(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const resetSort = () => {
    setSortedTypes(sort(sortedTypes).asc("priority"));
    setTouched(false);
  };

  const [batchUpdate, { loading }] = useMutation(UPDATE_REPORT_TYPES_MANY);

  const applySort = () => {
    const priorityMappedTypes = sortedTypes.map((type, i) => ({
      ...type,
      priority: i,
    }));

    const updates = priorityMappedTypes.map(type => ({
      where: { id: { _eq: type.id } },
      _set: {
        priority: type.priority,
      },
    }));

    batchUpdate({
      variables: {
        updates,
      },
      onCompleted() {
        updateReportTypes(priorityMappedTypes);
        setSortedTypes(priorityMappedTypes);
        setTouched(false);
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <label>보고 구분</label>
        <NewReportType type={type} />
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToWindowEdges]}
      >
        <SortableContext
          items={sortedTypes.map(type => type.id)}
          strategy={verticalListSortingStrategy}
        >
          {sortedTypes.map(reportType => (
            <ReportTypeRow
              key={reportType.id}
              reportType={reportType}
              isOverlay={false}
            />
          ))}
        </SortableContext>
        {activeItem && (
          <>
            {createPortal(
              <DragOverlay>
                <ReportTypeRow reportType={activeItem} isOverlay={true} />
              </DragOverlay>,
              document.body
            )}
          </>
        )}
      </DndContext>

      <div className="flex flex-row justify-end items-center gap-2 mt-2">
        {touched && (
          <>
            <Button onClick={resetSort} color="success">
              취소
            </Button>
            <LoadingButton
              onClick={applySort}
              loading={loading}
              {...blackButtonContained}
            >
              저장
            </LoadingButton>
          </>
        )}
      </div>
    </div>
  );
}
