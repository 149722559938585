import { TypedDocumentNode, gql } from "@apollo/client";
import { PRODUCT_FIELDS } from "../fragments/product";
import { product } from "../types/product";

export const UPDATE_PRODUCT_BY_ID: TypedDocumentNode<{
  update_products_by_pk: product;
}> = gql`
  ${PRODUCT_FIELDS}
  mutation UPDATE_PRODUCT_BY_ID($id: Int!, $set: products_set_input!) {
    update_products_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ProductFields
    }
  }
`;

export const UPDATE_PRODUCTS_MANY = gql`
  ${PRODUCT_FIELDS}
  mutation UPDATE_PRODUCTS_MANY($updates: [products_updates!]!) {
    update_products_many(updates: $updates) {
      returning {
        ...ProductFields
      }
    }
  }
`;

export const ADD_PRODUCT = gql`
  ${PRODUCT_FIELDS}
  mutation ADD_PRODUCT($object: products_insert_input!) {
    insert_products_one(object: $object) {
      ...ProductFields
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DELETE_PRODUCT($id: Int!) {
    delete_products_by_pk(id: $id) {
      id
    }
  }
`;
