import { gql } from "@apollo/client";

export const OTHER_HISTORY_FIELDS = gql`
  fragment OtherHistoryFields on otherHistories {
    id
    title
    created_at
    desc
    link
    userId
    user {
      id
      name
      color
    }
    companyId
    company {
      id
      name
    }
  }
`;
