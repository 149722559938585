import { TypedDocumentNode, gql } from "@apollo/client";
import {
  INV_ACTION_FIELDS,
  INV_HISTORY_FIELDS,
  INV_QTY_EXTENDED_FIELDS,
  INV_QTY_FIELDS,
  INV_STATUS_FIELDS,
  INVENTORY_FIELDS,
  INVENTORY_POPULATED_FIELDS,
} from "../fragments/inv";
import {
  invAction,
  invHistory,
  invQty,
  invStatus,
  inventory,
  inventoryPopulatedInvQty,
  inventory_populated,
  inventory_with_qty,
} from "../types/inv";
import { PRODUCT_FIELDS } from "../fragments/product";
import { product } from "../types/product";

export const GET_PRODUCTS: TypedDocumentNode<{ products: product[] }> = gql`
  ${PRODUCT_FIELDS}
  query GET_PRODUCTS {
    products {
      ...ProductFields
    }
  }
`;

export const GET_INVENTORY_BY_ID: TypedDocumentNode<{
  inventory_by_pk: inventory_populated;
}> = gql`
  ${INVENTORY_POPULATED_FIELDS}
  query GET_INVENTORY_BY_ID($id: Int!) {
    inventory_by_pk(id: $id) {
      ...InventoryPopulatedFields
    }
  }
`;

export const GET_INVENTORY_CONDITIONAL: TypedDocumentNode<{
  inventory: inventory[];
}> = gql`
  ${INVENTORY_FIELDS}
  query GET_INVENTORY_CONDITIONAL($where: inventory_bool_exp) {
    inventory(where: $where) {
      ...InventoryFields
    }
  }
`;

export const STREAM_INVENTORY: TypedDocumentNode<{
  inventory_stream: inventory[];
}> = gql`
  ${INVENTORY_FIELDS}
  subscription STREAM_INVENTORY(
    $where: inventory_bool_exp
    $cursor: [inventory_stream_cursor_input]!
  ) {
    inventory_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...InventoryFields
    }
  }
`;

export const GET_INVENTORY_CONDITIONAL_WITH_QTY: TypedDocumentNode<{
  inventory: inventory_with_qty[];
}> = gql`
  ${INVENTORY_FIELDS}
  ${INV_QTY_FIELDS}
  ${INV_STATUS_FIELDS}
  query GET_INVENTORY_CONDITIONAL_WITH_QTY($where: inventory_bool_exp) {
    inventory(where: $where) {
      ...InventoryFields
      qty {
        ...InvQtyFields
        status {
          ...InvStatusFields
        }
      }
      company {
        name
      }
      user {
        name
      }
    }
  }
`;

export const ADD_INVENTORY = gql`
  ${INVENTORY_FIELDS}
  mutation ADD_INVENTORY($object: inventory_insert_input!) {
    insert_inventory_one(object: $object) {
      ...InventoryFields
    }
  }
`;

export const ADD_INVENTORY_MANY = gql`
  ${INVENTORY_FIELDS}
  mutation ADD_INVENTORY_MANY($objects: [inventory_insert_input!]!) {
    insert_inventory(objects: $objects) {
      returning {
        ...InventoryFields
      }
    }
  }
`;

export const GET_INV_QTY_BY_ID: TypedDocumentNode<{
  invQty_by_pk: invQty;
}> = gql`
  ${INV_QTY_FIELDS}
  query GET_INV_QTY_BY_ID($id: Int!) {
    invQty_by_pk(id: $id) {
      ...InvQtyFields
    }
  }
`;

export const GET_INV_QTY_CONDITIONAL: TypedDocumentNode<{
  invQty: invQty[];
}> = gql`
  ${INV_QTY_FIELDS}
  query GET_INV_QTY_CONDITIONAL($where: invQty_bool_exp) {
    invQty(where: $where) {
      ...InvQtyFields
    }
  }
`;

export const STREAM_INV_QTY: TypedDocumentNode<{
  invQty_stream: invQty[];
}> = gql`
  ${INV_QTY_FIELDS}
  subscription STREAM_INV_QTY(
    $where: invQty_bool_exp
    $cursor: [invQty_stream_cursor_input]!
  ) {
    invQty_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...InvQtyFields
    }
  }
`;

export const GET_POPULATED_INV_QTY_CONDITIONAL: TypedDocumentNode<{
  invQty: inventoryPopulatedInvQty[];
}> = gql`
  ${INV_QTY_EXTENDED_FIELDS}
  query GET_INV_QTY_CONDITIONAL($where: invQty_bool_exp) {
    invQty(where: $where) {
      ...InvQtyExtendedFields
    }
  }
`;

export const ADD_INV_QTY = gql`
  ${INV_QTY_FIELDS}
  mutation ADD_INV_QTY($object: invQty_insert_input!) {
    insert_invQty_one(object: $object) {
      ...InvQtyFields
    }
  }
`;

export const DELETE_INV_QTY = gql`
  mutation DELETE_INV_QTY($id: Int!) {
    delete_invQty_by_pk(id: $id) {
      id
    }
  }
`;

export const ADD_INV_QTY_MANY = gql`
  ${INV_QTY_FIELDS}
  mutation ADD_INV_QTY_MANY($objects: [invQty_insert_input!]!) {
    insert_invQty(objects: $objects) {
      returning {
        ...InvQtyFields
      }
    }
  }
`;

export const ADD_INV_QTY_MANY_M = gql`
  mutation ADD_INV_QTY_MANY_M($objects: [invQty_insert_input!]!) {
    insert_invQty(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UDPATE_INV_QTY_BY_ID: TypedDocumentNode<{
  update_invQty_by_pk: invQty;
}> = gql`
  ${INV_QTY_FIELDS}
  mutation UDPATE_INV_QTY_BY_ID($id: Int!, $set: invQty_set_input!) {
    update_invQty_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...InvQtyFields
    }
  }
`;

export const UPDATE_INV_QTY_MANY = gql`
  ${INV_QTY_FIELDS}
  mutation UPDATE_INV_QTY_MANY($updates: [invQty_updates!]!) {
    update_invQty_many(updates: $updates) {
      returning {
        ...InvQtyFields
      }
    }
  }
`;

export const GET_INV_HISTORY_BY_PK: TypedDocumentNode<{
  invHistories_by_pk: invHistory;
}> = gql`
  ${INV_HISTORY_FIELDS}
  query GET_INV_HISTORY_BY_PK($id: Int!) {
    invHistories_by_pk(id: $id) {
      ...InvHistoryFields
    }
  }
`;

export const GET_INV_HISTORY_CONDITIONAL: TypedDocumentNode<{
  invHistories: invHistory[];
  invHistories_aggregate: {
    aggregate: {
      count: number;
    };
  };
}> = gql`
  ${INV_HISTORY_FIELDS}
  query GET_INV_HISTORY_CONDITIONAL(
    $where: invHistories_bool_exp
    $limit: Int
    $order_by: [invHistories_order_by!]
    $offset: Int
  ) {
    invHistories(
      where: $where
      limit: $limit
      order_by: $order_by
      offset: $offset
    ) {
      ...InvHistoryFields
    }
  }
`;

export const STREAM_INV_HISTORY: TypedDocumentNode<{
  invHistories_stream: invHistory[];
}> = gql`
  ${INV_HISTORY_FIELDS}
  subscription STREAM_INV_HISTORY(
    $where: invHistories_bool_exp
    $cursor: [invHistories_stream_cursor_input]!
  ) {
    invHistories_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...InvHistoryFields
    }
  }
`;

export const ADD_INV_HISTORY = gql`
  ${INV_HISTORY_FIELDS}
  mutation ADD_INV_HISTORY($object: invHistories_insert_input!) {
    insert_invHistories_one(object: $object) {
      ...InvHistoryFields
    }
  }
`;

export const DELETE_INV_HISTORY = gql`
  mutation DELETE_INV_HISTORY($id: Int!) {
    delete_invHistories_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_INVENTORY_MANY = gql`
  ${INVENTORY_FIELDS}
  mutation UPDATE_INVENTORY_MANY($updates: [inventory_updates!]!) {
    update_inventory_many(updates: $updates) {
      returning {
        ...InventoryFields
      }
    }
  }
`;

export const UPDATE_INV_ACTION_BY_ID: TypedDocumentNode<{
  update_invActions_by_pk: invAction;
}> = gql`
  ${INV_ACTION_FIELDS}
  mutation UPDATE_INV_ACTION_BY_ID($id: Int!, $set: invActions_set_input!) {
    update_invActions_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...InvActionFields
    }
  }
`;

export const UPDATE_INV_ACTIONS_MANY = gql`
  ${INV_ACTION_FIELDS}
  mutation UPDATE_INV_ACTIONS_MANY($updates: [invActions_updates!]!) {
    update_invActions_many(updates: $updates) {
      returning {
        ...InvActionFields
      }
    }
  }
`;

export const ADD_INV_ACTION = gql`
  ${INV_ACTION_FIELDS}
  mutation ADD_INV_ACTION($object: invActions_insert_input!) {
    insert_invActions_one(object: $object) {
      ...InvActionFields
    }
  }
`;

export const DELETE_INV_ACTION = gql`
  mutation DELETE_INV_ACTION($id: Int!) {
    delete_invActions_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_INV_STATUS_BY_ID: TypedDocumentNode<{
  update_invStatuses_by_pk: invStatus;
}> = gql`
  ${INV_STATUS_FIELDS}
  mutation UPDATE_INV_STATUS_BY_ID($id: Int!, $set: invStatuses_set_input!) {
    update_invStatuses_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...InvStatusFields
    }
  }
`;

export const UPDATE_INV_STATUSES_MANY = gql`
  ${INV_STATUS_FIELDS}
  mutation UPDATE_INV_STATUSES_MANY($updates: [invStatuses_updates!]!) {
    update_invStatuses_many(updates: $updates) {
      returning {
        ...InvStatusFields
      }
    }
  }
`;

export const ADD_INV_STATUS = gql`
  ${INV_STATUS_FIELDS}
  mutation ADD_INV_STATUS($object: invStatuses_insert_input!) {
    insert_invStatuses_one(object: $object) {
      ...InvStatusFields
    }
  }
`;

export const DELETE_INV_STATUS = gql`
  mutation DELETE_INV_STATUS($id: Int!) {
    delete_invStatuses_by_pk(id: $id) {
      id
    }
  }
`;
