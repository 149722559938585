import { Fragment } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FlakyIcon from "@mui/icons-material/Flaky";
import FaceIcon from "@mui/icons-material/Face";
import { invAction } from "../../../../../types/inv";

export default function InvActionIcon({ type }: { type: invAction["type"] }) {
  return (
    <Fragment>
      {type == "add" && <AddCircleIcon />}
      {type == "move" && <LocalShippingIcon />}
      {type == "delete" && <RemoveCircleIcon />}
      {type == "update" && <FlakyIcon />}
      {type == "assign" && <FaceIcon />}
    </Fragment>
  );
}
