import { FormProvider, useForm } from "react-hook-form";
import { customer, extendedCustomer } from "../../../../../types/customer";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerTypeControlOnly } from "../../commonControls/customerTypeControl";
import TextField from "@mui/material/TextField";
import { CutomerAddressControlOnly } from "../../commonControls/customerAddressControl";
import { SourceControlOnly } from "../../commonControls/sourceControl";
import { SourceDetailControlConly } from "../../commonControls/sourceDetailControl";
import dayjs from "dayjs";
import { CustomerASDateControlOnly } from "./customerASDateControl";
import { CustomerWarrantyDateControlOnly } from "./customerWarrantyDateControl";
import { CustomerRegisteredAtControlOnly } from "./customerRegisteredAtControl";
import CustomerContacts from "../../commonControls/customerContacts";
import { contact } from "../../../../../types/contact";
import { CustomerRefereeControlOnly } from "../../commonControls/customerRefereeControl";
import { tag } from "../../../../../types/common";
import CustomerTagControl from "../../commonControls/customerTagControl";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@apollo/client";
import {
  ADD_CUSTOMER_TAG_MANY,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER_BY_ID,
} from "../../../../../gqls/customer";
import useAddCustomerHistory from "../../../../../hooks/useAddCustomerHistory";
import {
  ADD_CONTACT_MANY,
  UPDATE_CONTACT_BY_ID,
} from "../../../../../gqls/contact";
import hasuraFilter from "../../../../../utils/hasuraFilter";
import { CONTACT_CORE_FIELDS } from "../../../../../fragments/contact";
import { CUSTOMER_TAG_FIELDS } from "../../../../../fragments/customer";
import { useAuthStore, useCheckAuth } from "../../../../../store/authStore";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { cacheEvict } from "../../../../../utils/cacheMethods";
import { useNavigate } from "react-router-dom";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../../classPresets";
import { useState } from "react";
import Edit from "@mui/icons-material/Edit";
import FieldWrapper from "./fieldWrapper";
import useS3 from "../../../../../hooks/useS3";
import CustomerCreatedAtControlOnly from "./customerCreatedAtControl";

interface props {
  customer: extendedCustomer;
}

const schema = z
  .object({
    name: z.string().min(1, "고객 이름을 입력하세요"),
    address: z.string().min(1, "고객 주소를 입력하세요"),
    description: z.string().nullable().optional(),
    sourceId: z.coerce.number().min(1, "접수처를 선택하세요"),
    sourceDetailId: z.coerce.number().nullable().optional(),
    sourceCompanyId: z.coerce.number().nullable().optional(),
    registered_at: z.coerce.date().nullable().optional(),
    created_at: z.coerce.date(),
    warrantyDate: z.coerce.date().nullable().optional(),
    asDate: z.coerce.date().nullable().optional(),
    contacts: z.array(z.any()),
    newContacts: z.array(z.any()),
    tags: z.array(z.any()),
    refereeId: z.coerce.number().nullable().optional(),
    typeId: z.coerce.number().min(1, "고객 구분을 선택하세요"),
  })
  .refine(data => data.sourceDetailId || data.sourceCompanyId, {
    message: "세부 접수처를 선택하세요",
    path: ["sourceId"],
  })
  .refine(data => data.contacts.length + data.newContacts.length > 0, {
    message: "고객 연락처를 하나 이상 입력하세요",
    path: ["contacts"],
  });

export default function CustomerBasicInfo({ customer }: props) {
  const {
    year,
    number,
    contactIds,
    creator,
    referee,
    tagIds,
    type,
    name,
    address,
    postCode,
    source,
    sourceDetail,
    sourceCompany,
    registered_at,
    created_at,
    warrantyDate,
    asDate,
  } = customer;

  const fullNum = `#${year}-${number}`;

  const defaultValues = makeDefaultValues(customer);

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [editing, setEditing] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, touchedFields },
  } = methods;

  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER_BY_ID);
  const [updateContact] = useMutation(UPDATE_CONTACT_BY_ID);
  const { addHistory } = useAddCustomerHistory();

  const [addContacts] = useMutation(ADD_CONTACT_MANY);
  const [addTags] = useMutation(ADD_CUSTOMER_TAG_MANY);

  const { user } = useAuthStore();

  const onSubmit = handleSubmit(async data => {
    if (!user) {
      return;
    }

    const {
      name,
      address,
      postCode,
      asDate,
      warrantyDate,
      registered_at,
      contacts,
      tags,
      description,
      refereeId,
      sourceCompanyId,
      sourceDetailId,
      sourceId,
      typeId,
    } = data;

    // handle Contacts
    const contactIds: number[] = [];
    if (contacts) {
      contactIds.push(...contacts.map(c => c.id));
    }

    if (touchedFields.contacts) {
      for (const contact of contacts) {
        updateContact({
          variables: {
            id: contact.id,
            set: {
              name: contact.name,
              number: contact.number,
            },
          },
        });
      }
    }

    await (async () => {
      if (!data.newContacts) {
        return;
      }

      const newContacts = data.newContacts.filter(c => c.name.trim() !== "");

      if (newContacts.length == 0) {
        return;
      }

      await addContacts({
        variables: {
          objects: newContacts,
        },
        onCompleted(data) {
          let insertedContacts = (data.insert_contacts?.returning ||
            []) as contact[];

          if (data.insert_contacts_one) {
            insertedContacts.concat(data.insert_contacts_one);
          }

          contactIds.push(...insertedContacts.map(c => c.id));
        },
        onError(error) {
          console.log(error);
        },
        update(cache, { data }) {
          let insertedContacts = (data.insert_contacts?.returning ||
            []) as contact[];

          if (data.insert_contacts_one) {
            insertedContacts.concat(data.insert_contacts_one);
          }

          cache.modify({
            fields: {
              contacts(existing = [], { storeFieldName }) {
                const filtered = hasuraFilter({
                  items: insertedContacts,
                  name: "contacts",
                  string: storeFieldName,
                });

                const newContactsRefs = filtered.map(sd =>
                  cache.writeFragment({
                    data: sd,
                    fragment: CONTACT_CORE_FIELDS,
                    fragmentName: "ContactCoreFields",
                  })
                );
                return [...existing, ...newContactsRefs];
              },
            },
          });
        },
      });

      return;
    })();

    // handle Tags
    const tagIds: number[] = [];
    await (async () => {
      tagIds.push(...tags.filter(t => t.id).map(t => t.id as number));
      const newTags = tags.filter(t => !t.id);

      if (newTags.length < 1) {
        return;
      }

      await addTags({
        variables: {
          objects: newTags,
        },
        onCompleted(data) {
          let insertedTags = (data.insert_customerTags?.returning ||
            []) as tag[];

          if (data.insert_customerTags_one) {
            insertedTags.concat(data.insert_customerTags_one);
          }

          tagIds.push(...insertedTags.map(c => c.id));
        },
        onError(error) {
          console.log(error);
        },
        update(cache, { data }) {
          let insertedTags = (data.insert_customerTags?.returning ||
            []) as tag[];

          if (data.insert_customerTags_one) {
            insertedTags.concat(data.insert_customerTags_one);
          }

          cache.modify({
            fields: {
              contacts(existing = [], { storeFieldName }) {
                const filtered = hasuraFilter({
                  items: insertedTags,
                  name: "customerTags",
                  string: storeFieldName,
                });

                const newTagsRefs = filtered.map(sd =>
                  cache.writeFragment({
                    data: sd,
                    fragment: CUSTOMER_TAG_FIELDS,
                    fragmentName: "CustomerTagFields",
                  })
                );
                return [...existing, ...newTagsRefs];
              },
            },
          });
        },
      });
      return;
    })();

    const set: Partial<customer> = {
      name,
      address,
      postCode,
      description,
      refereeId,
      asDate: asDate ? asDate.toISOString() : null,
      warrantyDate: warrantyDate ? warrantyDate.toISOString() : null,
      registered_at: registered_at.toISOString(),
      sourceCompanyId: sourceCompanyId || null,
      sourceDetailId: sourceDetailId || null,
      sourceId: sourceId || null,
      contactIds,
      tagIds,
      reads: [user.id],
      typeId,
    };

    await updateCustomer({
      variables: {
        id: customer.id,
        set,
      },
      onCompleted(data) {
        const updatedCustomer = data.update_customers_by_pk;
        const values = makeDefaultValues(updatedCustomer);
        reset({ ...values, contacts, tags });
      },
    });

    let oldData: any = {};
    let newData: any = {};

    const touchedFieldsArr = Object.keys(touchedFields);

    for (const field of touchedFieldsArr) {
      if (field in customer) {
        oldData[field] = customer[field as keyof customer];
      }
      if (field in set) {
        newData[field] = set[field as keyof customer];
      }
    }

    const isContactSame =
      contactIds.length === customer.contactIds.length &&
      customer.contactIds.every(id => contactIds.includes(id));
    if (!isContactSame) {
      oldData.contactIds = customer.contactIds;
      newData.contactIds = contactIds;
    }

    const isTagSame =
      tagIds.length === customer.tagIds.length &&
      customer.tagIds.every(id => tagIds.includes(id));
    if (!isTagSame) {
      oldData.tagIds = customer.tagIds;
      newData.tagIds = tagIds;
    }

    await addHistory({
      customerId: customer.id,
      message: "기본정보 변경",
      type: "detail",
      oldData,
      newData,
    });

    setEditing(false);

    return;
  });

  const checkAuth = useCheckAuth();
  const canEdit = checkAuth({ permissionName: "고객_기본정보수정" });

  const canDelete = checkAuth({
    permissionName: "고객_삭제",
    companyIds: [customer.companyId],
    userId: customer.createdById,
  });

  const navigate = useNavigate();

  const [remove] = useMutation(DELETE_CUSTOMER);

  const { deleteDirectory } = useS3();

  const deleteCustomer = async () => {
    const proceed = confirm("고객을 삭제하시겠습니까?");
    if (!proceed) {
      return;
    }

    await updateCustomer({
      variables: {
        id: customer.id,
        set: {
          deleted: true,
        },
      },
    });

    remove({
      variables: {
        id: customer.id,
      },
      update(cache) {
        cacheEvict({
          cache,
          id: customer.id,
          __typename: "customers",
        });
      },
      onCompleted() {
        navigate("/customers");
        deleteDirectory(`customers/${customer.id}`);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="flex-1 flex flex-col gap-4 md:mt-6">
        {/* Fields */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left */}
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2">
              {/* Number */}
              <FieldWrapper title="고객 번호" content={fullNum} striped />
              {/* Type */}
              <FieldWrapper
                title="고객 구분"
                content={type.name}
                editing={editing}
              >
                <CustomerTypeControlOnly />
              </FieldWrapper>
              {/* Name */}
              <FieldWrapper
                title="고객 이름"
                content={name}
                editing={editing}
                colSpan={2}
                striped
              >
                <TextField
                  margin="none"
                  size="small"
                  color="success"
                  placeholder="고객 이름"
                  disabled={!canEdit}
                  error={
                    touchedFields.name && errors.name?.message ? true : false
                  }
                  className="shadow-md"
                  {...register("name")}
                />
              </FieldWrapper>
              {/* Address */}
              <FieldWrapper
                title="주소"
                content={`${address}${postCode ? ` (${postCode})` : ""}`}
                editing={editing}
                colSpan={2}
              >
                <CutomerAddressControlOnly disabled={!canEdit} />
              </FieldWrapper>
              {/* Source */}
              <FieldWrapper
                title="접수처"
                content={source?.name || ""}
                editing={editing}
                striped
              >
                <SourceControlOnly disabled={!canEdit} />
              </FieldWrapper>
              {/* SourceDetailControl */}
              <FieldWrapper
                title="세부접수처"
                content={sourceDetail?.name || sourceCompany?.name || ""}
                editing={editing}
              >
                <SourceDetailControlConly disabled={!canEdit} />
              </FieldWrapper>
              <FieldWrapper
                title="접수원"
                colSpan={2}
                content={creator?.name || ""}
                striped
              />
              {/* Created At */}
              <FieldWrapper
                title="최초 접수 날짜"
                editing={editing}
                content={dayjs(created_at).format("YYYY-MM-DD")}
              >
                <CustomerCreatedAtControlOnly disabled={!canEdit} />
              </FieldWrapper>
              {/* Registered At */}
              <FieldWrapper
                title="접수 날짜"
                editing={editing}
                content={dayjs(registered_at).format("YYYY-MM-DD")}
                striped
              >
                <CustomerRegisteredAtControlOnly disabled={!canEdit} />
              </FieldWrapper>

              {/* AsDate */}
              <FieldWrapper
                title="AS 날짜"
                editing={editing}
                content={asDate ? dayjs(asDate).format("YYYY-MM-DD") : "미상"}
              >
                <CustomerASDateControlOnly disabled={!canEdit} />
              </FieldWrapper>
              {/* WarrantyDate */}
              <FieldWrapper
                title="무상 A/S 기간"
                striped
                editing={editing}
                content={
                  warrantyDate
                    ? dayjs(warrantyDate).format("YYYY-MM-DD")
                    : "미상"
                }
              >
                <CustomerWarrantyDateControlOnly disabled={!canEdit} />
              </FieldWrapper>
            </div>
          </div>
          {/* Right */}
          <div className="flex flex-col gap-4">
            {/* <CustomerContacts /> */}
            <div className="px-3 md:px-0">
              <CustomerContacts
                contactIds={contactIds}
                disabled={!canEdit}
                editing={editing}
              />
            </div>
            {/* <CustomerRefereeControl /> */}
            <FieldWrapper
              title="추천인"
              editing={editing}
              content={referee ? referee.name : "없음"}
              colSpan={2}
              striped
            >
              <CustomerRefereeControlOnly disabled={!canEdit} />
            </FieldWrapper>
            {/* Description */}
            <div className="flex flex-col gap-1 px-3 md:px-0">
              <label className="font-semibold">비고</label>
              <TextField
                margin="none"
                size="small"
                color="success"
                multiline
                minRows={3}
                className="shadow-md"
                disabled={!canEdit || !editing}
                {...register("description")}
              />
            </div>
            {/* <CustomerTagControl /> */}
            <div className="px-3 md:px-0">
              <CustomerTagControl tagIds={tagIds} disabled={!canEdit} />
            </div>
          </div>
        </div>
        {/* Actions */}
        <div className="flex flex-row justify-end gap-4 items-center px-3 md:px-0">
          {canDelete && (
            <DeleteForever
              className="cursor-pointer text-red-500"
              onClick={deleteCustomer}
            />
          )}
          {editing ? (
            <>
              <Button
                {...blackButtonOutlined}
                onClick={() => {
                  reset();
                  setEditing(false);
                }}
              >
                취소
              </Button>
              <LoadingButton
                loading={loading}
                disabled={!isDirty}
                {...blackButtonContained}
                type="submit"
              >
                수정
              </LoadingButton>
            </>
          ) : (
            <Button
              startIcon={<Edit />}
              color="success"
              onClick={() => setEditing(true)}
            >
              수정
            </Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

const makeDefaultValues = (customer: customer) => {
  const {
    name,
    type,
    description,
    address,
    postCode,
    sourceId,
    sourceDetailId,
    sourceCompanyId,
    registered_at,
    companyId,
    asDate,
    warrantyDate,
    installDate,
    refereeId,
    tagIds,
    created_at,
  } = customer;

  const defaultValues = {
    typeId: type.id,
    name,
    address,
    companyId,
    postCode,
    description,
    sourceId,
    sourceDetailId,
    sourceCompanyId,
    warrantyDate: warrantyDate ? dayjs(warrantyDate) : null,
    asDate: asDate ? dayjs(asDate) : null,
    installDate: installDate ? dayjs(installDate) : null,
    registered_at: dayjs(registered_at),
    created_at: dayjs(created_at),
    contacts: [] as contact[],
    newContacts: [] as Omit<contact, "id">[],
    tagIds,
    tags: [] as tag[],
    refereeId,
  };

  return defaultValues;
};
