import { FormProvider, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation } from "@apollo/client";
import {
  DELETE_CORRESPONDENCE,
  UPDATE_CORRESPONDENCE_BY_ID,
} from "../../../../gqls/correspondence";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CorrespondenceControls from "./correspondenceControls";
import { correspondence } from "../../../../types/correspondence";
import { cacheEvict } from "../../../../utils/cacheMethods";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../classPresets";

interface props {
  correspondence: correspondence;
}

const schema = z.object({
  content: z.string().min(1, "내역을 입력하세요"),
  byId: z.coerce.number(),
  date: z.coerce.date(),
});

export default function EditCorrespondence({ correspondence }: props) {
  const methods = useForm({
    defaultValues: {
      ...correspondence,
      date: dayjs(correspondence.date),
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const [updateCorrespondence, { loading }] = useMutation(
    UPDATE_CORRESPONDENCE_BY_ID
  );

  const onSubmit = handleSubmit(data => {
    updateCorrespondence({
      variables: {
        id: correspondence.id,
        set: {
          ...data,
          date: data.date.toISOString(),
        },
      },
      onCompleted() {
        reset({ ...data, date: dayjs(data.date) });
      },
    });
  });

  const [removeCorrespondence, { loading: deleting }] = useMutation(
    DELETE_CORRESPONDENCE
  );

  const deleteCorrespondence = () => {
    const proceed = confirm("내역을 지우시겠습니까?");
    if (!proceed) {
      return;
    }
    removeCorrespondence({
      variables: {
        id: correspondence.id,
      },
      update(cache) {
        cacheEvict({
          __typename: "correspondences",
          cache,
          id: correspondence.id,
        });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="w-full flex flex-col gap-4">
        <CorrespondenceControls taskId={correspondence.taskId} />
        <div className="flex flex-row justify-end gap-4">
          <LoadingButton
            loading={deleting}
            variant="outlined"
            color="error"
            size="small"
            onClick={deleteCorrespondence}
          >
            삭제
          </LoadingButton>
          {isDirty && (
            <>
              <Button
                size="small"
                {...blackButtonOutlined}
                onClick={() => {
                  reset();
                }}
              >
                취소
              </Button>
              <LoadingButton
                size="small"
                {...blackButtonContained}
                type="submit"
              >
                수정
              </LoadingButton>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
