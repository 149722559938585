import { biztalk } from "../types/biztalk";

export default function getBiztalkVariables(biztalk: biztalk) {
  const { title, message, buttons, quickReplies } = biztalk;

  const consolidatedString = `
  ${title || ""}
  ${message}
  ${
    buttons?.map(
      button =>
        `${button.url_mobile || ""} ${button.url_pc || ""} ${
          button.scheme_android || ""
        } ${button.scheme_ios || ""} `
    ) || ""
  }
  ${
    quickReplies?.map(
      qr =>
        `${qr.url_mobile || ""} ${qr.url_pc || ""} ${qr.scheme_android || ""} ${
          qr.scheme_ios || ""
        } `
    ) || ""
  }
  `;

  const variables = [
    ...new Set(
      [...consolidatedString.matchAll(/#\{[^}]*\}/g)].map(v =>
        v[0].replaceAll(/#|\{|\}/g, "")
      )
    ),
  ];

  return variables;
}
