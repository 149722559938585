import { invAction, invHistoryWithQty, invStatus } from "../../../../types/inv";
import { useState } from "react";
import InvActionIcon from "../../settings/inventories/invActinos/invActionIcon";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useCheckAuth } from "../../../../store/authStore";
import { modal } from "../../../../classPresets";
import LoadingButton from "@mui/lab/LoadingButton";
import Modal from "@mui/material/Modal";
import { useAddAlert } from "../../../../store/alertStore";
import PersonIcon from "@mui/icons-material/Person";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import Tooltip from "@mui/material/Tooltip";
import useCancelHistoryChanges from "../../../../hooks/useCancelHistoryChanges";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

interface props {
  history: invHistoryWithQty;
  invAction: invAction;
  invStatuses: invStatus[];
  invId: number;
}

export default function InvHistory({
  history,
  invAction,
  invId,
  invStatuses,
}: props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const addAlert = useAddAlert();

  const cancelHistoryChanges = useCancelHistoryChanges();

  const rollback = async () => {
    if (history.invActionId == 12) {
      addAlert({
        message: "상태 삭제로 인한 내역은 되돌릴 수 없습니다",
        type: "warning",
      });
      return;
    }
    const proceed = confirm("해당 내역을 되돌리시겠습니까?");
    if (!proceed) {
      return;
    }
    setLoading(true);
    // Undone qty changes on current inventory
    await cancelHistoryChanges(history.id);
  };

  return (
    <>
      <History
        history={history}
        invAction={invAction}
        invStatuses={invStatuses}
        invId={invId}
        setOpen={setOpen}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div
          className={`${modal} flex flex-col gap-2 outline-none p-4 w-[512px] max-w-[90vw]`}
        >
          <h3>히스토리</h3>
          <History
            history={history}
            invAction={invAction}
            invId={invId}
            invStatuses={invStatuses}
          />

          <div className="flex flex-row justify-end">
            <LoadingButton color="error" loading={loading} onClick={rollback}>
              되돌리기
            </LoadingButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

interface historyViewProps extends Omit<props, "inventory"> {
  setOpen?: (open: boolean) => void;
}

const History = ({
  history,
  invAction,
  invStatuses,
  setOpen,
  invId,
}: historyViewProps) => {
  const checkAuth = useCheckAuth();
  const canRollBack = checkAuth({
    permissionName: "재고내역복구",
    companyIds: [history.fromId || 0, history.toId || 0],
  });
  const open = () => {
    canRollBack && setOpen && setOpen(true);
  };

  const { changes, created_at, toId, to, fromId, from, description, by } =
    history;

  const myChanges = changes.filter(c => c.invId == invId);

  const diff = myChanges[0].diff;

  const position = fromId == toId ? "self" : fromId == invId ? "from" : "to";
  const otherParty = position == "from" ? to : from;

  return (
    <div className="p-1 flex flex-col gap-[6px]">
      {/* Top */}
      <div className="flex flex-row justify-between items-center flex-wrap">
        <div className="flex flex-row items-center gap-1 flex-1">
          <div
            style={{
              color: invAction.color,
            }}
          >
            <InvActionIcon type={invAction.type} />
          </div>

          <div className="text-sm">
            {invAction.name}{" "}
            {myChanges.length < 2 ? (diff < 0 ? "-" : "+") : "±"}{" "}
            {Math.abs(diff)}
          </div>
        </div>

        <div
          className={`text-sm ${
            setOpen && canRollBack ? "cursor-pointer hover:text-quezone" : ""
          }`}
          onClick={open}
        >
          {dayjs(created_at).format("YY. MM. DD")}{" "}
          <span className="text-quezone">|</span>{" "}
          {dayjs(created_at).format("hh:mm")}
        </div>
      </div>
      {/* Bottom */}
      <div className="flex flex-row justify-between items-center flex-wrap gap-2">
        {/* Changes */}
        <div className="flex flex-row flex-wrap gap-2 items-center">
          {myChanges.map(change => {
            const status = invStatuses.find(is => is.id == change.statusId);

            const matchingQtyBefore =
              history.qty.find(q => q.statusId == change.statusId)?.qty || 0;

            if (!status) {
              return null;
            }

            return (
              <div
                key={change.statusId}
                className="py-1 px-2 text-white text-xs font-light rounded-sm shadow-sm"
                style={{
                  backgroundColor: status.color,
                }}
              >
                {status.name} {matchingQtyBefore} {"->"}{" "}
                {matchingQtyBefore + change.diff}
              </div>
            );
          })}
        </div>
        {position !== "self" && (
          <div className="flex-1 flex flex-row gap-2 text-sm">
            <div>{position == "to" ? "<=" : "=>"}</div>
            <Link
              to={`/inventory/detail/${otherParty?.id}`}
              className="hover:text-quezone"
              target={target}
            >
              {otherParty?.customer?.name ||
                otherParty?.company?.name ||
                otherParty?.user?.name}
            </Link>
          </div>
        )}
        {/* Description */}
        <div className="text-sm flex flex-row items-center gap-1">
          {description}
          <Tooltip
            title={by ? by.name : "자동화로 인한 변경"}
            enterTouchDelay={0}
            arrow
          >
            {by ? (
              <PersonIcon
                className="text-gray-500 hover:text-quezone"
                sx={{ width: 20, height: 20 }}
              />
            ) : (
              <SmartToyIcon
                className="text-gray-500 hover:text-quezone"
                sx={{ width: 20, height: 20, marginBottom: "1px" }}
              />
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
