import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { User } from "../../../../types/user";
import TextField from "@mui/material/TextField";
import useGetRefereeUsers from "../../../../hooks/useGetRefereeUsers";

export function CustomerRefereeControlOnly({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { refereeUsers } = useGetRefereeUsers();

  const { control } = useFormContext();

  const options = [
    {
      label: "추천인이 있으면 선택해주세요",
      name: "추천인이 있으면 선택해주세요",
      id: 0,
    },
    ...refereeUsers.map(u => ({ ...u, label: u.name })),
  ];

  return (
    <Controller
      name="refereeId"
      control={control}
      render={({ field }) => (
        <Autocomplete
          disablePortal
          size="small"
          className="shadow-md flex-1"
          color="success"
          value={field.value || 0}
          onChange={(_: any, newValue: User) => {
            field.onChange(newValue?.id || 0);
          }}
          isOptionEqualToValue={(option, value) =>
            Number(option?.id) === Number(value)
          }
          onBlur={field.onBlur}
          ref={field.ref}
          disabled={field.disabled}
          options={options}
          noOptionsText={`해당하는 개인을 찾을 수 없습니다`}
          getOptionLabel={option => {
            return (
              option?.name ||
              options.find(o => o?.id == option)?.name ||
              "추천인이 있으면 선택해주세요"
            );
          }}
          renderInput={params => (
            <TextField
              {...params}
              color="success"
              margin="none"
              disabled={disabled}
            />
          )}
        />
      )}
    />
  );
}

export default function CustomerRefereeControl({
  disabled,
}: {
  disabled?: boolean;
}) {
  return (
    <div className="flex flex-col gap-1">
      <label>추천인</label>
      <CustomerRefereeControlOnly disabled={disabled} />
    </div>
  );
}
