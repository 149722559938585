import { useCallback } from "react";
import { useAuthStore } from "../store/authStore";
import { useCustomerStore } from "../store/customerStore";
import { useAddAlert } from "../store/alertStore";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_BY_ID } from "../gqls/customer";
import { customer } from "../types/customer";
import useAddCustomerHistory from "./useAddCustomerHistory";

interface props {
  customer: customer;
  newTypeId: number;
}
export default function useCustomerTypeUpdate() {
  const { types } = useCustomerStore();
  const { user } = useAuthStore();

  const addAlert = useAddAlert();

  const alertNoAuth = () => {
    addAlert({
      message: "구분 변경을 위한 권한이 없습니다",
      type: "warning",
    });
  };

  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER_BY_ID);

  const { addHistory } = useAddCustomerHistory();

  const updateFunc = ({ customer, newTypeId }: props) => {
    if (!user) {
      alertNoAuth();
      return;
    }

    if (user.auth.name !== "admin") {
      if (!user.company?.type.canHaveCustomers) {
        const companyTypePermissions = user.company?.customerTypeIds;
        if (
          !companyTypePermissions ||
          !companyTypePermissions.includes(newTypeId)
        ) {
          alertNoAuth();
          return;
        }
      }
    }

    const set = {
      typeId: newTypeId,
      reads: [user.id],
    };

    updateCustomer({
      variables: {
        id: customer.id,
        set,
      },
      optimisticResponse: {
        update_customers_by_pk: {
          ...customer,
          ...set,
          type: types?.find(sl => sl.id == newTypeId) || customer.type,
        },
      },
    });

    addHistory({
      customerId: customer.id,
      message: "구분 변경",
      type: "detail",
      oldData: {
        typeId: customer.typeId,
      },
      newData: {
        typeId: newTypeId,
      },
    });
  };

  return { updateType: useCallback(updateFunc, [user, types]), loading };
}
