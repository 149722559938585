import { useQuery } from "@apollo/client";
import { GET_TASK_TAGS } from "../../../../../gqls/task";
import NewTag from "./newTag";
import TaskTag from "./taskTag";
import { sort } from "fast-sort";

export default function TaskTags() {
  const { data } = useQuery(GET_TASK_TAGS);
  const tags = sort(data?.taskTags || []).asc("name");

  return (
    <div className="flex flex-col gap-2">
      <h1 className="md:mb-4">작업 태그 목록</h1>
      <div className="flex flex-row gap-2 flex-wrap">
        {tags.map(tag => (
          <TaskTag tag={tag} key={tag.id} />
        ))}
      </div>
      <NewTag />
    </div>
  );
}
