import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { action, biztalkVar, trigger } from "../../../../../types/automation";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "@apollo/client";
import { GET_BIZTALKS } from "../../../../../gqls/biztalk";
import getBiztalkVariables from "../../../../../utils/getBiztalkVariables";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import BiztalkRow from "../../biztalks/biztalkRow";
import TextField from "@mui/material/TextField";

export default function AutomationBiztalkControl() {
  const { control, getValues, register } = useFormContext();

  const trigger: trigger = useWatch({
    name: "trigger",
    control,
  });

  const action: action = useWatch({
    name: "action",
    control,
  });

  const biztalkId = useWatch({
    name: "biztalkId",
    control,
  });

  const { data } = useQuery(GET_BIZTALKS);

  const biztalks = data?.biztalks;

  const selectedBiztalk = biztalks?.find(biztalk => biztalk.id === biztalkId);

  const requiredVars = selectedBiztalk && getBiztalkVariables(selectedBiztalk);

  const { fields, replace } = useFieldArray({
    control,
    name: "biztalkVars",
  });

  useEffect(() => {
    const existingVars: biztalkVar[] | null = getValues("biztalkVars");
    if (
      requiredVars &&
      requiredVars.some(v => !existingVars?.find(e => e.name === v))
    ) {
      replace(requiredVars.map(v => ({ name: v, value: "" })));
    }
  }, [requiredVars]);

  const availableValues = [
    "고객번호",
    "고객이름",
    "고객상태",
    "고객주소",
    "고객고유번호",
    // "고객전화번호",
    // "고객이메일",
    // "담당자이름",
    // "담당자번호",
    "고객접수날짜",
    "고객설치날짜",
    "고객AS날짜",
    "고객보증날짜",
    "담당사이름",
  ];

  if (trigger.includes("작업")) {
    availableValues.push(
      "설치기사이름",
      "설치기사번호",
      "작업제안날짜",
      "작업상태"
    );
  }

  if (action !== "비즈톡 전송" || !biztalks) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <label>발송할 비즈톡</label>
        <Controller
          name="biztalkId"
          control={control}
          render={({ field }) => (
            <Select
              margin="none"
              size="small"
              required
              className="shadow-md"
              color="success"
              // defaultValue={0}
              value={field.value || 0}
              onChange={field.onChange}
              onBlur={field.onBlur}
              name={field.name}
              ref={field.ref}
              disabled={field.disabled}
            >
              <MenuItem value={0}>발송할 비즈톡을 선택해주세요</MenuItem>
              {biztalks.map(biztalk => (
                <MenuItem value={biztalk.id} key={biztalk.id}>
                  {biztalk.tmpltCode}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </div>
      {selectedBiztalk && (
        <>
          <div className="flex flex-col gap-1">
            <div className="font-light">
              * 조건 발동일로부터 비즈톡 발송 지연 기간을 일단위로 설정합니다.
              (발송 시간은:9시로 동일)
            </div>
            <TextField
              className="shadow-md"
              autoComplete="off"
              color="success"
              type="number"
              size="small"
              margin="none"
              defaultValue={0}
              placeholder="바로"
              {...register("date")}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label>비즈톡 미리보기</label>
            <BiztalkRow biztalk={selectedBiztalk} displayOnly={true} />
          </div>
          <div className="flex flex-col gap-4">
            <label>비즈톡 변수 설정</label>
            <div className="flex flex-row gap-2 gap-y-4 flex-wrap">
              {fields.map((biztalkVar, index) => (
                <FormControl
                  key={biztalkVar.id}
                  margin="none"
                  sx={{ minWidth: 200 }}
                  size="small"
                >
                  <InputLabel sx={{ fontWeight: 500 }} color="success">
                    {
                      // @ts-expect-error
                      biztalkVar.name
                    }
                  </InputLabel>
                  <Controller
                    name={`biztalkVars.${index}.value`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        margin="none"
                        className="shadow-md"
                        color="success"
                        // @ts-expect-error
                        label={biztalkVar.name}
                        defaultValue={0}
                        value={field.value || 0}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        name={field.name}
                        ref={field.ref}
                        disabled={field.disabled}
                      >
                        <MenuItem value={0}>변수를 지정하세요</MenuItem>
                        {availableValues.map(value => (
                          <MenuItem value={value} key={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
