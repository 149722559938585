import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { company } from "../../../../../types/company";
import { useMutation } from "@apollo/client";
import { DELETE_COMPANY } from "../../../../../gqls/company";
import { useNavigate } from "react-router-dom";
import { cacheEvict } from "../../../../../utils/cacheMethods";

interface props {
  company: company;
}

export default function companyDeleteControl({ company }: props) {
  const [deleting, setDeleting] = useState(false);
  const [nameCheck, setNameCheck] = useState("");

  const [remove, { loading }] = useMutation(DELETE_COMPANY);

  const navigate = useNavigate();

  const _delete = () => {
    remove({
      variables: {
        id: company.id,
      },
      onCompleted() {
        setDeleting(false);
        navigate("/settings/company");
      },
      update(cache) {
        cacheEvict({ cache, id: company.id, __typename: "companies" });
      },
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteForever />}
        onClick={() => {
          setDeleting(true);
        }}
      >
        삭제
      </Button>

      <Dialog
        open={deleting}
        onClose={() => {
          setDeleting(false);
        }}
      >
        <DialogTitle>회사 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            회사를 삭제할 경우 데이터의 무결성이 하향될 수 있습니다. 정말 회사를
            삭제하시려면 아래 회사의 이름을 입력해주세요.
          </DialogContentText>
          <TextField
            className="shadow-md col-span-1"
            size="small"
            margin="dense"
            autoComplete="off"
            type="text"
            color="success"
            placeholder={company.name}
            value={nameCheck}
            onChange={e => {
              setNameCheck(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setDeleting(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={company.name !== nameCheck}
            loading={loading}
            variant="contained"
            color="error"
            onClick={_delete}
          >
            삭제
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
