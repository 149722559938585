import { useQuery } from "@apollo/client";
import { GET_COMPANY_TYPES } from "../../../../../gqls/company";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useFormContext } from "react-hook-form";

export default function CompanyType() {
  const { data } = useQuery(GET_COMPANY_TYPES);
  const types = data?.companyTypes;

  const { register } = useFormContext();

  return (
    <FormControl margin="dense">
      <InputLabel id="company" sx={{ fontWeight: 500 }}>
        구분
      </InputLabel>
      <Select
        required
        className="shadow-md"
        labelId="companyType"
        id="companyType"
        label="회사"
        color="success"
        defaultValue={""}
        {...register("typeId")}
      >
        {types &&
          types.map(type => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
