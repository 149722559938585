import Paper from "@mui/material/Paper";
import { User } from "../../../../../types/user";
import { useForm, useWatch } from "react-hook-form";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisuallyHiddenInput from "../../../../../comps/hiddenInput";
import Avatar from "@mui/material/Avatar";
import { ChangeEvent, LegacyRef, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_BY_ID } from "../../../../../gqls/user";
import { useNavigate } from "react-router-dom";
import useUserAvatar from "../../../../../hooks/useUserAvatar";
import useS3 from "../../../../../hooks/useS3";

interface props {
  user: User;
}

export default function UserAvatarControl({ user }: props) {
  const { name, color } = user;

  const {
    setValue,
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      avatar: null as null | File | string,
      bio: user.bio,
    },
  });

  const avatar = useWatch({
    name: "avatar",
    control,
  });

  const { avatar: exAvatar, getAvatar } = useUserAvatar(user.id);

  const avatarURL =
    avatar instanceof File && URL.createObjectURL(avatar as File);

  useEffect(() => {
    reset({ avatar: exAvatar, bio: user.bio });
  }, [exAvatar]);

  const ref: LegacyRef<HTMLInputElement> | undefined | null = useRef(null);

  const resize = (file: File) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        256,
        256,
        "PNG",
        80,
        0,
        uri => {
          resolve(uri);
        },
        "file"
      );
    });

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    const converted = await resize(file);
    setValue("avatar", converted as File, { shouldDirty: true });
  };

  const [update] = useMutation(UPDATE_USER_BY_ID);

  const [loading, setLoading] = useState(false);

  const { uploadFile, deleteObject } = useS3();

  const onSubmit = handleSubmit(data => {
    setLoading(true);
    const { bio, avatar } = data;
    update({
      variables: {
        id: user.id,
        set: {
          bio,
        },
      },
      onError(error) {
        console.log(error);
        setLoading(false);
      },
      onCompleted: async data => {
        const updatedUser = data.update_users_by_pk;
        const { bio } = updatedUser;

        await (async () => {
          if (avatar instanceof File || avatar == null) {
            await deleteObject(`users/${user.id}.png`);
            if (avatar instanceof File) {
              await uploadFile(avatar, `users/${user.id}.png`, "image/png");
              return;
            }
            return;
          } else {
            return;
          }
        })();

        getAvatar();
        reset({
          avatar,
          bio,
        });
        setLoading(false);
      },
    });
  });

  const navigate = useNavigate();

  const goToCompany = () => {
    if (!user.company?.id) {
      return;
    }
    navigate(`/settings/company/detail/${user.company?.id}`);
  };

  return (
    <div className="flex flex-col">
      <Paper className="border-x-[1px] md:border-none p-4">
        <div className="flex flex-col items-center relative">
          {avatar && (
            <div className="absolute flex flex-row justify-end w-full -mt-2 h-8">
              {avatar && (
                <div
                  className="flex flex-row items-end cursor-pointer"
                  onClick={() => {
                    setValue("avatar", null, { shouldDirty: true });
                  }}
                >
                  <DeleteForeverIcon color="error" />
                </div>
              )}
            </div>
          )}
          <Avatar
            sx={{ width: 164, height: 164, bgcolor: color }}
            alt={name}
            // @ts-expect-error
            src={avatarURL || avatar}
            className="cursor-pointer hover:ring-1 ring-quezone"
            onClick={() => {
              ref?.current?.click();
            }}
          >
            <div className="flex flex-col items-center text-base">
              <CloudUploadIcon sx={{ width: 48, height: 48 }} />
              사진 등록
            </div>
          </Avatar>
          <VisuallyHiddenInput
            type="file"
            ref={ref}
            onChange={onChange}
            multiple={false}
            accept="image/*;capture=camera"
          />

          <div className="flex flex-row items-end mt-2">
            <h1>{name}</h1> /
            <div
              onClick={goToCompany}
              className={`${
                user.company && "cursor-pointer hover:text-quezone"
              }`}
            >
              {user.company?.name || "무소속"}
            </div>
          </div>
          <TextField
            sx={{ marginTop: 1 }}
            fullWidth
            label="Bio/소개"
            multiline
            rows={2}
            color="success"
            {...register("bio")}
          />
          <div className="flex flex-row w-full justify-end mt-3 text-sm">
            가입한 날짜: {dayjs(user.created_at).format("YYYY/MM/DD")}
          </div>
        </div>
      </Paper>
      {isDirty && (
        <div className="col-span-full flex flex-row justify-end gap-2 items-center mt-4">
          {/* Actions */}
          <Button
            color="success"
            onClick={() => {
              reset();
            }}
          >
            취소
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="success"
            sx={{
              backgroundColor: "black",
              fontWeight: 500,
            }}
            onClick={onSubmit}
          >
            수정
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
