import { useQuery } from "@apollo/client";
import { extendedCustomer } from "../../../../../../types/customer";
import {
  GET_CUSTOMER_PAYMENT_CONDITIONAL,
  GET_CUSTOMER_PRICE_CONDITIONAL,
} from "../../../../../../gqls/customer";
import CustomerBalance from "./customerBalance";
import CustomerPriceBreakDown from "./customerPriceBreakDown";
import CustomerPayments from "./customerPayments";
import { useCheckAuth } from "../../../../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface props {
  customer: extendedCustomer;
}
export default function CustomerAccount({ customer }: props) {
  const { data } = useQuery(GET_CUSTOMER_PRICE_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: customer.id,
        },
      },
    },
  });

  const { data: paymentsData } = useQuery(GET_CUSTOMER_PAYMENT_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: customer.id,
        },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const price = data?.customerPrices && data?.customerPrices[0];
  const payments = paymentsData?.customerPayments || [];

  const checkAuth = useCheckAuth();
  const navigate = useNavigate();

  const companyIds = [customer?.companyId || 0, customer.sourceCompanyId || 0];

  useEffect(() => {
    if (
      !checkAuth({
        permissionName: "고객_입금정보열람",
        companyIds,
      })
    ) {
      navigate(-1);
    }
  }, []);

  return (
    <div className="flex-1 grid grid-cols-1 lg:grid-cols-7 gap-y-4 gap-x-8 md:gap-x-16 xl:gap-x-24 pb-4 md:mt-6">
      {/* Left */}
      {checkAuth({ permissionName: "고객_가격정보열람" }) && (
        <div className="col-span-1 lg:col-span-4 flex flex-col gap-8 px-3 md:px-0">
          <CustomerBalance
            companyIds={companyIds}
            payments={payments}
            customerId={customer.id}
            price={price}
          />
          <CustomerPriceBreakDown price={price} customerId={customer.id} />
        </div>
      )}
      {/* Right */}
      {checkAuth({ permissionName: "고객_거래내역열람" }) && (
        <div className="col-span-1 lg:col-span-3 flex flex-col">
          <CustomerPayments payments={payments} companyIds={companyIds} />
        </div>
      )}
    </div>
  );
}
