import { Controller, useFormContext, useWatch } from "react-hook-form";
import { action } from "../../../../../types/automation";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useInvStore } from "../../../../../store/invStore";

export default function AutomationInvControl() {
  const { control } = useFormContext();

  const action: action = useWatch({
    name: "action",
    control,
  });

  let { invActions } = useInvStore();

  invActions = invActions.filter(invAction =>
    action == "재고 변경"
      ? invAction.type == "update"
      : invAction.type == "delete"
  );

  if (!["재고 변경", "재고 소진"].includes(action)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <label>재고 액션</label>
        <Controller
          name="invActionId"
          control={control}
          render={({ field }) => (
            <Select
              margin="none"
              size="small"
              required
              className="shadow-md"
              color="success"
              // defaultValue={0}
              value={field.value || 0}
              onChange={field.onChange}
              onBlur={field.onBlur}
              name={field.name}
              ref={field.ref}
              disabled={field.disabled}
            >
              <MenuItem value={0}>실행할 재고액션을 선택해주세요</MenuItem>
              {invActions.map(invAction => (
                <MenuItem key={invAction.id} value={invAction.id}>
                  {invAction.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </div>
      {/* {action == "재고 변경" && (
        <div className="flex flex-col gap-1">
          <label>이동 위치</label>
          <Controller
            name="invTo"
            control={control}
            render={({ field }) => (
              <Select
                margin="none"
                size="small"
                required
                className="shadow-md"
                color="success"
                // defaultValue={0}
                value={field.value || 0}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                ref={field.ref}
                disabled={field.disabled}
              >
                <MenuItem value={0}>재고 이동위치를 선택해주세요</MenuItem>
                <MenuItem value="설치점">설치점</MenuItem>
                <MenuItem value="설치기사">설치기사</MenuItem>
              </Select>
            )}
          />
        </div>
      )} */}
    </div>
  );
}
