import { zodResolver } from "@hookform/resolvers/zod";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { product } from "../../../../../types/product";
import ProductPhotoControl from "./productPhotoControl";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@apollo/client";
import { ADD_PRODUCT, UPDATE_PRODUCT_BY_ID } from "../../../../../gqls/product";
import { useProductStore } from "../../../../../store/productStore";
import { PRODUCT_FIELDS } from "../../../../../fragments/product";
import { useNavigate } from "react-router-dom";
import ProductDeleteControl from "./productDeleteControl";
import ReplyIcon from "@mui/icons-material/Reply";
import { blackButtonContained } from "../../../../../classPresets";
import ProductActivationControl from "./productActivationControl";

const schema = z.object({
  name: z.string().min(1, { message: "이름을 입력해주세요" }),
  brand: z.string().min(1, { message: "브랜드를 입력해주세요" }),
  description: z.string().nullable().optional(),
  price: z.coerce.number(),
  serial: z.string().min(1, { message: "일련번호를 입력해주세요" }),
  photo: z.string().nullable().optional(),
});

interface props {
  product?: product;
}

export default function ProductControl({ product }: props) {
  const defaultValues = {
    name: product?.name || "",
    brand: product?.brand || null,
    description: product?.description || "",
    price: product?.price || 0,
    serial: product?.serial || "",
    photo: product?.photo || "",
    active: product ? product.active : true,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, errors, touchedFields },
  } = methods;

  const { addProduct, updateProducts } = useProductStore();

  const [insert, { loading: inserting }] = useMutation(ADD_PRODUCT);

  const navigate = useNavigate();

  const add = (data: {
    name: string;
    brand: string | null;
    description: string;
    price: number;
    serial: string;
    photo: string;
  }) => {
    insert({
      variables: {
        object: data,
      },
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        addProduct(data.insert_products_one);
        reset();
        navigate("/settings/inventory");
      },
      update(cache, { data: { insert_products_one: newProduct } }) {
        cache.modify({
          fields: {
            products(existing = []) {
              const newProductRef = cache.writeFragment({
                data: newProduct,
                fragment: PRODUCT_FIELDS,
              });
              return [...existing, newProductRef];
            },
          },
        });
      },
    });
  };

  const [update, { loading: updating }] = useMutation(UPDATE_PRODUCT_BY_ID);

  const modify = (data: {
    name: string;
    brand: string | null;
    description: string;
    price: number;
    serial: string;
    photo: string;
  }) => {
    if (!product) {
      return;
    }

    update({
      variables: {
        id: product.id,
        set: data,
      },
      onCompleted(data) {
        updateProducts([data.update_products_by_pk]);
        reset();
        navigate("/settings/inventory");
      },
    });
  };

  const onSubmit = handleSubmit(data => {
    if (product) {
      modify(data);
    } else {
      add(data);
    }
  });

  const cancel = () => {
    reset();
    navigate("/settings/inventory");
  };

  const loading = inserting || updating;

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="flex flex-col gap-2 py-4 md:p-8">
        <div className="flex flex-row justify-between items-center">
          <h1 className="md:mb-4">제품 {product ? "수정" : "등록"}</h1>
          <div
            className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
            onClick={() => {
              navigate("/settings/inventory");
            }}
          >
            <ReplyIcon />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Photo */}
          <ProductPhotoControl />
          {/* Details */}
          <div className="col-span-1 grid grid-cols-1 md:grid-cols-2 gap-2 content-start">
            {/* Name */}
            <div className="flex flex-col gap-1 col-span-1">
              <label>이름</label>
              <TextField
                fullWidth
                size="small"
                margin="none"
                color="success"
                {...register("name")}
                placeholder="이름을 입력하세요"
                error={errors.name && touchedFields.name}
              />
              <FormHelperText error={errors.name && touchedFields.name}>
                {touchedFields.name && errors.name?.message}
              </FormHelperText>
            </div>
            {/* Brand */}
            <div className="flex flex-col gap-1 col-span-1">
              <label>브랜드</label>
              <TextField
                fullWidth
                size="small"
                margin="none"
                color="success"
                {...register("brand")}
                placeholder="브랜드를 입력하세요"
                error={errors.brand && touchedFields.brand}
              />
              <FormHelperText error={errors.brand && touchedFields.brand}>
                {touchedFields.brand && errors.brand?.message}
              </FormHelperText>
            </div>
            {/* Serial */}
            <div className="flex flex-col gap-1 col-span-1">
              <label>일련번호</label>
              <TextField
                fullWidth
                size="small"
                margin="none"
                color="success"
                {...register("serial")}
                placeholder="일련번호를 입력하세요"
                error={errors.serial && touchedFields.serial}
              />
              <FormHelperText error={errors.serial && touchedFields.serial}>
                {touchedFields.serial && errors.serial?.message}
              </FormHelperText>
            </div>
            {/* Price */}
            <div className="flex flex-col gap-1 col-span-1">
              <label>가격</label>
              <TextField
                fullWidth
                size="small"
                margin="none"
                color="success"
                {...register("price")}
                placeholder="각격을 입력하세요"
                error={errors.price && touchedFields.price}
              />
              <FormHelperText error={errors.price && touchedFields.price}>
                {touchedFields.price && errors.price?.message}
              </FormHelperText>
            </div>
            {/* Description */}
            <div className="flex flex-col gap-1 col-span-1 md:col-span-2">
              <label>설명</label>
              <TextField
                fullWidth
                size="small"
                margin="none"
                color="success"
                {...register("description")}
                placeholder="설명을 입력하세요"
                error={errors.description && touchedFields.description}
              />
              <FormHelperText
                error={errors.description && touchedFields.description}
              >
                {touchedFields.description && errors.description?.message}
              </FormHelperText>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-end gap-2">
          {product && (
            <>
              <ProductActivationControl product={product} />
              <ProductDeleteControl product={product} />
            </>
          )}
          {isDirty && (
            <>
              <Button onClick={cancel} color="success">
                취소
              </Button>
              <LoadingButton
                {...blackButtonContained}
                type="submit"
                loading={loading}
              >
                {product ? "수정" : "등록"}
              </LoadingButton>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
