import { FormProvider, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import CompanyType from "./companyType";
import { zodResolver } from "@hookform/resolvers/zod";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useMutation } from "@apollo/client";
import { ADD_COMPANY } from "../../../../../gqls/company";
import { COMPANY_FIELDS } from "../../../../../fragments/company";
import { basicCompanySchema } from "../../../../../types/company";
import ReplyIcon from "@mui/icons-material/Reply";

export default function NewCompany() {
  const defaultValues = {
    name: "",
    description: null,
    email: null,
    phone: null,
    address: null,
    registration: null,
    typeId: "",
    noUsers: false,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(basicCompanySchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = methods;

  const [insert, { loading }] = useMutation(ADD_COMPANY);

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/settings/company");
  };

  const onSubmit = handleSubmit(data => {
    insert({
      variables: {
        object: data,
      },
      onCompleted(data) {
        const company = data.insert_companies_one;
        if (company?.id) {
          reset();
          navigate(`/settings/company/detail/${company.id}`);
        }
      },
      update(cache, { data: { insert_companies_one: newCompany } }) {
        cache.modify({
          fields: {
            companies(existing = []) {
              const newCompanyRef = cache.writeFragment({
                data: newCompany,
                fragment: COMPANY_FIELDS,
                fragmentName: "CompanyFields",
              });
              return [...existing, newCompanyRef];
            },
          },
        });
      },
    });
  });

  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-xl md:text-2xl">새 회사 등록</h1>
        <div
          className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
          onClick={goBack}
        >
          <ReplyIcon />
        </div>
      </div>

      <FormProvider {...methods}>
        <form
          onSubmit={onSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-4"
        >
          {/* type */}
          <CompanyType />
          {/* name */}
          <FormControl margin="dense">
            <TextField
              error={errors.name && touchedFields.name}
              className="shadow-md col-span-1"
              required
              id="companyName"
              label="회사 이름"
              autoComplete="off"
              autoFocus
              type="text"
              color="success"
              placeholder="회사 이름"
              {...register("name")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText error={errors.name && touchedFields.name}>
              {touchedFields.name && errors.name?.message}
            </FormHelperText>
          </FormControl>
          {/* description */}
          <FormControl margin="dense">
            <TextField
              error={errors.description && touchedFields.description}
              className="shadow-md col-span-1"
              id="companyDesc"
              label="설명"
              autoComplete="off"
              type="text"
              color="success"
              placeholder="설명"
              {...register("description")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText
              error={errors.description && touchedFields.description}
            >
              {touchedFields.description && errors.description?.message}
            </FormHelperText>
          </FormControl>
          {/* email */}
          <FormControl margin="dense">
            <TextField
              error={errors.email && touchedFields.email}
              className="shadow-md col-span-1"
              id="companyEmail"
              label="이메일"
              autoComplete="off"
              type="email"
              color="success"
              placeholder="이메일"
              {...register("email")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText error={errors.email && touchedFields.email}>
              {touchedFields.email && errors.email?.message}
            </FormHelperText>
          </FormControl>
          {/* phone */}
          <FormControl margin="dense">
            <TextField
              error={errors.phone && touchedFields.phone}
              className="shadow-md col-span-1"
              id="companyPhone"
              label="연락처"
              autoComplete="off"
              type="text"
              color="success"
              placeholder="연락처"
              {...register("phone")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText error={errors.phone && touchedFields.phone}>
              {touchedFields.phone && errors.phone?.message}
            </FormHelperText>
          </FormControl>
          {/* address */}
          <FormControl margin="dense">
            <TextField
              error={errors.address && touchedFields.address}
              className="shadow-md col-span-1"
              id="companyAddress"
              label="주소"
              autoComplete="off"
              type="text"
              color="success"
              placeholder="주소"
              {...register("address")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText error={errors.address && touchedFields.address}>
              {touchedFields.address && errors.address?.message}
            </FormHelperText>
          </FormControl>
          {/* registration */}
          <FormControl margin="dense">
            <TextField
              error={errors.registration && touchedFields.registration}
              className="shadow-md col-span-1"
              id="companyABN"
              label="사업자 등록 번호"
              autoComplete="off"
              type="text"
              color="success"
              placeholder="사업자 등록 번호"
              {...register("registration")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText
              error={errors.registration && touchedFields.registration}
            >
              {touchedFields.registration && errors.registration?.message}
            </FormHelperText>
          </FormControl>
          <div className="col-span-1 flex flex-row justify-end items-center">
            <FormControlLabel
              control={<Checkbox color="success" {...register("noUsers")} />}
              label="사용자 가입 불가"
            />
          </div>

          <div className="col-span-full flex flex-row justify-end gap-2 items-center mt-4">
            {/* Actions */}
            <Button color="success" onClick={goBack}>
              취소
            </Button>
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              color="success"
              sx={{
                backgroundColor: "black",
                fontWeight: 500,
              }}
            >
              등록
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
