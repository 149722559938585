import dayjs from "dayjs";
import { correspondence } from "../../../../types/correspondence";
import { useCheckAuth } from "../../../../store/authStore";
import Edit from "@mui/icons-material/Edit";
import { useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import EditCorrespondence from "./editCorrespondence";

interface props {
  correspondence: correspondence;
}

export default function Correspondence({ correspondence }: props) {
  const { date, content, user, byId } = correspondence;

  const checkAuth = useCheckAuth();
  const canEdit = checkAuth({
    permissionName: "내역수정",
    userId: byId,
  });

  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [editing, setEditing] = useState(false);

  const handleClose = () => {
    setEditing(false);
  };

  return (
    <>
      <div className="flex flex-col" ref={anchorRef}>
        <div
          onClick={() => {
            canEdit && setEditing(true);
          }}
          className={`md:bg-lightGreen p-1 flex flex-row justify-between items-center text-sm px-3 md:px-0 ${
            canEdit && "hover:brightness-90 cursor-pointer"
          }`}
        >
          <div>{dayjs(date).format("YYYY. MM. DD")}</div>
          <div className="font-light flex flex-row items-center gap-1">
            {user.name}{" "}
            {canEdit && (
              <div className="md:hidden text-gray-500">
                <Edit sx={{ width: 16, height: 16, paddingBottom: "1px" }} />
              </div>
            )}
          </div>
        </div>
        <div className="p-1 text-sm font-light whitespace-pre-wrap pb-2 md:pb-1 border-b-[1px] md:border-none px-3 md:px-0">
          {content}
        </div>
      </div>
      <Popover
        open={editing}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: 1 }}
        className="shadow-md"
      >
        <div className="p-4">
          <EditCorrespondence correspondence={correspondence} />
        </div>
      </Popover>
    </>
  );
}
