import { company } from "../../../../../../types/company";
import { TypedDocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import { COMPANY_FIELDS } from "../../../../../../fragments/company";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useEventListener } from "usehooks-ts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Chip from "@mui/material/Chip";
import {
  ADD_SOURCE,
  ADD_SOURCE_DETAIL_MANY,
} from "../../../../../../gqls/source";
import {
  SOURCE_DETAIL_FIELDS,
  SOURCE_FIELDS,
} from "../../../../../../fragments/source";
import { sourceDetailExtended } from "../../../../../../types/source";

interface props {
  company: company;
}

const GET_CHAIN_COMPANIES: TypedDocumentNode<{ companies: company[] }> = gql`
  ${COMPANY_FIELDS}
  query GET_CHAIN_COMPANIES($ids: [Int!]!) {
    companies(where: { id: { _in: $ids }, type: { isChain: { _eq: true } } }) {
      ...CompanyFields
    }
  }
`;

export default function NewSource({ company }: props) {
  const { data: chainCompanyData } = useQuery(GET_CHAIN_COMPANIES, {
    variables: {
      ids: company.subCompanies,
    },
  });

  const availableChains = chainCompanyData?.companies || [];

  const [adding, setAdding] = useState(false);

  const defaultValues = {
    isSourceCompany: false,
    companyId: company.id,
    name: "",
    sourceDetails: [] as { name?: string; companyId?: number }[],
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
    control,
  } = useForm({ defaultValues });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sourceDetails",
  });

  const sourceDetails = useWatch({
    control,
    name: "sourceDetails",
  });

  const [addSource, { loading: addingSource }] = useMutation(ADD_SOURCE);
  const [addSourceDetails, { loading: addingSourceDetail }] = useMutation(
    ADD_SOURCE_DETAIL_MANY
  );

  const loading = addingSource || addingSourceDetail;

  const onSubmit = handleSubmit(async data => {
    const { isSourceCompany, companyId, name, sourceDetails } = data;

    addSource({
      variables: {
        object: {
          isSourceCompany,
          companyId,
          name,
        },
      },
      update(cache, { data: { insert_sources_one: newSource } }) {
        cache.modify({
          fields: {
            sources(existing = []) {
              const newSourceRef = cache.writeFragment({
                data: newSource,
                fragment: SOURCE_FIELDS,
                fragmentName: "SourceFields",
              });
              return [...existing, newSourceRef];
            },
          },
        });
      },
      onCompleted(data) {
        const sourceId = data.insert_sources_one?.id;

        const sourceDetailsToInsert = sourceDetails.map(s => ({
          sourceId,
          name: isSourceCompany ? null : s.name,
          companyId: isSourceCompany ? Number(s.companyId) : null,
        }));

        addSourceDetails({
          variables: {
            objects: sourceDetailsToInsert,
          },
          onCompleted() {
            setAdding(false);
            reset();
          },
          update(cache, { data }) {
            let insertedSoureDetails = (data.insert_sourceDetails?.returning ||
              []) as sourceDetailExtended[];

            if (data.insert_sourceDetails_one) {
              insertedSoureDetails.concat(data.insert_sourceDetails_one);
            }

            cache.modify({
              fields: {
                sourceDetails(existing = [], { storeFieldName }) {
                  if (!storeFieldName.includes(sourceId.toString())) {
                    return existing;
                  }
                  const newSourceDetailRefs = insertedSoureDetails.map(sd =>
                    cache.writeFragment({
                      data: sd,
                      fragment: SOURCE_DETAIL_FIELDS,
                      fragmentName: "SourceDetailFields",
                    })
                  );

                  return [...existing, ...newSourceDetailRefs];
                },
              },
            });
          },
        });
      },
    });
  });

  const isSourceCompany = useWatch({
    control,
    name: "isSourceCompany",
  });

  const [sourceDetail, setSourceDetail] = useState("");

  const appendSourceDetail = () => {
    isSourceCompany
      ? append({ companyId: Number(sourceDetail) })
      : append({ name: sourceDetail });
    setSourceDetail("");
  };

  useEventListener("keydown", e => {
    if (e.key === "Enter") {
      e.preventDefault();
      appendSourceDetail();
    }
  });

  const doesContain = (id: number) => {
    return sourceDetails.find(s => s.companyId == id);
  };

  const toggle = (id: number) => {
    if (doesContain(id)) {
      setValue(
        "sourceDetails",
        sourceDetails.filter(s => s.companyId != id)
      );
    } else {
      setValue("sourceDetails", sourceDetails.concat({ companyId: id }));
    }
  };

  return (
    <>
      <div className="flex flex-row justify-end">
        <Button color="success" onClick={() => setAdding(true)}>
          접수처 추가
        </Button>
      </div>
      <Dialog
        open={adding}
        onClose={() => {
          setAdding(false);
        }}
      >
        <DialogTitle>접수처 그룹 추가</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <label className="text-black">접수처 그룹 타입</label>
            <Controller
              control={control}
              name="isSourceCompany"
              render={({ field }) => (
                <ToggleButtonGroup
                  size="small"
                  value={field.value.toString()}
                  onChange={(_, val) => {
                    if (val == null) {
                      return;
                    }
                    field.onChange(val == "true");
                    setValue("sourceDetails", []);
                  }}
                  exclusive
                >
                  <ToggleButton value="false" className="w-28">
                    기타
                  </ToggleButton>
                  <ToggleButton value="true" className="w-28">
                    회사 (대리점)
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
            <label className="text-black">이름 *</label>
            <TextField
              className="shadow-sm"
              margin="none"
              size="small"
              required
              id="id"
              autoComplete="off"
              type="text"
              color="success"
              placeholder="접수처 이름"
              {...register("name")}
            />
            <label className="text-black mt-1">세부 접수처</label>
            {isSourceCompany ? (
              <div className="flex flex-row gap-2 flex-wrap max-w-md">
                {availableChains.map(chain => (
                  <Chip
                    key={chain.id}
                    variant={doesContain(chain.id) ? "filled" : "outlined"}
                    color={doesContain(chain.id) ? "success" : "default"}
                    onClick={() => toggle(chain.id)}
                    label={chain.name}
                  />
                ))}
              </div>
            ) : (
              <>
                <div className="flex flex-row gap-2 items-center">
                  <TextField
                    className="shadow-sm"
                    margin="none"
                    size="small"
                    id="id"
                    autoComplete="off"
                    type="text"
                    color="success"
                    placeholder="새 세부 접수처"
                    value={sourceDetail}
                    onChange={e => {
                      setSourceDetail(e.target.value);
                    }}
                  />
                  <AddCircleIcon
                    className="cursor-pointer"
                    color="success"
                    onClick={appendSourceDetail}
                  />
                </div>
                <div className="flex flex-row gap-2 flex-wrap max-w-md mt-1">
                  {fields.map((field, index) => (
                    <Chip
                      key={field.id}
                      variant="outlined"
                      color="success"
                      onDelete={() => remove(index)}
                      label={field.name}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setAdding(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={onSubmit}
          >
            추가
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
