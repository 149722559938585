import { extendedCustomerHistory } from "../../../../types/customer";
import { useCustomerStore } from "../../../../store/customerStore";
import MyBadge from "../../../../comps/myBadge";
import DetailUpdates from "../../customers/customerDetail/customerProgress/customerHistory/detailUpdates";
import { NavLink } from "react-router-dom";
import Popover from "@mui/material/Popover";
import BiztalkRow from "../../settings/biztalks/biztalkRow";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
dayjs.extend(relativeTime);
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

interface props {
  customerHistory: extendedCustomerHistory;
}

export default function CustomerLog({ customerHistory }: props) {
  const {
    message,
    customer,
    type,
    oldData,
    link,
    status,
    customerId,
    biztalk,
    created_at,
    task,
  } = customerHistory;

  const { statuses } = useCustomerStore();

  const { name, year, number } = customer;

  const fullNum = `#${year}-${number}`;

  const detail = () => {
    if (type == "status") {
      const oldStatusId = oldData?.statusId;
      const newStatus = status;
      const oldStatus = statuses.find(s => s.id == oldStatusId);

      return (
        <div className="flex flex-row gap-2 items-center">
          {oldStatus ? (
            <MyBadge color={oldStatus.color} text={oldStatus.name} fill />
          ) : (
            ""
          )}{" "}
          {"=>"}
          {newStatus ? (
            <MyBadge color={newStatus.color} text={newStatus.name} fill />
          ) : (
            ""
          )}
        </div>
      );
    }

    if (type == "detail") {
      return <DetailUpdates history={customerHistory} />;
    }

    if (type == "task") {
      const installer = task?.assignedTo?.name;
      return <div>기사: {installer}</div>;
    }

    return "변경 정보를 찾을 수 없습니다";
  };

  const to = link || `/customers/detail/${customerId}/progress`;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <NavLink to={to} target={target} onClick={handlePopoverClose}>
      <div
        className="flex flex-col gap-1 bg-white p-2 rounded-sm ring-1 ring-gray-300 hover:text-quezone relative"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div className="flex flex-row gap-1 items-center">
          {status && (
            <div
              className="rounded-full p-1"
              style={{ backgroundColor: status.color }}
            />
          )}
          <div className="font-medium break-keep">
            고객 {message} {status && ` [${status.name}]`}
            {biztalk && ` [${biztalk.tmpltCode}]`}
          </div>
        </div>
        <div className="font-light">
          {fullNum} {name}
        </div>

        <div className="text-xs absolute right-full mr-2 top-3 text-gray-500 text-right min-w-max">
          {Math.abs(dayjs(created_at).diff(dayjs(), "minutes")) < 40
            ? dayjs(created_at).fromNow()
            : dayjs(created_at).format("HH:mm")}
        </div>
        {type !== "registration" && (
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            className="shadow-md mt-1"
          >
            {biztalk && <BiztalkRow biztalk={biztalk} displayOnly />}
            {type !== "biztalk" && (
              <div className="p-2 flex flex-col text-sm">{detail()}</div>
            )}
          </Popover>
        )}
      </div>
    </NavLink>
  );
}
