import dayjs from "dayjs";
import { useTaskStore } from "../../../../../../store/taskStore";
import { task } from "../../../../../../types/task";
import "dayjs/locale/ko";
import AgendaItem from "./agendaItem";
import { sort } from "fast-sort";
dayjs.locale("ko");

interface props {
  tasks: task[];
}

export default function Agenda({ tasks }: props) {
  const { selectedDate } = useTaskStore();
  const selectedTasks = sort(
    tasks.filter(task => dayjs(task.date).isSame(selectedDate, "date"))
  ).asc("time");

  const title = dayjs(selectedDate).locale("ko").format("MM월 DD일 dddd");

  return (
    <div
      className={`flex-1 flex flex-col pt-4 px-1 overflow-y-auto max-h-[100dvh] scrollbar-none pb-40 lg:px-0 lg:pb-0 lg:border-r-[1px] border-black lg:pr-4 lg:ml-10`}
    >
      <h2 className="text-lg lg:text-xl">{title}</h2>
      <div className="flex flex-col gap-4 relative">
        <div className="w-full h-0" />
        {selectedTasks.length == 0 && "해당 날짜에 일정이 없습니다"}
        {selectedTasks.map(task => (
          <AgendaItem key={task.id} task={task} />
        ))}

        {selectedTasks.length !== 0 && (
          <div className="border-l-2 h-full absolute left-3 border-gray-300" />
        )}
      </div>
    </div>
  );
}
