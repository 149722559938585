import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useState } from "react";
import { invAction } from "../../../../../types/inv";
import EditInvAction from "./editInvAction";
import DeleteInvAction from "./deleteInvAction";

import { useInvStore } from "../../../../../store/invStore";
import InvActionIcon from "./invActionIcon";

interface props {
  invAction: invAction;
  isOverlay: boolean;
}

export default function InvActionRow({ invAction, isOverlay }: props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: invAction.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };
  const { color, type, name, description, statusId, fromStatIds } = invAction;

  const { invStatuses } = useInvStore();

  const [mode, setMode] = useState<null | "editing" | "deleting">(null);
  return (
    <>
      <div
        className={`flex flex-row gap-2 px-4 py-3 rounded-md shadow-md items-center group bg-white ${
          isOverlay ? "text-quezone" : ""
        }`}
        ref={setNodeRef}
        style={style}
      >
        <div
          className="flex flex-row items-center gap-2 flex-1 hover:text-quezone cursor-pointer"
          onClick={() => {
            setMode("editing");
          }}
        >
          <div
            style={{
              color: color,
            }}
            className="mr-2"
          >
            <InvActionIcon type={type} />
          </div>
          <h2>{name}</h2> <div className="hidden md:block">- {description}</div>
        </div>
        <div className="flex flex-row gap-2 items-center flex-1">
          {fromStatIds && fromStatIds.length > 0 && (
            <div className="flex flex-row gap-1 items-center">
              [
              {fromStatIds
                .map(fromStatId => {
                  const stat = invStatuses.find(stat => stat.id == fromStatId);

                  return stat?.name;
                })
                .join(", ")}
              ]{statusId && fromStatIds?.length > 0 && " =>"}
            </div>
          )}
          {statusId && (
            <div>{invStatuses.find(stat => stat.id == statusId)?.name}</div>
          )}
        </div>
        <div
          className="md:text-transparent md:group-hover:text-black"
          {...attributes}
          {...listeners}
        >
          <DragIndicatorIcon className="cursor-pointer" />
        </div>
      </div>
      {mode == "editing" && (
        <EditInvAction setMode={setMode} invAction={invAction} />
      )}
      {mode == "deleting" && (
        <DeleteInvAction setMode={setMode} invAction={invAction} />
      )}
    </>
  );
}
