import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_RELATED_CONFIGS } from "../../../../gqls/customer";
import { mongoCustomer } from "../types";
import { GET_CONTACTS } from "../../../../gqls/contact";
import { sort } from "fast-sort";
import { invQty, inventory } from "../../../../types/inv";
import { useInvStore } from "../../../../store/invStore";
import { historyToUploadProps } from "../../../../hooks/useAddCustomerHistory";
import { correspondence } from "../../../../types/correspondence";

export default function useCustomerConvert() {
  const { data, loading } = useQuery(GET_CUSTOMER_RELATED_CONFIGS, {
    onError(error) {
      console.log(error);
    },
  });

  const users = data?.users;
  const sources = data?.sources;
  const sourceDetails = data?.sourceDetails;

  const { data: contactsData } = useQuery(GET_CONTACTS);
  const allContacts = contactsData?.contacts;

  const { invStatuses } = useInvStore();

  const convert = (customer: mongoCustomer) => {
    if (loading) {
      return;
    }

    try {
      const {
        address,
        companyId,
        contacts,
        created,
        createdBy,
        name,
        number,
        read,
        source,
        sourceDetail,
        statusId,
        tags,
        typeId,
        year,
        asDate,
        description,
        due,
        from,
        warranty,
        history,
        _id,
      } = customer;

      const contactIds = contacts
        .map(c => {
          const loadedContact = allContacts?.find(
            lc => lc.name == c.name && lc.number == c.number
          );

          return loadedContact?.id || 0;
        })
        .filter(id => id !== 0);

      const lastHistory = sort(history).desc("date")[0];

      const matchingSource = sources?.find(
        s => s.companyId == companyId && s.name == source
      );
      const matchingSourceDetail = sourceDetails?.find(
        s =>
          s.sourceId == matchingSource?.id &&
          (s.name == sourceDetail || s.company?.name == sourceDetail)
      );

      const customerToUpload = {
        year,
        number,
        name,
        uuid: _id.$oid,
        address,
        description,
        companyId,
        sourceId: matchingSource?.id,
        sourceDetailId: matchingSourceDetail?.id,
        sourceCompanyId: matchingSource?.isSourceCompany
          ? matchingSourceDetail?.companyId
          : null,
        created_at: created.$date,
        updated_at: lastHistory?.date?.$date || created.$date,
        stat_updated_at: lastHistory?.date?.$date || created.$date,
        installDate: due ? due.$date : null,
        registered_at: from ? from.$date : created.$date,
        asDate: asDate ? asDate.$date : null,
        warrantyDate: warranty ? warranty.$date : null,
        statusId,
        typeId,
        createdById: users?.find(u => u.email == createdBy)?.id,
        reads: JSON.stringify(
          read
            .map(r => users?.find(u => u.email == r)?.id)
            .filter(r => r !== undefined)
        ),
        tagIds: JSON.stringify(tags.map(t => t.id)),
        contactIds: JSON.stringify(contactIds),
      };
      return customerToUpload;
    } catch (error) {
      console.log(customer, error);

      return null;
    }
  };

  const convertInventory = (
    customer: mongoCustomer,
    exCustomers: { id: number; uuid: string; name: string }[]
  ) => {
    const { products, _id } = customer;
    const uuid = _id.$oid;

    const exCustomer = exCustomers.find(c => c.uuid == uuid);

    if (!exCustomer) {
      return null;
    }

    const customerId = exCustomer.id;

    const inventoriesToUpload: Omit<
      inventory,
      "id" | "created_at" | "updated_at"
    >[] = (
      products?.map(p => ({
        productId: p.productId,
        customerId,
      })) || []
    ).filter(p => p.productId);

    return inventoriesToUpload;
  };

  const convertRest = (
    customer: mongoCustomer,
    exCustomers: { id: number; uuid: string; name: string }[],
    inventory: inventory[]
  ) => {
    const { products, _id, history, correspondence, value } = customer;
    const uuid = _id.$oid;

    const exCustomer = exCustomers.find(c => c.uuid == uuid);

    if (!exCustomer) {
      return null;
    }

    const customerId = exCustomer.id;

    const insertedInventories = inventory.filter(
      i => i.customerId == customerId
    );

    const invQty: Omit<invQty, "id">[] =
      insertedInventories?.map(inv => {
        const product = products?.find(p => p.productId == inv.productId);
        const qty = {
          invId: inv.id,
          qty: product?.qty || 1,
          created_at: product?.added.$date,
          updated_at: product?.added.$date,
          statusId:
            (product?.used
              ? invStatuses?.find(s => s.name == "설치완료")?.id
              : invStatuses?.find(s => s.name == "설치대기")?.id) || 7,
        };
        return qty;
      }) || [];

    const sortedHistory = sort(history).asc("date");

    const historiesToUpload: historyToUploadProps[] = sortedHistory.map(
      (history, index) => ({
        customerId,
        type: index == 0 ? "registration" : "status",
        message: index == 0 ? "등록" : "상태 변경",
        statusId: history.statusId,
        created_at: history.date.$date,
        oldData:
          index == 0
            ? undefined
            : {
                statusId: sortedHistory[index - 1].statusId,
              },
        newData: {
          statusId: history.statusId,
        },
      })
    );

    // upload correspondence
    const correspondences: Omit<
      correspondence,
      "id" | "created_at" | "updated_at" | "user"
    >[] = correspondence.map(c => ({
      byId: users?.find(u => u.email == c.email)?.id || 0,
      content: c.description,
      date: c.date.$date,
      customerId,
    }));

    const { install, product, received } = value;

    // upload customer Price
    const price = {
      customerId,
      installGst: install.gst,
      installSupply: install.supply,
      productGst: product.gst,
      productSupply: product.supply,
    };

    const paymentsToUpoad = received.map(r => ({
      amount: r.sum,
      methodId: r.type == "카드" ? 1 : 2,
      received_at: r.date.$date,
      comment: r.description,
      customerId,
    }));

    return {
      invQty,
      history: historiesToUpload,
      correspondence: correspondences,
      price,
      payment: paymentsToUpoad,
    };
  };

  return { convert, convertInventory, convertRest };
}
