import dayjs from "dayjs";
import { task } from "../../../../types/task";
import ListKanbanItem from "../../tasks/tasksMain/views/listKanbanItem/listKanbanItem";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

interface props {
  taskGroups: {
    date: string;
    tasks: task[];
  }[];
}

export default function UpcomingPc({ taskGroups }: props) {
  return (
    <div className="flex-col gap-2 hidden lg:flex">
      {taskGroups.map(group => (
        <div key={group.date} className="flex flex-col gap-2">
          {/* Date */}
          <div className="flex flex-row gap-2">
            <div className="font-medium">
              {dayjs(group.date).format("MM월 DD일")}
            </div>
            <div className="overflow-clip flex-1 w-0 truncate tracking-[.20em] text-gray-500">
              ---------------------------------------------------------------------------------------------------------------------
            </div>
          </div>
          {/* Tasks */}
          {group.tasks.map(task => (
            <NavLink
              key={task.id}
              to={`/tasks/detail/${task.id}`}
              target={target}
            >
              <ListKanbanItem task={task} />
            </NavLink>
          ))}
        </div>
      ))}
    </div>
  );
}
