import { useLocation, useNavigate } from "react-router-dom";
import { menu } from "../../../../store/navStore";
import Grow from "@mui/material/Grow";

interface props {
  menu: menu;
  index: number;
  open: boolean;
  setOpen: (bool: boolean) => void;
}

export default function Menu({ menu, index, open, setOpen }: props) {
  const navigate = useNavigate();

  const go = () => {
    navigate(`/${menu.path}`);
    setOpen(false);
  };

  const location = useLocation();
  const pathname = location.pathname.split("/");
  const isCurrent =
    pathname[1] == menu.path ||
    (menu.path == "dashboard" && location.pathname == "/");

  return (
    <Grow in={open} timeout={index * 100}>
      <div
        className={`flex flex-row w-full items-center px-4 py-3 gap-2 ${
          isCurrent ? "text-quezone" : "text-gray-500"
        } active:text-quezone`}
        onClick={go}
      >
        {isCurrent && (
          <div className="absolute left-0 h-12 rounded-r-md border-r-4 border-r-quezone" />
        )}
        {
          // @ts-ignore
          <menu.icon sx={{ fontSize: 30 }} />
        }
        <div className="pt-[3px] text-xl">{menu.title}</div>
      </div>
    </Grow>
  );
}
