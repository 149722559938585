import { gql } from "@apollo/client";

export const TASK_TAG_FIELDS = gql`
  fragment TaskTagFields on taskTags {
    id
    name
  }
`;

export const TASK_TYPE_FIELDS = gql`
  fragment TaskTypeFields on taskTypes {
    id
    name
    description
    color
    priority
  }
`;

export const TASK_STATUS_FIELDS = gql`
  fragment TaskStatusFields on taskStatuses {
    id
    name
    description
    color
    priority
  }
`;

export const TASK_CORE_FIELDS = gql`
  ${TASK_STATUS_FIELDS}
  ${TASK_TYPE_FIELDS}
  fragment TaskCoreFields on tasks {
    id
    customerId
    customer {
      year
      name
      number
      address
      subType {
        id
        name
        priority
        color
      }
      warrantyDate
      registered_at
      contactIds
      companyId
      sourceCompany {
        id
        name
      }
      sourceDetail {
        id
        name
      }
      source {
        id
        name
      }
      sourceCompanyId
      inventories {
        productId
      }
    }
    typeId
    type {
      ...TaskTypeFields
    }
    statusId
    status {
      ...TaskStatusFields
    }
    companyId
    company {
      name
    }
    description
    date
    time
    assignedById
    assignedBy {
      name
      email
      color
    }
    assignedToId
    assignedTo {
      id
      name
      email
      color
    }
    created_at
    updated_at
    amount
    tagIds
    reads
    deleted
    report {
      id
      reads
      checked
      accounts {
        id
        item
        amount
        gst
      }
    }
    invHistoryId
  }
`;

export const TASK_TYPE_CHECK_FIELDS = gql`
  fragment TaskTypeCheckFields on taskTypeChecks {
    id
    typeId
    name
    priority
    forCustomer
  }
`;
