import { useForm, useWatch } from "react-hook-form";
import { useCustomerStore } from "../../../../../store/customerStore";
import { company } from "../../../../../types/company";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { UPDATE_COMPANY_BY_ID } from "../../../../../gqls/company";
import { useMutation } from "@apollo/client";
import { useState } from "react";

interface props {
  company: company;
}
export default function CustomerStatusControl({ company }: props) {
  const { statuses } = useCustomerStore();
  const defaultValues = {
    statuses: company?.customerTypeIds || [],
  };

  const { setValue, control, reset } = useForm({
    defaultValues,
  });

  const formStatuses = useWatch({
    control,
    name: "statuses",
  });

  const [isDirty, setIsDirty] = useState(false);

  const toggle = (id: number) => {
    setIsDirty(true);
    if (formStatuses.includes(id)) {
      setValue(
        "statuses",
        formStatuses.filter(s => s != id)
      );
    } else {
      setValue("statuses", formStatuses.concat(id));
    }
  };

  const [update, { loading }] = useMutation(UPDATE_COMPANY_BY_ID);

  const submit = () => {
    update({
      variables: {
        id: company.id,
        set: {
          customerStatusIds: formStatuses,
        },
      },
      onCompleted() {
        reset({
          statuses: formStatuses,
        });
        setIsDirty(false);
      },
    });
  };

  return (
    <div className="col-span-1 flex flex-col gap-2">
      <h1>고객 상태 설정</h1>
      <label>* 엑세스 할수 있는 고객 상태를 설정합니다</label>
      <div className="flex flex-row gap-2 flex-wrap max-w-md">
        {statuses.map(stat => (
          <Chip
            key={stat.id}
            variant={formStatuses.includes(stat.id) ? "filled" : "outlined"}
            color={formStatuses.includes(stat.id) ? "success" : "default"}
            onClick={() => toggle(stat.id)}
            label={stat.name}
          />
        ))}
      </div>
      {isDirty && (
        <div className="flex flex-row justify-end gap-2">
          <Button
            color="success"
            onClick={() => {
              reset();
              setIsDirty(false);
            }}
          >
            취소
          </Button>

          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={submit}
          >
            저장
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
