import { gql } from "@apollo/client";
import { COMPANY_FIELDS } from "./company";
import { AUTH_FIELDS } from "./auth";

export const USER_CORE_FIELDS = gql`
  ${AUTH_FIELDS}
  ${COMPANY_FIELDS}
  fragment UserCoreFields on users {
    id
    name
    email
    color
    status {
      id
      name
      considerActive
    }
    auth {
      ...AuthFields
    }
    companyId
    company {
      ...CompanyFields
    }
    role
    bio
    mobile
    address
    pushPref
    created_at
    updated_at
  }
`;
