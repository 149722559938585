import { useNavigate } from "react-router-dom";
import { useProductStore } from "../../../../../store/productStore";
import { inventory } from "../../../../../types/inv";
import useInvQty from "../../../../../hooks/useInvQty";
import getContrast from "../../../../../utils/getContrast";

interface props {
  inventory: inventory;
  plain?: boolean;
}

export default function CustomerInvItem({ inventory, plain }: props) {
  const { products } = useProductStore();

  const product = products.find(p => p.id === inventory.productId);

  const qty = useInvQty(inventory, true);

  const allQty = qty.reduce((prev, cur) => {
    return prev + cur.qty;
  }, 0);

  const navigate = useNavigate();

  const goToDetail = () => {
    navigate(`./detail/${inventory.id}`);
  };

  if (allQty === 0) {
    return null;
  }

  return (
    <div
      onClick={() => {
        !plain && goToDetail();
      }}
      className={`flex flex-col w-[210px] cursor-pointer relative shadow-md ring-1 ring-gray-200 bg-white ${
        !plain && "hover:brightness-90"
      } `}
    >
      <div className="absolute -left-3 -top-3 py-1 px-3 rounded-2xl text-white bg-black text-sm font-light">
        {allQty}개
      </div>
      <img
        className="h-[150px] object-cover"
        src={product?.photo}
        alt={product?.name}
      />

      <div className="flex flex-col gap-1 p-2">
        {/* name */}
        <div className="font-medium truncate">{product?.name}</div>
        {/* S/M */}
        <div className="truncate font-light text-sm">{product?.serial}</div>
        {/* Qty Break Down */}
        <div className="flex flex-wrap gap-2 text-xs">
          {qty
            .filter(q => q.qty > 0)
            .map(q => (
              <div
                className="p-1 rounded-sm"
                key={q.id}
                style={{
                  backgroundColor: q.status?.color,
                  color: getContrast(q.status?.color || ""),
                }}
              >
                {q.status?.name} {q.qty}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
