import SnackbarContent from "@mui/material/SnackbarContent";
import {
  StampedMessagePayload,
  useNotificationStore,
} from "../../store/notificationStore";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

export default function Snackbar({ toast }: { toast: StampedMessagePayload }) {
  const { messageId, data } = toast;

  const { title, body, path } = data || {
    title: "",
    body: "",
  };

  const message = `${title.replace(/undefined/g, "")} - ${body.replace(
    /undefined/g,
    ""
  )}`;

  const { removeToast, removeNotification } = useNotificationStore();

  const handleClick = () => {
    removeToast(messageId);
    removeNotification(messageId);
    if (path) {
      window.open(path, target);
    }
  };

  return (
    <SnackbarContent
      className="cursor-pointer"
      message={message}
      onClick={handleClick}
    />
  );
}
