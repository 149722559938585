import { Outlet } from "react-router-dom";
import { useAuthStore, useLoadUser } from "../../store/authStore";
import Loading from "../../comps/loading";
import { useEffect } from "react";
import { isBrowser, isMobile, useDeviceData } from "react-device-detect";
import { useDeviceStore } from "../../store/deviceStore";
import { publicIpv4 } from "public-ip";

export default function Container() {
  const { loading, setLoading, loggingIn } = useAuthStore();

  const loadUser = useLoadUser();

  const {
    setIp,
    setAgent,
    setLocation,
    ipLoaded,
    agentLoaded,
    locationLoaded,
  } = useDeviceStore();

  const getIp = async () => {
    if (!setIp) {
      return;
    }
    try {
      const ip = await publicIpv4({
        fallbackUrls: ["https://ifconfig.co/ip"],
      });
      setIp(ip);
      useDeviceStore.setState({ ipLoaded: true });
    } catch (error) {
      console.log(error);
      useDeviceStore.setState({ ipLoaded: true });
    }
  };

  useEffect(() => {
    getIp();
  }, [setIp]);

  // get user location, device info

  useEffect(() => {
    let unsubscribe = false;

    const agentData = useDeviceData("");

    if (!unsubscribe && setAgent && setLocation) {
      // set agent data
      const summary = `${
        isMobile
          ? `mobile ${agentData.device?.vendor} ${agentData.device?.model}`
          : `pc ${agentData.os?.name} ${agentData.os?.version}`
      }`;

      setAgent({
        isBrowser,
        isMobile,
        os: agentData.os?.name
          ? agentData.os?.name + agentData.os?.version
          : "",
        browser: agentData.browser?.name || "",
        device: `${agentData.device?.vendor} ${agentData.device?.model}`,
        summary,
      });

      useDeviceStore.setState({ agentLoaded: true });
      // set location data
      if (navigator && "geolocation" in navigator) {
        try {
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position.coords?.latitude);
            if (position.coords?.latitude && position.coords?.longitude) {
              setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            }
          });

          useDeviceStore.setState({ locationLoaded: true });
        } catch (error) {
          console.log(error);
          useDeviceStore.setState({ locationLoaded: true });
        }
      } else {
        useDeviceStore.setState({ locationLoaded: true });
      }
    }

    return () => {
      unsubscribe = true;
    };
  }, [setAgent, setLocation]);

  useEffect(() => {
    const persist = localStorage.getItem("persist");

    if (
      persist == "true" &&
      !loggingIn &&
      ipLoaded &&
      agentLoaded &&
      locationLoaded
    ) {
      loadUser();
    }

    if (persist !== "true") {
      setLoading(false);
    }
  }, [ipLoaded, agentLoaded, locationLoaded]);

  return (
    <div className="flex flex-col flex-1 h-full">
      <div className="flex-1">{loading ? <Loading /> : <Wrapper />}</div>
    </div>
  );
}

const Wrapper = () => {
  return (
    <div className="h-full grid grid-cols-2 md:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-8">
      <div
        id="filler"
        className="hidden md:block md:col-span-3 lg:col-span-4 2xl:col-span-6 bg-[url('/bg_main.jpg')] bg-top bg-cover brightness-200 contrast-125"
      />
      <div className="col-span-2 shadow-2xl h-full">
        <Outlet />
      </div>
    </div>
  );
};
