import { useLocation, useNavigate } from "react-router-dom";
import { menu } from "../../../../store/navStore";
import Tooltip from "@mui/material/Tooltip";

interface menuProps {
  menu: menu;
}

const Menu = ({ menu }: menuProps) => {
  const navigate = useNavigate();

  const go = () => {
    navigate(`/${menu.path}`);
  };

  const location = useLocation();
  const pathname = location.pathname.split("/");
  const isCurrent =
    pathname[1] == menu.path ||
    (menu.path == "dashboard" && location.pathname == "/");

  return (
    <div
      className="flex flex-row justify-center cursor-pointer relative items-center"
      onClick={go}
    >
      <Tooltip title={menu.title}>
        {
          // @ts-ignore
          <menu.icon
            sx={{ fontSize: 34, color: isCurrent ? "#007d25" : "gray" }}
            className="hover:text-quezone"
          />
        }
      </Tooltip>
      {isCurrent && (
        <div className="absolute right-0 h-12 rounded-l-md border-l-4 border-l-quezone" />
      )}
    </div>
  );
};

export default Menu;
