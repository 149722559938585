import { useEffect, useState } from "react";
import { extendedReport, tempImage } from "../../../../../types/report";
import useS3 from "../../../../../hooks/useS3";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import MyBadge from "../../../../../comps/myBadge";
import ReplyIcon from "@mui/icons-material/Reply";
import ReportControls from "../reportControls/reportControls";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { UPDATE_REPORT } from "../../../../../gqls/report";
import { useTaskStore } from "../../../../../store/taskStore";
import { useAuthStore } from "../../../../../store/authStore";
import { useAddAlert } from "../../../../../store/alertStore";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../../classPresets";
import { base64ToBlob } from "../../../../../utils/objectArrayMethods";
import Resizer from "react-image-file-resizer";

interface props {
  report: extendedReport;
  nested?: boolean;
}

const schema = z.object({
  typeId: z.coerce.number().min(1),
  checks: z.array(z.coerce.number()).nullable().optional(),
  received_on_site: z.boolean().nullable().optional(),
  received_amount: z.coerce.number().nullable().optional(),
  charge: z.coerce.number().nullable().optional(),
  serials: z.array(z.string()).nullable().optional(),
  description: z.string().nullable().optional(),
  date: z.coerce.date(),
  images: z.array(z.any()).nullable().optional(),
  customerSign: z.string().nullable().optional(),
  installerSign: z.string().nullable().optional(),
});

export default function ReportEdit({ report, nested }: props) {
  const resize = (file: File) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        "PNG",
        90,
        0,
        uri => {
          resolve(uri);
        },
        "file"
      );
    });

  const {
    id,
    task,
    date,
    charge,
    checks,
    description,
    serials,
    type,
    received_on_site,
    received_amount,
  } = report;

  const navigate = useNavigate();

  const defaultValues = {
    typeId: 0,
    checks: [] as number[],
    charge: null as null | number,
    received_on_site: null as null | boolean,
    received_amount: null as null | number,
    serials: [] as string[],
    description: null as null | string,
    date: dayjs(),
    images: [] as tempImage[],
    customerSign: null as null | string,
    installerSign: null as null | string,
  };

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isDirty, touchedFields },
    reset,
  } = methods;

  const { getObjectsInDir, getFileURL, uploadFile, deleteObject } = useS3();

  const dir = `customers/${task.customerId}/reports/${id}`;

  const [exImages, setExImages] = useState<tempImage[]>([]);

  const getInitSigns = async () => {
    const res = await getObjectsInDir(`${dir}/signs`);

    let signs = {
      installer: null as null | string,
      customer: null as null | string,
    };

    if (!res || !res.Contents) {
      return signs;
    }

    const { Contents } = res;

    for await (const content of Contents) {
      if (content.Key?.includes("installer.png")) {
        const url = await getFileURL(content.Key);
        signs.installer = url;
      }
      if (content.Key?.includes("customer.png")) {
        const url = await getFileURL(content.Key);
        signs.customer = url;
      }
    }
    return signs;
  };

  const getInitImages = async () => {
    const res = await getObjectsInDir(dir);

    if (!res || !res.Contents) {
      return [];
    }

    const { Contents } = res;

    const objectKeysOnly = Contents.filter(c => c.Size && c.Size > 0)
      .map(c => c.Key)
      .filter(c => c);

    const presets = type.photoTemplate;

    const images: tempImage[] = await Promise.all(
      objectKeysOnly.map(async key => {
        if (!key) {
          return {
            image: null,
            isPreset: false,
            name: "제목없음",
            src: "",
          };
        }
        const url = await getFileURL(key);

        const name = key.split("/").pop()!.split(".")[0];

        const image: tempImage = {
          image: null,
          isPreset: (presets && presets.includes(name)) || false,
          name,
          src: url,
          key,
        };
        return image;
      })
    );

    setExImages(images);

    return images || [];
  };

  const [loaded, setLoaded] = useState(false);

  const init = async () => {
    const images = await getInitImages();
    const signs = await getInitSigns();
    reset({
      typeId: type.id,
      checks: checks || ([] as number[]),
      received_on_site: received_on_site || (null as null | boolean),
      received_amount: received_amount || (null as null | number),
      charge: charge || (null as null | number),
      serials: serials || ([] as string[]),
      description: description || (null as null | string),
      date: date ? dayjs(date) : dayjs(),
      images,
      customerSign: signs.customer,
      installerSign: signs.installer,
    });
    setLoaded(true);
  };

  useEffect(() => {
    init();
  }, [id]);

  const fullNum = `#${task.customer.year}-${task?.customer.number}`;

  const [update] = useMutation(UPDATE_REPORT);
  const [loading, setLoading] = useState(false);

  const { reportTypes, taskTypeChecks } = useTaskStore();
  const { user } = useAuthStore();
  const addAlert = useAddAlert();

  const onSubmit = handleSubmit(data => {
    setLoading(true);
    const {
      charge,
      checks,
      customerSign,
      date,
      description,
      images,
      installerSign,
      serials,
      typeId,
      received_on_site,
      received_amount,
    } = data;

    const reportType = reportTypes.find(t => t.id === typeId);

    if (!reportType) {
      setLoading(false);
      return;
    }

    const myChecks = taskTypeChecks.filter(
      check => check.typeId == task?.typeId
    );

    const { needAllChecks, photoTemplate } = reportType;

    if (needAllChecks && myChecks.some(t => !checks.includes(t.id))) {
      setLoading(false);
      return addAlert({
        message: "확인 사항을 모두 체크해주세요",
        type: "warning",
      });
    }

    update({
      variables: {
        id,
        set: {
          received_on_site,
          received_amount,
          charge,
          checks,
          date,
          description,
          serials,
          typeId,
          reads: [user?.id],
        },
      },
      onCompleted: async () => {
        let uploaded = [] as string[];

        await Promise.all(
          exImages.map(async image => {
            const matchingImage = images.find(i => i.name === image.name);
            if (!matchingImage) {
              if (image.key) {
                await deleteObject(image.key);
                return;
              } else {
                return;
              }
            } else {
              if (matchingImage.image) {
                const ext = matchingImage.image.name.split(".").pop();
                const name = `${matchingImage.name}.${ext}`;
                await deleteObject(image.key as string);

                if (matchingImage.image.size > 5000000) {
                  const resizedImage = await resize(matchingImage.image);
                  const keyName = `${image.name}.png`;

                  await uploadFile(
                    resizedImage as File,
                    `${dir}/${keyName}`,
                    "image/png"
                  );
                  uploaded.push(matchingImage.name);
                  return;
                } else {
                  await uploadFile(
                    matchingImage.image,
                    `${dir}/${name}`,
                    `image/${ext}`
                  );
                  uploaded.push(matchingImage.name);
                  return;
                }
              } else {
                return;
              }
            }
          })
        );

        const newImages = images.filter(i => !uploaded.includes(i.name));

        await Promise.all(
          newImages.map(async (image, i) => {
            if (!image.image) {
              return;
            }
            const ext = image.image.name.split(".").pop();

            const name = `${
              image.name == "" ? `제목없음_${i + 1}` : image.name
            }.${ext}`;

            if (image.image.size > 5000000) {
              const resizedImage = await resize(image.image);
              const keyName = `${image.name}.png`;

              await uploadFile(
                resizedImage as File,
                `${dir}/${keyName}`,
                "image/png"
              );

              return;
            } else {
              await uploadFile(image.image, `${dir}/${name}`, `image/${ext}`);

              return;
            }
          })
        );

        await (async () => {
          if (touchedFields.customerSign && customerSign) {
            const sign = await base64ToBlob(customerSign);
            await deleteObject(`${dir}/signs/customer.png`);
            await uploadFile(sign, `${dir}/signs/customer.png`, "image/png");
            return;
          } else {
            return;
          }
        })();

        await (async () => {
          if (touchedFields.installerSign && installerSign) {
            const sign = await base64ToBlob(installerSign);
            await deleteObject(`${dir}/signs/installer.png`);
            await uploadFile(sign, `${dir}/signs/installer.png`, "image/png");
            return;
          } else {
            return;
          }
        })();

        setLoading(false);
        addAlert({
          message: "보고를 수정했습니다",
          type: "success",
        });
        reset();
        localStorage.removeItem(`@report:${id}`);
        navigate(-1);
      },
      onError(error) {
        console.log(error);
        setLoading(false);
      },
    });
  });

  if (!loaded) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className={`flex-1 flex flex-col gap-4 ${!nested && "md:mx-10"}
        `}
      >
        <div
          className={`flex-1 pt-5 ${nested && "pt-0 md:pt-5"} ${
            !nested && "md:p-8 md:drop-shadow-md"
          } flex flex-col gap-4 md:bg-white overflow-x-clip`}
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center gap-4 flex-wrap px-3 md:px-0">
            <div className="flex flex-row items-center gap-2 mb-1">
              <h1 className="-mb-1">
                {nested ? `보고 수정` : `${fullNum} ${task?.customer.name}`}
              </h1>
              {task && (
                <MyBadge text={task?.type.name} color={task?.type.color} big />
              )}
            </div>
            <div
              className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ReplyIcon />
            </div>
          </div>
          {/* Body */}
          {task && <ReportControls task={task} />}
          {/* Footer */}
          <div className="flex flex-row justify-end gap-4 px-3 md:px-0 py-5 md:py-0 bg-gray-100 md:bg-transparent">
            <Button
              {...blackButtonOutlined}
              onClick={() => {
                navigate(-1);
              }}
            >
              취소
            </Button>
            <LoadingButton
              loading={loading}
              disabled={!isDirty}
              {...blackButtonContained}
              type="submit"
            >
              수정
            </LoadingButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
