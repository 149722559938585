import { FormProvider, useForm } from "react-hook-form";
import { useAuthStore } from "../../../../store/authStore";
import dayjs from "dayjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation } from "@apollo/client";
import { ADD_CORRESPONDENCE } from "../../../../gqls/correspondence";
import hasuraFilter from "../../../../utils/hasuraFilter";
import { CORRESPONDENCE_FIELDS } from "../../../../fragments/correspondence";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CorrespondenceControls from "./correspondenceControls";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../classPresets";

interface props {
  customerId: number;
  taskId?: number;
  simple?: boolean;
  mobile?: boolean;
  cancelFunc?: () => void;
}

const schema = z.object({
  content: z.string().min(1, "내역을 입력하세요"),
  byId: z.coerce.number(),
  date: z.coerce.date(),
});

export default function AddCorrespondence({
  customerId,
  taskId,
  simple,
  mobile,
  cancelFunc,
}: props) {
  const { user } = useAuthStore();

  const methods = useForm({
    defaultValues: {
      content: "",
      byId: user?.id,
      date: dayjs(),
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const [addCorrespondence, { loading }] = useMutation(ADD_CORRESPONDENCE);

  const onSubmit = handleSubmit(data => {
    addCorrespondence({
      variables: {
        object: {
          ...data,
          date: data.date.toISOString(),
          customerId,
          taskId,
        },
      },
      onCompleted() {
        reset();
        cancelFunc && cancelFunc();
      },
      update(cache, { data }) {
        const correspondence = data.insert_correspondences_one;
        cache.modify({
          fields: {
            correspondences(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: [correspondence],
                name: "correspondences",
                string: storeFieldName,
              });
              const newCorrespondenceRef = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: CORRESPONDENCE_FIELDS,
                  fragmentName: "CorrespondenceFields",
                })
              );
              return [...existing, ...newCorrespondenceRef];
            },
          },
        });
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="w-full flex flex-col gap-4">
        <CorrespondenceControls taskId={taskId} simple={simple} />
        {(isDirty || mobile) && (
          <div className="flex flex-row justify-end gap-4">
            <Button
              size="small"
              {...blackButtonOutlined}
              onClick={() => {
                reset();
                cancelFunc && cancelFunc();
              }}
            >
              취소
            </Button>
            <LoadingButton
              disabled={!isDirty}
              size="small"
              {...blackButtonContained}
              type="submit"
            >
              추가
            </LoadingButton>
          </div>
        )}
      </form>
    </FormProvider>
  );
}
