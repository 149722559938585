import { useState } from "react";
import { extendedCustomer } from "../../../../../types/customer";
import { useCustomerStore } from "../../../../../store/customerStore";
import MyBadge from "../../../../../comps/myBadge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAddCustomerHistory from "../../../../../hooks/useAddCustomerHistory";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_BY_ID } from "../../../../../gqls/customer";
import Tooltip from "@mui/material/Tooltip";
import { sort } from "fast-sort";

interface props {
  customer: extendedCustomer;
}

export default function CustomerTypeControl({ customer }: props) {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { subType } = customer;
  let { subTypes } = useCustomerStore();

  subTypes = subTypes.filter(t => t.id !== subType?.id);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [update] = useMutation(UPDATE_CUSTOMER_BY_ID);
  const { addHistory } = useAddCustomerHistory();

  const updateSubType = (typeId: number) => {
    update({
      variables: {
        id: customer.id,
        set: {
          subTypeId: typeId || null,
        },
      },
    });
    addHistory({
      customerId: customer.id,
      message: "서브타입 변경",
      type: "detail",
      oldData: {
        subTypeId: subType?.id,
      },
      newData: {
        subTypeId: typeId || null,
      },
    });
    setOpen(false);
  };

  const remove = () => {
    updateSubType(0);
  };

  return (
    <div className="relative">
      <div className="cursor-pointer hover:brightness-90" onClick={openMenu}>
        {subType && <MyBadge color={subType.color} text={subType.name} />}
        {!subType && (
          <Tooltip title="서브타입 추가">
            <div className="text-gray-400"> +</div>
          </Tooltip>
        )}
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {!subType && <MenuItem disabled>서브타입 추가</MenuItem>}
        {sort(subTypes)
          .asc("priority")
          .map(t => {
            const selected = t.id == subType?.id;

            return (
              <MenuItem
                key={t.id}
                onClick={() => updateSubType(t.id)}
                disabled={selected}
              >
                {t.name}
              </MenuItem>
            );
          })}
        {subType && <MenuItem onClick={remove}>서브타입 삭제</MenuItem>}
      </Menu>
    </div>
  );
}
