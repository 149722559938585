import { TypedDocumentNode, gql } from "@apollo/client";
import { Auth } from "../types/auth";
import { AUTH_FIELDS } from "../fragments/auth";

export const ADD_AUTH = gql`
  ${AUTH_FIELDS}
  mutation ADD_AUTH($object: auths_insert_input!) {
    insert_auths_one(object: $object) {
      ...AuthFields
    }
  }
`;

export const UPDATE_AUTH_BY_ID: TypedDocumentNode<{
  update_auths_by_pk: Auth;
}> = gql`
  ${AUTH_FIELDS}
  mutation UPDATE_AUTH_BY_ID($id: Int!, $set: auths_set_input!) {
    update_auths_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...AuthFields
    }
  }
`;

export const DELETE_AUTH = gql`
  mutation DELETE_AUTH($id: Int!) {
    delete_auths_by_pk(id: $id) {
      id
    }
  }
`;
