import { gql } from "@apollo/client";
import { COMPANY_FIELDS } from "./company";
import { USER_CORE_FIELDS } from "./user";
import { CUSTOMER_CORE_FIELDS } from "./customer";

export const INV_STATUS_FIELDS = gql`
  fragment InvStatusFields on invStatuses {
    id
    name
    description
    color
    priority
    available
  }
`;

export const INV_ACTION_FIELDS = gql`
  fragment InvActionFields on invActions {
    id
    name
    description
    color
    priority
    type
    statusId
    fromStatIds
    linger
  }
`;

export const INVENTORY_FIELDS = gql`
  fragment InventoryFields on inventory {
    id
    created_at
    updated_at
    productId
    companyId
    userId
    customerId
  }
`;

export const INVENTORY_POPULATED_FIELDS = gql`
  ${INVENTORY_FIELDS}
  ${COMPANY_FIELDS}
  ${USER_CORE_FIELDS}
  ${CUSTOMER_CORE_FIELDS}
  fragment InventoryPopulatedFields on inventory {
    ...InventoryFields
    user {
      ...UserCoreFields
    }
    company {
      ...CompanyFields
    }
    customer {
      ...CustomerCoreFields
    }
  }
`;

export const INV_HISTORY_FIELDS = gql`
  fragment InvHistoryFields on invHistories {
    id
    description
    invActionId
    fromId
    toId
    created_at
    updated_at
    changes
    from {
      id
      user {
        id
        name
      }
      company {
        id
        name
      }
      customer {
        id
        name
        year
        number
      }
      productId
    }
    byId
    by {
      id
      name
    }
    to {
      id
      user {
        id
        name
      }
      company {
        id
        name
      }
      customer {
        id
        name
        year
        number
      }
      productId
    }
  }
`;

export const INV_QTY_FIELDS = gql`
  fragment InvQtyFields on invQty {
    id
    invId
    subInvId
    statusId
    qty
  }
`;

export const INV_QTY_EXTENDED_FIELDS = gql`
  ${INV_QTY_FIELDS}
  ${INVENTORY_POPULATED_FIELDS}
  fragment InvQtyExtendedFields on invQty {
    ...InvQtyFields
    inventory {
      ...InventoryPopulatedFields
    }
    subInventory {
      ...InventoryPopulatedFields
    }
  }
`;
