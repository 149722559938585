import { Link } from "react-router-dom";
import { automation } from "../../../../types/automation";
import { useTaskStore } from "../../../../store/taskStore";
import { useCustomerStore } from "../../../../store/customerStore";
import { useQuery } from "@apollo/client";
import { GET_BIZTALK_BY_ID } from "../../../../gqls/biztalk";
import BiztalkRow from "../biztalks/biztalkRow";

interface props {
  automation: automation;
}

export default function AutomationRow({ automation }: props) {
  const { statuses: taskStats, types: taskTypes, reportTypes } = useTaskStore();
  const { statuses } = useCustomerStore();
  const {
    trigger,
    fromTaskStatIds,
    taskTypeIds,
    fromCustomerStatIds,
    reportTypeIds,
    action,
    biztalkId,
    id,
    toCustomerStatId,
    toTaskStatId,
    invTo,
    date,
  } = automation;

  const { data: biztalkData } = useQuery(GET_BIZTALK_BY_ID, {
    variables: {
      id: biztalkId,
    },
  });

  const biztalk = biztalkData?.biztalks_by_pk;

  return (
    <div className="p-4 shadow-md flex flex-col gap-2">
      <Link to={`/settings/automation/edit/${id}`}>
        <div className="flex flex-row justify-between items-center hover:text-quezone">
          <h2>{trigger} 시</h2>
          <h2>#{id}</h2>
        </div>
      </Link>
      {["작업 등록", "작업 상태 변경"].includes(trigger) && (
        <div className="flex flex-row gap-2">
          <div>
            <span className="font-semibold">구분</span>:{" "}
            {taskTypes
              .filter(t => taskTypeIds?.includes(t.id))
              .map(t => t.name)
              .join(", ")}
          </div>
          <div>
            <span className="font-semibold">상태</span>:{" "}
            {taskStats
              .filter(t => fromTaskStatIds?.includes(t.id))
              .map(t => t.name)
              .join(", ")}
          </div>
        </div>
      )}
      {["고객 등록", "고객 상태 변경"].includes(trigger) && (
        <div>
          <span className="font-semibold">상태</span>:{" "}
          {statuses
            .filter(t => fromCustomerStatIds?.includes(t.id))
            .map(t => t.name)
            .join(", ")}
        </div>
      )}
      {trigger == "보고 등록" && reportTypeIds && reportTypeIds?.length > 0 && (
        <div>
          <span className="font-semibold">구분</span>:{" "}
          {reportTypes
            .filter(rt => reportTypeIds?.includes(rt.id))
            .map(
              rt =>
                `${taskTypes.find(t => t.id == rt.taskTypeId)?.name} ${rt.name}`
            )
            .join(", ")}
        </div>
      )}

      <h2 className="mt-2">{action}</h2>
      {action == "비즈톡 전송" && biztalk && <BiztalkRow biztalk={biztalk} />}
      {action == "고객 상태 변경" && (
        <div>{statuses.find(stat => stat.id == toCustomerStatId)?.name}</div>
      )}
      {action == "작업 상태 변경" && (
        <div>{taskStats.find(stat => stat.id == toTaskStatId)?.name}</div>
      )}
      {[
        "고객 설치날짜 변경",
        "고객 AS날짜 변경",
        "고객 보증기간 변경",
      ].includes(action) && <div>조건 발동일로부터 {date}일</div>}
    </div>
  );
}
