import Header from "../layouts/header/header";
import { useRoutes, Outlet, useNavigate } from "react-router-dom";
import { useCheckAuth } from "../../../store/authStore";
import { useAddAlert } from "../../../store/alertStore";
import CustomersMain from "./customersMain/customersMain";
import AddCustomer from "./addCustomer/addCustomer";
import CustomerDetail from "./customerDetail/customerDetail";
import { scrollbar } from "../../../classPresets";
import AddCustomerBatch from "./addCustomer/addCustomerBatch";

function Wrapper() {
  const checkAuth = useCheckAuth();

  const navigate = useNavigate();
  const addAlert = useAddAlert();

  if (!checkAuth({ permissionName: "고객열람" })) {
    addAlert({
      message: "접근 권한이 없습니다",
      type: "error",
    });
    navigate("/");
    return null;
  }

  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar}  bg-white md:bg-transparent`}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <div className="px-10">
        <Header title="고객관리" />
      </div>
      <Outlet />
    </div>
  );
}

export default function Customers() {
  let routes = useRoutes([
    {
      path: "/",
      element: <Wrapper />,
      children: [
        { path: "/", element: <CustomersMain /> },
        { path: "/*", element: <CustomersMain /> },
        { path: "/add", element: <AddCustomer /> },
        { path: "/add/batch", element: <AddCustomerBatch /> },
        { path: "/detail/:id/*", element: <CustomerDetail /> },
      ],
    },
  ]);

  return routes;
}
