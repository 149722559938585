import { useTaskStore } from "../../../../../../../store/taskStore";
import { task } from "../../../../../../../types/task";
import dayjs from "dayjs";

interface props {
  date: Date;
  tasks: task[];
}

export default function Day({ tasks, date }: props) {
  const list = tasks.filter(task => dayjs(task.date).isSame(date, "date"));
  const { selectedDate } = useTaskStore();
  const isSelected = dayjs(selectedDate).isSame(date, "date");

  if (list.length > 0) {
    return (
      <div
        className={`flex flex-row justify-center pl-[1.5px] items-start absolute text-3xl top-3/4 w-full pointer-events-none `}
      >
        <div
          className={`p-[2.5px] rounded-full bg-quezone ${
            isSelected && "bg-white"
          }`}
        />
      </div>
    );
  }
  return null;
}
