import { TypedDocumentNode, gql } from "@apollo/client";
import { CORRESPONDENCE_FIELDS } from "../fragments/correspondence";
import { correspondence } from "../types/correspondence";

export const GET_CORRESPONDENCES_CONDITIONAL: TypedDocumentNode<{
  correspondences: correspondence[];
}> = gql`
  ${CORRESPONDENCE_FIELDS}
  query GET_CORRESPONDENCES_CONDITIONAL($where: correspondences_bool_exp!) {
    correspondences(where: $where) {
      ...CorrespondenceFields
    }
  }
`;

export const ADD_CORRESPONDENCE = gql`
  ${CORRESPONDENCE_FIELDS}
  mutation ADD_CORRESPONDENCE($object: correspondences_insert_input!) {
    insert_correspondences_one(object: $object) {
      ...CorrespondenceFields
    }
  }
`;

export const ADD_CORRESPONDENCE_MANY = gql`
  ${CORRESPONDENCE_FIELDS}
  mutation ADD_CORRESPONDENCE_MANY($objects: [correspondences_insert_input!]!) {
    insert_correspondences(objects: $objects) {
      returning {
        ...CorrespondenceFields
      }
    }
  }
`;

export const ADD_CORRESPONDENCE_MANY_M = gql`
  mutation ADD_CORRESPONDENCE_MANY_M(
    $objects: [correspondences_insert_input!]!
  ) {
    insert_correspondences(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_CORRESPONDENCE_BY_ID: TypedDocumentNode<{
  update_correspondences_by_pk: correspondence;
}> = gql`
  ${CORRESPONDENCE_FIELDS}
  mutation UPDATE_CORRESPONDENCE_BY_ID(
    $id: Int!
    $set: correspondences_set_input!
  ) {
    update_correspondences_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...CorrespondenceFields
    }
  }
`;

export const DELETE_CORRESPONDENCE = gql`
  mutation DELETE_CORRESPONDENCE($id: Int!) {
    delete_correspondences_by_pk(id: $id) {
      id
    }
  }
`;
