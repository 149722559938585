import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { filter } from "../../../../types/common";
import { useFormContext, useWatch } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface props {
  filter: filter;

  isOverlay?: boolean;
}
export default function SortItem({ filter, isOverlay }: props) {
  const { control, setValue, getValues } = useFormContext();

  const index = getValues("filters").findIndex(
    (f: filter) => f?.name === filter.name
  );

  const coord = `filters.${index}.sort`;
  const { name, sort } = filter;

  const dir = useWatch({
    name: `${coord}.dir`,
    control,
  });

  const changeDir = (dir: string) => {
    setValue(`${coord}.dir`, dir, { shouldDirty: true, shouldTouch: true });
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: filter.name });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  if (!sort) {
    return null;
  }

  return (
    <div
      className={`flex flex-row gap-2 items-center p-1 border-[1px] border-black ${
        isOverlay ? "text-quezone" : ""
      }`}
      ref={setNodeRef}
      style={style}
    >
      <div className="flex flex-row justify-between items-center flex-1">
        <div
          className={`font-medium flex flex-row gap-1 cursor-pointer items-center flex-1 ${
            dir == "off" && "text-gray-500"
          }`}
          onClick={() => {
            changeDir(dir == "off" ? "asc" : "off");
          }}
        >
          <Checkbox
            checked={dir !== "off"}
            readOnly
            size="small"
            color="success"
          />
          <div className="flex-1 w-0 truncate">{name}</div>
        </div>
        <div
          className="text-sm cursor-pointer"
          onClick={() => {
            changeDir(dir == "asc" ? "desc" : "asc");
          }}
        >
          <span className={` ${dir == "desc" && "text-quezone font-medium"}`}>
            내림차
          </span>
          <span className="text-gray-300"> | </span>
          <span className={` ${dir == "asc" && "text-quezone font-medium"}`}>
            오름차
          </span>
        </div>
      </div>
      <div {...attributes} {...listeners}>
        <DragIndicatorIcon className="cursor-pointer" />
      </div>
    </div>
  );
}
