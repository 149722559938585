import React from "react";

interface props {
  title: string;
  content: string;
  contentComp?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  colSpan?: number;
  editing?: boolean;
  striped?: boolean;
  onClick?: () => void;
}
export default function FieldWrapper({
  title,
  children,
  className,
  colSpan,
  editing,
  content,
  striped,
  onClick,
  contentComp,
}: props) {
  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className={`
      ring-1 ring-gray-100 md:ring-0
      flex flex-row flex-wrap justify-between items-center md:flex-col gap-1 py-3 md:py-2 md:items-stretch md:justify-normal px-3 md:px-0
      ${striped && "bg-gray-100 md:bg-transparent"}
      ${colSpan ? `md:col-span-${colSpan}` : "col-span-1"}
      ${className ? className : ""}`}
    >
      <label className="text-black" htmlFor={title}>
        {title}
      </label>
      {editing && children ? (
        children
      ) : contentComp ? (
        contentComp
      ) : (
        <div
          className={`${
            !!onClick && "cursor-pointer hover:text-quezone"
          } break-keep
          md:p-2 md:rounded-sm md:ring-1 md:ring-gray-300
          `}
        >
          {content}
        </div>
      )}
    </div>
  );
}
