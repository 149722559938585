import { customerHistory } from "../../../../../../types/customer";
import { useCustomerStore } from "../../../../../../store/customerStore";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USERS_CONDITIONAL } from "../../../../../../gqls/user";
import {
  GET_SOURCES_CONDITIONAL,
  GET_SOURCE_DETAILS_CONDITIONAL,
} from "../../../../../../gqls/source";
import MyBadge from "../../../../../../comps/myBadge";
import dayjs from "dayjs";
import { GET_CONTACTS_BY_IDS } from "../../../../../../gqls/contact";
import { GET_CUSTOMER_TAGS_BY_IDS } from "../../../../../../gqls/customer";

const customerKeysTranslated: {
  [key: string]: string;
} = {
  typeId: "구분",
  refereeId: "추천인",
  address: "주소",
  postCode: "주소",
  sourceId: "접수처",
  sourceDetailId: "세부접수처",
  sourceCompanyId: "세부접수처",
  registered_at: "접수날짜",
  asDate: "A/S날짜",
  installDate: "설치날짜",
  warrantyDate: "무상A/S기간",
  description: "비고",
  contactIds: "연락처",
  tagIds: "태그",
};

interface update {
  where: string;
  name: string;
  newData: Partial<customerHistory["newData"]>;
  oldData: Partial<customerHistory["oldData"]>;
}

export default function DetailUpdates({
  history,
}: {
  history: customerHistory;
}) {
  const { newData, oldData } = history;

  if (!newData || !oldData) {
    return "변경 정보를 찾을 수 없습니다";
  }

  const updates: update[] = [];

  for (const key in newData) {
    updates.push({
      where: key,
      name: customerKeysTranslated[key],
      newData: newData[key as keyof customerHistory["newData"]],
      oldData: oldData[key as keyof customerHistory["oldData"]],
    });
  }
  return (
    <div className="flex-1 flex flex-col gap-1">
      {updates.map(u => (
        <DetailUpdate key={u.where} update={u} updates={updates} />
      ))}
    </div>
  );
}

const DetailUpdate = ({
  update,
  updates,
}: {
  update: update;
  updates: update[];
}) => {
  const { where, name } = update;

  if (name == "주소") {
    <AddressUpdate update={update} updates={updates} />;
  }

  if (where == "sourceId") {
    return <SourceUpdate update={update} />;
  }

  if (where == "sourceDetailId") {
    return <SourceDetailUpdate update={update} />;
  }

  if (where == "sourceCompanyId") {
    return null;
  }

  if (where == "typeId") {
    return <TypeUpdate update={update} />;
  }

  if (where == "subTypeId") {
    return <SubTypeUpdate update={update} />;
  }

  if (where == "refereeId") {
    return <RefereeUpdate update={update} />;
  }

  if (where == "contactIds") {
    return <ContactUpdate update={update} />;
  }

  if (where == "tagIds") {
    return <TagUpdate update={update} />;
  }

  const dateUpdates = [
    "registered_at",
    "asDate",
    "installDate",
    "warrantyDate",
  ];

  if (dateUpdates.includes(where)) {
    return <DateUpdate update={update} />;
  }

  return <DefaultUpdate update={update} />;
};

const TypeUpdate = ({ update }: { update: update }) => {
  const { types } = useCustomerStore();

  const { newData, oldData } = update;

  const newType = types.find(t => t.id == newData);
  const oldType = types.find(t => t.id == oldData);

  return (
    <div className="flex flex-row gap-2 items-center">
      {oldType ? <MyBadge color={oldType.color} text={oldType.name} /> : ""}{" "}
      {"=>"}
      {newType ? <MyBadge color={newType.color} text={newType.name} /> : ""}
    </div>
  );
};

const SubTypeUpdate = ({ update }: { update: update }) => {
  const { subTypes } = useCustomerStore();

  const { newData, oldData } = update;

  const newType = subTypes.find(t => t.id == newData);
  const oldType = subTypes.find(t => t.id == oldData);

  return (
    <div className="flex flex-row gap-2 items-center">
      {oldType ? <MyBadge color={oldType.color} text={oldType.name} /> : ""}{" "}
      {"=>"}
      {newType ? <MyBadge color={newType.color} text={newType.name} /> : ""}
    </div>
  );
};

const RefereeUpdate = ({ update }: { update: update }) => {
  const { name, newData, oldData } = update;

  const [names, setNames] = useState({
    old: "없음",
    new: "없음",
  });

  useQuery(GET_USERS_CONDITIONAL, {
    variables: {
      where: {
        id: {
          _in: [oldData || 0, newData || 0],
        },
      },
    },
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      setNames({
        old: data.users.find(u => u.id == Number(oldData))?.name || "없음",
        new: data.users.find(u => u.id == Number(newData))?.name || "없음",
      });
    },
  });

  return (
    <div className="break-keep">{`${name}: ${names.old} => ${names.new}`}</div>
  );
};

const AddressUpdate = ({
  update,
  updates,
}: {
  update: update;
  updates: update[];
}) => {
  const { where, name } = update;

  if (where == "postCode" && updates.find(u => u.where == "address")) {
    return null;
  }

  let oldAddress = update.oldData as string;
  let newAddress = update.newData as string;

  if (where == "address" && updates.find(u => u.where == "postCode")) {
    const postCode = updates.find(u => u.where == "postCode");
    oldAddress = `${oldAddress} ${postCode?.oldData}`;
    newAddress = `${newAddress} ${postCode?.newData}`;
  }

  return (
    <div className="break-keep">{`${name}: ${oldAddress} => ${newAddress}`}</div>
  );
};

const SourceUpdate = ({ update }: { update: update }) => {
  const { name, newData, oldData } = update;

  const [sources, setSources] = useState({
    old: "",
    new: "",
  });

  useQuery(GET_SOURCES_CONDITIONAL, {
    variables: {
      where: {
        id: {
          _in: [oldData || 0, newData || 0],
        },
      },
    },
    onCompleted(data) {
      setSources({
        ...sources,
        old: data.sources.find(s => s.id == oldData)?.name || "미상",
        new: data.sources.find(s => s.id == newData)?.name || "미상",
      });
    },
  });

  return (
    <div className="break-keep">{`${name}: ${sources.old} => ${sources.new}`}</div>
  );
};

const SourceDetailUpdate = ({ update }: { update: update }) => {
  const { name, newData, oldData } = update;

  const [sourceDetails, setSourceDetails] = useState({
    old: "",
    new: "",
  });

  useQuery(GET_SOURCE_DETAILS_CONDITIONAL, {
    variables: {
      where: {
        id: {
          _in: [oldData || 0, newData || 0],
        },
      },
    },
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      const oldSource = data.sourceDetails.find(s => s.id == Number(oldData));
      const newSource = data.sourceDetails.find(s => s.id == Number(newData));

      setSourceDetails({
        ...sourceDetails,
        old: oldSource?.name || oldSource?.company.name || "미상",
        new: newSource?.name || newSource?.company.name || "미상",
      });
    },
  });

  return (
    <div className="break-keep">{`${name}: ${sourceDetails.old} => ${sourceDetails.new}`}</div>
  );
};

const DefaultUpdate = ({ update }: { update: update }) => {
  const { name, newData, oldData } = update;

  return (
    <div className="break-keep">{`${name}: ${oldData} => ${newData}`}</div>
  );
};

const DateUpdate = ({ update }: { update: update }) => {
  const { name, newData, oldData } = update;

  return (
    <div className="break-keep">{`${name}: ${dayjs(oldData as string).format(
      "YYYY. MM. DD"
    )} => ${dayjs(newData as string).format("YYYY. MM. DD")}`}</div>
  );
};

const ContactUpdate = ({ update }: { update: update }) => {
  const { name, newData, oldData } = update;

  const oldContactIds = (oldData as number[]) || [];
  const newContactIds = (newData as number[]) || [];

  const { data } = useQuery(GET_CONTACTS_BY_IDS, {
    variables: {
      ids: [...(oldData as number[]), ...(newData as number[])],
    },
  });

  const contacts = data?.contacts || [];

  const oldContacts = contacts.filter(c => oldContactIds.includes(c.id));
  const newContacts = contacts.filter(c => newContactIds.includes(c.id));

  return (
    <div className="flex flex-row gap-1 items-center">
      <div>{name}:</div>
      <div>{oldContacts.map(c => c.name).join(", ")}</div>
      <div>{"=>"}</div>
      <div>{newContacts.map(c => c.name).join(", ")}</div>
    </div>
  );
};

const TagUpdate = ({ update }: { update: update }) => {
  const { name, newData, oldData } = update;

  const oldTagIds = (oldData as number[]) || [];
  const newTagIds = (newData as number[]) || [];

  const { data } = useQuery(GET_CUSTOMER_TAGS_BY_IDS, {
    variables: {
      ids: [...(oldData as number[]), ...(newData as number[])],
    },
  });

  const tags = data?.customerTags || [];

  const oldTags = tags.filter(c => oldTagIds.includes(c.id));
  const newTags = tags.filter(c => newTagIds.includes(c.id));

  return (
    <div className="flex flex-row gap-1 items-center">
      <div>{name}:</div>
      <div>{oldTags.map(c => c.name).join(", ")}</div>
      <div>{"=>"}</div>
      <div>{newTags.map(c => c.name).join(", ")}</div>
    </div>
  );
};
