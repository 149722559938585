import { useQuery } from "@apollo/client";
import BlackHeading from "../../../../comps/blackHeading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { task } from "../../../../types/task";
import { GET_INVENTORY_CONDITIONAL } from "../../../../gqls/inv";
import CustomerInvItem from "../../customers/customerDetail/customerInventory/customerInvItem";
import { useState } from "react";

interface props {
  task: task;
}
export default function TaskInventory({ task }: props) {
  const { customerId } = task;

  const { data } = useQuery(GET_INVENTORY_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: customerId,
        },
      },
    },
  });

  const inventories = data?.inventory || [];

  const [show, setShow] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <BlackHeading title="고객 재고">
        <div className="flex flex-row justify-between items-center">
          <span>고객 재고</span>
          <div
            className="cursor-pointer hover:text-quezone text-white"
            onClick={() => setShow(!show)}
          >
            {show ? (
              <VisibilityIcon sx={{ width: 18 }} />
            ) : (
              <VisibilityOffIcon sx={{ width: 18 }} />
            )}
          </div>
        </div>
      </BlackHeading>
      <div
        className={`${
          !show && "hidden"
        } flex flex-row gap-y-8 gap-x-4 flex-wrap p-4 justify-evenly md:justify-normal`}
      >
        {inventories.length == 0 && "할당된 재고가 없습니다"}
        {inventories.map(inventory => (
          <CustomerInvItem key={inventory.id} inventory={inventory} plain />
        ))}
      </div>
    </div>
  );
}
