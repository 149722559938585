import { customer } from "../../../../../../types/customer";
import MyBadge from "../../../../../../comps/myBadge";
import dayjs from "dayjs";
import CakeIcon from "@mui/icons-material/Cake";
// import EventIcon from "@mui/icons-material/Event";
// import BuildIcon from "@mui/icons-material/Build";
import CustomerInventories from "./customerInventories";
import { useAuthStore } from "../../../../../../store/authStore";
import Visibility from "@mui/icons-material/Visibility";

interface props {
  customer: customer;
  isKanban?: boolean;
  measure?: () => void;
}

export default function ListKanbanItem({ customer, isKanban, measure }: props) {
  if (!customer) {
    return null;
  }

  const {
    year,
    number,
    name,
    status,
    type,
    subType,
    address,
    postCode,
    source,
    sourceDetail,
    sourceCompany,
    installDate,
    warrantyDate,
    registered_at,
    inventories,
    reads,
    created_at,
  } = customer;

  const { user } = useAuthStore();

  const fullNumber = `#${year}-${number}`;

  const updated =
    reads &&
    user &&
    !reads.includes(user.id) &&
    dayjs(created_at).isAfter(dayjs(user.created_at));

  const isUnderWarranty = warrantyDate
    ? dayjs(warrantyDate).isAfter(dayjs())
    : dayjs(registered_at).diff(dayjs(), "days") < 365;

  const watching = customer.watching.includes(user?.id || 0);

  return (
    <div
      onLoad={() => {
        measure && measure();
      }}
      className="flex flex-col gap-1 bg-white p-3 ring-1 ring-gray-300 rounded-sm m-[1px] group hover:ring-quezone cursor-pointer relative overflow-clip"
    >
      {/* updated badge */}
      {updated && (
        <div className="absolute -right-[4px] -top-[7px] py-[7px] px-4 rounded-lg bg-quezone" />
      )}
      {/* number, type, company */}
      <div className="flex flex-row justify-between items-center gap-2">
        <div className="flex flex-row gap-2 items-center">
          <div className="font-medium">{fullNumber}</div>
          <MyBadge color={type.color} text={type.name} />
          {subType && (
            <MyBadge fill color={subType.color} text={subType.name} />
          )}
        </div>

        {installDate && (
          <div
            className="text-sm flex flex-row gap-1 items-center"
            title="설치날짜"
          >
            {installDate ? dayjs(installDate).format("YY.MM.DD") : "미상"}
          </div>
        )}
      </div>
      <hr />
      {/* name, status, updated */}
      <div className="flex flex-row items-center justify-between">
        <h3 className="group-hover:text-quezone">{name}</h3>
        <div className="flex flex-row gap-2 items-center">
          {watching && (
            <Visibility
              className="text-quezone"
              sx={{ width: 20, height: 20 }}
            />
          )}
          {!isKanban && (
            <MyBadge color={status.color} text={status.name} fill />
          )}
          {!isUnderWarranty && <MyBadge text="₩" color="red" />}
        </div>
      </div>
      {/* address */}
      <div className="text-sm truncate">
        {address} {postCode}
      </div>
      {/* source */}
      <div className="text-sm truncate font-medium">
        접수처: {source?.name || "미상"} -{" "}
        {sourceCompany?.name || sourceDetail?.name || "미상"}
      </div>
      {/* Etcs */}
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center gap-x-2 flex-1 truncate flex-wrap">
          <div
            className="text-sm flex flex-row gap-1 items-center"
            title="접수날짜"
          >
            <div className="pb-[2px]">
              <CakeIcon sx={{ width: 17 }} />
            </div>
            {dayjs(registered_at).format("YY.MM.DD")}
          </div>

          {/* {asDate && (
            <div
              className="text-sm flex flex-row gap-1 items-center"
              title="AS날짜"
            >
              <BuildIcon sx={{ width: 16 }} />
              {dayjs(asDate).format("YY.MM.DD")}
            </div>
          )} */}
          {inventories && <CustomerInventories inventories={inventories} />}
        </div>
      </div>
    </div>
  );
}
