import Button from "@mui/material/Button";
import { product } from "../../../../../types/product";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT_BY_ID } from "../../../../../gqls/product";
import { useProductStore } from "../../../../../store/productStore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";

interface props {
  product: product;
}

export default function ProductActivationControl({ product }: props) {
  const [open, setOpen] = useState(false);

  const [update, { loading }] = useMutation(UPDATE_PRODUCT_BY_ID);

  const { updateProducts } = useProductStore();

  const _delete = () => {
    update({
      variables: {
        id: product.id,
        set: {
          active: !product.active,
        },
      },
      onCompleted() {
        setOpen(false);
        updateProducts([{ ...product, active: !product.active }]);
      },
    });
  };

  return (
    <>
      <Button color="warning" onClick={() => setOpen(true)}>
        {product.active ? "비활성" : "활성"}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>제품 {product.active ? "비활성" : "활성"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            제품을 {product.active ? "비활성" : "활성"} 상태로 전환하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setOpen(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="success"
            onClick={_delete}
          >
            적용
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
