import { useEffect, useState } from "react";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
import { useNavigate } from "react-router-dom";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import ReplyIcon from "@mui/icons-material/Reply";
import CloudUpload from "@mui/icons-material/CloudUpload";
import VisuallyHiddenInput from "../../../../comps/hiddenInput";
import Button from "@mui/material/Button";
import { utils, read } from "xlsx";
import { z } from "zod";
import { useCustomerStore } from "../../../../store/customerStore";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_CUSTOMER_MANY,
  ADD_CUSTOMER_TAG_MANY,
  GET_CUSTOMER_LAST_NUM,
  GET_CUSTOMER_RELATED_CONFIGS,
} from "../../../../gqls/customer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import dayjs from "dayjs";
import Popover from "@mui/material/Popover";
import {
  blackButtonContained,
  blackButtonOutlined,
  scrollbar,
} from "../../../../classPresets";
import { customer } from "../../../../types/customer";
import { ADD_CONTACT, FIND_CONTACT } from "../../../../gqls/contact";
import hasuraFilter from "../../../../utils/hasuraFilter";
import {
  CUSTOMER_CORE_FIELDS,
  CUSTOMER_TAG_FIELDS,
} from "../../../../fragments/customer";
import { tag } from "../../../../types/common";
import { CONTACT_CORE_FIELDS } from "../../../../fragments/contact";
import { useAddAlert } from "../../../../store/alertStore";
import LoadingButton from "@mui/lab/LoadingButton";
import { sort } from "fast-sort";
import useAddCustomerHistory from "../../../../hooks/useAddCustomerHistory";
import SubscriptIcon from "@mui/icons-material/Subscript";
import useS3 from "../../../../hooks/useS3";
import { getJsDateFromExcel } from "../../../../utils/numberMethods";

interface customerRow {
  고객: string;
  구분: string;
  서브타입?: string;
  상태: string;
  년도: number;
  담당사: string;
  접수처: string;
  "세부 접수처": string;
  주소: string;
  우편번호?: number;
  "연락처 이름"?: string;
  "연락처 번호": string;
  "접수 일자"?: Date;
  "설치 일자"?: Date;
  "AS 일자"?: Date;
  "AS 기간"?: Date;
  추천인: string;
  태그?: string[];
  비고?: string;
}

export default function AddCustomerBatch() {
  const { statuses, types, subTypes } = useCustomerStore();

  const [loaded, setLoaded] = useState(false);

  const { user } = useAuthStore();

  const checkAuth = useCheckAuth();
  const navigate = useNavigate();

  const [loadParams, { data: params }] = useLazyQuery(
    GET_CUSTOMER_RELATED_CONFIGS
  );

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (
      !checkAuth({
        permissionName: "고객_일괄추가",
      })
    ) {
      return goBack;
    }

    if (statuses.length == 0 || types.length == 0) {
      return;
    }

    loadParams()
      .then(_ => {
        setLoaded(true);
      })
      .catch(err => {
        console.log(err);
      });
  }, [statuses, types]);

  const defaultValues = {
    customers: [] as customerRow[],
    errors: [] as { index: number; message: string }[],
  };

  const methods = useForm({
    defaultValues,
  });

  const addAlert = useAddAlert();

  const { handleSubmit, reset, setValue, control } = methods;

  const { fields, replace } = useFieldArray({
    control,
    name: "customers",
  });

  const errorRows = useWatch({ control, name: "errors" });

  const handleFile: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const files = e.target.files;
    if (!files || !files[0]) {
      return;
    }

    const file = files[0];

    const reader = new FileReader();
    reader.onload = e => {
      const data = e.target?.result;
      if (!data) {
        return;
      }
      const workbook = read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const customerRows = utils.sheet_to_json(worksheet);

      if (!customerRows) {
        return;
      }

      const companies = (params?.companies || [])
        .filter(c => c.type.canHaveCustomers)
        .map(c => c.name);
      const sources = (params?.sources || []).map(c => c.name);
      const sourceDetails = (params?.sourceDetails || []).map(
        c => c.name || c.company.name
      );
      const customerTypes = types.map(t => t.name);
      const customerStatuses = statuses.map(s => s.name);
      const customerSubTypes = subTypes.map(s => s.name);

      const schema = z.object({
        고객: z.string().min(1, "고객 이름을 입력하세요"),
        구분: z.enum(customerTypes as any as [string]),
        서브타입: z
          .enum(customerSubTypes as any as [string])
          .nullable()
          .optional(),
        상태: z.enum(customerStatuses as any as [string]),
        년도: z.coerce.number(),
        담당사: z.enum(companies as any as [string]),
        접수처: z.enum(sources as any as [string]),
        "세부 접수처": z.enum(sourceDetails as any as [string]),
        주소: z.string().min(1, "고객 주소를 입력하세요"),
        우편번호: z.coerce.number().nullable().optional(),
        "연락처 이름": z.string().nullable().optional(),
        "연락처 번호": z.string().nullable().optional(),
        "접수 일자": z.coerce.date().nullable().optional(),
        "설치 일자": z.coerce.date().nullable().optional(),
        "AS 일자": z.coerce.date().nullable().optional(),
        "AS 기간": z.coerce.date().nullable().optional(),
        추천인: z.string().nullable().optional(),
        태그: z
          .string()
          .transform(value => value.split(",").map(String))
          .pipe(z.string().array())
          .nullable()
          .optional(),
        비고: z.string().nullable().optional(),
      });

      const refined = customerRows.map(cr => {
        try {
          const data = cr as object;
          const dateParsed = {
            ...data,
            // @ts-ignore
            ["AS 일자"]: data["AS 일자"]
              ? // @ts-ignore
                getJsDateFromExcel(Number(data["AS 일자"]))
              : undefined,
            // @ts-ignore
            ["접수 일자"]: data["접수 일자"]
              ? // @ts-ignore
                getJsDateFromExcel(Number(data["접수 일자"]))
              : undefined,
            // @ts-ignore
            ["설치 일자"]: data["설치 일자"]
              ? // @ts-ignore
                getJsDateFromExcel(Number(data["설치 일자"]))
              : undefined,
            // @ts-ignore
            ["AS 기간"]: data["AS 기간"]
              ? // @ts-ignore
                getJsDateFromExcel(Number(data["AS 기간"]))
              : undefined,
          };

          const parsed = schema.parse(dateParsed);
          return parsed;
        } catch (error) {
          // console.log(error);
          return error;
        }
      });

      // @ts-expect-error
      const customers = refined.filter(r => typeof r == "object" && !r?.issues);
      const errorRows = refined
        .map((r, i) =>
          // @ts-expect-error
          r?.issues ? { index: i + 1, message: r?.toString() } : null
        )
        .filter(r => r);

      replace(customers as customerRow[]);
      // @ts-ignore
      setValue("errors", errorRows as { index: number; message: string }[]);
    };
    reader.readAsBinaryString(file);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [addCustomers] = useMutation(ADD_CUSTOMER_MANY);
  const [addContact] = useMutation(ADD_CONTACT);
  const [findContact] = useLazyQuery(FIND_CONTACT);
  const [addTags] = useMutation(ADD_CUSTOMER_TAG_MANY);
  const [getLastNum] = useLazyQuery(GET_CUSTOMER_LAST_NUM, {
    fetchPolicy: "network-only",
  });
  const { addHistory } = useAddCustomerHistory();

  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async data => {
    if (!params || !user) {
      setLoading(false);
      return;
    }

    const { customers } = data;
    const { companies, customerTags, sourceDetails, sources, users } = params;

    let lastNum = 0;

    const sorted = sort(customers).asc("년도");
    const refinedCustomers: (Partial<customer> | null)[] = (
      await Promise.all(
        sorted.map(async (c, i) => {
          const {
            고객,
            년도,
            구분,
            서브타입,
            담당사,
            상태,
            접수처,
            주소,
            추천인,
            비고,
            우편번호,
            태그,
          } = c;

          const companyId = companies.find(c => c.name === 담당사)?.id;
          const typeId = types.find(t => t.name === 구분)?.id;
          const subTypeId = subTypes.find(t => t.name === 서브타입)?.id;
          const statusId = statuses.find(s => s.name === 상태)?.id;
          const sourceId = sources.find(
            s => s.companyId == companyId && s.name == 접수처
          )?.id;
          const sourceDetail = sourceDetails.find(
            sd =>
              sd.sourceId == sourceId &&
              (sd.name == c["세부 접수처"] || sd.company?.name)
          );
          const sourceDetailId = sourceDetail?.id;
          const sourceCompanyId = sourceDetail?.companyId || undefined;

          if (
            [statusId, typeId, companyId, sourceId, sourceDetailId].some(
              n => !n
            )
          ) {
            addAlert({
              message: `${고객} - 필수 정보가 누락되어 등록되지 않습니다.`,
              type: "error",
            });
            return null;
          }

          const refereeId = users.find(u => u.name === 추천인)?.id || undefined;
          const year = 년도 > 2000 ? 년도 - 2000 : 년도;

          if (i == 0 || (sorted[i - 1] && sorted[i - 1].년도 != 년도)) {
            await getLastNum({
              variables: { year },
              onError(error) {
                console.log(error);
              },
              onCompleted(data) {
                lastNum = Number(data.customers[0]?.number || 0);
              },
            });
          }

          lastNum++;

          // handle Contact
          const contactIds: number[] = [];

          // TODO, check existing contacts;

          await (async () => {
            const name = c["연락처 이름"] || 고객;
            const number = c["연락처 번호"];

            const { data } = await findContact({
              variables: {
                name,
                number,
              },
            });

            if (data?.contacts && data.contacts[0]?.id) {
              contactIds.push(data.contacts[0].id);
              return;
            }

            await addContact({
              variables: {
                object: {
                  name: c["연락처 이름"] || 고객,
                  number: c["연락처 번호"],
                },
              },
              onCompleted(data) {
                const insertedContact = data.insert_contacts_one;

                contactIds.push(insertedContact.id);
              },
              onError(error) {
                console.log(error);
              },
              update(cache, { data }) {
                const insertedContact = data.insert_contacts_one;
                cache.modify({
                  fields: {
                    contacts(existing = [], { storeFieldName }) {
                      const filtered = hasuraFilter({
                        items: [insertedContact],
                        name: "contacts",
                        string: storeFieldName,
                      });
                      const newContactsRefs = filtered.map(sd =>
                        cache.writeFragment({
                          data: sd,
                          fragment: CONTACT_CORE_FIELDS,
                          fragmentName: "ContactCoreFields",
                        })
                      );
                      return [...existing, ...newContactsRefs];
                    },
                  },
                });
              },
            });

            return;
          })();

          // handle Tags
          const tagIds = [] as number[];
          await (async () => {
            if (!태그) {
              return;
            }

            const exTags = 태그
              .filter(t => customerTags.map(ct => ct.name).includes(t))
              .map(t => customerTags.find(ct => ct.name === t) as tag);
            tagIds.push(...exTags.map(t => t.id));
            const newTags = 태그.filter(
              t => !customerTags.map(ct => ct.name).includes(t)
            );

            if (newTags.length < 1) {
              return;
            }

            await addTags({
              variables: {
                objects: newTags,
              },
              onCompleted(data) {
                let insertedTags = (data.insert_customerTags?.returning ||
                  []) as tag[];

                if (data.insert_customerTags_one) {
                  insertedTags.concat(data.insert_customerTags_one);
                }

                tagIds.push(...insertedTags.map(c => c.id));
              },
              onError(error) {
                console.log(error);
              },
              update(cache, { data }) {
                let insertedTags = (data.insert_customerTags?.returning ||
                  []) as tag[];

                if (data.insert_customerTags_one) {
                  insertedTags.concat(data.insert_customerTags_one);
                }

                cache.modify({
                  fields: {
                    customerTags(existing = [], { storeFieldName }) {
                      const filtered = hasuraFilter({
                        items: insertedTags,
                        name: "customerTags",
                        string: storeFieldName,
                      });

                      const newTagsRefs = filtered.map(sd =>
                        cache.writeFragment({
                          data: sd,
                          fragment: CUSTOMER_TAG_FIELDS,
                          fragmentName: "CustomerTagFields",
                        })
                      );
                      return [...existing, ...newTagsRefs];
                    },
                  },
                });
              },
            });

            return;
          })();

          const customer: Partial<customer> = {
            name: 고객,
            typeId,
            subTypeId,
            statusId,
            companyId,
            year,
            address: 주소,
            postCode: 우편번호,
            installDate: c["설치 일자"]
              ? new Date(c["설치 일자"]).toISOString()
              : undefined,
            created_at: c["접수 일자"]
              ? new Date(c["접수 일자"]).toISOString()
              : undefined,
            registered_at: c["접수 일자"]
              ? new Date(c["접수 일자"]).toISOString()
              : undefined,
            asDate: c["AS 일자"]
              ? new Date(c["AS 일자"]).toISOString()
              : undefined,
            warrantyDate: c["AS 기간"]
              ? new Date(c["AS 기간"]).toISOString()
              : undefined,
            createdById: user.id,
            description: 비고,
            reads: [user.id],
            refereeId,
            sourceId,
            sourceDetailId,
            sourceCompanyId,
            tagIds,
            contactIds,
            number: lastNum,
          };
          return customer;
        })
      )
    ).filter(c => c !== null);

    if (refinedCustomers.length < 1) {
      addAlert({ message: "추가 가능한 데이터가 없습니다", type: "error" });
      return;
    }

    await addCustomers({
      variables: {
        objects: refinedCustomers,
      },
      update(cache, { data }) {
        let insertedCustomers = (data.insert_customers?.returning ||
          []) as customer[];

        if (data.insert_customers_one) {
          insertedCustomers.concat(data.insert_customers_one);
        }

        cache.modify({
          fields: {
            customers(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: insertedCustomers,
                name: "customers",
                string: storeFieldName,
              });

              const newCustomersRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: CUSTOMER_CORE_FIELDS,
                  fragmentName: "CustomerCoreFields",
                })
              );
              return [...existing, ...newCustomersRefs];
            },
          },
        });
      },
      onCompleted(data) {
        let insertedCustomers = (data.insert_customers?.returning ||
          []) as customer[];

        if (data.insert_customers_one) {
          insertedCustomers.concat(data.insert_customers_one);
        }

        insertedCustomers.forEach(c => {
          addHistory({
            customerId: c.id,
            type: "registration",
            message: "등록",
            statusId: c.statusId,
          });
        });
      },
    });

    addAlert({
      message: "모든 고객 등록이 완료되었습니다.",
      type: "success",
    });

    setLoading(false);
    return reset();
  });

  const { getFileURL } = useS3();

  const downloadTemplate = async () => {
    const url = await getFileURL("고객 추가 템플릿.xlsx");
    const link = document.createElement("a");
    link.href = url;

    try {
      link.click();
      link.remove();
    } catch (error) {
      link.remove();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className="flex-1 py-4 px-3 md:p-8 md:mx-10 flex flex-col md:drop-shadow-md md:bg-white overflow-x-clip gap-4"
      >
        {/* Header */}
        <header className="flex flex-row justify-between items-center">
          <h1 className="text-xl md:text-2xl">고객 일괄 등록</h1>
          <div
            className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
            onClick={goBack}
          >
            <ReplyIcon />
          </div>
        </header>
        {/* Body*/}
        <main className="flex flex-col gap-2">
          <div className={`overflow-x-auto ${scrollbar}`}>
            <Table size="small" className="min-w-[2000px]">
              <TableHead>
                <TableRow>
                  <TableCell>고객</TableCell>
                  <TableCell>구분</TableCell>
                  <TableCell>서브타입</TableCell>
                  <TableCell>상태</TableCell>
                  <TableCell>년도</TableCell>
                  <TableCell>담당사</TableCell>
                  <TableCell>접수처</TableCell>
                  <TableCell>세부 접수처</TableCell>
                  <TableCell>주소</TableCell>
                  <TableCell>우편번호</TableCell>
                  <TableCell>연락처 이름</TableCell>
                  <TableCell>연락처 번호</TableCell>
                  <TableCell>접수 일자</TableCell>
                  <TableCell>설치 일자</TableCell>
                  <TableCell>AS 일자</TableCell>
                  <TableCell>AS 기간</TableCell>
                  <TableCell>추천인</TableCell>
                  <TableCell>태그</TableCell>
                  <TableCell>비고</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((c, i) => {
                  if (c instanceof Error) {
                    return (
                      <TableRow key={c.id}>
                        <TableCell>Row {i + 1}</TableCell>
                        <TableCell colSpan={17}>
                          <div
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                          >
                            오류가 있어 임포트 할 수 없습니다.
                          </div>
                          <Popover
                            id="mouse-over-popover"
                            sx={{
                              pointerEvents: "none",
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            className="p-2"
                          >
                            <div className="whitespace-pre-wrap">
                              {c.message}
                            </div>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow key={i}>
                      <TableCell>{c.고객}</TableCell>
                      <TableCell>{c.구분}</TableCell>
                      <TableCell>{c.서브타입}</TableCell>
                      <TableCell>{c.상태}</TableCell>
                      <TableCell>{c.년도}</TableCell>
                      <TableCell>{c.담당사}</TableCell>
                      <TableCell>{c.접수처}</TableCell>
                      <TableCell>{c["세부 접수처"]}</TableCell>
                      <TableCell>{c.주소}</TableCell>
                      <TableCell>{c.우편번호}</TableCell>
                      <TableCell>{c["연락처 이름"]}</TableCell>
                      <TableCell>{c["연락처 번호"]}</TableCell>
                      <TableCell>
                        {c["접수 일자"]
                          ? dayjs(c["접수 일자"]).format("YYYY. MM. DD")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {c["설치 일자"]
                          ? dayjs(c["설치 일자"]).format("YYYY. MM. DD")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {c["AS 일자"]
                          ? dayjs(c["AS 일자"]).format("YYYY. MM. DD")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {c["AS 기간"]
                          ? dayjs(c["AS 기간"]).format("YYYY. MM. DD")
                          : ""}
                      </TableCell>
                      <TableCell>{c.추천인}</TableCell>
                      <TableCell>{c.태그?.map(t => t).join(", ")}</TableCell>
                      <TableCell>{c.비고}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {errorRows && errorRows.length > 0 && (
              <Table size="small" className="min-w-[2000px]">
                <TableHead>
                  <TableRow>
                    <TableCell>추가 불가 Row</TableCell>
                    <TableCell>오류 디테일</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorRows.map((err, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>Row {err.index}</TableCell>
                        <TableCell colSpan={17}>
                          <div
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                          >
                            형식 오류 {err.message.slice(0, 100)}...
                          </div>
                          <Popover
                            id="mouse-over-popover"
                            sx={{
                              pointerEvents: "none",
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            className="p-2"
                          >
                            <div className="whitespace-pre-wrap">
                              {err.message}
                            </div>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>

          <div className="flex flex-row justify-end gap-2 items-center mt-2">
            <Button
              variant="contained"
              color="success"
              startIcon={<SubscriptIcon />}
              onClick={downloadTemplate}
            >
              템플릿 다운로드
            </Button>
            <Button
              disabled={!loaded}
              startIcon={<CloudUpload />}
              component="label"
              title="XLSX, XLS 확장자"
              {...blackButtonOutlined}
            >
              <VisuallyHiddenInput
                type="file"
                onChange={handleFile}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              파일 {fields.length > 0 ? "변경" : "선택"}
            </Button>
            {fields.length > 0 && (
              <LoadingButton
                loading={loading}
                type="submit"
                {...blackButtonContained}
              >
                일괄 등록
              </LoadingButton>
            )}
          </div>
        </main>
      </form>
    </FormProvider>
  );
}
