import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthStore } from "../../../../store/authStore";
import { useMutation } from "@apollo/client";
import { AUTH_FIELDS } from "../../../../fragments/auth";
import { useAddAlert } from "../../../../store/alertStore";
import { ADD_AUTH } from "../../../../gqls/auth";
import AuthPermissionControl from "./authPermissionControl";
import AuthCustomerStatusPermissionControl from "./authCustomerStatusPermissionControl";
import AuthTaskStatusPermissionControl from "./authTaskStatusPermissionControl";
import { useCustomerStore } from "../../../../store/customerStore";
import { useTaskStore } from "../../../../store/taskStore";
import { blackButtonContained } from "../../../../classPresets";

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  displayName: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
  permissions: z.array(z.any()),
  customerStatusPermissions: z.array(z.any()),
  taskStatusPermissions: z.array(z.any()),
});

export default function NewAuth() {
  const [adding, setAdding] = useState(false);

  const { addAuth, auths } = useAuthStore();

  const { statuses: customerStatuses } = useCustomerStore();
  const { statuses: taskStatuses } = useTaskStore();

  const defaultValues = {
    name: "",
    description: "",
    displayName: "",
    permissions: [],
    customerStatusPermissions: customerStatuses.map(status => ({
      id: status.id,
      scope: "none",
    })),
    taskStatusPermissions: taskStatuses.map(status => ({
      id: status.id,
      scope: "none",
    })),
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors, touchedFields },
  } = methods;

  const [insert, { loading }] = useMutation(ADD_AUTH);

  const addAlert = useAddAlert();

  const onSubmit = handleSubmit(data => {
    if (
      auths.some(
        auth => auth.name == data.name || auth.displayName == data.displayName
      )
    ) {
      return addAlert({
        message: "같은 이름의 권한은 추가할 수 없습니다",
        type: "error",
      });
    }
    insert({
      variables: {
        object: {
          ...data,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "권한 추가에 실패했습니다",
          type: "error",
        });
      },
      onCompleted(data) {
        addAuth(data.insert_auths_one);
        setAdding(false);
        reset();
      },
      update(cache, { data: { insert_auths_one: newAuth } }) {
        cache.modify({
          fields: {
            auths(existing = []) {
              const newAuthRef = cache.writeFragment({
                data: newAuth,
                fragment: AUTH_FIELDS,
              });
              return [...existing, newAuthRef];
            },
          },
        });
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <div className="flex flex-row justify-end">
        <Button
          {...blackButtonContained}
          onClick={() => {
            setAdding(true);
          }}
        >
          권한 추가
        </Button>
      </div>
      <Dialog
        open={adding}
        onClose={() => {
          setAdding(false);
        }}
      >
        <DialogTitle>권한 추가</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.displayName && touchedFields.displayName}
              color="success"
              placeholder={
                errors.displayName?.message
                  ? errors.displayName?.message
                  : "권한 이름"
              }
              label="이름"
              {...register("displayName")}
            />
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.name && touchedFields.name}
              color="success"
              placeholder={
                errors.name?.message ? errors.name?.message : "DB용 영문 이름"
              }
              label="DB용 영문 이름"
              {...register("name")}
            />
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.description && touchedFields.description}
              color="success"
              placeholder={
                errors.description?.message
                  ? errors.description?.message
                  : "권한 설명"
              }
              label="설명"
              {...register("description")}
            />
            <AuthPermissionControl />
            <AuthCustomerStatusPermissionControl />
            <AuthTaskStatusPermissionControl />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setAdding(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={onSubmit}
          >
            추가
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
