import DeleteForever from "@mui/icons-material/DeleteForever";
import { taskTypeCheck } from "../../../../../../types/taskTypeCheck";
import { useDebounce } from "usehooks-ts";
import { useMutation } from "@apollo/client";
import { DELETE_TASK_TYPE_CHECK } from "../../../../../../gqls/taskCheck";
import { useTaskStore } from "../../../../../../store/taskStore";
import { cacheEvict } from "../../../../../../utils/cacheMethods";

interface props {
  check: taskTypeCheck;
  focused: boolean;
}

export default function TaskTypeCheckDelete({ check, focused }: props) {
  const [remove] = useMutation(DELETE_TASK_TYPE_CHECK);
  const { deleteTaskTypeCheck } = useTaskStore();

  const _delete = () => {
    const proceed = confirm(`${check.name} 확인 사항을 삭제하시겠습니까?`);
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id: check.id,
      },
      onCompleted() {
        deleteTaskTypeCheck(check.id);
      },
      update(cache) {
        cacheEvict({
          cache,
          id: check.id,
          __typename: "taskTypeChecks",
        });
      },
    });
  };

  const debouncedFocus = useDebounce(focused);

  return (
    <>
      {(focused || debouncedFocus) && (
        <div className={`cursor-pointer text-red-500`} onClick={_delete}>
          <DeleteForever />
        </div>
      )}
    </>
  );
}
