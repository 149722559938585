import { task } from "../../../../../../types/task";
import TaskAssignedInventory from "../../../../tasks/taskDetail/taskAssignedInventory";
import AddInventoryFromInstaller from "./addInventoryFromInstaller";

export default function EditInventoryFromInstaller({ task }: { task: task }) {
  if (task.invHistoryId) {
    return <TaskAssignedInventory task={task} />;
  }
  return <AddInventoryFromInstaller customerId={task.customerId} />;
}
