import { useCallback } from "react";
import { useAuthStore } from "../store/authStore";
import { useCustomerStore } from "../store/customerStore";
import { useAddAlert } from "../store/alertStore";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_BY_ID } from "../gqls/customer";
import { customer } from "../types/customer";
import useAddCustomerHistory from "./useAddCustomerHistory";

interface props {
  customer: customer;
  newStatId: number;
}
export default function useCustomerStatUpdate() {
  const { statuses } = useCustomerStore();
  const { user } = useAuthStore();

  const addAlert = useAddAlert();

  const alertNoAuth = () => {
    addAlert({
      message: "상태 변경을 위한 권한이 없습니다",
      type: "warning",
    });
  };

  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER_BY_ID);

  const { addHistory } = useAddCustomerHistory();
  const updateFunc = ({ customer, newStatId }: props) => {
    if (!user) {
      alertNoAuth();
      return;
    }

    const oldStatId = customer.statusId;

    const userPermissionNewStat = user?.auth.customerStatusPermissions?.find(
      s => s.id == newStatId
    );
    const userPermissionOldStat = user?.auth.customerStatusPermissions?.find(
      s => s.id == oldStatId
    );

    if (user.auth.name !== "admin") {
      if (!userPermissionNewStat || !userPermissionOldStat) {
        alertNoAuth();
        return;
      }
      if (
        userPermissionNewStat?.scope !== "edit" ||
        userPermissionOldStat?.scope !== "edit"
      ) {
        alertNoAuth();
        return;
      }
      if (!user.company) {
        alertNoAuth();
        return;
      }
      if (!user.company?.type.canHaveCustomers) {
        const companyStatPermissions = user.company.customerStatusIds;
        if (
          !companyStatPermissions ||
          !companyStatPermissions.includes(newStatId)
        ) {
          alertNoAuth();
          return;
        }
      }
    }

    const set = {
      statusId: newStatId,
      reads: [user.id],
      stat_updated_at: new Date().toISOString(),
    };

    updateCustomer({
      variables: {
        id: customer.id,
        set,
      },
      optimisticResponse: {
        update_customers_by_pk: {
          ...customer,
          ...set,
          status: statuses?.find(sl => sl.id == newStatId) || customer.status,
        },
      },
    });

    addHistory({
      customerId: customer.id,
      message: "상태 변경",
      type: "status",
      oldData: {
        statusId: oldStatId,
      },
      newData: {
        statusId: newStatId,
      },
      statusId: newStatId,
    });
  };

  return { updateStatus: useCallback(updateFunc, [user, statuses]), loading };
}
