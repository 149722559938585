import { create } from "zustand";

type loc = {
  lat: number;
  lng: number;
};

type agent = {
  summary: string;
  isBrowser: boolean;
  os: string;
  browser: string;
  isMobile: boolean;
  device: string;
};

type Store = {
  location?: loc;
  agent?: agent;
  ip?: string;
  ipLoaded: boolean;
  agentLoaded: boolean;
  locationLoaded: boolean;
  setIp?: (str: string) => void;
  setLocation?: (location: loc) => void;
  setAgent?: (agent: agent) => void;
};

export const useDeviceStore = create<Store>(set => ({
  location: {
    lat: 0,
    lng: 0,
  },
  locationLoaded: false,
  agentLoaded: false,
  ipLoaded: false,
  agent: {
    summary: "",
    isBrowser: false,
    os: "",
    browser: "",
    isMobile: false,
    device: "",
  },
  ip: "",
  setIp: str =>
    set(state => ({
      ...state,
      ip: str,
    })),
  setLocation: loc =>
    set(state => ({
      ...state,
      location: {
        lat: loc.lat,
        lng: loc.lng,
      },
    })),
  setAgent: agent =>
    set(state => ({
      ...state,
      agent,
    })),
}));
