import { invLocation, inventory, inventory_populated } from "../types/inv";

export const makeInvName = (inv: inventory_populated) => {
  return (
    inv.company?.name ||
    inv.user?.name ||
    `#${inv.customer?.year}-${inv.customer?.number} ${inv.customer?.name}`
  );
};

export const getInvLocation: (
  inv: inventory_populated
) => invLocation = inv => {
  let type: invLocation["type"] = "company";

  inv.customerId && (type = "customer");
  inv.userId && (type = "user");

  const id = inv[`${type}Id` as keyof inventory] as number;
  return {
    type,
    id,
  };
};
