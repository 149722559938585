import Modal from "@mui/material/Modal";
import React, { useRef } from "react";
import { modal } from "../../../../../classPresets";
import Button from "@mui/material/Button";
import { useFormContext } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";

interface props {
  signing: null | "customer" | "installer";
  setSigning: React.Dispatch<
    React.SetStateAction<null | "customer" | "installer">
  >;
}
export default function SignControl({ signing, setSigning }: props) {
  const handleClose = () => {
    setSigning(null);
  };

  const ref = useRef<SignatureCanvas | null>(null);
  const clear = () => ref.current?.clear();

  const { setValue } = useFormContext();

  const save = () => {
    if (!ref.current) {
      return;
    }
    const signature = ref.current.toDataURL();

    setValue(`${signing}Sign`, signature, {
      shouldDirty: true,
      shouldTouch: true,
    });
    handleClose();
  };

  return (
    <Modal open={signing !== null} onClose={handleClose}>
      <div
        className={`${modal} w-[524px] h-[524px] max-h-[50dvh] max-w-[90dvw] relative`}
      >
        <SignatureCanvas
          ref={ref}
          penColor="black"
          canvasProps={{ className: "w-full h-full" }}
        />
        <div className="absolute bottom-0 right-0 flex flex-row gap-2">
          <Button
            color="success"
            sx={{ color: "black" }}
            size="small"
            onClick={clear}
          >
            지우기
          </Button>
          <Button color="success" size="small" onClick={save}>
            저장
          </Button>
        </div>
      </div>
    </Modal>
  );
}
