import { gql } from "@apollo/client";
import { BIZTALK_FIELDS } from "./biztalk";
import { COMPANY_FIELDS } from "./company";

export const CUSTOMER_TAG_FIELDS = gql`
  fragment CustomerTagFields on customerTags {
    id
    name
  }
`;

export const CUSTOMER_TYPE_FIELDS = gql`
  fragment CustomerTypeFields on customerTypes {
    id
    name
    description
    color
    priority
  }
`;

export const CUSTOMER_STATUS_FIELDS = gql`
  fragment CustomerStatusFields on customerStatuses {
    id
    name
    description
    color
    priority
  }
`;

export const CUSTOMER_SUB_TYPE_FIELDS = gql`
  fragment CustomerSubTypeFields on customerSubTypes {
    id
    name
    description
    color
    priority
  }
`;

export const CUSTOMER_CORE_FIELDS = gql`
  ${CUSTOMER_STATUS_FIELDS}
  ${CUSTOMER_TYPE_FIELDS}
  ${CUSTOMER_SUB_TYPE_FIELDS}
  fragment CustomerCoreFields on customers {
    id
    uuid
    registered_at
    created_at
    updated_at
    stat_updated_at
    name
    address
    contactIds
    year
    number
    tagIds
    typeId
    subTypeId
    type {
      ...CustomerTypeFields
    }
    subType {
      ...CustomerSubTypeFields
    }
    companyId
    company {
      id
      name
    }
    statusId
    status {
      ...CustomerStatusFields
    }
    installDate
    asDate
    warrantyDate
    sourceCompanyId
    sourceCompany {
      id
      name
    }
    sourceDetailId
    sourceDetail {
      id
      name
      companyId
    }
    createdById
    creator {
      id
      name
    }
    refereeId
    referee {
      id
      name
    }
    description
    postCode
    sourceId
    source {
      id
      name
    }
    deleted
    reads
    inventories {
      id
      productId
      qty {
        statusId
        qty
      }
      subQty {
        statusId
        qty
      }
    }
    watching
  }
`;

export const CUSTOMER_HISTORY_CORE_FIELDS = gql`
  ${BIZTALK_FIELDS}
  fragment CustomerHistoryCoreFields on customerHistories {
    id
    customerId
    created_at
    type
    message
    statusId
    oldData
    newData
    byId
    biztalkId
    link
    taskId
    task {
      id
      assignedTo {
        id
        name
      }
    }
    biztalk {
      ...BiztalkFields
    }
  }
`;

export const CUSTOMER_HISTORY_FIELDS = gql`
  ${CUSTOMER_HISTORY_CORE_FIELDS}
  fragment CustomerHistoryFields on customerHistories {
    ...CustomerHistoryCoreFields
    status {
      name
      color
      priority
    }
    by {
      name
      email
    }
    customer {
      year
      name
      number
    }
  }
`;

export const CUSTOMER_FIELDS = gql`
  ${CUSTOMER_CORE_FIELDS}
  ${COMPANY_FIELDS}
  fragment CustomerFields on customers {
    ...CustomerCoreFields
    company {
      ...CompanyFields
    }
  }
`;

export const CUSTOMER_PRICE_FIELDS = gql`
  fragment CustomerPriceFields on customerPrices {
    id
    created_at
    updated_at
    customerId
    productSupply
    productGst
    installSupply
    installGst
  }
`;

export const PAYMENT_METHOD_FIELDS = gql`
  fragment PaymentMethodFields on paymentMethods {
    id
    name
    color
    icon
  }
`;

export const CUSTOMER_PAYMENT_FIELDS = gql`
  ${PAYMENT_METHOD_FIELDS}
  fragment CustomerPaymentFields on customerPayments {
    id
    created_at
    updated_at
    customerId
    enteredBy {
      id
      name
      email
    }
    amount
    comment
    method {
      ...PaymentMethodFields
    }
    received_at
  }
`;
