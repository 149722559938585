import Button from "@mui/material/Button";
import { useState } from "react";
import TagControl from "../../tagControl";
import { useMutation } from "@apollo/client";
import { ADD_CUSTOMER_TAG } from "../../../../../gqls/customer";
import { CUSTOMER_TAG_FIELDS } from "../../../../../fragments/customer";
import { blackButtonContained } from "../../../../../classPresets";

export default function NewTag() {
  const [adding, setAdding] = useState(false);
  const [add] = useMutation(ADD_CUSTOMER_TAG);

  const onSubmit = (data: { name: string }) => {
    add({
      variables: {
        object: data,
      },
      onCompleted() {
        setAdding(false);
      },
      update(cache, { data: { insert_customerTags_one } }) {
        cache.modify({
          fields: {
            customerTags(existing = []) {
              const newTaskTagRef = cache.writeFragment({
                data: insert_customerTags_one,
                fragment: CUSTOMER_TAG_FIELDS,
              });
              return [...existing, newTaskTagRef];
            },
          },
        });
      },
    });
  };

  return (
    <div className="flex flex-row justify-end">
      <Button
        {...blackButtonContained}
        onClick={() => {
          setAdding(true);
        }}
      >
        태그 추가
      </Button>
      {adding && (
        <TagControl
          onSubmit={onSubmit}
          dismiss={() => {
            setAdding(false);
          }}
        />
      )}
    </div>
  );
}
