import { typeOrStatus } from "../../../../../types/common";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { z } from "zod";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_SUB_TYPE_BY_ID } from "../../../../../gqls/customer";
import { useCustomerStore } from "../../../../../store/customerStore";
import DeleteForever from "@mui/icons-material/DeleteForever";

interface props {
  type: typeOrStatus;
  setMode: React.Dispatch<React.SetStateAction<"editing" | "deleting" | null>>;
}

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
  color: z.string().min(1, { message: "required" }),
});

export default function EditCustomerSubType({ type, setMode }: props) {
  const { name, description, color } = type;

  const { updateSubTypes } = useCustomerStore();

  const defaultValues = {
    name,
    description,
    color,
  };

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [update, { loading }] = useMutation(UPDATE_CUSTOMER_SUB_TYPE_BY_ID);

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: type.id,
        set: data,
      },
      onCompleted(updatedData) {
        const updatedType = updatedData.update_customerSubTypes_by_pk;
        updateSubTypes([updatedType]);
        setMode(null);
      },
    });
  });

  return (
    <Dialog
      open={true}
      onClose={() => {
        setMode(null);
      }}
    >
      <DialogTitle>고객 서브타입 수정</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.name && touchedFields.name}
            color="success"
            placeholder={
              errors.name?.message ? errors.name?.message : "타입 이름"
            }
            label="이름"
            {...register("name")}
          />

          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.description && touchedFields.description}
            color="success"
            placeholder={
              errors.description?.message ? errors.description?.message : "설명"
            }
            label="설명"
            {...register("description")}
          />
          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.color && touchedFields.color}
            color="success"
            placeholder={errors.color?.message ? errors.color?.message : "색상"}
            label="색상"
            type="color"
            defaultValue="#ffffff"
            {...register("color")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div
          className="text-red-500 cursor-pointer"
          onClick={() => {
            setMode("deleting");
          }}
        >
          <DeleteForever />
        </div>
        <Button
          sx={{ color: "black" }}
          onClick={() => {
            setMode(null);
          }}
        >
          취소
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={loading}
          variant="contained"
          color="success"
          onClick={onSubmit}
        >
          수정
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
