import DeleteForever from "@mui/icons-material/DeleteForever";
import { biztalk } from "../../../../../types/biztalk";
import { useMutation } from "@apollo/client";
import { DELETE_BIZTALK } from "../../../../../gqls/biztalk";
import { cacheEvict } from "../../../../../utils/cacheMethods";
import { useNavigate } from "react-router-dom";

interface props {
  biztalk: biztalk;
}

export default function BiztalkDeleteControl({ biztalk }: props) {
  const [remove] = useMutation(DELETE_BIZTALK);

  const navigate = useNavigate();

  const _remove = () => {
    const proceed = confirm("비즈톡 템플릿을 삭제하시겠습니까?");
    if (!proceed) {
      return;
    }

    remove({
      variables: {
        id: biztalk.id,
      },
      update(cache) {
        cacheEvict({ cache, id: biztalk.id, __typename: "biztalks" });
      },
      onCompleted() {
        navigate("/settings/biztalk");
      },
    });
  };

  return (
    <div className="cursor-pointer text-red-500" onClick={_remove}>
      <DeleteForever />
    </div>
  );
}
