import { create } from "zustand";
import { filter, tab, typeOrStatus } from "../types/common";
import { useCallback } from "react";
import { useAuthStore } from "./authStore";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_RELATED_CONFIGS } from "../gqls/customer";
import useGetRefereeUsers from "../hooks/useGetRefereeUsers";

type Store = {
  statuses: typeOrStatus[];
  setStatuses: (status: typeOrStatus[]) => void;
  updateStatuses: (statuses: typeOrStatus[]) => void;
  addStatus: (status: typeOrStatus) => void;
  deleteStatus: (id: number) => void;
  types: typeOrStatus[];
  setTypes: (types: typeOrStatus[]) => void;
  updateTypes: (types: typeOrStatus[]) => void;
  addType: (type: typeOrStatus) => void;
  deleteType: (id: number) => void;
  subTypes: typeOrStatus[];
  setSubTypes: (types: typeOrStatus[]) => void;
  updateSubTypes: (types: typeOrStatus[]) => void;
  addSubType: (type: typeOrStatus) => void;
  deleteSubType: (id: number) => void;
  tabs: tab[];
  setTabs: (tabs: tab[]) => void;
  currentTab?: string;
  setCurrentTab: (id: string) => void;
};

export const useCustomerStore = create<Store>(set => ({
  statuses: [],
  setStatuses: statuses =>
    set(state => ({
      ...state,
      statuses,
    })),
  updateStatuses(statuses) {
    set(state => ({
      ...state,
      statuses: state.statuses.map(
        stat => statuses.find(s => s.id == stat.id) || stat
      ),
    }));
  },
  addStatus(status) {
    set(state => ({
      ...state,
      statuses: state.statuses.concat(status),
    }));
  },
  deleteStatus(id) {
    set(state => ({
      ...state,
      statuses: state.statuses.filter(s => s.id != id),
    }));
  },
  types: [],
  setTypes: types =>
    set(state => ({
      ...state,
      types,
    })),
  updateTypes(types) {
    set(state => ({
      ...state,
      types: state.types.map(stat => types.find(s => s.id == stat.id) || stat),
    }));
  },
  addType(type) {
    set(state => ({
      ...state,
      types: state.types.concat(type),
    }));
  },
  deleteType(id) {
    set(state => ({
      ...state,
      types: state.types.filter(s => s.id != id),
    }));
  },
  subTypes: [],
  setSubTypes: subTypes =>
    set(state => ({
      ...state,
      subTypes,
    })),
  updateSubTypes(subTypes) {
    set(state => ({
      ...state,
      subTypes: state.subTypes.map(
        stat => subTypes.find(s => s.id == stat.id) || stat
      ),
    }));
  },
  addSubType(subType) {
    set(state => ({
      ...state,
      subTypes: state.subTypes.concat(subType),
    }));
  },
  deleteSubType(id) {
    set(state => ({
      ...state,
      subTypes: state.subTypes.filter(s => s.id != id),
    }));
  },
  tabs: localStorage.getItem("customerTabs")
    ? JSON.parse(localStorage.getItem("customerTabs")!)
    : [],
  setTabs: tabs => {
    localStorage.setItem("customerTabs", JSON.stringify(tabs));
    return set(state => ({
      ...state,
      tabs,
    }));
  },
  currentTab: localStorage.getItem("customerTab") || undefined,
  setCurrentTab: id => {
    localStorage.setItem("customerTab", id);
    return set(state => ({
      ...state,
      currentTab: id,
    }));
  },
}));

export const useGetFilters = () => {
  const { user } = useAuthStore();

  let { types, statuses, subTypes } = useCustomerStore();

  const { data, loading } = useQuery(GET_CUSTOMER_RELATED_CONFIGS, {
    onError(error) {
      console.log(error);
    },
  });

  const { refereeUsers } = useGetRefereeUsers();

  // const checkAuth = useCheckAuth();

  const companyTypes = user?.company?.customerTypeIds;

  if (companyTypes) {
    types = types.filter(type => companyTypes.includes(type.id));
  }

  const getFilters = () => {
    function* infinite() {
      let index = 0;

      while (true) {
        yield index++;
      }
    }

    const priorityGen = infinite();

    if (loading || !user || types.length == 0 || statuses.length == 0) {
      return undefined;
    }

    const defaultFilters: filter[] = [];

    // Types
    const options_type = types.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_type = types.map(t => t.id);

    const typeFilter: filter = {
      name: "구분",
      where: "typeId",
      type: "in",
      values: defaultValues_type,
      defaultValues: defaultValues_type,
      options: options_type,
      on: true,
      defaultOn: true,
      required: true,
      sort: {
        where: "type.priority",
        dir: "asc",
        priority: priorityGen.next().value || 0,
      },
      defaultSort: {
        where: "type.priority",
        dir: "asc",
        priority: priorityGen.next().value || 0,
      },
    };
    defaultFilters.push(typeFilter);

    // Companies
    const options_companies = data?.companies
      .filter(c => c.type.canHaveCustomers && c.active)
      .map(c => ({
        name: c.name,
        value: c.id,
      }));

    const defaultValues_companies = options_companies?.find(
      o => o.value == user?.company?.id
    )
      ? ([user?.company?.id] as number[])
      : [];

    const companyFilter: filter = {
      name: "회사",
      where: "companyId",
      type: "in",
      values: defaultValues_companies,
      defaultValues: defaultValues_companies,
      options: options_companies,
      on: true,
      defaultOn: true,
      required: true,
    };

    defaultFilters.push(companyFilter);

    // Source
    const sourceFilter: filter = {
      name: "접수처",
      where: "sourceId",
      type: "in",
      defaultOn: false,
      defaultValues: [],
      on: false,
      dependsOn: "회사",
      dependsValue: "companyId",
      required: false,
      values: [],
      options: data?.sources.map(s => ({
        name: s.name,
        value: s.id,
        data: s,
      })),
    };

    defaultFilters.push(sourceFilter);

    // SourceDetail
    const sourceDetailFilter: filter = {
      name: "세부접수처",
      where: "sourceDetailId",
      type: "in",
      defaultOn: false,
      defaultValues: [],
      on: false,
      dependsOn: "접수처",
      dependsValue: "sourceId",
      required: false,
      values: [],
      options: data?.sourceDetails.map(s => ({
        name: s.name || s.company.name,
        value: s.id,
        data: s,
      })),
    };

    defaultFilters.push(sourceDetailFilter);

    const refereeFilter: filter = {
      name: "추천인",
      where: "refereeId",
      type: "in",
      on: false,
      defaultOn: false,
      required: false,
      defaultValues: [],
      values: [],
      options: refereeUsers.map(u => ({
        name: u.name,
        value: u.id,
      })),
    };

    defaultFilters.push(refereeFilter);

    // Year
    const years = [];

    for (let i = 2018; i <= new Date().getFullYear(); i++) {
      years.push(i - 2000);
    }

    const options_year = years.map(y => ({
      name: y.toString(),
      value: y,
    }));

    const yearFilter: filter = {
      name: "년도",
      where: "year",
      type: "in",
      values: years.slice(-1),
      defaultValues: years.slice(-1),
      options: options_year,
      on: true,
      defaultOn: true,
      required: true,
      sort: {
        dir: "desc",
        priority: priorityGen.next().value || 0,
        where: "year",
      },
      defaultSort: {
        dir: "desc",
        priority: priorityGen.next().value || 0,
        where: "year",
      },
    };

    defaultFilters.push(yearFilter);

    // Status
    const options_status = statuses
      .filter(stat => {
        if (user.auth.name == "admin") {
          return true;
        }

        const userPermissionStat = user.auth.customerStatusPermissions.find(
          p => p.id == stat.id
        );
        if (!userPermissionStat || userPermissionStat?.scope == "none") {
          return false;
        }

        const companyStatPermissions = user.company?.customerStatusIds;
        if (
          user.company &&
          !user.company?.type.canHaveCustomers &&
          companyStatPermissions &&
          !companyStatPermissions.includes(stat.id)
        ) {
          return false;
        }

        return true;
      })
      .map(s => ({
        name: s.name,
        value: s.id,
      }));

    const defaultValues_status = statuses.map(s => s.id);

    const statusFilter: filter = {
      name: "상태",
      where: "statusId",
      type: "in",
      values: defaultValues_status,
      defaultValues: defaultValues_status,
      options: options_status,
      on: true,
      defaultOn: true,
      required: true,
      sort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "status.priority",
      },
      defaultSort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "status.priority",
      },
    };

    defaultFilters.push(statusFilter);

    // SubTypes
    const options_subType = subTypes.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_subType = subTypes.map(t => t.id);

    const subTypeFilter: filter = {
      name: "서브타입",
      where: "subTypeId",
      type: "in",
      values: defaultValues_subType,
      defaultValues: defaultValues_subType,
      options: options_subType,
      includeNull: true,
      includeNullOption: true,
      on: false,
      defaultOn: false,
      required: false,
      sort: {
        where: "subType.priority",
        dir: "asc",
        priority: priorityGen.next().value || 0,
      },
      defaultSort: {
        where: "subType.priority",
        dir: "asc",
        priority: priorityGen.next().value || 0,
      },
    };
    defaultFilters.push(subTypeFilter);

    // Registered_At
    const registered_at: filter = {
      name: "접수날짜",
      where: "registered_at",
      type: "range",
      rangeType: "date",
      values: {
        from: undefined,
        to: undefined,
      },
      defaultValues: {
        from: undefined,
        to: undefined,
      },
      on: false,
      defaultOn: false,
      required: false,
      sort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "registered_at",
      },
      defaultSort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "registered_at",
      },
    };

    defaultFilters.push(registered_at);

    // Created_At
    const created_at: filter = {
      name: "최초접수날짜",
      where: "created_at",
      type: "range",
      rangeType: "date",
      values: {
        from: undefined,
        to: undefined,
      },
      defaultValues: {
        from: undefined,
        to: undefined,
      },
      on: false,
      defaultOn: false,
      required: false,
      sort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "created_at",
      },
      defaultSort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "created_at",
      },
    };

    defaultFilters.push(created_at);

    const numberSort: filter = {
      name: "번호",
      where: "number",
      type: "range",
      rangeType: "number",
      sortOnly: true,
      values: {
        from: undefined,
        to: undefined,
      },
      defaultValues: {
        from: undefined,
        to: undefined,
      },
      on: false,
      defaultOn: false,
      required: false,
      sort: {
        dir: "off",
        priority: priorityGen.next().value || 0,
        where: "number",
      },
      defaultSort: {
        dir: "off",
        priority: priorityGen.next().value || 0,
        where: "number",
      },
    };

    defaultFilters.push(numberSort);

    // installDate
    const installDate: filter = {
      name: "설치일자",
      where: "installDate",
      type: "range",
      rangeType: "date",
      values: {
        from: undefined,
        to: undefined,
      },
      defaultValues: {
        from: undefined,
        to: undefined,
      },
      on: false,
      defaultOn: false,
      required: false,
      sort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "installDate",
      },
      defaultSort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "installDate",
      },
    };

    defaultFilters.push(installDate);

    // asDate
    const asDate: filter = {
      name: "수리일자",
      where: "asDate",
      type: "range",
      rangeType: "date",
      values: {
        from: undefined,
        to: undefined,
      },
      defaultValues: {
        from: undefined,
        to: undefined,
      },
      on: false,
      defaultOn: false,
      required: false,
      sort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "asDate",
      },
      defaultSort: {
        dir: "asc",
        priority: priorityGen.next().value || 0,
        where: "asDate",
      },
    };

    defaultFilters.push(asDate);

    const taskCount: filter = {
      name: "작업미정",
      where: "active_task_count",
      type: "count",
      defaultOn: false,
      on: false,
      defaultValues: 10,
      values: 10,
      required: false,
      options: [
        {
          value: 0,
          name: "미정",
        },
        {
          value: 1,
          name: "있음",
        },
      ],
    };

    defaultFilters.push(taskCount);

    // tags
    const options_tags = data?.customerTags.map(tag => ({
      name: tag.name,
      value: tag.id,
    }));

    const tagsFilter: filter = {
      name: "태그",
      where: "tagIds",
      type: "contains",
      values: [],
      defaultValues: [],
      options: options_tags,
      on: false,
      defaultOn: false,
      required: false,
    };

    defaultFilters.push(tagsFilter);

    // product
    const options_products = data?.products.map(p => ({
      name: p.name,
      value: p.id,
    }));

    const productFilter: filter = {
      name: "제품",
      where: "inventories.productId",
      type: "in",
      values: [],
      defaultValues: [],
      options: options_products,
      on: false,
      defaultOn: false,
      required: false,
    };

    defaultFilters.push(productFilter);

    return defaultFilters;
  };

  return {
    loading,
    getFilters: useCallback(getFilters, [loading, types, statuses, user]),
  };
};
