import React, { useState } from "react";
import { reportType } from "../../../../../../types/report";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTaskStore } from "../../../../../../store/taskStore";
import { useMutation } from "@apollo/client";
import {
  DELETE_REPORT_TYPE,
  UPDATE_REPORT_MANY,
} from "../../../../../../gqls/report";
import { cacheEvict } from "../../../../../../utils/cacheMethods";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface props {
  reportType: reportType;
  mode: "editing" | "deleting" | null;
  setMode: React.Dispatch<React.SetStateAction<"editing" | "deleting" | null>>;
}

export default function ReportTypeDelete({ reportType, mode, setMode }: props) {
  const { reportTypes, deleteReportType } = useTaskStore();

  const typesNotMe = reportTypes.filter(
    s => s.id != reportType.id && s.taskTypeId == reportType.taskTypeId
  );

  const [altType, setAltType] = useState(0);

  const [remove, { loading: removing }] = useMutation(DELETE_REPORT_TYPE);

  const [updateReports, { loading: updatingReports }] =
    useMutation(UPDATE_REPORT_MANY);

  const loading = removing || updatingReports;

  const _delete = async () => {
    const updates = [
      {
        where: { typeId: { _eq: reportType.id } },
        _set: {
          typeId: altType,
        },
      },
    ];
    updateReports({
      variables: { updates },
      onCompleted() {
        remove({
          variables: {
            id: reportType.id,
          },
          onCompleted() {
            deleteReportType(reportType.id);
            setMode(null);
          },
          update(cache) {
            cacheEvict({
              cache,
              id: reportType.id,
              __typename: "reportTypes",
            });
          },
        });
      },
    });
  };
  return (
    <Dialog
      open={mode == "deleting"}
      onClose={() => {
        setMode(null);
      }}
    >
      <DialogTitle>보구 구분 삭제</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <div>{reportType.name} 보고 구분을 삭제합니다</div>
          <div>삭제될 구분을 대신할 구분을 선택해주세요</div>
          <Select
            required
            size="small"
            color="success"
            value={altType}
            onChange={e => setAltType(Number(e.target.value))}
          >
            <MenuItem value={0}>대체 구분 선택</MenuItem>
            {typesNotMe.map(type => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: "black" }}
          onClick={() => {
            setMode(null);
          }}
        >
          취소
        </Button>
        <LoadingButton
          disabled={altType === 0}
          loading={loading}
          variant="contained"
          color="success"
          onClick={_delete}
        >
          삭제
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
