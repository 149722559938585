import { useParams } from "react-router-dom";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TASK_BY_PK, READ_TASK_BY_ID } from "../../../../gqls/task";
import { useEffect } from "react";
import TaskDetail from "./taskDetail";

interface props {
  nested?: boolean;
  noCustomerInfo?: boolean;
}

export default function TaskDetailWrapper({ nested, noCustomerInfo }: props) {
  const { id } = useParams();

  const checkAuth = useCheckAuth();

  const { data } = useQuery(GET_TASK_BY_PK, {
    variables: {
      id,
    },
    onError(error) {
      console.log(error);
    },
  });

  const task = data?.tasks_by_pk;

  const { user } = useAuthStore();

  const [readTask] = useMutation(READ_TASK_BY_ID);

  useEffect(() => {
    checkAuth({
      permissionName: "작업열람",
      companyIds: [
        task?.companyId || 0,
        task?.customer.companyId || 0,
        task?.customer.sourceCompanyId || 0,
      ],
      userId: task?.assignedToId,
    });

    if (!task?.reads || !user) {
      return;
    }
    if (task.reads.includes(user.id)) {
      return;
    }

    readTask({
      variables: {
        id: task.id,
        userId: user.id,
      },
    });
  }, [task, user]);

  if (!task) {
    return null;
  }

  return (
    <div className="flex-1 pb-5 flex flex-col gap-4">
      <div
        className={`flex-1 py-5 ${
          !nested && "md:p-8 md:mx-10 md:drop-shadow-md"
        } 
        ${nested && "pt-0"}
        flex flex-col gap-4 md:bg-white overflow-x-clip`}
      >
        <TaskDetail task={task} noCustomerInfo={noCustomerInfo} />
      </div>
    </div>
  );
}
