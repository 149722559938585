import { scrollbar } from "../../classPresets";
import { useAuthStore } from "../../store/authStore";
import Header from "./layouts/header/header";
import UserDetail from "./settings/users/userDetail/userDetail";

export default function Profile() {
  const { user } = useAuthStore();
  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar} md:px-10 bg-white md:bg-transparent px-3`}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <Header title="프로필" />
      <div className="flex-1 pb-5 flex flex-col gap-4">
        <div className="flex-1 py-5 md:p-8 flex flex-col md:drop-shadow-md md:bg-white overflow-x-clip">
          <UserDetail userId={user?.id} />
        </div>
      </div>
    </div>
  );
}
