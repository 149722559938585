import { gql } from "@apollo/client";

export const BIZTALK_FIELDS = gql`
  fragment BiztalkFields on biztalks {
    id
    tmpltCode
    message
    title
    messageType
    buttons
    quickReplies
  }
`;
