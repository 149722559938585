import { Dispatch, SetStateAction, useEffect } from "react";
import { combineFilters } from "../../../../utils/filterMethods";
import Filters from "../../common/filters/filters";
import { filter, view } from "../../../../types/common";
import Button from "@mui/material/Button";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import ViewListIcon from "@mui/icons-material/ViewList";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useGetFilters, useTaskStore } from "../../../../store/taskStore";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PieChartIcon from "@mui/icons-material/PieChart";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useCheckAuth } from "../../../../store/authStore";

interface props {
  setFiltering: Dispatch<SetStateAction<boolean>>;
  readAll: () => void;
}

export default function TaskFilters({ setFiltering, readAll }: props) {
  const { currentTab, tabs, setTabs, setCurrentTab } = useTaskStore();
  const { loading, getFilters } = useGetFilters();

  const defaultFilters = getFilters();
  const savedFilters = tabs.find(t => t.id == currentTab)?.filters;

  const setFilters = (filters: filter[]) => {
    setTabs(
      tabs.map(t => {
        if (t.id == currentTab) {
          return {
            ...t,
            filters,
          };
        }
        return t;
      })
    );
  };

  useEffect(() => {
    if (loading || !savedFilters || !defaultFilters) {
      return;
    }
    if (savedFilters.length == 0) {
      setTabs(
        tabs.map(t => {
          if (t.id == currentTab) {
            return {
              ...t,
              filters: defaultFilters,
            };
          }

          return t;
        })
      );
    }
  }, [currentTab, savedFilters, defaultFilters]);

  const combinedFilters = combineFilters({
    defaultFilters: defaultFilters || [],
    savedFilters,
  });

  const deleteTab = () => {
    const filteredTabs = tabs.filter(t => t.id !== currentTab);
    const tab = filteredTabs[0];
    setCurrentTab(tab.id);
    setTabs(filteredTabs);
    setFiltering(false);
  };

  const currentView = tabs.find(t => t.id == currentTab)?.view || "list";

  const changeView = (view: view) => {
    setTabs(
      tabs.map(t => {
        if (t.id == currentTab) {
          return {
            ...t,
            view,
          };
        }
        return t;
      })
    );
    setFiltering(false);
  };

  const checkAuth = useCheckAuth();

  const statisticsPermission = checkAuth({
    permissionName: "보고정산열람",
  });

  if (loading) {
    return null;
  }

  return (
    <div className="w-full md:w-[528px] md:min-w-[528px] max-w-full md:pl-10">
      <div className="flex flex-row gap-2 items-center py-1">
        <div className="flex flex-row gap-2 items-center flex-1 justify-end md:justify-start">
          <div
            className="cursor-pointer text-gray-500 hover:text-quezone flex flex-row items-center gap-1 text-sm"
            onClick={readAll}
          >
            <ChecklistIcon />
            전부읽음
          </div>
          {statisticsPermission && (
            <div
              className={`cursor-pointer flex flex-row items-center gap-1 text-sm ${
                currentView == "statistics" ? "text-quezone" : "text-gray-500"
              }`}
              onClick={() => changeView("statistics")}
            >
              <PieChartIcon />
              통계
            </div>
          )}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div
            className={`${
              currentView == "list" ? "text-quezone" : "text-gray-500"
            } cursor-pointer`}
            onClick={() => changeView("list")}
          >
            <ViewListIcon />
          </div>
          <div
            className={`${
              currentView == "calendar" ? "text-quezone" : "text-gray-500"
            } cursor-pointer`}
            onClick={() => changeView("calendar")}
          >
            <CalendarMonthOutlinedIcon />
          </div>
          <div
            className={`${
              currentView == "kanban" ? "text-quezone" : "text-gray-500"
            } cursor-pointer hidden md:block`}
            onClick={() => changeView("kanban")}
          >
            <ViewKanbanIcon />
          </div>
          <div
            className={`${
              currentView == "table" ? "text-quezone" : "text-gray-500"
            } cursor-pointer hidden md:block`}
            onClick={() => changeView("table")}
          >
            <TableChartIcon />
          </div>
        </div>
      </div>
      <Filters
        filters={combinedFilters}
        setFilters={setFilters}
        cb={() => {
          setFiltering(false);
        }}
      />
      {tabs.length > 1 && (
        <div className="mt-2 flex flex-row justify-end">
          <Button onClick={deleteTab} sx={{ color: "black" }}>
            탭 삭제
          </Button>
        </div>
      )}
    </div>
  );
}
