import { useEffect, useState } from "react";
import { useCustomerStore } from "../../../../store/customerStore";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import TabSelect from "./tabSelect";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import uuid from "react-uuid";
import CustomerFilters from "./customerFilters";
import Views from "./views/views";
import { refineFilters } from "../../../../utils/filterMethods";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_CUSTOMERS_CONDITIONAL,
  GET_CUSTOMERS_TOTAL_CONDITIONAL,
  READ_CUSTOMERS,
  STREAM_CUSTOMERS,
} from "../../../../gqls/customer";
import { cloneDeep } from "apollo-utilities";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import CustomerSearch from "./customerSearch";
import MobileSearch from "./mobileSearch";
import { useAuthStore } from "../../../../store/authStore";
import { exportData, uniqueById } from "../../../../utils/objectArrayMethods";
import { useNavStore } from "../../../../store/navStore";
import LinearProgress from "@mui/material/LinearProgress";
import { customer } from "../../../../types/customer";
import { GET_CONTACTS_BY_IDS } from "../../../../gqls/contact";

export default function CustomersMain() {
  const { tabs, setTabs, currentTab, setCurrentTab } = useCustomerStore();

  const selectedTab = tabs.find(t => t.id == currentTab);
  const [filtering, setFiltering] = useState(false);

  const addTab = (init?: boolean) => {
    const id = uuid();
    setTabs([
      ...tabs,
      {
        id,
        name: init ? "기본" : "새 탭",
        filters: [],
        cursor: new Date().toISOString(),
        view: "list",
      },
    ]);
    setCurrentTab(id);
    setFiltering(init ? false : true);
  };

  useEffect(() => {
    if (!selectedTab && tabs.length > 0) {
      setCurrentTab(tabs[0].id);
    }
    if (tabs.length == 0) {
      addTab(true);
    }
  }, [selectedTab?.id]);

  const filters = selectedTab?.filters;
  const cursor = selectedTab?.cursor;
  const refinedFilters = refineFilters(filters || []);

  const { where, order_by } = refinedFilters;
  const [totalLength, setTotalLength] = useState(0);

  const [getCustomers, { data, subscribeToMore, fetchMore, loading }] =
    useLazyQuery(GET_CUSTOMERS_CONDITIONAL);

  const [getCustomersTotal] = useLazyQuery(GET_CUSTOMERS_TOTAL_CONDITIONAL);

  const limit = 200;

  const updateCursor = () => {
    setTabs(
      tabs.map(t => {
        if (t.id == selectedTab?.id) {
          return { ...selectedTab, cursor: new Date().toISOString() };
        }
        return t;
      })
    );
  };

  useEffect(() => {
    if (filters?.length == 0) {
      setFiltering(true);
      return;
    }

    getCustomersTotal({
      variables: {
        where,
      },
      onCompleted(data) {
        setTotalLength(data.customers_aggregate?.aggregate?.count || 0);
      },
    });

    getCustomers({
      variables: {
        where,
        order_by,
        limit,
        offset: 0,
      },
      onCompleted() {
        updateCursor();
      },
      onError(error) {
        console.log(error);
      },
    });
  }, [filters, selectedTab?.id]);

  const customers = uniqueById(data?.customers || []) as customer[];

  const [fetchingMore, setFetchingMore] = useState(false);

  const loadMore = () => {
    const currentLength = customers.length;
    if (currentLength >= totalLength) return;

    setFetchingMore(true);
    fetchMore({
      variables: {
        where,
        order_by,
        offset: currentLength,
        limit,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setFetchingMore(false);
        if (!fetchMoreResult) return previousResult;
        return Object.assign({}, previousResult, {
          customers: [
            ...previousResult.customers,
            ...fetchMoreResult.customers,
          ],
        });
      },
    });
  };

  const canLoadMore = totalLength > customers.length;

  const ids = customers.map(c => c.id);

  useEffect(() => {
    let _and = where._and;
    delete _and.deleted;

    const subFilter = {
      _or: [
        _and,
        // { _and: where._and.filter(p => !Object.hasOwn(p, "deleted")) },
        { id: { _in: ids || [] } },
      ],
    };

    const unsubscribe = subscribeToMore({
      document: STREAM_CUSTOMERS,
      variables: {
        where: subFilter,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        updateCursor();
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.customers);
        const newFeedItem = subscriptionData.data.customers_stream;
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];
        const filterDeleted = [...newFeedItem, ...existing].filter(
          p => !p.deleted
        );
        return Object.assign({}, previous, {
          customers: filterDeleted,
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [where, ids]);

  const [readCustomers] = useMutation(READ_CUSTOMERS);

  const { user } = useAuthStore();

  const readAll = () => {
    if (!user) {
      return;
    }
    const customersToRead = customers
      .filter(c => !c.reads.includes(user.id))
      .map(c => c.id);

    if (customersToRead.length > 0) {
      readCustomers({
        variables: {
          ids: customersToRead,
          userId: user.id,
        },
      });
    }
  };

  const [getContactsByIds] = useLazyQuery(GET_CONTACTS_BY_IDS);

  const [exporting, setExporting] = useState(false);

  const exportToExcel = async () => {
    setExporting(true);

    const _data = [];

    const chunkSize = 50;

    for (let i = 0; i < customers.length; i += chunkSize) {
      const customerChunk = customers.slice(i, i + chunkSize);
      const _customersData = await Promise.all(
        customerChunk.map(async d => {
          const { contactIds } = d;

          const { data } = await getContactsByIds({
            variables: {
              ids: contactIds,
            },
          });

          const contacts = data?.contacts || [];

          return {
            id: d.id,
            year: d.year,
            number: d.number,
            name: d.name,
            type: d.type.name,
            status: d.status.name,
            address: d.address,
            postCode: d.postCode,
            description: d.description,
            contactName1: contacts[0]?.name,
            contactNumber1: contacts[0]?.number,
            contactName2: contacts[1]?.name,
            contactNumber2: contacts[1]?.number,
          };
        })
      );
      _data.push(..._customersData);
    }

    exportData(_data);

    setExporting(false);
  };

  const { open } = useNavStore();

  return (
    <div className="flex flex-col gap-2 bg-lightBG flex-1 px-3 md:p-0">
      {/* Header */}
      <div className="flex flex-col gap-1 md:px-10">
        <div className="hidden md:flex flex-row justify-end">
          <NavLink to="/customers/add">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                fontWeight: 500,
              }}
              color="success"
            >
              새 고객 등록
            </Button>
          </NavLink>
        </div>
        <div className="flex flex-row md:items-center gap-2 md:flex-wrap mt-4">
          {/* Tab Select */}
          <div className="flex flex-row items-center scrollbar-none overflow-x-scroll flex-1 pt-[4px] md:pt-[1px] p-[1px]">
            {tabs.map(tab => (
              <TabSelect tab={tab} key={tab.id} />
            ))}
            <div
              onClick={() => addTab()}
              className={`cursor-pointer text-gray-500 font-medium border-b-2 md:hover:text-quezone md:hover:ring-quezone border-b-transparent px-2 py-[1px] rounded-sm`}
            >
              +
            </div>
          </div>
          {/* Search, Add */}
          <div className="flex flex-row items-center gap-2 md:gap-3 justify-end">
            {/* Search DeskTop */}
            <div className="hidden md:flex">
              <CustomerSearch />
            </div>
            <MobileSearch />
            <div
              onClick={() => setFiltering(!filtering)}
              className={`bg-white p-[2px] md:p-[6px] rounded-sm ring-1 ring-gray-300 cursor-pointer md:hover:ring-quezone md:hover:text-quezone ${
                filtering && "text-quezone ring-quezone"
              }`}
            >
              <TuneIcon />
            </div>
            <div className="hidden md:flex flex-row items-center gap-3">
              {!loading && canLoadMore && (
                <Button
                  onClick={loadMore}
                  variant="outlined"
                  sx={{ backgroundColor: "white" }}
                  color="success"
                >
                  더 불러오기
                </Button>
              )}
              <div
                className={`bg-white p-[2px] md:p-[6px] rounded-sm ring-1 ring-gray-300 cursor-pointer text-gray-300`}
              >
                {customers.length !== totalLength && `${customers.length}/`}
                {totalLength < customers.length
                  ? customers.length
                  : totalLength}
              </div>
            </div>
            {(loading || fetchingMore) && (
              <CircularProgress size={18} sx={{ color: green[500] }} />
            )}
          </div>
        </div>
      </div>
      {/* Tabs */}
      {loading && (
        <div className="md:hidden pt-4">
          <LinearProgress color="success" />
        </div>
      )}
      <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap h-full">
        {filtering && (
          <CustomerFilters
            setFiltering={setFiltering}
            readAll={readAll}
            exportToExcel={exportToExcel}
            exporting={exporting}
          />
        )}
        {selectedTab && (
          <Views
            customers={customers}
            tab={selectedTab}
            loadMore={loadMore}
            loading={loading}
            fetchingMore={fetchingMore}
            totalLength={totalLength}
            canLoadMore={canLoadMore}
          />
        )}
      </div>
      {/* Mobile Add */}
      <div
        className={`${open && "hidden"} md:hidden absolute bottom-3 right-3`}
      >
        <NavLink to={"/customers/add"}>
          <Fab color="success" size="medium">
            <AddIcon />
          </Fab>
        </NavLink>
      </div>
    </div>
  );
}
