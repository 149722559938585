import { TypedDocumentNode, gql } from "@apollo/client";
import { Permission } from "../types/auth";
import { PERMISSION_FIELDS } from "../fragments/auth";

export const ADD_PERMISSION = gql`
  ${PERMISSION_FIELDS}
  mutation ADD_PERMISSION($object: permissions_insert_input!) {
    insert_permissions_one(object: $object) {
      ...PermissionFields
    }
  }
`;

export const UPDATE_PERMISSION_BY_ID: TypedDocumentNode<{
  update_permissions_by_pk: Permission;
}> = gql`
  ${PERMISSION_FIELDS}
  mutation UPDATE_PERMISSION_BY_ID($id: Int!, $set: permissions_set_input!) {
    update_permissions_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...PermissionFields
    }
  }
`;

export const DELETE_PERMISSION = gql`
  mutation DELETE_PERMISSION($id: Int!) {
    delete_permissions_by_pk(id: $id) {
      id
    }
  }
`;
