import { useFormContext, useWatch } from "react-hook-form";
import { action } from "../../../../../types/automation";
import TextField from "@mui/material/TextField";

export default function AutomationDateControl() {
  const { register, control } = useFormContext();

  const action: action = useWatch({
    name: "action",
    control,
  });

  const requiredActions: action[] = [
    "고객 설치날짜 변경",
    "고객 AS날짜 변경",
    "고객 보증기간 변경",
    "고객 접수날짜 변경",
  ];

  if (!requiredActions.includes(action)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="font-light">
        * 기준일로부터 날짜가 변경될 기한을 일 단위로 설정합니다. 예) 365=1년
        0=당일
      </div>
      <TextField
        className="shadow-md"
        autoComplete="off"
        color="success"
        type="number"
        size="small"
        margin="none"
        defaultValue={0}
        placeholder="당일"
        {...register("date")}
      />
    </div>
  );
}
