import { useState } from "react";
import { customer } from "../../../../../types/customer";
import { useAuthStore } from "../../../../../store/authStore";
import { useCustomerStore } from "../../../../../store/customerStore";
import useCustomerTypeUpdate from "../../../../../hooks/useCustomerTypeUpdate";
import MyBadge from "../../../../../comps/myBadge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { sort } from "fast-sort";

interface props {
  customer: customer;
}

export default function CustomerTypeControl({ customer }: props) {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { type } = customer;

  const { user } = useAuthStore();
  let { types } = useCustomerStore();

  types = types.filter(t => {
    if (t.id == type.id) {
      return true;
    }
    if (!user) {
      return false;
    }
    if (user.auth.name !== "admin") {
      if (user.company && !user.company.type.canHaveCustomers) {
        const companyTypePermissions = user.company.customerTypeIds;
        if (!companyTypePermissions?.includes(t.id)) {
          return false;
        }
      }
    }
    return true;
  });

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { updateType } = useCustomerTypeUpdate();

  const update = (typeId: number) => {
    updateType({
      customer,
      newTypeId: typeId,
    });

    setOpen(false);
  };
  return (
    <div className="relative">
      <div className="cursor-pointer hover:brightness-90" onClick={openMenu}>
        <MyBadge color={type.color} text={type.name} />
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {sort(types)
          .asc("priority")
          .map(t => {
            const selected = t.id == type.id;

            return (
              <MenuItem
                key={t.id}
                onClick={() => update(t.id)}
                disabled={selected}
              >
                {t.name}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
