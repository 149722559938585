import { Controller, FormProvider, useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import CompanyType from "./companyType";
import { company, basicCompanySchema } from "../../../../../types/company";
import { UPDATE_COMPANY_BY_ID } from "../../../../../gqls/company";
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";

interface props {
  company: company;
}

export default function BasicInfo({ company }: props) {
  const {
    name,
    email,
    phone,
    address,
    registration,
    noUsers,
    typeId,
    description,
  } = company;

  const defaultValues = {
    name,
    description,
    email,
    phone,
    address,
    registration,
    typeId,
    noUsers,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(basicCompanySchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isDirty },
    control,
    reset,
  } = methods;

  const [update, { loading }] = useMutation(UPDATE_COMPANY_BY_ID);

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: company.id,
        set: data,
      },
      onCompleted() {
        reset(data);
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        {/* Basic Infos */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
          {/* name, desc */}
          <div className="col-span-1 grid grid-cols-1 md:grid-cols-2 gap-x-2">
            <FormControl margin="dense">
              <label htmlFor="companyName">이름</label>
              <TextField
                error={errors.name && touchedFields.name}
                className="shadow-md col-span-1"
                size="small"
                id="companyName"
                autoComplete="off"
                type="text"
                color="success"
                placeholder="이름"
                {...register("name")}
              />
              <FormHelperText error={errors.name && touchedFields.name}>
                {touchedFields.name && errors.name?.message}
              </FormHelperText>
            </FormControl>
            <FormControl margin="dense">
              <label htmlFor="companyDesc">설명</label>
              <TextField
                error={errors.description && touchedFields.description}
                className="shadow-md col-span-1"
                size="small"
                id="companyDesc"
                autoComplete="off"
                type="text"
                color="success"
                placeholder="없음"
                {...register("description")}
              />
              <FormHelperText
                error={errors.description && touchedFields.description}
              >
                {touchedFields.description && errors.description?.message}
              </FormHelperText>
            </FormControl>
          </div>
          {/* ABN, Type */}
          <div className="col-span-1 grid grid-cols-1 md:grid-cols-2 gap-x-2">
            <CompanyType defaultType={company.type.id} />
            <FormControl margin="dense">
              <label htmlFor="companyABN">사업자 등록 번호</label>
              <TextField
                error={errors.registration && touchedFields.registration}
                className="shadow-md col-span-1"
                size="small"
                id="companyABN"
                autoComplete="off"
                type="text"
                color="success"
                placeholder="미상"
                {...register("registration")}
              />
              <FormHelperText
                error={errors.registration && touchedFields.registration}
              >
                {touchedFields.registration && errors.registration?.message}
              </FormHelperText>
            </FormControl>
          </div>
          {/* address */}
          <div className="col-span-1">
            <FormControl margin="dense" fullWidth>
              <label htmlFor="companyAddress">주소</label>
              <TextField
                error={errors.address && touchedFields.address}
                className="shadow-md"
                size="small"
                id="companyAddress"
                autoComplete="off"
                type="text"
                color="success"
                placeholder="미상"
                {...register("address")}
              />
              <FormHelperText error={errors.address && touchedFields.address}>
                {touchedFields.address && errors.address?.message}
              </FormHelperText>
            </FormControl>
          </div>
          {/* email, phone */}
          <div className="col-span-1 grid grid-cols-1 md:grid-cols-2 gap-x-2">
            <FormControl margin="dense">
              <label htmlFor="companyEmail">메일</label>
              <TextField
                error={errors.email && touchedFields.email}
                className="shadow-md col-span-1"
                size="small"
                id="companyEmail"
                autoComplete="off"
                type="text"
                color="success"
                placeholder="미상"
                {...register("email")}
              />
              <FormHelperText error={errors.email && touchedFields.email}>
                {touchedFields.email && errors.email?.message}
              </FormHelperText>
            </FormControl>
            <FormControl margin="dense">
              <label htmlFor="companyPhone">연락처</label>
              <TextField
                error={errors.phone && touchedFields.phone}
                className="shadow-md col-span-1"
                size="small"
                id="companyPhone"
                autoComplete="off"
                type="text"
                color="success"
                placeholder="없음"
                {...register("phone")}
              />
              <FormHelperText error={errors.phone && touchedFields.phone}>
                {touchedFields.phone && errors.phone?.message}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-2 items-center w-full mt-2 flex-wrap">
          <Controller
            name="noUsers"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox color="success" checked={field.value} {...field} />
                }
                label="사용자 가입 불가"
              />
            )}
          />
          {isDirty && (
            <div className="flex flex-row gap-2 items-center justify-end flex-1">
              <Button
                color="success"
                onClick={() => {
                  reset();
                }}
              >
                취소
              </Button>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                color="success"
                sx={{
                  backgroundColor: "black",
                  fontWeight: 500,
                }}
              >
                수정
              </LoadingButton>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
