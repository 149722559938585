import InvActions from "./invActinos/InvActions";
import InvStats from "./invStats/invStats";
import Products from "./products/products";

export default function Inventories() {
  return (
    <div className="flex flex-col gap-2 py-4 md:p-8">
      <Products />
      <InvStats />
      <InvActions />
    </div>
  );
}
