import { useInvStore } from "../../../../../../store/invStore";
import { inventory, inventory_with_product } from "../../../../../../types/inv";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Face from "@mui/icons-material/Face";
import InvQty from "../../../../inventory/companyList/companyInventory/invQty";

interface props {
  inventory: inventory_with_product;
  customerInvs?: inventory[];
  handleClick: (inventory: inventory_with_product) => void;
}

export default function AvailableInventoryItem({
  inventory,
  handleClick,
  customerInvs,
}: props) {
  const { invActions } = useInvStore();
  const availableInvActions = invActions.filter(a => a.type == "assign");

  const totalQty = inventory.qty.reduce<number>((prev, cur) => {
    if (
      (availableInvActions.flatMap(a => a.fromStatIds).includes(cur.statusId) &&
        !cur.subInvId) ||
      !customerInvs?.some(i => i.id == cur.subInvId)
    ) {
      return prev + cur.qty;
    }

    return prev;
  }, 0);

  if (totalQty === 0) {
    return null;
  }

  return (
    <div
      onClick={() => handleClick(inventory)}
      className="flex flex-col w-[180px] relative shadow-md ring-1 ring-gray-200 bg-white hover:brightness-90 cursor-pointer"
    >
      <div className="absolute -left-3 -top-3 py-1 px-3 rounded-2xl text-white bg-black text-sm font-light">
        {totalQty}개
      </div>
      <img
        className="h-[120px] object-cover object-top"
        src={inventory.product?.photo}
        alt={inventory.product?.name}
      />

      <div className="flex flex-col gap-1 p-2">
        {/* name */}
        <div className="font-medium text-sm truncate">
          {inventory.product?.name}
        </div>
        {/* S/N */}
        {/* <div className="truncate font-light text-sm">
          {inventory.product?.serial}
        </div> */}
        {/* location */}
        <div className="text-quezone truncate text-sm">
          {inventory.company ? (
            <ApartmentIcon
              sx={{ width: 16, height: 16, marginBottom: "2px" }}
            />
          ) : (
            <Face sx={{ width: 16, height: 16, marginBottom: "2px" }} />
          )}{" "}
          {inventory.company?.name || inventory.user?.name}
        </div>
        {/* InvQty */}
        <div className="flex flex-row gap-2">
          <InvQty
            inv={inventory}
            subInvIds={customerInvs?.map(i => i.id)}
            small
          />
        </div>
      </div>
    </div>
  );
}
