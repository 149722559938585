import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAuthStore } from "../../../../store/authStore";
import { useNavigate } from "react-router-dom";
import useUserAvatar from "../../../../hooks/useUserAvatar";
import { useNotificationStore } from "../../../../store/notificationStore";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import Popover from "@mui/material/Popover";
import Notifications from "../notifications/notifications";

interface props {
  title: string;
  children?: React.ReactNode;
}

export default function Header({ title, children }: props) {
  const { user } = useAuthStore();

  const navigate = useNavigate();

  const { avatar } = useUserAvatar(Number(user?.id));
  const { notifications } = useNotificationStore();

  const notificationNotGranted =
    !("Notification" in window) || Notification?.permission !== "granted";
  const off = user?.pushPref == false || notificationNotGranted;

  const goToProfile = () => {
    navigate("/profile");
  };

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (off) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <header className="hidden md:flex flex-row justify-between items-center py-3 md:py-8">
      <div className="flex flex-row items-center gap-2">
        <div className="px-[6px] py-[10px] rounded-md bg-quezone md:hidden" />
        <div className="capitalize font-bold text-xl md:text-5xl pt-1 md:pt-2">
          {title}
        </div>
      </div>
      <div className="hidden md:flex flex-row items-center gap-3">
        <div
          className={`rounded-sm ring-1 ring-gray-300 bg-white cursor-poiner! hover:ring-quezone hover:text-quezone ${
            open && "ring-quezone"
          } w-12 h-12 flex flex-row justify-center items-center relative`}
          onClick={handleOpen}
        >
          {off ? (
            <Tooltip
              title={` ${
                notificationNotGranted
                  ? "알림이 지원되지 않거나 권한 허용이 되지 않았습니다. 앱을 설치하신 후 권한을 허용해주세요"
                  : "알림을 꺼두신 상태입니다, 프로필에서 설정을 변경하세요."
              }`}
            >
              <NotificationsOffIcon sx={{ fontSize: 38 }} />
            </Tooltip>
          ) : (
            <NotificationsIcon
              sx={{ fontSize: 38 }}
              className={`text-gray-700 ${open && "text-quezone"}`}
            />
          )}

          {!off && !open && notifications.length > 0 && (
            <div className="absolute right-[10px] top-[9px] bg-red-500 p-[5px] rounded-full" />
          )}
        </div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ marginTop: 1 }}
        >
          <Notifications />
        </Popover>
        <div
          className={`rounded-sm ring-1 ring-gray-300 cursor-pointer hover:ring-quezone hover:text-quezone text-white w-12 h-12 flex flex-row justify-center items-center text-lg ${
            !avatar && "p-1"
          }`}
          onClick={goToProfile}
          style={{
            backgroundColor: avatar ? "transparent" : user?.color,
          }}
        >
          {avatar ? (
            <img
              className="rounded-sm w-full h-full object-cover"
              src={avatar}
            />
          ) : (
            user?.name[0]
          )}
        </div>
      </div>
      {children}
    </header>
  );
}
