import { useQuery } from "@apollo/client";
import { GET_CORRESPONDENCES_CONDITIONAL } from "../../../../gqls/correspondence";
import Correspondence from "./correspondence";
import { scrollbar } from "../../../../classPresets";
import { sort } from "fast-sort";

interface props {
  customerId: number;
  taskId?: number;
}
export default function Correspondences({ customerId, taskId }: props) {
  const taskIdWhere = taskId
    ? {
        _eq: taskId,
      }
    : { _is_null: true };

  const { data } = useQuery(GET_CORRESPONDENCES_CONDITIONAL, {
    variables: {
      where: {
        customerId: {
          _eq: customerId,
        },
        taskId: taskIdWhere,
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const correspondences = sort(data?.correspondences || []).desc("date");

  return (
    <div
      className={`w-full h-full flex flex-col gap-2 min-h-[50px] max-h-[100dvh] overflow-y-auto ${scrollbar}`}
    >
      {correspondences.length === 0 && (
        <div className="px-3 md:px-0 pt-3 md:pt-0 text-sm">
          {taskId ? "" : "상담 "} 내역이 없습니다
        </div>
      )}
      {correspondences.map(c => (
        <Correspondence key={c.id} correspondence={c} />
      ))}
    </div>
  );
}
