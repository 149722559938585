import { memo, useEffect, useRef, useState } from "react";
import { useCheckAuth } from "../../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { blackButtonOutlined, scrollbar } from "../../../../classPresets";
import Header from "../../layouts/header/header";
import VisuallyHiddenInput from "../../../../comps/hiddenInput";
import Button from "@mui/material/Button";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { DataGrid } from "@mui/x-data-grid";
import { useLazyQuery } from "@apollo/client";
import { isMongoTask, mongoTask } from "../types";
import { GET_CUSTOMERS_CONDITIONAL_M } from "../../../../gqls/customer";
import { exportData } from "../../../../utils/objectArrayMethods";
import useTaskConvert from "./useTaskConvert";

export default function TaskMigration() {
  const checkAuth = useCheckAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!checkAuth({ isPeter: true })) {
      navigate(-1);
    }
  }, []);

  const [getCustomers, { data: exCustomerData }] = useLazyQuery(
    GET_CUSTOMERS_CONDITIONAL_M
  );
  const exCustomers = exCustomerData?.customers || [];

  const [tasks, setTasks] = useState<mongoTask[]>([]);
  const done = useRef<string[]>([]);

  const handleFile: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const files = e.target.files;
    if (!files || !files[0]) {
      return;
    }
    const file = files[0];

    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = async e => {
      const result = JSON.parse(e.target?.result as string);

      if (!Array.isArray(result)) {
        return;
      }

      const _tasks: mongoTask[] = result.filter(r => isMongoTask(r));

      await getCustomers({
        variables: {
          where: {
            uuid: {
              _in: _tasks.map(t => t.customer),
            },
          },
        },
      });

      if (result) {
        setTasks(_tasks);
      }
    };
  };
  const columns = [
    { field: "id", width: 250, headerName: "id", hide: true },
    { field: "statusId", width: 90, headerName: "statId" },
    { field: "typeId", width: 90, headerName: "typeId" },
    { field: "companyId", width: 90, headerName: "compId" },
    { field: "customerId", width: 100, headerName: "customerId" },
    { field: "by", width: 100, headerName: "by" },
    { field: "description", width: 100, headerName: "desc" },
    { field: "proposed", width: 100, headerName: "제안일" },
    { field: "date", width: 100, headerName: "설치일" },
  ];

  const rows = tasks.map(t => ({
    id: t._id,
    statusId: t.statusId,
    typeId: t.typeId,
    companyId: t.companyId,
    customerId: t.customer,
    by: t.by,
    description: t.description,
    proposed: t.proposed,
    date: t.date,
  }));

  const { convert, convertCorrespondence } = useTaskConvert();

  const convertAll = () => {
    const converted = tasks
      .map(task => convert(task, exCustomers))
      .filter(task => task);
    exportData(converted, "tasks");
  };

  const convertOne = async (id: string) => {
    const task = tasks.find(c => c._id == id);

    console.log(task);
    if (!task) {
      return;
    }
    const converted = convert(task, exCustomers);

    console.log(converted);
    // exportData(converted, "customers");
  };

  const convertCorrespondenceAll = () => {
    const converted = tasks
      .map(task => convertCorrespondence(task))
      .filter(correspondence => correspondence);
    exportData(converted, "correspondences");
  };

  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar} md:px-10 bg-white md:bg-transparent`}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <Header title="Mongo 작업이주" />
      <div className="bg-white p-4 rounded-md shadow-md mb-4">
        {tasks.length == 0 && <p>No file uploaded yet</p>}
        {tasks.length > 0 && (
          <MemoizedTable
            columns={columns}
            rows={rows}
            handleRowClick={convertOne}
            done={done.current}
          />
        )}
      </div>
      <div className="flex flex-row justify-between items-center gap-2 mb-4">
        <div>
          <div className="flex flex-row gap-4 text-sm">
            <p>
              {done.current.length}/{tasks.length}
            </p>
            {tasks.length > 0 && (
              <p className="text-quezone">
                {((done.current.length / tasks.length) * 100).toFixed(1)}%
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <Button startIcon={<CloudUpload />} color="success" component="label">
            <VisuallyHiddenInput
              type="file"
              accept="application/json"
              onChange={handleFile}
            />
            파일 선택
          </Button>
          {tasks.length > 0 && (
            <>
              <Button {...blackButtonOutlined} onClick={convertAll}>
                CONVERT
              </Button>
              <Button
                {...blackButtonOutlined}
                onClick={convertCorrespondenceAll}
              >
                CONVERT CORRESPONDENCE
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

interface props {
  rows: any[];
  columns: any[];
  handleRowClick: (id: string) => void;
  done: string[];
}

const Table = ({ rows, columns, handleRowClick }: props) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      sx={{ backgroundColor: "white" }}
      onRowClick={e => {
        handleRowClick(e.id as string);
      }}
    />
  );
};

const MemoizedTable = memo(Table, (prev, next) => {
  if (next.done.find(id => !prev.done.includes(id))) {
    return false;
  }
  return true;
});
