import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DELETE_INV_HISTORY,
  GET_INV_HISTORY_BY_PK,
  GET_INV_QTY_CONDITIONAL,
  UDPATE_INV_QTY_BY_ID,
} from "../gqls/inv";
import { cacheEvict } from "../utils/cacheMethods";
import { useAddAlert } from "../store/alertStore";

export default function useCancelHistoryChanges() {
  const [getInvQty] = useLazyQuery(GET_INV_QTY_CONDITIONAL, {
    fetchPolicy: "network-only",
  });
  const [updateInvQty] = useMutation(UDPATE_INV_QTY_BY_ID);
  const [deleteHistory] = useMutation(DELETE_INV_HISTORY);
  const [getInvHistory] = useLazyQuery(GET_INV_HISTORY_BY_PK);

  const addAlert = useAddAlert();

  const cancelHistoryChanges = async (id: number) => {
    const { data } = await getInvHistory({
      variables: {
        id,
      },
    });

    const history = data?.invHistories_by_pk;

    if (!history) {
      addAlert({
        message: "재고 기록을 찾을 수 없습니다",
        type: "warning",
      });
      return;
    }

    await Promise.all(
      history.changes.map(async change => {
        const { diff, invId, statusId } = change;

        const invQtyRes = await getInvQty({
          variables: {
            where: {
              invId: { _eq: invId },
              statusId: { _eq: statusId },
            },
          },
        });

        const invQty = invQtyRes.data?.invQty[0];

        if (!invQty) {
          return;
        }

        await updateInvQty({
          variables: {
            id: invQty.id,
            set: {
              qty: invQty.qty - diff,
            },
          },
        });

        return;
      })
    );

    await deleteHistory({
      variables: {
        id: history.id,
      },
      update: cache => {
        cacheEvict({
          cache,
          id: history.id,
          __typename: "invHistories",
        });
      },
    });

    addAlert({
      message: "재고 기록을 복구했습니다",
      type: "success",
    });
    return;
  };

  return cancelHistoryChanges;
}
