import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useInvStore } from "../../../../../store/invStore";
import { invAction } from "../../../../../types/inv";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export default function InvActionStatControl({
  invAction,
}: {
  invAction?: invAction;
}) {
  const { control } = useFormContext();
  let { invStatuses } = useInvStore();

  const type: invAction["type"] = useWatch({
    name: "type",
    control,
  });

  invStatuses = invStatuses.filter(stat => {
    if (type == "delete" && stat.available) {
      return false;
    }
    return true;
  });

  if (type == "delete") {
    return null;
  }

  return (
    <Controller
      name="statusId"
      control={control}
      defaultValue={invAction?.statusId || 0}
      render={({ field }) => (
        <FormControl size="small" color="success" margin="dense">
          <InputLabel>재고 상태 변경</InputLabel>
          <Select
            className="shadow-md"
            value={field.value || ""}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            label="재고 상태 변경"
            disabled={field.disabled}
          >
            {invStatuses.map(stat => (
              <MenuItem key={stat.id} value={stat.id}>
                {stat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
