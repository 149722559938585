import { useNavigate } from "react-router-dom";
import { useCheckAuth } from "../../../../store/authStore";
import { task } from "../../../../types/task";
import ReplyIcon from "@mui/icons-material/Reply";
import TaskBasicInfo from "./taskBasicInfo";
import TaskTags from "./taskTags";
import Button from "@mui/material/Button";
import TaskInventory from "./taskInventory";
import TaskFiles from "./taskFiles";
import TaskCorrespondences from "./taskCorrespondences";
import OtherTasks from "./otherTasks";
import { useMutation } from "@apollo/client";
import { DELETE_TASK, UPDATE_TASK_BY_ID } from "../../../../gqls/task";
import { cacheEvict } from "../../../../utils/cacheMethods";
import TaskReport from "./taskReport";
import { blackButtonOutlined } from "../../../../classPresets";
import useAddCustomerHistory from "../../../../hooks/useAddCustomerHistory";
import TaskAssignedInventory from "./taskAssignedInventory";
import { useAddAlert } from "../../../../store/alertStore";
import useCancelHistoryChanges from "../../../../hooks/useCancelHistoryChanges";

interface props {
  task: task;
  noCustomerInfo?: boolean;
}

export default function TaskDetail({ task, noCustomerInfo }: props) {
  const { customer, customerId, id, assignedToId } = task;

  const navigate = useNavigate();

  const goToEdit = () => {
    navigate(`/customers/detail/${customerId}/progress/taskEdit/${id}`);
  };

  const checkAuth = useCheckAuth();

  const companyIds = [
    customer.companyId || 0,
    customer.sourceCompanyId || 0,
    task.companyId,
  ];

  const canEdit = checkAuth({
    permissionName: "고객_작업수정",
    companyIds,
  });

  const fullNum = `#${customer.year}-${customer.number}`;

  const customerViewPermission = checkAuth({
    permissionName: "고객_열람",
    companyIds,
  });

  const [_delete] = useMutation(DELETE_TASK);

  const [update] = useMutation(UPDATE_TASK_BY_ID);

  const { addHistory } = useAddCustomerHistory();

  const addAlert = useAddAlert();
  const reclaimInventory = useCancelHistoryChanges();

  const remove = async () => {
    const proceed = confirm("작업을 삭제하시겠습니까?");
    if (!proceed) {
      return;
    }

    await update({
      variables: {
        id: task.id,
        set: {
          deleted: true,
        },
      },
    });

    const { data, errors } = await _delete({
      variables: {
        id: task.id,
      },
      update(cache) {
        cacheEvict({
          cache,
          id: task.id,
          __typename: "tasks",
        });
      },
    });

    if (data) {
      await (async () => {
        if (task.invHistoryId) {
          const proceed = confirm("작업에서 할당된 재고를 회수하시겠습니까?");
          if (!proceed) {
            return;
          }
          await reclaimInventory(task.invHistoryId);
          return;
        }
      })();

      addAlert({
        message: "작업을 삭제했습니다",
        type: "success",
      });
      navigate(-1);
      addHistory({
        customerId: task.customerId,
        message: `${task.type.name} 작업 삭제`,
        type: "task",
      });
    }

    if (errors) {
      addAlert({
        message: "작업을 삭제할 수 없습니다",
        type: "error",
      });
      console.log(errors);
    }
  };

  return (
    <>
      {/* Header */}
      {!noCustomerInfo && (
        <div className="flex flex-row justify-between items-center gap-4 px-3 md:px-0 flex-wrap">
          <h1
            className={`${
              customerViewPermission && "hover:text-quezone cursor-pointer"
            } truncate flex-1 w-0`}
            onClick={() => {
              navigate(`/customers/detail/${customerId}`);
            }}
          >
            {fullNum} {customer.name}
          </h1>
          <div
            className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ReplyIcon />
          </div>
        </div>
      )}
      {/* Body */}
      <div
        className={`grid grid-cols-1 ${
          !noCustomerInfo && "lg:grid-cols-2"
        } md:gap-4 grid-flow-dense`}
      >
        {/* Left */}
        <div className="col-span-1 flex flex-col gap-4">
          <TaskBasicInfo task={task} noCustomerInfo={noCustomerInfo} />
          <TaskCorrespondences task={task} />
        </div>
        {/* Right */}
        <div className="col-span-1 flex flex-col gap-4">
          <TaskTags task={task} />
          {!noCustomerInfo && <TaskInventory task={task} />}
          <TaskAssignedInventory task={task} />
          {!noCustomerInfo && (
            <>
              <OtherTasks task={task} />
              <TaskFiles task={task} />
            </>
          )}
          <TaskReport task={task} />
        </div>
      </div>
      {/* Footer */}

      <div className="flex flex-row gap-x-2 gap-y-4 items-center justify-end mt-4 flex-wrap px-3 md:px-0">
        {canEdit && (
          <>
            <Button
              variant="outlined"
              sx={{
                fontWeight: 400,
              }}
              color="error"
              onClick={remove}
              size="small"
            >
              삭제
            </Button>
            <Button {...blackButtonOutlined} onClick={goToEdit} size="small">
              수정
            </Button>
          </>
        )}
      </div>
    </>
  );
}
