import { useLazyQuery } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Avatar from "@mui/material/Avatar";
import { green } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import {
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { GET_CONTACTS_BY_IDS, SEARCH_CONTACT } from "../../../../gqls/contact";
import { useDebounce, useOnClickOutside } from "usehooks-ts";
import { contact } from "../../../../types/contact";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import StorageIcon from "@mui/icons-material/Storage";
import Edit from "@mui/icons-material/Edit";

interface props {
  contactIds?: number[];
  disabled?: boolean;
  editing?: boolean;
}

interface ContactFormData {
  contacts: contact[];
  newContacts: Omit<contact, "id">[];
}

export default function CustomerContacts({
  contactIds,
  disabled,
  editing,
}: props) {
  const {
    control,
    reset,
    getValues,
    formState: { errors },
  } = useFormContext<ContactFormData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
    keyName: "fid",
  });

  const [getContacts] = useLazyQuery(GET_CONTACTS_BY_IDS);

  useEffect(() => {
    if (!contactIds) return;
    getContacts({
      variables: {
        ids: contactIds,
      },
      onCompleted(data) {
        const values = getValues();
        reset({
          ...values,
          contacts: data.contacts,
        });
      },
    });
  }, [contactIds]);

  return (
    <div className="flex flex-col gap-1">
      <label className="font-semibold">연락처</label>
      {fields.map((field, i) => (
        <ExContact
          key={field.fid}
          field={field}
          disabled={disabled}
          i={i}
          remove={remove}
          editing={editing}
        />
      ))}
      {!disabled && (editing == undefined || editing) && (
        <NewContacts appendContact={append} />
      )}
      {errors?.contacts && (
        <div className="text-sm mt-2 text-red-500">
          {errors?.contacts?.message}
        </div>
      )}
    </div>
  );
}

interface exContactProps {
  field: FieldArrayWithId<ContactFormData, "contacts", "fid">;
  disabled?: boolean;
  i: number;
  remove: UseFieldArrayRemove;
  editing?: boolean;
}

const ExContact = ({ field, disabled, i, remove, editing }: exContactProps) => {
  const { register } = useFormContext();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-3 items-center ">
        <Avatar sx={{ width: 50, height: 50, bgcolor: green[100] }} />
        <div className="flex flex-col gap-0 flex-1">
          <div className="flex flex-row gap-2 items-center">
            {editing ? (
              <input
                className="p-[2px] w-full outline-none border-b-[1px] border-b-transparent focus:border-b-quezone font-semibold"
                placeholder="이름"
                {...register(`contacts.${i}.name`)}
              />
            ) : (
              <div className="p-[2px] mb-[1px] font-semibold">{field.name}</div>
            )}
            {!editing && (
              <div className="text-lightGreen">
                <StorageIcon sx={{ width: 18, paddingBottom: "5px" }} />
              </div>
            )}
          </div>
          {editing ? (
            <input
              className="p-[2px] w-full outline-none border-b-[1px] border-b-transparent focus:border-b-quezone"
              type="tel"
              placeholder="번호"
              {...register(`contacts.${i}.number`)}
            />
          ) : (
            <div className="p-[2px] mb-[1px]">{field.number}</div>
          )}
        </div>
        {!disabled && (editing == undefined || editing) && (
          <button className="text-red-500" onClick={() => remove(i)}>
            <DeleteForever />
          </button>
        )}
      </div>
      <hr className="border-gray-300 border-[1px]" />
    </div>
  );
};

const NewContacts = ({
  appendContact,
}: {
  appendContact: UseFieldArrayAppend<ContactFormData, "contacts">;
}) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newContacts",
  });

  return (
    <div className="flex flex-col gap-2 mt-2">
      {fields.map((field, i) => (
        <ContactItem
          key={field.id}
          i={i}
          remove={remove}
          appendContact={appendContact}
        />
      ))}
      <div
        className="flex flex-row gap-4 items-center text-quezone cursor-pointer mt-2"
        onClick={() =>
          append({
            name: "",
            number: "",
          })
        }
      >
        <Avatar
          sx={{
            width: 50,
            height: 50,
            bgcolor: "transparent",
            border: "#007d25 3px solid",
            color: "#007d25",
          }}
        >
          <Add />
        </Avatar>
        연락처 추가
      </div>
    </div>
  );
};

const ContactItem = ({
  i,
  remove,
  appendContact,
}: {
  i: number;
  remove: UseFieldArrayRemove;
  appendContact: UseFieldArrayAppend<ContactFormData, "contacts">;
}) => {
  const { register, control, getValues } = useFormContext();
  const coord = `newContacts.${i}`;

  const name = useWatch({
    control,
    name: `${coord}.name`,
  });

  const debouncedSearch = useDebounce(name, 500);

  const [searchContact, { loading }] = useLazyQuery(SEARCH_CONTACT);
  const [results, setResults] = useState<contact[]>([]);

  useEffect(() => {
    if (name.trim() == "") {
      setResults([]);
      return;
    }

    searchContact({
      variables: { Str: `${debouncedSearch}` },
      fetchPolicy: "network-only",
      onCompleted(data) {
        const res = data.contacts;
        if (res) {
          const currentContacts = getValues("contacts") as contact[];
          setResults(
            res.filter(r => !currentContacts.some(c => c.id === r.id))
          );
        }
      },
    });
  }, [debouncedSearch]);

  const setContact = (contact: contact) => {
    remove(i);
    appendContact(contact);
    setResults([]);
  };

  const ref = useRef<null | HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setResults([]);
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-3 items-center relative">
        <Avatar sx={{ width: 50, height: 50, bgcolor: green[100] }} />
        <div className="flex flex-col gap-0 flex-1">
          <div className="w-full relative">
            <input
              className="p-[2px] w-full outline-none border-b-[1px] border-b-transparent focus:border-b-quezone font-semibold"
              placeholder="이름 / 이름 번호 검색"
              {...register(`${coord}.name`)}
            />
          </div>
          <input
            className="p-[2px] w-full outline-none border-b-[1px] border-b-transparent focus:border-b-quezone"
            type="tel"
            placeholder="번호"
            {...register(`${coord}.number`)}
          />
        </div>

        <div className="cursor-pointer text-red-500" onClick={() => remove(i)}>
          <DeleteForever />
        </div>

        {results.length > 0 && (
          <div
            className="absolute w-full l-0 top-full bg-white ring-1 ring-quezone z-10"
            ref={ref}
          >
            <List dense>
              {results.map(result => (
                <ListItemButton
                  key={result.id}
                  onClick={() => {
                    setContact(result);
                  }}
                >
                  <ListItemText>
                    {result.name} : {result.number}
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </div>
        )}
      </div>
      <hr className="border-gray-300 border-[1px]" />
    </div>
  );
};
