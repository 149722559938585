import { Permission } from "../../../../types/auth";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { useEventListener } from "usehooks-ts";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@apollo/client";
import {
  DELETE_PERMISSION,
  UPDATE_PERMISSION_BY_ID,
} from "../../../../gqls/permission";
import { useAuthStore } from "../../../../store/authStore";
import { cacheEvict } from "../../../../utils/cacheMethods";

interface props {
  permission: Permission;
}

const schema = z.object({
  name: z.string().min(1, { message: "이름은 비워둘 수 없습니다" }),
  description: z.string().min(1, { message: "설명은 비워둘 수 없습니다" }),
});

export default function PermissionRow({ permission }: props) {
  const [editing, setEditing] = useState(false);

  const { updatePermission, deletePermission } = useAuthStore();

  const defaultValues = permission;

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, touchedFields, isDirty },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [update] = useMutation(UPDATE_PERMISSION_BY_ID);

  const submit = handleSubmit(data => {
    if (!isDirty) {
      return setEditing(false);
    }

    update({
      variables: {
        id: permission.id,
        set: data,
      },
      onCompleted: data => {
        reset(data.update_permissions_by_pk);
        updatePermission(data.update_permissions_by_pk);
        setEditing(false);
      },
    });
  });

  const [focused, setFocused] = useState(false);

  useEventListener("keydown", e => {
    if (!focused) {
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      submit();
    }
  });

  const [remove] = useMutation(DELETE_PERMISSION);

  const _delete = () => {
    const proceed = confirm(`${permission.name} 권한을 삭제하시겠습니까?`);
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id: permission.id,
      },
      onCompleted: () => {
        deletePermission(permission.id);
      },
      update(cache) {
        cacheEvict({ cache, id: permission.id, __typename: "permissions" });
      },
    });
  };

  return (
    <TableRow
      hover
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <TableCell>
        {editing ? (
          <TextField
            color="success"
            size="small"
            autoFocus
            margin="none"
            placeholder={errors.name?.message || "이름"}
            error={errors?.name && touchedFields?.name}
            {...register("name")}
          />
        ) : (
          permission.name
        )}
      </TableCell>
      <TableCell>
        {editing ? (
          <TextField
            color="success"
            size="small"
            margin="none"
            placeholder={errors.description?.message || "이름"}
            error={errors?.description && touchedFields?.description}
            {...register("description")}
          />
        ) : (
          permission.description
        )}
      </TableCell>

      <TableCell className="w-24">
        <div className="flex flex-row gap-2 items-center">
          {!editing ? (
            <>
              <div
                className="hover:text-quezone cursor-pointer"
                onClick={() => {
                  setEditing(true);
                }}
              >
                <EditIcon />
              </div>
              <div className="text-red-500 cursor-pointer" onClick={_delete}>
                <DeleteIcon />
              </div>
            </>
          ) : (
            <>
              <div
                className="text-red-500 cursor-pointer"
                onClick={() => {
                  setEditing(false);
                  reset();
                }}
              >
                <ClearIcon />
              </div>
              {isDirty && (
                <div className="text-quezone cursor-pointer" onClick={submit}>
                  <CheckIcon />
                </div>
              )}
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
