import { useQuery } from "@apollo/client";
import { useParams, useRoutes } from "react-router-dom";
import {
  GET_CUSTOMER_BY_PK,
  STREAM_CUSTOMER_BY_PK,
} from "../../../../gqls/customer";
import CustomerBasicInfo from "./customerBasicInfo/customerBasicInfo";
import CustomerProgress from "./customerProgress/customerProgress";
import { extendedCustomer } from "../../../../types/customer";
import CustomerDetailWrapper from "./customerDetailWrapper";
import LinearProgress from "@mui/material/LinearProgress";
import CustomerInventory from "./customerInventory/customerInventory";
import AddCustomerInventory from "./customerInventory/addCustomerInventory/addCustomerInventory";
import CustomerFile from "./customerFile/customerFile";
import CustomerPrice from "./customerInventory/customerAccount/customerAccount";
import AddTask from "./customerProgress/customerTasks/addTask";
import TaskDetailWrapper from "../../tasks/taskDetail/taskDetailWrapper";
import InventoryDetail from "../../inventory/detail/inventoryDetail";
import TaskEditWrapper from "./customerProgress/customerTasks/taskEditWrapper";
import AddReport from "../../tasks/report/addReport";
import ReportDetailWrapper from "../../tasks/report/reportDetail/reportDetailWrapper";
import ReportEditWrapper from "../../tasks/report/reportEdit/reportEditWrapper";

const Routes = ({ customer }: { customer: extendedCustomer }) => {
  let routes = useRoutes([
    {
      path: "/",
      element: <CustomerDetailWrapper customer={customer} />,
      children: [
        { path: "/", element: <CustomerBasicInfo customer={customer} /> },
        { path: "/*", element: <CustomerBasicInfo customer={customer} /> },
        {
          path: "/progress",
          element: <CustomerProgress customer={customer} />,
        },
        { path: "/progress/addTask", element: <AddTask customer={customer} /> },
        {
          path: "/progress/task/:id",
          element: <TaskDetailWrapper nested noCustomerInfo />,
        },
        {
          path: "/progress/taskEdit/:id",
          element: <TaskEditWrapper />,
        },
        {
          path: "/progress/task/:id/addReport",
          element: <AddReport nested />,
        },
        {
          path: "/progress/task/:id/reportDetail/:reportId",
          element: <ReportDetailWrapper nested />,
        },
        {
          path: "/progress/task/:id/reportDetail/:reportId/edit",
          element: <ReportEditWrapper nested />,
        },
        {
          path: "/inventory",
          element: <CustomerInventory customer={customer} />,
        },
        {
          path: "/inventory/detail/:id",
          element: <InventoryDetail nested />,
        },
        {
          path: "/inventory/add",
          element: <AddCustomerInventory customer={customer} />,
        },
        {
          path: "/file",
          element: <CustomerFile customer={customer} />,
        },
        { path: "/account", element: <CustomerPrice customer={customer} /> },
      ],
    },
  ]);

  return routes;
};

export default function CustomerDetail() {
  const { id } = useParams();

  const { data, loading, subscribeToMore } = useQuery(GET_CUSTOMER_BY_PK, {
    variables: { id: Number(id) },
    onError(error) {
      console.log(error);
    },
  });

  subscribeToMore({
    document: STREAM_CUSTOMER_BY_PK,
    variables: {
      where: { id: { _eq: id } },
      cursor: {
        initial_value: { updated_at: new Date().toISOString() },
        ordering: "ASC",
      },
    },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.customers_stream[0];
      if (!newFeedItem) return prev;
      return Object.assign({}, prev, {
        customers_by_pk: newFeedItem,
      });
    },
  });

  const customer = data?.customers_by_pk;

  if (loading) {
    return (
      <div className="flex-1 flex flex-col px-4 md:px-10 py-2 justify-center">
        <LinearProgress color="success" />
      </div>
    );
  }

  if (!customer) {
    return (
      <div className="flex-1 flex flex-col px-4 md:px-10 py-2 justify-center">
        해당하는 고객이 없습니다.
      </div>
    );
  }

  return <Routes customer={customer} />;
}
