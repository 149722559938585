import Button from "@mui/material/Button";
import { product } from "../../../../../types/product";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_PRODUCT } from "../../../../../gqls/product";
import { useProductStore } from "../../../../../store/productStore";
import { cacheEvict } from "../../../../../utils/cacheMethods";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

interface props {
  product: product;
}

export default function ProductDeleteControl({ product }: props) {
  const [deleting, setDeleting] = useState(false);
  const [nameCheck, setNameCheck] = useState("");

  const [remove, { loading }] = useMutation(DELETE_PRODUCT);

  const { deleteProduct } = useProductStore();

  const navigate = useNavigate();

  const _delete = () => {
    remove({
      variables: {
        id: product.id,
      },
      onCompleted() {
        setDeleting(false);
        deleteProduct(product.id);
        navigate("/settings/inventory");
      },
      update(cache) {
        cacheEvict({ cache, id: product.id, __typename: "products" });
      },
    });
  };

  return (
    <>
      <Button color="error" onClick={() => setDeleting(true)}>
        삭제
      </Button>
      <Dialog
        open={deleting}
        onClose={() => {
          setDeleting(false);
        }}
      >
        <DialogTitle>제품 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            제품을 삭제할 경우 모든 재고가 함께 삭제 됩니다. 정말 삭제하시려면
            아래 제품의 이름을 입력해주세요
          </DialogContentText>
          <TextField
            className="shadow-md col-span-1"
            size="small"
            margin="dense"
            autoComplete="off"
            type="text"
            color="success"
            placeholder={product.name}
            value={nameCheck}
            onChange={e => {
              setNameCheck(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setDeleting(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={product.name !== nameCheck}
            loading={loading}
            variant="contained"
            color="error"
            onClick={_delete}
          >
            삭제
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
