import { useState } from "react";
import { reportAccount } from "../../../../../../types/report";
import { currencyFormat } from "../../../../../../utils/numberMethods";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import {
  DELETE_REPORT_ACCOUNT,
  UPDATE_REPORT,
  UPDATE_REPORT_ACCOUNT,
} from "../../../../../../gqls/report";
import { useAuthStore } from "../../../../../../store/authStore";
import { cacheEvict } from "../../../../../../utils/cacheMethods";
import Modal from "@mui/material/Modal";
import {
  blackButtonContained,
  blackButtonOutlined,
  modal,
} from "../../../../../../classPresets";
import ReportAccountControls from "./reportAccountControls";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteForever from "@mui/icons-material/DeleteForever";

interface props {
  account: reportAccount;
  viewOnly?: boolean;
}

const schema = z.object({
  item: z.string(),
  amount: z.coerce.number(),
  gst: z.coerce.number(),
});

export default function ReportAccount({ account, viewOnly }: props) {
  const [editing, setEditing] = useState(false);

  const { item, amount, gst } = account;

  const methods = useForm({
    defaultValues: {
      item,
      amount,
      gst,
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const [update, { loading }] = useMutation(UPDATE_REPORT_ACCOUNT);
  const [remove] = useMutation(DELETE_REPORT_ACCOUNT);
  const [updateReport] = useMutation(UPDATE_REPORT);

  const resetAndClose = () => {
    reset();
    setEditing(false);
  };

  const { user } = useAuthStore();

  const deleteAccount = () => {
    const proceed = confirm("정산 내역을 삭제하시겠습니까?");
    if (!proceed) {
      return;
    }

    remove({
      variables: {
        id: account.id,
      },
      update(cache) {
        cacheEvict({
          cache,
          id: account.id,
          __typename: "reportAccounts",
        });
      },
      onCompleted: () => {
        resetAndClose();
        updateReport({
          variables: {
            id: account.reportId,
            set: {
              reads: [user?.id],
            },
          },
        });
      },
    });
  };

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: account.id,
        set: {
          ...data,
        },
      },

      onCompleted: () => {
        resetAndClose();
        updateReport({
          variables: {
            id: account.reportId,
            set: {
              reads: [user?.id],
            },
          },
        });
      },
    });
  });
  return (
    <>
      <tr
        className="hover:text-quezone cursor-pointer"
        onClick={() => {
          !viewOnly && setEditing(true);
        }}
      >
        <td>{item}</td>
        <td>{currencyFormat(amount)}</td>
        <td>{currencyFormat(gst)}</td>
        <td className="font-medium">{currencyFormat(amount + gst)}</td>
      </tr>
      <Modal open={editing} onClose={resetAndClose}>
        <form
          className={`${modal} p-2 flex flex-col gap-2 outline-none`}
          onSubmit={onSubmit}
        >
          <h3>정산 내역 수정</h3>
          <FormProvider {...methods}>
            <ReportAccountControls editing />
          </FormProvider>
          <div className="flex flex-row gap-2 justify-end items-center">
            <DeleteForever
              onClick={deleteAccount}
              className="cursor-pointer text-red-500"
            />
            <Button
              {...blackButtonOutlined}
              onClick={resetAndClose}
              size="small"
            >
              취소
            </Button>
            <LoadingButton
              loading={loading}
              disabled={!isDirty}
              {...blackButtonContained}
              size="small"
              type="submit"
            >
              수정
            </LoadingButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
