import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { typeOrStatus } from "../../../../../types/common";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditTaskType from "./editTaskType";
import DeleteTaskType from "./deleteTaskType";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportTypes from "./reportTypes/reportTypes";
import TaskTypeChecksInstaller from "./taskTypeChecks/taskTypeChecksInstaller";
import TaskTypeChecksCustomer from "./taskTypeChecks/taskTypeChecksCustomer";

interface props {
  type: typeOrStatus;
  isOverlay: boolean;
}

export default function TaskTypeRow({ type, isOverlay }: props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: type.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <>
      <Accordion
        ref={setNodeRef}
        style={style}
        className={`${isOverlay ? "text-quezone" : ""}`}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="flex flex-row items-center gap-2 justify-between group w-full">
            <div className="flex flex-row items-center gap-2 flex-1">
              <div
                className="p-2 rounded-full"
                style={{
                  backgroundColor: type.color,
                }}
              />
              <h2>{type.name}</h2>{" "}
              <div className="hidden md:block">- {type.description}</div>
            </div>
            <div
              className="md:hidden md:group-hover:flex"
              {...attributes}
              {...listeners}
            >
              <DragIndicatorIcon className="cursor-pointer" />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <EditTaskType type={type} />
          <ReportTypes type={type} />
          <div className="flex flex-col gap-4 mt-2">
            <TaskTypeChecksInstaller type={type} />
            <TaskTypeChecksCustomer type={type} />
          </div>
          <DeleteTaskType type={type} />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
