import { useEffect, useRef, useState } from "react";
import { tempImage } from "../../../../../types/report";
import { useFormContext } from "react-hook-form";
import DeleteForever from "@mui/icons-material/DeleteForever";
import VisuallyHiddenInput from "../../../../../comps/hiddenInput";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CollectionsIcon from "@mui/icons-material/Collections";

interface props {
  index: number;
  image: tempImage;
  remove: () => void;
  uploaded: number | null;
  setUploaded: (uploaded: number | null) => void;
  uploadingMode: null | "album" | "capture";
  setUploadingMode: (mode: null | "album" | "capture") => void;
}
export default function TempImage({
  index,
  image,
  remove,
  uploaded,
  setUploaded,
  uploadingMode,
  setUploadingMode,
}: props) {
  const [fileSrc, setFileSrc] = useState<null | string>(null);

  const { setValue, register } = useFormContext();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  useEffect(() => {
    if (!image?.image) {
      return;
    }
    const fr = new FileReader();

    fr.onload = () => {
      setFileSrc(fr.result as string);
    };

    fr.readAsDataURL(image.image);
  }, [image?.image]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const takePhotoRef = useRef<HTMLInputElement | null>(null);

  const handleImgClick = (mode: null | "album" | "capture") => {
    if (mode == null) {
      setDrawerOpen(true);
      return;
    }
    if (mode == "album") {
      fileInputRef.current?.click();
    } else {
      takePhotoRef.current?.click();
    }
  };

  const onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    mode: "album" | "capture"
  ) => {
    const files = e.target.files;
    let file = files && files[0];
    if (!file) {
      setValue(`images.${index}.image`, null, { shouldDirty: true });
      return;
    }
    setValue(`images.${index}.image`, file, { shouldDirty: true });
    if (uploaded !== index) {
      setUploaded(index);
      setUploadingMode(mode);
    }
  };

  useEffect(() => {
    if (uploaded == index - 1 && !fileSrc) {
      handleImgClick(uploadingMode);
    }
  }, [uploaded]);

  if (!image) {
    return null;
  }

  return (
    <div className="col-span-1 flex flex-col items-center gap-2 cursor-default relative">
      {!image.isPreset && (
        <div className="absolute -right-2 -top-2 cursor-pointer w-full flex flex-row justify-end">
          <DeleteForever
            className="text-red-500"
            sx={{ width: 30, height: 30 }}
            onClick={remove}
          />
        </div>
      )}
      <img
        src={fileSrc || image.src || "/no-img.jpg"}
        alt="no-image"
        className={`w-full aspect-square object-cover ring-1 ring-black rounded-md bg-gray-100 cursor-pointer ${
          !fileSrc && !image.src && "p-8"
        }`}
        onClick={() => {
          handleImgClick(null);
        }}
      />
      <VisuallyHiddenInput
        type="file"
        ref={fileInputRef}
        accept="image/*"
        onChange={e => {
          onFileChange(e, "album");
        }}
      />
      <VisuallyHiddenInput
        type="file"
        ref={takePhotoRef}
        accept="image/*"
        capture="environment"
        onChange={e => {
          onFileChange(e, "capture");
        }}
      />
      <div className="flex flex-row items-center gap-2">
        {image.isPreset ? (
          <div className="text-xs">{image.name}</div>
        ) : (
          <input
            className="max-w-full bg-transparent text-center text-xs outline-none"
            autoFocus
            placeholder="사진 이름을 입력하세요"
            {...register(`images.${index}.name`)}
          />
        )}
      </div>

      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleImgClick("capture");
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <PhotoCameraIcon />
              </ListItemIcon>
              <ListItemText primary="카메라" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleImgClick("album");
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <CollectionsIcon />
              </ListItemIcon>
              <ListItemText primary="앨범" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
