import { useQuery } from "@apollo/client";
import { useAuthStore } from "../../../store/authStore";
import { GET_INVENTORY_CONDITIONAL } from "../../../gqls/inv";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { useProductStore } from "../../../store/productStore";
import InvQty from "./companyList/companyInventory/invQty";
import { Fragment } from "react";

export default function MyInventory() {
  const { user } = useAuthStore();

  const { data } = useQuery(GET_INVENTORY_CONDITIONAL, {
    variables: {
      where: {
        userId: {
          _eq: user?.id,
        },
      },
    },
  });

  const { products } = useProductStore();

  const inventories = data?.inventory || [];

  const navigate = useNavigate();
  return (
    <div className="flex-1 flex flex-col gap-4">
      <main className="flex-1 md:py-5 md:p-8 flex flex-col md:drop-shadow-md md:bg-white overflow-x-clip">
        {/* Header */}
        <div className="pl-2 md:pl-0">
          <h2>내 재고</h2>
        </div>
        {/* PC */}
        <div className="hidden md:block">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>사진</TableCell>
                <TableCell>제품명</TableCell>
                <TableCell>일련번호</TableCell>
                <TableCell>재고</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventories.map(inventory => {
                const product = products.find(p => p.id == inventory.productId);

                if (!product || !product.active) {
                  return null;
                }

                return (
                  <TableRow
                    hover
                    className="cursor-pointer"
                    key={inventory.id}
                    onClick={() =>
                      navigate(`/inventory/detail/${inventory.id}`)
                    }
                  >
                    <TableCell className="w-40">
                      <Avatar
                        variant="square"
                        sx={{
                          width: 100,
                          height: 100,
                          bgcolor: "#CCCCCCCC",
                          borderRadius: 2,
                        }}
                        src={product.photo}
                      >
                        <PanoramaIcon />
                      </Avatar>
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.serial}</TableCell>
                    <TableCell>
                      <div className="flex flex-row gap-1 items-center flex-wrap">
                        <InvQty inv={inventory} />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {/* Mobile */}
        <div className="md:hidden mt-4 flex flex-col gap-2">
          {inventories.map((inventory, index) => {
            const product = products.find(p => p.id == inventory.productId);

            if (!product || !product.active) {
              return null;
            }

            return (
              <Link key={inventory.id} to={`/inventory/detail/${inventory.id}`}>
                <hr
                  className={`${Number(index) == 0 ? "hidden" : ""} mt-2 pb-2`}
                />
                {/* product total */}
                <div className="flex flex-row gap-2 ">
                  <Avatar
                    variant="square"
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: "#CCCCCCCC",
                      borderRadius: 2,
                    }}
                    src={product.photo}
                  >
                    <PanoramaIcon />
                  </Avatar>

                  <div className="flex flex-1 flex-col gap-1 justify-center">
                    <div className="flex flex-row items-center gap-2">
                      <div className="truncate">{product.name}</div>
                    </div>

                    <div className="flex flex-row flex-wrap gap-1 items-center">
                      <InvQty inv={inventory} />
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        {inventories.length == 0 && (
          <div className="mt-4">보유 재고가 없습니다</div>
        )}
      </main>
    </div>
  );
}
