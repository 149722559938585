import { User } from "../../../../../types/user";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_STATUSES, UPDATE_USER_BY_ID } from "../../../../../gqls/user";
import { Controller, useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { GET_COMPANIES } from "../../../../../gqls/company";
import { useAuthStore, useCheckAuth } from "../../../../../store/authStore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";
import { ADD_OTHER_HISTORY } from "../../../../../gqls/common";

interface props {
  user: User;
}

const schema = z.object({
  authId: z.coerce.number(),
  companyId: z.coerce.number().optional().nullable(),
  statusId: z.coerce.number(),
  role: z.string().optional().nullable(),
});

export default function UserAuthControl({ user }: props) {
  const { auth, company, status, role } = user;

  const defaultValues = {
    authId: auth.id,
    companyId: company?.id,
    statusId: status.id,
    role,
  };

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { user: currentUser } = useAuthStore();

  const { data: companiesData } = useQuery(GET_COMPANIES);
  const companies = companiesData?.companies?.filter(c => !c.noUsers);

  const { data: statusData } = useQuery(GET_USER_STATUSES);
  const statuses = statusData?.userStatuses;

  let { auths } = useAuthStore();

  const [update, { loading }] = useMutation(UPDATE_USER_BY_ID);

  const [addOtherHistory] = useMutation(ADD_OTHER_HISTORY);

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: user.id,
        set: data,
      },
      onCompleted: data => {
        const user = data.update_users_by_pk;
        const { auth, company, status, role } = user;
        reset({
          authId: auth.id,
          companyId: company?.id,
          statusId: status.id,
          role,
        });

        addOtherHistory({
          variables: {
            object: {
              title: "사용자 업데이트",
              desc: "사용자의 권한 관련 정보가 변경되었습니다.",
              link: `/settings/user/detail/${user.id}`,
              userId: user.id,
              companyId: company?.id,
              byId: currentUser?.id,
            },
          },
        });
      },
    });
  });

  const navigate = useNavigate();

  const goToCompany = () => {
    navigate(`/settings/company/detail/${company?.id}`);
  };

  const checkAuth = useCheckAuth();

  const requiredAuth =
    checkAuth({
      permissionName: "설정_사용자열람",
      scopeCheck: ["all", "company", "sub"],
    }) &&
    checkAuth({
      permissionName: "설정_사용자열람",
      userId: user.id,
    });

  if (!requiredAuth) {
    return null;
  }

  auths = auths.filter(auth => {
    if (user.auth.name == "admin") {
      return true;
    }

    if (auth.name == "admin") {
      return false;
    }

    if (user.auth.name.includes("직원")) {
      if (auth.name.includes("관리자")) {
        return false;
      }
    }

    return true;
  });

  return (
    <form onSubmit={onSubmit} className="col-span-1 flex flex-col gap-4">
      <Paper className="border-x-[1px] md:border-none p-4 flex flex-col">
        <h1>소속 & 권한</h1>
        <FormControl margin="dense">
          <div className="flex flex-row justify-between">
            <label htmlFor="userCompany">회사</label>
            {company && (
              <div
                className="hover:text-quezone cursor-pointer"
                onClick={goToCompany}
              >
                <OpenInNewIcon sx={{ width: 18, paddingBottom: 0.5 }} />
              </div>
            )}
          </div>
          {companies && (
            <Controller
              name="companyId"
              defaultValue={defaultValues.companyId}
              control={control}
              render={({ field }) => (
                <Select
                  required
                  size="small"
                  error={errors.companyId && touchedFields.companyId}
                  labelId="companyId"
                  id="companyId"
                  label="회사"
                  color="success"
                  {...field}
                >
                  <MenuItem value={0}>무소속</MenuItem>
                  {companies.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}

          <FormHelperText error={errors.companyId && touchedFields.companyId}>
            {touchedFields.companyId && errors.companyId?.message}
          </FormHelperText>
        </FormControl>
        <FormControl margin="dense">
          <label htmlFor="userAuth">권한</label>
          {auths.length > 0 && (
            <Controller
              name="authId"
              defaultValue={defaultValues.authId}
              control={control}
              render={({ field }) => (
                <Select
                  required
                  size="small"
                  error={errors.authId && touchedFields.authId}
                  labelId="authId"
                  id="authId"
                  label="권한"
                  color="success"
                  {...field}
                >
                  {auths.map(auth => (
                    <MenuItem key={auth.id} value={auth.id}>
                      {auth.displayName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}

          <FormHelperText error={errors.authId && touchedFields.authId}>
            {touchedFields.authId && errors.authId?.message}
          </FormHelperText>
        </FormControl>
        <FormControl margin="dense">
          <label htmlFor="userRole">직함</label>
          <TextField
            error={errors.role && touchedFields.role}
            className="shadow-md col-span-1"
            size="small"
            id="userRole"
            autoComplete="off"
            type="text"
            color="success"
            placeholder="대리, 이사 등.."
            {...register("role")}
          />
          <FormHelperText error={errors.role && touchedFields.role}>
            {touchedFields.role && errors.role?.message}
          </FormHelperText>
        </FormControl>
        <FormControl margin="dense">
          <label htmlFor="userAuth">상태</label>
          {statuses && statuses.length > 0 && (
            <Controller
              name="statusId"
              defaultValue={defaultValues.statusId}
              control={control}
              render={({ field }) => (
                <Select
                  required
                  size="small"
                  error={errors.statusId && touchedFields.statusId}
                  labelId="statusId"
                  id="statusId"
                  label="상태"
                  color="success"
                  {...field}
                >
                  {statuses.map(status => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}

          <FormHelperText error={errors.statusId && touchedFields.statusId}>
            {touchedFields.statusId && errors.statusId?.message}
          </FormHelperText>
        </FormControl>
      </Paper>
      {isDirty && (
        <div className="flex flex-row gap-2 items-center justify-end flex-1">
          <Button
            color="success"
            onClick={() => {
              reset();
            }}
          >
            취소
          </Button>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            color="success"
            sx={{
              backgroundColor: "black",
              fontWeight: 500,
            }}
          >
            수정
          </LoadingButton>
        </div>
      )}
    </form>
  );
}
