import { Link } from "react-router-dom";

export const MobileMenu = ({
  title,
  path,
  currentPath,
}: {
  title: string;
  path: string;
  currentPath: string;
}) => {
  const isCurrent = currentPath == path;

  return (
    <Link to={`./${path}`}>
      <div
        className={`whitespace-nowrap relative py-1 ${
          isCurrent ? "text-quezone" : ""
        } `}
      >
        {title}
        {isCurrent && (
          <div className="absolute bottom-0 h-2 w-full  border-b-4 border-b-quezone" />
        )}
      </div>
    </Link>
  );
};

export const Menu = ({
  title,
  path,
  currentPath,
}: {
  title: string;
  path: string;
  currentPath: string;
}) => {
  const isCurrent = currentPath == path;

  return (
    <Link to={`./${path}`}>
      <div
        className={`flex flex-row items-center py-3 px-6 rounded-l-md relative ${
          isCurrent ? "bg-[#d4e4d9] text-quezone" : "bg-[#e1e1e1] text-white"
        }`}
      >
        {title}
        {isCurrent && (
          <div className="absolute left-0 h-12 rounded-l-md border-l-[5px] border-l-quezone" />
        )}
      </div>
    </Link>
  );
};
