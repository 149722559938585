import { gql } from "@apollo/client";

export const CORRESPONDENCE_FIELDS = gql`
  fragment CorrespondenceFields on correspondences {
    id
    created_at
    updated_at
    customerId
    taskId
    byId
    date
    content
    user {
      email
      name
    }
  }
`;
