import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";
import { fieldWrapperEdit } from "../../../../../classPresets";

export default function ReportDateControl() {
  const { control } = useFormContext();

  return (
    <div className={`${fieldWrapperEdit()} col-span-1 gap-1 !py-2 md:!py-0`}>
      <label className="text-black">작업 날짜</label>
      <Controller
        name="date"
        control={control}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              slotProps={{
                textField: {
                  size: "small",
                  color: "success",
                  variant: "outlined",
                },
                openPickerButton: {
                  color: "success",
                },
              }}
              className="md:shadow-md max-w-40 md:max-w-full"
              value={field.value}
              onChange={e => {
                field.onChange(e);
                field.onBlur();
              }}
            />
          </LocalizationProvider>
        )}
      />
    </div>
  );
}
