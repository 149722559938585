import icon from "/quezone.png";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import CloseIcon from "@mui/icons-material/Close";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useMenues, useNavStore } from "../../../../store/navStore";
import Menu from "./menu";
import { useAuthStore, useLogOut } from "../../../../store/authStore";
import { useLocation, useNavigate } from "react-router-dom";
import Fade from "@mui/material/Fade";
import Avatar from "@mui/material/Avatar";
import Grow from "@mui/material/Grow";
import useUserAvatar from "../../../../hooks/useUserAvatar";
import { useNotificationStore } from "../../../../store/notificationStore";
import { memo, useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Notifications from "../notifications/notifications";
import { useMediaQuery, useOnClickOutside } from "usehooks-ts";
import { createPortal } from "react-dom";

const MemoizedNotifications = memo(Notifications);

export default function TopNav() {
  const { open, setOpen } = useNavStore();

  const menues = useMenues();
  const logOut = useLogOut();

  const { user } = useAuthStore();

  const navigate = useNavigate();
  const goToProfile = () => {
    setOpen(false);
    navigate("/profile");
  };

  const { pathname } = useLocation();

  const topLoc = pathname.split("/")[1] || "dashboard";
  const title = menues.find(m => m.path == topLoc)?.title;

  const { avatar } = useUserAvatar(Number(user?.id));

  const { notifications } = useNotificationStore();

  const notificationNotGranted =
    !("Notification" in window) || Notification?.permission !== "granted";
  const off = user?.pushPref == false || notificationNotGranted;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (off) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const NotificationsOpen = Boolean(anchorEl);

  const isBig = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    if (isBig) {
      setOpen(false);
    }
  }, [isBig]);

  const closeMenu = () => {
    if (open) {
      history.go(1);
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", closeMenu);
    return () => {
      window.removeEventListener("popstate", closeMenu);
    };
  }, [open]);

  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState<number>(0);
  const [refreshable, setRefreshable] = useState(false);

  const pullStart = (e: TouchEvent) => {
    if (!refreshable) {
      return;
    }
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
  };

  const pull = (e: TouchEvent) => {
    if (!refreshable) {
      return;
    }
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
    setPullChange(pullLength);
  };

  const endPull = () => {
    if (pullChange > 200) {
      window.location.reload();
    }
    setStartPoint(0);
    setPullChange(0);
  };

  useEffect(() => {
    window.addEventListener("touchstart", pullStart);
    window.addEventListener("touchmove", pull);
    window.addEventListener("touchend", endPull);
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });

  const ref = useRef(null);

  useOnClickOutside(
    ref,
    () => {
      setRefreshable(false);
    },
    "touchstart"
  );

  return (
    <>
      <header
        ref={ref}
        onTouchStart={() => {
          setRefreshable(true);
        }}
        id="topNav"
        className={`w-full px-2 md:hidden bg-white flex flex-row justify-between items-center gap-2 min-h-[64px] transition-all z-40 relative ${
          open ? "" : "shadow-md"
        }`}
      >
        <>
          {createPortal(
            <div
              className="refresh-icon p-2 rounded-full absolute z-50 w-full -top-10"
              style={{ transform: `translateY(${pullChange / 3 || 0}px)` }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`w-8 h-8 mx-auto rounded-full p-1 stroke-quezone ${
                  pullChange > 200 && "bg-quezone stroke-white"
                }`}
                style={{ transform: `rotate(${pullChange}deg)` }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>,
            document.body
          )}
        </>
        <div
          className="flex items-center transition-all"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="pl-1">
            {open ? (
              <CloseIcon sx={{ fontSize: 28 }} />
            ) : (
              <MenuIcon sx={{ fontSize: 28 }} />
            )}
          </div>
          <Fade in={!open}>
            <div className="text-lg font-semibold pt-[2px] pl-2">{title}</div>
          </Fade>
        </div>

        <Fade in={open}>
          <div className="p-3 absolute left-1/2 -translate-x-1/2 pt-4">
            <img src={icon} className="w-20" />
          </div>
        </Fade>
        <div className="p-2 pt-1" onClick={handleOpen}>
          {off ? (
            <NotificationsOffIcon sx={{ fontSize: 28 }} />
          ) : (
            <NotificationsIcon
              sx={{ fontSize: 28 }}
              className={`text-gray-700 ${NotificationsOpen && "text-quezone"}`}
            />
          )}

          {!off && !NotificationsOpen && notifications.length > 0 && (
            <div className="absolute right-[18px] top-[19px] bg-red-500 p-[5px] rounded-full" />
          )}
        </div>
        <Popover
          open={NotificationsOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ marginTop: 1 }}
        >
          <MemoizedNotifications />
        </Popover>
      </header>
      <Fade in={open} mountOnEnter unmountOnExit>
        <div className="w-screen min-h-[calc(100dvh-64px)] border-t-[1px] flex flex-col md:hidden justify-between items-start gap-2 bg-white overflow-y-auto">
          <div className="flex flex-col items-start">
            {user && (
              <div
                className="px-4 py-4 flex flex-row gap-3 items-center"
                onClick={goToProfile}
              >
                <Avatar
                  className="ring-quezone ring-1"
                  sx={{
                    width: 48,
                    height: 48,
                    bgcolor: user.color,
                    fontSize: 18,
                  }}
                  src={avatar || ""}
                >
                  {user.name[0]}
                </Avatar>
                <div className="flex flex-col gap-0 justify-around pt-1">
                  <div className="font-semibold">{user.name}</div>
                  <div className="text-quezone">{user.auth.name}</div>
                </div>
              </div>
            )}
            <hr className="w-screen mb-2" />
            {menues.map((menu, i) => (
              <Menu
                key={menu.path}
                menu={menu}
                index={i}
                open={open}
                setOpen={setOpen}
              />
            ))}
          </div>

          <Grow in={open} timeout={menues.length * 100}>
            <div className="flex flex-col w-full">
              <hr className="w-full" />
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-col justify-center px-4 py-3 opacity-50">
                  {!!APP_VERSION && APP_VERSION}
                </div>
                <div
                  className={`flex flex-row items-center px-4 py-3 gap-2 text-gray-500 active:text-quezone`}
                  onClick={logOut}
                >
                  <div className="pt-[2px] text-lg">로그아웃</div>
                  <LogoutOutlinedIcon sx={{ fontSize: 24 }} />
                </div>
              </div>
            </div>
          </Grow>
        </div>
      </Fade>
    </>
  );
}
