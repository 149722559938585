import { ApolloCache } from "@apollo/client";

interface evictProps {
  cache: ApolloCache<any>;
  id: number;
  __typename: string;
}

export const cacheEvict = ({ cache, id, __typename }: evictProps) => {
  const normalizedId = cache.identify({
    id,
    __typename,
  });

  cache.evict({ id: normalizedId });
  cache.gc();
};
