import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { typeOrStatus } from "../../../../../types/common";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useState } from "react";
import EditCustomerSubType from "./editCustomerSubType";
import DeleteCustomerSubType from "./deleteCustomerSubType";

interface props {
  type: typeOrStatus;
  isOverlay: boolean;
}

export default function CustomerSubTypeRow({ type, isOverlay }: props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: type.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const [mode, setMode] = useState<null | "editing" | "deleting">(null);
  return (
    <>
      <div
        className={`flex flex-row gap-2 px-4 py-3 rounded-md shadow-md items-center group bg-white ${
          isOverlay ? "text-quezone" : ""
        }`}
        ref={setNodeRef}
        style={style}
      >
        <div
          className="flex flex-row items-center gap-2 flex-1 hover:text-quezone cursor-pointer"
          onClick={() => {
            setMode("editing");
          }}
        >
          <div
            className="p-2 rounded-full"
            style={{
              backgroundColor: type.color,
            }}
          />
          <h2>{type.name}</h2>
          <div className="hidden md:block">
            {type.description ? ` - ${type.description}` : ""}
          </div>
        </div>

        <div
          className="md:hidden md:group-hover:flex"
          {...attributes}
          {...listeners}
        >
          <DragIndicatorIcon className="cursor-pointer" />
        </div>
      </div>
      {mode == "editing" && (
        <EditCustomerSubType setMode={setMode} type={type} />
      )}
      {mode == "deleting" && (
        <DeleteCustomerSubType setMode={setMode} type={type} />
      )}
    </>
  );
}
