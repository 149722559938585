import { useQuery } from "@apollo/client";
import { extendedCustomer } from "../../../../../types/customer";
import { GET_CONTACTS_BY_IDS } from "../../../../../gqls/contact";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function CustomerDetailHeaderContacts({
  customer,
}: {
  customer: extendedCustomer;
}) {
  const { contactIds } = customer;

  const { data } = useQuery(GET_CONTACTS_BY_IDS, {
    variables: {
      ids: contactIds,
    },
  });

  const [mode, setMode] = useState("tel" as "tel" | "sms");

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const contacts = data?.contacts || [];

  const callOrMessage = (type: "sms" | "tel", number: string) => {
    const link = document.createElement("a");
    link.href = `${type}:${number}`;
    try {
      link.click();
    } catch (error) {
      link.remove();
    }
    link.remove();
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    type: "sms" | "tel"
  ) => {
    if (contacts.length == 0) {
      return;
    }
    if (contacts.length == 1) {
      callOrMessage(type, contacts[0].number);
      return;
    }
    openMenu(event);
    setMode(type);
  };

  return (
    <div className="flex flex-row gap-3 items-center">
      <div
        onClick={event => {
          handleClick(event, "tel");
        }}
        className="rounded-full bg-quezone p-2 flex shadow-md hover:brightness-90 cursor-pointer"
      >
        <PhoneIcon className="text-white" sx={{ width: 18, height: 18 }} />
      </div>
      <div
        onClick={event => handleClick(event, "sms")}
        className="rounded-full bg-quezone p-2 flex shadow-md hover:brightness-90 cursor-pointer"
      >
        <MessageIcon className="text-white" sx={{ width: 18, height: 18 }} />
      </div>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {contacts.map(contact => {
          return (
            <MenuItem
              key={contact.id}
              onClick={() => callOrMessage(mode, contact.number)}
            >
              {contact.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
