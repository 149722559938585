import { filter } from "../../../../types/common";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Button from "@mui/material/Button";
import ShuffleIcon from "@mui/icons-material/Shuffle";

interface props {
  filter: filter;
  coord: string;
}
export default function MultiSelects({ filter, coord }: props) {
  const { setValue, control, getValues } = useFormContext();
  let { options } = filter;

  const { filters } = getValues() as { filters: filter[] };

  const parentFilterIndex = filters.findIndex(f => f?.name == filter.dependsOn);
  const parentValues = useWatch({
    control,
    name: `filters.${parentFilterIndex}.values`,
    // @ts-expect-error
  })?.map(v => v.toString());

  if (filter.dependsOn) {
    if (!parentValues || !filter.dependsValue) {
      return;
    }

    const dependsValue = filter.dependsValue.toString();

    options = options?.filter(o => {
      // @ts-expect-error
      if (!o.data || !o.data[dependsValue]) {
        return o;
      }
      // @ts-expect-error
      return parentValues.includes(o.data[dependsValue].toString());
    });
  }

  const values = useWatch({
    name: `${coord}.values`,
    control,
    defaultValue: [],
  }) as number[] | string[];

  const strValues = values.map(v => v.toString());

  const toggle = (value: string | number) => {
    if (strValues.includes(value.toString())) {
      setValue(
        `${coord}.values`,
        strValues.filter(v => v != value.toString()),
        { shouldDirty: true, shouldTouch: true }
      );
    } else {
      setValue(`${coord}.values`, [...strValues, value.toString()], {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const invert = () => {
    const invertedValues = options
      ?.filter(o => !strValues.includes(o.value.toString()))
      .map(o => o.value.toString());
    setValue(`${coord}.values`, invertedValues, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <FormGroup row>
      {options?.map((option, i) => (
        <FormControlLabel
          key={i}
          label={option.name}
          control={
            <Checkbox
              color="success"
              checked={strValues.includes(option.value.toString())}
              onChange={() => {
                toggle(option.value);
              }}
            />
          }
        />
      ))}
      {filter.includeNullOption && (
        <Controller
          name={`${coord}.includeNull`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              label="미정"
              control={
                <Checkbox
                  color="success"
                  checked={field.value}
                  onChange={field.onChange}
                />
              }
            />
          )}
        />
      )}
      <div className="flex-1 flex flex-row justify-end items-start pt-1">
        <Button
          onClick={invert}
          size="small"
          color="success"
          startIcon={<ShuffleIcon />}
        />
      </div>
    </FormGroup>
  );
}
