import { useAuthStore } from "../../store/authStore";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SyntheticEvent, useEffect, useState } from "react";
import SnackbarContent from "@mui/material/SnackbarContent";
import { createPortal } from "react-dom";
import { useNotificationStore } from "../../store/notificationStore";
import Snackbar from "./snackbar";

export default function Snackbars() {
  const { user } = useAuthStore();

  const [userInActive, setUserInActive] = useState(false);

  const handleClose = (_: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setUserInActive(false);
  };

  const inActiveSnackActino = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.status.considerActive) {
      setUserInActive(true);
    }
  }, [user]);

  const { toasts } = useNotificationStore();
  return (
    <>
      {createPortal(
        <div className="flex flex-col gap-2 absolute right-1 bottom-1 px-3 md:px-0">
          {userInActive && (
            <SnackbarContent
              message="아직 승인되지 않은 계정입니다. 활성화 알림을 기다려주세요"
              action={inActiveSnackActino}
            />
          )}
          {toasts.map(toast => (
            <Snackbar key={toast.messageId} toast={toast} />
          ))}
        </div>,
        document.body
      )}
    </>
  );
}
