import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { task } from "../../../../types/task";

interface props {
  task: task;
  index: number;
  setStr: React.Dispatch<React.SetStateAction<string>>;
}
export default function TaskSearchItem({ task, index, setStr }: props) {
  const navigate = useNavigate();

  const {
    type,
    id,
    customer: { number, year, address, name },
    company,
  } = task;

  const handleClick = () => {
    navigate(`/tasks/detail/${id}`);
    setStr("");
  };

  const fullNumber = `#${year}-${number}`;

  return (
    <Fragment>
      {index !== 0 && <hr />}
      <div
        className="flex flex-col gap-2 cursor-pointer md:hover:ring-1 group md:hover:ring-quezone rounded-sm p-2"
        onClick={handleClick}
      >
        <div className="font-medium truncate md:group-hover:text-quezone">
          {name}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="p-1 rounded-sm border-[1px] text-xs text-gray-500 border-gray-500">
            {fullNumber}
          </div>
          <div
            className="p-1 rounded-sm border-[1px] text-xs"
            style={{
              borderColor: type.color,
              color: type.color,
            }}
          >
            {type.name}
          </div>
          <div className="flex-1 text-right text-sm font-medium truncate">
            {company.name}
          </div>
        </div>
        <div className="text-xs text-quezone">
          {/* {source?.name} - {sourceCompany?.name || sourceDetail?.name} */}
        </div>
        <div className="text-xs truncate">{address}</div>
      </div>
    </Fragment>
  );
}
