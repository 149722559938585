import Switch from "@mui/material/Switch";
import { filter } from "../../../../types/common";
import MultiSelects from "./multiSelects";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import RangeFilter from "./rangeFilter";
import CountFilter from "./countFilter";

interface props {
  filter: filter;
  index: number;
}
export default function Filter({ filter, index }: props) {
  const coord = `filters.${index}`;
  const { control } = useFormContext();

  const on = useWatch({
    name: `${coord}.on`,
    control,
  });

  const { required } = filter;

  if (!filter) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center justify-between min-h-[38px]">
        <div className="flex flex-row gap-2 items-center">
          <div className="font-medium">{filter.name}</div>
        </div>
        {!required && (
          <Controller
            name={`${coord}.on`}
            control={control}
            render={({ field }) => (
              <Switch
                color="success"
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )}
      </div>
      {(required || on) && (
        <>
          {(filter.type == "contains" || filter.type == "in") && (
            <MultiSelects filter={filter} coord={coord} />
          )}
          {filter.type == "range" && (
            <RangeFilter filter={filter} coord={coord} />
          )}
          {filter.type == "count" && (
            <CountFilter filter={filter} coord={coord} />
          )}
        </>
      )}
    </div>
  );
}
