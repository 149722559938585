import { useQuery } from "@apollo/client";
import UserRow from "./userRow";
import { useNavigate } from "react-router-dom";
import { sort } from "fast-sort";
import { GET_USERS } from "../../../../gqls/user";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useCheckAuth } from "../../../../store/authStore";
import { useEffect } from "react";

export default function Users() {
  const { data } = useQuery(GET_USERS);
  const checkAuth = useCheckAuth();

  const users = sort(data?.users || [])
    .asc(["company", "auth", "name"])
    .filter(user =>
      checkAuth({
        permissionName: "설정_사용자열람",
        userId: user.id,
      })
    );

  const navigate = useNavigate();

  useEffect(() => {
    if (!checkAuth({ permissionName: "설정_사용자열람" })) {
      navigate(-1);
    }
  }, []);

  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <h1 className="hidden md:block mb-4 ">사용자 목록</h1>
      <Paper className="overflow-x-auto">
        <Table className="min-w-[524px] border-x-[1px] md:border-none">
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>회사</TableCell>
              <TableCell>권한</TableCell>
              <TableCell>직함</TableCell>
              <TableCell>상태</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <UserRow key={user.id} user={user} />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
