import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_REPORT_BY_ID } from "../../../../../gqls/report";
import ReportEdit from "./reportEdit";

export default function ReportEditWrapper({ nested }: { nested?: boolean }) {
  const { reportId } = useParams();

  const { data } = useQuery(GET_REPORT_BY_ID, {
    variables: {
      id: reportId,
    },
    onError(error) {
      console.log(error);
    },
  });

  const report = data?.reports_by_pk;

  if (!report) {
    return null;
  }

  return <ReportEdit report={report} nested={nested} />;
}
