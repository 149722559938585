import { task } from "../../../../types/task";
import { useQuery } from "@apollo/client";
import { GET_CONTACTS_BY_IDS } from "../../../../gqls/contact";
import LaunchIcon from "@mui/icons-material/Launch";
import { fieldWrapper } from "../../../../classPresets";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

interface props {
  task: task;
}

export default function TaskCustomerInfo({ task }: props) {
  const { customer } = task;
  const { address, contactIds, source, sourceDetail, sourceCompany } = customer;

  const { data } = useQuery(GET_CONTACTS_BY_IDS, {
    variables: {
      ids: contactIds,
    },

    onError(error) {
      console.log(error);
    },
  });

  const contacts = data?.contacts || [];

  const sourceString = `${source?.name || "미상"} ${
    sourceCompany?.name || sourceDetail?.name || "미상"
  }`;

  return (
    <>
      <div
        className={`${fieldWrapper} flex flex-row justify-between items-start gap-2`}
      >
        <label className="break-keep text-black">주소</label>
        <a
          href={`https://map.naver.com/p/search/${address}`}
          target="_blank"
          className="flex-1"
        >
          <div className="text-right hover:text-quezone">
            {address}
            <LaunchIcon className="ml-1" sx={{ width: 20, height: 20 }} />
          </div>
        </a>
      </div>
      {contacts.map((contact, i) => (
        <div
          key={contact.id}
          className={`${fieldWrapper} ${
            i % 2 === 0 ? "bg-gray-50 md:bg-transparent" : ""
          } flex flex-row justify-between items-start gap-2`}
        >
          <div className="flex flex-row items-center gap-1">
            <PhoneAndroidIcon
              className="text-quezone"
              style={{ width: 16, height: 16, marginBottom: 1 }}
            />
            <label className="break-keep text-black">{contact.name}</label>
          </div>
          <a href={`tel:${contact.number}`} className="flex-1">
            <div className="hover:text-quezone text-right">
              {contact.number}
            </div>
          </a>
        </div>
      ))}
      <div
        className={`${fieldWrapper} flex flex-row justify-between items-start gap-2 bg-gray-100 md:bg-transparent`}
      >
        <label className="break-keep text-black">접수처</label>

        <div className="text-right hover:text-quezone">{sourceString}</div>
      </div>
    </>
  );
}
