import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_COMPANIES } from "../../../../gqls/company";
import { useAuthStore } from "../../../../store/authStore";

export default function CustomerCompanyControl() {
  const { control, getValues, setValue } = useFormContext();

  const { user } = useAuthStore();
  const companyIds = [] as number[];

  const { data } = useQuery(GET_COMPANIES);
  let companies = data?.companies || [];

  if (user?.company) {
    companyIds.push(user.company.id);
    if (user.company.subCompanies) {
      companyIds.push(...user.company.subCompanies);
    }
  }

  if (user?.company?.id && !user?.company?.type.canHaveCustomers) {
    const parentCompanies = companies.filter(
      c =>
        c.subCompanies && c.subCompanies?.includes(user.company?.id as number)
    );
    companyIds.push(...parentCompanies.map(c => c.id));
  }

  companies = companies.filter(
    company =>
      companyIds.includes(company.id) &&
      company.type?.canHaveCustomers &&
      company.active
  );

  useEffect(() => {
    if (!companies || companies.length == 0) {
      return;
    }
    const companyId = getValues("companyId");
    if (companies.some(company => company.id === companyId)) {
      return;
    }
    const defaultCompany =
      companies.find(c => c.id == user?.company?.id)?.id || companies[0]?.id;

    setValue("companyId", defaultCompany);
  }, [companies]);

  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>담당사</label>
      <Controller
        name="companyId"
        control={control}
        render={({ field }) => (
          <Select
            margin="none"
            size="small"
            required
            className="shadow-md"
            color="success"
            value={field.value || 0}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            ref={field.ref}
            disabled={field.disabled}
          >
            <MenuItem value={0}>회사를 선택하세요</MenuItem>
            {companies.map(type => (
              <MenuItem value={type.id} key={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </div>
  );
}
