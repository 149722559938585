import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import { DesktopTimePicker } from "@mui/x-date-pickers";

export default function TaskTimeControl() {
  const { control, setValue, getValues } = useFormContext();

  const setNull = () => {
    setDisabled(true);
    setValue("time", null, { shouldDirty: true });
  };

  const [disabled, setDisabled] = useState(getValues("time") ? false : true);

  const handleChange = () => {
    if (disabled) {
      setDisabled(false);
    } else {
      setNull();
    }
  };

  return (
    <div className="col-span-1 lg:col-span-2 flex flex-col gap-1">
      <div className="flex flex-row justify-between gap-2 items-center">
        <label>작업 시간</label>
        <Switch color="success" onChange={handleChange} checked={!disabled} />
      </div>
      <Controller
        name="time"
        control={control}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
              format="HH:mm"
              ampm={false}
              slotProps={{
                textField: {
                  size: "small",
                  color: "success",
                  variant: "outlined",
                },
                openPickerButton: {
                  color: "success",
                },
              }}
              disabled={disabled}
              className="shadow-md"
              value={field.value}
              onChange={e => {
                field.onChange(e);
                field.onBlur();
              }}
            />
          </LocalizationProvider>
        )}
      />
    </div>
  );
}
