import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { StatusPermission } from "../../../../types/auth";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useCustomerStore } from "../../../../store/customerStore";

export default function AuthCustomerStatusPermissionControl() {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: "customerStatusPermissions",
    keyName: "fid",
  });

  const typedFields = fields as FieldArrayWithId<
    {
      name: string;
      displayName: string;
      description: string;
      customerStatusPermissions: StatusPermission[];
    },
    "customerStatusPermissions",
    "fid"
  >[];

  const { statuses } = useCustomerStore();

  return (
    <div className="flex flex-col gap-2 mt-2">
      <label className="mt-2">고객 상태 관련 권한</label>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>상태</TableCell>
            <TableCell>범위</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {typedFields.map((field, i) => {
            const status = statuses.find(s => s.id == field.id);
            return (
              <TableRow hover key={field.id}>
                <TableCell>{status?.name}</TableCell>
                <TableCell>
                  <Controller
                    name={`customerStatusPermissions.${i}.scope`}
                    control={control}
                    defaultValue={field.scope}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        value={field.value}
                        onChange={e => field.onChange(e.target.value)}
                        color="success"
                      >
                        <FormControlLabel
                          value="none"
                          control={<Radio size="small" color="success" />}
                          label="없음"
                        />
                        <FormControlLabel
                          value="access"
                          control={<Radio size="small" color="success" />}
                          label="열람"
                        />
                        <FormControlLabel
                          value="edit"
                          control={<Radio size="small" color="success" />}
                          label="변경"
                        />
                      </RadioGroup>
                    )}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
