import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";
import useFormValues from "../../../../../hooks/useFormValues";
import dayjs from "dayjs";
import { sort } from "fast-sort";

export function CustomerWarrantyDateControlOnly({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { control, setValue } = useFormContext();
  const { installDate, asDate } = useFormValues();

  const dates = [installDate, asDate].filter(date => date);

  const setToYearLater = () => {
    let day = dayjs();
    if (dates.length === 0) {
      day = dayjs().add(1, "year");
    } else {
      const sorted = sort(dates).by({ desc: d => new Date(d).getTime() });
      day = dayjs(sorted[0]).add(1, "year");
    }

    setValue("warrantyDate", day, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <div className="flex flex-row gap-2 items-center">
      <Controller
        name="warrantyDate"
        control={control}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              slotProps={{
                textField: {
                  size: "small",
                  color: "success",
                  variant: "outlined",
                },
                openPickerButton: {
                  color: "success",
                },
              }}
              className="flex-1 shadow-md"
              value={field.value}
              disabled={disabled}
              onChange={e => {
                field.onChange(e);
                field.onBlur();
              }}
            />
          </LocalizationProvider>
        )}
      />
      <div
        onClick={setToYearLater}
        className="px-2 py-1 bg-quezone text-white rounded-md cursor-pointer hover:brightness-75 text-sm"
      >
        1년
      </div>
    </div>
  );
}

export default function CustomerWarrantyDateControl({
  disabled,
}: {
  disabled?: boolean;
}) {
  return (
    <div className="col-span-1 md:col-span-2 flex flex-col gap-1">
      <label>무상 A/S 기간</label>
      <CustomerWarrantyDateControlOnly disabled={disabled} />
    </div>
  );
}
