import { useFormContext, useWatch } from "react-hook-form";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisuallyHiddenInput from "../../comps/hiddenInput";
import Avatar from "@mui/material/Avatar";
import { ChangeEvent, LegacyRef, useRef } from "react";
import Resizer from "react-image-file-resizer";

export default function AvatarControl() {
  const { setValue, control } = useFormContext();

  const name = useWatch({
    name: "name",
    control,
  });

  const avatar = useWatch({
    name: "avatar",
    control,
  });

  const color = useWatch({
    name: "color",
    control,
  });

  const ref: LegacyRef<HTMLInputElement> | undefined | null = useRef(null);

  const resize = (file: File) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        256,
        256,
        "PNG",
        80,
        0,
        uri => {
          resolve(uri);
        },
        "file"
      );
    });

  const avatarURL = avatar && URL.createObjectURL(avatar);

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    const file = e.target.files[0];

    const converted = await resize(file);
    setValue("avatar", converted);
  };

  return (
    <div className="flex flex-col items-center">
      <Avatar
        sx={{ width: 128, height: 128, bgcolor: color }}
        alt={name}
        src={avatarURL}
        className="cursor-pointer hover:ring-1 ring-quezone"
        onClick={() => {
          ref?.current?.click();
        }}
      >
        <div className="flex flex-col items-center text-base">
          <CloudUploadIcon sx={{ width: 48, height: 48 }} />
          사진 등록
        </div>
      </Avatar>
      <VisuallyHiddenInput
        type="file"
        ref={ref}
        onChange={onChange}
        multiple={false}
        accept="image/*;capture=camera"
      />

      <div className="flex flex-row justify-end w-full -mt-2 h-8">
        {avatar && (
          <div
            className="flex flex-row items-end cursor-pointer"
            onClick={() => {
              setValue("avatar", null);
            }}
          >
            <DeleteForeverIcon color="error" />
            <span style={{ lineHeight: 1.2 }}>사진 삭제</span>
          </div>
        )}
      </div>
    </div>
  );
}
