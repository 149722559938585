import Button from "@mui/material/Button";
import { filter } from "../../../../types/common";
import Filter from "./filter";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import Fade from "@mui/material/Fade";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { sort } from "fast-sort";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { createPortal } from "react-dom";
import SortItem from "./sortItem";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../classPresets";

interface props {
  filters: filter[];
  setFilters: (filters: filter[]) => void;
  cb?: () => void;
}

export default function Filters({ filters, setFilters, cb }: props) {
  const methods = useForm({
    defaultValues: { filters },
  });

  const {
    handleSubmit,
    reset: formReset,
    getValues,
    setValue,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    formReset({ filters });
  }, [filters]);

  const reset = () => {
    const defaultFilters = filters.map(f => {
      return {
        ...f,
        values: f.defaultValues,
        on: f.defaultOn,
        sort: f.defaultSort,
        includeNull: false,
      };
    });

    setFilters(defaultFilters);
    formReset({ filters: defaultFilters });
    cb && cb();
  };

  const onSubmit = handleSubmit(data => {
    const { filters: newFilters } = data;
    setFilters(newFilters);
    formReset({
      filters: newFilters,
    });
    cb && cb();
  });

  const [sorters, setSorters] = useState<filter[]>([]);

  useEffect(() => {
    const sortersOnly = filters.filter(filter => filter.sort);
    setSorters(sort(sortersOnly).asc(sorter => sorter.sort?.priority));
  }, [filters]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sorters.find(item => item.name === theActive?.id),
    [theActive, sorters]
  );

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sorters.findIndex(({ name }) => name === active.id);
      const overIndex = sorters.findIndex(({ name }) => name === over.id);
      const movedArray = arrayMove(sorters, activeIndex, overIndex);
      setSorters(movedArray);

      const currentFilters: filter[] = getValues("filters");

      movedArray.map((sorter, i) => {
        const index = currentFilters.findIndex(
          filter => filter.name == sorter.name
        );
        setValue(`filters.${index}.sort.priority`, i, {
          shouldDirty: true,
          shouldTouch: true,
        });
      });
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  return (
    <FormProvider {...methods}>
      <Fade in={true}>
        <form
          onSubmit={onSubmit}
          className="p-4 shadow-md bg-white w-full  flex flex-col gap-2"
        >
          <h3>필터</h3>

          <hr />
          {filters.map((filter, i) => {
            if (filter.sortOnly) {
              return null;
            }
            return <Filter key={filter.name} index={i} filter={filter} />;
          })}

          <h3 className="mt-2">정렬</h3>
          <hr />
          <div className="flex flex-col gap-1">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToWindowEdges]}
            >
              <SortableContext
                items={sorters.map(sorter => sorter.name)}
                strategy={verticalListSortingStrategy}
              >
                {sorters.map(sorter => {
                  return <SortItem filter={sorter} key={sorter.name} />;
                })}
              </SortableContext>
              {activeItem && (
                <>
                  {createPortal(
                    <DragOverlay>
                      <SortItem filter={activeItem} isOverlay={true} />
                    </DragOverlay>,
                    document.body
                  )}
                </>
              )}
            </DndContext>
          </div>

          <hr />

          <div className="flex flex-row gap-2 items-center justify-end">
            <Button size="small" color="success" onClick={reset}>
              리셋
            </Button>
            {isDirty && (
              <>
                <Button
                  {...blackButtonOutlined}
                  onClick={() => {
                    formReset();
                  }}
                >
                  취소
                </Button>
                <Button {...blackButtonContained} type="submit">
                  적용
                </Button>
              </>
            )}
          </div>
        </form>
      </Fade>
    </FormProvider>
  );
}
