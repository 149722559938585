import { invHistory } from "../../../../types/inv";
import { extendedCustomerHistory } from "../../../../types/customer";
import CustomerLog from "./customerLog";
import InvLog from "./invLog";
import dayjs from "dayjs";
import { otherHistory } from "../../../../types/common";
import OtherLog from "./otherLog";

interface props {
  group: {
    date: string;
    logs: (invHistory | extendedCustomerHistory | otherHistory)[];
  };
}

export default function RecentGroup({ group }: props) {
  const { date, logs } = group;
  function isCustomerHistory(
    log: extendedCustomerHistory | invHistory | otherHistory
  ): log is extendedCustomerHistory {
    return (log as extendedCustomerHistory).customerId !== undefined;
  }

  function isInvHistory(log: invHistory | otherHistory): log is invHistory {
    return (
      (log as invHistory).fromId !== undefined ||
      (log as invHistory).toId !== undefined
    );
  }

  return (
    <div className="flex flex-row gap-2">
      <div className="font-medium">{dayjs(date).format("MM월 DD일")}</div>
      <div className="flex flex-col gap-2 flex-1">
        <div className="flex flex-row gap-2 items-center">
          <div className="overflow-clip flex-1 w-0 truncate tracking-[.20em] text-gray-500">
            ---------------------------------------------------------------------------------------------------------------------
          </div>
        </div>
        {logs.map(log =>
          isCustomerHistory(log) ? (
            <CustomerLog customerHistory={log} key={`customerLog:${log.id}`} />
          ) : isInvHistory(log) ? (
            <InvLog invHistory={log} key={`invLog:${log.id}`} />
          ) : (
            <OtherLog otherHistory={log} key={`otherLog:${log.id}`} />
          )
        )}
      </div>
    </div>
  );
}
