import { useMutation } from "@apollo/client";
import { DELETE_USER } from "../../../../../gqls/user";
import { User } from "../../../../../types/user";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface props {
  user: User;
}

export default function UserDeleteControl({ user }: props) {
  const [deleting, setDeleting] = useState(false);
  const [nameCheck, setNameCheck] = useState("");

  const [remove, { loading }] = useMutation(DELETE_USER);

  const navigate = useNavigate();

  const deleteUser = () => {
    remove({
      variables: {
        id: user.id,
      },
      onCompleted() {
        navigate("/settings/user");
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: user.id,
          __typename: "users",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };
  return (
    <div className="flex flex-row justify-end">
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteForever />}
        onClick={() => {
          setDeleting(true);
        }}
      >
        삭제
      </Button>

      <Dialog
        open={deleting}
        onClose={() => {
          setDeleting(false);
        }}
      >
        <DialogTitle>사용자 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            사용자를 삭제할 경우 데이터의 무결성이 하향될 수 있습니다. 가능하면
            사용자의 상태를 변경하는 것을 추천합니다. 정말 사용자를 삭제하시려면
            아래 사용자의 이름을 입력해주세요.
          </DialogContentText>
          <TextField
            className="shadow-md col-span-1"
            size="small"
            margin="dense"
            autoComplete="off"
            type="text"
            color="success"
            placeholder={user.name}
            value={nameCheck}
            onChange={e => {
              setNameCheck(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setDeleting(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={user.name !== nameCheck}
            loading={loading}
            variant="contained"
            color="error"
            onClick={deleteUser}
          >
            삭제
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
