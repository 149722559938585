import { TypedDocumentNode, gql } from "@apollo/client";
import { otherHistory } from "../types/common";
import { OTHER_HISTORY_FIELDS } from "../fragments/common";

export const GET_OTHER_HISTORY_BY_ID: TypedDocumentNode<{
  otherHistories_by_pk: otherHistory;
}> = gql`
  ${OTHER_HISTORY_FIELDS}
  query GET_OTHER_HISTORY_BY_ID($id: Int!) {
    otherHistories_by_pk(id: $id) {
      ...OtherHistoryFields
    }
  }
`;

export const GET_OTHER_HISTORIES_CONDITIONAL: TypedDocumentNode<{
  otherHistories: otherHistory[];
}> = gql`
  ${OTHER_HISTORY_FIELDS}
  query GET_OTHER_HISTORIES_CONDITIONAL($where: otherHistories_bool_exp!) {
    otherHistories(where: $where, limit: 200, order_by: { created_at: asc }) {
      ...OtherHistoryFields
    }
  }
`;

export const UPDATE_OTHER_HISTORY_BY_ID: TypedDocumentNode<{
  update_otherHistories_by_pk: otherHistory;
}> = gql`
  ${OTHER_HISTORY_FIELDS}
  mutation UPDATE_OTHER_HISTORY_BY_ID(
    $id: Int!
    $set: otherHistories_set_input!
  ) {
    update_otherHistories_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...OtherHistoryFields
    }
  }
`;

export const ADD_OTHER_HISTORY = gql`
  ${OTHER_HISTORY_FIELDS}
  mutation ADD_OTHER_HISTORY($object: otherHistories_insert_input!) {
    insert_otherHistories_one(object: $object) {
      ...OtherHistoryFields
    }
  }
`;

export const DELETE_OTHER_HISTORY = gql`
  mutation DELETE_OTHER_HISTORY($id: Int!) {
    delete_otherHistories_by_pk(id: $id) {
      id
    }
  }
`;

export const STREAM_OTHER_HISTORIES: TypedDocumentNode<{
  otherHistories_stream: otherHistory[];
}> = gql`
  ${OTHER_HISTORY_FIELDS}
  subscription STREAM_OTHER_HISTORIES(
    $where: otherHistories_bool_exp
    $cursor: [otherHistories_stream_cursor_input]!
  ) {
    otherHistories_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...OtherHistoryFields
    }
  }
`;
