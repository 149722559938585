import dayjs from "dayjs";
import MyBadge from "../../../../../../comps/myBadge";
import { task } from "../../../../../../types/task";
import { NavLink } from "react-router-dom";

interface props {
  task: task;
  inTask?: boolean;
}

export default function CustomerTask({ task, inTask }: props) {
  const { date, assignedTo, status, type } = task;

  const to = inTask
    ? `/tasks/detail/${task.id}`
    : `../progress/task/${task.id}`;
  return (
    <NavLink to={to}>
      <div className="flex flex-col gap-1 p-2 ring-1 ring-gray-100 bg-white shadow-md rounded-sm">
        <div className="flex flex-row gap-2 items-center">
          <div className="w-0 flex-1 truncate text-sm">
            {dayjs(date).format("YYYY년 MM월 DD일")}
          </div>
          <MyBadge text={type.name} color={type.color} />
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="w-0 flex-1 truncate font-medium text-lg">
            {assignedTo?.name || "작업자 미정"}
          </div>
          <MyBadge text={status.name} color={status.color} fill />
        </div>
      </div>
    </NavLink>
  );
}
