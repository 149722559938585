import { z } from "zod";
import { action, automation, trigger } from "../../../../../types/automation";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import {
  ADD_AUTOMATION,
  UPDATE_AUTOMATION_BY_ID,
} from "../../../../../gqls/automation";
import { useNavigate, Link } from "react-router-dom";
import { AUTOMATION_FIELDS } from "../../../../../fragments/automation";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ReplyIcon from "@mui/icons-material/Reply";
import AutomationBiztalkControl from "./automationBiztalkControl";
import AutomationCustomerTriggerControl from "./automationCustomerTriggerControl";
import AutomationTaskTriggerControl from "./automationTaskTriggerControl";
import AutomationCustomerStatControl from "./automationCustomerStatControl";
import AutomationTaskStatControl from "./automationTaskStatControl";
import AutomationReportTypeControl from "./automationReportTypeControl";
import AutomationDateControl from "./automationDateControl";
import AutomationInvControl from "./automationInvControl";
import AutomationDeleteControl from "./automationDeleteControl";
import AutomationDateFromControl from "./automationDateFromControl";

interface props {
  automation?: automation;
}

const schema = z.object({
  trigger: z.string().min(1, { message: "required" }),
  action: z.string().min(1, { message: "required" }),
  title: z.string().optional().nullable(),
  fromCustomerStatIds: z.array(z.coerce.number()).optional().nullable(),
  toCustomerStatId: z.coerce.number().optional().nullable(),
  fromTaskStatIds: z.array(z.coerce.number()).optional().nullable(),
  taskTypeIds: z.array(z.coerce.number()).optional().nullable(),
  toTaskStatId: z.coerce.number().optional().nullable(),
  reportTypeIds: z.array(z.coerce.number()).optional().nullable(),
  biztalkId: z.coerce.number().optional().nullable(),
  biztalkVars: z
    .array(
      z.object({
        name: z.string(),
        value: z.string(),
      })
    )
    .nullable()
    .optional(),
  schedule: z.any(),
  dateFrom: z.string().optional().nullable(),
  date: z.coerce.number().optional().nullable(),
  invTo: z.string().optional().nullable(),
  invActionId: z.coerce.number().optional().nullable(),
});

export default function AutomationControl({ automation }: props) {
  const defaultValues: Omit<automation, "id" | "created_at" | "updated_at"> = {
    trigger: automation?.trigger || "고객 등록",
    action: automation?.action || "비즈톡 전송",
    biztalkId: automation?.biztalkId || null,
    biztalkVars: automation?.biztalkVars || null,
    fromCustomerStatIds: automation?.fromCustomerStatIds || null,
    toCustomerStatId: automation?.toCustomerStatId || null,
    fromTaskStatIds: automation?.fromTaskStatIds || null,
    taskTypeIds: automation?.taskTypeIds || null,
    toTaskStatId: automation?.toTaskStatId || null,
    reportTypeIds: automation?.reportTypeIds || null,
    dateFrom: automation?.dateFrom || null,
    date: automation?.date || null,
    invTo: automation?.invTo || null,
    invActionId: automation?.invActionId || null,
    schedule: automation?.schedule || null,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isDirty },
  } = methods;

  const trigger = useWatch({
    control,
    name: "trigger",
  });

  const [insert, { loading: inserting }] = useMutation(ADD_AUTOMATION);

  const [update, { loading: updating }] = useMutation(UPDATE_AUTOMATION_BY_ID);

  const loading = inserting || updating;

  const navigate = useNavigate();

  const onSubmit = handleSubmit(data => {
    const {
      trigger,
      action,
      fromCustomerStatIds,
      fromTaskStatIds,
      taskTypeIds,
      reportTypeIds,
      // invTo,
      toTaskStatId,
      toCustomerStatId,
      biztalkId,
      biztalkVars,
    } = data;

    if (
      ["고객 등록", "고객 상태 변경"].includes(trigger) &&
      (!fromCustomerStatIds || fromCustomerStatIds.length == 0)
    ) {
      return;
    }

    if (["작업 등록", "작업 상태 변경"].includes(trigger)) {
      if (!fromTaskStatIds || fromTaskStatIds.length == 0) {
        return;
      }
      if (!taskTypeIds || taskTypeIds.length == 0) {
        return;
      }
    }

    if (action == "작업 상태 변경" && !toTaskStatId) {
      return;
    }

    if (action == "고객 상태 변경" && !toCustomerStatId) {
      return;
    }

    if (action == "비즈톡 전송") {
      if (!biztalkId) {
        return;
      }
      if (biztalkVars) {
        if (biztalkVars.some(v => !v.value)) {
          return;
        }
      }
    }

    const _data = {
      ...data,
      invActionId: data.invActionId || undefined,
    };

    if (automation) {
      update({
        variables: { id: automation.id, set: _data },
        onCompleted(returning) {
          reset(returning.update_automations_by_pk);
          navigate("/settings/automation");
        },
      });
    } else {
      insert({
        variables: { object: _data },
        onCompleted() {
          reset();
          navigate("/settings/automation");
        },
        update(cache, { data: { insert_automations_one: newAutomation } }) {
          cache.modify({
            fields: {
              automations(existing = []) {
                const newAutomationRef = cache.writeFragment({
                  data: newAutomation,
                  fragment: AUTOMATION_FIELDS,
                });
                return [...existing, newAutomationRef];
              },
            },
          });
        },
      });
    }
  });

  const cancel = () => {
    reset();
    if (!automation) {
      navigate("/settings/automation");
    }
  };

  const triggers: trigger[] = [
    "고객 등록",
    "고객 삭제",
    "고객 상태 변경",
    "작업 등록",
    "작업 삭제",
    "작업 상태 변경",
    "보고 등록",
    "정산 등록",
  ];

  const actions: action[] = [
    "고객 상태 변경",
    "고객 접수날짜 변경",
    "고객 AS날짜 변경",
    "고객 보증기간 변경",
    "고객 설치날짜 변경",
    "작업 상태 변경",
    "비즈톡 전송",
    "재고 변경",
    // "재고 소진",
    // "메일 전송",
    // "일정 등록",
  ];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-4 py-4 md:p-8 w-full"
      >
        <div className="flex flex-row items-center justify-between">
          <h1 className="mb-4">자동화 {automation ? "수정" : "등록"}</h1>
          <Link to="/settings/automation" className="hidden md:flex">
            <div className="hover:text-quezone">
              <ReplyIcon />
            </div>
          </Link>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label className="font-semibold">실행 조건</label>
            <Controller
              name="trigger"
              control={control}
              render={({ field }) => (
                <Select
                  margin="none"
                  size="small"
                  required
                  className="shadow-md"
                  color="success"
                  defaultValue={defaultValues.trigger}
                  value={field.value || ""}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                  ref={field.ref}
                  disabled={field.disabled}
                >
                  {triggers.map(t => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </div>
          <AutomationCustomerTriggerControl automation={automation} />
          <AutomationTaskTriggerControl automation={automation} />
          <AutomationReportTypeControl automation={automation} />
          <div className="flex flex-col gap-1">
            <label className="font-semibold">실행 사항</label>
            <Controller
              name="action"
              control={control}
              render={({ field }) => (
                <Select
                  margin="none"
                  size="small"
                  required
                  className="shadow-md"
                  color="success"
                  defaultValue={defaultValues.action}
                  value={field.value || ""}
                  onChange={e => {
                    setValue("invActionId", 0);
                    field.onChange(e.target.value);
                  }}
                  onBlur={field.onBlur}
                  name={field.name}
                  ref={field.ref}
                  disabled={field.disabled}
                >
                  {actions
                    .filter(a => !a.includes(trigger))
                    .map(a => (
                      <MenuItem key={a} value={a}>
                        {a}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </div>
          <AutomationBiztalkControl />
          <AutomationCustomerStatControl />
          <AutomationTaskStatControl />
          <AutomationDateFromControl />
          <AutomationDateControl />
          <AutomationInvControl />
        </div>

        <div className="flex flex-row gap-2 justify-end items-center mt-2">
          {automation && <AutomationDeleteControl automation={automation} />}
          {isDirty && (
            <>
              <Button color="success" onClick={cancel}>
                취소
              </Button>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                color="success"
                sx={{
                  backgroundColor: "black",
                  fontWeight: 500,
                }}
              >
                {automation ? "수정" : "등록"}
              </LoadingButton>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
