import AutoSizer from "react-virtualized-auto-sizer";
import "react-virtualized/styles.css";
import List from "react-virtualized/dist/commonjs/List";
import {
  CellMeasurerCache,
  ListRowRenderer,
  CellMeasurer,
} from "react-virtualized";
import ListKanbanItem from "./listKanbanItem/listKanbanItem";
import { NavLink } from "react-router-dom";
import { task } from "../../../../../types/task";
import { useResizeObserver } from "usehooks-ts";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import { useRef } from "react";

const cache = new CellMeasurerCache({
  defaultHeight: 150,
  fixedWidth: true,
});

interface props {
  tasks: task[];
  loadMore: () => void;
  canLoadMore: boolean;
  loading: boolean;
  fetchingMore: boolean;
  totalLength: number;
}

export default function TaskList({
  tasks,
  loadMore,
  canLoadMore,
  fetchingMore,
  totalLength,
  loading,
}: props) {
  const Row: ListRowRenderer = ({ index, style, key, parent }) => (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={key}
      parent={parent}
      rowIndex={index}
    >
      {({ measure, registerChild }) => (
        // @ts-expect-error
        <div style={style} key={index} ref={registerChild || null}>
          <NavLink to={`/tasks/detail/${tasks[index]?.id}`}>
            <ListKanbanItem measure={measure} task={tasks[index]} />
          </NavLink>
        </div>
      )}
    </CellMeasurer>
  );

  const isItemLoaded = (index: number) =>
    !canLoadMore || index < tasks.length - 1;

  const ref = useRef<HTMLDivElement>(null);

  const onResize = () => {
    cache.clearAll();
  };

  useResizeObserver({
    ref,
    onResize,
  });
  return (
    <div className="h-full flex-1 md:px-10" ref={ref}>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            rowCount={totalLength}
            loadMoreRows={async () => {
              canLoadMore && !loading && !fetchingMore && loadMore();
            }}
            isRowLoaded={({ index }) => {
              const bool = !!tasks[index];
              return bool;
            }}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                width={width}
                height={height}
                itemCount={tasks.length}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                rowCount={tasks.length}
                className="outline-none"
                rowRenderer={Row}
                deferredMeasurementCache={cache}
                rowHeight={row => {
                  const height = cache.rowHeight(row);
                  return height + 10;
                }}
              />
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
}
