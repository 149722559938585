import { create } from "zustand";

type Store = {
  customerCursor: string;
  setCustomerCursor: (cursor: string) => void;
  invCursor: string;
  setInvCursor: (cursor: string) => void;
  taskCursor: string;
  setTaskCursor: (cursor: string) => void;
  otherCursor: string;
  setOtherCurosr: (cursor: string) => void;
};

export const useDashboardStore = create<Store>(set => ({
  customerCursor: new Date().toISOString(),
  setCustomerCursor: cursor => set({ customerCursor: cursor }),
  invCursor: new Date().toISOString(),
  setInvCursor: cursor => set({ invCursor: cursor }),
  taskCursor: new Date().toISOString(),
  setTaskCursor: cursor => set({ taskCursor: cursor }),
  otherCursor: new Date().toISOString(),
  setOtherCurosr: cursor => set({ otherCursor: cursor }),
}));
