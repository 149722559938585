import CalendarNav from "../calendarNav";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import Day from "./day";
import "./calendar.css";
import { task } from "../../../../../../../types/task";
import { useTaskStore } from "../../../../../../../store/taskStore";

interface props {
  tasks: task[];
}

export default function MobileCalendar({ tasks }: props) {
  const { selectedDate, setSelectedDate } = useTaskStore();
  return (
    <div className="flex flex-col gap-4">
      {/* Header */}
      <div className="flex flex-row gap-4 items-end">
        <CalendarNav />
      </div>
      {/* Body */}
      <div className="flex-1 mobileCalendar">
        <Calendar
          onChange={(v: any) => {
            setSelectedDate(dayjs(v));
          }}
          calendarType="hebrew"
          activeStartDate={selectedDate.toDate()}
          value={selectedDate.toDate()}
          showNavigation={false}
          formatShortWeekday={(_, date) => {
            return dayjs(date).locale("en").format("ddd");
          }}
          formatDay={(_, date) => {
            return dayjs(date).format("D");
          }}
          tileContent={({ date }) => <Day tasks={tasks} date={date} />}
        />
      </div>
    </div>
  );
}
