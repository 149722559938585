import { useInvStore } from "../../../../store/invStore";
import {
  inventory,
  inventory_populated,
  statPopulatedInvQty,
} from "../../../../types/inv";
import InventoryActionPerStat from "./inventoryActionPerStat";

interface props {
  inventory: inventory_populated;
  statId: number;
  qty: statPopulatedInvQty[];
}

export default function InventoryActionGroup({
  inventory,
  statId,
  qty,
}: props) {
  const { invStatuses } = useInvStore();

  const status = invStatuses.find(s => s.id === statId);
  const totalQty = qty.reduce((acc, cur) => acc + cur.qty, 0);

  return (
    <div className="border-[1px] p-2 flex flex-col">
      <div style={{ color: status?.color }}>
        {status?.name} {totalQty}
      </div>
      <div className="mt-3 flex flex-col gap-2">
        {qty.map(q => (
          <InventoryActionPerStat inventory={inventory} qty={q} key={q.id} />
        ))}
      </div>
    </div>
  );
}
