import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { taskTypeCheck } from "../../../../../../types/taskTypeCheck";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useForm } from "react-hook-form";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import UndoIcon from "@mui/icons-material/Undo";
import CheckIcon from "@mui/icons-material/Check";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_TYPE_CHECK_BY_ID } from "../../../../../../gqls/taskCheck";
import { useTaskStore } from "../../../../../../store/taskStore";
import { useState } from "react";
import TaskTypeCheckDelete from "./taskTypeCheckDelete";

interface props {
  check: taskTypeCheck;
  isOverlay: boolean;
}

export default function TaskTypeCheck({ check, isOverlay }: props) {
  const defaultValues = {
    name: check.name,
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const { updateTaskTypeChecks } = useTaskStore();

  const [update] = useMutation(UPDATE_TASK_TYPE_CHECK_BY_ID);

  const save = handleSubmit(data => {
    update({
      variables: {
        id: check.id,
        set: {
          name: data.name,
        },
      },
      onCompleted: data => {
        updateTaskTypeChecks([data.update_taskTypeChecks_by_pk]);
        reset({ name: data.update_taskTypeChecks_by_pk.name });
      },
    });
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: check.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const [focused, setFocused] = useState(false);

  return (
    <form
      onSubmit={save}
      className={`flex flex-row gap-2 items-center pl-2 group h-6 ${
        isOverlay ? "text-quezone" : ""
      }`}
      ref={setNodeRef}
      style={style}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
    >
      <FiberManualRecordIcon sx={{ width: 10 }} />
      <input
        {...register("name")}
        className=" disabled:bg-transparent flex-1"
      />
      {isDirty && (
        <div className="flex flex-row gap-2 items-center">
          <div
            className="text-red-500 cursor-pointer"
            onClick={() => {
              reset();
            }}
          >
            <UndoIcon />
          </div>
          <div
            className="text-quezone cursor-pointer"
            onClick={() => {
              save();
            }}
          >
            <CheckIcon />
          </div>
        </div>
      )}
      <div className="flex flex-row gap-1 items-center">
        <TaskTypeCheckDelete check={check} focused={focused} />

        <div
          className="md:hidden md:group-hover:block cursor-pointer"
          {...attributes}
          {...listeners}
        >
          <DragIndicatorIcon />
        </div>
      </div>
    </form>
  );
}
