import Switch from "@mui/material/Switch";
import { extendedReport } from "../../../../../types/report";
import { useMutation } from "@apollo/client";
import { UPDATE_REPORT } from "../../../../../gqls/report";
import { useCheckAuth } from "../../../../../store/authStore";

interface props {
  viewOnly?: boolean;
  report: extendedReport;
}

export default function ReportCheckSwitch({ viewOnly, report }: props) {
  const [update] = useMutation(UPDATE_REPORT);

  const onChange = () => {
    update({
      variables: {
        id: report.id,
        set: {
          checked: !report.checked,
        },
      },
      optimisticResponse: {
        update_reports_by_pk: {
          ...report,
          checked: !report.checked,
        },
      },
    });
  };

  const checkAuth = useCheckAuth();

  const permission = checkAuth({
    permissionName: "보고확인처리",
    userId: report.task.assignedToId,
  });

  return (
    <div className="print:hidden flex flex-row justify-end items-center gap-2">
      <span className={report.checked ? "" : "text-gray-500"}>확인완료</span>
      <Switch
        color="success"
        disabled={viewOnly || !permission}
        checked={report.checked}
        onChange={onChange}
      />
    </div>
  );
}
