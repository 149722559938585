import { useNavigate } from "react-router-dom";
import { customer } from "../../../../../../types/customer";
import ListKanbanItem from "../listKanbanItem/listKanbanItem";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

interface props {
  customer: customer;
}
export default function KanbanItem({ customer }: props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: customer.id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  const navigate = useNavigate();

  const toDetail = () => {
    navigate(`/customers/detail/${customer.id}`);
  };

  return (
    <div
      className="shadow-md"
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      onClick={toDetail}
    >
      <ListKanbanItem customer={customer} isKanban={true} />
    </div>
  );
}
