import { TypedDocumentNode, gql } from "@apollo/client";
import { BIZTALK_FIELDS } from "../fragments/biztalk";
import { biztalk } from "../types/biztalk";

export const GET_BIZTALK_BY_ID: TypedDocumentNode<{
  biztalks_by_pk: biztalk;
}> = gql`
  ${BIZTALK_FIELDS}
  query GET_BIZTALK_BY_ID($id: Int!) {
    biztalks_by_pk(id: $id) {
      ...BiztalkFields
    }
  }
`;

export const GET_BIZTALKS: TypedDocumentNode<{
  biztalks: biztalk[];
}> = gql`
  ${BIZTALK_FIELDS}
  query GET_BIZTALKS {
    biztalks {
      ...BiztalkFields
    }
  }
`;

export const UPDATE_BIZTALK_BY_ID: TypedDocumentNode<{
  update_biztalks_by_pk: biztalk;
}> = gql`
  ${BIZTALK_FIELDS}
  mutation UPDATE_BIZTALK_BY_ID($id: Int!, $set: biztalks_set_input!) {
    update_biztalks_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...BiztalkFields
    }
  }
`;

export const ADD_BIZTALK = gql`
  ${BIZTALK_FIELDS}
  mutation ADD_BIZTALK($object: biztalks_insert_input!) {
    insert_biztalks_one(object: $object) {
      ...BiztalkFields
    }
  }
`;

export const DELETE_BIZTALK = gql`
  mutation DELETE_BIZTALK($id: Int!) {
    delete_biztalks_by_pk(id: $id) {
      id
    }
  }
`;
