import { extendedReport } from "../../../../../../types/report";
import { task } from "../../../../../../types/task";
import InstallerSummary from "./installerSummary";
import Charts from "./charts";
import { useState } from "react";
import Button from "@mui/material/Button";

interface props {
  tasks: task[];
  reports: extendedReport[];
}

export default function Settlements({ reports, tasks }: props) {
  const [seeCharts, setSeeCharts] = useState(true);
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-end">
        <Button
          size="small"
          color="success"
          onClick={() => setSeeCharts(!seeCharts)}
        >
          통계 {seeCharts ? "숨기기" : "보기"}
        </Button>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
        {seeCharts && (
          <div className="col-span-1 lg:col-span-3">
            <div className="flex flex-col shadow-md bg-white rounded-md p-4 gap-2">
              <Charts tasks={tasks} />
            </div>
          </div>
        )}
        <div
          className={`${
            seeCharts ? "col-span-1 lg:col-span-2" : "col-span-full"
          } flex flex-col`}
        >
          <InstallerSummary reports={reports} />
        </div>
      </div>
    </div>
  );
}
