import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { toFixed } from "../../../../../../utils/numberMethods";

interface FormContext {
  item: string;
  amount: number;
  gst: number;
}

export default function ReportAccountControls({
  editing,
}: {
  editing?: boolean;
}) {
  const { register, control, setValue } = useFormContext<FormContext>();
  return (
    <div className="flex flex-col gap-4 mt-1">
      <TextField
        label="항목"
        variant="outlined"
        size="small"
        margin="none"
        color="success"
        placeholder="항목"
        {...register("item")}
      />
      <Controller
        name="amount"
        control={control}
        render={({ field }) => (
          <TextField
            label="금액"
            variant="outlined"
            size="small"
            margin="none"
            color="success"
            type="number"
            placeholder="금액"
            {...field}
            onChange={e => {
              field.onChange(e.target.value);
              if (!editing) {
                const gst = toFixed(Number(e.target.value) / 10, 2);
                setValue("gst", gst);
              }
            }}
          />
        )}
      />
      <TextField
        label="부가세"
        variant="outlined"
        size="small"
        margin="none"
        color="success"
        type="number"
        placeholder="부가세"
        disabled={!editing}
        {...register("gst")}
      />
    </div>
  );
}
