import { useCustomerStore } from "../../store/customerStore";
import { TypedDocumentNode, gql, useLazyQuery } from "@apollo/client";
import {
  CUSTOMER_STATUS_FIELDS,
  CUSTOMER_SUB_TYPE_FIELDS,
  CUSTOMER_TYPE_FIELDS,
} from "../../fragments/customer";
import { typeOrStatus } from "../../types/common";
import { AUTH_FIELDS, PERMISSION_FIELDS } from "../../fragments/auth";
import { useAuthStore } from "../../store/authStore";
import { Auth, Permission } from "../../types/auth";
import { useTaskStore } from "../../store/taskStore";
import {
  TASK_STATUS_FIELDS,
  TASK_TYPE_CHECK_FIELDS,
  TASK_TYPE_FIELDS,
} from "../../fragments/task";
import { REPORT_TYPE_FIELDS } from "../../fragments/report";
import { reportType } from "../../types/report";
import { taskTypeCheck } from "../../types/taskTypeCheck";
import { invAction, invStatus } from "../../types/inv";
import { INV_ACTION_FIELDS, INV_STATUS_FIELDS } from "../../fragments/inv";
import { useInvStore } from "../../store/invStore";
import { PRODUCT_FIELDS } from "../../fragments/product";
import { product } from "../../types/product";
import { useProductStore } from "../../store/productStore";
import { useState } from "react";

const LOAD_QUERY: TypedDocumentNode<{
  customerStatuses: typeOrStatus[];
  customerTypes: typeOrStatus[];
  customerSubTypes: typeOrStatus[];
  taskStatuses: typeOrStatus[];
  taskTypes: typeOrStatus[];
  reportTypes: reportType[];
  taskTypeChecks: taskTypeCheck[];
  products: product[];
  invActions: invAction[];
  invStatuses: invStatus[];
  permissions: Permission[];
  auths: Auth[];
}> = gql`
  ${CUSTOMER_STATUS_FIELDS}
  ${CUSTOMER_TYPE_FIELDS}
  ${CUSTOMER_SUB_TYPE_FIELDS}
  ${TASK_STATUS_FIELDS}
  ${TASK_TYPE_FIELDS}
  ${REPORT_TYPE_FIELDS}
  ${TASK_TYPE_CHECK_FIELDS}
  ${PRODUCT_FIELDS}
  ${INV_ACTION_FIELDS}
  ${INV_STATUS_FIELDS}
  ${PERMISSION_FIELDS}
  ${AUTH_FIELDS}
  query LoadQuery {
    customerStatuses {
      ...CustomerStatusFields
    }
    customerTypes {
      ...CustomerTypeFields
    }
    customerSubTypes {
      ...CustomerSubTypeFields
    }
    taskStatuses {
      ...TaskStatusFields
    }
    taskTypes {
      ...TaskTypeFields
    }
    taskTypeChecks {
      ...TaskTypeCheckFields
    }
    reportTypes {
      ...ReportTypeFields
    }
    products {
      ...ProductFields
    }
    invActions {
      ...InvActionFields
    }
    invStatuses {
      ...InvStatusFields
    }
    permissions {
      ...PermissionFields
    }
    auths {
      ...AuthFields
    }
  }
`;

export default function useLoad() {
  const { setTypes, setStatuses, setSubTypes } = useCustomerStore();
  const {
    setTypes: setTaskTypes,
    setStatuses: setTaskStatuses,
    setReportTypes,
    setTaskTypeChecks,
  } = useTaskStore();
  const { setPermissions, setAuths } = useAuthStore();
  const { setInvActions, setInvStatuses } = useInvStore();
  const { setProducts } = useProductStore();

  const [loading, setLoading] = useState(true);

  const [load] = useLazyQuery(LOAD_QUERY, {
    onCompleted(data) {
      // TODO, persist loaded data, update only if data differs
      setStatuses(data.customerStatuses);
      setTypes(data.customerTypes);
      setSubTypes(data.customerSubTypes);
      setPermissions(data.permissions);
      setAuths(data.auths);
      setTaskStatuses(data.taskStatuses);
      setTaskTypes(data.taskTypes);
      setReportTypes(data.reportTypes);
      setTaskTypeChecks(data.taskTypeChecks);
      setInvActions(data.invActions);
      setInvStatuses(data.invStatuses);
      setProducts(data.products);

      setLoading(false);
    },
    onError(error) {
      console.log(error);
    },
  });

  return { load, loading };
}
