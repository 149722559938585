import { useQuery } from "@apollo/client";
import { GET_USERS } from "../gqls/user";
import { useAuthStore } from "../store/authStore";

export default function useGetRefereeUsers() {
  const { data, loading } = useQuery(GET_USERS);
  const users = data?.users || [];
  const { user, permissions } = useAuthStore();
  const RefereePermission = permissions.find(p => p.name == "추천인피지명");
  let refereeUsers = users.filter(
    u =>
      u.auth.permissions.find(
        p => RefereePermission && p.id == RefereePermission.id
      ) || u.auth.name == "admin"
  );

  refereeUsers = refereeUsers.filter(u => {
    if (user?.auth.name == "admin") {
      return true;
    }
    if (!u.company) {
      return true;
    }
    if (u.company && user?.company) {
      if (user.company.id == u.company.id) {
        return true;
      }
      if (user.company.subCompanies?.includes(u.company.id)) {
        return true;
      }
    }
    return false;
  });

  return { loading, refereeUsers };
}
