import { product } from "../../../../types/product";
import { useQuery } from "@apollo/client";
import { GET_POPULATED_INV_QTY_CONDITIONAL } from "../../../../gqls/inv";
import { useInvStore } from "../../../../store/invStore";
import ProductListItemPC from "./productListItemPC";
import ProductListItemMobile from "./productListItemMobile";
import { company } from "../../../../types/company";

interface props {
  product: product;
  includeNull: boolean;
  mode: string;
  index?: number;
  companies: company[];
}

export default function ProductListItem({
  product,
  includeNull,
  mode,
  index,
  companies,
}: props) {
  const { data: invData } = useQuery(GET_POPULATED_INV_QTY_CONDITIONAL, {
    variables: {
      where: {
        _and: {
          inventory: {
            productId: {
              _eq: product.id,
            },
          },
          _or: [
            {
              inventory: {
                companyId: {
                  _in: companies.map(c => c.id),
                },
              },
            },
            {
              inventory: {
                user: {
                  companyId: {
                    _in: companies.map(c => c.id),
                  },
                },
              },
            },
          ],
        },
      },
    },
  });

  const qties = (invData?.invQty || []).map(q => ({
    ...q,
    groupCompanyId: q.inventory.companyId || q.inventory.user?.companyId || 0,
  }));

  const { invStatuses } = useInvStore();

  const qtyPerStat = invStatuses
    .filter(s => s.available)
    .map(stat => {
      return {
        ...stat,
        qty: qties
          .filter(qty => qty.statusId === stat.id)
          .reduce((acc, qty) => acc + qty.qty, 0),
      };
    })
    .filter(q => q.qty > 0);

  const totalQty = qtyPerStat.reduce((acc, qty) => acc + qty.qty, 0);

  const qtyPerCompany = qties.reduce<
    { companyId: number; qties: typeof qties }[]
  >((acc, qty) => {
    if (acc.find(a => a.companyId == qty.groupCompanyId)) {
      return acc.map(a => ({
        ...a,
        qties:
          a.companyId == qty.groupCompanyId ? a.qties.concat(qty) : a.qties,
      }));
    }
    return acc.concat({
      companyId: qty.groupCompanyId,
      qties: [qty],
    });
  }, []);

  if (totalQty === 0 && !includeNull) return null;

  return (
    <>
      {mode == "PC" ? (
        <ProductListItemPC
          product={product}
          companies={companies}
          qtyPerCompany={qtyPerCompany}
          qtyPerStat={qtyPerStat}
          totalQty={totalQty}
        />
      ) : (
        <ProductListItemMobile
          product={product}
          companies={companies}
          qtyPerCompany={qtyPerCompany}
          qtyPerStat={qtyPerStat}
          totalQty={totalQty}
          index={index}
        />
      )}
    </>
  );
}
