import { getFCMToken, getMyMessaging } from "../firebase";
import { MessagePayload, onMessage } from "firebase/messaging";
import {
  useNotificationStore,
  StampedMessagePayload,
  useAddNotification,
} from "../store/notificationStore";
import ping from "/notification-sound.mp3";
import { useEffect } from "react";
import { useAuthStore } from "../store/authStore";

const pingSound = new Audio(ping);

interface bgNotification {
  id: string;
  title: string;
  body: string;
  path?: string;
}

export const useNotification = () => {
  // const db = useIndexedDB("bgNotifications");
  const addNotification = useAddNotification();
  const { setToken } = useNotificationStore();

  const { user } = useAuthStore();

  function getBGNotifications() {
    const request = indexedDB.open("notifications", 1);

    request.onupgradeneeded = function (event) {
      // console.log("upgradeNeeded", event);
      // @ts-ignore
      const db = event.target?.result;

      // console.log("upgradeNeeded DB", db);

      if (!db) {
        return;
      }

      const store = db.createObjectStore("bgNotifications", {
        keyPath: "id",
      });
      // define what data items the objectStore will contain
      store.createIndex("id", "id", { unique: false });
      store.createIndex("title", "title", { unique: false });
      store.createIndex("body", "body", { unique: false });
      store.createIndex("path", "path", { unique: false });
    };

    // @ts-ignore
    request.onsuccess = function (e) {
      // console.log("success", e);
      const db = request.result;

      const transaction = db.transaction(["bgNotifications"], "readwrite");
      const store = transaction.objectStore("bgNotifications");

      // console.log("store", store);

      store.getAll().onsuccess = function (event) {
        // console.log("storeSuccess", event);
        // @ts-ignore
        const result = event.target?.result as bgNotification[];

        // console.log(result);
        result.forEach(notification => {
          const converted: StampedMessagePayload = {
            collapseKey: "",
            from: "",
            messageId: notification.id,
            data: {
              ...notification,
              path: notification.path || "",
            },
            notification: notification,
            timestamp: new Date(),
          };
          addNotification(converted);
        });
      };

      store.clear();
    };

    request.onblocked = function (e) {
      console.log("blocked", e);
    };

    request.onerror = function (err) {
      console.log("error", err);
    };
  }

  useEffect(() => {
    window.addEventListener("focus", getBGNotifications);
    return () => {
      window.removeEventListener("focus", getBGNotifications);
    };
  }, []);

  useEffect(() => {
    getBGNotifications();
  }, [user]);

  const handleFgMsg = (res: MessagePayload) => {
    const converted = { ...res, timestamp: new Date() };
    addNotification(converted);
    pingSound.play();
  };

  const handleNotificationPermission = () => {
    return new Promise(async resolve => {
      if (Notification?.permission == "granted") {
        resolve(true);
        return;
      }
      if (Notification?.permission !== "denied") {
        Notification?.requestPermission()
          .then(permission => {
            if (permission == "granted") {
              resolve(true);
              return;
            }
            resolve(false);
          })
          .catch(e => {
            console.log(e);
            resolve(false);
          });
      } else {
        resolve(true);
      }
    });
  };

  const initNotification = async () => {
    try {
      if (!("Notification" in window)) {
        return console.log("notification API not availalbe");
      }

      const proceed = await handleNotificationPermission();

      if (!proceed) {
        console.log("notification permission not allowd");
        return;
      }

      const token = await getFCMToken();

      // console.log(token);

      setToken(token);
      const messaging = getMyMessaging();
      if (messaging) {
        onMessage(messaging, handleFgMsg);
      }

      return token;
    } catch (error) {
      console.log(error);
      return;
    }
  };

  return { initNotification };
};
