import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import BG from "/quezone.svg";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import AvatarControl from "./avatarControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import generatePastelColor from "../../utils/genPastelColor";
import { TypedDocumentNode, gql, useQuery } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import { company } from "../../types/company";
import { NewUser } from "../../types/user";
import { useRegister } from "../../store/authStore";
import { useNavigate } from "react-router-dom";

const GET_COMPANIES_REGISTER_PAGE: TypedDocumentNode<{
  companies: Partial<company>[];
}> = gql`
  query GetCompaniesRegisterPage {
    companies(where: { noUsers: { _eq: false }, active: { _eq: true } }) {
      id
      name
    }
  }
`;

const registerSchema = z
  .object({
    email: z.string().email({ message: "올바른 이메일 형식이 아닙니다" }),
    password: z
      .string()
      .min(8, { message: "비밀번호는 8자리 이상이어야 합니다" }),
    passwordConfirm: z
      .string()
      .min(8, { message: "비밀번호는 8자리 이상이어야 합니다" }),
    name: z.string().min(1, { message: "이름을 입력해주세요" }),
    mobile: z.string().optional().nullable(),
    address: z.string().optional().nullable(),
    role: z.string().optional().nullable(),
    color: z.string().optional().nullable(),
    avatar: z.any().optional().nullable(),
    company: z.coerce.number(),
  })
  .refine(data => data.password === data.passwordConfirm, {
    message: "비밀번호가 일치하지 않습니다",
    path: ["passwordConfirm"],
  });

export default function Register() {
  const { data } = useQuery(GET_COMPANIES_REGISTER_PAGE);

  const companies = data?.companies || [];

  const defaultValues: NewUser = {
    email: "",
    password: "",
    passwordConfirm: "",
    name: "",
    mobile: null,
    address: null,
    role: null,
    color: generatePastelColor(),
    avatar: null,
    company: 0,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(registerSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    control,
  } = methods;

  const registerUser = useRegister();

  const onSubmit = handleSubmit(data => {
    registerUser(data);
  });

  const company = useWatch({
    control,
    name: "company",
  });

  const navigate = useNavigate();

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className="flex flex-col py-20 px-10 h-[100dvh] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-slate-700"
      >
        <img src={BG} className="max-w-[250px] m-auto mb-12" />
        {/* Avatar */}
        <AvatarControl />
        {/* Email */}
        <FormControl fullWidth>
          <TextField
            className="shadow-lg"
            error={errors.email && touchedFields.email}
            margin="normal"
            required
            id="id"
            label="이메일"
            autoComplete="off"
            autoFocus
            type="email"
            color="success"
            placeholder="이메일"
            {...register("email")}
            InputLabelProps={{
              style: {
                fontWeight: 500,
              },
            }}
          />
          <FormHelperText error={errors.email && touchedFields.email}>
            {touchedFields.email && errors.email?.message}
          </FormHelperText>
        </FormControl>
        {/* Password */}
        <FormControl fullWidth>
          <TextField
            className="shadow-lg"
            error={errors.password && touchedFields.password}
            margin="normal"
            required
            id="password"
            label="비밀번호"
            autoComplete="off"
            color="success"
            type="password"
            placeholder="비밀번호"
            {...register("password")}
            InputLabelProps={{
              style: {
                fontWeight: 500,
              },
            }}
          />
          <FormHelperText error={errors.password && touchedFields.password}>
            {touchedFields.password && errors.password?.message}
          </FormHelperText>
        </FormControl>
        {/* Password Confirm*/}
        <FormControl fullWidth>
          <TextField
            className="shadow-lg"
            error={errors.passwordConfirm && touchedFields.passwordConfirm}
            margin="normal"
            required
            id="passwordConfirm"
            label="비밀번호 확인"
            color="success"
            type="password"
            placeholder="비밀번호 확인"
            {...register("passwordConfirm")}
            InputLabelProps={{
              style: {
                fontWeight: 500,
              },
            }}
          />
          <FormHelperText
            error={errors.passwordConfirm && touchedFields.passwordConfirm}
          >
            {touchedFields.passwordConfirm && errors.passwordConfirm?.message}
          </FormHelperText>
        </FormControl>
        {/* Name */}
        <FormControl fullWidth>
          <TextField
            className="shadow-lg"
            error={errors.name && touchedFields.name}
            margin="normal"
            required
            id="name"
            label="이름"
            autoComplete="name"
            color="success"
            placeholder="이름"
            {...register("name")}
            InputLabelProps={{
              style: {
                fontWeight: 500,
              },
            }}
          />
          <FormHelperText error={errors.name && touchedFields.name}>
            {touchedFields.name && errors.name?.message}
          </FormHelperText>
        </FormControl>
        {/* Mobile */}
        <FormControl fullWidth>
          <TextField
            className="shadow-lg"
            error={errors.mobile && touchedFields.mobile}
            margin="normal"
            id="mobile"
            label="연락처"
            autoComplete="mobile"
            color="success"
            placeholder="연락처"
            {...register("mobile")}
            InputLabelProps={{
              style: {
                fontWeight: 500,
              },
            }}
          />
          <FormHelperText error={errors.mobile && touchedFields.mobile}>
            {touchedFields.mobile && errors.mobile?.message}
          </FormHelperText>
        </FormControl>
        {/* Address */}
        <FormControl fullWidth>
          <TextField
            className="shadow-lg"
            error={errors.address && touchedFields.address}
            margin="normal"
            id="address"
            label="주소"
            autoComplete="address"
            color="success"
            placeholder="주소"
            {...register("address")}
            InputLabelProps={{
              style: {
                fontWeight: 500,
              },
            }}
          />
          <FormHelperText error={errors.address && touchedFields.address}>
            {touchedFields.address && errors.address?.message}
          </FormHelperText>
        </FormControl>
        {/* Company */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="company" sx={{ fontWeight: 500 }}>
            회사
          </InputLabel>
          <Select
            required
            error={errors.company && touchedFields.company}
            labelId="company"
            id="company"
            label="회사"
            color="success"
            defaultValue={0}
            {...register("company")}
          >
            <MenuItem value={0}>회사를 선택하세요</MenuItem>
            {companies &&
              companies.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
          </Select>
          {errors.company && touchedFields.company && (
            <FormHelperText
              id="my-helper-text"
              error={errors.company && touchedFields.company}
              style={{
                marginTop: "14px",
              }}
            >
              {touchedFields.company && errors.company.message}
            </FormHelperText>
          )}
        </FormControl>
        {/* Role */}
        {company !== 0 && (
          <FormControl fullWidth>
            <TextField
              className="shadow-lg"
              error={errors.role && touchedFields.role}
              margin="normal"
              id="role"
              label="직함"
              autoComplete="role"
              color="success"
              placeholder="대리, 기사..."
              {...register("role")}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <FormHelperText error={errors.role && touchedFields.role}>
              {touchedFields.role && errors.role?.message}
            </FormHelperText>
          </FormControl>
        )}

        {/* Color */}
        <FormControl fullWidth>
          <TextField
            className="shadow-lg"
            error={errors.color && touchedFields.color}
            margin="normal"
            id="color"
            label="개인 색상"
            autoComplete="color"
            color="success"
            {...register("color")}
            type="color"
            defaultValue="#ffffff"
            InputLabelProps={{
              style: {
                fontWeight: 500,
              },
            }}
          />
          <FormHelperText error={errors.color && touchedFields.color}>
            {touchedFields.color && errors.color?.message}
          </FormHelperText>
        </FormControl>

        {/* Actions */}
        <LoadingButton
          type="submit"
          fullWidth
          // loading={loading}
          variant="contained"
          color="success"
          sx={{
            mt: 3,
            mb: 2,
            height: "56px",
            backgroundColor: "black",
            fontWeight: 500,
          }}
        >
          가입
        </LoadingButton>

        <div className="flex flex-row justify-end items-center text-sm gap-2">
          이미 가입하셨나요?
          <div
            className="text-quezone font-semibold cursor-pointer"
            onClick={() => {
              navigate("/login");
            }}
          >
            로그인
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
