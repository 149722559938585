import { TypedDocumentNode, gql } from "@apollo/client";
import { tag, typeOrStatus } from "../types/common";
import {
  CUSTOMER_TYPE_FIELDS,
  CUSTOMER_STATUS_FIELDS,
  CUSTOMER_CORE_FIELDS,
  CUSTOMER_TAG_FIELDS,
  CUSTOMER_FIELDS,
  CUSTOMER_HISTORY_CORE_FIELDS,
  CUSTOMER_PRICE_FIELDS,
  PAYMENT_METHOD_FIELDS,
  CUSTOMER_PAYMENT_FIELDS,
  CUSTOMER_SUB_TYPE_FIELDS,
} from "../fragments/customer";
import { PRODUCT_FIELDS } from "../fragments/product";
import { COMPANY_FIELDS, COMPANY_TYPE_FIELDS } from "../fragments/company";
import { product } from "../types/product";
import { company } from "../types/company";
import { source, sourceDetailExtended } from "../types/source";
import { SOURCE_DETAIL_FIELDS, SOURCE_FIELDS } from "../fragments/source";
import {
  customer,
  customerHistory,
  customerPayment,
  customerPrice,
  extendedCustomer,
  paymentMethod,
} from "../types/customer";
import { User } from "../types/user";
import { USER_CORE_FIELDS } from "../fragments/user";

export const ADD_CUSTOMER_PAYMENT = gql`
  ${CUSTOMER_PAYMENT_FIELDS}
  mutation ADD_CUSTOMER_PAYMENT($object: customerPayments_insert_input!) {
    insert_customerPayments_one(object: $object) {
      ...CustomerPaymentFields
    }
  }
`;

export const ADD_CUSTOMER_PAYMENT_MANY = gql`
  ${CUSTOMER_PAYMENT_FIELDS}
  mutation ADD_CUSTOMER_PAYMENT_MANY(
    $objects: [customerPayments_insert_input!]!
  ) {
    insert_customerPayments(objects: $objects) {
      returning {
        ...CustomerPaymentFields
      }
    }
  }
`;

export const ADD_CUSTOMER_PAYMENT_MANY_M = gql`
  mutation ADD_CUSTOMER_PAYMENT_MANY_M(
    $objects: [customerPayments_insert_input!]!
  ) {
    insert_customerPayments(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const GET_CUSTOMER_PAYMENT_CONDITIONAL: TypedDocumentNode<{
  customerPayments: customerPayment[];
}> = gql`
  ${CUSTOMER_PAYMENT_FIELDS}
  query GET_CUSTOMER_PAYMENT_CONDITIONAL($where: customerPayments_bool_exp) {
    customerPayments(where: $where) {
      ...CustomerPaymentFields
    }
  }
`;

export const UPDATE_CUSTOMER_PAYMENT_BY_ID: TypedDocumentNode<{
  update_customerPayments_by_pk: customerPayment;
}> = gql`
  ${CUSTOMER_PAYMENT_FIELDS}
  mutation UPDATE_CUSTOMER_PAYMENT_BY_ID(
    $id: Int!
    $set: customerPayments_set_input!
  ) {
    update_customerPayments_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...CustomerPaymentFields
    }
  }
`;

export const DELETE_CUSTOMER_PAYMENT = gql`
  mutation DELETE_CUSTOMER_PAYMENT($id: Int!) {
    delete_customerPayments_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_PAYMENT_METHODS: TypedDocumentNode<{
  paymentMethods: paymentMethod[];
}> = gql`
  ${PAYMENT_METHOD_FIELDS}
  query GET_PAYMENT_METHODS {
    paymentMethods {
      ...PaymentMethodFields
    }
  }
`;

export const ADD_CUSTOMER_PRICE = gql`
  ${CUSTOMER_PRICE_FIELDS}
  mutation ADD_CUSTOMER_PRICE($object: customerPrices_insert_input!) {
    insert_customerPrices_one(object: $object) {
      ...CustomerPriceFields
    }
  }
`;

export const ADD_CUSTOMER_PRICE_M = gql`
  mutation ADD_CUSTOMER_PRICE_M($object: customerPrices_insert_input!) {
    insert_customerPrices_one(object: $object) {
      id
    }
  }
`;

export const GET_CUSTOMER_PRICE_CONDITIONAL: TypedDocumentNode<{
  customerPrices: customerPrice[];
}> = gql`
  ${CUSTOMER_PRICE_FIELDS}
  query GET_CUSTOMER_PRICE_CONDITIONAL($where: customerPrices_bool_exp) {
    customerPrices(where: $where) {
      ...CustomerPriceFields
    }
  }
`;

export const UPDATE_CUSTOMER_PRICE_BY_ID: TypedDocumentNode<{
  update_customerPrices_by_pk: customerPrice;
}> = gql`
  ${CUSTOMER_PRICE_FIELDS}
  mutation UPDATE_CUSTOMER_PRICE_BY_ID(
    $id: Int!
    $set: customerPrices_set_input!
  ) {
    update_customerPrices_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...CustomerPriceFields
    }
  }
`;

export const ADD_CUSTOMER_HISTORY = gql`
  ${CUSTOMER_HISTORY_CORE_FIELDS}
  mutation ADD_CUSTOMER_HISTORY($object: customerHistories_insert_input!) {
    insert_customerHistories_one(object: $object) {
      ...CustomerHistoryCoreFields
    }
  }
`;

export const ADD_CUSTOMER_HISTORY_MANY_M = gql`
  mutation ADD_CUSTOMER_HISTORY_MANY_M(
    $objects: [customerHistories_insert_input!]!
  ) {
    insert_customerHistories(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const GET_CUSTOMER_HISTORY_BY_CUSTOMER_ID: TypedDocumentNode<{
  customerHistories: customerHistory[];
}> = gql`
  ${CUSTOMER_HISTORY_CORE_FIELDS}
  query GET_CUSTOMER_HISTORY_BY_CUSTOMER_ID($customerId: Int!) {
    customerHistories(where: { customerId: { _eq: $customerId } }) {
      ...CustomerHistoryCoreFields
    }
  }
`;

export const STREAM_CUSTOMER_HISTORY_BY_CUSTOMER_ID: TypedDocumentNode<{
  customerHistories_stream: extendedCustomer[];
}> = gql`
  ${CUSTOMER_HISTORY_CORE_FIELDS}
  subscription STREAM_CUSTOMER_HISTORY_BY_CUSTOMER_ID(
    $where: customerHistories_bool_exp
    $cursor: [customerHistories_stream_cursor_input]!
  ) {
    customerHistories_stream(where: $where, cursor: $cursor, batch_size: 1) {
      ...CustomerHistoryCoreFields
    }
  }
`;

export const GET_CUSTOMER_BY_PK: TypedDocumentNode<{
  customers_by_pk: extendedCustomer;
}> = gql`
  ${CUSTOMER_FIELDS}
  query GET_CUSTOMER_BY_PK($id: Int!) {
    customers_by_pk(id: $id) {
      ...CustomerFields
    }
  }
`;

export const STREAM_CUSTOMER_BY_PK: TypedDocumentNode<{
  customers_stream: extendedCustomer[];
}> = gql`
  ${CUSTOMER_FIELDS}
  subscription STREAM_CUSTOMER_BY_PK(
    $where: customers_bool_exp
    $cursor: [customers_stream_cursor_input]!
  ) {
    customers_stream(where: $where, cursor: $cursor, batch_size: 1) {
      ...CustomerFields
    }
  }
`;

export const GET_CUSTOMER_CONDITIONAL: TypedDocumentNode<{
  customers: customer[];
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  query GET_CUSTOMER_CONDITIONAL($where: customers_bool_exp) {
    customers(where: $where, limit: 10) {
      ...CustomerCoreFields
    }
  }
`;

export const UPDATE_CUSTOMER_BY_ID: TypedDocumentNode<{
  update_customers_by_pk: customer;
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  mutation UPDATE_CUSTOMER_BY_ID($id: Int!, $set: customers_set_input!) {
    update_customers_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...CustomerCoreFields
    }
  }
`;

export const READ_CUSTOMER_BY_ID: TypedDocumentNode<{
  update_customers_by_pk: customer;
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  mutation READ_CUSTOMER_BY_ID($id: Int!, $userId: jsonb!) {
    update_customers_by_pk(
      pk_columns: { id: $id }
      _append: { reads: $userId }
    ) {
      ...CustomerCoreFields
    }
  }
`;

export const WATCH_CUSTOMER_BY_ID: TypedDocumentNode<{
  update_customers_by_pk: customer;
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  mutation WATCH_CUSTOMER_BY_ID($id: Int!, $userId: jsonb!) {
    update_customers_by_pk(
      pk_columns: { id: $id }
      _append: { watching: $userId }
    ) {
      ...CustomerCoreFields
    }
  }
`;

export const SET_WATCH_CUSTOMER_BY_ID: TypedDocumentNode<{
  update_customers_by_pk: customer;
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  mutation SET_WATCH_CUSTOMER_BY_ID($id: Int!, $userIds: jsonb!) {
    update_customers_by_pk(
      pk_columns: { id: $id }
      _set: { watching: $userIds }
    ) {
      ...CustomerCoreFields
    }
  }
`;

export const READ_CUSTOMERS: TypedDocumentNode<{
  update_customers: customer[];
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  mutation UPDATE_CUSTOMERS($ids: [Int!]!, $userId: jsonb!) {
    update_customers(
      where: { id: { _in: $ids } }
      _append: { reads: $userId }
    ) {
      returning {
        ...CustomerCoreFields
      }
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DELETE_CUSTOMER($id: Int!) {
    delete_customers_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_CUSTOMER_RELATED_CONFIGS: TypedDocumentNode<{
  products: Omit<
    product,
    "photo" | "created_at" | "updated_at" | "description"
  >[];
  customerTags: tag[];
  companies: Omit<
    company,
    "sources" | "users" | "updated_at" | "created_at" | "description"
  >[];
  sources: source[];
  sourceDetails: sourceDetailExtended[];
  users: User[];
}> = gql`
  ${PRODUCT_FIELDS}
  ${CUSTOMER_TAG_FIELDS}
  ${COMPANY_FIELDS}
  ${COMPANY_TYPE_FIELDS}
  ${SOURCE_FIELDS}
  ${SOURCE_DETAIL_FIELDS}
  ${USER_CORE_FIELDS}
  query GET_CUSTOMER_RELATED_CONFIGS {
    products {
      id
      brand
      name
      price
      serial
    }
    customerTags {
      ...CustomerTagFields
    }
    companies {
      id
      isQuezone
      name
      email
      phone
      address
      registration
      subCompanies
      typeId
      type {
        ...CompanyTypeFields
      }
      customerTypeIds
      customerStatusIds
      invLocations
      noUsers
      active
    }
    sources {
      ...SourceFields
    }
    sourceDetails {
      ...SourceDetailFields
    }
    users {
      ...UserCoreFields
    }
  }
`;

export const ADD_CUSTOMER = gql`
  ${CUSTOMER_CORE_FIELDS}
  mutation ADD_CUSTOMER($object: customers_insert_input!) {
    insert_customers_one(object: $object) {
      ...CustomerCoreFields
    }
  }
`;

export const ADD_CUSTOMER_M = gql`
  mutation ADD_CUSTOMER_M($object: customers_insert_input!) {
    insert_customers_one(object: $object) {
      id
    }
  }
`;

export const ADD_CUSTOMER_MANY = gql`
  ${CUSTOMER_CORE_FIELDS}
  mutation ADD_CUSTOMER_MANY($objects: [customers_insert_input!]!) {
    insert_customers(objects: $objects) {
      returning {
        ...CustomerCoreFields
      }
    }
  }
`;

export const GET_CUSTOMER_LAST_NUM: TypedDocumentNode<{
  customers: { id: number; number: number }[];
}> = gql`
  query GET_CUSTOMER_LAST_NUM($year: Int!) {
    customers(
      where: { year: { _eq: $year } }
      order_by: { number: desc }
      limit: 1
    ) {
      id
      number
    }
  }
`;

export const SEARCH_CUSTOMER: TypedDocumentNode<{
  customers: customer[];
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  query SEARCH_CUSTOMER($where: customers_bool_exp!) {
    customers(where: $where, limit: 20) {
      ...CustomerCoreFields
    }
  }
`;

export const GET_CUSTOMERS_GUEST: TypedDocumentNode<{
  customers: customer[];
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  query GET_CUSTOMERS_CONDITIONAL($where: customers_bool_exp!) {
    customers(where: $where, limit: 1) {
      id
      number
      name
      year
      uuid
    }
  }
`;

export const GET_CUSTOMERS_CONDITIONAL: TypedDocumentNode<{
  customers: customer[];
  // customers_aggregate: { aggregate: { count: number } };
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  query GET_CUSTOMERS_CONDITIONAL(
    $where: customers_bool_exp!
    $limit: Int
    $order_by: [customers_order_by!]
    $offset: Int
  ) {
    customers(
      where: $where
      limit: $limit
      order_by: $order_by
      offset: $offset
    ) {
      ...CustomerCoreFields
    }
  }
`;

export const GET_CUSTOMERS_TOTAL_CONDITIONAL: TypedDocumentNode<{
  customers_aggregate: { aggregate: { count: number } };
}> = gql`
  query GET_CUSTOMERS_TOTAL_CONDITIONAL($where: customers_bool_exp!) {
    customers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CUSTOMERS_CONDITIONAL_M: TypedDocumentNode<{
  customers: { id: number; uuid: string; name: string }[];
  customers_aggregate: { aggregate: { count: number } };
}> = gql`
  query GET_CUSTOMERS_CONDITIONAL_M($where: customers_bool_exp!) {
    customers(where: $where) {
      id
      uuid
      name
    }
    customers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CUSTOMERS_M: TypedDocumentNode<{
  customers: { id: number; uuid: string; name: string }[];
}> = gql`
  query GET_CUSTOMERS_CONDITIONAL_M {
    customers {
      id
      uuid
      name
    }
  }
`;

export const STREAM_CUSTOMERS: TypedDocumentNode<{
  customers_stream: customer[];
}> = gql`
  ${CUSTOMER_CORE_FIELDS}
  subscription STREAM_CUSTOMERS(
    $where: customers_bool_exp
    $cursor: [customers_stream_cursor_input]!
  ) {
    customers_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...CustomerCoreFields
    }
  }
`;

export const SEARCH_CUSTOMER_TAG: TypedDocumentNode<{
  customerTags: tag[];
}> = gql`
  query SEARCH_CUSTOMER_TAG($Str: String!) {
    customerTags(where: { name: { _ilike: $Str } }) {
      id
      name
    }
  }
`;

export const GET_CUSTOMER_TAGS_BY_IDS: TypedDocumentNode<{
  customerTags: tag[];
}> = gql`
  query GET_CUSTOMER_TAGS_BY_IDS($ids: [Int!]!) {
    customerTags(where: { id: { _in: $ids } }) {
      id
      name
    }
  }
`;

export const GET_CUSTOMER_TAGS: TypedDocumentNode<{
  customerTags: tag[];
}> = gql`
  query GET_CUSTOMER_TAGS {
    customerTags {
      id
      name
    }
  }
`;

export const ADD_CUSTOMER_TAG = gql`
  mutation ADD_CUSTOMER_TAG($object: customerTags_insert_input!) {
    insert_customerTags_one(object: $object) {
      id
      name
    }
  }
`;

export const ADD_CUSTOMER_TAG_MANY = gql`
  mutation ADD_CUSTOMER_TAG_MANY($objects: [customerTags_insert_input!]!) {
    insert_customerTags(objects: $objects) {
      returning {
        id
        name
      }
    }
  }
`;

export const DELETE_CUSTOMER_TAG = gql`
  mutation DELETE_CUSTOMER_TAG($id: Int!) {
    delete_customerTags_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_TAG_BY_ID: TypedDocumentNode<{
  update_customerTags_by_pk: typeOrStatus;
}> = gql`
  mutation UPDATE_CUSTOMER_TAG_BY_ID($id: Int!, $set: customerTags_set_input!) {
    update_customerTags_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      name
    }
  }
`;

export const UPDATE_CUSTOMER_MANY = gql`
  ${CUSTOMER_CORE_FIELDS}
  mutation UPDATE_CUSTOMER_MANY($updates: [customers_updates!]!) {
    update_customers_many(updates: $updates) {
      returning {
        ...CustomerCoreFields
      }
    }
  }
`;

export const GET_CUSTOMER_TYPES: TypedDocumentNode<{
  customerTypes: typeOrStatus[];
}> = gql`
  query GetCustomerTypes {
    customerTypes {
      ...CustomerTypeFields
    }
  }
  ${CUSTOMER_TYPE_FIELDS}
`;

export const GET_CUSTOMER_STATUSES: TypedDocumentNode<{
  customerStatuses: typeOrStatus[];
}> = gql`
  query GetCustomerStatuses {
    customerStatuses {
      ...CustomerStatusFields
    }
  }
  ${CUSTOMER_STATUS_FIELDS}
`;

export const UPDATE_CUSTOMER_STATUS_BY_ID: TypedDocumentNode<{
  update_customerStatuses_by_pk: typeOrStatus;
}> = gql`
  ${CUSTOMER_STATUS_FIELDS}
  mutation UPDATE_CUSTOMER_STATUS_BY_ID(
    $id: Int!
    $set: customerStatuses_set_input!
  ) {
    update_customerStatuses_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...CustomerStatusFields
    }
  }
`;

export const UPDATE_CUSTOMER_STATUSES_MANY = gql`
  ${CUSTOMER_STATUS_FIELDS}
  mutation UPDATE_CUSTOMER_STATUSES_MANY(
    $updates: [customerStatuses_updates!]!
  ) {
    update_customerStatuses_many(updates: $updates) {
      returning {
        ...CustomerStatusFields
      }
    }
  }
`;

export const ADD_CUSTOMER_STATUS = gql`
  ${CUSTOMER_STATUS_FIELDS}
  mutation ADD_CUSTOMER_STATUS($object: customerStatuses_insert_input!) {
    insert_customerStatuses_one(object: $object) {
      ...CustomerStatusFields
    }
  }
`;

export const DELETE_CUSTOMER_STATUS = gql`
  mutation DELETE_CUSTOMER_STATUS($id: Int!) {
    delete_customerStatuses_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_TYPE_BY_ID: TypedDocumentNode<{
  update_customerTypes_by_pk: typeOrStatus;
}> = gql`
  ${CUSTOMER_TYPE_FIELDS}
  mutation UPDATE_CUSTOMER_TYPE_BY_ID(
    $id: Int!
    $set: customerTypes_set_input!
  ) {
    update_customerTypes_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...CustomerTypeFields
    }
  }
`;

export const UPDATE_CUSTOMER_TYPES_MANY = gql`
  ${CUSTOMER_TYPE_FIELDS}
  mutation UPDATE_CUSTOMER_TYPES_MANY($updates: [customerTypes_updates!]!) {
    update_customerTypes_many(updates: $updates) {
      returning {
        ...CustomerTypeFields
      }
    }
  }
`;

export const ADD_CUSTOMER_TYPE = gql`
  ${CUSTOMER_TYPE_FIELDS}
  mutation ADD_CUSTOMER_TYPE($object: customerTypes_insert_input!) {
    insert_customerTypes_one(object: $object) {
      ...CustomerTypeFields
    }
  }
`;

export const DELETE_CUSTOMER_TYPE = gql`
  mutation DELETE_CUSTOMER_TYPE($id: Int!) {
    delete_customerTypes_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_SUB_TYPE_BY_ID: TypedDocumentNode<{
  update_customerSubTypes_by_pk: typeOrStatus;
}> = gql`
  ${CUSTOMER_SUB_TYPE_FIELDS}
  mutation UPDATE_CUSTOMER_SUB_TYPE_BY_ID(
    $id: Int!
    $set: customerSubTypes_set_input!
  ) {
    update_customerSubTypes_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...CustomerSubTypeFields
    }
  }
`;

export const UPDATE_CUSTOMER_SUB_TYPES_MANY = gql`
  ${CUSTOMER_SUB_TYPE_FIELDS}
  mutation UPDATE_CUSTOMER_SUB_TYPES_MANY(
    $updates: [customerSubTypes_updates!]!
  ) {
    update_customerSubTypes_many(updates: $updates) {
      returning {
        ...CustomerSubTypeFields
      }
    }
  }
`;

export const ADD_CUSTOMER_SUB_TYPE = gql`
  ${CUSTOMER_SUB_TYPE_FIELDS}
  mutation ADD_CUSTOMER_SUB_TYPE($object: customerSubTypes_insert_input!) {
    insert_customerSubTypes_one(object: $object) {
      ...CustomerSubTypeFields
    }
  }
`;

export const DELETE_CUSTOMER_SUB_TYPE = gql`
  mutation DELETE_CUSTOMER_SUB_TYPE($id: Int!) {
    delete_customerSubTypes_by_pk(id: $id) {
      id
    }
  }
`;
