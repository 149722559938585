import { Outlet } from "react-router-dom";
import { useNavStore } from "../../store/navStore";
import { useEffect, useRef } from "react";
import SideNav from "./layouts/sideNav/sideNav";
import TopNav from "./layouts/topNav/topNav";
import useLoad from "./useLoad";
import Loading from "../../comps/loading";
import Snackbars from "../../comps/snackbars/snackbars";
import PromptSafariInstall from "../../comps/promptSafariInstall";

export default function Container() {
  const { setContainerRef } = useNavStore();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current == null) {
      return;
    }
    setContainerRef(containerRef.current);
  }, [containerRef.current]);

  useEffect(() => {
    if (APP_VERSION) {
      document.title = `Quezone v${APP_VERSION}`;
    }
    load();
  }, []);

  const { load, loading } = useLoad();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-row">
      <SideNav />
      <div
        className={`@container h-[100dvh] relative flex-1 flex flex-col overflow-hidden bg-lightBG`}
        ref={containerRef}
      >
        <TopNav />
        <Outlet />
        <Snackbars />
        <PromptSafariInstall />
      </div>
    </div>
  );
}
