import dayjs from "dayjs";
import { task } from "../../../../types/task";
import ListKanbanItem from "../../tasks/tasksMain/views/listKanbanItem/listKanbanItem";
import { NavLink } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

interface props {
  taskGroups: {
    date: string;
    tasks: task[];
  }[];
}

export default function UpcomingMobile({ taskGroups }: props) {
  const groupByMonth = taskGroups.reduce(
    (acc, group) => {
      const monthYear = dayjs(group.date).format("YYMM");

      if (acc.find(d => d.monthYear === monthYear)) {
        return acc.map(a => ({
          ...a,
          groups: monthYear == a.monthYear ? [...a.groups, group] : a.groups,
        }));
      }

      return acc.concat({
        monthYear,
        groups: [group],
      });
    },

    [] as {
      monthYear: string;
      groups: {
        date: string;
        tasks: task[];
      }[];
    }[]
  );

  if (taskGroups.length == 0) {
    return null;
  }

  return (
    <div className="flex flex-row lg:hidden border-y-2 border-black overflow-x-auto bg-white relative">
      <div className="absolute w-full border-b-[1px] border-black bg-gray-700 text-gray-700 text-sm pl-1 font-light">
        month placeholder
      </div>
      {groupByMonth.map(monthGroup => (
        <div key={monthGroup.monthYear} className="flex flex-col z-10">
          {/* Month */}
          <div className="border-b-[1px] border-black bg-gray-700 text-white text-sm pl-1 font-light">
            {dayjs(monthGroup.monthYear, "YYMM").format("MM월")}
          </div>
          {/* Groups */}
          <div className="flex flex-row">
            {monthGroup.groups.map(group => (
              <div key={group.date} className="flex flex-row">
                {/* Date */}
                <div className="p-2 flex flex-col items-center bg-gray-300 w-[60px] justify-center">
                  <div className="text-sm">
                    {dayjs(group.date).format("dddd")}
                  </div>
                  <h3 className="font-bold">
                    {dayjs(group.date).format("DD")}
                  </h3>
                </div>
                {/* Tasks */}
                {group.tasks.map((task, i) => (
                  <NavLink key={task.id} to={`/tasks/detail/${task.id}`}>
                    <div
                      className={`flex flex-col gap-1 ${
                        i !== 0 && `border-l-[1px] border-black`
                      }`}
                    >
                      <ListKanbanItem task={task} mobileDashboard />
                    </div>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
