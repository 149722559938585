import { useQuery } from "@apollo/client";
import { GET_POPULATED_INV_QTY_CONDITIONAL } from "../../../../gqls/inv";
import { invQty, inventoryPopulatedInvQty } from "../../../../types/inv";
import { useInvStore } from "../../../../store/invStore";
import MyBadge from "../../../../comps/myBadge";
import { Fragment, useState } from "react";
import { company } from "../../../../types/company";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useProductStore } from "../../../../store/productStore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Place from "@mui/icons-material/Place";
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";

export default function InventoryPerLocation({
  companies,
}: {
  companies: company[];
}) {
  const { data: invQtyData, loading } = useQuery(
    GET_POPULATED_INV_QTY_CONDITIONAL,
    {
      variables: {
        where: {
          _and: {
            _or: [
              {
                inventory: {
                  companyId: {
                    _in: companies.map(c => c.id),
                  },
                },
              },
              {
                inventory: {
                  user: {
                    companyId: {
                      _in: companies.map(c => c.id),
                    },
                  },
                },
              },
              {
                inventory: {
                  customer: {
                    companyId: {
                      _in: companies.map(c => c.id),
                    },
                  },
                },
              },
              {
                inventory: {
                  customer: {
                    sourceCompanyId: {
                      _in: companies.map(c => c.id),
                    },
                  },
                },
              },
            ],
            status: {
              available: {
                _eq: true,
              },
            },
          },
        },
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const invQties = invQtyData?.invQty || [];

  const companyGroupedInvQties = invQties
    .reduce<{ companyId: number; qties: inventoryPopulatedInvQty[] }[]>(
      (acc, cur) => {
        const companyId =
          cur.inventory.companyId || cur.inventory.user?.companyId || 0;
        if (acc.find(a => a.companyId == companyId)) {
          return acc.map(a => ({
            ...a,
            qties: a.companyId == companyId ? a.qties.concat(cur) : a.qties,
          }));
        }
        return acc.concat({
          companyId,
          qties: [cur],
        });
      },
      []
    )
    .filter(c => c.companyId !== 0);

  const customerInvQties = invQties.filter(c => c.inventory.customer);

  const getInvQtyTotal = (qties: invQty[]) => {
    return qties.reduce((acc, cur) => acc + cur.qty, 0);
  };

  const { invStatuses } = useInvStore();

  const invQtiesPerStat = invQties.reduce<
    { statId: number; qties: inventoryPopulatedInvQty[] }[]
  >((acc, cur) => {
    const statId = cur.statusId;
    const invStat = invStatuses.find(s => s.id == statId);
    if (!invStat?.available) {
      return acc;
    }
    if (acc.find(a => a.statId == statId)) {
      return acc.map(a => ({
        ...a,
        qties: a.statId == statId ? a.qties.concat(cur) : a.qties,
      }));
    }
    return acc.concat({
      statId,
      qties: [cur],
    });
  }, []);

  const total = invQties.reduce((acc, cur) => acc + cur.qty, 0);
  const [mode, setMode] = useState("stat" as "stat" | "product");

  if (loading) {
    return (
      <div className="flex-1 my-2">
        <LinearProgress color="success" />
      </div>
    );
  }

  return (
    <section className="flex flex-col gap-4 flex-1">
      <div className="p-4 rounded-md shadow-md flex flex-col gap-2 ring-1 ring-gray-200">
        <div className="flex flex-row justify-between items-center">
          <h2>총 재고 현황</h2>
          <MyBadge color="gray" text={`${total}`} big />
        </div>
        <div className="flex flex-row gap-2 flex-wrap">
          {invQtiesPerStat.map(qty => {
            const invStat = invStatuses.find(s => s.id == qty.statId);
            if (!invStat) {
              return null;
            }

            const total = getInvQtyTotal(qty.qties);
            const text = `${invStat.name} ${total}`;
            return (
              <MyBadge key={qty.statId} color={invStat.color} big text={text} />
            );
          })}
        </div>
      </div>

      <div className="p-4 rounded-md shadow-md flex flex-col gap-2 ring-1 ring-gray-200">
        <div className="flex flex-row justify-between items-center mb-2">
          <h2>위치별 통계</h2>
          <ToggleButtonGroup
            size="small"
            color="success"
            value={mode}
            onChange={(_, val) => {
              setMode(val as "stat" | "product");
            }}
            exclusive
          >
            <ToggleButton value="product" className="w-20">
              제품별
            </ToggleButton>
            <ToggleButton value="stat" className="w-20">
              상태별
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="flex flex-col gap-6">
          {companyGroupedInvQties.map(companyGroup => {
            const company = companies.find(c => c.id == companyGroup.companyId);
            if (!company) {
              return null;
            }
            return (
              <CompanyInventoryStatistics
                key={companyGroup.companyId}
                company={company}
                companyGroup={companyGroup}
                mode={mode}
              />
            );
          })}
          <CustomerInventoryStatistics
            invQties={customerInvQties}
            mode={mode}
          />
        </div>
      </div>
    </section>
  );
}

interface props {
  company: company;
  companyGroup: { companyId: number; qties: inventoryPopulatedInvQty[] };
  mode: string;
}

const CompanyInventoryStatistics = ({ companyGroup, company, mode }: props) => {
  const { invStatuses } = useInvStore();
  const { products } = useProductStore();

  const companyTotal = companyGroup.qties.reduce(
    (acc, cur) => acc + cur.qty,
    0
  );

  const invQtiesPerProduct = companyGroup.qties.reduce<
    { productId: number; qties: inventoryPopulatedInvQty[] }[]
  >((acc, cur) => {
    const productId = cur.inventory.productId;
    if (acc.find(a => a.productId == productId)) {
      return acc.map(a => ({
        ...a,
        qties: a.productId == productId ? a.qties.concat(cur) : a.qties,
      }));
    }
    return acc.concat({
      productId,
      qties: [cur],
    });
  }, []);

  const invQtiesPerStat = companyGroup.qties.reduce<
    { statId: number; qties: inventoryPopulatedInvQty[] }[]
  >((acc, cur) => {
    const statId = cur.statusId;
    if (acc.find(a => a.statId == statId)) {
      return acc.map(a => ({
        ...a,
        qties: a.statId == statId ? a.qties.concat(cur) : a.qties,
      }));
    }
    return acc.concat({
      statId,
      qties: [cur],
    });
  }, []);

  return (
    <div className="flex flex-col gap-2" key={company.id}>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-1 flex-1">
          <Place className="text-quezone" />
          <h3 className="flex-1 w-0 truncate">{company.name}</h3>
        </div>
        <MyBadge color="gray" big text={`${companyTotal}`} />
      </div>

      <div className="flex flex-col gap-2">
        {mode == "stat" &&
          invQtiesPerStat.map(statGroup => {
            const invStat = invStatuses.find(s => s.id == statGroup.statId);

            const total = statGroup.qties.reduce(
              (acc, cur) => acc + cur.qty,
              0
            );

            if (!invStat) {
              return null;
            }

            const qtiesPerProduct = statGroup.qties.reduce<
              {
                productId: number;
                qties: inventoryPopulatedInvQty[];
              }[]
            >((acc, cur) => {
              const productId = cur.inventory.productId;
              if (acc.find(a => a.productId == productId)) {
                return acc.map(a => ({
                  ...a,
                  qties:
                    a.productId == productId ? a.qties.concat(cur) : a.qties,
                }));
              }
              return acc.concat({
                productId,
                qties: [cur],
              });
            }, []);

            return (
              <Accordion key={invStat.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    className="flex flex-row justify-between items-center w-full mr-2"
                    key={invStat.id}
                  >
                    <div
                      className="font-semibold"
                      style={{ color: invStat.color }}
                    >
                      {invStat.name}
                    </div>
                    <div style={{ color: invStat.color }}>{total}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col gap-2">
                    {qtiesPerProduct.map((productGroup, i) => {
                      const product = products.find(
                        p => p.id == productGroup.productId
                      );
                      if (!product) {
                        return null;
                      }

                      const total = productGroup.qties.reduce(
                        (acc, cur) => acc + cur.qty,
                        0
                      );

                      const reducedQties = productGroup.qties.reduce<
                        inventoryPopulatedInvQty[]
                      >((acc, cur) => {
                        if (acc.find(a => a.invId == cur.invId)) {
                          return acc.map(a => {
                            if (a.invId == cur.invId) {
                              return {
                                ...a,
                                qty: a.qty + cur.qty,
                              };
                            }
                            return a;
                          });
                        }
                        return acc.concat(cur);
                      }, []);

                      return (
                        <Fragment key={productGroup.productId}>
                          {i !== 0 && <hr />}
                          <div className="flex flex-row justify-between items-center w-full pr-8 text-sm">
                            <div>{product.name} </div>
                            <div className="font-bold">{total}</div>
                          </div>
                          {reducedQties.map(qty =>
                            qty.qty == 0 ? null : (
                              <Link
                                to={`/inventory/detail/${qty.invId}`}
                                className="flex flex-row justify-between items-center w-full pr-8 text-sm pl-2 hover:text-quezone"
                                key={qty.id}
                              >
                                <div>
                                  {qty.inventory.company?.name ||
                                    qty.inventory.user?.name}{" "}
                                </div>
                                <div>{qty.qty}</div>
                              </Link>
                            )
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}

        {mode == "product" &&
          invQtiesPerProduct.map(productGroup => {
            const product = products.find(s => s.id == productGroup.productId);

            const total = productGroup.qties.reduce(
              (acc, cur) => acc + cur.qty,
              0
            );

            if (!product) {
              return null;
            }

            const qtiesPerStats = productGroup.qties.reduce<
              {
                statId: number;
                qties: inventoryPopulatedInvQty[];
              }[]
            >((acc, cur) => {
              const statId = cur.statusId;
              if (acc.find(a => a.statId == statId)) {
                return acc.map(a => ({
                  ...a,
                  qties: a.statId == statId ? a.qties.concat(cur) : a.qties,
                }));
              }
              return acc.concat({
                statId,
                qties: [cur],
              });
            }, []);

            return (
              <Accordion key={product.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    className="flex flex-row justify-between items-center w-full mr-2"
                    key={product.id}
                  >
                    <div className="font-semibold">{product.name}</div>
                    <div>{total}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col gap-2">
                    {qtiesPerStats.map((statGroup, i) => {
                      const invStat = invStatuses.find(
                        p => p.id == statGroup.statId
                      );
                      if (!invStat) {
                        return null;
                      }

                      const total = statGroup.qties.reduce(
                        (acc, cur) => acc + cur.qty,
                        0
                      );

                      const reducedQties = statGroup.qties.reduce<
                        inventoryPopulatedInvQty[]
                      >((acc, cur) => {
                        if (acc.find(a => a.invId == cur.invId)) {
                          return acc.map(a => {
                            if (a.invId == cur.invId) {
                              return {
                                ...a,
                                qty: a.qty + cur.qty,
                              };
                            }
                            return a;
                          });
                        }
                        return acc.concat(cur);
                      }, []);

                      return (
                        <Fragment key={statGroup.statId}>
                          {i !== 0 && <hr />}
                          <div className="flex flex-row justify-between items-center w-full pr-8 text-sm">
                            <div>{invStat.name} </div>
                            <div className="font-bold">{total}</div>
                          </div>
                          {reducedQties.map(qty =>
                            qty.qty == 0 ? null : (
                              <Link
                                to={`/inventory/detail/${qty.invId}`}
                                className="flex flex-row justify-between items-center w-full pr-8 text-sm pl-2 hover:text-quezone"
                                key={qty.id}
                              >
                                <div>
                                  {qty.inventory.company?.name ||
                                    qty.inventory.user?.name}{" "}
                                </div>
                                <div>{qty.qty}</div>
                              </Link>
                            )
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
};

interface CustomerInventoryStatisticsProps {
  invQties: inventoryPopulatedInvQty[];
  mode: string;
}

const CustomerInventoryStatistics = ({
  invQties,
  mode,
}: CustomerInventoryStatisticsProps) => {
  const { invStatuses } = useInvStore();
  const { products } = useProductStore();

  const total = invQties.reduce((acc, cur) => acc + cur.qty, 0);

  const invQtiesPerProduct = invQties.reduce<
    { productId: number; qties: inventoryPopulatedInvQty[] }[]
  >((acc, cur) => {
    const productId = cur.inventory.productId;
    if (acc.find(a => a.productId == productId)) {
      return acc.map(a => ({
        ...a,
        qties: a.productId == productId ? a.qties.concat(cur) : a.qties,
      }));
    }
    return acc.concat({
      productId,
      qties: [cur],
    });
  }, []);

  const invQtiesPerStat = invQties.reduce<
    { statId: number; qties: inventoryPopulatedInvQty[] }[]
  >((acc, cur) => {
    const statId = cur.statusId;
    if (acc.find(a => a.statId == statId)) {
      return acc.map(a => ({
        ...a,
        qties: a.statId == statId ? a.qties.concat(cur) : a.qties,
      }));
    }
    return acc.concat({
      statId,
      qties: [cur],
    });
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-1 flex-1">
          <Place className="text-quezone" />
          <h3 className="flex-1 w-0 truncate">고객 재고</h3>
        </div>
        <MyBadge color="gray" big text={`${total}`} />
      </div>

      <div className="flex flex-col gap-2">
        {mode == "stat" &&
          invQtiesPerStat.map(statGroup => {
            const invStat = invStatuses.find(s => s.id == statGroup.statId);

            const total = statGroup.qties.reduce(
              (acc, cur) => acc + cur.qty,
              0
            );

            if (!invStat) {
              return null;
            }

            const qtiesPerProduct = statGroup.qties.reduce<
              {
                productId: number;
                qties: inventoryPopulatedInvQty[];
              }[]
            >((acc, cur) => {
              const productId = cur.inventory.productId;
              if (acc.find(a => a.productId == productId)) {
                return acc.map(a => ({
                  ...a,
                  qties:
                    a.productId == productId ? a.qties.concat(cur) : a.qties,
                }));
              }
              return acc.concat({
                productId,
                qties: [cur],
              });
            }, []);

            return (
              <Accordion key={invStat.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    className="flex flex-row justify-between items-center w-full mr-2"
                    key={invStat.id}
                  >
                    <div
                      className="font-semibold"
                      style={{ color: invStat.color }}
                    >
                      {invStat.name}
                    </div>
                    <div style={{ color: invStat.color }}>{total}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col gap-2">
                    {qtiesPerProduct.map(productGroup => {
                      const product = products.find(
                        p => p.id == productGroup.productId
                      );
                      if (!product) {
                        return null;
                      }

                      const total = productGroup.qties.reduce(
                        (acc, cur) => acc + cur.qty,
                        0
                      );
                      return (
                        <Accordion key={productGroup.productId}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="flex flex-row justify-between items-center w-full pr-8 text-sm">
                              <div>{product.name} </div>
                              <div className="font-bold">{total}</div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="flex flex-col gap-1">
                            {productGroup.qties.map(qty =>
                              qty.qty == 0 ? null : (
                                <Link
                                  to={`/inventory/detail/${qty.inventory.id}`}
                                  className="flex flex-row justify-between items-center w-full pr-8 text-sm pl-2 hover:text-quezone"
                                  key={qty.id}
                                >
                                  <div>
                                    #{qty.inventory.customer?.year}-
                                    {qty.inventory.customer?.number}{" "}
                                    {qty.inventory.customer?.name}
                                  </div>
                                  <div>{qty.qty}</div>
                                </Link>
                              )
                            )}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}

        {mode == "product" &&
          invQtiesPerProduct.map(productGroup => {
            const product = products.find(s => s.id == productGroup.productId);

            const total = productGroup.qties.reduce(
              (acc, cur) => acc + cur.qty,
              0
            );

            if (!product) {
              return null;
            }

            const qtiesPerStats = productGroup.qties.reduce<
              {
                statId: number;
                qties: inventoryPopulatedInvQty[];
              }[]
            >((acc, cur) => {
              const statId = cur.statusId;
              if (acc.find(a => a.statId == statId)) {
                return acc.map(a => ({
                  ...a,
                  qties: a.statId == statId ? a.qties.concat(cur) : a.qties,
                }));
              }
              return acc.concat({
                statId,
                qties: [cur],
              });
            }, []);

            return (
              <Accordion key={product.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    className="flex flex-row justify-between items-center w-full mr-2"
                    key={product.id}
                  >
                    <div className="font-semibold">{product.name}</div>
                    <div>{total}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col gap-2">
                    {qtiesPerStats.map((statGroup, i) => {
                      const invStat = invStatuses.find(
                        p => p.id == statGroup.statId
                      );
                      if (!invStat) {
                        return null;
                      }

                      const total = statGroup.qties.reduce(
                        (acc, cur) => acc + cur.qty,
                        0
                      );
                      return (
                        <Fragment key={statGroup.statId}>
                          {i !== 0 && <hr />}
                          <div className="flex flex-row justify-between items-center w-full pr-8 text-sm">
                            <MyBadge
                              color={invStat.color}
                              text={invStat.name}
                            />
                            <div className="font-bold">{total}</div>
                          </div>
                          {statGroup.qties.map(qty =>
                            qty.qty == 0 ? null : (
                              <Link
                                to={`/inventory/detail/${qty.inventory.id}`}
                                className="flex flex-row justify-between items-center w-full pr-8 text-sm pl-2 hover:text-quezone"
                                key={qty.id}
                              >
                                <div>
                                  #{qty.inventory.customer?.year}-
                                  {qty.inventory.customer?.number}{" "}
                                  {qty.inventory.customer?.name}
                                </div>
                                <div>{qty.qty}</div>
                              </Link>
                            )
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
};
