import { gql } from "@apollo/client";

export const AUTH_FIELDS = gql`
  fragment AuthFields on auths {
    id
    name
    displayName
    description
    permissions
    customerStatusPermissions
    taskStatusPermissions
  }
`;

export const PERMISSION_FIELDS = gql`
  fragment PermissionFields on permissions {
    id
    name
    description
  }
`;
