import { typeOrStatus } from "../../../../../types/common";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { DELETE_TASK_STATUS, UPDATE_TASK_MANY } from "../../../../../gqls/task";
import { useTaskStore } from "../../../../../store/taskStore";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { cacheEvict } from "../../../../../utils/cacheMethods";

interface props {
  status: typeOrStatus;
  setMode: React.Dispatch<React.SetStateAction<"editing" | "deleting" | null>>;
}
export default function DeleteTaskStatus({ status, setMode }: props) {
  const { statuses, deleteStatus } = useTaskStore();
  const statusesNotMe = statuses.filter(s => s.id != status.id);

  const [altStat, setAltStat] = useState(0);

  const [remove, { loading: removing }] = useMutation(DELETE_TASK_STATUS);

  const [updateTasks, { loading: updatingTasks }] =
    useMutation(UPDATE_TASK_MANY);

  const loading = removing || updatingTasks;

  const _delete = async () => {
    const updates = [
      {
        where: { statusId: { _eq: status.id } },
        _set: {
          statusId: altStat,
        },
      },
    ];
    updateTasks({
      variables: { updates },
      onCompleted() {
        remove({
          variables: {
            id: status.id,
          },
          onCompleted() {
            deleteStatus(status.id);
            setMode(null);
          },
          update(cache) {
            cacheEvict({
              cache,
              id: status.id,
              __typename: "taskStatuses",
            });
          },
        });
      },
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        setMode(null);
      }}
    >
      <DialogTitle>작업 상태 삭제</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <div>{status.name} 상태를 삭제합니다</div>
          <div>삭제될 상태를 대신할 상태를 선택해주세요</div>
          <Select
            required
            size="small"
            color="success"
            value={altStat}
            onChange={e => setAltStat(Number(e.target.value))}
          >
            <MenuItem value={0}>대체 상태 선택</MenuItem>
            {statusesNotMe.map(stat => (
              <MenuItem key={stat.id} value={stat.id}>
                {stat.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: "black" }}
          onClick={() => {
            setMode(null);
          }}
        >
          취소
        </Button>
        <LoadingButton
          disabled={altStat === 0}
          loading={loading}
          variant="contained"
          color="success"
          onClick={_delete}
        >
          삭제
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
