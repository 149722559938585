import Checkbox from "@mui/material/Checkbox";
import { taskTypeCheck } from "../../../../../types/taskTypeCheck";

interface props {
  check: taskTypeCheck;
  checks: number[];
}

export default function Check({ check, checks }: props) {
  const checked = checks.includes(check.id);
  return (
    <div
      className={`border-2 ${
        checked && "border-quezone"
      } p-2 flex flex-row items-center gap-2 print:text-xs break-keep`}
    >
      <Checkbox
        color="success"
        checked={checked}
        onChange={() => {}}
        sx={{ padding: 0 }}
      />
      {check.name}
    </div>
  );
}
