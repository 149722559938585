import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import InvActionStatControl from "./invActionStatControl";
import { invAction } from "../../../../../types/inv";
import InvActionFromStatControl from "./invActionFromStatControl";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";

interface props {
  invAction?: invAction;
}

export default function InvActionControl({ invAction }: props) {
  const {
    register,
    control,
    formState: { errors, touchedFields },
  } = useFormContext();
  return (
    <div className="flex flex-col gap-2">
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth margin="dense" color="success" size="small">
            <InputLabel>액션 종류</InputLabel>

            <Select
              className="shadow-md"
              size="small"
              color="success"
              defaultValue="add"
              value={field.value || ""}
              onChange={field.onChange}
              onBlur={field.onBlur}
              name={field.name}
              ref={field.ref}
              label="액션 종류"
              disabled={field.disabled}
            >
              <MenuItem value="add">추가</MenuItem>
              <MenuItem value="move">이동</MenuItem>
              <MenuItem value="assign">할당</MenuItem>
              <MenuItem value="update">변경</MenuItem>
              <MenuItem value="delete">소진</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <TextField
        margin="dense"
        className="shadow-md"
        size="small"
        fullWidth
        error={errors.name && touchedFields.name}
        color="success"
        placeholder={
          errors.name?.message ? (errors.name?.message as string) : "액션 이름"
        }
        label="이름"
        {...register("name")}
      />

      <TextField
        margin="dense"
        className="shadow-md"
        size="small"
        fullWidth
        error={errors.description && touchedFields.description}
        color="success"
        placeholder={
          errors.description?.message
            ? (errors.description?.message as string)
            : "설명"
        }
        label="설명"
        {...register("description")}
      />
      <TextField
        margin="dense"
        className="shadow-md"
        size="small"
        fullWidth
        error={errors.color && touchedFields.color}
        color="success"
        placeholder={
          errors.color?.message ? (errors.color?.message as string) : "색상"
        }
        label="색상"
        type="color"
        defaultValue="#ffffff"
        {...register("color")}
      />
      <Controller
        control={control}
        name="linger"
        render={({ field }) => (
          <div className="flex flex-row justify-end gap-1 items-center text-sm">
            <span className={field.value ? "" : "text-gray-500"}>재고연결</span>
            <Switch
              color="success"
              checked={!!field.value}
              onChange={() => {
                field.onChange(!field.value);
              }}
            />
          </div>
        )}
      />
      <InvActionFromStatControl invAction={invAction} />
      <InvActionStatControl invAction={invAction} />
    </div>
  );
}
