import BlackHeading from "../../../../comps/blackHeading";
import { task } from "../../../../types/task";
import AddCorrespondence from "../../common/correspondences/addCorrespondence";
import Correspondences from "../../common/correspondences/correspondences";
import AddCorrespondencesMobile from "../../customers/customerDetail/customerProgress/addCorrespondencesMobile";

interface props {
  task: task;
}

export default function TaskCorrespondences({ task }: props) {
  return (
    <div className="flex flex-col gap-2">
      <BlackHeading title="코멘트">
        <div className="flex flex-row justify-between items-center">
          <label className="text-white">코멘트</label>
          <div className="block md:hidden">
            <AddCorrespondencesMobile
              customerId={task.customerId}
              taskId={task.id}
              simple
            />
          </div>
        </div>
      </BlackHeading>
      <div className="md:border-[1px] md:px-2 md:py-1">
        <Correspondences customerId={task.customerId} taskId={task.id} />
      </div>

      <div className="hidden md:block">
        <AddCorrespondence
          customerId={task.customerId}
          taskId={task.id}
          simple
        />
      </div>
    </div>
  );
}
