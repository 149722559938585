import { useQuery } from "@apollo/client";
import {
  Controller,
  useController,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { GET_COMPANIES } from "../../../../gqls/company";
import { GET_USERS } from "../../../../gqls/user";
import Select from "@mui/material/Select";
// import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
// import TextField from "@mui/material/TextField";
import { User } from "../../../../types/user";
import { company } from "../../../../types/company";
import { sort } from "fast-sort";
import { styled, useAutocomplete } from "@mui/material";
import { Fragment } from "react";

export default function InvLocationControl() {
  const { control, setValue } = useFormContext();

  const { data: companyData } = useQuery(GET_COMPANIES);

  const checkAuth = useCheckAuth();

  const companies = (companyData?.companies || []).filter(
    company =>
      checkAuth({
        permissionName: "재고추가",
        companyIds: [company.id],
      }) &&
      company.invLocations &&
      company.invLocations.length > 0 &&
      company.active
  );

  const { data: userData } = useQuery(GET_USERS);

  const { permissions } = useAuthStore();

  const canHaveInvPermissionId =
    permissions.find(p => p.name == "재고보유")?.id || -1;

  const users = (userData?.users || []).filter(
    u =>
      (u.auth.permissions.some(p => p.id == canHaveInvPermissionId) ||
        u.auth.name == "admin") &&
      checkAuth({
        permissionName: "재고추가",
        userId: u.id,
        companyIds: [u.company?.id || 0],
      })
  );

  const type = useWatch({
    name: "location.type",
    control,
  });

  const options = [
    { label: "위치를 선택해주세요", name: "위치를 선택해주세요", id: 0 },
    ...(type == "company"
      ? (companies || []).map(c => ({ ...c, label: c.name }))
      : sort(users || [])
          .asc("companyId")
          .map(u => ({ ...u, label: u.name }))),
  ];

  const Listbox = styled("ul")(({ theme }) => ({
    minWidth: 200,
    margin: 0,
    marginTop: 2,
    padding: 0,
    zIndex: 1,
    position: "absolute",
    listStyle: "none",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
    overflow: "auto",
    maxHeight: 200,
    border: "1px solid rgba(0,0,0,.25)",
  }));

  const { field } = useController({
    name: "location.id",
    control,
  });

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    onChange(_, value: company | User) {
      field.onChange(value?.id || 0);
    },
    options,
    value: field.value || 0,
    isOptionEqualToValue: (option, value) =>
      Number(option?.id) === Number(value),
    getOptionLabel: option => {
      return (
        option?.name ||
        options.find(o => o?.id == option)?.name ||
        "위치를 선택해주세요"
      );
    },
  });

  return (
    <div className="flex flex-col gap-1">
      <label>위치</label>
      <div className="flex flex-row gap-1">
        <Controller
          name="location.type"
          control={control}
          render={({ field }) => (
            <Select
              margin="none"
              size="small"
              required
              className="shadow-md w-20"
              color="success"
              value={field.value}
              onChange={e => {
                setValue("location.id", 0);
                field.onChange(e.target.value);
              }}
              onBlur={field.onBlur}
              name={field.name}
              ref={field.ref}
              disabled={field.disabled}
            >
              <MenuItem value="company">회사</MenuItem>
              <MenuItem value="user">개인</MenuItem>
            </Select>
          )}
        />
        <div className="flex-1 relative">
          <div {...getRootProps()}>
            <input
              {...getInputProps()}
              className="w-full text-sm shadow-md ring-1 ring-gray-300 rounded-sm h-[38px] mt-[1px] px-2 py-1"
            />
          </div>
          {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <Fragment key={option.id}>
                  {type == "user" &&
                    (index == 0 ||
                      option.companyId !==
                        groupedOptions[index - 1].companyId) && (
                      <li className="pointer-events-none p-1 text-sm text-quezone">
                        {option.company?.name}
                      </li>
                    )}
                  <li
                    {...getOptionProps({ option, index })}
                    className="text-sm indent-1 p-1 hover:bg-quezone hover:text-white hover:cursor-pointer"
                  >
                    {option.label}
                  </li>
                </Fragment>
              ))}
            </Listbox>
          ) : null}
        </div>
      </div>
    </div>
  );
}
