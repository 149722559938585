import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useInvStore } from "../../../../store/invStore";
import {
  inventory_populated,
  statPopulatedInvQty,
} from "../../../../types/inv";
import { sort } from "fast-sort";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import useInvAction from "../../../../hooks/useInvActions";
import { useState } from "react";
import { useAddAlert } from "../../../../store/alertStore";
import InvActionLocationControl from "./invActionLocationControl";
import { useCheckAuth } from "../../../../store/authStore";
import { CircularProgress } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_INVENTORY_BY_ID } from "../../../../gqls/inv";
import { getInvLocation, makeInvName } from "../../../../utils/invUtils";

interface props {
  inventory: inventory_populated;
  qty: statPopulatedInvQty;
}

const schema = z.object({
  actionId: z.coerce.number().min(1),
  qty: z.coerce.number().min(1),
  description: z.string().nullable().optional(),
  location: z
    .object({
      type: z.string(),
      id: z.coerce.number(),
    })
    .nullable()
    .optional(),
});

interface defaultValues {
  actionId: number;
  qty: number;
  description: string | null;
  location?: {
    type: "company" | "user";
    id: number;
  };
}

export default function InventoryActionPerStat({ inventory, qty }: props) {
  let { invActions } = useInvStore();
  invActions = sort(
    invActions.filter(
      action =>
        action.fromStatIds?.includes(qty.statusId) &&
        (action.type !== "assign" || !!qty.subInvId)
    )
  ).asc("priority");

  const defaultValues: defaultValues = {
    actionId: invActions[0]?.id || 0,
    qty: 1,
    description: null,
    location: {
      type: "company",
      id: 0,
    },
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { register, control, reset, handleSubmit } = methods;

  const selectedActionId = useWatch({
    name: "actionId",
    control,
  });

  const selectedAction = invActions.find(
    action => action.id === Number(selectedActionId)
  );

  const update = useInvAction({
    inventory,
    invQty: qty,
  });

  const [updating, setUpdating] = useState(false);

  const addAlert = useAddAlert();

  const { data: otherInvData } = useQuery(GET_INVENTORY_BY_ID, {
    variables: {
      id: qty.invId == inventory.id ? qty.subInvId : qty.invId,
    },
  });

  const otherInventory = otherInvData?.inventory_by_pk;
  const otherPartyName = otherInventory && makeInvName(otherInventory);

  const mainInventory =
    qty.invId == inventory.id
      ? inventory
      : (otherInventory as inventory_populated);

  const subInventory =
    qty.subInvId == inventory.id ? inventory : otherInventory;

  const customerInventory = [mainInventory, subInventory].find(
    inv => !!inv?.customerId
  );

  const notCustomerInventory = [mainInventory, subInventory].find(
    inv => !inv?.customerId
  );

  const onSubmit = handleSubmit(async data => {
    if (!selectedAction) {
      return;
    }

    if (selectedAction.type == "assign" && !customerInventory?.id) {
      console.log({ customerInventory });
      return;
    }

    if (updating) {
      return;
    }

    if (data.qty > qty.qty) {
      addAlert({
        message: "가용 갯수보다 많습니다",
        type: "error",
      });
      return;
    }
    setUpdating(true);

    const res = await update({
      qty: data.qty,
      description: data.description,
      invAction: selectedAction,
      customerId:
        selectedAction.type == "assign"
          ? customerInventory?.customerId
          : undefined,
      location:
        selectedAction.type == "assign"
          ? getInvLocation(mainInventory)
          : data.location,
      inventoryOverride:
        selectedAction.type == "assign" ? notCustomerInventory : undefined,
    });

    if (res) {
      addAlert({
        message: `${selectedAction?.name} 완료`,
        type: "success",
      });
      reset();
    } else {
      addAlert({
        message: `${selectedAction?.name} 실패`,
        type: "error",
      });
    }

    setUpdating(false);
  });

  const checkAuth = useCheckAuth();

  const canEdit =
    checkAuth({
      permissionName: "고객_재고액션",
    }) || checkAuth({ permissionName: "재고액션" });

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-1" onSubmit={onSubmit}>
        {otherPartyName && (
          <div className="flex flex-row items-center justify-between gap-2 text-sm pr-1">
            {otherPartyName}
            <span>{qty.qty} 개</span>
          </div>
        )}
        {canEdit && (
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              {invActions.length > 0 && (
                <Controller
                  name={"actionId"}
                  control={control}
                  render={({ field }) => (
                    <select
                      required
                      color="success"
                      value={field.value || 0}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      name={field.name}
                      ref={field.ref}
                      disabled={field.disabled}
                      className="text-sm border-[1px] rounded-sm px-2 self-stretch min-h-8"
                      style={{
                        color: selectedAction?.color,
                      }}
                    >
                      {invActions.map(action => (
                        <option
                          key={action.id}
                          value={action.id}
                          style={{
                            color: action.color,
                          }}
                        >
                          {action.name}
                        </option>
                      ))}
                    </select>
                  )}
                />
              )}
              {selectedAction && (
                <>
                  <input
                    max={qty.qty}
                    type="number"
                    className="border-[1px] rounded-sm border-gray-300 px-1 py-[2px] w-10 text-center h-8"
                    placeholder="수량"
                    {...register("qty")}
                  />

                  {selectedAction?.type !== "move" && (
                    <div className="flex-1 flex flex-row gap-2 items-center">
                      <div className="flex-1">
                        <CommentControl />
                      </div>
                      <SubmitControl loading={updating} />
                    </div>
                  )}
                  {selectedAction?.type == "move" && (
                    <div className="flex-1">
                      <InvActionLocationControl inventory={inventory} />
                    </div>
                  )}
                </>
              )}
            </div>
            {selectedAction && selectedAction?.type == "move" && (
              <div className="flex-1 flex flex-row gap-2 items-center">
                <div className="flex-1">
                  <CommentControl />
                </div>
                <SubmitControl loading={updating} />
              </div>
            )}
          </div>
        )}
      </form>
    </FormProvider>
  );
}

const CommentControl = () => {
  const { register } = useFormContext();
  return (
    <input
      className="border-[1px] rounded-sm border-gray-300 px-1 py-[2px] h-8 w-full text-center text-sm"
      autoComplete="off"
      placeholder="비고"
      {...register("description")}
    />
  );
};

const SubmitControl = ({ loading }: { loading: boolean }) => {
  return (
    <button
      type="submit"
      className="bg-black text-white px-2 py-[3px] h-8 md:hover:text-quezone rounded-sm flex flex-row items-center"
      disabled={loading}
    >
      {loading ? <CircularProgress size={20} color="success" /> : "실행"}
    </button>
  );
};
