import { extendedReport } from "../../../../../../types/report";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { task } from "../../../../../../types/task";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import dayjs from "dayjs";
import { currencyFormat } from "../../../../../../utils/numberMethods";
import { Fragment } from "react";
import { sort } from "fast-sort";
import IosShareIcon from "@mui/icons-material/IosShare";
import Button from "@mui/material/Button";
import { exportData } from "../../../../../../utils/objectArrayMethods";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

interface props {
  reports: extendedReport[];
}

interface group {
  installerId: number;
  installer: task["assignedTo"];
  reports: extendedReport[];
}

export default function InstallerSummary({ reports }: props) {
  const installerReports = reports.reduce<group[]>((acc, report) => {
    if (!report.task.assignedToId || !report.task.assignedTo) {
      return acc;
    }

    if (acc.find(a => a.installerId == report.task.assignedToId)) {
      return acc.map(a => ({
        ...a,
        reports:
          a.installerId == report.task.assignedToId
            ? [...a.reports, report]
            : a.reports,
      }));
    }

    return acc.concat({
      installerId: report.task.assignedToId,
      installer: report.task.assignedTo,
      reports: [report],
    });
  }, []);

  const goToDetail = (taskId: number, reportId: number) => {
    window.open(`/tasks/detail/${taskId}/reportDetail/${reportId}`, target);
  };

  const exportToExcel = () => {
    const _data = installerReports
      .flatMap(r =>
        r.reports.map(report => ({ ...report, installer: r.installer }))
      )
      .flatMap(report => {
        const { name, number, year } = report.task.customer;
        const fullNumName = `#${year}-${number} ${name}`;

        if (report.accounts.length == 0) {
          return [
            {
              기사: report.installer?.name,
              날짜: dayjs(report.date).format("YYYY-MM-DD"),
              고객: fullNumName,
              작업: report.task.type.name,
              보고: report.type.name,
              항목: "없음",
              금액: currencyFormat(0),
            },
          ];
        }

        return report.accounts.map(account => ({
          기사: report.installer?.name,
          날짜: dayjs(report.date).format("YYYY-MM-DD"),
          고객: fullNumName,
          작업: report.task.type.name,
          보고: report.type.name,
          항목: account.item,
          금액: currencyFormat(account.amount + account.gst),
        }));
      });

    exportData(
      sort(_data).by([{ asc: r => r.기사 }, { asc: r => new Date(r.날짜) }]),
      "기사정산"
    );
  };

  return (
    <>
      <div className="shadow-md bg-white rounded-md p-4 mb-4">
        <h1>기사 정산</h1>
      </div>
      <div>
        {sort(installerReports)
          .asc(r => r.installer?.name)
          .map(r => {
            const total = currencyFormat(
              r.reports.reduce(
                (acc, cur) =>
                  acc +
                  cur.accounts.reduce(
                    (acc, cur) => acc + (cur.amount + cur.gst),
                    0
                  ),
                0
              )
            );
            return (
              <Accordion key={r.installerId}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="w-full flex flex-row justify-between">
                    <div className="font-semibold">
                      {r.installer?.name}{" "}
                      <span className="font-normal text-gray-500">
                        - {r.reports.length}
                      </span>
                    </div>
                    <div className="font-semibold">{total}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="overflow-x-auto">
                    <div className="min-w-[380px]">
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>날짜</TableCell>
                            <TableCell>고객</TableCell>
                            <TableCell>작업</TableCell>
                            <TableCell>보고</TableCell>
                            <TableCell>항목</TableCell>
                            <TableCell>금액</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sort(r.reports)
                            .asc("date")
                            .map(report => {
                              const AccountRow = ({
                                id,
                                item,
                                amount,
                              }: {
                                id: number;
                                item: string;
                                amount: number;
                              }) => (
                                <TableRow
                                  key={id}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    goToDetail(report.taskId, report.id)
                                  }
                                >
                                  <TableCell>
                                    {dayjs(report.date).format("MM. DD")}
                                  </TableCell>
                                  <TableCell>
                                    {report.task.customer.name}
                                  </TableCell>
                                  <TableCell>{report.task.type.name}</TableCell>
                                  <TableCell>{report.type.name}</TableCell>
                                  <TableCell>{item}</TableCell>
                                  <TableCell>
                                    {currencyFormat(amount)}
                                  </TableCell>
                                </TableRow>
                              );

                              return (
                                <Fragment key={report.id}>
                                  {report.accounts.length == 0 && (
                                    <AccountRow
                                      id={report.id}
                                      item="없음"
                                      amount={0}
                                    />
                                  )}
                                  {report.accounts.map(acc => (
                                    <AccountRow
                                      id={acc.id}
                                      item={acc.item}
                                      amount={acc.amount + acc.gst}
                                      key={acc.id}
                                    />
                                  ))}
                                </Fragment>
                              );
                            })}
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div className="text-right font-semibold">
                                Total
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="text-quezone">{total}</div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
      <div className="mt-2 flex flex-row justify-end">
        <Button onClick={exportToExcel} color="success">
          <div className="flex flex-row gap-1 items-center">
            <IosShareIcon
              className="text-quezone mb-0.5"
              sx={{ width: 20, height: 20 }}
            />
            내보내기
          </div>
        </Button>
      </div>
    </>
  );
}
