import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useCustomerStore } from "../../../../store/customerStore";
import { sort } from "fast-sort";

export const CustomerSubTypeControlOnly = () => {
  const { control } = useFormContext();

  let { subTypes } = useCustomerStore();

  return (
    <Controller
      name="subTypeId"
      control={control}
      render={({ field }) => (
        <Select
          margin="none"
          size="small"
          className="shadow-md"
          color="success"
          value={field.value || 0}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={field.name}
          ref={field.ref}
          disabled={field.disabled}
        >
          <MenuItem value={0}>고객 서브타입</MenuItem>
          {sort(subTypes)
            .asc("priority")
            .map(type => (
              <MenuItem value={type.id} key={type.id}>
                {type.name}
              </MenuItem>
            ))}
        </Select>
      )}
    />
  );
};

export default function CustomerSubTypeControl() {
  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>서브 타입</label>
      <CustomerSubTypeControlOnly />
    </div>
  );
}
