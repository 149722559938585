import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_AUTOMATION_BY_ID } from "../../../../gqls/automation";
import AutomationControl from "./automationControl/automationControl";

export default function EditAutomation() {
  const { id } = useParams();

  const { data } = useQuery(GET_AUTOMATION_BY_ID, {
    variables: { id },
    onError(error) {
      console.log(error);
    },
  });

  const automation = data?.automations_by_pk;

  if (!automation) {
    return null;
  }

  return <AutomationControl automation={automation} />;
}
