import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import MyBadge from "../../../../../../comps/myBadge";
import { task } from "../../../../../../types/task";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { useAuthStore } from "../../../../../../store/authStore";

interface props {
  tasks: task[];
}

export default function Table({ tasks }: props) {
  const navigate = useNavigate();

  const { user } = useAuthStore();

  const columns = [
    { field: "id", width: 50, headerName: "id", hide: true },
    { field: "year", width: 70, headerName: "연도" },
    { field: "number", width: 80, headerName: "번호" },
    { field: "company", width: 100, headerName: "담당사" },
    { field: "assignedTo", width: 100, headerName: "작업자" },
    {
      field: "type",
      width: 90,
      headerName: "구분",
      renderCell: (params: any) => (
        <MyBadge color={params.value.color} text={params.value.name} />
      ),
    },
    { field: "name", flex: 1, headerName: "이름" },
    { field: "address", flex: 2, headerName: "주소" },
    { field: "created_at", width: 100, headerName: "제안일" },
    { field: "installDate", width: 100, headerName: "작업일" },

    {
      field: "status",
      flex: 1,
      headerName: "상태",
      renderCell: (params: any) => (
        <MyBadge color={params.value.color} text={params.value.name} fill />
      ),
    },
    {
      field: "isUnderWarranty",
      width: 50,
      headerName: "",
      renderCell: (params: any) => (
        <>{!params.value && <MyBadge text="₩" color="red" />}</>
      ),
    },
    {
      field: "update",
      width: 50,
      headerName: "",
      renderCell: (params: any) => {
        const reads = params?.value?.reads || [];
        const reportReads = params?.value?.reportReads;
        const reportCheck = params?.value?.reportCheck;
        const updated =
          (!reads.includes(user?.id) ||
            (reportReads && !reportReads.includes(user?.id)) ||
            reportCheck) &&
          dayjs(params?.value?.created_at).isAfter(dayjs(user?.created_at));
        if (updated) {
          return (
            <TipsAndUpdatesIcon
              sx={{ width: 20 }}
              className="text-yellow-500 animate-pulse"
            />
          );
        }
        return null;
      },
    },
  ];

  const rows = tasks.map(task => ({
    id: task.id,
    year: task.customer.year,
    number: task.customer.number,
    company: task.company?.name,
    type: task.type,
    name: task.customer.name,
    assignedTo: task.assignedTo?.name || "미정",
    address: task.customer.address,
    created_at: dayjs(task.created_at).format("YY. MM. DD"),
    installDate: task.date ? dayjs(task.date).format("YY. MM. DD") : "미정",
    isUnderWarranty: task.customer.warrantyDate
      ? dayjs(task.customer.warrantyDate).isAfter(dayjs())
      : dayjs(task.customer.registered_at).diff(dayjs(), "days") < 365,
    status: task.status,
    update: {
      reads: task.reads,
      reportReads: task.report?.reads,
      reportCheck: task.report?.checked,
    },
  }));

  return (
    <div style={{ flexGrow: 1, minWidth: "100%" }} className="md:px-10">
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{ backgroundColor: "white" }}
        onRowClick={e => {
          navigate("/tasks/detail/" + e.row.id);
        }}
      />
    </div>
  );
}
