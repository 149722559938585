import { TypedDocumentNode, gql } from "@apollo/client";
import { source, sourceDetailExtended } from "../types/source";
import { SOURCE_FIELDS, SOURCE_DETAIL_FIELDS } from "../fragments/source";

export const GET_SOURCE_BY_ID: TypedDocumentNode<{
  sources_by_pk: source;
}> = gql`
  ${SOURCE_FIELDS}
  query GET_SOURCE_BY_ID($id: Int!) {
    sources_by_pk(id: $id) {
      ...SourceFields
    }
  }
`;

export const GET_SOURCES: TypedDocumentNode<{
  sources: source[];
}> = gql`
  ${SOURCE_FIELDS}
  query GET_SOURCES {
    sources {
      ...SourceFields
    }
  }
`;

export const GET_SOURCES_CONDITIONAL: TypedDocumentNode<{
  sources: source[];
}> = gql`
  ${SOURCE_FIELDS}
  query GET_SOURCES($where: sources_bool_exp!) {
    sources(where: $where) {
      ...SourceFields
    }
  }
`;

export const GET_COMPANY_SOURCES: TypedDocumentNode<{
  sources: source[];
}> = gql`
  ${SOURCE_FIELDS}
  query GET_GET_COMPANY_SOURCES($id: Int!) {
    sources(where: { companyId: { _eq: $id } }) {
      ...SourceFields
    }
  }
`;

export const UPDATE_SOURCE_BY_ID: TypedDocumentNode<{
  update_sources_by_pk: source;
}> = gql`
  ${SOURCE_FIELDS}
  mutation UpdateSourceById($id: Int!, $set: sources_set_input!) {
    update_sources_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...SourceFields
    }
  }
`;

export const ADD_SOURCE = gql`
  ${SOURCE_FIELDS}
  mutation ADD_SOURCE($object: sources_insert_input!) {
    insert_sources_one(object: $object) {
      ...SourceFields
    }
  }
`;

export const DELETE_SOURCE = gql`
  mutation DELETE_SOURCE($id: Int!) {
    delete_sources_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_SOURCE_DETAILS: TypedDocumentNode<{
  sourceDetails: sourceDetailExtended[];
}> = gql`
  ${SOURCE_DETAIL_FIELDS}
  query GET_SOURCE_DETAILS {
    sourceDetails {
      ...SourceDetailFields
    }
  }
`;

export const GET_SOURCE_DETAILS_CONDITIONAL: TypedDocumentNode<{
  sourceDetails: sourceDetailExtended[];
}> = gql`
  ${SOURCE_DETAIL_FIELDS}
  query GET_SOURCE_DETAILS_CONDITIONAL($where: sourceDetails_bool_exp!) {
    sourceDetails(where: $where) {
      ...SourceDetailFields
    }
  }
`;

export const GET_COMPANY_SOURCE_DETAILS: TypedDocumentNode<{
  sourceDetails: sourceDetailExtended[];
}> = gql`
  ${SOURCE_DETAIL_FIELDS}
  query GET_SOURCE_DETAILS($id: Int!) {
    sourceDetails(where: { source: { company: { id: { _eq: $id } } } }) {
      ...SourceDetailFields
    }
  }
`;

export const UPDATE_SOURCE_DETAIL_BY_ID: TypedDocumentNode<{
  update_sourceDetails_by_pk: source;
}> = gql`
  ${SOURCE_DETAIL_FIELDS}
  mutation UPDATE_SOURCE_DETAIL_BY_ID(
    $id: Int!
    $set: sourceDetails_set_input!
  ) {
    update_sourceDetails_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...SourceDetailFields
    }
  }
`;

export const ADD_SOURCE_DETAIL = gql`
  ${SOURCE_DETAIL_FIELDS}
  mutation ADD_SOURCE_DETAIL($object: sourceDetails_insert_input!) {
    insert_sourceDetails_one(object: $object) {
      ...SourceDetailFields
    }
  }
`;

export const ADD_SOURCE_DETAIL_MANY = gql`
  ${SOURCE_DETAIL_FIELDS}
  mutation ADD_SOURCE_DETAIL($objects: [sourceDetails_insert_input!]!) {
    insert_sourceDetails(objects: $objects) {
      returning {
        ...SourceDetailFields
      }
    }
  }
`;

export const UPDATE_SOURCE_DETAIL_MANY = gql`
  ${SOURCE_DETAIL_FIELDS}
  mutation UPDATE_SOURCE_DETAIL_MANY($updates: [sourceDetails_updates!]!) {
    update_sourceDetails_many(updates: $updates) {
      returning {
        ...SourceDetailFields
      }
    }
  }
`;

export const DELETE_SOURCE_DETAIL_MANY = gql`
  mutation DELETE_SOURCE_DETAIL_MANY($ids: [Int!]!) {
    delete_souceDetails(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

export const MUTATION_SOURCE_DETAIL_MANY = gql`
  ${SOURCE_DETAIL_FIELDS}
  mutation MUTATION_SOURCE_DETAIL_MANY(
    $updates: [sourceDetails_updates!]!
    $inserting: [sourceDetails_insert_input!]!
    $deleting: [Int!]!
  ) {
    update_sourceDetails_many(updates: $updates) {
      returning {
        ...SourceDetailFields
      }
    }
    insert_sourceDetails(objects: $inserting) {
      returning {
        ...SourceDetailFields
      }
    }
    delete_sourceDetails(where: { id: { _in: $deleting } }) {
      affected_rows
    }
  }
`;

export const DELETE_SOURCE_DETAIL = gql`
  mutation DELETE_SOURCE_DETAIL($id: Int!) {
    delete_sourceDetails_by_pk(id: $id) {
      id
    }
  }
`;
