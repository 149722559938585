import Avatar from "@mui/material/Avatar";
import { task } from "../../../../../../types/task";
import MyBadge from "../../../../../../comps/myBadge";
import { useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import FaceIcon from "@mui/icons-material/Face";
import LaunchIcon from "@mui/icons-material/Launch";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import useUserAvatar from "../../../../../../hooks/useUserAvatar";
import { isMobile } from "react-device-detect";
const target = isMobile ? "_self" : "_blank";

export default function AgendaItem({ task }: { task: task }) {
  const {
    assignedTo,
    assignedToId,
    type,
    customer,
    status,
    assignedBy,
    id,
    time,
  } = task;

  const [expanded, setExpanded] = useState(false);

  const { avatar: assignedToAvatar } = useUserAvatar(assignedToId || 0);

  return (
    <section
      className="border-l-[6px] p-2 flex flex-col gap-2 bg-white shadow-md ring-1 ring-gray-100 lg:ring-0  z-10"
      style={{
        borderColor: type.color,
      }}
    >
      {/* Header */}
      <header
        className="flex flex-row w-full items-center cursor-pointer hover:text-quezone"
        onClick={() => setExpanded(!expanded)}
      >
        {/* details */}
        <div className="flex-1 flex flex-col gap-1 items-start">
          <div className="flex flex-row gap-4 items-center">
            <MyBadge color={status.color} text={status.name} fill />
            {time ? (
              <div className="text-sm">
                <AccessTimeFilledIcon
                  sx={{ width: 20, height: 20 }}
                  className="text-quezone"
                />{" "}
                {dayjs(time, "HH:mm:ssZ").format("HH:mm")}
              </div>
            ) : null}
          </div>
          <div className="font-medium">
            {customer.name} {type.name}
          </div>
        </div>
        {/* installer */}
        <div className="flex flex-col gap-1 items-end">
          <Avatar
            sx={{ width: 30, height: 30, bgcolor: assignedTo?.color }}
            alt={assignedTo?.name}
            src={assignedToAvatar || ""}
          />
          <div className="text-sm max-w-[80px] truncate">
            {assignedTo?.name || "미정"}
          </div>
        </div>
      </header>
      {/* Details */}
      <div className={`${expanded ? "flex" : "hidden"} flex-col gap-1`}>
        <a
          href={`https://map.naver.com/p/search/${customer.address}`}
          target={target}
        >
          <div className="text-sm font-extralight">
            <PlaceIcon
              className="text-quezone"
              sx={{ width: 20, height: 20 }}
            />
            {customer.address}
          </div>
        </a>
        <div className="text-sm font-extralight">
          <FaceIcon className="text-quezone" sx={{ width: 20, height: 20 }} />{" "}
          담당자: {assignedBy.name}
        </div>
        <NavLink to={`/tasks/detail/${id}`} target={target}>
          <div className="flex flex-row justify-end">
            <LaunchIcon
              className="text-quezone"
              sx={{ width: 20, height: 20 }}
            />
          </div>
        </NavLink>
      </div>
    </section>
  );
}
