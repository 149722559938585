import { useForm, useWatch } from "react-hook-form";
import { company } from "../../../../../types/company";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import {
  GET_COMPANIES,
  UPDATE_COMPANY_BY_ID,
} from "../../../../../gqls/company";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";

interface props {
  company: company;
}
export default function InvLocationControl({ company }: props) {
  const { data } = useQuery(GET_COMPANIES);
  const companies = (data?.companies || []).filter(c => c.active);

  const defaultValues = {
    invLocations: company.invLocations || [],
  };

  const { setValue, control, reset } = useForm({
    defaultValues,
  });

  const formLocations = useWatch({
    control,
    name: "invLocations",
  });

  const [isDirty, setIsDirty] = useState(false);

  const toggle = (id: number) => {
    setIsDirty(true);
    if (formLocations.includes(id)) {
      setValue(
        "invLocations",
        formLocations.filter(s => s != id)
      );
    } else {
      setValue("invLocations", formLocations.concat(id));
    }
  };

  const [update, { loading }] = useMutation(UPDATE_COMPANY_BY_ID);

  const submit = () => {
    update({
      variables: {
        id: company.id,
        set: {
          invLocations: formLocations,
        },
      },
      onCompleted() {
        reset({
          invLocations: formLocations,
        });
        setIsDirty(false);
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <h1>재고 위치 설정</h1>
      <div className="flex flex-row gap-2 flex-wrap">
        {companies.map(sub => (
          <Chip
            key={sub.id}
            variant={formLocations.includes(sub.id) ? "filled" : "outlined"}
            color={formLocations.includes(sub.id) ? "success" : "default"}
            onClick={() => toggle(sub.id)}
            label={sub.name}
          />
        ))}
      </div>
      {isDirty && (
        <div className="flex flex-row justify-end gap-2">
          <Button
            color="success"
            onClick={() => {
              reset();
              setIsDirty(false);
            }}
          >
            취소
          </Button>

          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={submit}
          >
            저장
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
