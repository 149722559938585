import { FormProvider, useForm } from "react-hook-form";
import { customerPrice } from "../../../../../../types/customer";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState } from "react";
import { currencyFormat } from "../../../../../../utils/numberMethods";
import Button from "@mui/material/Button";
import SupplyCostControl from "./supplyCostControl";
import SubTotalCostControl from "./subTotalCostControl";
import GstCostControl from "./gstCostControl";
import { useMutation } from "@apollo/client";
import {
  ADD_CUSTOMER_PRICE,
  UPDATE_CUSTOMER_PRICE_BY_ID,
} from "../../../../../../gqls/customer";
import LoadingButton from "@mui/lab/LoadingButton";
import hasuraFilter from "../../../../../../utils/hasuraFilter";
import { CUSTOMER_PRICE_FIELDS } from "../../../../../../fragments/customer";
import { useCheckAuth } from "../../../../../../store/authStore";
import { blackButtonContained } from "../../../../../../classPresets";

interface props {
  price?: customerPrice;
  customerId: number;
}

interface defaultValues
  extends Omit<
    customerPrice,
    "id" | "created_at" | "updated_at" | "customerId"
  > {
  installSubTotal: number;
  productSubTotal: number;
  total: number;
}

const schema = z.object({
  installGst: z.coerce.number(),
  installSupply: z.coerce.number(),
  productGst: z.coerce.number(),
  productSupply: z.coerce.number(),
  installSubTotal: z.coerce.number(),
  productSubTotal: z.coerce.number(),
  total: z.coerce.number(),
});

export default function CustomerPriceBreakDown({ price, customerId }: props) {
  const defaultValues: defaultValues = {
    installGst: price?.installGst || 0,
    installSupply: price?.installSupply || 0,
    installSubTotal: (price?.installGst || 0) + (price?.installSupply || 0),
    productGst: price?.productGst || 0,
    productSupply: price?.productSupply || 0,
    productSubTotal: (price?.productGst || 0) + (price?.productSupply || 0),
    total:
      (price?.productGst || 0) +
      (price?.productSupply || 0) +
      (price?.installGst || 0) +
      (price?.installSupply || 0),
  };

  const {
    installGst,
    installSupply,
    productGst,
    productSupply,
    installSubTotal,
    productSubTotal,
    total,
  } = defaultValues;

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const [editing, setEditing] = useState(false);

  const [addPrice, { loading: inserting }] = useMutation(ADD_CUSTOMER_PRICE);
  const [updatePrice, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_PRICE_BY_ID
  );

  const loading = inserting || updating;

  const onSubmit = handleSubmit(data => {
    const { installGst, installSupply, productGst, productSupply } = data;

    if (price) {
      updatePrice({
        variables: {
          id: price.id,
          set: {
            installGst,
            installSupply,
            productGst,
            productSupply,
          },
        },
        onCompleted: () => {
          setEditing(false);
          reset(data);
        },
      });
    } else {
      addPrice({
        variables: {
          object: {
            customerId,
            installGst,
            installSupply,
            productGst,
            productSupply,
          },
        },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              customerPrices(existing = [], { storeFieldName }) {
                const filtered = hasuraFilter({
                  items: [data.insert_customerPrices_one],
                  name: "customerPrices",
                  string: storeFieldName,
                });

                const newPriceRefs = filtered.map(sd =>
                  cache.writeFragment({
                    data: sd,
                    fragment: CUSTOMER_PRICE_FIELDS,
                    fragmentName: "CustomerPriceFields",
                  })
                );
                return [...existing, ...newPriceRefs];
              },
            },
          });
        },
        onCompleted: () => {
          setEditing(false);
          reset(data);
        },
      });
    }
  });

  const checkAuth = useCheckAuth();

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="flex flex-col gap-1 md:gap-2">
        <h2 className="mb-2 text-quezone text-lg md:text-xl">제품부</h2>
        <div className="flex flex-row justify-between gap-2 items-center">
          <h3 className="text-base md:text-md">공급가격</h3>
          {editing ? (
            <SupplyCostControl where="product" />
          ) : (
            <div> {currencyFormat(productSupply)}</div>
          )}
        </div>
        <div className="flex flex-row justify-between gap-2 items-center">
          <h3 className="text-base md:text-md">부가세</h3>
          {editing ? (
            <GstCostControl where="product" />
          ) : (
            <div> {currencyFormat(productGst)}</div>
          )}
        </div>

        <hr className="border-black" />
        <div className="flex flex-row justify-between gap-2 items-center">
          <h3 className="text-base md:text-md">SUB TOTAL</h3>
          {editing ? (
            <SubTotalCostControl where="product" />
          ) : (
            <div className="text-lg font-bold">
              {currencyFormat(productSubTotal)}
            </div>
          )}
        </div>

        <h2 className="mb-2 mt-4 text-quezone text-lg md:text-xl">설치부</h2>
        <div className="flex flex-row justify-between gap-2 items-center">
          <h3 className="text-base md:text-md">공급가격</h3>
          {editing ? (
            <SupplyCostControl where="install" />
          ) : (
            <div> {currencyFormat(installSupply)}</div>
          )}
        </div>
        <div className="flex flex-row justify-between gap-2 items-center">
          <h3 className="text-base md:text-md">부가세</h3>
          {editing ? (
            <GstCostControl where="install" />
          ) : (
            <div> {currencyFormat(installGst)}</div>
          )}
        </div>

        <hr className="border-black" />
        <div className="flex flex-row justify-between gap-2 items-center">
          <h3 className="text-base md:text-md">SUB TOTAL</h3>
          {editing ? (
            <SubTotalCostControl where="install" />
          ) : (
            <div className="text-lg font-bold">
              {currencyFormat(installSubTotal)}
            </div>
          )}
        </div>

        <div className="flex flex-row justify-between gap-2 items-center mt-4">
          <h2 className="text-quezone text-lg md:text-xl">총 가격</h2>
          {editing ? (
            <input
              {...register("total")}
              className="text-center bg-gray-100"
              disabled
            />
          ) : (
            <div className="text-quezone text-lg font-bold">
              {" "}
              {currencyFormat(total)}
            </div>
          )}
        </div>
        {checkAuth({ permissionName: "고객_가격정보수정" }) && (
          <div className="flex flex-row gap-2 mt-4 justify-end">
            <Button
              onClick={() => {
                setEditing(!editing);
              }}
              {...blackButtonContained}
              size="small"
            >
              {editing ? "취소" : "수정"}
            </Button>
            {isDirty && (
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                color="success"
                size="small"
                sx={{ fontWeight: 400 }}
              >
                저장
              </LoadingButton>
            )}
          </div>
        )}
      </form>
    </FormProvider>
  );
}
