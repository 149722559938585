import { useQuery } from "@apollo/client";
import { GET_INV_QTY_CONDITIONAL } from "../../../../../gqls/inv";

interface props {
  companyId: number;
  productId: number;
}

export default function Total({ companyId, productId }: props) {
  const { data } = useQuery(GET_INV_QTY_CONDITIONAL, {
    variables: {
      where: {
        _and: {
          inventory: {
            productId: {
              _eq: productId,
            },
          },

          _or: [
            {
              inventory: {
                companyId: {
                  _eq: companyId,
                },
              },
            },
            {
              inventory: {
                user: {
                  companyId: {
                    _eq: companyId,
                  },
                },
              },
            },
          ],
        },
      },
    },
  });

  const qtys = data?.invQty || [];

  const totalQty = qtys.reduce((acc, qty) => acc + qty.qty, 0);

  return totalQty;
}
