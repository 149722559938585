import { sort } from "fast-sort";
import History from "./history";
import { customerHistory } from "../../../../../../types/customer";

interface historyGroup {
  date: string;
  histories: customerHistory[];
}
interface props {
  group: historyGroup;
  boxClass: string;
}

export default function HistoryGroup({ group, boxClass }: props) {
  const { date, histories } = group;

  const sortedHistories = sort(histories).by({
    desc: h => new Date(h.created_at),
  });

  return (
    <div className="flex flex-col items-start">
      <div className="border-l-[1px] border-dashed border-black flex flex-col gap-2 py-4">
        {sortedHistories.map(h => (
          <History key={h.created_at} history={h} />
        ))}
      </div>
      <div className={boxClass}>{date}</div>
    </div>
  );
}
