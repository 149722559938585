import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthStore } from "../../../../store/authStore";
import { useMutation } from "@apollo/client";
import { ADD_PERMISSION } from "../../../../gqls/permission";
import { PERMISSION_FIELDS } from "../../../../fragments/auth";
import { useAddAlert } from "../../../../store/alertStore";

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
});

export default function AddPermission() {
  const [adding, setAdding] = useState(false);

  const defaultValues = {
    name: "",
    description: "",
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { addPermission } = useAuthStore();
  const [insert, { loading }] = useMutation(ADD_PERMISSION);

  const addAlert = useAddAlert();

  const onSubmit = handleSubmit(data => {
    insert({
      variables: {
        object: data,
      },
      onError(error) {
        console.log(error);
        addAlert({
          message:
            "권한단위 추가에 실패했습니다, 이미 존재하는 단위인지 확인하세요.",
          type: "error",
        });
      },
      onCompleted(data) {
        addPermission(data.insert_permissions_one);
        setAdding(false);
        reset();
      },
      update(cache, { data: { insert_permissions_one: newPermission } }) {
        cache.modify({
          fields: {
            permissions(existing = []) {
              const newPermissionRef = cache.writeFragment({
                data: newPermission,
                fragment: PERMISSION_FIELDS,
              });
              return [...existing, newPermissionRef];
            },
          },
        });
      },
    });
  });

  return (
    <>
      <div className="flex flex-row justify-end">
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            fontWeight: 300,
            marginTop: 1,
          }}
          color="success"
          onClick={() => {
            setAdding(true);
          }}
        >
          추가
        </Button>
      </div>
      <Dialog
        open={adding}
        onClose={() => {
          setAdding(false);
        }}
      >
        <DialogTitle>권한 추가</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.name && touchedFields.name}
              color="success"
              placeholder={
                errors.name?.message ? errors.name?.message : "권한 이름"
              }
              label="이름"
              {...register("name")}
            />
            <TextField
              margin="dense"
              size="small"
              fullWidth
              error={errors.description && touchedFields.description}
              color="success"
              placeholder={
                errors.description?.message
                  ? errors.description?.message
                  : "권한 설명"
              }
              label="설명"
              {...register("description")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setAdding(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            disabled={!isDirty}
            loading={loading}
            variant="contained"
            color="success"
            onClick={onSubmit}
          >
            추가
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
