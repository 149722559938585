import { useEffect, useState } from "react";
import useS3 from "../../../../../hooks/useS3";
import ReportImage from "./reportImage";

interface props {
  dir: string;
}

export default function ReportImages({ dir }: props) {
  const { getObjectsInDir } = useS3();

  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    getObjectsInDir(dir).then(res => {
      if (!res || !res.Contents) {
        return;
      }

      const { Contents } = res;

      const objectKeysOnly = Contents.filter(c => c.Size && c.Size > 0)
        .map(c => c.Key)
        .filter(c => c);
      setImages(objectKeysOnly as string[]);
    });
  }, [dir]);

  return (
    <div className="col-span-full flex flex-col md:gap-1">
      <label className="text-black px-3 md:px-0 bg-gray-100 md:bg-transparent pt-3 md:pt-0 print:pt-0 print:bg-transparent">
        관련 사진
      </label>
      <div className="grid grid-cols-3 gap-4 p-4 md:p-2 md:pt-4 bg-gray-100 md:rounded-md print:rounded-md">
        {images.map(image => (
          <ReportImage key={image} image={image} />
        ))}
      </div>
    </div>
  );
}
