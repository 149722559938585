import { TypedDocumentNode, gql, useLazyQuery } from "@apollo/client";
import { task } from "../../../../types/task";
import { TASK_CORE_FIELDS } from "../../../../fragments/task";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
import { useDashboardStore } from "../../../../store/dashboardStore";
import { useEffect } from "react";
import { cloneDeep } from "apollo-utilities";
import UpcomingPc from "./upcomingPc";
import UpcomingMobile from "./upcomingMobile";
import dayjs from "dayjs";
import { sort } from "fast-sort";

export default function Upcoming() {
  const { user } = useAuthStore();
  const checkAuth = useCheckAuth();
  const subCompanyIds = user?.company?.subCompanies || [];
  const companyIds = [user?.company?.id || 0, ...subCompanyIds].filter(
    companyId =>
      checkAuth({
        permissionName: "작업열람",
        companyIds: [companyId],
      })
  );

  const { taskCursor, setTaskCursor } = useDashboardStore();

  const where =
    user?.auth.name == "admin"
      ? {
          date: {
            _gte: new Date().toISOString(),
          },
        }
      : {
          _and: {
            date: {
              _gte: new Date().toISOString(),
            },
            _or: [
              {
                customer: {
                  companyId: {
                    _in: companyIds,
                  },
                },
              },
              {
                customer: {
                  sourceCompanyId: {
                    _in: companyIds,
                  },
                },
              },
              {
                companyId: {
                  _in: companyIds,
                },
              },
              {
                assignedToId: {
                  _eq: user?.id,
                },
              },
              {
                assignedById: {
                  _eq: user?.id,
                },
              },
            ],
          },
        };

  const [getTasks, { data, subscribeToMore, called, loading }] =
    useLazyQuery(GET_TASKS);

  useEffect(() => {
    getTasks({
      variables: {
        where,
      },
      onCompleted() {
        setTaskCursor(new Date().toISOString());
      },
    });

    const unsubscribe = subscribeToMore({
      document: STREAM_TASKS,
      variables: {
        where,
        cursor: {
          initial_value: { updated_at: taskCursor },
          ordering: "ASC",
        },
      },
      onError(error) {
        console.log(error);
      },
      updateQuery: (previous, { subscriptionData }) => {
        setTaskCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.tasks);
        const newFeedItem = subscriptionData.data.tasks_stream;
        const existing =
          previousData?.filter(p => !newFeedItem.find(i => i.id == p.id)) || [];
        const items = [...newFeedItem, ...existing];
        return Object.assign({}, previous, {
          tasks: items,
        });
      },
    });

    return () => unsubscribe();
  }, []);

  const tasks = data?.tasks || [];

  const groupByDate = sort(
    tasks.reduce(
      (acc, task) => {
        const date = dayjs(task.date).format("YYYY-MM-DD");
        if (acc.find(d => d.date === date)) {
          return acc.map(a => ({
            ...a,
            tasks: date == a.date ? [...a.tasks, task] : a.tasks,
          }));
        }

        return acc.concat({
          date,
          tasks: [task],
        });
      },
      [] as {
        date: string;
        tasks: task[];
      }[]
    )
  ).asc("date");

  return (
    <div className="col-span-1 lg:col-span-3 lg:px-2 flex flex-col gap-4">
      <div className="flex flex-row gap-2 items-center">
        <div className="lg:hidden px-2 self-stretch bg-quezone rounded-md" />
        <h1 className="leading-none mt-[2px] lg:mt-0">다가오는 일정</h1>
      </div>

      {tasks.length == 0 && called && !loading && <NoTask />}
      <UpcomingPc taskGroups={groupByDate} />
      <UpcomingMobile taskGroups={groupByDate} />
    </div>
  );
}

const NoTask = () => {
  return (
    <div className="flex flex-col gap-1 items-center">
      <img src="/allDone.png" className="max-w-[80%]" />
      현재 이후 일정이 없습니다
    </div>
  );
};

const GET_TASKS: TypedDocumentNode<{
  tasks: task[];
}> = gql`
  ${TASK_CORE_FIELDS}
  query GET_TASKS($where: tasks_bool_exp!) {
    tasks(where: $where, limit: 200, order_by: { date: asc }) {
      ...TaskCoreFields
    }
  }
`;

export const STREAM_TASKS: TypedDocumentNode<{
  tasks_stream: task[];
}> = gql`
  ${TASK_CORE_FIELDS}
  subscription STREAM_TASKS(
    $where: tasks_bool_exp
    $cursor: [tasks_stream_cursor_input]!
  ) {
    tasks_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...TaskCoreFields
    }
  }
`;
