import { typeOrStatus } from "../../../../../types/common";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { z } from "zod";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_TYPE_BY_ID } from "../../../../../gqls/task";
import { useTaskStore } from "../../../../../store/taskStore";

interface props {
  type: typeOrStatus;
}

const schema = z.object({
  name: z.string().min(1, { message: "required" }),
  description: z.string().min(1, { message: "required" }),
  color: z.string().min(1, { message: "required" }),
});

export default function EditTaskType({ type }: props) {
  const { name, description, color } = type;

  const { updateTypes } = useTaskStore();

  const defaultValues = {
    name,
    description,
    color,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [update, { loading }] = useMutation(UPDATE_TASK_TYPE_BY_ID);

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: type.id,
        set: data,
      },
      onCompleted(updatedData) {
        const updatedType = updatedData.update_taskTypes_by_pk;
        updateTypes([updatedType]);
        reset(data);
      },
    });
  });

  return (
    <div className="flex flex-col gap-2">
      <label>기본 정보</label>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <TextField
          className="col-span-1"
          margin="dense"
          size="small"
          fullWidth
          error={errors.name && touchedFields.name}
          color="success"
          placeholder={
            errors.name?.message ? errors.name?.message : "타입 이름"
          }
          label="이름"
          {...register("name")}
        />
        <TextField
          className="col-span-1"
          margin="dense"
          size="small"
          fullWidth
          error={errors.description && touchedFields.description}
          color="success"
          placeholder={
            errors.description?.message ? errors.description?.message : "설명"
          }
          label="설명"
          {...register("description")}
        />
        <TextField
          className="col-span-1"
          margin="dense"
          size="small"
          fullWidth
          error={errors.color && touchedFields.color}
          color="success"
          placeholder={errors.color?.message ? errors.color?.message : "색상"}
          label="색상"
          type="color"
          defaultValue="#ffffff"
          {...register("color")}
        />
      </div>

      <div className="flex flex-row justify-end items-center mt-2 gap-2">
        {isDirty && (
          <>
            <Button
              sx={{ color: "black" }}
              onClick={() => {
                reset();
              }}
            >
              취소
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="success"
              onClick={onSubmit}
            >
              수정
            </LoadingButton>
          </>
        )}
      </div>
    </div>
  );
}
