import { sort } from "fast-sort";
import { useTaskStore } from "../../../../../../store/taskStore";
import { task } from "../../../../../../types/task";
import StatGroup from "./statGroup";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useAuthStore } from "../../../../../../store/authStore";
import { scrollbar } from "../../../../../../classPresets";
import { tab } from "../../../../../../types/common";
import useTaskStatUpdate from "../../../../../../hooks/useTaskStatUpdate";

interface props {
  tasks: task[];
  tab: tab;
}

export default function Kanban({ tasks, tab }: props) {
  const { statuses } = useTaskStore();
  const statusFilter = (tab.filters.find(f => f.where == "statusId")?.values ||
    []) as number[];

  const selectedStatuses = statuses.filter(s =>
    statusFilter.map(s => Number(s)).includes(s.id)
  );

  const statGropus = sort(statuses)
    .asc("priority")
    .map(status => ({
      status,
      tasks: tasks.filter(c => c.statusId == status.id),
      selected: selectedStatuses.some(s => s.id == status.id),
    }));

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: { tolerance: 5, delay: 200 },
    }),
    useSensor(MouseSensor, {
      activationConstraint: { distance: 5, delay: 200, tolerance: 20 },
    }),
    useSensor(KeyboardSensor)
  );

  const { user } = useAuthStore();
  const { updateStatus } = useTaskStatUpdate();

  const handleDragEnd = (event: any) => {
    if (!user) {
      return;
    }

    const { active, over } = event;

    const activeItem = tasks.find(c => c.id === active.id);
    const overItem = statuses.find(s => s.id == over?.id);

    if (!activeItem || !overItem) {
      return;
    }

    if (activeItem.statusId == overItem.id) {
      return;
    }

    updateStatus({
      task: activeItem,
      newStatId: overItem.id,
    });
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToWindowEdges]}
    >
      <div
        className={`flex min-w-full h-[calc(100dvh-20px)] flex-row gap-4 overflow-x-auto ${scrollbar} p-[1px] px-10`}
      >
        {statGropus.map(group => (
          <StatGroup key={group.status.id} group={group} />
        ))}
      </div>
    </DndContext>
  );
}
