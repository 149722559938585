import { company } from "../../../../../../types/company";
import { TypedDocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import { COMPANY_FIELDS } from "../../../../../../fragments/company";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useEventListener } from "usehooks-ts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Chip from "@mui/material/Chip";
import {
  DELETE_SOURCE,
  MUTATION_SOURCE_DETAIL_MANY,
  UPDATE_SOURCE_BY_ID,
} from "../../../../../../gqls/source";
import {
  source,
  sourceDetail,
  sourceDetailExtended,
} from "../../../../../../types/source";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { SOURCE_DETAIL_FIELDS } from "../../../../../../fragments/source";
import hasuraFilter from "../../../../../../utils/hasuraFilter";

interface props {
  source: source;
  sourceDetails: sourceDetail[];
  company: company;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const GET_CHAIN_COMPANIES: TypedDocumentNode<{ companies: company[] }> = gql`
  ${COMPANY_FIELDS}
  query GET_CHAIN_COMPANIES($ids: [Int!]!) {
    companies(where: { id: { _in: $ids }, type: { isChain: { _eq: true } } }) {
      ...CompanyFields
    }
  }
`;

export default function EditSource({
  source,
  sourceDetails,
  company,
  editing,
  setEditing,
}: props) {
  const { data: chainCompanyData } = useQuery(GET_CHAIN_COMPANIES, {
    variables: {
      ids: [
        ...(company.subCompanies || []),
        ...sourceDetails.map(sd => sd.companyId),
      ],
    },
  });

  const isSourceCompany = source.isSourceCompany;

  const availableChains = chainCompanyData?.companies || [];

  const defaultValues = {
    companyId: company.id,
    name: source.name,
    sourceDetails: sourceDetails.map(detail => ({
      companyId: detail.companyId,
      name: detail.name,
      id: detail.id,
    })) as { name?: string; companyId?: number; id?: number | string }[],
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
    control,
  } = useForm({ defaultValues });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sourceDetails",
  });

  const formSourceDetails = useWatch({
    control,
    name: "sourceDetails",
  });

  const [updateSourceDetails, { loading: sdLoading }] = useMutation(
    MUTATION_SOURCE_DETAIL_MANY
  );
  const [updateSource, { loading: sourceLoading }] =
    useMutation(UPDATE_SOURCE_BY_ID);

  const [deleteSource, { loading: deleteLoading }] = useMutation(
    DELETE_SOURCE,
    {
      variables: {
        id: source.id,
      },
      onCompleted() {
        setEditing(false);
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: source.id,
          __typename: "sources",
        });

        cache.evict({ id: normalizedId });

        cache.gc();
      },
    }
  );

  const loading = sdLoading || sourceLoading;

  const onSubmit = handleSubmit(async data => {
    const { name } = data;

    const sourceDetailsToInsert = formSourceDetails
      .filter(s => !sourceDetails.find(sd => sd.id == s.id))
      .map(s => ({
        sourceId: source.id,
        name: isSourceCompany ? null : s.name,
        companyId: isSourceCompany ? Number(s.companyId) : null,
      }));

    const updatedSourceDetails = formSourceDetails
      .filter(s => {
        const originalSD = sourceDetails.find(sd => sd.id == s.id);
        if (!originalSD) {
          return false;
        }
        return originalSD.name !== s.name;
      })
      .map(s => ({
        where: { id: { _eq: s.id } },
        _set: {
          sourceId: source.id,
          name: isSourceCompany ? null : s.name,
          companyId: isSourceCompany ? Number(s.companyId) : null,
        },
      }));

    const deletedSourceDetails = sourceDetails
      .filter(s => !formSourceDetails.find(sd => sd.id == s.id))
      .map(s => s.id);

    await updateSource({
      variables: {
        id: source.id,
        set: {
          name,
        },
      },
    });

    updateSourceDetails({
      variables: {
        updates: updatedSourceDetails,
        inserting: sourceDetailsToInsert,
        deleting: deletedSourceDetails,
      },
      onCompleted() {
        reset();
        setEditing(false);
      },
      update(cache, { data }) {
        let insertedSoureDetails = (data.insert_sourceDetails?.returning ||
          []) as sourceDetailExtended[];

        if (data.insert_sources_one) {
          insertedSoureDetails.concat(data.insert_sources_one);
        }

        cache.modify({
          fields: {
            sourceDetails(existing = [], { storeFieldName }) {
              const filtered = hasuraFilter({
                items: insertedSoureDetails,
                name: "sourceDetails",
                string: storeFieldName,
              });

              const newSourceDetailRefs = filtered.map(sd =>
                cache.writeFragment({
                  data: sd,
                  fragment: SOURCE_DETAIL_FIELDS,
                  fragmentName: "SourceDetailFields",
                })
              );
              return [...existing, ...newSourceDetailRefs];
            },
          },
        });

        deletedSourceDetails.forEach(id => {
          const normalizedId = cache.identify({
            id: id,
            __typename: "sourceDetails",
          });
          cache.evict({ id: normalizedId });
        });

        cache.gc();
      },
    });
  });

  const [sourceDetail, setSourceDetail] = useState("");

  const appendSourceDetail = () => {
    isSourceCompany
      ? append({ companyId: Number(sourceDetail) })
      : append({ name: sourceDetail });
    setSourceDetail("");
  };

  useEventListener("keydown", e => {
    if (e.key === "Enter") {
      e.preventDefault();
      appendSourceDetail();
    }
  });

  const doesContain = (id: number) => {
    return formSourceDetails.find(s => s.companyId == id);
  };

  const toggle = (id: number) => {
    if (doesContain(id)) {
      setValue(
        "sourceDetails",
        formSourceDetails.filter(s => s.companyId != id),
        { shouldDirty: true }
      );
    } else {
      setValue("sourceDetails", formSourceDetails.concat({ companyId: id })),
        { shouldDirty: true };
    }
  };

  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    if (deleting) {
      setTimeout(() => setDeleting(false), 3000);
    }
  }, [deleting]);

  return (
    <Dialog
      open={editing ? true : false}
      onClose={() => {
        setEditing(false);
      }}
    >
      <DialogTitle>
        <div className="flex flex-row justify-between">
          접수처 수정{" "}
          <span className="font-normal text-base text-gray-500">
            #{source.id}
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <label className="text-black">이름 *</label>
          <TextField
            className="shadow-sm"
            margin="none"
            size="small"
            required
            id="id"
            autoComplete="off"
            type="text"
            color="success"
            placeholder="접수처 이름"
            {...register("name")}
          />
          <label className="text-black mt-1">세부 접수처</label>
          {isSourceCompany ? (
            <div className="flex flex-row gap-2 flex-wrap max-w-md">
              {availableChains.map(chain => (
                <Chip
                  key={chain.id}
                  variant={doesContain(chain.id) ? "filled" : "outlined"}
                  color={doesContain(chain.id) ? "success" : "default"}
                  onClick={() => toggle(chain.id)}
                  label={chain.name}
                />
              ))}
            </div>
          ) : (
            <>
              <div className="flex flex-row gap-2 items-center">
                <TextField
                  className="shadow-sm"
                  margin="none"
                  size="small"
                  id="id"
                  autoComplete="off"
                  type="text"
                  color="success"
                  placeholder="새 세부 접수처"
                  value={sourceDetail}
                  onChange={e => {
                    setSourceDetail(e.target.value);
                  }}
                />
                <AddCircleIcon
                  className="cursor-pointer"
                  color="success"
                  onClick={appendSourceDetail}
                />
              </div>
              <div className="flex flex-row gap-2 flex-wrap max-w-md mt-1">
                {fields.map((field, index) => (
                  <OutlinedInput
                    size="small"
                    className="w-28"
                    key={field.id}
                    color="success"
                    endAdornment={
                      <InputAdornment position="end">
                        <ClearIcon
                          className="cursor-pointer"
                          onClick={() => remove(index)}
                        />
                      </InputAdornment>
                    }
                    {...register(`sourceDetails.${index}.name`)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            if (deleting) {
              deleteSource();
            } else {
              setDeleting(true);
            }
          }}
        >
          {deleting ? "삭제 확인" : <DeleteForever />}
        </Button>
        <Button
          color="success"
          onClick={() => {
            reset();
            setEditing(false);
          }}
        >
          취소
        </Button>

        <LoadingButton
          disabled={!isDirty && !isSourceCompany}
          loading={loading}
          variant="contained"
          color="success"
          onClick={onSubmit}
        >
          수정
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
