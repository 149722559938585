import { User } from "../../../../../types/user";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_BY_ID } from "../../../../../gqls/user";
import { Controller, useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useAuthStore } from "../../../../../store/authStore";

interface props {
  user: User;
}

const schema = z.object({
  name: z.string().min(1, { message: "이름은 비워둘 수 없습니다" }),
  mobile: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  pushPref: z.boolean(),
});

export default function UserBasicInfoControl({ user }: props) {
  const { name, mobile, address, pushPref } = user;

  const defaultValues = {
    name,
    mobile,
    address,
    pushPref,
  };

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { isDirty, errors, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { user: currentUser, setUser } = useAuthStore();

  const [update, { loading }] = useMutation(UPDATE_USER_BY_ID);

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: user.id,
        set: data,
      },
      onCompleted: data => {
        const user = data.update_users_by_pk;
        const { name, mobile, address, pushPref } = user;
        reset({
          name,
          mobile,
          address,
          pushPref,
        });

        if (user.id == currentUser?.id) {
          setUser({
            ...currentUser,
            name,
            mobile,
            address,
            pushPref,
          });
        }
      },
    });
  });

  return (
    <form onSubmit={onSubmit} className="col-span-1 flex flex-col gap-4">
      <Paper className="border-x-[1px] md:border-none p-4 flex flex-col">
        <h1>개인정보</h1>
        <FormControl margin="dense">
          <label htmlFor="userEmail">이메일</label>
          <TextField
            disabled
            className="shadow-md flex-1"
            size="small"
            id="email"
            autoComplete="off"
            type="text"
            color="success"
            defaultValue={user.email}
          />
        </FormControl>
        <FormControl margin="dense">
          <label htmlFor="userName">이름</label>
          <TextField
            error={errors.name && touchedFields.name}
            className="shadow-md col-span-1"
            size="small"
            id="userName"
            autoComplete="off"
            type="text"
            color="success"
            placeholder="이름"
            {...register("name")}
          />
          <FormHelperText error={errors.name && touchedFields.name}>
            {touchedFields.name && errors.name?.message}
          </FormHelperText>
        </FormControl>
        <FormControl margin="dense">
          <label htmlFor="userMobile">연락처</label>
          <TextField
            error={errors.mobile && touchedFields.mobile}
            className="shadow-md flex-1"
            size="small"
            id="mobile"
            autoComplete="off"
            type="text"
            color="success"
            placeholder="연락처"
            {...register("mobile")}
          />
          <FormHelperText error={errors.mobile && touchedFields.mobile}>
            {touchedFields.mobile && errors.mobile?.message}
          </FormHelperText>
        </FormControl>
        <FormControl margin="dense">
          <label htmlFor="userAddress">주소</label>
          <TextField
            error={errors.address && touchedFields.address}
            className="shadow-md flex-1"
            size="small"
            id="address"
            autoComplete="off"
            type="text"
            color="success"
            placeholder="주소"
            {...register("address")}
          />
          <FormHelperText error={errors.address && touchedFields.address}>
            {touchedFields.address && errors.address?.message}
          </FormHelperText>
        </FormControl>
        <div className="flex flex-row justify-end">
          <Controller
            name="pushPref"
            control={control}
            defaultValue={defaultValues.pushPref}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox color="success" checked={field.value} {...field} />
                }
                label="푸쉬 알림"
              />
            )}
          />
        </div>
      </Paper>
      {isDirty && (
        <div className="flex flex-row gap-2 items-center justify-end flex-1">
          <Button
            color="success"
            onClick={() => {
              reset();
            }}
          >
            취소
          </Button>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            color="success"
            sx={{
              backgroundColor: "black",
              fontWeight: 500,
            }}
          >
            수정
          </LoadingButton>
        </div>
      )}
    </form>
  );
}
