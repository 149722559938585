import { useEffect } from "react";
import useS3 from "./useS3";
import { useAuthStore } from "../store/authStore";

export default function useUserAvatar(id: number) {
  const { getFileURL, getObjectsInDir } = useS3();

  const { avatars, addAvatar, updateAvatar } = useAuthStore();

  const avatar = avatars.find(a => a.id == id)?.url;

  const getAvatar = async () => {
    const res = await getObjectsInDir("users");

    if (!res) {
      return;
    }

    const { Contents } = res;

    if (!Contents || Contents.length == 0) {
      return;
    }

    if (!Contents.find(c => c.Key?.includes(String(id)))) {
      return;
    }

    getFileURL(`users/${id}.png`).then(res => {
      const newAvatar = { id, url: res as string };
      if (avatar) {
        updateAvatar(newAvatar);
      } else {
        addAvatar(newAvatar);
      }
    });
  };

  useEffect(() => {
    if (id == 0) {
      return;
    }
    if (avatar) {
      return;
    }

    getAvatar();
  }, [id]);

  return { avatar, getAvatar };
}
