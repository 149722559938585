import { useState } from "react";
import { AddButton } from "../../../../../comps/oldMobileComps";
import Modal from "@mui/material/Modal";
import { modal } from "../../../../../classPresets";
import AddCorrespondence from "../../../common/correspondences/addCorrespondence";

export default function AddCorrespondencesMobile({
  customerId,
  taskId,
  simple,
}: {
  customerId: number;
  taskId?: number;
  simple?: boolean;
}) {
  const [adding, setAdding] = useState(false);

  const handleClose = () => {
    setAdding(false);
  };

  return (
    <>
      <div className="md:hidden">
        <AddButton onClick={() => setAdding(true)} />
      </div>
      <Modal open={adding} onClose={handleClose}>
        <div
          className={`${modal} !rounded-md p-4 relative flex flex-col gap-4`}
        >
          내역추가
          <AddCorrespondence
            cancelFunc={handleClose}
            taskId={taskId}
            simple={simple}
            mobile
            customerId={customerId}
          />
        </div>
      </Modal>
    </>
  );
}
