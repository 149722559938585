import { useQuery } from "@apollo/client";
import { GET_PAYMENT_METHODS } from "../../../../../../gqls/customer";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CreditCard from "@mui/icons-material/CreditCard";
import LocalAtm from "@mui/icons-material/LocalAtm";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function PaymentControls() {
  const { data } = useQuery(GET_PAYMENT_METHODS);

  const paymentMethods = data?.paymentMethods;

  const { register, control } = useFormContext();

  if (!paymentMethods) {
    return null;
  }

  return (
    <>
      <div className="col-span-1 flex flex-col gap-1">
        <label>금액</label>
        <TextField
          color="success"
          type="number"
          margin="none"
          size="small"
          fullWidth
          {...register("amount")}
        />
      </div>
      <div className="col-span-1 flex flex-col gap-1">
        <label>구분</label>
        <Controller
          name="methodId"
          control={control}
          render={({ field }) => (
            <Select
              color="success"
              margin="none"
              size="small"
              fullWidth
              {...field}
            >
              {paymentMethods.map(m => (
                <MenuItem key={m.id} value={m.id}>
                  <div className="flex flex-row gap-2">
                    {m?.icon == "CreditCard" ? <CreditCard /> : <LocalAtm />}
                    {m.name}
                  </div>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </div>
      <div className="col-span-1 md:col-span-2 flex flex-col gap-1">
        <label>날짜</label>
        <Controller
          name="received_at"
          control={control}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                slotProps={{
                  textField: {
                    size: "small",
                    color: "success",
                    variant: "outlined",
                  },
                  openPickerButton: {
                    color: "success",
                  },
                }}
                value={field.value}
                onChange={e => {
                  field.onChange(e);
                  field.onBlur();
                }}
              />
            </LocalizationProvider>
          )}
        />
      </div>
      <div className="col-span-1 md:col-span-2 flex flex-col gap-1">
        <label>비고</label>
        <TextField
          color="success"
          multiline
          minRows={2}
          type="number"
          margin="none"
          size="small"
          fullWidth
          {...register("comment")}
        />
      </div>
    </>
  );
}
