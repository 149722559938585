import Button from "@mui/material/Button";
import { task } from "../../../../../../types/task";
import { blackButtonContained } from "../../../../../../classPresets";
import { useLazyQuery } from "@apollo/client";
import { GET_REPORTS_BY_TASK_IDS } from "../../../../../../gqls/report";
import { Fragment, useRef, useState } from "react";
import { extendedReport } from "../../../../../../types/report";
import ReportDetail from "../../../report/reportDetail/reportDetail";
import { useReactToPrint } from "react-to-print";

interface props {
  reports: extendedReport[];
}

export default function Reports({ reports }: props) {
  const printableDiv = useRef(null);
  const printReports = useReactToPrint({
    content: () => printableDiv.current,
    documentTitle: `보고서`,
  });

  const getPageMargins = () => {
    return `
    @media all {
      .page-break {
        display: none;
      }
    }
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        display: block;
        page-break-before: always;
      }
      .no-print, .no-print * {
        display:none !important
      }
    }
    `;
  };

  return (
    <div className="flex flex-col gap-4">
      {reports.length > 0 && (
        <div className="flex flex-row justify-end gap-2 items-center">
          <Button {...blackButtonContained} onClick={printReports}>
            프린트
          </Button>
        </div>
      )}
      <div
        ref={printableDiv}
        className="flex flex-col gap-2 bg-white shadow-md print:shadow-none rounded-md"
      >
        <style>{getPageMargins()}</style>
        {reports.map((report, i) => (
          <Fragment key={report.id}>
            <div className="p-4 print:px-2 pt-4 flex flex-col gap-4">
              <ReportDetail report={report} viewOnly />
            </div>
            {i !== reports.length - 1 && <div className="page-break hidden" />}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
