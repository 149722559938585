import { useNavigate } from "react-router-dom";
import TaskControls from "./taskControls/taskControls";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { tag } from "../../../../../../types/common";
import dayjs from "dayjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@apollo/client";
import {
  ADD_TASK_TAG_MANY,
  UPDATE_TASK_BY_ID,
} from "../../../../../../gqls/task";
import hasuraFilter from "../../../../../../utils/hasuraFilter";
import { TASK_TAG_FIELDS } from "../../../../../../fragments/task";
import { task } from "../../../../../../types/task";
import { useAuthStore } from "../../../../../../store/authStore";
import {
  blackButtonContained,
  blackButtonOutlined,
} from "../../../../../../classPresets";
import useAddCustomerHistory from "../../../../../../hooks/useAddCustomerHistory";
import { useTaskStore } from "../../../../../../store/taskStore";
import EditInventoryFromInstaller from "./editInventoryFromInstaller";
import { inventory_with_product } from "../../../../../../types/inv";
import useInvAction from "../../../../../../hooks/useInvActions";
import { useInvStore } from "../../../../../../store/invStore";

interface props {
  task: task;
}

const schema = z.object({
  description: z.string().nullable(),
  tags: z.array(z.any()),
  typeId: z.coerce.number().min(1, "고객 구분을 선택하세요"),
  companyId: z.coerce.number().min(1, "담당사를 선택하세요"),
  assignedToId: z.coerce.number().nullable().optional(),
  statusId: z.coerce.number().min(1, "고객 상태를 선택하세요"),
  date: z.coerce.date(),
  time: z.coerce.date().nullable().optional(),
  amount: z.coerce.number().nullable(),
  inv: z.any().nullable().optional(),
  invQtyId: z.coerce.number().nullable().optional(),
  qty: z.coerce.number().nullable().optional(),
  invActionId: z.coerce.number().nullable().optional(),
});

export default function EditTask({ task }: props) {
  const navigate = useNavigate();

  const { types, statuses } = useTaskStore();
  const { invActions } = useInvStore();

  const {
    typeId,
    statusId,
    companyId,
    assignedToId,
    amount,
    tagIds,
    description,
    date,
    time,
  } = task;

  const defaultValues = {
    typeId,
    statusId,
    companyId,
    assignedToId,
    amount,
    tags: [] as Partial<tag>[],
    description,
    date: dayjs(date),
    time: time ? dayjs(time, "HH:mm:ssZ") : null,
    inv: null as inventory_with_product | null,
    invQtyId: 0,
    qty: 1,
    invActionId: 0,
  };
  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = methods;

  const [editTask, { loading }] = useMutation(UPDATE_TASK_BY_ID);
  const [addTags] = useMutation(ADD_TASK_TAG_MANY);
  const { user } = useAuthStore();
  const { addHistory } = useAddCustomerHistory();

  const inventory = useWatch({
    control,
    name: "inv",
  });

  const invQtyId = useWatch({
    control,
    name: "invQtyId",
  });

  const selectedInvQty = inventory?.qty.find(q => q.id == Number(invQtyId));

  const addInventory = useInvAction({
    inventory,
    invQty: selectedInvQty,
  });

  const onSubmit = handleSubmit(async data => {
    const {
      typeId,
      statusId,
      companyId,
      assignedToId,
      amount,
      tags,
      description,
      date,
      time,
      inv,
    } = data;

    let invHistoryId = task.invHistoryId as number | null;

    await (async () => {
      if (inv?.id) {
        const invAction = invActions.find(a => a.id == data.invActionId);
        if (!invAction || !inventory) {
          console.log(invAction, inventory);
          return;
        }

        const id = await addInventory({
          qty: data.qty,
          customerId: task.customerId,
          invAction,
          location: {
            type: "user",
            id: (inventory.userId
              ? inventory.userId
              : inventory.companyId) as number,
          },
        });

        invHistoryId = id || null;
      }
    })();

    // handle Tags
    const tagIds = [] as number[];
    await (async () => {
      tagIds.push(...tags.filter(t => t.id).map(t => t.id as number));
      const newTags = tags.filter(t => !t.id);

      if (newTags.length < 1) {
        return;
      }

      await addTags({
        variables: {
          objects: newTags,
        },
        onCompleted(data) {
          let insertedTags = (data.insert_customerTags?.returning ||
            []) as tag[];

          if (data.insert_customerTags_one) {
            insertedTags.concat(data.insert_customerTags_one);
          }

          tagIds.push(...insertedTags.map(c => c.id));
        },
        onError(error) {
          console.log(error);
        },
        update(cache, { data }) {
          let insertedTags = (data.insert_customerTags?.returning ||
            []) as tag[];

          if (data.insert_customerTags_one) {
            insertedTags.concat(data.insert_customerTags_one);
          }

          cache.modify({
            fields: {
              taskTags(existing = [], { storeFieldName }) {
                const filtered = hasuraFilter({
                  items: insertedTags,
                  name: "taskTags",
                  string: storeFieldName,
                });

                const newTagsRefs = filtered.map(sd =>
                  cache.writeFragment({
                    data: sd,
                    fragment: TASK_TAG_FIELDS,
                    fragmentName: "TaskTagFields",
                  })
                );
                return [...existing, ...newTagsRefs];
              },
            },
          });
        },
      });

      return;
    })();

    await editTask({
      variables: {
        id: task.id,
        set: {
          typeId,
          statusId,
          companyId,
          assignedToId: assignedToId ? assignedToId : undefined,
          amount,
          tagIds,
          description,
          assignedById: user?.id,
          reads: [user?.id],
          date: date.toISOString(),
          time: time ? dayjs(time).format("HH:mm:ssZ") : undefined,
          invHistoryId,
        },
      },
      onCompleted() {
        if (task.statusId !== statusId) {
          const oldStat = statuses.find(s => s.id === task.statusId);
          const newStat = statuses.find(s => s.id === statusId);
          addHistory({
            customerId: task.customerId,
            type: "task",
            message: `작업 상태 변경 [${oldStat?.name} => ${newStat?.name}]`,
          });
        }
        if (task.typeId !== typeId) {
          const oldType = types.find(s => s.id === task.typeId);
          const newType = types.find(s => s.id === typeId);
          addHistory({
            customerId: task.customerId,
            type: "task",
            message: `작업 구분 변경 [${oldType?.name} => ${newType?.name}]`,
            link: `/tasks/detail/${task.id}`,
            taskId: task.id,
          });
        }
      },
    });

    reset();
    navigate(-1);
  });

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-2 px-3 md:px-0" onSubmit={onSubmit}>
        {/* Header PC */}
        <div className="hidden md:flex flex-row justify-end items-center gap-2 text-white bg-gray-200 px-6 py-3 mb-3">
          <h2 className="font-normal">작업 수정</h2>
        </div>
        {/* Header Mobile */}
        <div className="md:hidden">
          <h2>작업 수정</h2>
        </div>
        {/* Fields */}
        <TaskControls tagIds={tagIds} />
        <EditInventoryFromInstaller task={task} />
        {/* Footer */}
        <div className="flex flex-row justify-end gap-4 mt-4">
          <Button
            {...blackButtonOutlined}
            onClick={() => {
              navigate(-1);
            }}
          >
            취소
          </Button>
          <LoadingButton
            loading={loading}
            disabled={!isDirty}
            {...blackButtonContained}
            type="submit"
          >
            수정
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
}
