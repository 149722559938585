import { create } from "zustand";
import axios from "axios";
import { useDeviceStore } from "./deviceStore";
import { useAddAlert } from "./alertStore";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { LoginUser, NewUser, User } from "../types/user";
import { Auth, Permission, AuthPermission } from "../types/auth";
import { useNotificationStore } from "./notificationStore";
import { useCallback } from "react";
import { GET_COMPANIES } from "../gqls/company";
import { GET_USERS } from "../gqls/user";
import useS3 from "../hooks/useS3";

const API = import.meta.env.VITE_API;

interface avatar {
  id: number;
  url: string | null;
}

type AuthStore = {
  user?: User;
  setUser: (user: User) => void;
  removeUser: () => void;
  loading: boolean;
  setLoading: (bool: boolean) => void;
  loggingIn: boolean;
  setLoggingIn: (bool: boolean) => void;
  auths: Auth[];
  setAuths: (auths: Auth[]) => void;
  addAuth: (Auth: Auth) => void;
  updateAuth: (Auth: Auth) => void;
  deleteAuth: (id: number) => void;
  permissions: Permission[];
  setPermissions: (permissions: Permission[]) => void;
  addPermission: (permission: Permission) => void;
  updatePermission: (permission: Permission) => void;
  deletePermission: (id: number) => void;
  avatars: avatar[];
  setAvatars: (avatars: avatar[]) => void;
  addAvatar: (avatar: avatar) => void;
  updateAvatar: ({ id, url }: avatar) => void;
};

export const useAuthStore = create<AuthStore>(set => ({
  loading: true,
  setLoading: bool =>
    set(state => ({
      ...state,
      loading: bool,
    })),
  user: undefined,
  setUser: user =>
    set(state => ({
      ...state,
      user: user,
      loading: false,
    })),
  removeUser: () => {
    localStorage.clear();
    localStorage.setItem("persist", "false");
    return set(state => ({
      ...state,
      user: undefined,
      loading: false,
    }));
  },
  loggingIn: false,
  setLoggingIn: bool =>
    set(state => ({
      ...state,
      loggingIn: bool,
    })),
  permissions: [],
  setPermissions(permissions) {
    set(state => ({
      ...state,
      permissions,
    }));
  },
  addPermission(permission) {
    set(state => ({
      ...state,
      permissions: [...state.permissions, permission],
    }));
  },
  updatePermission(permission) {
    set(state => ({
      ...state,
      permissions: state.permissions.map(p => {
        if (p.id === permission.id) {
          return permission;
        }
        return p;
      }),
    }));
  },
  deletePermission(id) {
    set(state => ({
      ...state,
      permissions: state.permissions.filter(p => p.id !== id),
    }));
  },
  auths: [],
  setAuths(auths) {
    set(state => ({
      ...state,
      auths,
    }));
  },
  addAuth(auth) {
    set(state => ({
      ...state,
      auths: [...state.auths, auth],
    }));
  },
  updateAuth(auth) {
    set(state => ({
      ...state,
      auths: state.auths.map(p => {
        if (p.id === auth.id) {
          return auth;
        }
        return p;
      }),
    }));
  },
  deleteAuth(id) {
    set(state => ({
      ...state,
      auths: state.auths.filter(p => p.id !== id),
    }));
  },
  avatars: [],
  setAvatars(avatars) {
    set(state => ({
      ...state,
      avatars,
    }));
  },
  addAvatar(avatar) {
    set(state => ({
      ...state,
      avatars: [...state.avatars, avatar],
    }));
  },
  updateAvatar({ id, url }) {
    set(state => ({
      ...state,
      avatars: state.avatars.map(p => {
        if (p.id === id) {
          return { id, url };
        }
        return p;
      }),
    }));
  },
}));

export const useLoadUser = () => {
  const { setLoading, setUser, setLoggingIn, removeUser } = useAuthStore();
  const { token: pushToken } = useNotificationStore();

  const addAlert = useAddAlert();

  return async () => {
    try {
      setLoading(true);
      setLoggingIn(true);

      const { location, ip, agent } = useDeviceStore.getState();

      const res = await axios.post(API + "/auth/loadUser", {
        location,
        ip,
        agent: agent?.summary,
        pushToken,
      });

      const user = res.data?.user;

      if (user) {
        sessionStorage.setItem("accessToken", res.data.token);
        setUser(user);
      }

      setLoading(false);
      setLoggingIn(false);
      return true;
    } catch (error: any) {
      if (error?.response?.data?.msg) {
        const errorMessage = error?.response?.data?.msg;
        addAlert({
          type: "error",
          message: errorMessage,
        });
      } else {
        console.log(error);
      }

      setLoading(false);
      setLoggingIn(false);

      localStorage.setItem("persist", "false");
      removeUser();

      return false;
    }
  };
};

export const useLogin = () => {
  const { setLoggingIn, setUser, removeUser } = useAuthStore();
  const { location, ip, agent } = useDeviceStore();
  const { token: pushToken } = useNotificationStore();

  const addAlert = useAddAlert();

  const navigate = useNavigate();

  return async (user: LoginUser) => {
    setLoggingIn(true);

    try {
      const res = await axios.post(API + "/auth/login", {
        ...user,
        location,
        ip,
        agent: agent?.summary,
        pushToken,
      });

      const User = res.data?.user;

      if (User) {
        sessionStorage.setItem("accessToken", res.data.token);
        localStorage.setItem("persist", user.persist ? "true" : "false");

        setUser(User);
        navigate("/");
      }

      setLoggingIn(false);
      return true;
    } catch (error: any) {
      if (error?.response?.data?.msg) {
        const errorMessage = error?.response?.data?.msg;
        addAlert({
          type: "error",
          message: errorMessage,
        });
      } else {
        console.log(error);
      }
      setLoggingIn(false);
      removeUser();
      sessionStorage.removeItem("accessToken");
      return false;
    }
  };
};

export const useRegister = () => {
  const { setLoggingIn, setUser } = useAuthStore();
  const { location, ip, agent } = useDeviceStore.getState();
  const { token: pushToken } = useNotificationStore();
  const navigate = useNavigate();

  const addAlert = useAddAlert();

  const { uploadFile } = useS3();

  return async (user: NewUser) => {
    try {
      setLoggingIn(true);
      const res = await axios.post(API + "/auth/register", {
        user,
        location,
        ip,
        agent: agent?.summary,
        pushToken,
      });

      if (res.data) {
        const User = res.data.user;
        console.log(User);

        setUser({ ...User });
        sessionStorage.setItem("accessToken", res.data.token);
        localStorage.setItem("persist", "true");
        if (user.avatar) {
          uploadFile(user.avatar, `users/${User.id}.png`, "image/png");
        }
      }
      setLoggingIn(false);
      navigate("/");
      return true;
    } catch (error: any) {
      if (error?.response?.data?.msg) {
        const errorMessage = error?.response?.data?.msg;
        addAlert({
          type: "error",
          message: errorMessage,
        });
      } else {
        console.log(error);
      }
      setLoggingIn(false);
      return false;
    }
  };
};

export const useRecover = () => {
  const addAlert = useAddAlert();

  return async (email: string) => {
    try {
      await axios.post(API + "/auth/recover", {
        email,
      });
    } catch (error: any) {
      if (error?.response?.data?.msg) {
        const errorMessage = error?.response?.data?.msg;
        addAlert({
          type: "error",
          message: errorMessage,
        });
      } else {
        console.log(error);
      }
    }
  };
};

export const useReset = () => {
  const addAlert = useAddAlert();

  return async (token: string, password: string) => {
    try {
      if (token == "") {
        return addAlert({
          type: "warning",
          message: "토큰이 올바르지 않습니다",
        });
      }

      const res = await axios.post(API + "/auth/reset", { token, password });

      if (res.data) {
        addAlert({
          type: "success",
          message:
            "비밀번호를 성공적으로 재설정 하였습니다, 다시 로그인 해주세요",
        });
        return true;
      } else {
        addAlert({
          type: "error",
          message: "비밀번호를 재설정 할 수 없습니다, 관리자에게 연락해주세요",
        });
        return false;
      }
    } catch (error: any) {
      if (error?.response?.data?.msg) {
        const errorMessage = error?.response?.data?.msg;
        addAlert({
          type: "error",
          message: errorMessage,
        });
      } else {
        console.log(error);
      }

      return;
    }
  };
};

const DELETE_DEVICES = gql`
  mutation delete_devices($device: String!, $userId: Int!, $ip: String!) {
    delete_devices(
      where: {
        device: { _eq: $device }
        userId: { _eq: $userId }
        ip: { _eq: $ip }
      }
    ) {
      affected_rows
    }
  }
`;

export const useLogOut = () => {
  const [delete_devices] = useMutation(DELETE_DEVICES);
  const { agent, ip } = useDeviceStore();

  const { user, removeUser } = useAuthStore();

  const variables = {
    device: agent?.summary,
    userId: user?.id,
    ip,
  };

  return async () => {
    try {
      removeUser();
      await delete_devices({ variables });
      sessionStorage.removeItem("accessToken");
    } catch (error) {
      console.log(error);
      return false;
    }
  };
};

export const useCheckAuth = () => {
  const { user } = useAuthStore();

  const { permissions } = useAuthStore();
  const { data: companyData } = useQuery(GET_COMPANIES);
  const companies = companyData?.companies;

  const { data: usersData } = useQuery(GET_USERS);
  const users = usersData?.users;

  const userPermissions: AuthPermission[] =
    user?.auth.permissions.map(permission => permission) || [];

  /**
   * check permission or permissions and return true or false
   * @param {boolean} isPeter only alllow developer user
   */
  const checkAuth = ({
    isPeter,
    permissionName,
    companyIds,
    userId,
    scopeCheck,
  }: {
    isPeter?: boolean;
    permissionName?: string | null;
    companyIds?: number[];
    userId?: number;
    scopeCheck?: AuthPermission["scope"][];
  }) => {
    if (!user) {
      return false;
    }

    if (isPeter) {
      return user.email == "ksi9302@gmail.com";
    }

    // if user is admin, all permissions are allowed
    if (user?.auth.name == "admin") {
      return true;
    }

    if (!permissionName) {
      return false;
    }

    const permission = permissions.find(p => p.name == permissionName);
    if (!permission) {
      return false;
    }

    const userPermission = userPermissions.find(up => up.id == permission.id);

    if (scopeCheck) {
      return (
        userPermission?.scope && scopeCheck.includes(userPermission?.scope)
      );
    }

    const userCompanies = [user.company];
    if (user.company?.subCompanies) {
      const subsPopulated = user.company.subCompanies.map(cId =>
        companies?.find(c => c.id == cId)
      );
      userCompanies.push(...subsPopulated);
    }

    const userSubUsers = userCompanies
      .map(company => {
        if (!company || company.noUsers) {
          return null;
        }
        const companyUsers = users?.filter(u => u.company?.id == company.id);
        return companyUsers || null;
      })
      .filter(u => u)
      .flat();

    if (!userPermission) {
      return false;
    }

    if (userPermission.scope == "all") {
      return true;
    }

    if (!companyIds && !userId) {
      return true;
    }

    if (userPermission.scope == "sub") {
      if (
        companyIds &&
        companyIds.filter(id => id !== 0).length > 0 &&
        !companyIds.some(ci => userCompanies.some(c => c?.id == ci))
      ) {
        return false;
      }

      if (userId && !userSubUsers.some(u => u?.id == userId)) {
        return false;
      }

      return true;
    }

    if (userPermission.scope == "company") {
      if (
        companyIds &&
        companyIds.filter(id => id !== 0).length > 0 &&
        !companyIds.includes(user.company?.id || 0)
      ) {
        return false;
      }

      return true;
    }

    if (userPermission.scope == "me") {
      return userId && userId == user.id;
    }

    return true;
  };

  return useCallback(checkAuth, [
    permissions,
    userPermissions,
    companies,
    users,
  ]);
};
