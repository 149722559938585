import Button from "@mui/material/Button";
import { useFormContext, useWatch } from "react-hook-form";
import { useState } from "react";
import DeleteForever from "@mui/icons-material/DeleteForever";
import SNCapture from "./SNCapture";

export default function ReportSNControl() {
  const { control, setValue } = useFormContext();

  const serials: string[] = useWatch({
    control,
    name: "serials",
    defaultValue: [],
  });

  const [newSN, setNewSN] = useState("");
  const addSN = () => {
    const trimmed = newSN.trim();
    if (trimmed == "") {
      return;
    }
    const newSerials = [...serials.filter(s => s !== trimmed), trimmed];
    setNewSN("");
    setValue("serials", newSerials, { shouldDirty: true });
  };

  const remove = (serial: string) => {
    const newSerials = serials.filter(s => s !== serial);
    setValue("serials", newSerials, { shouldDirty: true });
  };

  return (
    <div className="flex flex-col gap-1 bg-gray-100 md:bg-transparent pb-3 md:pb-0">
      <label className="text-black p-3 md:p-0 pb-0">일련번호 등록</label>
      <div className="flex flex-col gap-1 rounded-sm bg-gray-100 p-2">
        <ul className="indent-2 pl-8 list-disc">
          {serials.map((serial, i) => (
            <li key={`${serial}-${i}`}>
              {serial}{" "}
              <DeleteForever
                className="text-red-500 pb-[1px] cursor-pointer"
                sx={{ width: 20 }}
                onClick={() => {
                  remove(serial);
                }}
              />
            </li>
          ))}
        </ul>
        <div className="col-span-full flex flex-row justify-end p-1 gap-2">
          <input
            value={newSN}
            onChange={e => setNewSN(e.target.value)}
            className="p-1 text-sm border-b-[1px] flex-1 text-center outline-none w-0"
            placeholder="새 일련번호 입력"
          />
          <Button
            size="small"
            color="success"
            variant="outlined"
            sx={{ fontWeight: 400 }}
            onClick={addSN}
          >
            추가
          </Button>
          <SNCapture setNewSN={setNewSN} />
        </div>
      </div>
    </div>
  );
}
