import {
  S3Client,
  ListObjectsCommand,
  ListObjectsCommandInput,
  GetObjectCommand,
  PutObjectCommand,
  PutObjectCommandInput,
  DeleteObjectCommand,
  DeleteObjectCommandInput,
  DeleteObjectsCommand,
  DeleteObjectsCommandInput,
  CopyObjectCommand,
  CopyObjectCommandInput,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useAddAlert } from "../store/alertStore";
import { FetchHttpHandler } from "@smithy/fetch-http-handler";

const accessKeyId = import.meta.env.VITE_VULTR_ACCESS_ID;
const secretAccessKey = import.meta.env.VITE_VULTR_ACCESS_KEY;

const s3 = new S3Client({
  region: "sgp",
  endpoint: "https://sgp1.vultrobjects.com", //your region S3 endpoint with https://
  credentials: {
    accessKeyId, //your access-key
    secretAccessKey, //your secret-key
  },
  requestHandler: new FetchHttpHandler({ keepAlive: false }),
});

export default function useS3() {
  const addAlert = useAddAlert();

  const uploadFile = async (
    file: File | Blob,
    Key: string,
    ContentType?: string
  ) => {
    try {
      if (navigator?.onLine === false) {
        addAlert({
          type: "error",
          message: "인터넷이 연결되어있지 않습니다.",
          persist: true,
        });

        return false;
      }
      const input: PutObjectCommandInput = {
        Body: file,
        Bucket: "quezone",
        Key,
        ContentType,
        ACL: "public-read",
        ContentDisposition: "inline",
      };
      const command = new PutObjectCommand(input);
      const response = await s3.send(command);

      if (!!response?.$metadata?.httpStatusCode) {
        return true;
      } else {
        const response = await s3.send(command);
        return !!response?.$metadata?.httpStatusCode;
      }
    } catch (error) {
      addAlert({
        type: "error",
        message: `s3_upload_failure: ${error}`,
        persist: true,
      });
      console.log(error);
      return false;
    }
  };

  const getObjectsInDir = async (Dir: string) => {
    try {
      const input: ListObjectsCommandInput = {
        Bucket: "quezone",
        Delimiter: "/",
        Prefix: `${Dir}/`,
      };
      const command = new ListObjectsCommand(input);
      const response = await s3.send(command);

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /**
   * Gets objects in the directory including sub directories
   * @param {string} Dir
   * @returns {ListObjectsCommandOutput}
   */
  const getObjectsInDirV2 = async (Dir: string) => {
    try {
      const input: ListObjectsCommandInput = {
        Bucket: "quezone",
        // Delimiter: "/",
        Prefix: `${Dir}/`,
      };
      const command = new ListObjectsCommand(input);
      const response = await s3.send(command);

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteObject = async (Key: string) => {
    try {
      const input: DeleteObjectCommandInput = {
        Bucket: "quezone",
        Key,
      };
      const command = new DeleteObjectCommand(input);
      const response = await s3.send(command);

      return !!response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteDirectory = async (location: string) => {
    try {
      let list = await getObjectsInDir(location);

      if (list && list.Contents) {
        const input: DeleteObjectsCommandInput = {
          Bucket: "quezone",
          Delete: {
            Objects: list.Contents.map(item => ({ Key: item.Key || "" })),
          },
        };

        const command = new DeleteObjectsCommand(input);
        const response = await s3.send(command);

        return response;
      } else {
        return "No files to delete.";
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const renameFile = async (Key: string, newKey: string) => {
    try {
      const input: CopyObjectCommandInput = {
        ACL: "public-read",
        Bucket: "quezone",
        CopySource: encodeURI(`quezone/${Key}`),
        Key: newKey,
      };

      const command = new CopyObjectCommand(input);
      const response = await s3.send(command);

      if (response.$metadata.httpStatusCode == 200) {
        await deleteObject(Key);

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const moveDir = async (dir: string, newDir: string) => {
    try {
      const res = await getObjectsInDirV2(dir);
      if (!res || !res.Contents) {
        throw new Error("Directory does not exist");
      }

      const keys = res.Contents.map(item => item.Key).filter(
        item => !!item
      ) as string[];

      await Promise.all(
        keys.map(async key => {
          await renameFile(key, key.replace(dir, newDir));
        })
      );

      return true;
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const getFileURL = async (Key: string) => {
    try {
      const command = new GetObjectCommand({ Bucket: "quezone", Key });
      const url = await getSignedUrl(s3, command, { expiresIn: 3600 * 24 * 7 }); // expires in one week

      return url;
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  return {
    uploadFile,
    getFileURL,
    getObjectsInDir,
    deleteObject,
    deleteDirectory,
    renameFile,
    moveDir,
  };
}
