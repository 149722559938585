import { memo, useEffect, useRef, useState } from "react";
import { useCheckAuth } from "../../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { blackButtonOutlined, scrollbar } from "../../../../classPresets";
import Header from "../../layouts/header/header";
import VisuallyHiddenInput from "../../../../comps/hiddenInput";
import Button from "@mui/material/Button";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { DataGrid } from "@mui/x-data-grid";
import { useLazyQuery } from "@apollo/client";
import { mongoReport } from "../types";
import useReportUpload from "./useReportUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import MyBadge from "../../../../comps/myBadge";
import { GET_REPORT_M } from "../../../../gqls/report";
import DataArrayIcon from "@mui/icons-material/DataArray";

export default function ReportMigration() {
  const checkAuth = useCheckAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!checkAuth({ isPeter: true })) {
      navigate(-1);
    }
  }, []);

  const [getReports] = useLazyQuery(GET_REPORT_M);

  const [reports, setReports] = useState<mongoReport[]>([]);
  const done = useRef<string[]>([]);
  const [currentId, setCurrentId] = useState<null | string>(null);

  const handleFile = async (file: File, adding?: boolean) => {
    const size = file.size; //getting the file size so that we can use it for loop statement
    let i = 0;
    let offset = 0;
    const chunk_size = 1024 * 100;

    const chunks = [];
    while (i < size) {
      const blob = file.slice(offset, offset + chunk_size); //slice the file by specifying the index(chunk size)
      chunks.push(blob);
      offset += chunk_size; // Increment the index position(chunk)
      i += chunk_size; // Keeping track of when to exit, by incrementing till we reach file size(end of file).
    }

    const output: string[] = await Promise.all(
      chunks.map(async chunk => {
        const res: string = await new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = e => {
            resolve((e.target?.result || "") as string);
            // output += ; //concatenate the output on each iteration.
          };
          reader.readAsText(chunk, "UTF-8");
        });
        return res as string;
      })
    );

    const result = JSON.parse(output.join(""));

    if (!Array.isArray(result)) {
      return;
    }

    const _reports: mongoReport[] = result;

    const res = await getReports();
    const exReports = res.data?.reports || [];

    if (result) {
      const filtered = _reports.filter(
        r => !exReports.some(er => er.uuid === r._id && r.reporter)
      );

      if (adding) {
        setReports(reps => [...reps, ...filtered]);
        return;
      } else {
        setReports(filtered);
        return;
      }
    }
  };

  const importSingle: React.ChangeEventHandler<HTMLInputElement> = async e => {
    setReports([]);
    const files = e.target.files;
    if (!files || !files[0]) {
      return;
    }
    const file = files[0];

    handleFile(file);
  };

  const importMultiple: React.ChangeEventHandler<
    HTMLInputElement
  > = async e => {
    const files = e.target.files;

    if (!files) {
      return;
    }

    for await (const file of files) {
      if (!file) {
        continue;
      }
      await handleFile(file, true);
    }
  };

  const columns = [
    { field: "id", width: 250, headerName: "id", hide: true },
    { field: "type", width: 200, headerName: "type" },
    { field: "category", width: 200, headerName: "category" },
    { field: "customerId", width: 100, headerName: "customerId" },
    { field: "by", width: 100, headerName: "by" },
    { field: "description", width: 100, headerName: "desc" },
    { field: "date", width: 100, headerName: "date" },
    {
      field: "progress",
      width: 100,
      headerName: "Prog",
      renderCell: (params: any) => (
        <>
          {currentId == params.value ? (
            <CircularProgress color="success" size={20} />
          ) : (
            <MyBadge
              text={`${done.current.includes(params.value) ? "V" : "X"}`}
              color={done.current.includes(params.value) ? "green" : "red"}
            />
          )}
        </>
      ),
    },
  ];

  const rows = reports.map(r => ({
    id: r._id,
    type: r.type,
    category: r.subCategory,
    customerId: r.customerId,
    by: r.reporter,
    description: r.desc,
    date: r.reportDate,
    progress: r._id,
  }));

  const { upload } = useReportUpload();

  const [uploading, setUploading] = useState(false);

  const uploadAll = async () => {
    setUploading(true);
    for await (const report of reports) {
      setCurrentId(report._id);
      try {
        if (done.current.includes(report._id)) {
          continue;
        }
        const result = await upload(report);
        if (result) {
          done.current.push(report._id);
        }
      } catch (error) {
        console.log(error);
      }
      setCurrentId(null);
    }
    setUploading(false);
  };

  const uploadOne = async (id: string) => {
    if (done.current.includes(id)) {
      return;
    }
    const report = reports.find(r => r._id == id);

    console.log(report);
    if (!report) {
      return;
    }
    setCurrentId(report._id);
    try {
      const result = await upload(report);
      if (result) {
        done.current.push(id);
      }
      setCurrentId(null);
    } catch (error) {
      console.log(error);
      setCurrentId(null);
    }
  };

  return (
    <div
      className={`flex flex-col h-full overflow-y-auto ${scrollbar} md:px-10 bg-white md:bg-transparent`}
      style={{
        scrollbarGutter: "stable",
      }}
    >
      <Header title="Mongo 보고이주" />
      <div className="bg-white p-4 rounded-md shadow-md mb-4">
        {reports.length == 0 && <p>No file uploaded yet</p>}
        {reports.length > 0 && (
          <MemoizedTable
            columns={columns}
            rows={rows}
            handleRowClick={uploadOne}
            done={done.current}
            currentId={currentId}
          />
        )}
      </div>
      <div className="flex flex-row justify-between items-center gap-2 mb-4">
        <div>
          <div className="flex flex-row gap-4 text-sm">
            <p>
              {done.current.length}/{reports.length}
            </p>
            {reports.length > 0 && (
              <p className="text-quezone">
                {((done.current.length / reports.length) * 100).toFixed(1)}%
              </p>
            )}
            {uploading && <p>{currentId}</p>}
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <Button startIcon={<CloudUpload />} color="success" component="label">
            <VisuallyHiddenInput
              type="file"
              accept="application/json"
              onChange={importSingle}
            />
            파일 선택
          </Button>
          <Button
            startIcon={<DataArrayIcon />}
            color="success"
            component="label"
          >
            <VisuallyHiddenInput
              type="file"
              accept="application/json"
              onChange={importMultiple}
              multiple
            />
            다중 업로드
          </Button>
          {reports.length > 0 && (
            <LoadingButton
              loading={uploading}
              {...blackButtonOutlined}
              onClick={uploadAll}
            >
              CONVERT
            </LoadingButton>
          )}
        </div>
      </div>
    </div>
  );
}

interface props {
  rows: any[];
  columns: any[];
  handleRowClick: (id: string) => void;
  done: string[];
  currentId: string | null;
}

const Table = ({ rows, columns, handleRowClick }: props) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      sx={{ backgroundColor: "white" }}
      onRowClick={e => {
        handleRowClick(e.id as string);
      }}
    />
  );
};

const MemoizedTable = memo(Table, (prev, next) => {
  if (next.done.find(id => !prev.done.includes(id))) {
    return false;
  }
  if (next.currentId !== prev.currentId) {
    return false;
  }
  return true;
});
