import { useState } from "react";
import { typeOrStatus } from "../../../../../../types/common";
import { task } from "../../../../../../types/task";
import KanbanItem from "./kanbanItem";
import { useDndMonitor, useDroppable } from "@dnd-kit/core";

interface group {
  status: typeOrStatus;
  tasks: task[];
  selected: boolean;
}

interface props {
  group: group;
}

export default function StatGroup({ group }: props) {
  const { selected, tasks, status } = group;

  const [dragging, setDragging] = useState(false);

  useDndMonitor({
    onDragStart: () => setDragging(true),
    onDragCancel: () => setDragging(false),
    onDragEnd: () => setDragging(false),
  });

  const canDisplay = (tasks.length > 0 && selected) || dragging;

  const { setNodeRef, isOver } = useDroppable({
    id: group.status.id,
    data: group,
  });

  return (
    <div
      ref={setNodeRef}
      className={`${canDisplay ? "flex flex-col w-[280px]" : "hidden"} 
      ${dragging && "outline-2 outline-dashed outline-gray-300"}
      ${isOver && "outline-quezone"}
      gap-4 p-2`}
    >
      {/* Header */}
      <div className="flex flex-row items-center gap-3">
        <div className="flex flex-row items-center gap-1 h-full">
          <div
            className="px-1 h-3/5 mb-[2px] rounded-md"
            style={{ backgroundColor: status.color }}
          />
          <h3 className="truncate">{status.name}</h3>
        </div>
        <div className="rounded-sm px-2 text-sm py-[1px] bg-white ring-1 ring-gray-300 text-gray-300 mb-[2px]">
          {tasks.length}
        </div>
      </div>
      {/* Tasks */}
      <div className="overflow-y-auto flex-1 flex flex-col gap-4 scrollbar-none pb-4">
        {tasks.map(c => (
          <KanbanItem key={c.id} task={c} />
        ))}
      </div>
    </div>
  );
}
