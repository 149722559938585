import { Controller, useFormContext, useWatch } from "react-hook-form";
import ReportTypeControl from "./reportTypeControl";
import { task } from "../../../../../types/task";
import ReportDateControl from "./reportDateControl";
import ReportImagesControl from "./reportImagesControl";
import TextField from "@mui/material/TextField";
import ReportSNControl from "./reportSNControl/reportSNControl";
import ReportChecksSignsControl from "./reportChecksControl";
import { fieldWrapperEdit } from "../../../../../classPresets";
import Switch from "@mui/material/Switch";

interface props {
  task: task;
}

export default function ReportControls({ task }: props) {
  const { control, register } = useFormContext();

  const typeId = useWatch({
    control,
    name: "typeId",
  });

  const receivedOnSite = useWatch({
    control,
    name: "received_on_site",
  });

  return (
    <div
      className={`grid grid-cols-1 lg:grid-cols-2 gap-x-8 md:gap-4 grid-flow-dense`}
    >
      {/* Left */}
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 md:gap-4">
          {/* Type */}
          <ReportTypeControl task={task} />
          {/* Date */}
          <ReportDateControl />
          {/* Images */}
          {typeId ? <ReportImagesControl typeId={typeId} /> : null}
        </div>
      </div>
      {/* Right */}
      <div className="flex flex-col md:gap-4">
        <div className="flex flex-col gap-1">
          <label className="text-black px-3 md:px-0 pt-3 md:pt-0">
            보고내용
          </label>
          <textarea
            color="success"
            className="md:shadow-md md:border-[1px] border-gray-300 p-3 md:focus-within:border-quezone rounded-md text-sm outline-none font-light bg-gray-100 md:bg-transparent"
            rows={2}
            placeholder="보고 내용을 입력하세요"
            {...register("description")}
          />
        </div>
        <div className={`${fieldWrapperEdit()} !py-2 md:!py-0 gap-1`}>
          <label className="text-black">추가 비용</label>
          <TextField
            color="success"
            className="md:shadow-md max-w-40 md:max-w-full"
            size="small"
            margin="none"
            type="number"
            placeholder="0"
            {...register("charge")}
          />
        </div>
        <div
          className={`${fieldWrapperEdit()} !py-2 md:!py-0 gap-1 border-y-[1px] md:border-none`}
        >
          <div className="flex flex-row gap-2 items-center">
            <Controller
              name="received_on_site"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  color="success"
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <label className="text-black">현장 수령</label>
          </div>
          {receivedOnSite && (
            <TextField
              color="success"
              className="md:shadow-md max-w-40 md:max-w-full"
              size="small"
              margin="none"
              type="number"
              placeholder="수령 금액"
              {...register("received_amount")}
            />
          )}
        </div>
        <ReportSNControl />
        {typeId ? <ReportChecksSignsControl typeId={task.typeId} /> : null}
      </div>
    </div>
  );
}
