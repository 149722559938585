import BlackHeading from "../../../../comps/blackHeading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useCheckAuth } from "../../../../store/authStore";
import { task } from "../../../../types/task";
import Directory from "../../common/files/directory";
import { useState } from "react";

interface props {
  task: task;
}

export default function TaskFiles({ task }: props) {
  const { customerId } = task;
  const installerDir = `customers/${customerId}/installer`;
  const customerDir = `customers/${customerId}/customerPhotos`;

  const checkAuth = useCheckAuth();

  const canEdit = checkAuth({
    permissionName: "고객_파일수정",
  });

  const canAdd = checkAuth({
    permissionName: "고객_파일추가",
  });

  const [showPhotos, setShowPhotos] = useState(false);
  const [showFiles, setShowFiles] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-2">
        <BlackHeading title="고객 사진">
          <div className="flex flex-row justify-between items-center">
            <span>고객 사진</span>
            <div
              className="cursor-pointer hover:text-quezone text-white"
              onClick={() => setShowPhotos(!showPhotos)}
            >
              {showPhotos ? (
                <VisibilityIcon sx={{ width: 18 }} />
              ) : (
                <VisibilityOffIcon sx={{ width: 18 }} />
              )}
            </div>
          </div>
        </BlackHeading>
        <div className={`${!showPhotos && "hidden"} border-[1px] p-2`}>
          <Directory
            name="고객 사진"
            dir={customerDir}
            noHeading
            noAdd={true}
            noEdit={true}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <BlackHeading title="기타 파일">
          <div className="flex flex-row justify-between items-center">
            <span>기타 파일</span>
            <div
              className="cursor-pointer hover:text-quezone text-white"
              onClick={() => setShowFiles(!showFiles)}
            >
              {showFiles ? (
                <VisibilityIcon sx={{ width: 18 }} />
              ) : (
                <VisibilityOffIcon sx={{ width: 18 }} />
              )}
            </div>
          </div>
        </BlackHeading>
        <div className={`${!showFiles && "hidden"} border-[1px] p-2`}>
          <Directory
            name="설치기사용"
            dir={installerDir}
            noHeading
            noAdd={!canAdd}
            noEdit={!canEdit}
          />
        </div>
      </div>
    </>
  );
}
