import { useNavigate } from "react-router-dom";
import { sort } from "fast-sort";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useAuthStore } from "../../../../store/authStore";
import PermissionRow from "./permissionRow";
import AddPermission from "./addPermission";

export default function Permissions() {
  const { permissions } = useAuthStore();
  const sorted = sort(permissions).asc("name");

  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <h1 className="hidden md:block mb-4 ">권한단위 목록</h1>
      <Paper className="overflow-x-auto">
        <Table className="min-w-[524px] border-x-[1px] md:border-none">
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>설명</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map(permission => (
              <PermissionRow key={permission.id} permission={permission} />
            ))}
          </TableBody>
        </Table>
      </Paper>
      <AddPermission />
    </div>
  );
}
