import CustomerStatuses from "./customerStatuses/customerStatuses";
import CustomerSubTypes from "./customerSubTypes/customerSubTypes";
import CustomerTags from "./customerTags/customerTags";
import CustomerTypes from "./customerTypes/customerTypes";

export default function Customers() {
  return (
    <div className="flex flex-col gap-2 py-4 md:p-8 w-full">
      <CustomerStatuses />
      <CustomerTypes />
      <CustomerSubTypes />
      <CustomerTags />
    </div>
  );
}
