import { useLazyQuery } from "@apollo/client";
import { useFormContext, useWatch } from "react-hook-form";
import { GET_CUSTOMER_LAST_NUM } from "../../../../gqls/customer";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

export default function NumberControl() {
  const {
    control,
    setValue,
    formState: { errors, touchedFields },
  } = useFormContext();

  const [getLastNum, { loading: loading_lastNum }] = useLazyQuery(
    GET_CUSTOMER_LAST_NUM,
    {
      fetchPolicy: "network-only",
    }
  );

  const number = useWatch({
    name: "number",
    control,
  });

  const year = new Date().getFullYear() - 2000;

  useEffect(() => {
    getLastNum({
      variables: { year },
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        setValue("number", Number(data.customers[0]?.number || 0) + 1, {
          shouldDirty: true,
        });
      },
    });
  }, [year]);

  const dpNumber = `${year} - #${number || 0}`;

  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>번호</label>
      <TextField
        margin="none"
        size="small"
        color="success"
        placeholder="번호"
        error={touchedFields.number && errors.number?.message ? true : false}
        disabled
        className="shadow-md"
        value={dpNumber}
        onChange={() => {}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading_lastNum && (
                <CircularProgress size={18} sx={{ color: green[500] }} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
