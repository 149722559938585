import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_TASK_BY_PK } from "../../../../../../gqls/task";
import EditTask from "./editTask";

export default function TaskEditWrapper() {
  const { id } = useParams();

  const { data } = useQuery(GET_TASK_BY_PK, {
    variables: {
      id,
    },
    onError(error) {
      console.log(error);
    },
  });

  const task = data?.tasks_by_pk;

  if (!task) {
    return null;
  }

  return <EditTask task={task} />;
}
