import { create } from "zustand";
import { product } from "../types/product";

type Store = {
  products: product[];
  setProducts: (products: product[]) => void;
  updateProducts: (products: product[]) => void;
  addProduct: (product: product) => void;
  deleteProduct: (id: number) => void;
};

export const useProductStore = create<Store>(set => ({
  products: [],
  setProducts: products =>
    set(state => ({
      ...state,
      products,
    })),
  updateProducts(products) {
    set(state => ({
      ...state,
      products: state.products.map(
        product => products.find(s => s.id == product.id) || product
      ),
    }));
  },
  addProduct(product) {
    set(state => ({
      ...state,
      products: state.products.concat(product),
    }));
  },
  deleteProduct(id) {
    set(state => ({
      ...state,
      products: state.products.filter(s => s.id != id),
    }));
  },
}));
