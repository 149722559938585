import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { GET_INVENTORY_BY_ID } from "../../../../gqls/inv";
import { useCheckAuth } from "../../../../store/authStore";
import { useEffect } from "react";
import { useProductStore } from "../../../../store/productStore";
import ProductRow from "../../settings/inventories/products/productRow";
import InvQty from "../companyList/companyInventory/invQty";
import ReplyIcon from "@mui/icons-material/Reply";
import BlackHeading from "../../../../comps/blackHeading";
import InventoryHistory from "./inventoryHistory";
import InventoryAction from "./inventoryAction";
import Loading from "../../../../comps/loading";

interface props {
  nested?: boolean;
  id?: number;
}

export default function InventoryDetail({ nested, id }: props) {
  const { id: paramId } = useParams();

  const { data, loading } = useQuery(GET_INVENTORY_BY_ID, {
    variables: {
      id: id || paramId,
    },
  });

  const inventory = data?.inventory_by_pk;

  const checkAuth = useCheckAuth();

  const navigate = useNavigate();

  const { products } = useProductStore();

  const product = products.find(p => p.id == inventory?.productId);

  useEffect(() => {
    if (!inventory) {
      return;
    }

    !checkAuth({
      permissionName: "재고열람",
      companyIds: [inventory?.companyId || 0],
      userId: inventory?.userId,
    }) && navigate("/inventory");
  }, [inventory]);

  if (loading) {
    return <Loading />;
  }

  if (!loading && (!inventory || !product)) {
    return "존재하지 않는 재고입니다";
  }

  return (
    <div className="flex-1 pb-5 flex flex-col gap-4">
      {inventory && product && (
        <div
          className={`flex-1 py-5 
          ${nested && "py-0 md:py-5 mx-3 md:mx-0"}
          ${
            !nested && "md:p-8 md:drop-shadow-md"
          } flex flex-col gap-4 md:bg-white overflow-x-clip`}
        >
          {/* Location, Qty */}
          <div className="flex flex-row justify-between items-center gap-4 flex-wrap">
            <h1>
              {inventory.company?.name ||
                inventory.user?.name ||
                inventory?.customer?.name}
            </h1>
            <div className="flex flex-row flex-wrap gap-2">
              <InvQty inv={inventory} includeAll />
              <div
                className="hidden md:block cursor-pointer text-gray-500 hover:text-quezone"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ReplyIcon />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 grid-flow-dense">
            {/* Product */}
            <div className="col-span-1 flex flex-col gap-4">
              <ProductRow product={product} newTab={true} noBorder={true} />
              <InventoryAction inventory={inventory} />
            </div>

            <div className="col-span-1 flex flex-col gap-2">
              <BlackHeading title="재고 히스토리" fullWidth />
              <div className="border-[1px] p-2">
                <InventoryHistory inventory={inventory} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
