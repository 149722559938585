import { useNavigate } from "react-router-dom";
import { customer } from "../../../../../../types/customer";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import MyBadge from "../../../../../../comps/myBadge";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { useAuthStore } from "../../../../../../store/authStore";
import Visibility from "@mui/icons-material/Visibility";

interface props {
  customers: customer[];
}

export default function Table({ customers }: props) {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  const columns = [
    { field: "id", width: 50, headerName: "id", hide: true },
    { field: "number", width: 90, headerName: "번호" },
    { field: "company", width: 100, headerName: "회사" },
    {
      field: "type",
      width: 90,
      headerName: "구분",
      renderCell: (params: any) => (
        <MyBadge color={params.value.color} text={params.value.name} />
      ),
    },
    {
      field: "subType",
      width: 90,
      headerName: "서브타입",
      renderCell: (params: any) => (
        <>
          {params.value && (
            <MyBadge color={params.value.color} text={params.value.name} fill />
          )}
        </>
      ),
    },
    { field: "name", flex: 1, headerName: "이름" },
    { field: "address", flex: 2, headerName: "주소" },
    { field: "source", flex: 1, headerName: "접수처" },
    { field: "registered_at", width: 100, headerName: "접수일" },
    { field: "installDate", width: 100, headerName: "설치일" },
    {
      field: "status",

      width: 70,
      headerName: "상태",
      renderCell: (params: any) => (
        <MyBadge color={params.value.color} text={params.value.name} fill />
      ),
    },
    {
      field: "isUnderWarranty",
      width: 50,
      headerName: "",
      renderCell: (params: any) => (
        <>{!params.value && <MyBadge text="₩" color="red" />}</>
      ),
    },
    {
      field: "update",
      width: 50,
      headerName: "",
      renderCell: (params: any) => {
        const reads = params?.value?.reads || [];

        const updated =
          !reads.includes(user?.id) &&
          dayjs(params?.value?.created_at).isAfter(dayjs(user?.created_at));

        const watching = params?.value?.watching?.includes(user?.id);

        return (
          <>
            {updated && (
              <TipsAndUpdatesIcon
                sx={{ width: 20 }}
                className="text-yellow-500 animate-pulse"
              />
            )}
            {watching && (
              <Visibility
                sx={{ width: 20, height: 20 }}
                className="text-quezone"
              />
            )}
          </>
        );
      },
    },
  ];

  const rows = customers.map(c => ({
    id: c.id,
    number: `#${c.year}-${c.number}`,
    company: c.company?.name,
    type: c.type,
    subType: c.subType,
    name: c.name,
    address: c.address,
    source: c.source
      ? c.source.name + `(${c.sourceCompany?.name || c.sourceDetail?.name})`
      : "미상",
    registered_at: dayjs(c.registered_at).format("YY. MM. DD"),
    installDate: c.installDate
      ? dayjs(c.installDate).format("YY. MM. DD")
      : "미정",
    isUnderWarranty: c.warrantyDate
      ? dayjs(c.warrantyDate).isAfter(dayjs())
      : dayjs(c.registered_at).diff(dayjs(), "days") < 365,
    status: c.status,
    update: {
      reads: c.reads,
      watching: c.watching,
    },
  }));

  return (
    <div style={{ flexGrow: 1, minWidth: "100%" }} className="md:px-10">
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{ backgroundColor: "white" }}
        onRowClick={e => {
          navigate("/customers/detail/" + e.row.id);
        }}
      />
    </div>
  );
}
