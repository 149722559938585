import { task } from "../../../../../../types/task";
import { useEffect, useState } from "react";
import { GET_REPORTS_BY_TASK_IDS } from "../../../../../../gqls/report";
import { useLazyQuery } from "@apollo/client";
import { extendedReport } from "../../../../../../types/report";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Reports from "./reports";
import Settlements from "./settlements";

interface props {
  tasks: task[];
}

export default function Statistics({ tasks }: props) {
  const [getReportsByTaskIds, { loading, called }] = useLazyQuery(
    GET_REPORTS_BY_TASK_IDS
  );

  const [reports, setReports] = useState<extendedReport[]>([]);

  const [seeReports, setSeeReports] = useState(false);

  const getReports = (silent?: boolean) => {
    const proceed = silent
      ? true
      : confirm(
          "정산을 위해 불러온 모든 작업에 대해 보고서를 불러옵니다, 양이 많을 경우 인터넷 사용이 급증할 수 있으니 와이파이가 연결된 환경에서 진행해주세요"
        );
    if (!proceed) {
      return;
    }

    getReportsByTaskIds({
      variables: {
        taskIds: tasks.map(task => task.id),
      },
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        setReports(data.reports);
      },
    });
  };

  useEffect(() => {
    if (called) {
      getReports(true);
    }
  }, [tasks]);

  if (reports.length == 0) {
    return (
      <div className="flex flex-col justify-center items-center w-full md:mx-10">
        <img src="/settlement.png" alt="settlement img" />
        {loading && <LinearProgress color="success" className="w-full mb-4" />}
        {!loading && (
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              getReports();
            }}
            sx={{ fontWeight: 400 }}
          >
            정산 데이터 불러오기
          </Button>
        )}
        {called && <div className="mt-4">데이터가 없습니다</div>}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 w-full md:mx-10">
      <Settlements reports={reports} tasks={tasks} />
      <div className="flex flex-row justify-start">
        <Button color="success" onClick={() => setSeeReports(!seeReports)}>
          포함된 보고서 {seeReports ? "숨기기" : "보기"}
        </Button>
      </div>
      {seeReports && <Reports reports={reports} />}
    </div>
  );
}
