import Avatar from "@mui/material/Avatar";
import { extendedCustomer } from "../../../../../types/customer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { green } from "@mui/material/colors";
import dayjs from "dayjs";
import CustomerStatControl from "./customerStatControl";
import CustomerTypeControl from "./customerTypeControl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAuthStore } from "../../../../../store/authStore";
import { useMutation } from "@apollo/client";
import {
  SET_WATCH_CUSTOMER_BY_ID,
  WATCH_CUSTOMER_BY_ID,
} from "../../../../../gqls/customer";
import Tooltip from "@mui/material/Tooltip";
import CustomerDetailHeaderContacts from "./customerDetailHeaderContacts";
import CustomerSubTypeControl from "./customerSubTypeControl";

interface props {
  customer: extendedCustomer;
}

export default function CustomerDetailHeader({ customer }: props) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const { user } = useAuthStore();

  const { name, year, number, installDate, id } = customer;

  const watching = customer.watching?.includes(user?.id || 0);

  const [watch] = useMutation(WATCH_CUSTOMER_BY_ID);
  const [setWatch] = useMutation(SET_WATCH_CUSTOMER_BY_ID);

  const toggleWatch = () => {
    if (watching) {
      setWatch({
        variables: {
          id: customer.id,
          userIds: customer.watching?.filter(u => u !== user?.id),
        },
      });
    } else {
      watch({
        variables: {
          id: customer.id,
          userId: user?.id,
        },
      });
    }
  };

  return (
    <div className="flex flex-col px-3 md:px-0 bg-gray-100 md:bg-transparent">
      <div
        className="hidden md:block pt-2 -ml-6 cursor-pointer"
        onClick={goBack}
      >
        <ArrowBackIcon />
      </div>
      <div className="flex flex-row gap-3 items-center mt-4 md:mt-2">
        <div className="hidden md:block">
          <Avatar sx={{ width: 65, height: 65, backgroundColor: green[200] }} />
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div className="flex flex-row items-center gap-2 justify-between">
            <div className="flex flex-row items-center gap-2">
              <CustomerTypeControl customer={customer} />
              <CustomerStatControl customer={customer} />
              <CustomerSubTypeControl customer={customer} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Tooltip title="변경사항 알림">
                <VisibilityIcon
                  onClick={toggleWatch}
                  className={`opacity-10 ${
                    watching && "text-quezone opacity-100"
                  } cursor-pointer`}
                  sx={{ width: 20, height: 20 }}
                />
              </Tooltip>
              <div className="text-sm">
                {user?.email == "ksi9302@gmail.com" ? id : `#${year}-${number}`}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="flex flex-col flex-1">
              <div className="flex flex-row gap-2 items-center">
                <h3 className="truncate flex-1 w-0">{name}</h3>
              </div>
              <div className="text-xs ">
                설치일{" "}
                {installDate ? dayjs(installDate).format("YYYY.MM.DD") : "미상"}
              </div>
            </div>
            <CustomerDetailHeaderContacts customer={customer} />
          </div>
        </div>
      </div>
      <hr className="mt-2 md:mt-3  border-gray-200 md:border-black md:border-[1px]" />
    </div>
  );
}
