import { task } from "../../../../types/task";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import dayjs from "dayjs";
import { useState } from "react";
import { useAuthStore, useCheckAuth } from "../../../../store/authStore";
import {
  blackButtonContained,
  blackButtonOutlined,
  fieldWrapper,
  modal,
} from "../../../../classPresets";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UPDATE_TASK_BY_ID } from "../../../../gqls/task";
import { useMutation } from "@apollo/client";
import TaskDateControl from "../../customers/customerDetail/customerProgress/customerTasks/taskControls/taskDateControl";
import TaskTimeControl from "../../customers/customerDetail/customerProgress/customerTasks/taskControls/taskTimeControl";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

const schema = z.object({
  date: z.coerce.date(),
  time: z.coerce.date().nullable().optional(),
});

export default function TaskDateTimeControl({ task }: { task: task }) {
  const { date, time, customer } = task;

  const checkAuth = useCheckAuth();

  const companyIds = [
    customer.companyId || 0,
    customer.sourceCompanyId || 0,
    task.companyId,
  ];

  const canEdit = checkAuth({
    permissionName: "작업_날짜변경",
    companyIds,
    userId: task.assignedToId,
  });

  const [editing, setEditing] = useState(false);

  const handleOpen = () => {
    if (canEdit) {
      setEditing(true);
    }
  };

  const handleClose = () => {
    setEditing(false);
  };

  const defaultValues = {
    date: dayjs(date),
    time: time ? dayjs(time, "HH:mm:ssZ") : null,
  };
  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const [editTask, { loading }] = useMutation(UPDATE_TASK_BY_ID);

  const { user } = useAuthStore();

  const onSubmit = handleSubmit(async data => {
    const { date, time } = data;

    const ISOTime = dayjs(time).format("HH:mm:ssZ");

    await editTask({
      variables: {
        id: task.id,
        set: {
          reads: [user?.id],
          date: date.toISOString(),
          time: time ? ISOTime : null,
        },
      },
    });
    reset({
      date: dayjs(date),
      time: time ? dayjs(ISOTime, "HH:mm:ssZ") : null,
    });
    handleClose();
  });

  return (
    <>
      <div
        className={`${fieldWrapper} flex flex-row justify-between items-center gap-2 cursor-pointer hover:text-quezone relative`}
        onClick={handleOpen}
      >
        <label className="text-black">작업 일시</label>
        <div className="flex flex-col gap-1 items-end">
          <div>
            <CalendarMonthOutlinedIcon className="text-quezone" />{" "}
            {dayjs(date).format("YYYY. MM. DD")}
          </div>

          <div>
            <AccessTimeFilledIcon className="text-quezone" />{" "}
            {time ? dayjs(time, "HH:mm:ssZ").format("HH:mm") : "시간미정"}
          </div>
        </div>
      </div>
      <Modal open={editing} onClose={handleClose}>
        <form
          className={`${modal} outline-none flex flex-col gap-2 p-6 min-w-[300px]`}
          onSubmit={onSubmit}
        >
          <h3>작업 일시 수정</h3>
          <FormProvider {...methods}>
            <TaskDateControl />
            <TaskTimeControl />
          </FormProvider>
          <div className="flex flex-row justify-end gap-2 mt-4">
            <Button {...blackButtonOutlined} onClick={handleClose}>
              취소
            </Button>
            <LoadingButton
              disabled={!isDirty}
              {...blackButtonContained}
              loading={loading}
              type="submit"
            >
              수정
            </LoadingButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
