import { gql } from "@apollo/client";

export const COMPANY_TYPE_FIELDS = gql`
  fragment CompanyTypeFields on companyTypes {
    id
    name
    description
    icon
    canHaveCustomers
    isChain
    isInstallAgency
  }
`;

export const COMPANY_FIELDS = gql`
  ${COMPANY_TYPE_FIELDS}
  fragment CompanyFields on companies {
    id
    isQuezone
    name
    description
    email
    phone
    address
    registration
    subCompanies
    typeId
    type {
      ...CompanyTypeFields
    }
    sources {
      id
      name
      isSourceCompany
    }
    users {
      id
      name
    }
    noUsers
    created_at
    updated_at
    noUsers
    customerTypeIds
    customerStatusIds
    invLocations
    active
  }
`;
