interface oid {
  $oid: string;
}

interface MDate {
  $date: string;
}

interface MContact {
  name: string;
  number: string;
}

interface MTag {
  _id: oid;
  name: string;
  id: number;
}

interface MCorrespondence {
  email: string;
  date: MDate;
  description: string;
}

interface MHistory {
  type: "status";
  message: string;
  statusId: number;
  email: string;
  date: MDate;
}

interface MProduct {
  added: MDate;
  productId: number;
  qty: number;
  used: boolean;
}

interface MReceived {
  sum: number;
  date: MDate;
  type: string;
  description: string;
}

export interface mongoCustomer {
  _id: oid;
  source: string;
  sourceDetail: string;
  year: number;
  number: number;
  description?: string;
  created: MDate;
  createdBy: string;
  name: string;
  address: string;
  contacts: MContact[];
  tags: MTag[];
  correspondence: MCorrespondence[];
  // first History is registration
  history: MHistory[];
  value: {
    product: {
      supply: number;
      gst: number;
    };
    install: {
      supply: number;
      gst: number;
    };
    received: MReceived[];
  };
  updated: boolean;
  read: string[];
  from?: MDate;
  due?: MDate;
  warranty?: MDate;
  asDate?: MDate;
  statusId: number;
  typeId: number;
  companyId: number;
  products?: MProduct[];
}

export interface mongoTask {
  _id: string;
  description: string;
  by?: string;
  createdBy?: string;
  otherReason?: string;
  statusId: number;
  typeId: number;
  companyId: number;
  customer: string;
  date: string;
  proposed?: string;
  tags: number[];
  value?: number;
}

export interface mongoReport {
  _id: string;
  customerId: string;
  taskId: string;
  taskTypeId: number;
  type: string;
  subCategory?: string;
  reporter?: string;
  date: string;
  reportDate: string;
  serials: string[];
  sign: {
    installer?: string;
    customer?: string;
  };
  desc: string;
  extraCost?: number;
  photos?: {
    data: string;
    name: string;
  }[];
  checks: number[];
}

export function isMongoTask(obj: any): obj is mongoTask {
  return (
    obj &&
    obj._id &&
    typeof obj.description === "string" &&
    typeof obj.statusId === "number" &&
    typeof obj.typeId === "number" &&
    typeof obj.companyId === "number" &&
    typeof obj.customer === "string" &&
    typeof obj.date === "string"
  );
}

export function isMongoCustomer(obj: any): obj is mongoCustomer {
  return (
    obj &&
    obj._id &&
    typeof obj.source === "string" &&
    typeof obj.sourceDetail === "string" &&
    typeof obj.year === "number" &&
    typeof obj.number === "number" &&
    obj.created &&
    typeof obj.createdBy === "string" &&
    typeof obj.name === "string" &&
    typeof obj.address === "string" &&
    Array.isArray(obj.contacts) &&
    Array.isArray(obj.tags) &&
    Array.isArray(obj.correspondence) &&
    Array.isArray(obj.history) &&
    obj.value &&
    typeof obj.value.product.supply === "number" &&
    typeof obj.value.product.gst === "number" &&
    typeof obj.value.install.supply === "number" &&
    typeof obj.value.install.gst === "number" &&
    typeof obj.updated === "boolean" &&
    Array.isArray(obj.read) &&
    typeof obj.statusId === "number" &&
    typeof obj.typeId === "number" &&
    typeof obj.companyId === "number" &&
    (obj.products === undefined || Array.isArray(obj.products))
  );
}
