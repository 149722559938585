import dayjs from "dayjs";
import { filter, filterValues, range } from "../types/common";
import { put } from "./objectArrayMethods";

interface props {
  defaultFilters: filter[];
  savedFilters: filter[] | undefined;
}

export function combineFilters({ defaultFilters, savedFilters }: props) {
  return defaultFilters?.map(filter => {
    const savedFilter = savedFilters?.find(sf => sf.name == filter.name);
    if (!savedFilter) {
      return filter;
    }

    const combined: filter = {
      ...filter,
      on: savedFilter.on,
      sort: savedFilter.sort,
      includeNull: savedFilter.includeNull,
    };

    if (filter.type == "contains" || filter.type == "in") {
      const values = (savedFilter.values as string[] | number[]).filter(v =>
        filter.options?.find(o => o.value == v)
      );
      combined.values = values as filterValues;
    }
    if (filter.type == "range") {
      combined.values = {
        // @ts-expect-error
        from: savedFilter.values?.from,
        // @ts-expect-error
        to: savedFilter.values?.to,
      };
    }
    if (filter.type == "count") {
      combined.values = savedFilter.values as filterValues;
    }
    return combined;
  });
}

export const refineFilters = (filters: filter[]) => {
  if (!filters) {
    return {
      where: { _and: [] },
      order_by: {},
    };
  }

  const where: any = { _and: {} };
  const order_by: object[] = [];
  for (const filter of filters.sort((a, b) =>
    (a.sort?.priority || 0) > (b.sort?.priority || 0) ? 1 : -1
  )) {
    // Filter
    if (
      (filter.on || filter.required) &&
      !filter.sortOnly &&
      !filter.middleMan
    ) {
      switch (filter.type) {
        case "in":
          const filterValues = filter.values as Array<number | string | null>;

          const inFilterValue = { _in: filterValues };

          if (filter.includeNull) {
            put(where._and, "_or", [
              { [filter.where]: inFilterValue },
              { [filter.where]: { _is_null: true } },
            ]);
          } else {
            put(where._and, filter.where, inFilterValue);
          }

          break;
        case "count":
          const value = filter.values as number;

          if (value == 0) {
            put(where._and, filter.where, { _eq: value });
          } else {
            put(where._and, filter.where, { _gt: value - 1 });
          }

          break;
        case "contains":
          put(where._and, "_or", [
            { [filter.where]: { _contains: filter.values } },
          ]);

          break;
        case "range":
          const { from, to } = filter.values as range;

          const fromDate = from ? dayjs(from) : dayjs();
          const toDate = to ? dayjs(to) : dayjs();

          const _gte =
            filter.rangeType == "date"
              ? fromDate.startOf("day").toISOString()
              : Number(from);

          const _lte =
            filter.rangeType == "date"
              ? toDate.endOf("day").toISOString()
              : Number(to);

          put(where._and, filter.where, {
            _gte,
            _lte,
          });
          break;
      }
    }

    // Sort
    if (filter.sort && filter.sort.dir !== "off") {
      const sortObj = {};
      put(sortObj, filter.sort.where, filter.sort.dir);
      order_by.push(sortObj);
    }
  }

  if (!where._and.deleted) {
    put(where._and, "deleted", { _eq: false });
  }

  return {
    where: where,
    order_by: order_by,
  };
};
