import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ADD_CUSTOMER_M,
  ADD_CUSTOMER_PAYMENT_MANY_M,
  ADD_CUSTOMER_PRICE_M,
  GET_CUSTOMER_RELATED_CONFIGS,
} from "../../../../gqls/customer";
import { customer } from "../../../../types/customer";
import { mongoCustomer } from "../types";
import { GET_CONTACTS_CONDITIONAL } from "../../../../gqls/contact";
import { sort } from "fast-sort";
import { invQty, inventory } from "../../../../types/inv";
import { ADD_INVENTORY_MANY, ADD_INV_QTY_MANY_M } from "../../../../gqls/inv";
import { useInvStore } from "../../../../store/invStore";
import useAddCustomerHistory, {
  historyToUploadProps,
} from "../../../../hooks/useAddCustomerHistory";
import { ADD_CORRESPONDENCE_MANY_M } from "../../../../gqls/correspondence";
import { correspondence } from "../../../../types/correspondence";
import { useState } from "react";

export default function useUploadCustomer() {
  const { data, loading } = useQuery(GET_CUSTOMER_RELATED_CONFIGS, {
    onError(error) {
      console.log(error);
    },
  });

  const users = data?.users;
  const sources = data?.sources;
  const sourceDetails = data?.sourceDetails;

  const [currentJobId, setCurrentJobId] = useState<null | string>(null);

  const [addCustomer] = useMutation(ADD_CUSTOMER_M);
  const [getContacts] = useLazyQuery(GET_CONTACTS_CONDITIONAL);
  const [addInventories] = useMutation(ADD_INVENTORY_MANY);
  const [addInvQties] = useMutation(ADD_INV_QTY_MANY_M);
  const [addCorrespondences] = useMutation(ADD_CORRESPONDENCE_MANY_M);
  const [addPrice] = useMutation(ADD_CUSTOMER_PRICE_M);
  const [addPayments] = useMutation(ADD_CUSTOMER_PAYMENT_MANY_M);
  const { addHistoryBatch } = useAddCustomerHistory();
  const { invStatuses } = useInvStore();

  const upload = async (customer: mongoCustomer) => {
    if (loading) {
      return;
    }

    setCurrentJobId(customer._id.$oid);

    try {
      const {
        address,
        companyId,
        contacts,
        created,
        createdBy,
        name,
        number,
        read,
        source,
        sourceDetail,
        statusId,
        tags,
        typeId,
        year,
        asDate,
        description,
        due,
        from,
        warranty,
        products,
        value,
        correspondence,
        history,
        _id,
      } = customer;

      // handle contacts
      const { data } = await getContacts({
        variables: {
          where: {
            name: {
              _in: contacts?.map(c => c.name),
            },
          },
        },
      });

      const loadedContacts = data?.contacts || [];

      const contactIds = contacts
        .map(c => {
          const loadedContact = loadedContacts.find(
            lc => lc.name == c.name && lc.number == c.number
          );

          return loadedContact?.id || 0;
        })
        .filter(id => id !== 0);

      const lastHistory = sort(history).desc("date")[0];

      const matchingSource = sources?.find(
        s => s.companyId == companyId && s.name == source
      );
      const matchingSourceDetail = sourceDetails?.find(
        s =>
          s.sourceId == matchingSource?.id &&
          (s.name == sourceDetail || s.company?.name == sourceDetail)
      );

      const customerToUpload: Partial<customer> = {
        year,
        number,
        name,
        uuid: _id.$oid,
        address,
        description,
        companyId,
        sourceId: matchingSource?.id,
        sourceDetailId: matchingSourceDetail?.id,
        sourceCompanyId: matchingSource?.isSourceCompany
          ? matchingSourceDetail?.companyId
          : null,
        created_at: created.$date,
        updated_at: lastHistory?.date?.$date || created.$date,
        installDate: due ? due.$date : null,
        registered_at: from ? from.$date : created.$date,
        asDate: asDate ? asDate.$date : null,
        warrantyDate: warranty ? warranty.$date : null,
        statusId,
        typeId,
        createdById: users?.find(u => u.email == createdBy)?.id,
        reads: read
          .map(r => users?.find(u => u.email == r)?.id)
          .filter(r => r !== undefined) as number[],
        tagIds: tags.map(t => t.id),
        contactIds,
      };

      // upload customer
      const { data: insertedCustomerData } = await addCustomer({
        variables: {
          object: customerToUpload,
        },
        onError(error) {
          console.log(error);
        },
      });

      // customerId Dummy
      const customerId = insertedCustomerData.insert_customers_one.id;

      if (!customerId) {
        setCurrentJobId(null);
        return;
      }

      // upload inventory
      const inventories: Omit<inventory, "id" | "created_at" | "updated_at">[] =
        products?.map(p => ({
          productId: p.productId,
          customerId,
        })) || [];

      const { data: invData } = await addInventories({
        variables: {
          objects: inventories.filter(i => i.productId),
        },
        onError(error) {
          console.log(error);
        },
      });

      let insertedInventories = (invData.insert_inventory?.returning ||
        []) as inventory[];
      if (invData.insert_inventory_one) {
        insertedInventories.concat(invData.insert_inventory_one);
      }

      const invQties: Omit<invQty, "id" | "created_at" | "updated_at">[] =
        insertedInventories?.map(inv => {
          const product = products?.find(p => p.productId == inv.productId);
          const qty = {
            invId: inv.id,
            qty: product?.qty || 1,
            statusId:
              (product?.used
                ? invStatuses?.find(s => s.name == "설치완료")?.id
                : invStatuses?.find(s => s.name == "설치대기")?.id) || 7,
          };
          return qty;
        }) || [];

      // upload invQty
      addInvQties({
        variables: {
          objects: invQties,
        },
        onError(error) {
          console.log(error);
        },
      });

      const sortedHistory = sort(history).asc("date");

      const historiesToUpload: historyToUploadProps[] = sortedHistory.map(
        (history, index) => ({
          customerId,
          type: index == 0 ? "registration" : "status",
          message: index == 0 ? "등록" : "상태 변경",
          statusId: history.statusId,
          created_at: history.date.$date,
          oldData:
            index == 0
              ? undefined
              : {
                  statusId: sortedHistory[index - 1].statusId,
                },
          newData: {
            statusId: history.statusId,
          },
        })
      );

      addHistoryBatch(historiesToUpload);

      // upload correspondence
      const correspondences: Omit<
        correspondence,
        "id" | "created_at" | "updated_at" | "user"
      >[] = correspondence.map(c => ({
        byId: users?.find(u => u.email == c.email)?.id || 0,
        content: c.description,
        date: c.date.$date,
        customerId,
      }));

      addCorrespondences({
        variables: {
          objects: correspondences,
        },

        onError(error) {
          console.log(error);
        },
      });

      const { install, product, received } = value;

      // upload customer Price
      addPrice({
        variables: {
          object: {
            customerId,
            installGst: install.gst,
            installSupply: install.supply,
            productGst: product.gst,
            productSupply: product.supply,
          },
        },
      });

      const paymentsToUpoad = received.map(r => ({
        amount: r.sum,
        methodId: r.type == "카드" ? 1 : 2,
        received_at: r.date.$date,
        comment: r.description,
        customerId,
      }));
      // upload customer payments
      addPayments({
        variables: {
          objects: paymentsToUpoad,
        },
        onError(error) {
          console.log(error);
        },
      });

      setCurrentJobId(null);
      return customerToUpload;
    } catch (error) {
      console.log(customer, error);
      setCurrentJobId(null);
      return false;
    }
  };

  return { upload, currentJobId };
}
