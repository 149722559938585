import { useLazyQuery, useMutation } from "@apollo/client";
import { useInvStore } from "../store/invStore";
import {
  invAction,
  invHistory,
  invQty,
  inventory,
  inventory_with_product,
  statPopulatedInvQty,
} from "../types/inv";
import {
  ADD_INVENTORY,
  ADD_INV_QTY,
  DELETE_INV_QTY,
  GET_INVENTORY_CONDITIONAL_WITH_QTY,
  GET_INV_QTY_BY_ID,
  GET_INV_QTY_CONDITIONAL,
  UDPATE_INV_QTY_BY_ID,
} from "../gqls/inv";
import { INVENTORY_FIELDS, INV_QTY_FIELDS } from "../fragments/inv";
import useAddInvHistory from "./useAddInvHistory";
import { useCallback } from "react";
import hasuraFilter from "../utils/hasuraFilter";
import { cacheEvict } from "../utils/cacheMethods";

interface props {
  inventory?: inventory | inventory_with_product | null;
  invQty?: invQty | statPopulatedInvQty | null;
}

interface functionProps {
  qty: number;
  description?: string | null;
  invAction?: invAction;
  location?: {
    type: "company" | "user" | "customer";
    id: number;
  };
  customerId?: number;
  inventoryOverride?: inventory | inventory_with_product | null;
}

export default function useInvAction({ inventory, invQty }: props) {
  const { invStatuses } = useInvStore();
  const fromInvStat = invStatuses.find(is => is.id == invQty?.statusId);

  const [getInventory] = useLazyQuery(GET_INVENTORY_CONDITIONAL_WITH_QTY);
  const [getInvQty] = useLazyQuery(GET_INV_QTY_CONDITIONAL, {
    fetchPolicy: "network-only",
  });
  const [getInvQtyById] = useLazyQuery(GET_INV_QTY_BY_ID);
  const [updateInvQty] = useMutation(UDPATE_INV_QTY_BY_ID);
  const [addInvQty] = useMutation(ADD_INV_QTY);
  const [addInventory] = useMutation(ADD_INVENTORY);
  const [deleteInvQty] = useMutation(DELETE_INV_QTY);

  const removeInvQty = (id: number) => {
    deleteInvQty({
      variables: {
        id,
      },
      update: cache => {
        cacheEvict({
          cache,
          id,
          __typename: "invQty",
        });
      },
    });
  };

  const addInvHistory = useAddInvHistory();

  const updateFunction = async ({
    qty,
    description,
    invAction,
    location,
    customerId,
    inventoryOverride,
  }: functionProps) => {
    const toInvStat = invStatuses.find(is => is.id == invAction?.statusId);

    const currentInvQtyData = await getInvQtyById({
      variables: {
        id: invQty?.id,
      },
      fetchPolicy: "network-only",
    });

    const currentInvQty = currentInvQtyData.data?.invQty_by_pk;

    if (!invAction || !currentInvQty || !inventory) {
      console.log("currentInvQty unknwon");
      return null;
    }

    // UPDATE ACTION
    if (invAction.type == "update") {
      if (!fromInvStat || !toInvStat) {
        return null;
      }

      const qtyToApply_from = currentInvQty.qty - qty;

      // update fromInvQty
      await updateInvQty({
        variables: {
          id: currentInvQty.id,
          set: {
            qty: qtyToApply_from,
          },
        },
      });

      if (qtyToApply_from <= 0) {
        removeInvQty(currentInvQty.id);
      }

      let where: any = {
        statusId: { _eq: invAction.statusId },
        invId: { _eq: currentInvQty.invId },
      };

      if (invAction.linger) {
        where.subInvId = { _eq: currentInvQty.subInvId };
      } else {
        where.subInvId = { _is_null: true };
      }

      // get toInvQty
      const toInvQtyData = await getInvQty({
        variables: {
          where,
        },
        fetchPolicy: "network-only",
      });
      let toInvQty = toInvQtyData.data?.invQty[0];

      const changes: invHistory["changes"] = [
        {
          diff: -qty,
          statusId: currentInvQty.statusId,
          invId: currentInvQty.invId,
        },
        { diff: qty, statusId: invAction.statusId, invId: currentInvQty.invId },
      ];

      // update toInvQty || add toInvQty
      await (async () => {
        if (toInvQty) {
          await updateInvQty({
            variables: {
              id: toInvQty.id,
              set: {
                qty: toInvQty.qty + qty,
              },
            },
          });

          return;
        } else {
          let object: any = {
            invId: currentInvQty.invId,
            statusId: invAction.statusId,
            qty,
          };

          if (invAction.linger) {
            object = {
              ...object,
              subInvId: currentInvQty.subInvId,
            };
          }
          const data = await addInvQty({
            variables: {
              object,
            },
            update: (cache, { data }) => {
              cache.modify({
                fields: {
                  invQty(existing = [], { storeFieldName }) {
                    const filtered = hasuraFilter({
                      items: [data?.insert_invQty_one],
                      name: "invQty",
                      string: storeFieldName,
                    });

                    const newInvQtyRefs = filtered.map(sd =>
                      cache.writeFragment({
                        data: sd,
                        fragment: INV_QTY_FIELDS,
                        fragmentName: "InvQtyFields",
                      })
                    );
                    return [...existing, ...newInvQtyRefs];
                  },
                },
              });
            },
          });

          toInvQty = data.data?.insert_invQty_one;

          return;
        }
      })();

      // add History

      const id = await addInvHistory({
        changes,
        invActionId: invAction.id,
        fromId: currentInvQty.invId,
        toId: toInvQty?.invId,
        description: description ? description : undefined,
      });

      return id;
    }
    // DELETE ACTION
    if (invAction.type == "delete") {
      await updateInvQty({
        variables: {
          id: currentInvQty.id,
          set: {
            qty: currentInvQty.qty - qty,
          },
        },
      });

      removeInvQty(currentInvQty.id);

      const id = await addInvHistory({
        changes: [
          {
            diff: -qty,
            statusId: currentInvQty.statusId,
            invId: inventory.id,
          },
        ],
        invActionId: invAction.id,
        fromId: inventory.id,
        toId: inventory.id,
        description: description ? description : undefined,
      });

      return id;
    }
    // MOVE ACTION
    if (invAction.type == "move") {
      if (!location) {
        return null;
      }

      if (!fromInvStat || !toInvStat) {
        return null;
      }
      const qtyToApply_from = currentInvQty.qty - qty;
      // update fromInvQty
      await updateInvQty({
        variables: {
          id: currentInvQty.id,
          set: {
            qty: qtyToApply_from,
          },
        },
      });

      if (qtyToApply_from <= 0) {
        removeInvQty(currentInvQty.id);
      }

      // get toInventory
      const toInventoryData = await getInventory({
        variables: {
          where: {
            productId: { _eq: inventory.productId },
            [`${location.type}Id`]: { _eq: location.id },
          },
        },
        fetchPolicy: "network-only",
      });

      let toInventory = toInventoryData.data?.inventory[0];

      // add Inventory if not exist
      await (async () => {
        if (!toInventory) {
          const addedInventoryData = await addInventory({
            variables: {
              object: {
                productId: inventory.productId,
                [`${location.type}Id`]: location.id,
              },
            },
            update: (cache, { data }) => {
              cache.modify({
                fields: {
                  inventory(existing = [], { storeFieldName }) {
                    const filtered = hasuraFilter({
                      items: [data.insert_inventory_one],
                      name: "inventory",
                      string: storeFieldName,
                    });

                    const newInvRefs = filtered.map(sd =>
                      cache.writeFragment({
                        data: sd,
                        fragment: INVENTORY_FIELDS,
                        fragmentName: "InventoryFields",
                      })
                    );
                    return [...existing, ...newInvRefs];
                  },
                },
              });
            },
          });

          toInventory = addedInventoryData.data.insert_inventory_one;
          return;
        }

        return;
      })();

      if (!toInventory) {
        return null;
      }

      // get toInvQty

      let toInvQty =
        toInventory.qty &&
        toInventory.qty.find(
          q =>
            q.statusId == invAction.statusId &&
            (!invAction.linger || q.subInvId == currentInvQty.invId)
        );

      // update toInvQty
      await (async () => {
        if (toInvQty) {
          await updateInvQty({
            variables: {
              id: toInvQty.id,
              set: {
                qty: toInvQty.qty + qty,
              },
            },
          });
          return;
        } else {
          await addInvQty({
            variables: {
              object: {
                invId: toInventory.id,
                subInvId: invAction.linger ? currentInvQty.invId : undefined,
                statusId: invAction.statusId,
                qty,
              },
            },
            update: (cache, { data }) => {
              cache.modify({
                fields: {
                  invQty(existing = [], { storeFieldName }) {
                    const filtered = hasuraFilter({
                      items: [data?.insert_invQty_one],
                      name: "invQty",
                      string: storeFieldName,
                    });

                    const newInvQtyRefs = filtered.map(sd =>
                      cache.writeFragment({
                        data: sd,
                        fragment: INV_QTY_FIELDS,
                        fragmentName: "InvQtyFields",
                      })
                    );
                    return [...existing, ...newInvQtyRefs];
                  },
                },
              });
            },
          });
          return;
        }
      })();

      // add History
      const id = await addInvHistory({
        changes: [
          {
            diff: -qty,
            invId: inventory.id,
            statusId: currentInvQty.statusId,
          },
          {
            diff: qty,
            invId: toInventory.id,
            statusId: invAction.statusId,
          },
        ],
        fromId: inventory.id,
        toId: toInventory.id,
        invActionId: invAction.id,
        description: description ? description : undefined,
      });

      return id;
    }
    // ASSIGN ACTION
    if (invAction.type == "assign") {
      if (!customerId || !location) {
        return null;
      }

      if (!fromInvStat || !toInvStat) {
        return null;
      }

      const fromInventory = inventoryOverride || inventory;

      // location is FROM,
      // customer is TO

      // get toInventory
      const toInventoryData = await getInventory({
        variables: {
          where: {
            productId: { _eq: fromInventory.productId },
            customerId: { _eq: customerId },
          },
        },
        fetchPolicy: "network-only",
      });

      let toInventory = toInventoryData.data?.inventory[0];

      // add Inventory if not exist
      await (async () => {
        if (!toInventory) {
          const addedInventoryData = await addInventory({
            variables: {
              object: {
                productId: fromInventory.productId,
                customerId,
              },
            },
            update: (cache, { data }) => {
              cache.modify({
                fields: {
                  inventory(existing = [], { storeFieldName }) {
                    const filtered = hasuraFilter({
                      items: [data.insert_inventory_one],
                      name: "inventory",
                      string: storeFieldName,
                    });

                    const newInvRefs = filtered.map(sd =>
                      cache.writeFragment({
                        data: sd,
                        fragment: INVENTORY_FIELDS,
                        fragmentName: "InventoryFields",
                      })
                    );
                    return [...existing, ...newInvRefs];
                  },
                },
              });
            },
          });

          toInventory = addedInventoryData.data.insert_inventory_one;
          return;
        }

        return;
      })();

      if (!toInventory) {
        return null;
      }

      // get toInvQty
      let toInvQty =
        toInventory.qty &&
        toInventory.qty.find(
          q =>
            q.statusId == invAction.statusId &&
            (!invAction.linger || q.subInvId == currentInvQty.invId)
        );

      // update toInvQty
      await (async () => {
        if (toInvQty) {
          await updateInvQty({
            variables: {
              id: toInvQty.id,
              set: {
                qty: toInvQty.qty + qty,
              },
            },
          });
          return;
        } else {
          await addInvQty({
            variables: {
              object: {
                invId: toInventory.id,
                subInvId: invAction.linger ? currentInvQty.invId : undefined,
                statusId: invAction.statusId,
                qty,
              },
            },
            update: (cache, { data }) => {
              cache.modify({
                fields: {
                  invQty(existing = [], { storeFieldName }) {
                    const filtered = hasuraFilter({
                      items: [data?.insert_invQty_one],
                      name: "invQty",
                      string: storeFieldName,
                    });

                    const newInvQtyRefs = filtered.map(sd =>
                      cache.writeFragment({
                        data: sd,
                        fragment: INV_QTY_FIELDS,
                        fragmentName: "InvQtyFields",
                      })
                    );
                    return [...existing, ...newInvQtyRefs];
                  },
                },
              });
            },
          });
          return;
        }
      })();

      const qtyToApply_from = currentInvQty.qty - qty;

      // update fromInvQty
      await updateInvQty({
        variables: {
          id: currentInvQty.id,
          set: {
            qty: qtyToApply_from,
          },
        },
      });

      if (qtyToApply_from <= 0) {
        removeInvQty(currentInvQty.id);
      }

      // add History
      const id = await addInvHistory({
        changes: [
          {
            invId: fromInventory.id,
            diff: -qty,
            statusId: currentInvQty.statusId,
          },
          {
            invId: toInventory.id,
            diff: qty,
            statusId: invAction.statusId,
          },
        ],
        fromId: fromInventory.id,
        toId: toInventory.id,
        invActionId: invAction.id,
        description: description ? description : undefined,
      });

      return id;
    }
  };

  const callbacked = useCallback(updateFunction, [inventory, invQty]);

  return callbacked;
}
