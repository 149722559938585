import { TypedDocumentNode, gql } from "@apollo/client";
import { AUTOMATION_FIELDS } from "../fragments/automation";
import { automation } from "../types/automation";

export const GET_AUTOMATION_BY_ID: TypedDocumentNode<{
  automations_by_pk: automation;
}> = gql`
  ${AUTOMATION_FIELDS}
  query GET_AUTOMATION_BY_ID($id: Int!) {
    automations_by_pk(id: $id) {
      ...AutomationFields
    }
  }
`;

export const GET_AUTOMATIONS: TypedDocumentNode<{
  automations: automation[];
}> = gql`
  ${AUTOMATION_FIELDS}
  query GET_AUTOMATIONS {
    automations {
      ...AutomationFields
    }
  }
`;

export const UPDATE_AUTOMATION_BY_ID: TypedDocumentNode<{
  update_automations_by_pk: automation;
}> = gql`
  ${AUTOMATION_FIELDS}
  mutation UPDATE_AUTOMATION_BY_ID($id: Int!, $set: automations_set_input!) {
    update_automations_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...AutomationFields
    }
  }
`;

export const ADD_AUTOMATION = gql`
  ${AUTOMATION_FIELDS}
  mutation ADD_AUTOMATION($object: automations_insert_input!) {
    insert_automations_one(object: $object) {
      ...AutomationFields
    }
  }
`;

export const DELETE_AUTOMATION = gql`
  mutation DELETE_AUTOMATION($id: Int!) {
    delete_automations_by_pk(id: $id) {
      id
    }
  }
`;
