import { TypedDocumentNode, gql } from "@apollo/client";
import { User } from "../types/user";
import { USER_CORE_FIELDS } from "../fragments/user";

export const GET_USERS: TypedDocumentNode<{
  users: User[];
}> = gql`
  ${USER_CORE_FIELDS}
  query GET_USERS {
    users {
      ...UserCoreFields
    }
  }
`;

export const GET_USERS_CONDITIONAL: TypedDocumentNode<{
  users: User[];
}> = gql`
  ${USER_CORE_FIELDS}
  query GET_USERS($where: users_bool_exp!) {
    users(where: $where) {
      ...UserCoreFields
    }
  }
`;

export const GET_USER_BY_ID: TypedDocumentNode<{
  users_by_pk: User;
}> = gql`
  ${USER_CORE_FIELDS}
  query GET_USER_BY_ID($id: Int!) {
    users_by_pk(id: $id) {
      ...UserCoreFields
    }
  }
`;

export const UPDATE_USER_BY_ID: TypedDocumentNode<{
  update_users_by_pk: User;
}> = gql`
  ${USER_CORE_FIELDS}
  mutation UPDATE_USER_BY_ID($id: Int!, $set: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...UserCoreFields
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($id: Int!) {
    delete_users_by_pk(pk_columns: { id: $id }) {
      id
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!, $id: Int!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { password: $password }) {
      id
    }
  }
`;

export const GET_PASSWORD = gql`
  query getPassword($id: Int!) {
    users_by_pk(id: $id) {
      password
    }
  }
`;

export const GET_USER_STATUSES: TypedDocumentNode<{
  userStatuses: User["status"][];
}> = gql`
  query getUserStatuses {
    userStatuses {
      id
      name
      considerActive
    }
  }
`;
