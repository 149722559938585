import { ChangeEvent, LegacyRef, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Resizer from "react-image-file-resizer";
import getBase64 from "../../../../../utils/getBase64";
import Avatar from "@mui/material/Avatar";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisuallyHiddenInput from "../../../../../comps/hiddenInput";

export default function ProductPhotoControl() {
  const { setValue, control } = useFormContext();

  const photo = useWatch({
    name: "photo",
    control,
  });

  const ref: LegacyRef<HTMLInputElement> | undefined | null = useRef(null);

  const resize = (file: File) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        384,
        384,
        "PNG",
        80,
        0,
        uri => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    if (file.size > 50000) {
      const converted = await resize(file);
      setValue("photo", converted, { shouldDirty: true });
    } else {
      const converted = await getBase64(file);
      setValue("photo", converted, { shouldDirty: true });
    }
  };

  return (
    <div className="flex flex-col gap-2 items-start">
      <div className="flex flex-row justify-between items-center w-[184px]">
        <label>제품 사진</label>
        {photo && (
          <div
            className="flex flex-row items-end cursor-pointer"
            onClick={() => {
              setValue("photo", null);
            }}
          >
            <DeleteForeverIcon color="error" />
          </div>
        )}
      </div>

      <Avatar
        variant="square"
        sx={{ width: 180, height: 180, bgcolor: "#CCCCCCCC", borderRadius: 2 }}
        src={photo}
        className="cursor-pointer hover:ring-1 ring-quezone"
        onClick={() => {
          ref?.current?.click();
        }}
      >
        <div className="flex flex-col items-center text-base">
          <AddPhotoAlternateIcon sx={{ width: 124, height: 124 }} />
        </div>
      </Avatar>
      <VisuallyHiddenInput
        type="file"
        ref={ref}
        onChange={onChange}
        multiple={false}
        accept="image/*;capture=camera"
      />
    </div>
  );
}
