import { useForm } from "react-hook-form";
import { tag } from "../../../types/common";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DeleteForever from "@mui/icons-material/DeleteForever";

interface props {
  tag?: tag;
  dismiss: () => void;
  onSubmit: (data: { name: string }) => void;
  onDelete?: () => void;
}

const schema = z.object({
  name: z.string().min(1, "이름을 입력하세요"),
});

export default function TagControl({
  tag,
  dismiss,
  onSubmit,
  onDelete,
}: props) {
  const defaultValues = {
    name: tag?.name || "",
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, touchedFields },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const onDismiss = () => {
    reset();
    dismiss();
  };

  const submit = handleSubmit(data => {
    onSubmit(data);
    reset();
  });

  const remove = () => {
    if (!onDelete) {
      return;
    }

    const proceed = confirm("정말 태그를 삭제하시겠습니까?");
    if (!proceed) {
      return;
    }
    onDelete();
  };

  return (
    <Dialog open={true} onClose={onDismiss}>
      <DialogTitle>작업 태그 {tag ? "수정" : "추가"}</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <TextField
            margin="dense"
            size="small"
            fullWidth
            error={errors.name && touchedFields.name}
            color="success"
            placeholder={
              errors.name?.message ? errors.name?.message : "태그 이름"
            }
            label="이름"
            {...register("name")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {tag && onDelete && (
          <div className="text-red-500 cursor-pointer" onClick={remove}>
            <DeleteForever />
          </div>
        )}
        <Button sx={{ color: "black" }} onClick={onDismiss}>
          취소
        </Button>
        <Button
          disabled={!isDirty}
          variant="contained"
          color="success"
          onClick={submit}
        >
          {tag ? "수정" : "추가"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
