import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { GET_COMPANY_SOURCES } from "../../../../gqls/source";
import useFormValues from "../../../../hooks/useFormValues";
import { useAuthStore } from "../../../../store/authStore";

export function SourceControlOnly({ disabled }: { disabled?: boolean }) {
  const { control, setValue } = useFormContext();

  const { companyId } = useFormValues();

  const { data, loading } = useQuery(GET_COMPANY_SOURCES, {
    variables: {
      id: companyId,
    },
    fetchPolicy: "cache-and-network",
  });

  const { user } = useAuthStore();

  let sources = data?.sources || [];

  if (!user?.company?.type.canHaveCustomers) {
    sources = sources.filter(s => s.isSourceCompany);
  }

  return (
    <>
      {!loading && (
        <Controller
          name="sourceId"
          control={control}
          render={({ field }) => (
            <Select
              margin="none"
              size="small"
              required
              className="shadow-md"
              color="success"
              value={field.value || 0}
              onChange={e => {
                setValue("sourceDetailId", 0);
                field.onChange(e.target.value);
              }}
              onBlur={field.onBlur}
              name={field.name}
              ref={field.ref}
              disabled={field.disabled || disabled}
            >
              <MenuItem value={0}>접수처를 선택하세요</MenuItem>
              {sources.map(source => (
                <MenuItem value={source.id} key={source.id}>
                  {source.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      )}
    </>
  );
}

export default function SourceControl({ disabled }: { disabled?: boolean }) {
  return (
    <div className="col-span-1 flex flex-col gap-1">
      <label>접수처</label>
      <SourceControlOnly disabled={disabled} />
    </div>
  );
}
