import { useAlertStore } from "../../store/alertStore";
import AlertComp from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function Alert() {
  const { alerts, removeAlert } = useAlertStore();

  // available types: info, success, warning, error

  return (
    <div className="absolute w-full z-50">
      <Stack sx={{ width: "100%" }} spacing={2}>
        {alerts.map(a => {
          return (
            <AlertComp
              key={a.id}
              onClose={() => {
                removeAlert(a.id);
              }}
              severity={a.type}
              className="mb-2"
            >
              {a.message}
            </AlertComp>
          );
        })}
      </Stack>
    </div>
  );
}
